import React from 'react';
import SidebarSecondary from '../components/sidebar/SidebarSecondary';
import { Container } from 'reactstrap';

export const PaymentsContainer = props => {
  return (
    <div className='container-with-sidebar'>
      <SidebarSecondary
        router={props.router}
        route={props.route}
        subRoutes={sideBarSchema.routes()}
        title={sideBarSchema.name}
      />
      <Container>{props.children}</Container>
    </div>
  );
};

const sideBarSchema = {
  name: 'Payments',
  routes: () => {
    return [
      // {
      //   title: true,
      //   name: 'Accounts'
      // },
      // {
      //   name: 'All',
      //   url: `/payments/accounts/`
      // },
      {
        title: true,
        name: 'Payment alerts',
      },
      {
        name: 'Unresolved alerts',
        url: '/payments/alerts/unresolved',
      },
      {
        name: 'Resolved alerts',
        url: '/payments/alerts/resolved',
      },
      {
        title: true,
        name: 'Payment batches',
      },
      {
        name: 'Pending',
        url: '/payment-batches/pending',
      },
      {
        name: 'Submitted',
        url: '/payment-batches/submitted',
      },
      {
        title: true,
        name: 'Payments',
      },
      {
        name: 'Pending',
        url: '/payments/pending',
      },
      {
        name: 'Processing',
        url: '/payments/processing',
      },
      {
        title: true,
        name: 'BANV',
      },
      {
        name: 'Pending',
        url: '/payments/banv/pending',
      },
      {
        name: 'Processing',
        url: '/payments/banv/processing',
      },
      {
        title: true,
        name: 'Policy lifecycle',
      },
      {
        name: 'Pending',
        url: '/payments/update-requests/pending',
      },
      {
        title: true,
        name: 'Payout requests',
      },
      {
        name: 'Policy refunds',
        url: '/payout-requests/policy-refunds',
      },
      {
        name: 'Claim payouts',
        url: '/payout-requests/claim-payouts',
      },
    ];
  },
};
