import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export class InputNumberBlockState extends BlockState {
  readonly value?: number;

  constructor(init: Construct<InputNumberBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      value: this.value,
    };
  }

  static fromJSON(init: any) {
    return new InputNumberBlockState({
      type: init.type,
      value: init.value,
    });
  }
}
