import React from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import {
  prettyDataSource,
  Template,
} from './data-exports/domain/templates/template';
import { TemplateViewStore } from './data-exports/stores/template-view-store';
import { ScheduledDataExport } from './data-exports/domain/scheduled-data-export';
import uuid from 'uuid';
import { OrganizationProductModule } from '../domain/organization-product-module';
import { withRouter, RouteComponentProps } from 'react-router';
import { History } from 'history';

interface Props extends RouteComponentProps {
  template: Template;
  productModules: OrganizationProductModule[];
  scheduledDataExports: ScheduledDataExport[];
  isDefault: boolean;
  newExport: () => void;
  warningEdit: any;
  confirmEdit: () => void;
  archive: any;
  router?: History;
}

interface Injected extends Props {
  templateViewStore: TemplateViewStore;
}

interface State {
  popoverOpen: boolean;
}

@inject('templateViewStore')
@observer
class TemplateRowImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  toggle = async () => {
    const { templateViewStore, template } = this.injected;
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    }, async () => {
      if (this.state.popoverOpen) {
        await templateViewStore.load({
          organizationId: this.organizationId,
          templateId: template.templateId,
        });
      }
    });
  };

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  onRowClick = async () => {
    const { template, router } = this.props;
    if (router) {
      router.push(`/organizations/${this.organizationId}/exports/templates/${template.templateId}`);
    }
  }

  render() {
    const productModule = this.props.productModules.find(
      (p) => p.productModuleId === this.props.template.productModuleId,
    );

    const activeExportsCount = this.props.template.getActiveExports(
      this.props.scheduledDataExports,
    ).length;

    return (
      <tr className='data-export-row'>
        <td className='data-export-row-item' onClick={async () => this.onRowClick()}>
          <strong>{this.props.template.templateName}</strong>
        </td>
        <td className='data-export-row-item' onClick={async () => this.onRowClick()}>
          {prettyDataSource(this.props.template.dataSource)}
        </td>
        <td className='data-export-row-item' onClick={async () => this.onRowClick()}>
          {(productModule && productModule.name) || ''}
        </td>
        <td className='data-export-row-item' onClick={async () => this.onRowClick()}>
          {activeExportsCount}
        </td>
        <td className='data-export-row-item'>
          <Button
            outline
            color='secondary'
            className='ellipsisButton'
            id={`template-popover-${this.props.template.templateId || uuid().toString()}`}
            type='button'
            onClick={this.toggle}
            disabled={this.props.isDefault}
          >
            &#183;&#183;&#183;
          </Button>
          <Popover
            className='popoverBox'
            placement='bottom'
            isOpen={this.state.popoverOpen}
            target={`template-popover-${this.props.template.templateId || uuid().toString()}`}
          >
            <PopoverBody>
              <Button
                className='popoverListItem'
                outline
                color='secondary'
                onClick={() => {
                  this.props.newExport();
                  this.toggle();
                }}
              >
                New export from template
              </Button>
              <Button
                className='popoverListItem'
                outline
                color='secondary'
                onClick={() => {
                  activeExportsCount > 0
                    ? this.props.warningEdit()
                    : this.props.confirmEdit();
                  this.toggle();
                }}
              >
                Edit template
              </Button>
              <hr />
              <Button
                className='popoverListItem'
                outline
                color='danger'
                onClick={() => {
                  this.props.archive(this.props.template);
                  this.toggle();
                }}
              >
                Archive template
              </Button>
            </PopoverBody>
          </Popover>
        </td>
      </tr>
    );
  }
}

export const TemplateRow = withRouter(TemplateRowImpl);
