import React from 'react';
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
} from 'reactstrap';
import PaymentsTable from '../../../payments/components/payments-table';
import UpdateRequestsTable from '../../../policy-lifecycle/components/pending/update-requests-table';
import {
  withData,
  ajax,
  toSentenceCase,
  renderValue,
  toCamelCaseKeys,
} from '../../../helpers';
import CardEntry from '../../../components/card-entry';
import {
  getProductModuleByKey,
  getProductModuleDefinition,
} from '../../../product-modules/components/product-modules-api';
import * as _ from 'lodash';
import { AddPaymentModal } from './modals/add-payment-modal';

interface Payment {}

interface Props {
  payments: Payment[];
  loading: boolean;
  params: {
    policyId: string;
  };
  fetchPayments: () => any;
}

interface LifecycleRule {
  rules: {
    period: {
      type: string;
      value: string;
    };
  }[];
}

interface ModuleSettings {
  settings: {
    lifecycle: LifecycleRule[];
  };
}

interface UpdateRequest {
  policy_id: string;
}

interface Policy {
  [key: string]: any;
  module: {
    type: string;
  };
}

interface State {
  policy?: Policy;
  moduleSettings?: ModuleSettings;
  updateRequests?: UpdateRequest[];
  loading: boolean;
  checkbox: boolean;
  showAddPaymentModal: boolean;
}

class PolicyPayments extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      checkbox: true,
      showAddPaymentModal: false,
    };
  }

  componentDidMount = () => {
    this.fetchPolicyAndModuleSettings();
    this.fetchUpdateRequests();
  };

  fetchPolicyAndModuleSettings = async () => {
    const { policyId } = this.props.params;

    const policy = await ajax({
      type: 'GET',
      path: `/insurance/admin/policies/${policyId}`,
    });

    const productModule = await getProductModuleByKey(policy.module.type);

    if (productModule.liveId) {
      const productModuleDefinition = await getProductModuleDefinition(
        productModule.productModuleId,
        productModule.liveId,
      );
      this.setState({ moduleSettings: productModuleDefinition as any }); //we need to come back to this and fix up children
    }

    this.setState({ policy });
  };

  fetchUpdateRequests = async () => {
    const updateRequests = (await ajax({
      type: 'GET',
      path: `/insurance/admin/update-requests?policy_id=${this.props.params.policyId}&status=pending&include=policy`,
    })).map(toCamelCaseKeys); // UpdateRequestsTable was structured to use camel case keys
    this.setState({ updateRequests });
  };

  handleChange = async () => {
    await this.setState({ checkbox: !this.state.checkbox });
  };

  handleAddPaymentAdded = () => {
    this.fetchPolicyAndModuleSettings();
    this.props.fetchPayments();
  }

  renderCheckboxFilter() {
    return (
      <FormGroup check>
        <Label check>
          <span style={{ marginRight: '8px' }}>Filter &#39;cancelled&#39;</span>
          <Input
            type='checkbox'
            checked={this.state.checkbox}
            onChange={this.handleChange}
          />
        </Label>
      </FormGroup>
    );
  }

  renderLifecycleRules(lifecycleRules: LifecycleRule[]) {
    const components = [];
    let index = 0;

    for (const key in lifecycleRules) {
      const rules = lifecycleRules[key].rules;

      if (rules && rules.length) {
        const { type, value } = rules[0].period;
        components.push(
          <CardEntry index={++index} key={key}>
            <div style={{ padding: '2px' }}>
              <b>{_.startCase(key)}</b>
            </div>
            {type && value ? <div style={{ width: '70%', padding: '2px' }}>{`${renderValue(
              type,
              value,
            )} ${_.startCase(type)}`}</div> : <div style={{ width: '70%', padding: '2px' }}>Please check product module tooling</div> }
          </CardEntry>,
        );
      }
    }

    return components;
  }

  renderPolicySummary = () => {
    const { policy, moduleSettings } = this.state;

    if (!policy) return 'Loading policy...';

    return (
      <div>
        {[
          'status',
          'billing_day',
          'monthly_premium',
          'balance',
          'start_date',
          'end_date',
        ].map((key, index) => (
          <CardEntry key={key} index={index}>
            <div style={{ padding: '2px' }}>
              <b>{toSentenceCase(key)}</b>
            </div>
            <div style={{ width: '70%', padding: '2px' }}>
              {renderValue(key, policy[key])}
            </div>
          </CardEntry>
        ))}
        <CardEntry index={6}>
          <div style={{ padding: '2px' }}>
            <b>{'Module'}</b>
          </div>
          <div style={{ width: '70%', padding: '2px' }}>
            {policy.module.type}
          </div>
        </CardEntry>
        {moduleSettings &&
          this.renderLifecycleRules(moduleSettings.settings.lifecycle)}
      </div>
    );
  };

  render() {
    const { payments, params } = this.props;
    const { updateRequests, policy } = this.state;
    if (this.props.loading) {
      return (
        <div>
          <i>Loading payments...</i>
        </div>
      );
    }

    return (
      <div>
        <Container style={{ padding: 0 }}>
          <Card>
            <CardHeader>
              <h5>Policy summary</h5>
            </CardHeader>
            <CardBody>
              {policy && this.renderPolicySummary()}
              <br />
              <h5>Policy update requests</h5>
              {updateRequests && updateRequests.length === 0 ? (
                <p>No pending update requests</p>
              ) : (
                <UpdateRequestsTable
                  updateRequests={updateRequests}
                  refresh={this.fetchUpdateRequests}
                  params={params}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ alignItems: 'center', display: 'inline-flex' }}>
                  <h5>Payments</h5>
                </span>
                <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ alignItems: 'center', display: 'inline-flex' }}>{this.renderCheckboxFilter()}</span>
                  <span>
                    <Button color='primary' onClick={() => this.setState({ showAddPaymentModal: true })}>
                      Add payment
                    </Button>
                  </span>
                </span>
              </div>
              <div>
                {payments && payments.length === 0 ? (
                  <p>This policy has no payments</p>
                ) : (
                  <PaymentsTable
                    payments={payments}
                    refresh={this.props.fetchPayments}
                    checkbox={this.state.checkbox}
                  />
                )}
              </div>
            </CardBody>
          </Card>

          <AddPaymentModal
            onClose={() => this.setState({ showAddPaymentModal: false })}
            onPaymentAdded={this.handleAddPaymentAdded} // force update to fetch updates list of payments
            show={this.state.showAddPaymentModal}
            policy={this.state.policy}
          />
        </Container>
      </div>
    );
  }
}

export default withData({
  path: (props: Props) =>
    `/insurance/admin/payments?policy_id=${
      props.params.policyId
    }&include=payment_method,reversal_of_payment,billing_provider`,
  prop: 'payments',
})(PolicyPayments as any);
