import React from 'react';
import { observer } from 'mobx-react';
import { DomainEntityTable } from '../../../components/domain/tables/domain-entity-table';
import { Feature } from '../../domain/feature';
import { BulkUpdateFeatureModal } from './bulk-update-feature-modal';

const SandboxByDefaultElement: React.SFC<Feature> = (feature) => (
  <span>{feature.sandboxByDefault ? 'Yes' : 'No'}</span>
);

const SandboxByDefaultColumn = {
  heading: 'Sandbox by default',
  element: SandboxByDefaultElement,
};

const FeatureKeyElement: React.SFC<Feature> = (feature) => (
  <code>{feature.featureKey}</code>
);

const FeatureKeyColumn = {
  heading: 'Feature key',
  element: FeatureKeyElement,
};

@observer
export class FeatureListTable extends DomainEntityTable<Feature> {}

interface Props {
  rows: Feature[];
  isLoaded: boolean;
  selected: (feature: Feature, index: number) => void;
}

interface State {
  selectedFeature: Feature | undefined;
  isBulkUpdateOpen: boolean;
}

export default class RenderFeatureTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFeature: undefined,
      isBulkUpdateOpen: false,
    };
  }

  render() {
    return (
      <>
        {this.state.isBulkUpdateOpen && this.state.selectedFeature && (
          <BulkUpdateFeatureModal
            feature={this.state.selectedFeature}
            isOpen={this.state.isBulkUpdateOpen}
            onClose={() => {
              this.setState({
                selectedFeature: undefined,
                isBulkUpdateOpen: false,
              });
            }}
          />
        )}
        <FeatureListTable
          rows={this.props.rows}
          columns={[FeatureKeyColumn, 'description', SandboxByDefaultColumn]}
          isLoaded={this.props.isLoaded}
          getRowProps={(feature: Feature, index: number) => ({
            onClick: () => {
              this.setState({
                isBulkUpdateOpen: true,
                selectedFeature: feature,
              });
              console.log(this.props.selected);
              this.props.selected(feature, index);
            },
          })}
        />
      </>
    );
  }
}
