import React from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { withData } from '../../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import Icon from '../../../components/icon';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';

class Policyholder extends React.Component {
  renderSidebar() {
    return (
      <div>
        <Card className='mb-4'>
          <CardBody>
            <b>Policyholder</b>
            <br />
            {this.props.policyholder.firstName}{' '}
            {this.props.policyholder.lastName}
          </CardBody>
        </Card>
        <LinkContainer
          to={`/organizations/${
            this.props.params.organizationId
          }/policyholders/${this.props.params.policyholderId}?sandbox=${this
            .props.location.query.sandbox === 'true'}`}
        >
          <Button block className='text-left'>
            <Icon name='list' /> Overview
          </Button>
        </LinkContainer>
        <LinkContainer
          to={`/organizations/${
            this.props.params.organizationId
          }/policyholders/${
            this.props.params.policyholderId
          }/payment-methods?sandbox=${this.props.location.query.sandbox ===
            'true'}`}
        >
          <Button block className='text-left'>
            <Icon name='credit-card' /> Payment methods
          </Button>
        </LinkContainer>
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading policyholder...</i>
        </div>
      );
    }

    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes(
            this.props.router.params,
            this.props.router.location.query,
          )}
          title={sideBarSchema.name}
        />
        <Container>
          <Row>
            <Col sm={12}>
              <Row>
                <Col>{this.props.children}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withData({
  path: props =>
    `/insurance/admin/policyholders/${props.params.policyholderId}`,
  prop: 'policyholder',
})(Policyholder);

const sideBarSchema = {
  name: 'Policyholder',
  routes: (params, query) => {
    const { policyholderId } = params;
    const isSandbox = query.sandbox === 'true';
    return [
      {
        title: true,
        name: 'View',
      },
      {
        name: 'Overview',
        url: `/policyholders/${policyholderId}/overview?sandbox=${isSandbox}`,
      },
      {
        name: 'Payment methods',
        url: `/policyholders/${policyholderId}/payment-methods?sandbox=${isSandbox}`,
      },
    ];
  },
};
