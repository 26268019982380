/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='80px' height='30px' viewBox='0 0 80 30' version='1.1'>
        <defs>
          <rect
            id='path-1'
            x='0.744326518'
            y='0.683799342'
            width='79.2556735'
            height='29.2105263'
            rx='3'
          />
          <mask
            id='mask-2'
            maskContentUnits='userSpaceOnUse'
            maskUnits='objectBoundingBox'
            x='0'
            y='0'
            width='79.2556735'
            height='29.2105263'
            fill='white'
          >
            <use xlinkHref='#path-1' />
          </mask>
        </defs>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-57'
            transform='translate(-624.000000, -302.000000)'
          >
            <g id='new-sheet' transform='translate(624.000000, 302.000000)'>
              <use
                id='Rectangle-8-Copy'
                stroke='#009E55'
                mask='url(#mask-2)'
                strokeWidth='2'
                fill='#009E55'
                xlinkHref='#path-1'
              />
              <g id='Group-3-Copy-2' transform='translate(10.000000, 6.289062)'>
                <g id='Group-6'>
                  <text
                    id='New'
                    fontFamily='ProximaNova-Regular, Proxima Nova'
                    fontSize='13'
                    fontWeight='normal'
                    fill='#FFFFFF'
                  >
                    <tspan x='23' y='13'>
                      New{' '}
                    </tspan>
                  </text>
                  <g
                    id='Group-3-Copy'
                    transform='translate(0.713163, 2.837012)'
                    stroke='#FFFFFF'
                  >
                    <rect
                      id='Rectangle-4'
                      x='0'
                      y='0'
                      width='10.9477512'
                      height='13.4465195'
                    />
                    <path
                      d='M0.515397377,9.09240844 L10.8233449,9.09240844'
                      id='Line'
                      strokeLinecap='square'
                    />
                    <path
                      d='M0.515397377,4.29008004 L10.8233449,4.29008004'
                      id='Line-Copy'
                      strokeLinecap='square'
                    />
                    <path
                      d='M5.54338513,0.174306735 L5.54338513,12.9991725'
                      id='Line'
                      strokeLinecap='square'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
