import { utc as moment, Moment } from 'moment';
import { Construct } from '../../../../../../../shared/domain/construct';
import { Requestee } from '../../../../../../../shared/domain/requestee';

export interface FulfillmentData {
  readonly [key: string]: {
    label: string;
    valueType: string | number | 'enum' | 'currency';
    valueOptions?: { [key: string]: any };
    isValue: boolean;
  };
}

export class FulfillmentType {
  readonly fulfillmentTypeId?: string;
  readonly productModuleId?: string;
  readonly key: string;
  readonly label: string;
  readonly fulfillmentData: FulfillmentData;
  readonly createdAt?: Moment;
  readonly createdBy?: object;

  constructor(init: Construct<FulfillmentType>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkFulfillmentType) {
    return new FulfillmentType({
      fulfillmentTypeId: init.fulfillment_type_id,
      productModuleId: init.product_module_id,
      key: init.key,
      label: init.label,
      fulfillmentData: init.fulfillment_data,
      createdAt: init.created_at ? moment(init.created_at) : undefined,
      createdBy: init.created_by ? Requestee.fromNetwork(init.created_by) : undefined,
    });
  }
}

export interface NetworkFulfillmentType {
  fulfillment_type_id: string;
  product_module_id: string;
  key: string;
  label: string;
  fulfillment_data: FulfillmentData;
  created_at?: string;
  created_by?: object;
}
