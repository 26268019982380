import React from 'react';
import { CardBody, Button, Card, CardHeader, Row, Col, CardFooter, Input } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore } from '../../product-definition/stores/product-module-store';

import {
  SubmitForReviewModal,
  ApproveToPublish,
  RejectReviewModal,
} from '../../product-definition/modals';
import {
  sendProductModuleDefinitionToReview,
  publishProductModule,
  revokeProductModuleDefinintionReview,
} from '../../product-modules-api';
import { ProductModuleDefinition } from '../../../domain/product-module-definition';
import LoadingInPage from '../../../loading';
import marked from 'marked';
import { buttonText } from '../../../../shared/helpers/button-text-loading';

interface Props {
  productModuleKey: string;
  redirect: (key: string) => any;
}

interface State {
  showSendToReview: boolean;
  showPublishModule: boolean;
  showRejectPublish: boolean;
  showMarkdownEditor: boolean;
  isLoading: boolean;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDeployment extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSendToReview: false,
      showPublishModule: false,
      showRejectPublish: false,
      showMarkdownEditor: false,
      isLoading: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { productModuleStore, productModuleKey } = this.injected;
    await productModuleStore.init(productModuleKey, true);
  }

  onProductModuleDefinitionSelect = (
    productModuleDefinition: ProductModuleDefinition,
  ) => {
    const { productModuleStore, redirect } = this.injected;
    productModuleStore.setProductModulePrimary(productModuleDefinition);
    redirect(productModuleStore.productModule.key);
  };

  handleMarkdownSave = async () => {
    const { productModuleStore } = this.injected;
    this.setState({ isLoading: true });
    await productModuleStore.updateProductModuleChangelog({
      productModuleId: productModuleStore.productModule.productModuleId,
      markdown: productModuleStore.changelogMarkdown,
    });
    this.setState({ showMarkdownEditor: false, isLoading: false });
  }

  renderMarkdownCard = (title: string) => {
    const { productModuleStore } = this.injected;
    if (productModuleStore.productModuleDefinitionDraft)
      return <Card>
        <CardHeader>
          <b>{title}</b>
          {
            this.state.showMarkdownEditor
              ? <Button
                color='primary'
                disabled={this.state.isLoading}
                className='show-markdown-editor-button-style'
                onClick={() => this.handleMarkdownSave()}
              >
                {buttonText({ text: 'Save', loading: this.state.isLoading })}
              </Button>
              : <Button
                color='link'
                style={{ float: 'right', padding: 0, margin: 0, minWidth: 0 }}
                onClick={() => this.setState({ showMarkdownEditor: true })}
              >
                Edit
              </Button>
          }
        </CardHeader>
        <CardBody style={{ padding: this.state.showMarkdownEditor ? 0 : '1.25rem' }}>
          {
            this.state.showMarkdownEditor
              ? <Input
                type="textarea"
                name="markdownEditor"
                style={{ border: 'none', minHeight: '20vh' }}
                value={productModuleStore.changelogMarkdown}
                onChange={(e) => productModuleStore!.changelogMarkdown = e.target.value}
              />
              : <div dangerouslySetInnerHTML={{ __html: marked(productModuleStore.changelogMarkdown || '') }}></div>
          }
        </CardBody>
      </Card>;
    return <></>;
  }

  sendToReview = async () => {
    const { productModuleStore, productModuleKey } = this.injected;
    await sendProductModuleDefinitionToReview(productModuleStore.productModule.productModuleId);
    await productModuleStore.init(productModuleKey, true);
  };

  approveToPublish = async () => {
    const { productModuleStore, productModuleKey } = this.injected;
    await publishProductModule(productModuleStore.productModule.productModuleId);
    await productModuleStore.init(productModuleKey, true);
  };

  rejectReview = async () => {
    const { productModuleStore, productModuleKey } = this.injected;
    const { productModule } = productModuleStore;
    const data = {
      product_module_id: productModule.productModuleId,
      product_module_definition_id: productModule.draftId,
    };
    if (productModuleStore) {
      await revokeProductModuleDefinintionReview(productModuleStore.productModule.productModuleId, data);
      await productModuleStore.init(productModuleKey, true);
    }
  };

  renderCardLatestIsLive() {

  }

  renderCardReadyForReview() {
    const { productModuleStore } = this.injected;
    if (
      !productModuleStore.productModule.reviewId &&
      productModuleStore.productModuleDefinitionDraft
    ) {
      const draft = productModuleStore.productModuleDefinitionDraft;
      const live = productModuleStore.productModuleDefinitionLive;

      return <Card>
        <CardHeader>
          Deploy request
        </CardHeader>
        <CardBody>
          <p>
            This product module has changes that can be sent for deployment review.
          </p>
          <p>
            Deploy version {draft.versionMajor}.{draft.versionMinor}. Current live version is {live && live.versionMajor || '-'}.{live && live.versionMinor || '-'}.
          </p>
        </CardBody>
        <CardFooter>
          <Button
            color='primary'
            style={{ margin: 0 }}
            onClick={() => this.setState({ showSendToReview: !this.state.showSendToReview })}
          >
            Send for review
          </Button>
        </CardFooter>
      </Card>;
    }
  };

  renderCardInReview() {
    const { productModuleStore } = this.injected;
    const { productModule, productModuleDefinitionReview } = productModuleStore;
    const { showRejectPublish, showPublishModule } = this.state;

    if (productModule.reviewId && productModuleDefinitionReview) {
      const { versionMajor, versionMinor } = productModuleStore.productModuleDefinitionReview;

      return <Card>
        <CardHeader>
          Deploy request
        </CardHeader>
        <CardBody>
          <p>
            Version {versionMajor}.{versionMinor} of this product module has been submitted for review.
          </p>
          <p>
            Use the changelog above to record a summary of the changes made and the review steps taken.
          </p>
          <p>
            Approving this Pull Request will deploy this version to production, and all newly issued policies will
            reference it. Existing policies will not be affected. Declining it will void the review,
            allowing you to edit the latest draft and resubmit it.
          </p>
          <div className='product-module-card-inner-div'>
            <Button
              color='primary'
              outline
              style={{ marginLeft: 0, marginBottom: 0, marginTop: 0 }}
              onClick={() => this.onProductModuleDefinitionSelect(productModuleStore.productModuleDefinitionReview)}
            >
              Preview this version
            </Button>
          </div>
        </CardBody>
        <CardFooter>
          <Button
            color='danger'
            outline
            style={{ marginLeft: 0, marginBottom: 0, marginTop: 0 }}
            disabled={!productModuleStore.productModule.reviewId}
            onClick={() => this.setState({ showRejectPublish: !showRejectPublish })}
          >
            Decline
          </Button>
          <Button
            color='primary'
            style={{ margin: 0 }}
            disabled={!productModuleStore.productModule.reviewId}
            onClick={() => this.setState({ showPublishModule: !showPublishModule })}
          >
            Publish
          </Button>
        </CardFooter>
      </Card>;
    }
  };

  render() {
    const {
      showRejectPublish,
      showPublishModule,
      showSendToReview,
    } = this.state;
    const { productModuleStore } = this.injected;
    if (productModuleStore.productModuleDefinitionDraft) {
      return <>
        <Row style={{ marginTop: '1.5rem' }}><Col><h3>Deployment</h3></Col></Row>
        <RejectReviewModal
          show={showRejectPublish}
          onClose={() => this.setState({ showRejectPublish: !showRejectPublish })}
          rejectReview={() => { this.rejectReview(); this.setState({ showRejectPublish: !showRejectPublish }); }}
        />
        <ApproveToPublish
          show={showPublishModule}
          onClose={() => this.setState({ showPublishModule: !showPublishModule })}
          submitApproveToPublish={() => { this.approveToPublish(); this.setState({ showPublishModule: !showPublishModule }); }}
        />
        <SubmitForReviewModal
          show={showSendToReview}
          onClose={() => this.setState({ showSendToReview: !showSendToReview })}
          submitForReview={() => { this.sendToReview(); this.setState({ showSendToReview: !showSendToReview }); }}
        />
        <div style={{ marginBottom: '1rem' }}>{this.renderMarkdownCard('CHANGELOG.md')}</div>
        {this.renderCardLatestIsLive()}
        {this.renderCardReadyForReview()}
        {this.renderCardInReview()}
        <br /><br />
      </>;
    }
    return <LoadingInPage />;
  }
}
