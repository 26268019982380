import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { RadioBlock as Block } from '../domain/radio-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { RadioBlockState } from '../domain/states/radio-block-state';
import { FormGroup, Input, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: RadioBlockState;
}

interface State {
  UIState: BlockUIState;
  radioBlockState?: BlockState;
}

@observer
export class RadioBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      radioBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  renderRadioInputs( blockState: RadioBlockState, disabled?: boolean) {
    return this.props.block.options.map((option) => (
      <FormGroup check key={option.key}>
        <Label check>
          <Input
            type='radio'
            name={option.key}
            className='blocks-radio'
            disabled={disabled}
            selected={option.key === blockState.optionKey}
          />
          {option.value}
        </Label>
      </FormGroup>
    ));
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { radioBlockState } = this.state;
    if (!radioBlockState) {
      return null;
    }

    return (
      <FormGroup style={{ width: '50%' }}>
        <Label>
          <b>{block.title}</b>
        </Label>
        {this.props.block.options && this.renderRadioInputs(blockState, disabled)}
      </FormGroup>
    );
  }
}
