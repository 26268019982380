export enum DataExportAdapterType {
  SFTP = 'sftp',
  HTTPS = 'https',
  S3 = 's3',
}

export enum SFTPAuthenticationMethod {
  Password = 'password',
  PrivateKey = 'private_key',
}

export interface NetworkDataExportAdapter {
  type: DataExportAdapterType;
}

type DataExportFrequencyInit<T> = Pick<T, Exclude<{
  [K in keyof T]: T[K] extends Function ? never : K
}[keyof T], 'type'>>;

export interface DataExportAdapter {
  readonly type: DataExportAdapterType;
  toNetwork(): { type: string; [key: string]: any };
}

abstract class BaseDataExportAdapter<T> implements DataExportAdapter {
  abstract readonly type: DataExportAdapterType;

  constructor(init: DataExportFrequencyInit<T>) {
    Object.assign(this, init);
  }

  abstract toNetwork(): { type: string; [key: string]: any };
}

export class SFTPAdapter extends BaseDataExportAdapter<SFTPAdapter> {
  readonly type: DataExportAdapterType = DataExportAdapterType.SFTP;
  readonly host: string;
  readonly port?: number;
  readonly username?: string;
  readonly password?: string;
  readonly path?: string;
  readonly authenticationMethod: SFTPAuthenticationMethod;
  readonly privateKey?: string; // Encrypted JSON string
  readonly passphrase?: string; // Encrypted JSON string

  toNetwork() {
    return {
      type: this.type,
      host: this.host,
      port: this.port,
      username: this.username,
      password: this.password,
      path: this.path,
      authentication_method: this.authenticationMethod,
      private_key: this.privateKey,
      passphrase: this.passphrase,
    };
  }

  static fromNetwork(network: any): SFTPAdapter {
    return new SFTPAdapter({
      host: network.host,
      port: network.port,
      username: network.username,
      password: network.password,
      path: network.path,
      authenticationMethod: network.authentication_method,
      privateKey: network.private_key,
      passphrase: network.passphrase,
    });
  }
}

export class HTTPSAdapter extends BaseDataExportAdapter<HTTPSAdapter> {
  readonly type: DataExportAdapterType = DataExportAdapterType.HTTPS;
  readonly url: string;
  readonly headers?: { [key: string]: string };
  readonly batchSize?: number;

  toNetwork() {
    return {
      type: this.type,
      url: this.url,
      headers: this.headers,
      batch_size: this.batchSize,
    };
  }

  static fromNetwork(network: any): HTTPSAdapter {
    return new HTTPSAdapter({
      url: network.url,
      headers: network.headers,
      batchSize: network.batch_size,
    });
  }
}

export class S3Adapter extends BaseDataExportAdapter<S3Adapter> {
  readonly type: DataExportAdapterType = DataExportAdapterType.S3;
  readonly region?: string;
  readonly bucketName: string;
  readonly bucketPath?: string;
  readonly secretAccessKey: string; // Encrypted JSON string
  readonly accessKeyId: string; // Encrypted JSON string

  toNetwork() {
    return {
      type: this.type,
      region: this.region,
      bucket_name: this.bucketName,
      bucket_path: this.bucketPath,
      secret_access_key: this.secretAccessKey,
      access_key_id: this.accessKeyId,
    };
  }

  static fromNetwork(network: any): S3Adapter {
    return new S3Adapter({
      region: network.region,
      bucketName: network.bucket_name,
      bucketPath: network.bucket_path,
      secretAccessKey: network.secret_access_key,
      accessKeyId: network.access_key_id,
    });
  }
}

export class DataExportAdapterFactory {
  static fromNetwork(network: NetworkDataExportAdapter): DataExportAdapter {
    switch (network.type) {
      case DataExportAdapterType.SFTP:
        return SFTPAdapter.fromNetwork(network);
      case DataExportAdapterType.HTTPS:
        return HTTPSAdapter.fromNetwork(network);
      case DataExportAdapterType.S3:
        return S3Adapter.fromNetwork(network);
    }
  }
}

export const isSFTPDestinationData = (
  value: DataExportAdapter,
): value is SFTPAdapter => value && !!(value as SFTPAdapter).host;
export const isHTTPSDestinationData = (
  value: DataExportAdapter,
): value is HTTPSAdapter => value && !!(value as HTTPSAdapter).url;
export const isS3DestinationData = (
  value: DataExportAdapter,
): value is S3Adapter => value && !!(value as S3Adapter).region;
