export const productModulePolicy = `
declare class Policy {
  policy_number: string;
  package_name: string;
  sum_assured: number;
  base_premium: number;
  monthly_premium: number;
  start_date: Moment;
  end_date?: Moment;
  module: { type: string; [key: string]: any };
  beneficiaries?: any;
  members?: any;
  covered_items?: any;
  charges: any;

  constructor(init: Policy) {
    Object.assign(this, init);
  };
}
`;
