/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='80px'
        height='80px'
        viewBox='0 0 80 80'
        enableBackground='new 0 0 80 80'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <path
              fill='none'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              d='M60.632,54.805
                  L40.581,14.607c-0.357-0.71-0.977-1.2-1.682-1.417c-0.221-0.142-0.486-0.236-0.791-0.265l-19.324-1.765
                  c-1.113-0.102-2.506,0.592-3.097,1.541L5.474,29.203c-0.144,0.233-0.228,0.473-0.259,0.705c-0.295,0.72-0.303,1.557,0.073,2.306
                  l20.05,40.194c0.689,1.38,2.367,1.943,3.748,1.254l30.292-15.109C60.759,57.864,61.321,56.185,60.632,54.805z'
            />
          </g>
          <g>
            <path
              fill='#FFFFFF'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              d='M74.133,40.633
                  L40.458,6.958c-0.597-0.596-1.385-0.879-2.167-0.86c-0.27-0.067-0.568-0.075-0.885-0.001L17.383,10.82
                  c-1.153,0.271-2.32,1.438-2.593,2.592l-4.723,20.024c-0.066,0.283-0.069,0.552-0.023,0.797c-0.054,0.821,0.221,1.663,0.85,2.292
                  l33.672,33.673c1.157,1.156,3.032,1.156,4.189,0l25.378-25.376C75.289,43.666,75.29,41.791,74.133,40.633z M20.113,19.742
                  c-1.013-1.012-1.014-2.65,0-3.664c1.012-1.011,2.65-1.012,3.664,0.001c1.011,1.012,1.011,2.651,0,3.663
                  C22.763,20.755,21.125,20.754,20.113,19.742z'
            />
            <line
              fill='#FFFFFF'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='62.816'
              y1='43.525'
              x2='47.458'
              y2='58.881'
            />
            <line
              fill='#FFFFFF'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='58.156'
              y1='38.865'
              x2='42.798'
              y2='54.221'
            />
            <line
              fill='#FFFFFF'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='53.495'
              y1='34.205'
              x2='38.139'
              y2='49.561'
            />
          </g>
          <line
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='21.56'
            y1='17.619'
            x2='11.36'
            y2='7.418'
          />
        </g>
      </svg>
    );
  }
}
