import { ValidatorResult, Validator } from './types';

export interface EmailValidator {
  name: string;
}

export class EmailValidator<T extends string = string> implements Validator<T> {
  validate(value: T) {
    if (value === undefined) {
      return { valid: true };
    }

    const result: ValidatorResult = {
      valid: isValidEmail(String(value).toLowerCase()),
    };

    if (!result.valid) {
      result.message = `${this.name} should be a valid email address.`;
    }

    return result;
  }
}

export const isValidEmail = (email: string) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
