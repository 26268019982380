import React from 'react';
import PolicyUpdateRequests from './update-requests';

export default class PendingUpdateRequests extends React.Component {
  render() {
    return (
      <div>
        <PolicyUpdateRequests requestType='lapse' />
        <PolicyUpdateRequests requestType='expire' />
        <PolicyUpdateRequests requestType='not_taken_up' />
      </div>
    );
  }
}
