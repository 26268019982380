import ajax from '../../helpers/ajax';

export const initUser = (userId) => {
  return (dispatch) => {
    return ajax({
      dispatch,
      type: 'POST',
      path: '/admin/init-user',
      data: { user_id: userId },
    });
  };
};

export const lockUser = (userId) => {
  return (dispatch) => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/lock-user',
      data: { user_id: userId },
    }).then((result) => {
      dispatch({ type: 'LOCKED_USER', userId });
    });
  };
};

export const unlockUser = (userId) => {
  return (dispatch) => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/unlock-user',
      data: { user_id: userId },
    }).then((result) => {
      dispatch({ type: 'UNLOCKED_USER', userId });
    });
  };
};

export const disableAppOTP = (userId) => {
  return (dispatch) => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/disable-app-otp',
      data: { user_id: userId },
    }).then((result) => {
      dispatch({ type: 'DISABLED_APP_TOTP', userId });
    });
  };
};

export const updateUsers = (users) => ({ type: 'SET_USERS', users });

export const updateUser = (userId, firstName, lastName, email, cellphone) => ({
  type: 'UPDATE_USER',
  userId,
  firstName,
  lastName,
  email,
  cellphone,
});
