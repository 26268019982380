import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import { toSentenceCase, getRandValue } from '../../helpers';
import { get, renderValue } from '../helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    const env = runtimeEnv();
    const accountUrl =
      env.REACT_APP_COLLECT_HOST + '/accounts/' + this.props.params.accountId;
    const account = await get(accountUrl);

    const requestsUrl = accountUrl + '/requests';
    const requests = await get(requestsUrl);
    this.setState({ account, requests });
  }

  renderAccount() {
    return (
      <Card>
        <CardHeader>Account</CardHeader>
        <CardBody>
          {this.state.account ? (
            <Table size='sm'>
              <tbody>
                {Object.keys(this.state.account).map(k => (
                  <tr key={k}>
                    <td>
                      <b>{toSentenceCase(k)}</b>
                    </td>
                    <td>{renderValue(k, this.state.account[k])}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <i>Loading account...</i>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  renderRequests() {
    return (
      <Card>
        <CardHeader>
          Requests
          {/* <Button color='primary' className='pull-right'>
          <Icon name='plus' /> New Account
        </Button> */}
        </CardHeader>
        <CardBody>
          {this.state.requests ? (
            <Table size='sm' hover>
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Reference</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.state.requests.map(r => (
                  <tr
                    key={r.requestId}
                    onClick={() =>
                      this.props.router.push('/collect/requests/' + r.requestId)
                    }
                  >
                    <td>{moment(r.createdAt).format('DD MMMM YYYY, hh:mm')}</td>
                    <td>{r.reference}</td>
                    <td>{getRandValue(r.amount)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <i>Loading requests...</i>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <div>
        {this.renderAccount()}
        <br />
        {this.renderRequests()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(Accounts);
