import React, { Component } from 'react';
import {
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  ModalHeader,
} from 'reactstrap';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-night.css';
import { FullScreenModal } from '../../../../components/modals/full-screen';
import TemplatePreview from '../../template-preview';
import { Icon18PXCog } from '../../../../components/icons/icon-18-px-cog';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import LoadingInPage from '../../../loading';
import { GeneralModal, MergeVarsModal } from '../modals';
import {
  htmlToPdf,
  establishState,
} from './orchestration';
import CodeWindow from '../../../utils/code-editor/code-editor';
import { DRAFT_ID } from './constants';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../stores/product-module-store';
import { ProductModule } from '../../../domain/product-module';
import '../../../styles/styles.scss';
import { mergeVariableStubs } from './merge-variable-stubs';
import { ProductModuleDefinitionDocumentIndexStructure } from '../product-module-documentation/orchestration';

var debouncer: any;

const getMergeVariablesForDocumentType = (
  docType: ProductModuleDefinitionDocumentIndexStructure,
) => {
  switch (docType) {
    case ProductModuleDefinitionDocumentIndexStructure.MemberCertificateId:
      return mergeVariableStubs.memberCertificate;
    case ProductModuleDefinitionDocumentIndexStructure.ScheduleId:
      return mergeVariableStubs.schedule;
    case ProductModuleDefinitionDocumentIndexStructure.WelcomeLetterId:
      return mergeVariableStubs.welcomeLetter;
    case ProductModuleDefinitionDocumentIndexStructure.QuoteSummaryId:
      return mergeVariableStubs.quoteSummary;
    case ProductModuleDefinitionDocumentIndexStructure.PolicyAnniversaryId:
      return mergeVariableStubs.policyAnniversary;
    default:
      return {};
  }
};

interface Props {
  productModuleStore?: ProductModuleStore;
  docType: ProductModuleDefinitionDocumentIndexStructure;
  toDbKey: string;
  productModuleKey: string;
  breadcrumbDocName: string;
  closeDocument: () => any;
}

interface ProductModuleHtmlDoc {
  modalContent: {
    title: string;
    body: string;
    submit: string;
  };
}

interface State {
  editorKey: number;
  contentChanged: boolean;
  showResetToPublished: boolean;
  showSaveModal: boolean;
  loadingHtml: boolean;
  channel: string;
  templateHTML: string;
  templateHtmlLoading: boolean;
  templateBase64: string;
  showArchiveModal: boolean;
  processingDocument: boolean;
  mergeVars: object;
  showMergeVars: boolean;
  testMergeVars: boolean;
  mergeVarsEditorKey: number;
  templateHTMLOriginal: string;
  productModule?: ProductModule;
}

@inject('productModuleStore')
@observer
class ProductModuleHtmlDoc extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      editorKey: 0,
      contentChanged: false,
      showResetToPublished: true,
      showSaveModal: false,
      loadingHtml: true,
      channel: 'base64',
      templateHTML: '',
      templateHTMLOriginal: '',
      templateHtmlLoading: true,
      templateBase64: '',
      showArchiveModal: false,
      processingDocument: false,
      mergeVars: getMergeVariablesForDocumentType(props.docType),
      showMergeVars: false,
      testMergeVars: false,
      mergeVarsEditorKey: 0,
    };

    this.modalContent = {
      title: 'Schema Saved',
      body: 'Your schema has successfully been saved',
      submit: 'Ok',
    };
  }

  handleCodeChange = async (code: any) => {
    this.setState({ loadingHtml: true });
    if (DRAFT_ID === DRAFT_ID) {
      //fix this
      if (code !== this.state.templateHTMLOriginal) {
        if (!this.state.contentChanged) {
          this.setState({ contentChanged: true });
        }
      } else {
        if (this.state.contentChanged) {
          this.setState({ contentChanged: false });
        }
      }

      if (typeof debouncer !== 'undefined') {
        clearTimeout(debouncer);
      }
      this.setState({ processingDocument: true });
      debouncer = setTimeout(
        async () =>
          this.setState(
            await htmlToPdf(
              code,
              this.state.testMergeVars,
              this.state.mergeVars,
            ),
          ),
        3000,
      );
    } else {
      const editorKey = this.state.editorKey + 1;
      const mergeVarsEditorKey = this.state.mergeVarsEditorKey + 1;
      this.setState({
        templateHTML: this.state.templateHTML,
        editorKey,
        mergeVarsEditorKey,
      });
    }
  };

  async componentDidMount() {
    const { productModuleStore, docType, productModuleKey } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);

      const productModuleDefinition =
        productModuleStore.productModuleDefinitionDraft;

      const state = await establishState(productModuleDefinition, docType);

      if (state) {
        const htmlToPdfData = await htmlToPdf(
          state.templateHTML,
          this.state.testMergeVars,
          this.state.mergeVars,
        );

        if (htmlToPdfData) {
          this.setState(
            {
              templateHTML: htmlToPdfData.templateHTML,
              templateHTMLOriginal: state.templateHTML,
              loadingHtml: false,
              processingDocument: false,
              templateBase64: htmlToPdfData.templateBase64,
            },
            () =>
              this.setState({
                editorKey: this.state.editorKey + 1,
                templateHtmlLoading: false,
              }),
          );
        } else {
          this.setState({
            templateHTML: '<div></div>',
            templateHTMLOriginal: '<div></div>',
            loadingHtml: false,
            processingDocument: false,
            templateBase64: '',
          });
        }
      }
    }
  }

  // revertDraft = async () => {
  //   const productDefinitionResponse = getProductModuleDefinition(
  //     this.state.productModule.product_module_id,
  //     this.state.productModule.live_id,
  //   );

  //   const productModuleHtmlDoc = await getProductModuleDefinitionDocuments(
  //     this.state.productModule.product_module_id,
  //     this.state.productModule.live_id,
  //     productDefinitionResponse[this.props.route.propsData.docType],
  //   );

  //   this.setState(
  //     {
  //       templateHTML: productModuleHtmlDoc.html,
  //       templateHtmlOriginal: productModuleHtmlDoc.html,
  //     },
  //     async () => {
  //       this.saveDraftMethod();
  //       const editorkey = this.state.editorKey + 1;
  //       this.setState({ editorkey });
  //       this.setState(
  //         await htmlToPdf(
  //           productModuleHtmlDoc.html,
  //           this.state.testMergeVars,
  //           this.state.mergeVars,
  //         ),
  //       );
  //       this.closeArchiveModal();
  //     },
  //   );
  // };

  closeArchiveModal = () => {
    this.setState({ showArchiveModal: false });
  };

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  submitModal = () => {
    this.closeSaveModal();
  };

  saveDraftMethod = async () => {
    const { productModuleStore, toDbKey } = this.props;
    if (productModuleStore) {
      const productModule = productModuleStore.productModule;

      const data = {
        [toDbKey]: this.state.templateHTML,
      };

      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModule.productModuleId,
        data,
      });

      this.setState({
        templateHTMLOriginal: this.state.templateHTML,
        showSaveModal: true,
      });
    }
  };

  toggle = () => {
    this.setState((prevState: any) => ({
      showMergeVars: !prevState.showMergeVars,
    }));
  };

  handleMergeVarsChange = async (data: any) => {
    this.setState({ mergeVars: data });
    if (this.state.testMergeVars) {
      this.handleCodeChange(this.state.templateHTML);
    }
  };

  clearMergeVarsRender = async () => {
    this.setState(
      await htmlToPdf(this.state.templateHTML, false, this.state.mergeVars),
    );
  };

  testMergeVars = () => {
    this.setState((prevState: any) => ({
      testMergeVars: !prevState.testMergeVars,
      loadingHtml: true,
    }));
    this.handleCodeChange(this.state.templateHTML);
    const mergeVarsEditorKey = this.state.mergeVarsEditorKey + 1;

    this.setState({ mergeVarsEditorKey });
  };

  get mergeVarsButton() {
    if (this.state.showMergeVars) {
      return <div style={{ display: 'inline-block' }}>Hide merge vars</div>;
    }
    return <div style={{ display: 'inline-block' }}>Show merge vars</div>;
  }

  get mergeVarsStatus() {
    if (this.state.testMergeVars) {
      return 'Merge on';
    }
    return 'Merge off';
  }

  handleCodeChangeTest = async (code: string) => {
    this.setState({ loadingHtml: true });
    if (DRAFT_ID === DRAFT_ID) {
      if (code !== this.state.templateHTMLOriginal) {
        if (!this.state.contentChanged) {
          this.setState({ contentChanged: true });
        }
      } else {
        if (this.state.contentChanged) {
          this.setState({ contentChanged: false });
        }
      }

      if (typeof debouncer !== 'undefined') {
        clearTimeout(debouncer);
      }
      this.setState({ processingDocument: true });
      debouncer = setTimeout(
        async () =>
          this.setState(
            await htmlToPdf(
              code,
              this.state.testMergeVars,
              this.state.mergeVars,
            ),
          ),
        3000,
      );
    } else {
      // const editorkey = this.state.editorKey + 1;
      // const mergeVarsEditorKey = this.state.mergeVarsEditorKey + 1;
      // this.setState({
      //   templateHTML: this.state.templateHTML,
      //   editorkey,
      //   mergeVarsEditorKey,
      // });
    }
  };

  get displaySaveDraft() {
    // return (
    //   this.isEditing &&
    //   this.stage === DRAFT &&
    //   this.state.contentChanged &&
    //   !this.state.showSaveModal &&
    //   !this.state.processingDocument
    // );
    return true;
  }

  get displayRevert() {
    // return (
    //   this.state.productModule.live_id &&
    //   this.stage === DRAFT &&
    //   !this.state.processingDocument &&
    //   this.state.showResetToPublished
    // );
    return false;
  }

  render() {
    const {
      productModuleStore,
      breadcrumbDocName,
      productModuleKey,
    } = this.props;
    if (!productModuleStore || !productModuleStore.isLoaded) {
      return <LoadingInPage />;
    }
    const lockedComponent = productModuleStore.lockedComponent;

    return (
      <FullScreenModal isOpen={true}>
        {/* <RevertDraftModal
            show={this.state.showArchiveModal}
            onClose={this.closeArchiveModal}
            revertDraft={this.revertDraft}
          /> */}
        <GeneralModal
          show={this.state.showSaveModal}
          onclose={this.closeSaveModal}
          submitModal={this.submitModal}
          modalContent={this.modalContent}
        />
        <ModalHeader style={{ paddingBottom: 19 }}>
          <div
            className='Rectangle'
            style={{ display: 'inline-block', paddingTop: 9 }}
          >
            <a
              onClick={e => {
                e.preventDefault;
                this.props.closeDocument();
              }}
            >
              <Icon24PXCross />
            </a>
          </div>
          <div style={{ display: 'inline-block', paddingTop: 17 }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href='/product-modules'>
                  Product modules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  href={`/product-modules/${productModuleKey}`}
                >
                  {productModuleStore.productModule.name}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  onClick={e => {
                    e.preventDefault;
                    this.props.closeDocument();
                  }}
                >
                  Product definition
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>{breadcrumbDocName}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div
            style={{
              paddingLeft: 10,
              float: 'right',
              paddingTop: 17,
              paddingRight: 8,
            }}
          />
        </ModalHeader>
        <ModalBody className='no-padding' style={{ padding: 0 }}>
          <div className='template-editor' onKeyDown={(event) => {
            if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
              event.preventDefault();
              this.setState({ showSaveModal: true }, () =>
                this.saveDraftMethod());
            }
          }}>
            {!this.state.templateHtmlLoading && (
              <CodeWindow
                defaultValue={this.state.templateHTML.toString()}
                language='html'
                handleCodeChange={(code: any) =>
                  this.handleCodeChangeTest(code)
                }
              />
            )}
            <div
              className='template-editor template-preview'
              style={{ justifyContent: 'center', overflow: 'hidden' }}
            >
              <div
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  position: 'relative',
                }}
              >
                <div className='merge-vars-bar-menu'>
                  <Form>
                    <FormGroup row>
                      <div
                        style={{
                          display: 'inline-block',
                          marginRight: 0,
                          zIndex: 2,
                        }}
                      >
                        <Button
                          style={{ width: 148 }}
                          color='secondary'
                          outline
                          className='merge-vars-button'
                          onClick={() => this.toggle()}
                        >
                          {this.mergeVarsButton}
                        </Button>
                      </div>
                      <div
                        style={{ flex: 1, paddingTop: 8, textAlign: 'right' }}
                      >
                        {this.state.showMergeVars && (
                          <div style={{ display: 'inline-block', width: 200 }}>
                            <Input
                              type='select'
                              name='select'
                              id='exampleSelect'
                            >
                              <option>Default</option>
                            </Input>
                          </div>
                        )}
                      </div>
                      {this.state.showMergeVars && (
                        <div style={{ display: 'inline-block' }}>
                          <Button
                            color='primary'
                            outline
                            style={{
                              backgroundColor: 'rgb(238,238,238)',
                              width: 100,
                            }}
                            onClick={() => {
                              this.handleMergeVarsChange(
                                getMergeVariablesForDocumentType(
                                  this.props.docType,
                                ),
                              );
                              const mergeVarsEditorKey =
                                this.state.mergeVarsEditorKey + 1;
                              this.setState({ mergeVarsEditorKey });
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      )}
                      {!this.state.showMergeVars && (
                        <div
                          style={{
                            display: 'inline-block',
                            marginRight: 0,
                            zIndex: 2,
                          }}
                        >
                          <Button
                            style={{ width: 80 }}
                            color='secondary'
                            outline
                            className='merge-vars-button'
                            onClick={() => {
                              this.testMergeVars();
                            }}
                          >
                            {this.mergeVarsStatus}
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </Form>
                </div>
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                  {this.state.loadingHtml && <LoadingInPage />}
                  {this.state.showMergeVars && (
                    <MergeVarsModal
                      editorKey={this.state.mergeVarsEditorKey}
                      toggle={() => this.toggle()}
                      mergeVars={this.state.mergeVars}
                      handleMergeVarsChange={(data: any) =>
                        this.handleMergeVarsChange(data)
                      }
                      reload={false}
                    />
                  )}
                </div>
                {!this.state.showMergeVars && (
                  <TemplatePreview
                    content={this.state.templateBase64}
                    channel={this.state.channel}
                    documentName={this.props.productModuleKey}
                    toggle={() => this.toggle()}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {
            <div className='full-icon'>
              <Icon18PXCog />
            </div>
          }

          <div className='text'>
            {breadcrumbDocName}
            {/* {<small>Edited a few seconds ago</small> // {this.lastEditedDate} */}
          </div>
          {
            <div className='right-content'>
              {this.displayRevert && (
                <div
                  onClick={() => this.setState({ showArchiveModal: true })}
                  style={{ color: '#2259f9', cursor: 'pointer' }}
                  className='text'
                >
                  Revert to published version
                </div>
              )}
              {
                <Button
                  disabled={lockedComponent}
                  color='secondary'
                  onClick={async () => this.saveDraftMethod()}
                >
                  Save draft
                </Button>
              }
            </div>
          }
        </ModalFooter>
      </FullScreenModal>
    );
  }
}

export default ProductModuleHtmlDoc;
