import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col, Alert,
} from 'reactstrap';
import { addUserToOrganizationRole } from './team-members-api';
import { RolesList } from '../roles/components/roles-list';
import { useRolesList } from '../roles/hooks/use-roles-list';

export const EditUserModal = ({ show, onClose, user, organizationId }) => {
  const [selectedRoleId, setSelectedRoleId] = useState(user.organizationRoleId);
  const [error, setError] = useState('');

  const {roles, error: rolesListError} = useRolesList(organizationId);

  useEffect(()=> {
    setError(rolesListError);
  }, [rolesListError]);

  const isSubmitButtonDisabled = () => !selectedRoleId || user.organizationRoleId === selectedRoleId;

  const roleChangeHandler = value => setSelectedRoleId(value);

  const submitHandler = async () => {
    try {
      if (!window.confirm(`Are you sure you want edit the roles assigned to ${user.firstName}?`)) {
        return false;
      }

      await addUserToOrganizationRole({organizationId, userId: user.userId, roleId: selectedRoleId});
      closeHandler();
    } catch(error) {
      setError(JSON.parse(error.message).message);
    }
  };

  const closeHandler = () => {
    setError('');
    setSelectedRoleId(null);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={closeHandler}>
        <ModalHeader toggle={closeHandler}>
          Edit {`${user.firstName} ${user.lastName}`}
        </ModalHeader>
        <ModalBody>
          {error && <Alert color='danger'>{error}</Alert>}
          <Row>
            <Col xs={12}>
              <h6 className='dialog'>Assign role</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{height: '500px', overflowY: 'scroll' }}>
              <RolesList roles={roles} onChange={roleChangeHandler} selectedRoleId={selectedRoleId} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={closeHandler}>Cancel</Button>
          <Button
            color='primary'
            onClick={submitHandler}
            disabled={isSubmitButtonDisabled()}
          >
            Update role
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

};
