export const momentImport = `
var moment: (inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean) => Moment;
interface Moment extends Object {
  format(format?: string): string;

  startOf(unitOfTime: unitOfTime.StartOf): Moment;
  endOf(unitOfTime: unitOfTime.StartOf): Moment;

  add(amount?: DurationInputArg1, unit?: DurationInputArg2): Moment;
  /**
   * @deprecated reverse syntax
   */
  add(unit: unitOfTime.DurationConstructor, amount: number|string): Moment;

  subtract(amount?: DurationInputArg1, unit?: DurationInputArg2): Moment;
  /**
   * @deprecated reverse syntax
   */
  subtract(unit: unitOfTime.DurationConstructor, amount: number|string): Moment;

  calendar(time?: MomentInput, formats?: CalendarSpec): string;

  clone(): Moment;

  /**
   * @return Unix timestamp in milliseconds
   */
  valueOf(): number;

  // current date/time in local mode
  local(keepLocalTime?: boolean): Moment;
  isLocal(): boolean;

  // current date/time in UTC mode
  utc(keepLocalTime?: boolean): Moment;
  isUTC(): boolean;
  /**
   * @deprecated use isUTC
   */
  isUtc(): boolean;

  parseZone(): Moment;
  isValid(): boolean;
  invalidAt(): number;

  hasAlignedHourOffset(other?: MomentInput): boolean;

  creationData(): MomentCreationData;
  parsingFlags(): MomentParsingFlags;

  year(y: number): Moment;
  year(): number;
  /**
   * @deprecated use year(y)
   */
  years(y: number): Moment;
  /**
   * @deprecated use year()
   */
  years(): number;
  quarter(): number;
  quarter(q: number): Moment;
  quarters(): number;
  quarters(q: number): Moment;
  month(M: number|string): Moment;
  month(): number;
  /**
   * @deprecated use month(M)
   */
  months(M: number|string): Moment;
  /**
   * @deprecated use month()
   */
  months(): number;
  day(d: number|string): Moment;
  day(): number;
  days(d: number|string): Moment;
  days(): number;
  date(d: number): Moment;
  date(): number;
  /**
   * @deprecated use date(d)
   */
  dates(d: number): Moment;
  /**
   * @deprecated use date()
   */
  dates(): number;
  hour(h: number): Moment;
  hour(): number;
  hours(h: number): Moment;
  hours(): number;
  minute(m: number): Moment;
  minute(): number;
  minutes(m: number): Moment;
  minutes(): number;
  second(s: number): Moment;
  second(): number;
  seconds(s: number): Moment;
  seconds(): number;
  millisecond(ms: number): Moment;
  millisecond(): number;
  milliseconds(ms: number): Moment;
  milliseconds(): number;
  weekday(): number;
  weekday(d: number): Moment;
  isoWeekday(): number;
  isoWeekday(d: number|string): Moment;
  weekYear(): number;
  weekYear(d: number): Moment;
  isoWeekYear(): number;
  isoWeekYear(d: number): Moment;
  week(): number;
  week(d: number): Moment;
  weeks(): number;
  weeks(d: number): Moment;
  isoWeek(): number;
  isoWeek(d: number): Moment;
  isoWeeks(): number;
  isoWeeks(d: number): Moment;
  weeksInYear(): number;
  isoWeeksInYear(): number;
  dayOfYear(): number;
  dayOfYear(d: number): Moment;

  from(inp: MomentInput, suffix?: boolean): string;
  to(inp: MomentInput, suffix?: boolean): string;
  fromNow(withoutSuffix?: boolean): string;
  toNow(withoutPrefix?: boolean): string;

  diff(b: MomentInput, unitOfTime?: unitOfTime.Diff, precise?: boolean): number;

  toArray(): number[];
  toDate(): Date;
  toISOString(keepOffset?: boolean): string;
  inspect(): string;
  toJSON(): string;
  unix(): number;

  isLeapYear(): boolean;
  /**
   * @deprecated in favor of utcOffset
   */
  zone(): number;
  zone(b: number|string): Moment;
  utcOffset(): number;
  utcOffset(b: number|string, keepLocalTime?: boolean): Moment;
  isUtcOffset(): boolean;
  daysInMonth(): number;
  isDST(): boolean;

  zoneAbbr(): string;
  zoneName(): string;

  isBefore(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
  isAfter(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
  isSame(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
  isSameOrAfter(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
  isSameOrBefore(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
  isBetween(a: MomentInput, b: MomentInput, granularity?: unitOfTime.StartOf, inclusivity?: "()" | "[)" | "(]" | "[]"): boolean;

  /**
   * @deprecated as of 2.8.0, use locale
   */
  lang(language: LocaleSpecifier): Moment;
  /**
   * @deprecated as of 2.8.0, use locale
   */
  lang(): Locale;

  locale(): string;
  locale(locale: LocaleSpecifier): Moment;

  localeData(): Locale;

  /**
   * @deprecated no reliable implementation
   */
  isDSTShifted(): boolean;

  // NOTE(constructor): Same as moment constructor
  /**
   * @deprecated as of 2.7.0, use moment.min/max
   */
  max(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
  /**
   * @deprecated as of 2.7.0, use moment.min/max
   */
  max(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

  // NOTE(constructor): Same as moment constructor
  /**
   * @deprecated as of 2.7.0, use moment.min/max
   */
  min(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
  /**
   * @deprecated as of 2.7.0, use moment.min/max
   */
  min(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

  get(unit: unitOfTime.All): number;
  set(unit: unitOfTime.All, value: number): Moment;
  set(objectLiteral: MomentSetObject): Moment;

  toObject(): MomentObjectOutput;
};
`;