export const productModuleAlterationPackage = `
declare class AlterationPackage {
  sum_assured: number;
  monthly_premium: number;
  change_description: string;
  module: object;
  input_data: { [key: string]: any };

  constructor(init: AlterationPackage) {
    Object.assign(this, init);
  };
}
`;
