import React from 'react';
import { observer } from 'mobx-react';
import { BlockType } from '../domain/block-type';
import { DividerBlock as DividerBlockSpec } from '../domain/divider-block';
import { DividerBlockState } from '../domain/states/divider-block-state';
import { DividerBlock } from './divider-block';
import { AlertBlock as AlertBlockSpec } from '../domain/alert-block';
import { AlertBlockState } from '../domain/states/alert-block-state';
import { AlertBlock } from './alert-block';
import { HeadingBlock as HeadingBlockSpec } from '../domain/heading-block';
import { HeadingBlockState } from '../domain/states/heading-block-state';
import { HeadingBlock } from './heading-block';
import { MarkdownBlock as MarkdownBlockSpec } from '../domain/markdown-block';
import { MarkdownBlockState } from '../domain/states/markdown-block-state';
import { MarkdownBlock } from './markdown-block';
import { InputTextBlock as InputTextBlockSpec } from '../domain/input-text-block';
import { InputTextBlockState } from '../domain/states/input-text-block-state';
import { InputTextBlock } from './input-text-block';
import { InputNumberBlock as InputNumberBlockSpec } from '../domain/input-number-block';
import { InputNumberBlockState } from '../domain/states/input-number-block-state';
import { InputNumberBlock } from './input-number-block';
import { InputTimeBlock as InputTimeBlockSpec } from '../domain/input-time-block';
import { InputTimeBlockState } from '../domain/states/input-time-block-state';
import { InputTimeBlock } from './input-time-block';
import { InputDateBlock as InputDateBlockSpec } from '../domain/input-date-block';
import { InputDateBlockState } from '../domain/states/input-date-block-state';
import { InputDateBlock } from './input-date-block';
import { InputCurrencyBlock as InputCurrencyBlockSpec } from '../domain/input-currency-block';
import { InputCurrencyBlockState } from '../domain/states/input-currency-block-state';
import { InputCurrencyBlock } from './input-currency-block';
import { CheckboxBlock as CheckboxBlockSpec } from '../domain/checkbox-block';
import { InputParagraphBlock as InputParagraphBlockSpec } from '../domain/input-paragraph-block';
import { InputParagraphBlockState } from '../domain/states/input-paragraph-block-state';
import { InputParagraphBlock } from './input-paragraph-block';
import { CheckboxBlockState } from '../domain/states/checkbox-block-state';
import { CheckboxBlock } from './checkbox-block';
import { DropdownBlock as DropdownBlockSpec } from '../domain/dropdown-block';
import { DropdownBlockState } from '../domain/states/dropdown-block-state';
import { DropdownBlock } from './dropdown-block';
import { RadioBlock as RadioBlockSpec } from '../domain/radio-block';
import { RadioBlockState } from '../domain/states/radio-block-state';
import { RadioBlock } from './radio-block';
import { AnnuityRequestBlock as AnnuityRequestBlockSpec } from '../domain/annuity-request-block';
import { AnnuityRequestBlock } from './annuity-request-block';
import { AnnuityRequestBlockState } from '../domain/states/annuity-request-block-state';
import { PayoutRequestBlock as PayoutRequestBlockSpec } from '../domain/payout-request-block';
import { PayoutRequestBlock } from './payout-request-block';
import { PayoutRequestBlockState } from '../domain/states/payout-request-block-state';
import { FulfillmentRequestBlock as FulfillmentRequestBlockSpec } from '../domain/fulfillment-request-block';
import { FulfillmentRequestBlock } from './fulfillment-request-block';
import { FulfillmentRequestBlockState } from '../domain/states/fulfillment-request-block-state';
import { BlockSchema } from '../domain/block-schema';
import { Form, FormGroup } from 'reactstrap';
import { BlockStore } from '../stores/block-store';
import { GroupBlock } from './group-block';
import { GroupBlock as GroupBlockSpec } from '../domain/group-block';
import { GroupBlockState } from '../domain/states/group-block-state';

interface Props {
  blockStore: BlockStore;
}

interface RenderBlockProps {
  blockSchema: BlockSchema;
  claimBlocksStates: {
    [key: string]:
    | InputTextBlockState
    | InputNumberBlockState
    | InputTimeBlockState
    | InputCurrencyBlockState
    | CheckboxBlockState
    | PayoutRequestBlockState
    | FulfillmentRequestBlockState
    | AnnuityRequestBlockState
    | DropdownBlockState
    | HeadingBlockState
    | DividerBlockState
    | AlertBlockState
    | InputParagraphBlockState
    | RadioBlockState
    | MarkdownBlockState
    | InputDateBlockState
    | GroupBlockState;
  };
}

export const ClaimBlocks = observer(({ blockStore }: Props) => {
  const { blocksSchema, blocksStates } = blockStore;

  if (!blocksSchema || !blocksStates) {
    return null;
  }

  return (
    <Form style={{ marginTop: '36px' }}>
      {blocksSchema.map((blockSchema) => (
        <FormGroup key={blockSchema.block.key}>
          {renderBlock({
            blockSchema: blockSchema,
            claimBlocksStates: blocksStates,
          })}
        </FormGroup>
      ))}
    </Form>
  );
});

//// Helpers
const renderBlock = ({ blockSchema, claimBlocksStates }: RenderBlockProps) => {
  const { block, showIf, hideIf, requiredIf, disabledIf } = blockSchema;
  const blockState = claimBlocksStates[blockSchema.block.key];

  if (
    !(
      blockState !== undefined &&
      (showIf === undefined || showIf) &&
      (hideIf === undefined || !hideIf)
    )
  ) {
    return null;
  }

  switch (block.type) {
    case BlockType.InputText:
      return (
        <InputTextBlock
          key={block.key}
          block={InputTextBlockSpec.fromJSON(block)}
          blockState={blockState as InputTextBlockState}
          disabled={disabledIf}
          required={requiredIf}
        />
      );
    case BlockType.InputNumber:
      return (
        <InputNumberBlock
          key={block.key}
          block={InputNumberBlockSpec.fromJSON(block)}
          blockState={blockState as InputNumberBlockState}
          disabled={disabledIf}
          required={requiredIf}
        />
      );
    case BlockType.InputTime:
      return (
        <InputTimeBlock
          key={block.key}
          block={InputTimeBlockSpec.fromJSON(block)}
          blockState={blockState as InputTimeBlockState}
          disabled={disabledIf}
          required={requiredIf}
        />
      );
    case BlockType.InputDate:
      return (
        <InputDateBlock
          key={block.key}
          block={InputDateBlockSpec.fromJSON(block)}
          blockState={blockState as InputDateBlockState}
          disabled={disabledIf}
          required={requiredIf}
        />
      );
    case BlockType.InputParagraph:
      return (
        <InputParagraphBlock
          key={block.key}
          block={InputParagraphBlockSpec.fromJSON(block)}
          blockState={blockState as InputParagraphBlockState}
          disabled={disabledIf}
          required={requiredIf}
        />
      );
    case BlockType.PayoutRequest:
      return (
        <PayoutRequestBlock
          disabled={disabledIf}
          key={block.key}
          block={PayoutRequestBlockSpec.fromJSON(block)}
          blockState={blockState as PayoutRequestBlockState}
        />
      );
    case BlockType.FulfillmentRequest:
      return (
        <FulfillmentRequestBlock
          disabled={disabledIf}
          key={block.key}
          block={FulfillmentRequestBlockSpec.fromJSON(block)}
          blockState={blockState as FulfillmentRequestBlockState}
        />
      );
    case BlockType.AnnuityRequest:
      return (
        <AnnuityRequestBlock
          disabled={disabledIf}
          key={block.key}
          block={AnnuityRequestBlockSpec.fromJSON(block)}
          blockState={blockState as AnnuityRequestBlockState}
        />
      );
    case BlockType.InputCurrency:
      return (
        <InputCurrencyBlock
          key={block.key}
          required={requiredIf}
          disabled={disabledIf}
          block={block as InputCurrencyBlockSpec}
          blockState={blockState as InputCurrencyBlockState}
        />
      );
    case BlockType.Checkbox:
      return (
        <CheckboxBlock
          key={block.key}
          required={requiredIf}
          disabled={disabledIf}
          block={block as CheckboxBlockSpec}
          blockState={blockState as CheckboxBlockState}
        />
      );
    case BlockType.Dropdown:
      return (
        <DropdownBlock
          key={block.key}
          required={requiredIf}
          disabled={disabledIf}
          block={DropdownBlockSpec.fromJSON(block)}
          blockState={blockState as DropdownBlockState}
        />
      );
    case BlockType.Radio:
      return (
        <RadioBlock
          key={block.key}
          required={requiredIf}
          disabled={disabledIf}
          block={RadioBlockSpec.fromJSON(block)}
          blockState={blockState as RadioBlockState}
        />
      );
    case BlockType.Heading:
      return (
        <HeadingBlock
          key={block.key}
          block={block as HeadingBlockSpec}
          blockState={blockState as HeadingBlockState}
        />
      );
    case BlockType.Markdown:
      return (
        <MarkdownBlock
          key={block.key}
          block={block as MarkdownBlockSpec}
          blockState={blockState as MarkdownBlockState}
        />
      );
    case BlockType.Divider:
      return (
        <DividerBlock
          key={block.key}
          block={block as DividerBlockSpec}
          blockState={blockState as DividerBlockState}
        />
      );
    case BlockType.Alert:
      return (
        <AlertBlock
          key={block.key}
          block={block as AlertBlockSpec}
          blockState={blockState as AlertBlockState}
        />
      );
    case BlockType.Group: {
      const groupBlockSpec = block as GroupBlockSpec;
      const groupBlockState = blockState as GroupBlockState;
      const childBlocks =
        groupBlockSpec.blocks &&
        groupBlockSpec.blocks.map((b: BlockSchema) =>
          renderBlock({ blockSchema: b, claimBlocksStates }),
        );

      return (
        <GroupBlock
          key={block.key}
          block={groupBlockSpec}
          blockState={groupBlockState}
          childBlocks={childBlocks}
        />
      );
    }
  }
};
