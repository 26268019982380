import React from 'react';
import { connect } from 'react-redux';
import { Container, Button } from 'reactstrap';
import Loading from './components/full-loading';
import { logout } from './account/actions/logout';
import Sidebar from './components/sidebar/sidebar';

class App extends React.Component {
  state = {
    error: '',
  };

  componentDidCatch(error, info) {
    console.log('DIDCATCH', error, info);

    this.setState({ error: error.message });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.error && prevState.error === this.state.error) {
      console.log(prevState, this.state);
      this.setState({ error: '' });
    }
  }

  logout = () => {
    this.props
      .dispatch(logout())
      .catch(e => e)
      .then(r => this.props.router.push('/login'));
  };

  renderError = message => (
    <div>
      <p>Oops! It seems like something went wrong</p>
      <p>Please refresh the page.</p>
      <p>If the issue persists, sign out and sign in again...</p>
      <Button onClick={this.logout}>Sign out</Button>
    </div>
  );

  renderApp() {
    return (
      <div style={{ height: '100vh' }}>
        <div className='wrapper'>
          <Sidebar {...this.props} />
          <main className='content pt-4'>
            <Container fluid style={{ minHeight: '100%', paddingLeft: 0 }}>
              {this.state.error
                ? this.renderError(this.state.error)
                : this.props.children}
            </Container>
          </main>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.state === 'loading') {
      return <Loading />;
    } else if (this.props.state === 'complete') {
      return this.renderApp();
    } else {
      return this.renderError(this.state.error);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const account = state.account; // { complete: false, loading: false, error: false }
  if (account.complete) {
    return { state: 'complete', alerts: state.alerts, user: state.user };
  }
  if (account.loading) {
    return { state: 'loading', alerts: state.alerts, user: state.user };
  }
  return { state: undefined, alerts: state.alerts, user: state.user };
};

export default connect(mapStateToProps)(App);
