import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  settingsToExtract,
  TypesOfSetupConditions,
} from '../config/product-module-definition-settings-set-up';
import '../../../../../styles/styles.scss';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import LoadingInPage from '../../../../../loading';
import ProductModuleSettingsTextInput from '../text-input';
import ProductModuleSettingsSelect from '../select-input';
import ProductModuleSettingsCheckbox from '../checkbox';
import { ComponentType } from '../../product-module-definitions-billing/components/billing';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  productModuleKey: string;
}

interface SelectOptions {
  label: string;
  value: string;
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfSetupConditions;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
  options?: any;
  multipleSelect?: boolean;
}

interface State {}

@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
class ProductModuleDefinitionSettingsSetUp extends React.Component<
  Props,
  State
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      module: {
        name: '',
      },
    };
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(settingsToExtract).forEach((checkboxDescription) => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleSettingsComponents,
          checkboxDescription,
        );
      });
    }
  }

  defaultCheckboxValue = (params: Params) => {
    const { path, displayCheckValue } = params;

    const { productModuleStore } = this.props;
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        return !!settingsItem.find((val) => val === displayCheckValue);
      }
      return settingsItem === displayCheckValue;
    }
  };

  renderSetupSettings = (params: Params, componentType: ComponentType) => {
    if (componentType === ComponentType.Checkbox) {
      return <ProductModuleSettingsCheckbox params={params} />;
    }

    if (componentType === ComponentType.Select) {
      return <ProductModuleSettingsSelect params={params} />;
    }

    if (
      componentType === ComponentType.Text ||
      componentType === ComponentType.Number
    ) {
      return (
        <ProductModuleSettingsTextInput
          params={params}
          componentType={componentType}
        />
      );
    }
  };

  render() {
    const { productModuleComponentLoadingStateStore, productModuleStore } =
      this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row style={{ marginBottom: 0, paddingBottom: 20 }}>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>Set up</p>
            </Col>
            <Col xs={9} style={{ paddingBottom: 23 }}>
              <span>
                These basic settings were defined on creation of the product
                module and may not be changed once the product module has been
                published.
              </span>
            </Col>
          </Row>
          {this.renderSetupSettings(
            {
              ...settingsToExtract.policySchemeType,
              lockedComponent: productModuleStore.lockedComponent,
            },
            ComponentType.Select,
          )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.policyActivationEvent,
              lockedComponent: productModuleStore.lockedComponent,
            },
            ComponentType.Select,
          )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.coveredItemsEnabled,
              lockedComponent: productModuleStore.lockedComponent,
            },
            ComponentType.Checkbox,
          )}
          {this.defaultCheckboxValue({
            ...settingsToExtract.coveredItemsEnabled,
            lockedComponent: productModuleStore.lockedComponent,
          }) &&
            this.renderSetupSettings(
              {
                ...settingsToExtract.coveredItemsMaxSumAssured,
                lockedComponent: productModuleStore.lockedComponent,
              },
              ComponentType.Number,
            )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.canIssuePolicyThroughDashboard,
              lockedComponent: productModuleStore.lockedComponent,
            },
            ComponentType.Checkbox,
          )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.canRequote,
              lockedComponent: productModuleStore.lockedComponent,
            },

            ComponentType.Checkbox,
          )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.canReactivate,
              lockedComponent: productModuleStore.lockedComponent,
            },

            ComponentType.Checkbox,
          )}
          {this.renderSetupSettings(
            {
              ...settingsToExtract.policyAnniversary,
              lockedComponent: productModuleStore.lockedComponent,
            },

            ComponentType.Checkbox,
          )}
          {this.defaultCheckboxValue({
            ...settingsToExtract.policyAnniversary,
            lockedComponent: productModuleStore.lockedComponent,
          }) &&
            this.renderSetupSettings(
              {
                ...settingsToExtract.policyAnniversaryDaysBeforeAnniversary,
                lockedComponent: productModuleStore.lockedComponent,
              },

              ComponentType.Number,
            )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}

export default ProductModuleDefinitionSettingsSetUp;
