import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Button from '../../../components/loading-button';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys, parseCSV } from '../../../helpers';
import { utc as moment } from 'moment';
import PaymentMethodsTable from '../payment-methods-table';
import { FileModal } from '../../../components/file-modal';
import { PaginationComponent } from '../../../components/pagination';

export default class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: null,
      loading: true,
      showFileModal: false,
      page: 0,
      pageSize: 20,
    };
  }

  componentDidMount() {
    this.fetchPaymentMethods();
  }

  downloadExport = async () => {
    const response = await ajax({
      type: 'GET',
      path: '/insurance/admin/payment-methods/pending-banv/csv',
      raw: true,
    });

    if (response.status === 204) {
      window.alert('No pending BANV checks.');
    } else if (response.status !== 200) {
      const body = await response.json();
      console.error(
        'Error downloading export:',
        body && JSON.stringify(body.error, null, 2),
      );
    } else {
      const blob = await response.blob();
      const uri = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const filename = `${moment().format('YYYY_MM_DD')}_BANV.csv`;

      element.setAttribute('href', uri);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  async fetchPaymentMethods() {
    this.setState({ loading: true });

    const queryString = 'type=debit_order&banv_status=pending&include=policyholder';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payment-methods?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const paymentMethods = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payment-methods?${queryString}${pagination}`,
    })).map(toCamelCaseKeys)
      .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

    this.setState({
      paymentMethods,
      loading: false,
      total: total.headers.get('X-Total-Count'),
    });
  }

  async markAsSubmitted(file) {
    const data = await parseCSV(file);
    const payments = data.map(e => ({
      payment_method_id: e['Customer Code'],
      banv_status: 'processing',
    }));

    await ajax({
      type: 'PATCH',
      path: '/insurance/admin/payment-methods',
      data: payments,
    });

    await this.fetchPaymentMethods();
  }

  closeModal() {
    console.log('close modal');
    this.setState({ showFileModal: false });
  }

  render() {
    const {
      paymentMethods,
      loading,
      page,
      pageSize,
      total,
      showFileModal,
    } = this.state;

    return (
      <div>
        <Card>
          <CardHeader>
            Pending BANV payment methods (
            {paymentMethods && paymentMethods.length})
            <div className='pull-right'>
              <Button
                color='primary'
                onClick={this.downloadExport}
                disabled={loading}
              >
                Download CSV
              </Button>{' '}
              <Button
                color='warning'
                onClick={() => this.setState({ showFileModal: true })}
                disabled={loading}
              >
                Mark batch file as submitted
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div>Loading...</div>
            ) : paymentMethods && paymentMethods.length > 0 ? (
              <div>
                <PaymentMethodsTable paymentMethods={paymentMethods} />
                <PaginationComponent
                  page={page || 0}
                  limit={pageSize}
                  total={total || 0}
                  disabled={false}
                  goToPage={page => {
                    this.setState(
                      {
                        page,
                      },
                      async () => await this.fetchPaymentMethods(),
                    );
                  }}
                />
              </div>
            ) : (
              <div>No payment methods</div>
            )}
          </CardBody>
          <FileModal
            open={showFileModal}
            onClose={this.closeModal.bind(this)}
            handleSubmit={this.markAsSubmitted.bind(this)}
            inputLabel='Select submitted batch file'
            confirmMessage={file =>
              `Are you sure CSV file '${
                file.name
              }' was submitted to Peach Payments?`
            }
            header='Mark BANV batch as submitted'
            description='Upload the CSV batch file submitted to Peach Payments to mark the BANV status as processing.'
            submitText='Mark as processing'
          />
        </Card>
      </div>
    );
  }
}
