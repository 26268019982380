import React from 'react';
import { Table, CardBody, Container, Button, Input } from 'reactstrap';
import PageHeader from '../../components/page-header';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';
import { NewProductModule } from './product-definition/modals';
import { alphabetically } from '../../helpers';
import {
  createProductModule,
  getProductModuleByKey,
} from './product-modules-api';
import { Icon16PXCopy } from '../../components/icons/icon-16-copy';
import { ProductModule, DbProductModule } from '../domain/product-module';
import { inject, observer } from 'mobx-react';
import { ProductModuleListStore } from './product-definition/stores/product-module-list-store';
import { debounce } from '../../helpers/debounce';
import { PaginationComponent } from '../../components/pagination';
import { OrganizationListStore, organizationListStore } from '../../organizations/stores/organization-list-store';

interface State {
  modules: ProductModule[];
  page: number;
  isLoadingCreateNewModule: boolean;
  pageSize: number;
  showNewProductModule: boolean;
  cloneModuleKey: string;
  errorModalText: string;
  loading: boolean;
}

interface Props {
  router: {
    push: (route: string) => void;
  };
  route: string;
}

interface Injected extends Props {
  productModuleListStore: ProductModuleListStore;
  organizationListStore: OrganizationListStore;
}
@inject(
  'productModuleListStore',
  'organizationListStore',
)
@observer
export default class ProductModules extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 1,
      isLoadingCreateNewModule: false,
      pageSize: 15,
      showNewProductModule: false,
      cloneModuleKey: '',
      errorModalText: '',
      modules: [],
      loading: false,
    };

    this.injected.productModuleListStore.setProductModuleListStoreIsLoading();
    this.injected.productModuleListStore.setProductModuleName('');
    this.injected.productModuleListStore.setPage(0);
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { productModuleListStore } = this.injected;

    await Promise.all([
      productModuleListStore.getProductModules(),
      organizationListStore.getAllOrganizations(),
    ]);
  }

  renderEditInput = () => (
    <div className='pull-right'>
      <Button
        color='primary'
        onClick={() => this.setState({ showNewProductModule: true })}
        style={{ paddingLeft: 20, paddingRight: 20 }}
        disabled={this.state.loading}
      >
        New
      </Button>
    </div>
  );

  closeNewProductModule = () => {
    this.setState({
      showNewProductModule: false,
      cloneModuleKey: '',
      errorModalText: '',
    });
  };

  newProductModule = async (data: DbProductModule) => {
    const { productModuleListStore } = this.injected;
    const productModules = productModuleListStore.productModules;
    this.setState({ isLoadingCreateNewModule: true });
    const existingKey = productModules.filter(
      moduleData => moduleData.key === data.key,
    );

    if (existingKey && existingKey.length > 0) {
      this.setState({
        errorModalText:
          'The key entered is already in use please enter another',
      });
    } else {
      try {
        let cloneFromProductModuleDefinitionId;
        if (this.state.cloneModuleKey) {
          this.setState({ errorModalText: '' });
          const productModule = await getProductModuleByKey(
            this.state.cloneModuleKey,
          );

          cloneFromProductModuleDefinitionId = productModule.liveId
            ? productModule.liveId.toString()
            : productModule.draftId.toString();
        }
        await createProductModule(data, cloneFromProductModuleDefinitionId);
        await productModuleListStore.getProductModules();
        this.setState({
          modules: productModuleListStore.productModules,
          isLoadingCreateNewModule: false,
        });
        this.closeNewProductModule();
      } catch (err) {
        this.setState({
          isLoadingCreateNewModule: false,
          errorModalText: err.toString(),
        });
      }
    }
  };

  render() {
    const { productModuleListStore } = this.injected;

    if (!productModuleListStore.productModules) {
      return <div>Loading...</div>;
    }

    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <NewProductModule
          show={this.state.showNewProductModule}
          isLoading={this.state.isLoadingCreateNewModule}
          onClose={this.closeNewProductModule}
          newProductModule={this.newProductModule}
          errorModalText={this.state.errorModalText}
          cloneModuleKey={this.state.cloneModuleKey}
        />
        <Container style={{ backgroundColor: 'white' }}>
          <div style={{ marginLeft: '1.5rem' }}>
            <PageHeader
              title={'Overview'}
              rightComponent={this.renderEditInput()}
            />
          </div>
          <CardBody>
            <div className='organizations-modal-search-input-wrapper'>
              <Input
                type='text'
                placeholder='search'
                onChange={e => {
                  productModuleListStore.setProductModuleName(e.target.value);
                  productModuleListStore.setPage(0);
                  debounce(
                    'filterByProductModuleName',
                    async () =>
                      await productModuleListStore.getProductModules(),
                    500,
                  );
                }}
              />
            </div>
            {this.renderLoaded()}
            <div className='product-modules-list' style={{ marginLeft: 5 }}>
              <PaginationComponent
                page={productModuleListStore.page}
                limit={10}
                total={productModuleListStore.totalProductModuleRowsCount || 0}
                disabled={false}
                goToPage={async page => {
                  productModuleListStore.setPage(page);
                  await productModuleListStore.getProductModules();
                }}
              />
            </div>
          </CardBody>
        </Container>
      </div>
    );
  }

  renderLoading() {
    if (!this.state.modules) {
      return <p>Loading...</p>;
    }

    return null;
  }

  renderLoaded() {
    const { productModuleListStore } = this.injected;
    const { productModules } = productModuleListStore;
    const sortedProductModules = productModules
      .slice()
      .sort(alphabetically('name'));

    return (
      <Table hover>
        <thead>
          <tr>
            <th style={{ fontWeight: 'bold' }}>Module name</th>
            <th style={{ fontWeight: 'bold' }}>Module type</th>
            <th style={{ fontWeight: 'bold' }}>Clone</th>
          </tr>
        </thead>
        <tbody>{sortedProductModules.map(o => this.renderRow(o))}</tbody>
      </Table>
    );
  }

  renderRow(mod: ProductModule) {
    return (
      <tr key={mod.key}>
        <td
          style={{ cursor: 'pointer' }}
          onClick={() => this.props.router.push('/product-modules/' + mod.key)}
        >
          {mod.name}
        </td>
        <td
          style={{ cursor: 'pointer' }}
          onClick={() => this.props.router.push('/product-modules/' + mod.key)}
          className='module-Type'
        >
          {mod.key}
        </td>
        <td
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.setState({
              showNewProductModule: true,
              cloneModuleKey: mod.key,
            })
          }
        >
          <span style={{ cursor: 'pointer' }}>
            <Icon16PXCopy />
          </span>
        </td>
      </tr>
    );
  }
}

const sideBarSchema = {
  name: 'Product modules',
  routes: () => {
    return [
      {
        name: 'All product modules',
        url: '/product-modules',
      },
    ];
  },
};
