import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { FormStore } from '../../formStore';
import { Validator, ComponentProps } from '../interfaces';

interface Props {
  keyVal: string;
  label: string;
  formStore: FormStore;
  validators: Validator[];
  componentProps: ComponentProps;
  readonly?: boolean;
  disabled?: boolean;
  type: string;
  defaultValue: string | null;
  parentKeyLookup?: string | null;
  onCheckboxInputChange: (value: boolean) => void;
}

interface State {}

@observer
export default class CheckboxInput extends Component<Props, State> {
  selectedValue = () => {
    const { keyVal, formStore, validators } = this.props;
    const value = formStore.inputState[keyVal] ? false : true;
    this.props.onCheckboxInputChange(value);
    formStore.updateComponentValid(keyVal, validators);
    formStore.updateValidTotalDirect(
      keyVal,
      !!formStore.componentValid[keyVal].validation.valid,
    );
  };

  componentDidMount() {
    const { keyVal, formStore, validators, defaultValue } = this.props;
    if (
      this.checked(formStore.inputState[keyVal]) === true ||
      this.checked(formStore.inputState[keyVal]) === false
    ) {
      this.props.formStore.updateInputState(
        this.props.keyVal,
        this.checked(formStore.inputState[keyVal]),
      );
    } else if (defaultValue !== null) {
      const defaultValueSet = defaultValue ? defaultValue : '';
      this.props.formStore.updateInputState(
        this.props.keyVal,
        this.checked(defaultValueSet),
      );
    }
    formStore.updateValidTotal(
      keyVal,
      formStore.inputState[keyVal],
      validators,
    );
  }

  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, null);
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, null);
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  checked = (value: string | boolean | null) => {
    const { defaultValue } = this.props;

    try {
      if (defaultValue && JSON.parse(defaultValue ? defaultValue : 'false')) {
        return true;
      }
      if (value || JSON.parse(value ? value : 'false')) {
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  };

  render() {
    const { label, keyVal, formStore } = this.props;
    return (
      <div className='form-group col-sm-6' style={{ display: 'inline-grid' }}>
        <label className='form-label' htmlFor={keyVal}>
          {this.props.label}
        </label>
        <Input
          className='form-control'
          name={label}
          type='checkbox'
          defaultChecked={this.checked(formStore.inputState[keyVal])}
          style={{ display: 'inline-block', marginRight: 10 }}
          readOnly={this.props.readonly ? this.props.readonly : false}
          disabled={this.props.disabled ? this.props.disabled : false}
          onChange={() => this.selectedValue()}
        />
      </div>
    );
  }
}
