/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='51px' height='51px' viewBox='0 0 51 51' version='1.1'>
        <g
          id='Pages'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-65'
            transform='translate(-469.000000, -449.000000)'
          >
            <g id='Group-6' transform='translate(470.000000, 450.000000)'>
              <text
                id='?'
                fontFamily='ProximaNova-Semibold, Proxima Nova'
                fontSize='27'
                fontWeight='500'
                fill='#E1EBF0'
              >
                <tspan x='18' y='33'>
                  ?
                </tspan>
              </text>
              <circle
                id='Oval-5'
                stroke='#E1EBF0'
                strokeWidth='2'
                strokeDasharray='5,8'
                cx='24.5'
                cy='24.5'
                r='24.5'
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
