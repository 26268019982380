import { observable, computed, action } from 'mobx';
import { Feature } from '../domain/feature';
import { FeatureApi } from '../feature-api';

export class FeatureListStore {
  @observable features: Feature[] = [];
  @observable isLoading = true;
  @observable newFeature = {
    featureKey: '',
    description: '',
    sandboxByDefault: true,
  };

  load = async () => {
    this.isLoading = true;
    this.features = await FeatureApi.get();
    this.isLoading = false;
  };

  @action createNewFeature = async (params: {
    featureKey: string;
    description: string;
    sandboxByDefault: boolean;
  }) => {
    this.isLoading = true;
    const { description, featureKey, sandboxByDefault } = params;
    await FeatureApi.createFeatureRequest({
      featureKey,
      description,
      sandboxByDefault,
    });
    this.features = await FeatureApi.get();
    this.isLoading = false;
  };

  @computed get isEmpty() {
    return !this.isLoading && this.features.length === 0;
  }
}

export const featureListStore = new FeatureListStore();
