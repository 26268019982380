import React from 'react';
import {
  Col,
  Button,
  FormGroup,
  Card,
  Row,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer } from 'mobx-react';
import '../../../../../../../../../styles/styles.scss';
import { WaitingPeriodRulesConfig } from '../config';
import { ObservablesTemp, Store } from '../../../store';
import SelectInputLifecycle from '../../form-components/select-input';
import InputLifecycle from '../../form-components/text-input';
import { WaitingPeriodRulesOutput } from '../../../store-interfaces';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';
import { Icon24PXCross } from '../../../../../../../../../../components/icons/icon-24-px-cross';

interface Props {
  index: number;
  allowList: boolean;
  reduceCount: () => void;
  removeFromLifeCycleStore: (lifecycleObservable: ObservablesTemp) => void;
  waitingPeriodRulesTemp: WaitingPeriodRulesOutput;
  store: Store;
}

interface State {}

@observer
class WaitingPeriodRule extends React.Component<Props, State> {
  render() {
    const { applyWaitingPeriodOf, to } = WaitingPeriodRulesConfig;
    return (
      <Card style={{ marginBottom: 20 }}>
        <CardHeader
          className='rule-header'
          style={{ backgroundColor: 'white', paddingBottom: 0 }}
        >
          <Row>
            <Col md={4} sm={12}>
              <div className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'>
                <Icon12ArrowRight />
              </div>
              <h6 className='completed-card-rule-heading'>
                Rule {this.props.index + 1}
              </h6>
            </Col>
            <Col md={8} sm={12} style={{ paddingTop: 0, marginTop: 0 }}>
              {this.props.allowList && (
                <Button
                  className='remove-rule-button'
                  color='link'
                  onClick={() => {
                    this.props.removeFromLifeCycleStore(
                      ObservablesTemp.waitingPeriodRulesTemp,
                    );
                    this.props.reduceCount();
                  }}
                >
                  Remove
                  {
                    <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                      <Icon24PXCross
                        height={'10'}
                        width={'10'}
                        stroke={'#007bff'}
                      />
                    </div>
                  }
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup row style={{ marginBottom: 15 }}>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>
                Apply a waiting period of
              </label>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: 2 }}>
              <InputLifecycle
                observable={ObservablesTemp.waitingPeriodRulesTemp}
                store={this.props.store}
                index={this.props.index}
                path={applyWaitingPeriodOf.outputPaths.value}
                placeHolder='30'
              />
            </Col>
            <Col xs={3} sm={4} style={{ paddingLeft: 0, marginTop: 2 }}>
              <SelectInputLifecycle
                observable={ObservablesTemp.waitingPeriodRulesTemp}
                store={this.props.store}
                options={applyWaitingPeriodOf.selectWaitingPeriodRules.type}
                index={this.props.index}
                path={applyWaitingPeriodOf.outputPaths.type}
                placeHolder={
                  applyWaitingPeriodOf.selectWaitingPeriodRules.type[0].label
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row className='row-form-spacing'>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>To</label>
            </Col>
            <Col xs={3} sm={6} style={{ paddingLeft: 16, paddingBottom: 10 }}>
              <SelectInputLifecycle
                observable={ObservablesTemp.waitingPeriodRulesTemp}
                store={this.props.store}
                options={to.selectWaitingPeriodRules}
                index={this.props.index}
                path={to.outputPaths}
                isDisabled={true}
                defaultValue={to.selectWaitingPeriodRules[0]}
                placeHolder={to.selectWaitingPeriodRules[0].label}
              />
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

export default WaitingPeriodRule;
