import React from 'react';
import { Card, CardBody, CardHeader, Container, Button } from 'reactstrap';
import CardEntry from '../../../components/card-entry';
import {
  ajax,
  renderValue,
  toCamelCaseKeys,
  toSentenceCase,
} from '../../../helpers';

export default class PaymentBatchOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentBatch: null,
      loading: true,
    };
  }

  get paymentBatchId() {
    return this.props.router.params.paymentBatchId;
  }

  componentDidMount() {
    this.fetchPaymentBatch();
  }

  async fetchPaymentBatch() {
    this.setState({ loading: true });
    const paymentBatch = toCamelCaseKeys(
      await ajax({
        type: 'GET',
        path: `/insurance/admin/payment-batches/${this.paymentBatchId}?include=billing_provider`,
      }),
    );

    this.setState({ paymentBatch, loading: false });
  }

  async archiveBatch() {
    const message =
      'Are you sure you want to archive this batch?\n\nAll payments in this batch will be unlinked ' +
      'but will still exist.';
    if (!window.confirm(message)) {
      return;
    }
    this.setState({ loading: true });
    try {
      await ajax({
        type: 'DELETE',
        path: `/insurance/admin/payment-batches/${this.paymentBatchId}`,
      });
    } catch (e) {
      window.alert(e.message);
    }
    this.setState({ loading: false });
    this.fetchPaymentBatch();
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <i>Loading payment batch...</i>
        </div>
      );
    }

    return (
      <Container style={{ padding: 0 }}>
        <Card>
          <CardHeader>
            Payment batch details{' '}
            <div className='pull-right'>
              <Button
                color='primary'
                onClick={this.archiveBatch.bind(this)}
                disabled={
                  this.state.loading ||
                  this.state.paymentBatch.status !== 'pending'
                }
              >
                Archive batch
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {Object.keys(this.state.paymentBatch).map((key, index) => (
              <CardEntry key={key} index={index}>
                <div style={{ padding: '2px' }}>
                  <b>{toSentenceCase(key)}</b>
                </div>
                <div style={{ width: '70%', padding: '2px' }}>
                  {renderValue(key, this.state.paymentBatch[key])}
                </div>
              </CardEntry>
            ))}
          </CardBody>
        </Card>
      </Container>
    );
  }
}
