import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withData } from '../../../helpers';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';

class Policy extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading policy...</i>
        </div>
      );
    }

    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes(
            this.props.router.params,
            this.props.router.location.query,
          )}
          title={sideBarSchema.name}
        />

        <Container>
          <Row>
            <Col sm={12}>
              <Row>
                <Col>{this.props.children}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withData({
  path: props => `/insurance/admin/policies/${props.params.policyId}`,
  prop: 'policy',
})(Policy);

const sideBarSchema = {
  name: 'Policy',
  routes: (params, query) => {
    const { policyId } = params;
    const isSandbox = query.sandbox === 'true';
    return [
      {
        title: true,
        name: 'View',
      },
      {
        name: 'Overview',
        url: `/policies/${policyId}/overview?sandbox=${isSandbox}`,
      },
      {
        name: 'Payment method',
        url: `/policies/${policyId}/payment-method?sandbox=${isSandbox}`,
      },
      {
        name: 'Payments',
        url: `/policies/${policyId}/payments?sandbox=${isSandbox}`,
      },
      {
        name: 'Ledger',
        url: `/policies/${policyId}/ledger?sandbox=${isSandbox}`,
      },
    ];
  },
};
