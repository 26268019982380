// Collect-specific helpers
import React from 'react';
import { toCamelCaseKeys, toKebabCase, getRandValue } from '../helpers';
import pluralize from 'pluralize';
import moment from 'moment';
import Highlight from 'react-highlight';
import runtimeEnv from '@mars/heroku-js-runtime-env';

export const get = async url => {
  const env = runtimeEnv();
  const response = await fetch(url, {
    // eslint-disable-line no-undef
    headers: {
      'x-service-name': 'alfred_proxy',
      'x-service-token': env.REACT_APP_SERVICE_TOKEN_COLLECT,
    },
  });
  const body = await response.json();
  if (Array.isArray(body)) {
    return body.map(toCamelCaseKeys);
  }
  return toCamelCaseKeys(body);
};

export const renderValue = (key, value) => {
  if (key.slice(-2) === 'At') {
    const m = moment(value);
    if (!m.isValid()) {
      return '-';
    }
    return moment(value).format('DD MMMM YYYY, hh:mm');
  }
  if (key === 'appData') {
    return (
      <Highlight className='json'>
        {JSON.stringify(value, null, 2) || '{}'}
      </Highlight>
    );
  }
  if (key.slice(-6).toLowerCase() === 'amount') {
    return 'R' + getRandValue(value);
  }
  if (key.slice(-2) === 'Id') {
    const pathName = pluralize(toKebabCase(key.slice(0, -2)));
    return <a href={`/collect/${pathName}/${value}`}>{value}</a>;
  }
  if (typeof value === 'object' && value !== null) {
    return (
      <Highlight className='json'>
        {JSON.stringify(value, null, 2) || '{}'}
      </Highlight>
    );
  }
  if (key.slice(-3) === 'Xml') {
    return <Highlight className='xml'>{value || ''}</Highlight>;
  }
  if (key === 'callbackUrl') {
    return value ? value.slice(value.indexOf('.com/') + 4) : '-';
  }
  return value;
};
