import { isValidSAIdNumber } from '../../../../utils/validations/valid-sa-id-number';
import { isValidEmail } from '../../../../utils/validations/valid-email-address';
import {
  Validator,
  InputState,
} from '../dynamic-form/interfaces/index';
import { FailConditions } from '../../../../utils/validations/constants';
import {
  getProductModuleDefinitionSchema,
} from '../../../product-modules-api';
import { isValidImei } from '../../../../../helpers/valid-imei';
import { ProductModuleDefinition } from '../../../../domain/product-module-definition';


export enum ProductModuleDefinitionSchemaIndexStructure {
  quoteSchemaId = 'quoteSchemaId',
  applicationSchemaId = 'applicationSchemaId',
  claimSchemaId = 'claimSchemaId',
  claimBlocksSchemaId = 'claimBlocksSchemaId',
}

export const establishState = async (
  productModuleDefinition: ProductModuleDefinition,
  productModuleDefinitionSchemaId: string,
) => {
  const productModuleDefinitionSchema = await getProductModuleDefinitionSchema(
    productModuleDefinition.productModuleId,
    productModuleDefinition.productModuleDefinitionId,
    productModuleDefinitionSchemaId,
  );
  let templateJson;
  let templateJsonOriginal;
  try {
    templateJson = productModuleDefinitionSchema.jsonContent;
    templateJsonOriginal = productModuleDefinitionSchema.jsonContent;
  } catch (err) {
    console.log('error parsing json schema', err);

    const defaultTemplate = {
      created_at: null,
      created_by: {
        id: '00000000-0000-0000-0000-000000000000',
        type: 'user',
      },
      id: null,
      type: 'user',
      json: [{}],
    };
    templateJson = defaultTemplate;
    templateJsonOriginal = defaultTemplate;
  }

  return {
    templateJson,
    templateJsonOriginal,
  };
};


export const failCondition = (condition: string) => {
  console.log(condition);
};

const enum validationTypes {
  ZA_ID = 'za_id',
  EMAIL = 'email',
  GREATER_THAN_NUMBER = 'greaterThanNumber',
  GREATER_THAN_LENGTH = 'greaterThanLength',
  LESS_THAN_NUMBER = 'lessThanNumber',
  GREATER_THAN_CURRENCY = 'greaterThanCurrency',
  LESS_THAN_CURRENCY = 'lessThanCurrency',
  LESS_THAN_LENGTH = 'lessThanLength',
  REQUIRED = 'required',
  IMEI = 'imei',
}

export const validityCheck = (
  key: string,
  validators: Validator[],
  inputState: InputState,
  props?: any,
  onBlurCheck: boolean = false,
) => {
  let failCondition = '';
  const {
    ZA_ID,
    EMAIL_ADDRESS,
    GREATER_THAN_NUMBER,
    LESS_THAN_NUMBER,
    GREATER_THAN_CURRENCY,
    LESS_THAN_CURRENCY,
    GREATER_THAN_LENGTH,
    LESS_THAN_LENGTH,
    IMEI,
    COMPLETE_FIELD,
  } = FailConditions;

  const value = inputState[key];
  const valid = validators.every(
    (validator: Validator): boolean => {
      if (validator.validation && validator.validation.type) {
        switch (validator.validation.type) {
          case validationTypes.ZA_ID:
            if (value !== null && value !== undefined) {
              failCondition = ZA_ID;
              return isValidSAIdNumber(value.toString());
            }
          case validationTypes.EMAIL:
            if (value !== null && value !== undefined) {
              failCondition = EMAIL_ADDRESS;
              return isValidEmail(value);
            }
          case validationTypes.GREATER_THAN_NUMBER:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.min !== null &&
                validator.validation.min !== undefined
              ) {
                const valid = numericComparision(
                  value,
                  validator.validation.min,
                  validationTypes.GREATER_THAN_NUMBER,
                );
                failCondition = !valid
                  ? `${GREATER_THAN_NUMBER} ${validator.validation.min}`
                  : '';

                return valid;
              }
            }
          case validationTypes.LESS_THAN_NUMBER:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.max !== null &&
                validator.validation.max !== undefined
              ) {
                const max = validator.validation.max;
                const valid = numericComparision(
                  value,
                  max,
                  validationTypes.LESS_THAN_NUMBER,
                );
                failCondition = !valid ? `${LESS_THAN_NUMBER} ${max}` : '';

                return valid;
              }
            }
          case validationTypes.GREATER_THAN_CURRENCY:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.min !== null &&
                validator.validation.min !== undefined
              ) {
                const min = validator.validation.min;
                const valid = numericComparision(
                  value,
                  min,
                  validationTypes.GREATER_THAN_CURRENCY,
                );
                failCondition = !valid
                  ? `${GREATER_THAN_CURRENCY} ${(min / 100).toLocaleString(
                    'en-ZA',
                    { style: 'currency', currency: 'ZAR' },
                  )}`
                  : '';

                return valid;
              }
            }
          case validationTypes.LESS_THAN_CURRENCY:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.max !== null &&
                validator.validation.max !== undefined
              ) {
                const max = validator.validation.max;
                const valid = numericComparision(
                  value,
                  max,
                  validationTypes.LESS_THAN_CURRENCY,
                );
                failCondition = !valid
                  ? `${LESS_THAN_CURRENCY} ${(max / 100).toLocaleString(
                    'en-ZA',
                    { style: 'currency', currency: 'ZAR' },
                  )}`
                  : '';

                return valid;
              }
            }
          case validationTypes.GREATER_THAN_LENGTH:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.min !== null &&
                validator.validation.min !== undefined
              ) {
                const min = validator.validation.min;
                const valid = lengthComparision(
                  value,
                  min,
                  validationTypes.GREATER_THAN_LENGTH,
                );
                failCondition = !valid ? `${GREATER_THAN_LENGTH} ${min}` : '';

                return valid;
              }
            }
          case validationTypes.LESS_THAN_LENGTH:
            if (value !== null && value !== undefined) {
              if (
                validator.validation.max !== null &&
                validator.validation.max !== undefined
              ) {
                const max = validator.validation.max;
                const valid = lengthComparision(
                  value,
                  max,
                  validationTypes.LESS_THAN_LENGTH,
                );
                failCondition = !valid ? `${LESS_THAN_LENGTH} ${max}` : '';

                return valid;
              }
            }
          case validationTypes.IMEI:
            if (value !== null && value !== undefined) {
              const valid = isValidImei(value.toString());
              failCondition = !valid ? `${IMEI}` : '';

              return valid;
            }
          case validationTypes.REQUIRED:
            if ((value === null || value === undefined) && onBlurCheck) {
              failCondition = COMPLETE_FIELD;
              return false;
            }
            if (onBlurCheck && value.length === 0) {
              failCondition = COMPLETE_FIELD;
              return false;
            }
          default:
        }
      }
      return true;
    },
  );

  return {
    valid,
    failCondition,
  };
};

export const numericComparision = (
  value: string,
  comparison: number,
  type: string,
): boolean => {
  try {
    switch (type) {
      case validationTypes.GREATER_THAN_NUMBER:
        return parseFloat(value) >= parseFloat(comparison.toString())
          ? true
          : false;

      case validationTypes.LESS_THAN_NUMBER:
        return parseFloat(value) <= parseFloat(comparison.toString())
          ? true
          : false;

      case validationTypes.GREATER_THAN_CURRENCY:
        return parseFloat(value) >= parseFloat(comparison.toString())
          ? true
          : false;

      case validationTypes.LESS_THAN_CURRENCY:
        return parseFloat(value) <= parseFloat(comparison.toString())
          ? true
          : false;

      default:
        return true;
    }
  } catch (err) {
    return true;
  }
};

export const lengthComparision = (
  value: string | number,
  comparison: number,
  type: string,
): boolean => {
  try {
    switch (type) {
      case validationTypes.GREATER_THAN_LENGTH:
        return value.toString().length >= parseFloat(comparison.toString())
          ? true
          : false;

      case validationTypes.LESS_THAN_LENGTH:
        return value.toString().length <= parseFloat(comparison.toString())
          ? true
          : false;

      default:
        return true;
    }
  } catch (err) {
    return true;
  }
};
