import React from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
} from 'reactstrap';
import { ajax } from '../../../../helpers';
import ToggleSwitch from '../../../../components/toggle-switch';

export class NotificationChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: true,
      smsConfigs: null,
      error: false,
      errorMessage: null,
    };
  }

  fetchSmsConfig = async ({ environment }) => {
    const { organizationId } = this.props;
    try {
      const smsConfig = await ajax({
        path: `insurance/admin/organizations/${organizationId}/sms-configs`,
        sandbox: environment === 'sandbox',
      });
      return smsConfig;
    } catch (error) {
      console.dir(error);
    }
  };

  updateSmsConfigs = async ({ smsConfigSandboxEnabled, smsConfigProductionEnabled }) => {
    const { organizationId } = this.props;
    const baseAjaxParams = {
      path: `insurance/admin/organizations/${organizationId}/sms-configs`,
      type: 'PATCH',
    };
    try {
      const updatedSmsConfigSandbox = await ajax({
        ...baseAjaxParams,
        sandbox: true,
        data: {
          enabled: smsConfigSandboxEnabled,
        },
      });

      const updatedSmsConfigProduction = await ajax({
        ...baseAjaxParams,
        sandbox: false,
        data: {
          enabled: smsConfigProductionEnabled,
        },
      });

      const updatedSmsConfigs = [updatedSmsConfigSandbox, updatedSmsConfigProduction];
      this.toggle();
      this.populateSmsConfigs(updatedSmsConfigs);
      return updatedSmsConfigs;
    } catch (error) {
      console.dir(error);
      this.toggle();
      this.populateSmsConfigs();
      this.setState({ errorMessage: JSON.parse(error.message), error: true });
    }
  };

  configureSmsConfigs = () => {
    this.setState({ modal: true, error: false });
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  populateSmsConfigs = async (updatedSmsConfigs) => {
    const smsConfigs = updatedSmsConfigs && updatedSmsConfigs.length === 2
      ? updatedSmsConfigs
      : await Promise.all([
        this.fetchSmsConfig({ environment: 'production' }),
        this.fetchSmsConfig({ environment: 'sandbox' }),
      ]);

    this.setState({ smsConfigs, loading: false });
  };

  componentDidMount() {
    this.populateSmsConfigs();
  }

  render() {
    const { smsConfigs, loading, modal, error, errorMessage } = this.state;

    const customTableHeadCell = {
      width: '120px',
    };

    const tableCellOverride = {
      verticalAlign: 'baseline',
    };

    if (loading) {
      return (
        <div>
          <i>Loading notification channels...</i>
        </div>
      );
    }

    const smsConfigProductionEnabled = smsConfigs
      ? smsConfigs.find((smsConfig) => smsConfig.environment === 'production').enabled
      : undefined;

    const smsConfigSandboxEnabled = smsConfigs
      ? smsConfigs.find((smsConfig) => smsConfig.environment === 'sandbox').enabled
      : undefined;

    return (
      <div>
        <h5 style={{ paddingTop: 20 }} className='feather-light'>
          Notification channels
        </h5>
        {error && <Alert color='danger'>{`${errorMessage.message}`}</Alert>}
        <Alert color='info'>
          If a notification channel is <strong>enabled</strong>, the Root platform will send notifications using a native
          provider integration. If <strong>disabled</strong>, the platform
          will create notifications but will not attempt to send them.
        </Alert>
        <Table>
          <thead>
            <tr>
              <th>Channel</th>
              <th style={customTableHeadCell}>Sandbox</th>
              <th style={customTableHeadCell}>Production</th>
              <th style={customTableHeadCell} />
            </tr>
          </thead>
          <tbody>
            {smsConfigs && (
              <tr /*key={index}*/>
                <td style={tableCellOverride}>SMS</td>
                {
                  <td
                    style={tableCellOverride}
                    className={smsConfigSandboxEnabled ? 'success' : 'default'}
                  >
                    {smsConfigSandboxEnabled ? 'Enabled' : 'Disabled'}
                  </td>
                }
                {
                  <td
                    style={tableCellOverride}
                    className={
                      smsConfigProductionEnabled ? 'success' : 'default'
                    }
                  >
                    {smsConfigProductionEnabled ? 'Enabled' : 'Disabled'}
                  </td>
                }
                {
                  <td style={tableCellOverride}>
                    <Button
                      color='link'
                      onClick={() => this.configureSmsConfigs()}
                    >
                      Configure
                    </Button>
                  </td>
                }
              </tr>
            )}
          </tbody>
          {modal && (
            <ConfigurationModal
              modal={modal}
              smsConfigs={smsConfigs}
              toggle={this.toggle}
              updateSmsConfigs={this.updateSmsConfigs}
            />
          )}
        </Table>
      </div>
    );
  }
}

class ConfigurationModal extends React.Component {
  constructor(props) {
    super(props);

    const { smsConfigs } = props;

    const smsConfigProductionEnabled = smsConfigs.find(
      (smsConfig) => smsConfig.environment === 'production',
    ).enabled;

    const smsConfigSandboxEnabled = smsConfigs.find(
      (smsConfig) => smsConfig.environment === 'sandbox',
    ).enabled;

    this.state = {
      productionChecked: smsConfigProductionEnabled,
      sandboxChecked: smsConfigSandboxEnabled,
    };
  }

  handleProductionChange = (productionChecked) => {
    this.setState({ productionChecked });
  };

  handleSandboxChange = (sandboxChecked) => {
    this.setState({ sandboxChecked });
  };

  render() {
    const { modal, updateSmsConfigs, toggle } = this.props;
    const { productionChecked, sandboxChecked } = this.state;
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>SMS</ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'row' }}>
          <ToggleSwitch
            id={'sandbox-toggle'}
            leftText={'Sandbox'}
            checked={this.state.sandboxChecked}
            onChange={this.handleSandboxChange}
            onColor={'#1fc881'}
            offColor={'#c3c3c3'}
          />
          <ToggleSwitch
            id={'live-toggle'}
            leftText={'Production'}
            checked={this.state.productionChecked}
            onChange={this.handleProductionChange}
            onColor={'#1fc881'}
            offColor={'#c3c3c3'}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() =>
              updateSmsConfigs({
                smsConfigSandboxEnabled: sandboxChecked,
                smsConfigProductionEnabled: productionChecked,
              })
            }
          >
            Confirm
          </Button>
          <Button color='secondary' onClick={() => toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
