/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='52px' height='37px' viewBox='0 0 52 37' version='1.1'>
        <g
          id='Activation'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-7'
            transform='translate(-142.000000, -324.000000)'
            strokeWidth='2'
            stroke='#FFFFFF'
          >
            <g id='Group-Copy' transform='translate(143.000000, 325.000000)'>
              <g id='Page-1'>
                <path
                  d='M36.0514266,26.9346089 C40.0134802,26.9680399 42.9675163,26.9913622 43.6273582,26.9913622 C46.9705231,26.9913622 49.6814681,24.3045299 49.6814681,20.9900801 C49.6814681,17.6748024 46.9705231,14.9879701 43.6273582,14.9879701 L43.6273582,14.9879701 C43.3751385,14.9879701 43.1262593,15.0053579 42.8823912,15.0368216 C42.9366769,14.6236538 42.9742593,14.2055182 42.9742593,13.7774466 C42.9742593,8.41702991 38.5913143,4.07090278 33.1844571,4.07090278 C30.8476659,4.07090278 28.7037978,4.88564637 27.0201055,6.24106838 C25.0833582,2.85458333 21.4228308,0.563530983 17.2152703,0.563530983 C10.986611,0.563530983 5.93803956,5.56956731 5.93803956,11.7447276 C5.93803956,12.0403205 5.95307253,12.3326015 5.97562198,12.6223985 C2.72014945,13.2972115 0.273951648,16.157922 0.273951648,19.5866346 C0.273951648,23.5154541 3.48766593,26.7007372 7.45135824,26.7007372 C8.17095218,26.7007372 10.5799931,26.7182797 13.839443,26.7447449'
                  id='Stroke-19'
                />
                <polygon
                  id='Stroke-23'
                  strokeLinejoin='round'
                  points='33.8007938 20.3846205 24.9778769 12.5670072 16.15496 20.3846205 19.9584889 20.3846205 19.9584889 34.0571114 29.997265 34.0571114 29.997265 20.3846205'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
