/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Capa_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 198.496 198.496'
        enableBackground='new 0 0 198.496 198.496'
        xmlSpace='preserve'
        width='50px'
      >
        <g>
          <g>
            <rect
              x='84.55'
              y='148.231'
              fill='#FDCC9B'
              width='29.395'
              height='32.922'
            />
            <path
              fill='#FCBC85'
              d='M84.551,152.108c0,0,12.365,7.874,29.395,6.05v-9.928H84.551V152.108z'
            />
            <ellipse
              fill='#FCBC85'
              cx='42.164'
              cy='97.181'
              rx='14.343'
              ry='16.364'
            />
            <ellipse
              fill='#FCBC85'
              cx='156.332'
              cy='97.181'
              rx='14.343'
              ry='16.364'
            />
            <path
              fill='#FDCC9B'
              d='M156.274,65.925c0-24.103-17.637-41.741-57.026-41.741c-39.389,0-57.026,17.637-57.026,41.741
          c0,24.104-4.115,87.597,57.026,87.597C160.389,153.522,156.274,90.029,156.274,65.925z'
            />
            <g>
              <g>
                <ellipse
                  fill='#3B2519'
                  cx='71.472'
                  cy='93.262'
                  rx='6.173'
                  ry='6.761'
                />
                <circle fill='#FFFFFF' cx='68.781' cy='90.277' r='1.846' />
              </g>
              <path
                fill='#51362A'
                d='M61.597,76.507c2.919,1.46,7.606-4.96,18.335,0.625c1.956,1.018,3.123-8.708-8.377-8.708
            C61.597,68.424,59.833,75.626,61.597,76.507z'
              />
              <g>
                <ellipse
                  fill='#3B2519'
                  cx='127.786'
                  cy='93.262'
                  rx='6.173'
                  ry='6.761'
                />
                <circle fill='#FFFFFF' cx='125.095' cy='90.277' r='1.846' />
              </g>
              <path
                fill='#51362A'
                d='M136.899,76.507c-2.919,1.46-7.606-4.96-18.335,0.625c-1.956,1.018-3.123-8.708,8.378-8.708
            C136.899,68.424,138.662,75.626,136.899,76.507z'
              />
            </g>
            <path
              fill='#FCBC85'
              d='M99.248,117.043c-6.1,0-9.774-4.556-9.774-2.352c0,2.205,1.764,6.394,9.774,6.394
          c8.01,0,9.774-4.189,9.774-6.394C109.022,112.486,105.347,117.043,99.248,117.043z'
            />
            <path
              fill='#FCBC85'
              d='M99.248,137.313c-2.11,0-3.381-1.576-3.381-0.813s0.61,2.211,3.381,2.211
          c2.771,0,3.38-1.448,3.38-2.211S101.357,137.313,99.248,137.313z'
            />
            <path
              fill='#F7945E'
              d='M99.248,131.696c-9.668,0-15.493-3.937-15.493-2.939c0,0.998,2.796,4.924,15.493,4.924
          c12.697,0,15.493-3.926,15.493-4.924C114.74,127.759,108.916,131.696,99.248,131.696z'
            />
          </g>
          <path
            fill='#F7941E'
            d='M99.248,161.458v37.038H27.821C27.821,183.504,65.444,161.458,99.248,161.458z'
          />
          <path
            fill='#F7941E'
            d='M99.248,161.458v37.038h71.427C170.675,183.504,133.052,161.458,99.248,161.458z'
          />
          <path
            fill='#F36C21'
            d='M99.248,161.458c-7.19,0-14.552,1.005-21.689,2.72c0.048,0.063,7.916,10.214,21.689,10.214
        c12.754,0,21.233-8.693,22.462-10.03C114.332,162.531,106.698,161.458,99.248,161.458z'
          />
          <path
            fill='#FDCC9B'
            d='M84.55,162.74c0,0,4.299,5.332,14.697,5.332c10.398,0,14.698-5.332,14.698-5.332
        S98.697,157.189,84.55,162.74z'
          />
          <path
            fill='#51362A'
            d='M146.132,19.041c0,0-21.164-26.896-65.152-16.755C36.993,12.426,33.697,38.882,35.02,64.621
        c1.323,25.74,4.85,40.84,9.022,38.974c4.172-1.867,2.001-18.857,2.442-22.778c0.441-3.921,4.409-21.653,31.162-19.007
        c26.752,2.646,49.296-7.055,49.296-7.055s9.075,11.471,15.047,13.669c11.934,4.391,8.02,33.67,12.696,33.67
        S182.288,34.473,146.132,19.041z'
          />
        </g>
      </svg>
    );
  }
}
