import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, browserHistory, IndexRedirect } from 'react-router';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import reducers from './reducers/index';
import './stylesheets/application.scss';
import { initAccount } from './account/actions/init-account';
import { startCheckIfNeeded } from './helpers/token-refresh';
import { Provider as MobxProvider } from 'mobx-react';
import runtimeEnv from '@mars/heroku-js-runtime-env';

import App from './app';

// Sections
import accountRoutes from './account/routes';
import usersRoutes from './users/routes';
import analyticsRoutes from './analytics/routes';
import communicationsRoutes from './communications/routes';
import organizationRoutes from './organizations/routes';
import productModuleRoutes from './product-modules/routes';
import paymentRoutes from './payments/routes';
import featureRoutes from './features/routes';
import { organizationListStore } from './organizations/stores/organization-list-store';
import { organizationViewStore } from './organizations/stores/organization-view-store';
import { productModuleStore } from './product-modules/components/product-definition/stores/product-module-store';
import { productModuleDefinitionScheduledFunctionStore } from './product-modules/components/product-definition/stores/product-module-definition-scheduled-function-store';
import { productModuleListStore } from './product-modules/components/product-definition/stores/product-module-list-store';
import { dataExportListStore } from './organizations/components/data-exports/stores/data-export-list-store';
import { dataExportRunListStore } from './organizations/components/data-exports/stores/data-export-run-list-store';
import { dataExportViewStore } from './organizations/components/data-exports/stores/data-export-view-store';
import { templateListStore } from './organizations/components/data-exports/stores/template-list-store';
import { templateViewStore } from './organizations/components/data-exports/stores/template-view-store';
import { productModuleComponentLoadingStateStore } from './product-modules/components/product-definition/stores/product-module-component-loading-state-store';
import { policyIssuingFlowStore } from './product-modules/components/product-definition/stores/policy-issuing-flow-store';
import { productModuleOrganizationStore } from './product-modules/components/product-definition/stores/product-module-organization-store';
import { featureListStore } from './features/stores/feature-list-store';
import { organizationFeatureListStore } from './features/stores/organization-feature-list-store';
import { dataExportRunLogStore } from './organizations/components/data-exports/stores/data-export-run-log-store';
import { productModuleCodeRunListStore } from './product-modules/stores/product-module-code-run-list-store';
import { productModuleCodeRunLogStore } from './product-modules/stores/product-module-code-run-log-store';
import { productModuleCodeRunStore } from './product-modules/stores/product-module-code-run-store';

import { reportListStore } from './organizations/components/reports/stores/report-list-store';
import { reportViewStore } from './organizations/components/reports/stores/report-view-store';
import { reportRunListStore } from './organizations/components/reports/stores/report-runs-list-store';
import { productModuleDefinitionAlterationHooksListStore } from './product-modules/components/product-definition/stores/product-module-definition-alteration-hooks-list-store';

// Javascript file loaded, remove html loader
const loaderElement = document.getElementById('javascript-loader');
loaderElement.parentNode.removeChild(loaderElement);

const env = runtimeEnv();
// Google Analytics
if (env.NODE_ENV === 'development') {
  // options.debug = true; // Verbose in the browser console
}

const store = createStore(combineReducers(reducers), applyMiddleware(thunk));

const requireAuth = (nextState, replace) => {
  const loginToken = window.localStorage.getItem('login_token');
  if (loginToken) {
    const decoded = jwtDecode(loginToken);
    const exp = decoded.exp * 1000;
    if (moment(exp).isBefore(moment())) {
      console.log(
        'LoginToken expired...',
        moment(exp).format(),
        moment().format(),
      );
      // Clear local storage
      console.log('requireAuth clearing storage');
      window.localStorage.clear();
      replace({
        pathname: '/login',
        state: { nextPathname: nextState.location.pathname },
      });
    } else {
      // Login Token refresher
      startCheckIfNeeded(store.dispatch);
    }
  } else {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};

// Check if init-account should be called
const loginToken = window.localStorage.getItem('login_token');
if (loginToken) {
  const decoded = jwtDecode(loginToken);
  const exp = decoded.exp * 1000;
  if (moment(exp).isBefore(moment())) {
    console.log('loginToken clearing storage');
    window.localStorage.clear();
  } else {
    // TODO check if data is in redux store
    // It's expected to be empty because the
    // page just loaded
    store.dispatch(initAccount());
  }
}

ReactDOM.render(
  <Provider store={store}>
    <MobxProvider
      policyIssuingFlowStore={policyIssuingFlowStore}
      productModuleStore={productModuleStore}
      productModuleDefinitionScheduledFunctionStore={
        productModuleDefinitionScheduledFunctionStore
      }
      productModuleListStore={productModuleListStore}
      productModuleComponentLoadingStateStore={
        productModuleComponentLoadingStateStore
      }
      productModuleOrganizationStore={productModuleOrganizationStore}
      featureListStore={featureListStore}
      dataExportViewStore={dataExportViewStore}
      dataExportRunListStore={dataExportRunListStore}
      templateViewStore={templateViewStore}
      organizationListStore={organizationListStore}
      templateListStore={templateListStore}
      dataExportListStore={dataExportListStore}
      organizationFeatureListStore={organizationFeatureListStore}
      dataExportRunLogStore={dataExportRunLogStore}
      organizationViewStore={organizationViewStore}
      reportListStore={reportListStore}
      reportViewStore={reportViewStore}
      reportRunListStore={reportRunListStore}
      productModuleCodeRunListStore={productModuleCodeRunListStore}
      productModuleCodeRunLogStore={productModuleCodeRunLogStore}
      productModuleCodeRunStore={productModuleCodeRunStore}
      productModuleDefinitionAlterationHooksListStore={productModuleDefinitionAlterationHooksListStore}
    >
      <Router history={browserHistory}>
        <Route path='/' component={App} onEnter={requireAuth}>
          <IndexRedirect to='analytics' />
          <Route path='analytics'>{analyticsRoutes}</Route>
          <Route path='users'>{usersRoutes}</Route>
          <Route path='payments'>{paymentRoutes}</Route>
          <Route path='organizations'>{organizationRoutes}</Route>
          <Route path='product-modules'>{productModuleRoutes}</Route>
          <Route path='communications'>{communicationsRoutes}</Route>
          <Route path='features'>{featureRoutes}</Route>
        </Route>

        {/* LOGIN */}
        {accountRoutes}
      </Router>
    </MobxProvider>
  </Provider>,
  document.getElementById('root'),
);
