import _ from 'lodash';
import {
  CoolingOffPeriodOutput,
  WaitingPeriodRulesOutput,
  WaitingPeriodNotificationsOutput,
  GracePeriodOutput,
} from '../store-interfaces';

interface ToReturn {
  [k: string]: string;
}
interface SelectOptions {
  value: string;
  label: string;
}

export const flattenObject = function(ob: any) {
  var toReturn: ToReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const spliceSelectOptions = (
  store: WaitingPeriodRulesOutput| WaitingPeriodNotificationsOutput| CoolingOffPeriodOutput| GracePeriodOutput,
  path: string,
  comparisionValues: string[],
  options: SelectOptions[],
) => {
  let values: string[] = [];
  let optionsDefault = options;
  for (const key in store) {
    values.push(_.get(store[key], path));
  }
  values = _.uniq(values);

  comparisionValues.map(comparisionValue => {
    if (values.indexOf(comparisionValue) !== -1) {
      optionsDefault = optionsDefault.filter(
        (val: SelectOptions) => val.value !== comparisionValue,
      );
    }
  });
  return optionsDefault;
};

export const setSingularOrPlural = (value: string, phrase: string) => {
  try {
    if (value.toString() === '1') {
      return _.endsWith(phrase, 's') ? _.trimEnd(phrase, 's') : phrase;
    }
    return phrase;
  } catch (err) {
    return phrase;
  }
};

export const displayBasedOnCount = (count: number, comparisonValue: number) => {
  try {
    return count < comparisonValue ? true : false;
  } catch (err) {
    return false;
  }
};
