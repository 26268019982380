import { ajax } from '../../../helpers';
import { ScheduledDataExport } from './domain/scheduled-data-export';
import { DataExportRun } from './domain/data-export-run';
import { DataExportRunLogItem } from './domain/data-export-run-log-item';
import { ExportType } from './domain/export-type';
import { DetailsData } from './views/modals/new-export-modal';

export class DataExportApi {
  static get = async (params: {
    organizationId: string;
    page: number;
    pageSize: number;
  }) => {
    const { organizationId, page, pageSize } = params;
    const query: [string, string | number][] = [];

    query.push(
      ['page', page],
      ['page_size', pageSize],
      ['include', 'latest_run'],
    );

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');
    const total = await ajax({
      type: 'HEAD',
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports?${queryString}`,
      raw: true,
    });

    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports?${queryString}`,
      type: 'get',
    });

    const rowCount = total.headers.get('X-Total-Count');

    return {
      dataExportsExist: result.length,
      scheduledDataExports: result.map(ScheduledDataExport.fromNetwork),
      total: rowCount,
    };
  };

  static single(params: {
    organizationId: string;
    scheduledDataExportId: string;
  }) {
    const { scheduledDataExportId, organizationId } = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}?include=latest_run`,
      type: 'get',
    }).then(s => ScheduledDataExport.fromNetwork(s));
  }

  static runs = async (params: {
    organizationId: string;
    scheduledDataExportId: string;
    page: number;
    pageSize: number;
  }) => {
    const { organizationId, scheduledDataExportId, page, pageSize } = params;
    const query: [string, string | number][] = [];

    query.push(
      ['page', page],
      ['page_size', pageSize],
    );

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');
    const total = await ajax({
      type: 'HEAD',
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/runs?${queryString}`,
      raw: true,
    });

    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/runs?${queryString}`,
      type: 'get',
    });

    const rowCount = total.headers.get('X-Total-Count');

    return {
      dataExportRunsExist: result.length,
      dataExportRuns: result.map(DataExportRun.fromNetwork),
      total: rowCount,
    };
  }

  static logItems(params: { organizationId: string; dataExportRunId: string }) {
    const { organizationId, dataExportRunId } = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-runs/${dataExportRunId}/log-items`,
      type: 'get',
    }).then(result => result.map(DataExportRunLogItem.fromNetwork));
  }

  static add(params: { organizationId: string; payload: DetailsData }) {
    const { organizationId, payload } = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports`,
      type: 'post',
      data: {
        environment: payload.environment,
        name: payload.name,
        frequency: payload.frequency.toNetwork(),
        export_type: payload.exportType,
        template_id:
          payload.exportType === ExportType.CustomExport
            ? payload.templateId
            : undefined,
        data_range: payload.dataRange,
        adapter: payload.adapter.toNetwork(),
        restricted: payload.restricted,
      },
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }

  static update(params: { organizationId: string; scheduledDataExportId: string; payload: any }) {
    const { scheduledDataExportId, organizationId, payload } = params;

    let data = {};

    if (payload.templateId && payload.exportType === 'custom_export') {
      data = { ...data, template_id: payload.templateId };
    }

    if (payload.frequency) {
      data = { ...data, frequency: {...payload.frequency.toNetwork()}};
    }

    if (payload.adapter) {
      data = { ...data, adapter: {...payload.adapter.toNetwork()}};
    }

    data = {
      ...data,
      name: payload.name,
      export_type: payload.exportType,
      data_range: payload.dataRange,
    };

    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}`,
      type: 'PATCH',
      data,
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }

  static pause(params: { organizationId: string; scheduledDataExportId: string }) {
    const { organizationId, scheduledDataExportId} = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/pause`,
      type: 'post',
      data: {},
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }

  static resume(params: { organizationId: string; scheduledDataExportId: string }) {
    const { organizationId, scheduledDataExportId} = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/resume`,
      type: 'post',
      data: {},
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }

  static archive(params: { organizationId: string; scheduledDataExportId: string }) {
    const { organizationId, scheduledDataExportId} = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/archive`,
      type: 'post',
      data: {},
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }

  static trigger(params: { organizationId: string; scheduledDataExportId: string; fromExcl?: string; toIncl?: string }) {
    const { scheduledDataExportId, fromExcl, organizationId, toIncl } = params;
    return ajax({
      path: `/insurance/admin/apps/${organizationId}/scheduled-data-exports/${scheduledDataExportId}/trigger`,
      type: 'post',
      data: {
        from_excl: fromExcl,
        to_incl: toIncl,
      },
    }).then(dataExport => ScheduledDataExport.fromNetwork(dataExport));
  }
}
