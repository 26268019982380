import React from 'react';
import { Route, IndexRoute } from 'react-router';

// Components
import ProductModules from './components/product-modules';
import ProductDefinitionParent from './components/product-definition/index';
import { ProductModuleOverview } from './components/overview';
import { ProductModuleDeployment } from './components/deployment';
import { ProductModuleVersionHistory } from './components/version-history';
import { ProductModuleRunListView } from './components/run-logs/run-list-view';
import { ProductModuleCodeRunView } from './components/run-logs/run-view';

const productModuleData = (data) => {
  return data;
};

export default (
  <Route name='product_modules'>
    <IndexRoute component={ProductModules} />
    <Route path=':productModuleKey' component={ProductModuleOverview} />
    <Route path=':productModuleKey/deployment' component={ProductModuleDeployment} />
    <Route path=':productModuleKey/version-history' component={ProductModuleVersionHistory} />
    <Route
      path=':productModuleKey/product-definition'
      component={ProductDefinitionParent}
      productModuleData={(data) => productModuleData(data)}
    />
    <Route path=':productModuleKey/runs' component={ProductModuleRunListView} />
    <Route
      path=':productModuleKey/runs/:productModuleCodeRunId'
      component={ProductModuleCodeRunView}
    />
  </Route>
);
