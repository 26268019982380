import React from 'react';
import { FormGroup, Col } from 'reactstrap';
import { setSingularOrPlural } from '../../../utils';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';

interface Props {
  value: string;
  applyTo: string;
  type: string;
  index: number;
}

interface State {}

class WaitingPeriodRulesCompletedChild extends React.Component<Props, State> {
  render() {
    let { value, applyTo, type, index } = this.props;

    type = type ? setSingularOrPlural(value, type.toString()) + ' ' : '';
    value = value ? value + ' ' : '';
    applyTo = applyTo ? applyTo + '' : '';

    return (
      <FormGroup row style={{ marginBottom: -13 }}>
        <Col md={4} sm={12}>
          <div className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'>
            {index === 0 ? <Icon12ArrowRight /> : 'OR'}
          </div>
          <h6 className='completed-card-rule-heading'>Rule {index + 1}</h6>
        </Col>
        <Col md={8} sm={12} className='completed-card-rule-heading'>
          <p>{`Apply a waiting period of ${value}${type}${applyTo}.`}</p>
        </Col>
      </FormGroup>
    );
  }
}

export default WaitingPeriodRulesCompletedChild;
