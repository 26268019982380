import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';
import { BlockSchema } from './block-schema';

export class GroupBlock extends Block {
  readonly title: string;
  readonly collapsible: boolean;
  readonly blocks: BlockSchema[] = [];

  constructor(init: Construct<GroupBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      collapsible: this.collapsible,
      blocks: this.blocks,
    };
  }

  static fromJSON(init: any) {
    return new GroupBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      collapsible: init.collapsible,
      blocks: init.blocks,
    });
  }
}
