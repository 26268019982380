import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';

interface Props {
  close: () => void;
  submit: () => void;
}

export class EditTemplateModal extends React.Component<Props, any> {
  render() {
    return (
      <Modal isOpen={true} toggle={this.props.close} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={this.props.close} />
          <h4 className='exports-prompt-modal-header-text'>Edit an active template?</h4>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <p>
            If this template is actively being used in scheduled jobs, editing it
            may change the format of future exports.
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button color='primary' onClick={() => {
            this.props.submit();
            this.props.close();
          }}>
            Continue to edit
          </Button>
          <Button color='link' onClick={this.props.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
