import React from 'react';

export class LoadingDots extends React.Component {
  render() {
    return (
      <span className='loading-dots'>
        <span className='dot dot-one' />
        <span className='dot dot-two' />
        <span className='dot dot-three' />
      </span>
    );
  }
}
