import { ajax } from '../../../helpers';

export const addUserToOrganizationRole = async (params: {
  organizationId: string;
  userId: string;
  roleId: string;
}) => {
  const { organizationId, userId, roleId } = params;
  return await ajax({
    path: `/organizations/${organizationId}/users/${userId}/role`,
    type: 'PUT',
    data: {
      organization_role_id: roleId,
    },
  });
};

export const removeUser = async (params: {
  organizationId: string;
  userId: string;
}) => {
  const { organizationId, userId } = params;

  await ajax({
    path: `/organizations/${organizationId}/users/${userId}`,
    type: 'DELETE',
  });
};

export const searchUsers = async (params: { searchTerm: string }) => {
  const { searchTerm } = params;

  return await ajax({
    path: `/admin/search/users?query=${searchTerm}`,
    type: 'GET',
  });
};
