/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='41px' height='38px' viewBox='0 0 41 38' version='1.1'>
        <g
          id='Pages'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-65'
            transform='translate(-1051.000000, -457.000000)'
            fill='#E1EBF0'
          >
            <g id='Group-4' transform='translate(959.000000, 454.000000)'>
              <g id='Page-1' transform='translate(92.750000, 2.632653)'>
                <polygon
                  id='Fill-3'
                  points='5.25 21.9387755 5.25 37.7346939 14.875 37.7346939 14.875 24.5714286 25.375 24.5714286 25.375 37.7346939 35 37.7346939 35 21.9387755 20.125 7.45918367'
                />
                <path
                  d='M39.6417,19.2876939 L34.999825,14.7428571 L34.999825,4.3877551 L29.749825,4.3877551 L29.749825,9.60304082 L21.041825,1.07763265 C20.5317,0.578306122 19.71795,0.578306122 19.207825,1.07763265 L0.52745,19.3666735 C-0.23205,20.1108367 -0.173425,21.378898 0.719075,22.041449 C1.41995,22.5618367 2.41395,22.4249388 3.037825,21.8141633 L20.124825,5.08540816 L37.27745,21.8782245 C37.617825,22.2116939 38.058825,22.377551 38.499825,22.377551 C38.993325,22.377551 39.486825,22.1695714 39.836825,21.7553673 C40.4432,21.0375306 40.312825,19.9449796 39.6417,19.2876939'
                  id='Fill-4'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
