import React from 'react';
import DebitOrderPayments from './debit-order-payments';

export default class ProcessingPayments extends React.Component {
  render() {
    return (
      <div>
        <DebitOrderPayments />
      </div>
    );
  }
}
