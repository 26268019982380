import React from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { sentenceCase } from 'sentence-case';
import './domain-entity-card';

type PrimitiveRow<T extends { [k: string]: any }> = (keyof T extends Function ? never : keyof T);

export interface CustomRow<T> {
  heading: string;
  element: React.SFC<T>;
}

const isPrimitiveRow = (value: any): value is PrimitiveRow<any> => {
  return (!value.heading && !value.element);
};

export type RowToRender<T> = PrimitiveRow<T> | CustomRow<T>;

export interface DomainEntityCardProps<T> {
  domainEntity: T;
  rows: RowToRender<T>[];
  isLoading?: boolean;
}

export class DomainEntityCard<T> extends React.Component<DomainEntityCardProps<T>> {
  renderCellTitle = (row: RowToRender<T>) => {

    return <Col className={'col-title '}>
      <span style={{ fontWeight: 'bold' }}>
        {
          isPrimitiveRow(row) ?
            sentenceCase(row.toString()) :
            sentenceCase(row.heading)
        }
      </span>
    </Col>;
  }

  renderCellValue = (row: RowToRender<T>) => {
    let propertyToRender;

    if (isPrimitiveRow(row)) {
      const key = row;
      propertyToRender = _.get(this.props.domainEntity, key);

      if (moment.isMoment(propertyToRender)) {
        propertyToRender = propertyToRender.format('YYYY-MM-DD HH:mm');
      }

      return <Col className={'col-value'}>
        {propertyToRender && sentenceCase((propertyToRender.toString()))}
      </Col>;
    } else {
      return <Col className={'col-value'}>
        {row.element(this.props.domainEntity)}
      </Col>;
    }

  }

  getRandomWidth = (max: number = 90, min: number = 60) => {
    const ran = Math.random();
    return Math.round(((max - min) * ran) + min);
  }

  renderEmptyRows = () => {
    return <div>
      {
        this.props.rows.map((row, index) => <div key={index} className='empty-row-title'>
          <div style={{ width: `${this.getRandomWidth()}%` }} />
        </div>)
      }
    </div>;
  }

  render() {
    return (
      this.props.isLoading ?
        this.renderEmptyRows() :
        this.props.rows.map((row, index) => (
          <div key={`${row}_${index}`}>
            <Row className={'row-title'} style={{ marginBottom: 0 }}>
              {this.renderCellTitle(row)}
              {this.renderCellValue(row)}
            </Row>
            <Row className={'row-hr'} style={{ marginBottom: 0 }}>
              <Col>
                <hr className={'hr-style'} />
              </Col>
            </Row>
          </div>
        ))
    );
  }
}
