import { Moment, utc as moment } from 'moment';
import { Construct } from '../../shared/domain/construct';
import { Requestee } from '../../shared/domain/requestee';

export class ProductModule {
  readonly productModuleId: string;
  readonly createdAt: Moment;
  readonly restricted: boolean;
  readonly key: string;
  readonly name: string;
  readonly draftId: string;
  readonly liveId?: string;
  readonly reviewId?: string;
  readonly createdBy: Requestee;
  readonly testModeProductModuleDefinitionId: string;

  constructor(init: Construct<ProductModule>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: DbProductModule): ProductModule {
    return new ProductModule({
      productModuleId: init.product_module_id,
      key: init.key,
      name: init.name,
      draftId: init.draft_id,
      restricted: init.restricted,
      liveId: init.live_id,
      reviewId: init.review_id,
      createdAt: moment(init.created_at).utc(),
      createdBy: Requestee.fromJSON(init.created_by),
      testModeProductModuleDefinitionId: init.test_mode_product_module_definition_id ? init.test_mode_product_module_definition_id : init.draft_id,
    });
  }
}
export interface DbProductModule {
  product_module_id: string;
  restricted: boolean;
  key: string;
  name: string;
  draft_id: string;
  live_id?: string;
  review_id?: string;
  created_at: string;
  created_by: object;
  test_mode_product_module_definition_id: string;
}
