/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='108.141px'
        height='66.305px'
        viewBox='0 0 108.141 66.305'
        enableBackground='new 0 0 108.141 66.305'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <path
              fill='#9FC3E7'
              d='M17.395,18.129c0-2.75,2.25-5,5-5h63.319c2.75,0,5,2.25,5,5v42.167c0,2.75-2.25,5-5,5H22.395
                    c-2.75,0-5-2.25-5-5V18.129z'
            />
          </g>
          <g>
            <path
              fill='#CCDEF3'
              d='M17.395,16.346c0-2.75,2.25-5,5-5h63.319c2.75,0,5,2.25,5,5v42.167c0,2.75-2.25,5-5,5H22.395
                    c-2.75,0-5-2.25-5-5V16.346z'
            />
          </g>
          <path
            fill='#9FC3E7'
            d='M90.714,18.163L57.932,47.115c-2.013,1.778-5.045,1.778-7.056-0.001L17.594,17.722
                c-0.06-0.053-0.131-0.081-0.2-0.115v2.319l32.3,28.524c1.342,1.188,3.024,1.781,4.708,1.781s3.366-0.593,4.709-1.78l31.602-27.908
                V18.163z'
          />
          <path
            fill='none'
            stroke='#212B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='
                M90.714,63.116L58.09,34.304c-2.307-2.039-5.768-2.039-8.074,0L17.395,63.116'
          />
          <path
            fill='#CCDEF3'
            stroke='#212B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='
                M91.803,16.608L58.522,46c-2.353,2.081-5.884,2.081-8.237,0L17.004,16.608'
          />
          <circle fill='#212B40' cx='86.63' cy='16.345' r='15.347' />
          <path
            fill='#212B40'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M74.256,16.345
                c0,6.833,5.54,12.375,12.375,12.375c6.831,0,12.373-5.541,12.373-12.375S93.462,3.973,86.631,3.973
                C79.796,3.973,74.256,9.511,74.256,16.345z'
          />
          <g>
            <path
              fill='#FFFFFF'
              stroke='#212B40'
              strokeWidth='0.5'
              strokeMiterlimit='10'
              d='M87.294,22.269
                    c-0.667,0-1.226-0.56-1.226-1.223v-7.34l-1.312,1.347c-0.191,0.19-0.438,0.279-0.681,0.279c-0.542,0-1.029-0.456-1.029-1.031
                    c0-0.264,0.103-0.525,0.313-0.735l2.69-2.727c0.263-0.261,0.646-0.419,1.048-0.419c0.786,0,1.451,0.63,1.451,1.416v9.21
                    C88.55,21.709,87.992,22.269,87.294,22.269z'
            />
          </g>
        </g>
      </svg>
    );
  }
}
