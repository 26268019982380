import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { InputParagraphBlock as Block } from '../domain/input-paragraph-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputParagraphBlockState } from '../domain/states/input-paragraph-block-state';
import { FormGroup, Label, Input } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputParagraphBlockState;
}

interface State {
  UIState: BlockUIState;
  inputParagraphBlockState?: BlockState;
}

@observer
export class InputParagraphBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputParagraphBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { UIState, value } = this.validate(event.target.value);

    this.setState({
      UIState,
      inputParagraphBlockState: blockStateFactory.createBlock({
        ...this.state.inputParagraphBlockState,
        value,
      }),
    });
    event.preventDefault();
  };

  validate = (value: string) => {
    const { minLength, maxLength } = this.props.block;
    let UIState = BlockUIState.Normal;
    const num = value.length;

    if (minLength && num < minLength) {
      UIState = BlockUIState.Error;
    }

    if (maxLength && num > maxLength) {
      value = value.substring(0, maxLength);
    }

    return { UIState, value };
  };

  render() {
    const { block, blockState, disabled } = this.props;
    const { inputParagraphBlockState } = this.state;
    if (!inputParagraphBlockState) {
      return null;
    }

    const { value } = inputParagraphBlockState as InputParagraphBlockState;

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label>
          <b>{block.title}</b>
        </Label>
        <br />
        <Input
          id={block.key}
          disabled={disabled}
          key={block.key}
          type='textarea'
          defaultValue={blockState.value}
          max={block.maxLength}
          value={value}
          placeholder={block.placeholder}
          onChange={(e) => this.handleOnChange(e)}
        />
      </FormGroup>
    );
  }
}
