import { ajax } from '../helpers';
import { OrganizationProductModule } from './domain/organization-product-module';

export class OrganizationsApi {
  public static async getOrganizationProductModules(params: {
    organizationId: string;
  }): Promise<OrganizationProductModule[]> {
    const { organizationId } = params;
    const result = await ajax({
      path: `/insurance/admin/organizations/${organizationId}/product-modules/`,
      type: 'GET',
    });

    return result.map(OrganizationProductModule.fromNetwork);
  }
}
