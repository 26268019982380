/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='133px' height='43px' viewBox='0 0 133 43' version='1.1'>
        <defs>
          <path
            d='M26.443491,31.0777442 C25.8246762,31.4650951 23.6147757,31.0777442 18.2509863,31.0777442 C12.8870056,31.0777442 9.38238811,31.4650951 8.76356927,31.0777442 C8.59545153,30.9724473 10.0447786,29.5580025 10.0582931,29.3639291 C10.09761,28.7918621 10.3752798,27.5380499 12.0025974,26.9679729 C13.3739523,26.4874669 14.9386142,26.2738873 15.9542717,25.7263024 C16.5128888,25.4251352 16.5796415,24.6713476 16.0892247,24.2736354 C15.2488341,23.5916984 14.7856455,22.509666 14.5370531,21.4097189 C14.102528,21.0946226 13.1366223,19.2036478 14.0023948,18.8491473 C13.8099099,18.1922881 13.3567506,15.9748765 15.3844017,14.3703316 C17.6622703,12.5679442 21.119633,14.924099 22.0179679,13.233569 C22.0642496,13.1463785 22.1891515,13.1380238 22.2430126,13.2212286 C22.5171931,13.6447993 23.0496035,14.7334059 22.3503113,16.0214575 C22.4905767,16.6098353 22.6951504,17.7681148 22.4623215,18.8292467 C23.5967492,19.1290168 22.4334518,21.1047716 21.963902,21.4137045 C21.7151088,22.5122546 21.251923,23.5924927 20.4125566,24.2736354 C19.922331,24.6713476 19.9890974,25.4251352 20.5473048,25.7263024 C21.5631672,26.2738873 23.1278277,26.4874669 24.499386,26.9679729 C26.1264975,27.5380499 26.4043789,28.7918621 26.443491,29.3639291 C26.4569972,29.5580025 26.6118204,30.9724473 26.443491,31.0777442 Z'
            id='path-1'
          />
        </defs>
        <g
          id='Pages'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-65'
            transform='translate(-959.000000, -454.000000)'
          >
            <g id='Group-4' transform='translate(959.000000, 454.000000)'>
              <g id='Group-2-Copy'>
                <g id='Group'>
                  <rect
                    id='Rectangle-46'
                    stroke='#E1EBF0'
                    strokeWidth='2'
                    x='1'
                    y='1'
                    width='62.75'
                    height='41'
                    rx='2'
                  />
                  <rect
                    id='Rectangle-5'
                    stroke='#E1EBF0'
                    strokeWidth='2'
                    x='8.875'
                    y='12.4081633'
                    width='18.5368652'
                    height='18.5967395'
                  />
                  <rect
                    id='Rectangle-7-Copy-3'
                    fill='#E1EBF0'
                    x='35.875'
                    y='12.2857143'
                    width='22.75'
                    height='2.63265306'
                  />
                  <rect
                    id='Rectangle-7-Copy-4'
                    fill='#E1EBF0'
                    x='35.875'
                    y='20.622449'
                    width='22.75'
                    height='2.63265306'
                  />
                  <rect
                    id='Rectangle-7-Copy-5'
                    fill='#E1EBF0'
                    x='35.875'
                    y='28.9591837'
                    width='22.75'
                    height='2.63265306'
                  />
                  <mask id='mask-2' fill='white'>
                    <use xlinkHref='#path-1' />
                  </mask>
                  <use id='Clip-7' fill='#E1EBF0' xlinkHref='#path-1' />
                </g>
              </g>
              <g
                id='Group-3'
                transform='translate(72.625000, 14.040816)'
                stroke='#E1EBF0'
                strokeWidth='2'
                strokeLinecap='round'
              >
                <path d='M7,0.43877551 L7,13.6312601' id='Line' />
                <path d='M14,7.02040816 L0.845865669,7.02040816' id='Line' />
              </g>
              <g
                id='Page-1'
                transform='translate(92.750000, 2.632653)'
                fill='#E1EBF0'
              >
                <polygon
                  id='Fill-3'
                  points='5.25 21.9387755 5.25 37.7346939 14.875 37.7346939 14.875 24.5714286 25.375 24.5714286 25.375 37.7346939 35 37.7346939 35 21.9387755 20.125 7.45918367'
                />
                <path
                  d='M39.6417,19.2876939 L34.999825,14.7428571 L34.999825,4.3877551 L29.749825,4.3877551 L29.749825,9.60304082 L21.041825,1.07763265 C20.5317,0.578306122 19.71795,0.578306122 19.207825,1.07763265 L0.52745,19.3666735 C-0.23205,20.1108367 -0.173425,21.378898 0.719075,22.041449 C1.41995,22.5618367 2.41395,22.4249388 3.037825,21.8141633 L20.124825,5.08540816 L37.27745,21.8782245 C37.617825,22.2116939 38.058825,22.377551 38.499825,22.377551 C38.993325,22.377551 39.486825,22.1695714 39.836825,21.7553673 C40.4432,21.0375306 40.312825,19.9449796 39.6417,19.2876939'
                  id='Fill-4'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
