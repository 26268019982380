export class TemplateField {
  templateFieldId = '';
  name = '';
  value = '';

  constructor(init) {
    Object.assign(this, init);
  }

  static addSequence(templateFields) {
    return templateFields.map((t, i) => {
      return {
        sequence: i,
        name: t.name,
        value: t.value,
      };
    });
  }

  static fromNetwork(init) {
    return new TemplateField({
      sequence: init.sequence,
      name: init.name,
      value: init.value,
    });
  }

  toNetwork(templateField) {
    return {
      sequence: templateField.sequence,
      name: templateField.name,
      value: templateField.value,
    };
  }
}
