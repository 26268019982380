import * as React from 'react';
import '../styles/code-window.scss';
import PdfViewer from '../utils/pdf-viewer/pdf-viewer';
export default class TemplatePreview extends React.Component {
  render() {
    const documentName = this.props.documentName || '';
    return (
      <div className='template-preview' style={{ height: '100%' }}>
        {this.props.channel === 'sms' && this.props.content && (
          <PhonePreviewer {...this.props} documentName={documentName} />
        )}
        {this.props.channel === 'base64' && this.props.content && (
          <div className='pdf-viewer-wrapper'>
            <PdfViewer
              base64={this.props.content}
            />
          </div>
        )}
        {this.props.channel === 'html' && this.props.content && (
          <HtmlPreviewer {...this.props} documentName={documentName} />
        )}
      </div>
    );
  }
}

const HtmlPreviewer = props => (
  <iframe
    style={{
      border: '1px solid #eee',
      width: '100%',
      height: '100%',
      flex: '1',
    }}
    srcDoc={props.content}
  />
);

const PhonePreviewer = props => {
  return (
    <div className='phone-outer'>
      <div className='phone-decoration'>
        <div className='phone-speaker' />
        <div className='phone-light' />
      </div>

      <div className='phone-screen'>
        <div className='phone-text'>{props.content}</div>
      </div>
    </div>
  );
};
