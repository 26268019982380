import React from 'react';
import { Button } from 'reactstrap';
import Images from '../../../../../components/svg-images';
import ajax from '../../../../../helpers/ajax';

interface ValueDefinition {
  key: string;
  example: string;
  description: string;
}

interface HandlebarsHelper {
  key: string;
  aliases?: string [];
  title: string;
  summary: string;
  values?: ValueDefinition[];
  format: string;
  example: string;
}

interface Props {}

interface State {
  scrollRefs: { [key: string]: React.RefObject<{}> };
  helpers: HandlebarsHelper[];
}

export class HelpersModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scrollRefs: {},
      helpers: [],
    };
  }

  componentWillMount = async () => {
    const response = await ajax({
      path: '/insurance/admin/handlebars/helpers',
      type: 'get',
    });

    response.forEach((h: HandlebarsHelper) => {
      const scrollRefs = this.state.scrollRefs;
      const scrollRefIndex = h.key;
      scrollRefs[scrollRefIndex] = React.createRef();
      this.setState({
        scrollRefs,
      });
    });

    this.setState({
      helpers: response,
    });
  }

  copyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    const btnPos = document.getElementsByClassName('copyBtn')[0];
    btnPos.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch {
      console.error('Could not copy handlebar helper definition to clipboard');
    }

    btnPos.removeChild(textArea);
  }

  renderValueRows(values: ValueDefinition[]) {
    return values.map(v => {
      return (
        <tr key={`${v.key}-tr`}>
          <td
            className='data-export-row-item'
            style={{ fontFamily: 'monospace', padding: '5px 0px 5px 0px' }}
          >
            {v.key}
          </td>
          <td
            className='data-export-row-item'
            style={{ padding: '5px 0px 5px 0px' }}
          >
            {v.description}
          </td>
          <td
            className='data-export-row-item'
            style={{ padding: '5px 0px 5px 0px' }}
          />
        </tr>
      );
    });
  }

  renderHelper(helper: HandlebarsHelper, verbose: boolean) {
    if (verbose) {
      return (
        <div style={{ padding: '16px' }} className={helper.key} key={`${helper.key}-div`}>
          <h4>{helper.key}</h4>
          <table className='handlebars-helpers-table'>
            <tbody>
              <tr>
                <td
                  className='data-export-row-item'
                  style={{
                    width: '20%',
                    fontWeight: 'bold',
                  }}
                >
                  {'Format'}
                </td>
                <td
                  className='data-export-row-item'
                  style={{
                    width: '70%',
                    fontFamily: 'monospace',
                  }}
                >
                  {helper.format}
                </td>
                <td
                  className='data-export-row-item'
                  style={{ width: '10%' }}
                >
                  <Button
                    className='copyBtn'
                    color='link'
                    onClick={() => this.copyToClipboard(helper.format)}
                  >
                    <Images.copy />
                  </Button>
                </td>
              </tr>
              <tr>
                <td
                  className='data-export-row-item'
                  style={{
                    width: '20%',
                    fontWeight: 'bold',
                  }}
                >
                  {'Example'}
                </td>
                <td
                  className='data-export-row-item'
                  style={{
                    width: '70%',
                    fontFamily: 'monospace',
                  }}
                >
                  {helper.example}
                </td>
                <td
                  className='data-export-row-item'
                  style={{ width: '10%' }}
                />
              </tr>
              <tr>
                <td
                  className='data-export-row-item'
                  style={{
                    width: '20%',
                    fontWeight: 'bold',
                  }}
                >
                  {'Summary'}
                </td>
                <td
                  className='data-export-row-item'
                  style={{ width: '70%' }}
                >
                  {helper.summary}
                </td>
                <td
                  className='data-export-row-item'
                  style={{ width: '10%' }}
                />
              </tr>
              {this.renderValueRows(helper.values ? helper.values : [])}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div key={`${helper.key}-div`}>
          <a
            className='handlebars-helper-modal-btn'
            href='#'
            onClick={() => this.handleScroll(`${helper.key}`)}
          >
            {helper.key}
          </a>
          <br />
        </div>
      );
    }
  }

  handleScroll(helperName: string) {
    const parent = document.getElementsByClassName('detail')[0];
    const target = document.getElementsByClassName(helperName)[0];
    if (target && parent) {
      this.scrollTo(parent as HTMLElement, (target as HTMLElement).offsetTop - 60, 500);
    }
  }

  scrollTo(element: HTMLElement, to: number, duration: number) {
    if (to < 0) {
      to = 0;
    }

    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) {
        return (c / 2) * t * t + b;
      }
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  renderHelperList() {
    const helperList = this.state.helpers.map(h => this.renderHelper(h, false));
    helperList.push(<div key={'padding-1-div'} style={{ height: '200px', width: '200px'}}></div>);
    helperList.push(<div key={'padding-2-div'} style={{ height: '200px', width: '200px'}}></div>);
    helperList.push(<div key={'padding-3-div'} style={{ height: '200px', width: '200px'}}></div>);
    return helperList;
  }

  renderHelperDetail() {
    const detailList = this.state.helpers.map(h => this.renderHelper(h, true));
    detailList.push(<div key={'padding-1-div'} style={{ height: '200px', width: '200px'}}></div>);
    detailList.push(<div key={'padding-2-div'} style={{ height: '200px', width: '200px'}}></div>);
    detailList.push(<div key={'padding-3-div'} style={{ height: '200px', width: '200px'}}></div>);
    detailList.push(<div key={'padding-4-div'} style={{ height: '200px', width: '200px'}}></div>);
    return detailList;
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className='master'>{this.renderHelperList()}</div>
        <div className='detail'>{this.renderHelperDetail()}</div>
      </div>
    );
  }
}
