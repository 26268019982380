import moment, { Moment } from 'moment';
import { Requestee } from '../../../../shared/domain/requestee';
import { Construct } from '../../../../shared/domain/construct';

export class ReportRun {
  readonly reportRunId: string;
  readonly reportId: string;
  readonly attachmentId: string;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;

  constructor(init: Construct<ReportRun>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkReportRun {
    return {
      report_run_id: this.reportId,
      report_id: this.reportId,
      attachment_id: this.attachmentId,
      created_at: this.createdAt.toISOString(),
      created_by: this.createdBy.toJSON(),
    };
  }

  static fromNetwork(init: NetworkReportRun): ReportRun {
    return new ReportRun({
      reportRunId: init.report_run_id,
      reportId: init.report_id,
      attachmentId: init.attachment_id,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
    });
  }
}

export class NetworkReportRun {
  report_run_id: string;
  report_id: string;
  attachment_id: string;
  created_at: string;
  created_by: object;
}
