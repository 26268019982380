import * as React from 'react';
import { PayoutRequestListStore } from './stores/payout-request-list-store';
import { observer } from 'mobx-react';
import {
  PayoutRequestType,
  PayoutRequestStatus,
  PayoutRequest,
} from './domain/payout-request';
import { startCase } from 'lodash';
import Pager from '../../../components/pager';
import { FilterBox } from './components/filter-box';
import { NoEntitiesFound } from './components/no-entities-found';
import { FileModal } from '../../../components/file-modal';
import { RejectPayoutModal } from './components/reject-payout-modal';
import {
  DomainEntityTable,
  ColumnToRender,
} from '../../../components/domain/tables/domain-entity-table';
import { OrganizationListStore } from '../../../organizations/stores/organization-list-store';
import {
  FinalisePayout,
  PolicyLink,
  FormatAmount,
  OrganizationLink,
  ShortId,
  PayoutRequestStatusDisplay,
} from './components/table-helpers';

import { RouteComponentProps } from 'react-router';

interface PayoutRequestListProps {
  router: RouteComponentProps;
  organizationListStore?: OrganizationListStore;
}

interface PayoutRequestListState {
  payoutRequestListStore: PayoutRequestListStore;
}

@observer
export class PayoutRequestList extends React.Component<PayoutRequestListProps,PayoutRequestListState> {
  constructor(props: PayoutRequestListProps) {
    super(props);
    this.state = {
      payoutRequestListStore: new PayoutRequestListStore(),
    };

    this.state.payoutRequestListStore.initialize(this.payoutRequestType);
  }

  componentDidUpdate() {
    if (
      this.payoutRequestType !== this.state.payoutRequestListStore.filterType
    ) {
      this.state.payoutRequestListStore.initialize(this.payoutRequestType);
    }
  }

  get payoutRequestType() {
    const [, , routeType] = this.props.router.location.pathname.split('/');
    return RoutePayoutRequestTypes[routeType] as PayoutRequestType;
  }

  finalisePayoutColumn = () => {
    return {
      heading: ' ',
      element: (payoutRequest: PayoutRequest) => (
        <FinalisePayout
          payoutRequest={payoutRequest}
          onConfirm={
            this.state.payoutRequestListStore.openPayoutConfirmationModal
          }
          onReject={this.state.payoutRequestListStore.openPayoutRejectionModal}
        />
      ),
    };
  };

  // this should change to a link once we have claims tooling in Alfred
  claimColumn = () => {
    return {
      heading: 'Claim',
      element: (payoutRequest: PayoutRequest) => (
        <div className='table-action'>
          {payoutRequest.linkedEntities &&
            payoutRequest.linkedEntities.claimId &&
            payoutRequest.linkedEntities.claimId.toString()}
        </div>
      ),
    };
  };

  policyColumn = () => {
    return {
      heading: 'Policy',
      element: PolicyLink,
    };
  };

  currencyColumn = () => {
    return {
      heading: 'Amount',
      element: FormatAmount,
    };
  };

  statusColumn = () => {
    return {
      heading: 'Status',
      element: PayoutRequestStatusDisplay,
    };
  };

  orgColumn = () => {
    return {
      heading: 'Organization',
      element: OrganizationLink,
    };
  };

  idColumn = () => {
    return {
      heading: 'Id',
      element: ShortId,
    };
  };

  get payoutTypeColumnsToRender(): ColumnToRender<PayoutRequest>[] {
    switch (this.payoutRequestType) {
      case PayoutRequestType.ClaimPayout:
        return [this.claimColumn()];
      case PayoutRequestType.PolicyRefund:
        return [this.policyColumn()];
      default:
        return [];
    }
  }

  get shouldRenderPagination() {
    return (
      this.state.payoutRequestListStore.isLoaded &&
      this.state.payoutRequestListStore.payoutRequests.length !== 0
    );
  }

  get shouldRenderEmptyState() {
    return (
      this.state.payoutRequestListStore.isLoaded &&
      this.state.payoutRequestListStore.payoutRequests.length === 0
    );
  }

  get subMenu() {
    return (
      <div className='sub-menu'>
        <FilterBox
          label='Status:'
          value={this.state.payoutRequestListStore.filterStatus}
          options={Object.values(PayoutRequestStatus).map(s => ({
            value: s,
            label: startCase(s),
          }))}
          onChange={this.state.payoutRequestListStore.setFilterStatus}
        />
      </div>
    );
  }

  get error() {
    return (
      <div>
        {this.state.payoutRequestListStore.errorMessage &&
          this.state.payoutRequestListStore.errorMessage}
      </div>
    );
  }

  get table() {
    return this.shouldRenderEmptyState ? (
      <NoEntitiesFound entityNames='payout requests' />
    ) : (
      <PayoutsTable
        rows={this.state.payoutRequestListStore.payoutRequests}
        columns={[
          this.idColumn(),
          'createdAt',
          'actionDate',
          this.orgColumn(),
          this.currencyColumn(),
          this.statusColumn(),
          ...this.payoutTypeColumnsToRender,
          this.finalisePayoutColumn(),
        ]}
        isLoaded={this.state.payoutRequestListStore.isLoaded}
      />
    );
  }

  get pagination() {
    return (
      this.shouldRenderPagination && (
        <>
          <Pager
            currentPage={this.state.payoutRequestListStore.pageNumber}
            maxPages={this.state.payoutRequestListStore.maxNumberOfPages}
            maxPagers={2}
            onClick={this.state.payoutRequestListStore.setPageNumber}
          />
          {this.state.payoutRequestListStore.isLoaded && (
            <p className='info-text'>
              {this.state.payoutRequestListStore.paginationText}
            </p>
          )}
        </>
      )
    );
  }

  render() {
    const payoutRequestListStore = this.state.payoutRequestListStore;

    return (
      <div>
        <h3>{startCase(this.payoutRequestType)}s</h3>

        {this.subMenu}

        {this.error}

        {this.table}

        {this.pagination}

        <FileModal
          open={payoutRequestListStore.confirmationModalOpen}
          header='Proof of payment'
          description='A proof of payment document is required to complete a payout request.'
          inputLabel='Proof of payment'
          onClose={payoutRequestListStore.closePayoutConfirmationModal}
          confirmMessage={() => 'Do you wish to proceed?'}
          handleSubmit={file =>
            payoutRequestListStore.confirmPayoutRequest(file)
          }
          accept={['.pdf', '.jpg']}
        />

        <RejectPayoutModal
          isOpen={payoutRequestListStore.rejectionModalOpen}
          onConfirm={payoutRequestListStore.rejectPayoutRequest}
          onClose={payoutRequestListStore.closePayoutRejectionModal}
        />
      </div>
    );
  }
}

class PayoutsTable extends DomainEntityTable<PayoutRequest> {}

export const RoutePayoutRequestTypes: {
  [k: string]: PayoutRequestType;
} = {
  'claim-payouts': PayoutRequestType.ClaimPayout,
  'policy-refunds': PayoutRequestType.PolicyRefund,
};
