import React from 'react';
import Images from '../../../../../../components/svg-images';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import {
  DisplayTemplateFieldRow,
  EditTemplateFieldRow,
  FilterRow,
} from './template-fields-form-table';
import { ajax } from '../../../../../../helpers';
import { ProductModule } from '../../../../../../product-modules/domain/product-module';
import { OrganizationProductModule } from '../../../../../domain/organization-product-module';
import { observer, inject } from 'mobx-react';
import { TemplateViewStore } from '../../../stores/template-view-store';
import { TemplateApi } from '../../../template-api';
import { TemplateDataSource } from '../../../domain/templates/template';


export interface TemplateData {
  fields: any;
  dataSource: TemplateDataSource;
  productModuleId: string;
  templateName: string;
  description: string;
  restricted: boolean;
  filter?: string;
}
export interface TemplateField {
  index: number;
  name: string;
  value: string;
  exampleResult?: string;
  editing?: boolean;
}

export interface TemplateFieldData {
  sequence: number;
  index: number;
  name: string;
  value: string;
  exampleResult: string;
}

interface Props {
  templateData: TemplateData;
  templateFieldsData: TemplateFieldData[];
  changed: (valid: boolean, templateFields: any, filter?: string) => void;
  productModules: ProductModule[] | OrganizationProductModule[];
  updating: boolean;
}

interface Injected extends Props {
  templateViewStore: TemplateViewStore;
}

interface State {
  updating: boolean;
  editingIndex: number;
  templateFields: TemplateField[];
  prevTemplateFields: TemplateFieldData[];
  handlebarResults?: string[];
  filterHandlebarResult?: string;
  filter: string;
}

interface CompilationError {
  message: string;
}

const formatExampleResultError = (error: CompilationError) => {
  return (
    <div className='handlebars-example-error'>
      <Images.errorOrange />
      <p>{`${JSON.stringify(error).substr(0, 30)}`}</p>
    </div>
  );
};

const formatExampleResult = (text: string) => {
  if (text.trim().length > 0) {
    return <span className='handlebars-example-result'>{text.toString()}</span>;
  } else {
    return (
      <div className='handlebars-example-error'>
        <Images.errorOrange /> <p>Expression is null</p>
      </div>
    );
  }
};

const compileHandlebars = async (params: {
  values: string[];
  dataSource: TemplateDataSource;
  organizationId: string;
}) => {
  const { values, dataSource, organizationId } = params;
  const mergeVars = await TemplateApi.getDataExportTemplateMergeVariables({
    dataSource,
    organizationId,
  });

  return await ajax({
    path: '/insurance/admin/handlebars',
    type: 'post',
    data: values.map(v => ({
      content: v,
      merge_vars: mergeVars,
      fake_nip: true,
    })),
  });
};

export const generateFormattedExampleResult = async (params: {
  values: string[];
  dataSource: TemplateDataSource;
  productModules: OrganizationProductModule[] | ProductModule[];
  organizationId: string;
}) => {
  const { values, dataSource, organizationId } = params;

  try {
    const response = await compileHandlebars({
      values,
      dataSource,
      organizationId,
    });

    return response.result.map((r: any) => r.error ? formatExampleResultError(r.error) : formatExampleResult(r));
  } catch (error) {
    console.log(error);
  }
};

@inject('templateViewStore')
@observer
export class TemplateFieldsForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      updating: false,
      editingIndex: -1,
      templateFields: [],
      prevTemplateFields: [],
      filter: this.injected.templateViewStore.template && this.injected.templateViewStore.template.filter || '',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  componentDidMount = () => {
    const { templateViewStore } = this.injected;
    if (templateViewStore.template) {
      const sortedTemplateFields = templateViewStore.template.fields.sort(
        (a, b) => {
          return a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0;
        },
      );
      this.setState({
        templateFields: sortedTemplateFields,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields, this.state.filter);
        this.compileTemplateFields();
        this.compileFilterField();
      });
    }
  };

  compileTemplateFields = async () => {
    const { templateData } = this.injected;
    const organizationId = window.location.pathname.split('/')[2];

    const handlebarResults = await generateFormattedExampleResult({
      values: this.state.templateFields.map(t => t.value),
      dataSource: templateData.dataSource,
      productModules: this.props.productModules,
      organizationId,
    });

    this.setState({
      ...this.state,
      handlebarResults,
    });
  };

  compileFilterField = async () => {
    const { templateViewStore } = this.injected;
    const organizationId = window.location.pathname.split('/')[2];

    if (this.state.filter && templateViewStore.template) {
      const [filterHandlebarResult] = await generateFormattedExampleResult({
        values: [this.state.filter],
        dataSource: templateViewStore.template.dataSource,
        productModules: this.props.productModules,
        organizationId,
      });

      this.setState({
        ...this.state,
        filterHandlebarResult,
      });
    }
  };

  reorderColumn = (direction: string, index: number) => {
    if (!this.state.updating) {
      const templateFields = this.state.templateFields;
      const prevTemplateFields = this.state.prevTemplateFields;
      const tempTemplateField = templateFields[index];
      const tempPrevTemplateField = prevTemplateFields[index];

      if (direction === 'down' && index + 1 < templateFields.length) {
        templateFields[index] = templateFields[index + 1];
        templateFields[index + 1] = tempTemplateField;

        prevTemplateFields[index] = prevTemplateFields[index + 1];
        prevTemplateFields[index + 1] = tempPrevTemplateField;
      } else if (direction === 'up' && index - 1 >= 0) {
        templateFields[index] = templateFields[index - 1];
        templateFields[index - 1] = tempTemplateField;

        prevTemplateFields[index] = prevTemplateFields[index - 1];
        prevTemplateFields[index - 1] = tempPrevTemplateField;
      }

      this.setState(
        {
          ...this.state,
          templateFields: templateFields,
          prevTemplateFields: prevTemplateFields,
        },
        () => {
          this.props.changed(this.templatesValid(), this.state.templateFields);
          this.compileTemplateFields();
        },
      );
    }
  };

  duplicateColumn = (index: number) => {
    const templateFields = this.state.templateFields;
    const prevTemplateFields = this.state.prevTemplateFields;
    templateFields.splice(index, 0, Object.assign({}, templateFields[index]));
    prevTemplateFields.splice(
      index,
      0,
      Object.assign({}, prevTemplateFields[index]),
    );

    this.setState(
      {
        ...this.state,
        templateFields: templateFields,
        prevTemplateFields: prevTemplateFields,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields);
        this.compileTemplateFields();
      },
    );
  };

  deleteColumn = async (index: number) => {
    const templateFields = this.state.templateFields;
    const prevTemplateFields = this.state.prevTemplateFields;
    templateFields.splice(index, 1);
    prevTemplateFields.splice(index, 1);

    this.setState(
      {
        ...this.state,
        templateFields: templateFields,
        prevTemplateFields: prevTemplateFields,
      },
      () => {
        this.compileTemplateFields();
        this.props.changed(this.templatesValid(), this.state.templateFields);
      },
    );
  };

  updateIndexes = (templateFields: TemplateField[]): TemplateField[] => {
    let i = 0;

    return templateFields.map((t: any) => {
      t.index = i;
      i++;
      return t;
    });
  };

  validateName = (index: number, name: string) => {
    const templateFields = this.state.templateFields;
    templateFields[index].name = name;

    this.setState(
      {
        ...this.state,
        templateFields: templateFields,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields);
      },
    );
  };

  validateFieldValue = (index: number, value: string) => {
    const templateFields = this.state.templateFields;
    templateFields[index].value = value;

    this.setState(
      {
        ...this.state,
        templateFields: templateFields,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields);
      },
    );
  };

  validateFilterValue = (value: string) => {
    this.setState(
      {
        ...this.state,
        filter: value,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields, this.state.filter);
      },
    );
  };

  templatesValid = () => {
    const templateFieldsArray = this.state.templateFields;

    if (templateFieldsArray.length > 0) {
      const checkEmptyFields = templateFieldsArray.filter(
        t => t.value && /\S/.test(t.value),
      );

      if (checkEmptyFields.length !== this.state.templateFields.length) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  defocusAll = () => {
    let templateFields = this.state.templateFields;
    templateFields = templateFields.map((t, i) => {
      t.editing = false;
      return t;
    });
    this.setState({
      ...this.state,
      editingIndex: -1,
      templateFields: templateFields,
    });
  };

  addTemplateField = () => {
    const templateFieldsList = this.state.templateFields;
    const prevTemplateFieldsList = this.state.prevTemplateFields;

    const newField = {
      sequence: templateFieldsList.length,
      index: templateFieldsList.length,
      name: '',
      value: '',
      exampleResult: '',
    };

    templateFieldsList.push(newField);
    prevTemplateFieldsList.push(newField);

    this.setState({
      ...this.state,
      editingIndex: templateFieldsList.length - 1,
      templateFields: templateFieldsList,
      prevTemplateFields: prevTemplateFieldsList,
    });
  };

  editTemplateField = (index: number) => {
    let templateFields = this.state.templateFields;
    templateFields = templateFields.map((t, i) => {
      i === index ? (t.editing = true) : (t.editing = false);
      return t;
    });

    this.setState({
      ...this.state,
      editingIndex: index,
      templateFields: templateFields,
    });
  };

  updateTemplateFields = () => {
    this.setState(
      {
        ...this.state,
        editingIndex: -1,
      },
      () => {
        this.props.changed(this.templatesValid(), this.state.templateFields);
      },
    );
  };

  render() {
    return (
      <div>
        <Card className='template-editor-card'>
          <CardHeader>
            <h5>Filter</h5>
          </CardHeader>
          <CardBody>
            <table
              style={{
                margin: '1rem auto auto auto',
                alignSelf: 'centre',
                width: '100%',
              }}
            >
              <thead>
                <tr>
                  <th className='scheduled-export-heading-item'>DEFINITION</th>
                  <th className='scheduled-export-heading-item'>EXAMPLE RESULT</th>
                </tr>
              </thead>
              <tbody>
                <FilterRow
                  filter={this.state.filter}
                  handlebarResult={this.state.filterHandlebarResult}
                  compileHandlebars={this.compileFilterField}
                  validateValue={this.validateFilterValue}
                />
              </tbody>
            </table>
          </CardBody>
        </Card>
        <br />
        <Card className='template-editor-card'>
          <CardHeader>
            <h5>Fields</h5>
          </CardHeader>
          <CardBody>
            <table
              style={{
                margin: '1rem auto auto auto',
                alignSelf: 'centre',
                width: '100%',
              }}
            >
              <thead>
                <tr>
                  <th className='scheduled-export-heading-item' />
                  <th className='scheduled-export-heading-item'>FIELD NAME</th>
                  <th className='scheduled-export-heading-item'>DEFINITION</th>
                  <th className='scheduled-export-heading-item'>EXAMPLE RESULT</th>
                  <th className='scheduled-export-heading-item' />
                </tr>
              </thead>
              <tbody>
                {this.state.templateFields.map((t, i) => {
                  if (i === this.state.editingIndex) {
                    return (
                      <EditTemplateFieldRow
                        key={`template-field-row-${i}`}
                        index={i}
                        handlebarResult={
                          this.state.handlebarResults
                            ? this.state.handlebarResults[i]
                            : ''
                        }
                        compileHandlebars={this.compileTemplateFields}
                        numRows={this.state.templateFields.length}
                        templateField={t}
                        reorderColumn={this.reorderColumn}
                        revertField={this.state.prevTemplateFields[i]}
                        confirmEdit={this.updateTemplateFields}
                        validateName={this.validateName}
                        validateValue={this.validateFieldValue}
                        updating={this.props.updating}
                        duplicateColumn={this.duplicateColumn}
                        deleteColumn={this.deleteColumn}
                        invalidateTemplateFields={() =>
                          this.props.changed(false, this.state.templateFields)
                        }
                      />
                    );
                  } else {
                    return (
                      <DisplayTemplateFieldRow
                        key={`template-field-row-${i}`}
                        index={i}
                        handlebarResult={
                          this.state.handlebarResults
                            ? this.state.handlebarResults[i]
                            : ''
                        }
                        compileHandlebars={this.compileTemplateFields}
                        numRows={this.state.templateFields.length}
                        templateField={t}
                        reorderColumn={this.reorderColumn}
                        duplicateColumn={this.duplicateColumn}
                        deleteColumn={this.deleteColumn}
                        enterEdit={this.editTemplateField}
                      />
                    );
                  }
                })}
              </tbody>
            </table>
            <Button
              color='primary'
              className='template-field-editor-button'
              onClick={() => {
                this.defocusAll();
                this.addTemplateField();
              }}
            >
              <strong> + </strong> Add field
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}

