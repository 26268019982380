import { observable, computed, action } from 'mobx';
import { TemplateApi } from '../template-api';
import { Template } from '../domain/templates/template';
import { Pagination } from '../../../../shared/domain/pagination';
import { debounce } from '../../../../helpers/debounce';

export class TemplateListStore {
  @observable templates: Template[] = [];
  @observable isLoading = true;
  @observable pagination: Pagination = { page: 0, pageSize: 10 };
  @observable total: number = 0;
  @observable organizationId: string = '';

  load = async (params: {
    organizationId: string;
    pagination?: Pagination;
    search?: string;
    ignorePagination?: boolean;
  }) => {
    const { organizationId, pagination, search, ignorePagination } = params;

    this.organizationId = organizationId;
    this.isLoading = true;

    try {
      const { templates, total } = await TemplateApi.get({
        organizationId,
        page: pagination ? pagination.page : this.pagination.page + 1,
        pageSize: pagination ? pagination.pageSize : this.pagination.pageSize,
        search,
        ignorePagination,
      });
      this.templates = templates;
      this.total = total;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !this.isLoading && this.templates.length === 0;
  }

  @action create = async (params: {
    organizationId: string;
    payload: Template;
  }) => {
    const { organizationId, payload } = params;
    this.isLoading = true;
    try {
      const template = await TemplateApi.add({ organizationId, payload });
      this.templates = [...this.templates, template];
      return template;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  setTemplateListStoreLoadingTrue = () => {
    this.isLoading = true;
  };

  goToPage = (pageNumber: number, search?: string) => {
    this.pagination.page = pageNumber;
    debounce('go-to-page', () =>
      this.load({ organizationId: this.organizationId, search }),
    );
  };
}

export const templateListStore = new TemplateListStore();
