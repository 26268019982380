export const filterUsers = (users, searchQuery) => {
  const phrase = searchQuery.toLowerCase();
  // Filter list
  return users.filter(u => {
    // Search first and last name
    return (
      (u.firstName + ' ' + u.lastName).toLowerCase().indexOf(phrase) !== -1 ||
      // Search email
      u.email.toLowerCase().indexOf(phrase) !== -1 ||
      // Search ID Number
      (u.idNumber + '').indexOf(phrase) !== -1 ||
      // Search bank number
      (u.bankNumber + '').indexOf(phrase) !== -1 ||
      // Search cellphone
      (u.cellphone + '').indexOf(phrase) !== -1
    );
  });
};
