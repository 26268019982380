export enum EmailRecipientType {
  To = 'to',
  Cc = 'cc',
  Bcc = 'bcc',
}

export interface EmailRecipient {
  email: string;
  name?: string;
  type?: EmailRecipientType;
}
