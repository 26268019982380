export const productModuleMemberModuleChange = `
declare class MemberModuleChange {
  member_id: string;
  end_date: Moment;
  module: { type: string; [key: string]: any };

  constructor(init: MemberModuleChange) {
    Object.assign(this, init);
  };
}
`;

