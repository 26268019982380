import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import { toSentenceCase } from '../../helpers';
import { get, renderValue } from '../helpers';
import moment from 'moment';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class PeachBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const env = runtimeEnv();
    const peachBatchUrl =
      env.REACT_APP_COLLECT_HOST +
      '/peach-batches/' +
      this.props.params.peachBatchId;
    const peachBatch = await get(peachBatchUrl);

    const peachCallbacksUrl =
      env.REACT_APP_COLLECT_HOST +
      '/peach-batches/' +
      this.props.params.peachBatchId +
      '/callbacks';
    const peachCallbacks = await get(peachCallbacksUrl);
    this.setState({ peachBatch, peachCallbacks });
    console.log('componentDidMount');
  }

  renderPeachCallbackRow(peachCallback) {
    return (
      <tr
        key={peachCallback.peachCallbackId}
        onClick={() =>
          this.props.router.push(
            '/collect/peach-callbacks/' + peachCallback.peachCallbackId,
          )
        }
      >
        <td>{moment(peachCallback.createdAt).format('DD MMM YYYY, hh:mm')}</td>
        <td>{peachCallback.peachBatchId}</td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Peach Batch</CardHeader>
          <CardBody>
            {this.state.peachBatch ? (
              <Table size='sm'>
                <tbody>
                  {Object.keys(this.state.peachBatch)
                    .filter(
                      k =>
                        k !== 'requestXml' &&
                        k !== 'responseXml' &&
                        k !== 'results',
                    )
                    .map(k => (
                      <tr key={k}>
                        <td>
                          <b>{toSentenceCase(k)}</b>
                        </td>
                        <td>{renderValue(k, this.state.peachBatch[k])}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />

        <Card>
          <CardHeader>Peach Callbacks</CardHeader>
          <CardBody>
            {this.state.peachCallbacks ? (
              <Table hover>
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Batch Id</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.peachCallbacks.map(o =>
                    this.renderPeachCallbackRow(o),
                  )}
                </tbody>
              </Table>
            ) : (
              <div>
                <i>Loading peach callbacks...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />

        <Card>
          <CardHeader>Request Payload</CardHeader>
          <CardBody>
            {this.state.peachBatch ? (
              <div>
                {renderValue('requestXml', this.state.peachBatch.requestXml)}
              </div>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />

        <Card>
          <CardHeader>Response Payload</CardHeader>
          <CardBody>
            {this.state.peachBatch ? (
              <div>
                {renderValue('responseXml', this.state.peachBatch.responseXml)}
              </div>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />

        <Card>
          <CardHeader>Results</CardHeader>
          <CardBody>
            {this.state.peachBatch ? (
              <div>{renderValue('results', this.state.peachBatch.results)}</div>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(PeachBatch);
