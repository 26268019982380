import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { ProductModuleDefinitionSchemaIndexStructure } from '../../product-module-json-to-html/orchestration';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import { ProductModuleJsonHtml } from '../../product-module-json-to-html';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}
@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionQuoteSchema extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    const { productModuleStore } = this.injected;
    const productModuleDefinitionSchemaId = productModuleStore.productModuleDefinitionDraft[ProductModuleDefinitionSchemaIndexStructure.quoteSchemaId];
    return (
      <ProductModuleJsonHtml
        productModuleDefinitionSchemaId={productModuleDefinitionSchemaId}
        toDbKey={'quote_schema_json'}
        breadcrumbDocName={'Quote schema form'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  async componentWillMount() {
    const { productModuleKey, productModuleStore } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
  }

  render() {
    const { productModuleStore } = this.props;

    if (!productModuleStore) {
      return <LoadingInPage />;
    }
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <span>
            Pricing comprises of the logic that is used to create a quote, application and policy.
          </span>
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top ' />
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Quote schema
              </p>
            </Col>
            <Col xs={9}>
              <p>Quote schema</p>
              <span>
                The input model defines what data is used to produce a
                quotation. Use this json schema editor to define the input. You
                can read about input model and view examples in the
                documentation.
              </span>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  className='product-module-definition-horizontal-card-button'
                  color='primary'
                  style={{ margin: 0 }}
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
