import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class FulfillmentRequestBlock extends Block {
  readonly title: string;
  readonly description?: string;
  readonly fulfillmentTypeKey: string;
  readonly fulfillmentData: { [key: string]: any };

  constructor(init: Construct<FulfillmentRequestBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      fulfillment_type_key: this.fulfillmentTypeKey,
      fulfillment_data: this.fulfillmentData,
    };
  }

  static fromJSON(init: any) {
    return new FulfillmentRequestBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      fulfillmentTypeKey: init.fulfillment_type_key,
      fulfillmentData: init.fulfillment_data,
    });
  }
}
