import { ProductModule } from '../domain/product-module';

export const productModuleRoutes = (productModule: ProductModule) => {
  const { key } = productModule;
  return [
    {
      name: 'Overview',
      url: `/product-modules/${key}`,
    },
    {
      name: 'Deployment',
      url: `/product-modules/${key}/deployment`,
    },
    {
      name: 'Version history',
      url: `/product-modules/${key}/version-history`,
    },
    {
      name: 'Run logs',
      url: `/product-modules/${key}/runs`,
    },
  ];
};
