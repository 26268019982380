import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Template } from './data-exports/domain/templates/template';
import { ScheduledDataExport } from './data-exports/domain/scheduled-data-export';
import { TemplateListStore } from './data-exports/stores/template-list-store';
import { DataExportListStore } from './data-exports/stores/data-export-list-store';
import { DataExportRow } from './data-export-row';
import { PaginationComponent } from '../../components/pagination';

interface Props {}

interface RunningExportsTableInjected extends Props {
  templateListStore: TemplateListStore;
  dataExportListStore: DataExportListStore;
}

@inject('templateListStore')
@inject('dataExportListStore')
@observer
export class RunningExportsTable extends Component<Props, any> {
  get injected() {
    return this.props as RunningExportsTableInjected;
  }

  componentWillMount() {
    const { dataExportListStore, templateListStore } = this.injected;

    dataExportListStore.load({ organizationId: this.organizationId });
    templateListStore.load({ organizationId: this.organizationId });
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    const { templateListStore, dataExportListStore } = this.injected;

    const { scheduledDataExports, isLoading, total, pagination } = dataExportListStore;
    const { templates } = templateListStore;
    const pageChangeHandler = (newPage: number) => dataExportListStore.goToPage(newPage);

    return (
      <div>
        <table style={{ width: '100%' }}>
          <DataExportsTableHeader />
          {isLoading && <EmptyTableBody />}
          {!isLoading && (
            <DataExportTableBody
              templates={templates}
              scheduledDataExports={scheduledDataExports}
            />
          )}
        </table>
        <PaginationComponent
          page={pagination.page || 0}
          limit={pagination.pageSize}
          total={total || 0}
          disabled={isLoading}
          goToPage={pageChangeHandler}
        />
      </div>
    );
  }
}

const EmptyRow = () => (
  <tr>
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
    <td style={{ width: '80px' }} />
  </tr>
);

const EmptyTableBody = () => (
  <tbody>
    <EmptyRow />
    <EmptyRow />
    <EmptyRow />
  </tbody>
);

export const DataExportsTableHeader = () => (
  <thead>
    <tr>
      <th className='data-export-heading-item'>NAME</th>
      <th className='data-export-heading-item'>EXPORT TEMPLATE</th>
      <th className='data-export-heading-item'>ENVIRONMENT</th>
      <th
        style={{ overflow: 'wrap', width: '15%' }}
        className='data-export-heading-item'
      >
        INTERVAL
      </th>
      <th style={{ width: '10%' }} className='data-export-heading-item'>
        DATA RANGE
      </th>
      <th className='data-export-heading-item'>LATEST RUN</th>
      <th className='data-export-heading-item'>NEXT RUN</th>
    </tr>
  </thead>
);

const sortDataExports = (a: any, b: any) => {
  if (a.createdAt.isBefore(b.createdAt)) {
    return 1;
  } else if (a.createdAt.isAfter(b.createdAt)) {
    return -1;
  }

  return 0;
};

export const DataExportTableBody = (params: {
  templates: Template[];
  scheduledDataExports: ScheduledDataExport[];
}) => {
  const { scheduledDataExports, templates } = params;
  return (
    <tbody>
      {scheduledDataExports
        .slice()
        .sort(sortDataExports)
        .map((s) => (
          <DataExportRow
            templates={templates}
            dataExport={s}
            key={s.scheduledDataExportId}
          />
        ))}
    </tbody>
  );
};
