import moment, { Moment } from 'moment';
import { Construct } from '../../shared/domain/construct';

export class Organization {
  readonly organizationId: string;
  readonly name: string;
  readonly description: string;
  readonly allowedScope?: string
  readonly banvDisabled?: boolean
  readonly clientId: string;
  readonly ficaEnabled?: string;
  readonly productDescription: string;
  readonly productIcon?: string
  readonly productName: string;
  readonly productWebsite: string;
  readonly qaEnabled?: boolean;
  readonly redirectUris: string[]
  readonly reviewed: boolean;
  readonly reviewedAt?: Moment;
  readonly secret: string;
  readonly userId: string;
  readonly validUserDomains: string[]
  readonly validOutboundEmailDomains: string[];

  constructor(init: Construct<Organization>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkOrganization {
    return {
      organization_id: this.organizationId,
      name: this.name,
      description: this.description,
      allowed_scope: this.allowedScope,
      banv_disabled: this.banvDisabled,
      client_id: this.clientId,
      fica_enabled: this.ficaEnabled,
      product_description: this.productDescription,
      product_icon: this.productIcon,
      product_name: this.productName,
      product_website: this.productWebsite,
      qa_enabled: this.qaEnabled,
      redirect_uris: this.redirectUris,
      reviewed: this.reviewed,
      reviewed_at: this.reviewedAt,
      secret: this.secret,
      user_id: this.userId,
      valid_user_domains: this.validUserDomains,
      valid_outbound_email_domains: this.validOutboundEmailDomains,
    };
  }

  static fromNetwork(init: NetworkOrganization): Organization {
    return new Organization({
      organizationId: init.organization_id,
      name: init.name,
      description: init.description,
      allowedScope: init.allowed_scope,
      banvDisabled: init.banv_disabled,
      clientId: init.client_id,
      ficaEnabled: init.fica_enabled,
      productDescription: init.product_description,
      productIcon: init.product_icon,
      productName: init.product_name,
      productWebsite: init.product_website,
      qaEnabled: init.qa_enabled,
      redirectUris: init.redirect_uris,
      reviewed: init.reviewed,
      reviewedAt: init.reviewed_at && moment(init.reviewed_at),
      secret: init.secret,
      userId: init.user_id,
      validUserDomains: init.valid_user_domains,
      validOutboundEmailDomains: init.valid_outbound_email_domains,
    });
  }
}

export class NetworkOrganization {
  organization_id: string;
  name: string;
  description: string;
  allowed_scope?: string
  banv_disabled?: boolean
  client_id: string;
  fica_enabled?: string;
  product_description: string;
  product_icon?: string
  product_name: string;
  product_website: string;
  qa_enabled?: boolean;
  redirect_uris: string[]
  reviewed: boolean;
  reviewed_at?: Moment;
  secret: string;
  user_id: string;
  valid_user_domains: string[];
  valid_outbound_email_domains: string[];
}
