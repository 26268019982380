import React from 'react';
import { Route } from 'react-router';

// Components
import Login from './components/login';

const onSinglePageEnter = () =>
  (document.body.className = 'single-page-background');
const onSinglePageLeave = () => (document.body.className = '');

export default (
  <Route onEnter={onSinglePageEnter} onLeave={onSinglePageLeave}>
    <Route path='/login' component={Login} />
  </Route>
);
