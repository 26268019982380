import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export class DropdownBlockState extends BlockState {
  readonly optionKey: string = '';
  readonly optionValue: string = '';

  constructor(init: Construct<DropdownBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      option_key: this.optionKey,
      option_value: this.optionValue,
    };
  }

  static fromJSON(init: any) {
    return new DropdownBlockState({
      type: init.type,
      optionKey: init.option_key,
      optionValue: init.option_value,
    });
  }
}