/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='139.753px'
        height='68.596px'
        viewBox='0 0 139.753 68.596'
        enableBackground='new 0 0 139.753 68.596'
        xmlSpace='preserve'
      >
        <ellipse fill='#0E131D' cx='58.148' cy='65.61' rx='56.871' ry='2.275' />
        <g>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#FFFFFF'
            d='M29.692,53.342c0,2.286,1.871,4.159,4.158,4.159H99.84
    c2.287,0,4.16-1.873,4.16-4.159V12.67c0-2.288-1.873-4.158-4.16-4.158H33.851c-2.287,0-4.158,1.87-4.158,4.158V53.342z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#FFFFFF'
            d='M40.186,55.149c-1.771,1.453-1.343,2.641,0.944,2.641h65.989
    c2.289,0,4.158-1.869,4.158-4.16V43.292c0-2.289-1.42-5.377-3.16-6.865L85.146,16.76c-1.736-1.484-4.603-1.516-6.371-0.062
    L40.186,55.149z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#9DC3ED'
            d='M26.728,53.629c0,2.287,1.871,4.16,4.158,4.16h45.192V4.87
    c0-2.289-1.871-4.159-4.158-4.159H30.886c-2.287,0-4.158,1.87-4.158,4.159V53.629z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#CAE0FF'
            d='M30.228,53.629c0,2.287,1.871,4.16,4.158,4.16h45.192V4.87
    c0-2.289-1.871-4.159-4.158-4.159H34.386c-2.287,0-4.158,1.87-4.158,4.159V53.629z'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#FFFFFF'
            stroke='#202B40'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='
    M102.795,57.485c0,4.488-3.638,8.125-8.125,8.125s-8.125-3.637-8.125-8.125c0-4.486,3.638-8.123,8.125-8.123
    S102.795,52.999,102.795,57.485'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#FFFFFF'
            stroke='#202B40'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='
    M55.378,57.485c0,4.488-3.638,8.125-8.125,8.125c-4.486,0-8.125-3.637-8.125-8.125c0-4.486,3.639-8.123,8.125-8.123
    C51.74,49.362,55.378,52.999,55.378,57.485'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#202B40'
            d='M49.683,57.485c0,1.342-1.088,2.43-2.43,2.43
    s-2.43-1.088-2.43-2.43c0-1.34,1.088-2.428,2.43-2.428S49.683,56.145,49.683,57.485'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='#202B40'
            d='M97.1,57.485c0,1.342-1.088,2.43-2.43,2.43
    s-2.429-1.088-2.429-2.43c0-1.34,1.087-2.428,2.429-2.428S97.1,56.145,97.1,57.485'
          />
        </g>
        <line
          fill='none'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='13.401'
          y1='49.772'
          x2='21.022'
          y2='49.772'
        />
        <line
          fill='none'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='1.278'
          y1='41.612'
          x2='18.597'
          y2='41.612'
        />
        <line
          fill='none'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='9.938'
          y1='33.452'
          x2='22.407'
          y2='33.452'
        />
        <g>
          <path
            fill='#202B40'
            d='M84.726,31.007c0,1.101,0.9,2,2,2h11.405c1.1,0,2-0.899,2-2V14.808c0-1.101-0.9-2-2-2H86.726
    c-1.1,0-2,0.899-2,2V31.007z'
          />
        </g>
        <line
          fill='#FFFFFF'
          stroke='#202B40'
          strokeWidth='3'
          strokeMiterlimit='10'
          x1='79.435'
          y1='0.71'
          x2='79.435'
          y2='59.628'
        />
        <line
          fill='#FFFFFF'
          stroke='#202B40'
          strokeWidth='3'
          strokeMiterlimit='10'
          x1='30.228'
          y1='43.542'
          x2='72.764'
          y2='43.542'
        />
        <line
          fill='#FFFFFF'
          stroke='#202B40'
          strokeWidth='3'
          strokeMiterlimit='10'
          x1='30.228'
          y1='38.042'
          x2='72.764'
          y2='38.042'
        />
      </svg>
    );
  }
}
