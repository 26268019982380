import { Moment, utc as moment } from 'moment';
import { Requestee } from '../../shared/domain/requestee';
import { Construct } from '../../shared/domain/construct';


export class ProductModuleDefinitionDocument {
  readonly productModuleDefinitionDocumentId: string;
  readonly html: string;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly type: ProductModuleDefinitionDocumentType;

  constructor(init: Construct<ProductModuleDefinitionDocument>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: DbProductModuleDefinitionDocument): ProductModuleDefinitionDocument {
    return new ProductModuleDefinitionDocument({
      productModuleDefinitionDocumentId: init.product_module_definition_document_id,
      html: init.html,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      type: init.type,
    });
  }
}

export enum ProductModuleDefinitionDocumentType {
  WelcomeLetter = 'welcome_letter',
  PolicySchedule = 'policy_schedule',
  MemberCertificate = 'member_certificate',
  QuoteSummary = 'quote_summary'
}

export interface DbProductModuleDefinitionDocument {
  product_module_definition_document_id: string;
  html: string;
  created_at: string;
  created_by: object;
  type: ProductModuleDefinitionDocumentType;
};

