import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Provider, observer, inject } from 'mobx-react';
import { DataExportListStore } from './data-exports/stores/data-export-list-store';
import { TemplateListStore } from './data-exports/stores/template-list-store';
import { NewTemplateModal } from './data-exports/views/modals/new-template-modal';
import { ExportTemplatesTable } from './export-templates-table';
import { TemplateViewStore } from './data-exports/stores/template-view-store';
import { ajax } from '../../helpers';
import { RunningExportsTable } from './running-exports';
import { NewExportModal } from './data-exports/views/modals/new-export-modal';
import { OrganizationProductModule } from '../domain/organization-product-module';
import { OrganizationsApi } from '../organizations-api';

interface Props {}
interface Injected extends Props {
  dataExportListStore: DataExportListStore;
  templateListStore: TemplateListStore;
  templateViewStore: TemplateViewStore;
}
interface State {
  featureEnabled: boolean;
  organizationProductModules: OrganizationProductModule[];
  showNewExportForm: boolean;
  showTemplates: boolean;
  showNewTemplateForm: boolean;
  showConfirmEditTemplateModal: boolean;
}

@inject(
  'dataExportListStore',
  'templateListStore',
  'templateViewStore',
)
@observer
export class ExportsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      featureEnabled: true,
      organizationProductModules: [],
      showNewExportForm: false,
      showNewTemplateForm: false,
      showConfirmEditTemplateModal: false,
      showTemplates: false,
    };

    this.injected.dataExportListStore.setDataExportListStoreLoadingTrue();
    this.injected.templateListStore.setTemplateListStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  async fetchOrganization() {

    const result = await ajax({
      path: `/client-apps/${this.organizationId}`,
      type: 'GET',
    });

    if (!result.error) {
      return (result);
    } else {
      return result;
    }
  }

  async componentDidMount() {
    const { dataExportListStore, templateListStore } = this.injected;

    try {
      await Promise.all([
        dataExportListStore.load({ organizationId: this.organizationId }),
        templateListStore.load({ organizationId: this.organizationId }),
      ]);

      const result = await OrganizationsApi.getOrganizationProductModules({
        organizationId: this.organizationId,
      });

      this.setState({
        organizationProductModules: result,
      });
    } catch (err) {
      if (err.toString().includes(FEATURE_NOT_ENABLED)) {
        this.setState({
          ...this.state,
          featureEnabled: false,
        });
      }
    }
  }

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>No exports, yet!</h3>
          <p>
            Setup exports to synchronize external systems with data from Root.
          </p>
          <Button
            color='primary'
            className='exports-new-button'
            onClick={() => this.setState({ showNewExportForm: true })}>
              New export
          </Button>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  render() {
    const { featureEnabled, organizationProductModules, showNewExportForm, showNewTemplateForm, showTemplates } = this.state;

    if (this.state.featureEnabled && this.state.organizationProductModules.length === 0) {
      return <div>Loading...</div>;
    }

    else if (!featureEnabled) {
      return  <div className='list-empty-state'>
        <div>
          <p>
            This feature is not enabled for this organization.
          </p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>;
    }

    return (
      <div>
        <div>
          <h2 className='export-details-page-header'>
            Exports
          </h2>
          <div style={{ display: 'flex' }}>
            <span
              style={{ width: '30%' }}
              className={
                !showTemplates ? 'selectedTab' : 'unselectedTab'}>
              <Button
                style={{ paddingLeft: '0px', marginLeft: '0px' }}
                color='link'
                onClick={() =>
                  this.setState({ ...this.state, showTemplates: false })}>
                Running exports
              </Button>
            </span>
            <span
              className={
                showTemplates ? 'selectedTab' : 'unselectedTab'}>
              <Button
                style={{ paddingLeft: '0px', marginLeft: '0px' }}
                color='link'
                onClick={() =>
                  this.setState({ ...this.state, showTemplates: true })}>
                Export templates
              </Button>
            </span>
          </div>
        </div>

        {!showTemplates && (
          <div>
            <div className='exports-header'>
              <ExportsDescription />
              <Button
                color='primary'
                className='exports-new-button'
                onClick={() => this.setState({ showNewExportForm: true })}>
                New export
              </Button>
            </div>
          </div>
        )}

        {showTemplates && (
          <div>
            <div className='exports-header'>
              <TemplatesDescription />
              <Button
                color='primary'
                className='exports-new-button'
                onClick={() => this.setState({ showNewTemplateForm: !this.state.showNewTemplateForm })}>
                New template
              </Button>
            </div>
          </div>
        )}

        {!showTemplates && (
          <RunningExportsTable />
        )}

        {showTemplates && (
          <ExportTemplatesTable organizationProductModules={organizationProductModules} />
        )}

        {showNewTemplateForm && (
          <NewTemplateModal
            organizationProductModules={this.state.organizationProductModules}
            close={() => this.setState({ showNewTemplateForm: !this.state.showNewTemplateForm })}
            updating={false}
          />
        )}
        {showNewExportForm && (
          <NewExportModal
            editing={false}
            close={() => this.setState({ showNewExportForm: false })}
          />
        )}
      </div>
    );
  }
}

interface ExportsContainerProps {}

const dataExportListStore = new DataExportListStore();
const templateListStore = new TemplateListStore();
const templateViewStore = new TemplateViewStore();

export class ExportsContainer extends React.Component<ExportsContainerProps, any> {
  render() {
    return (
      <Provider
        dataExportListStore={dataExportListStore}
        templateListStore={templateListStore}
        templateViewStore={templateViewStore}
      >
        <ExportsComponent />
      </Provider>
    );
  }
}

const TemplatesDescription = () => (
  <p className='exports-desc'>
    Export templates allow you to define a custom structure for data export from Root.
    You can configure export templates for all primary data sources such as
    policies, policyholders, claims and complaints.
  </p>
);

const ExportsDescription = () => (
  <p className='exports-desc'>
    Setup exports between Root and external systems. Exports can be
    used to synchronize external systems with data stored on the Root platform.
    You can configure and schedule exports for all primary data sources
    such as policies, policyholders, claims, and complaints.
  </p>
);

const FEATURE_NOT_ENABLED = Object.freeze('This feature is not enabled for your organization');
