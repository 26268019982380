import { Template, TemplateDataSource } from './domain/templates/template';
import { ajax } from '../../../helpers';

export class TemplateApi {
  static get = async (params: {
    organizationId: string;
    page: number;
    pageSize: number;
    search?: string;
    ignorePagination?: boolean;
  }) => {
    const { organizationId, page, pageSize, search, ignorePagination } = params;
    const query: [string, string | number][] = [];

    if (!ignorePagination) {
      query.push(['page', page], ['page_size', pageSize]);
    }

    if (search) {
      query.push(['search', search]);
    }

    const queryString = query
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const total = await ajax({
      type: 'HEAD',
      path: `/insurance/admin/apps/${organizationId}/data-export-templates?${queryString}`,
      raw: true,
    });

    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates?${queryString}`,
      type: 'get',
    });

    const rowCount = total.headers.get('X-Total-Count');

    return {
      templatesExist: result.length,
      templates: result.map(Template.fromNetwork),
      total: rowCount,
    };
  };

  public static async single(params: {
    organizationId: string;
    templateId: string;
  }) {
    const { organizationId, templateId } = params;

    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates/${templateId}`,
      type: 'get',
    });

    return Template.fromNetwork(result);
  }

  public static async add(params: {
    organizationId: string;
    payload: Template;
  }) {
    const { payload, organizationId } = params;
    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates`,
      type: 'post',
      data: {
        template_name: payload.templateName,
        description: payload.description,
        data_source: payload.dataSource,
        product_module_id: payload.productModuleId
          ? payload.productModuleId
          : undefined,
        fields: payload.fields,
        restricted: payload.restricted,
        filter: payload.filter,
      },
    });
    return Template.fromNetwork(result);
  }

  public static async update(params: {
    organizationId: string;
    templateId: string;
    payload: Template;
  }) {
    const { organizationId, payload, templateId } = params;
    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates/${templateId}`,
      type: 'PATCH',
      data: {
        template_name: payload.templateName,
        description: payload.description,
        data_source: payload.dataSource,
        product_module_id: payload.productModuleId
          ? payload.productModuleId
          : undefined,
        fields: payload.fields,
        filter: payload.filter,
      },
    });

    return Template.fromNetwork(result);
  }

  public static async archive(params: {
    organizationId: string;
    templateId: string;
  }) {
    const { organizationId, templateId } = params;
    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates/${templateId}/archive`,
      type: 'post',
      data: {},
    });
    return Template.fromNetwork(result);
  }

  public static async getDataExportTemplateMergeVariables(params: {
    dataSource: TemplateDataSource;
    organizationId: string;
  }) {
    const { dataSource, organizationId } = params;

    const result = await ajax({
      path: `/insurance/admin/apps/${organizationId}/data-export-templates/merge-variables`,
      type: 'post',
      data: {
        data_source: dataSource,
      },
    });

    return result;
  }
}
