import { ajax, toCamelCaseKeys } from '../../../helpers';

export enum PolicyStatus {
  PendingInitialPayment = 'pending_initial_payment',
  NotTakenUp = 'not_taken_up',
  Cancelled = 'cancelled',
  Active = 'active',
  Lapsed = 'lapsed',
  Expired = 'expired',
}

export enum PolicyIncludes {
  Policyholder = 'policyholder',
};

export enum DebiCheckMandateStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Processing = 'processing',
  Active = 'active',
  Rejected = 'rejected',
  Suspended = 'suspended',
  Cancelled = 'cancelled',
  NoResponse = 'no_response',
}

export interface PolicyFilters {
  status?: PolicyStatus[];
  search?: string;
  createdBy?: string;
  policyholderId?: string;
};

export const getPolicies = async (params: {
  filters?: PolicyFilters;
  includes?: PolicyIncludes[];
  page: number;
  pageSize: number;
  environment: string;
  organizationId: string;
}) => {
  const { organizationId, environment } = params;
  const query: [string, string | number][] = [];

  if (params.filters) {
    if (params.filters.search) {
      query.push(['search', params.filters.search]);
    }

    if (params.filters.policyholderId) {
      query.push(['policyholder_id', params.filters.policyholderId]);
    }

    if (params.filters.status && params.filters.status.length !== 0) {
      query.push(['status', params.filters.status.join(',')]);
    }

    if (params.filters.createdBy) {
      query.push(['created_by', params.filters.createdBy]);
    }
  }

  if (params.includes) {
    query.push(['include', Object.values(params.includes).join(',')]);
  }

  query.push(
    ['page', params.page],
    ['page_size', params.pageSize],
  );

  const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');
  const total = await ajax({
    type: 'HEAD',
    path: `/apps/${organizationId}/insurance/policies?${queryString}`,
    raw: true,
    sandbox: environment === 'sandbox',
  });
  const response = await ajax({
    type: 'GET',
    path: `/apps/${organizationId}/insurance/policies?${queryString}`,
    sandbox: environment === 'sandbox',
  });

  const rowCount = total.headers.get('X-Total-Count');

  return {
    response,
    rowCount,
  };
};

export const createNewDebiCheckMandate = async (params: { policyId: string; organizationId: string}) => {
  const { policyId, organizationId } = params;

  await ajax({
    path: `/apps/${organizationId}/insurance/admin/policies/debicheck-mandates`,
    type: 'POST',
    data: [
      {
        policy_id: policyId,
      },
    ],
  });
};

export const resubmitBanvRequest = async (params: { organizationId: string; paymentMethodId: string }) => {
  const { organizationId, paymentMethodId } = params;

  await ajax({
    path: `/insurance/admin/apps/${organizationId}/payment-methods/submit-verification-requests`,
    type: 'POST',
    data: {
      payment_method_ids: [paymentMethodId],
    },
  });
};

export const setBANVStatus = async (params: { paymentMethodId: string; status: string }) => {
  const { paymentMethodId, status } = params;

  await ajax({
    path: '/insurance/admin/payment-methods',
    type: 'PATCH',
    data: [
      {
        payment_method_id: paymentMethodId,
        banv_status: status,
      },
    ],
  });
};

export const getDebiCheckMandate = async (params: { organizationId: string; policyId: string }) => {
  const { organizationId, policyId } = params;

  const result = await ajax({
    path: `/apps/${organizationId}/insurance/policies/${policyId}/debicheck-mandate`,
    type: 'GET',
  });
  return toCamelCaseKeys(result);
};
