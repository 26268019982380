export enum FailConditions {
  ZA_ID = 'Should be a valid South African ID number.',
  EMAIL_ADDRESS = 'Please enter a valid email address',
  GREATER_THAN_CURRENCY = 'Please enter a currency value greater than',
  GREATER_THAN_NUMBER = 'Please enter a number greater than',
  GREATER_THAN_LENGTH = 'Please complete the input with an input length greater than',
  LESS_THAN_LENGTH = 'Please complete the input with an input length less than',
  LESS_THAN_NUMBER = 'Please enter a number less than',
  LESS_THAN_CURRENCY = 'Please enter a currency value less than',
  COMPLETE_FIELD = 'This field is required',
  SA_ID_REQUIRED = 'This field is required. Should be a valid South African ID number.',
  IMEI = 'Please enter a valid imei number'
}
