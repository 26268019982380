import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <path
          fill='#eaeaea'
          fillRule='evenodd'
          d='M11.273 5.372a1 1 0 0 1 1.454 1.373l-4 4.236a1 1 0 0 1-1.454 0l-4-4.236a1 1 0 1 1 1.454-1.373L8 8.838l3.273-3.466z'
        />
      </svg>
    );
  }
}
