import React from 'react';
import { Provider, observer } from 'mobx-react';
import { DataExportViewStore } from './stores/data-export-view-store';
import { DataExportListStore } from './stores/data-export-list-store';
import { TemplateListStore } from './stores/template-list-store';
import { Details } from './views/template-details';
import { TemplateViewStore } from './stores/template-view-store';
import { OrganizationsApi } from '../../organizations-api';
import { OrganizationProductModule } from '../../domain/organization-product-module';
import { ActiveExports } from './template-active-exports';

interface Props {}

interface State {
  organizationProductModules: OrganizationProductModule[];
  users?: any[];
  isLoading: boolean;
}

const templateViewStore = new TemplateViewStore();
const templateListStore = new TemplateListStore();
const dataExportViewStore = new DataExportViewStore();
const dataExportListStore = new DataExportListStore();

@observer
class TemplateView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      organizationProductModules: [],
    };
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  async componentDidMount() {
    const result = await OrganizationsApi.getOrganizationProductModules({
      organizationId: this.organizationId,
    });

    this.setState({
      organizationProductModules: result,
    });
  }

  renderSubheading() {
    return <div className='detail-view-subheading'>
      <h5>Active exports</h5>
      <hr />
    </div>;
  }

  render() {
    if (this.state.organizationProductModules.length === 0) {
      return <div />;
    }
    return <Provider
      templateViewStore={templateViewStore}
      templateListStore={templateListStore}
      dataExportViewStore={dataExportViewStore}
      dataExportListStore={dataExportListStore}>
      <div>
        <Details
          organizationProductModules={this.state.organizationProductModules}
        />
        {this.renderSubheading()}
        <ActiveExports />
      </div>
    </Provider>;
  }
}

export default TemplateView;
