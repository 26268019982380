import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { setBANVStatus } from '../policy/policy-api';

export class SetBANVStatusModal extends React.Component {
  state = {
    status: '',
    isValid: false,
  };

  updateBANVStatus = async () => {
    const { paymentMethodId } = this.props;

    await setBANVStatus({
      paymentMethodId,
      status: this.state.status,
    });

    this.props.onClose();
    this.props.onBANVStatusUpdated();
  };

  setStatus = e => {
    if (e.target.value) {
      const status = e.target.value;
      const isValid = status !== '';

      this.setState({ status, isValid });
    } else {
      this.setState({ status: null, isValid: false });
    }
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Set the BANV status for this policy
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='banvStatusSelect'>Select status:</Label>
              <Input
                onChange={e => this.setStatus(e)}
                type='select'
                name='select'
                id='banvStatusSelect'
              >
                <option value=''>Select...</option>
                <option value='pending'>Pending</option>
                <option value='verified'>Verified</option>
                <option value='failed_verification'>Failed verification</option>
              </Input>
            </FormGroup>
            <Alert color='danger'>
              <strong>Warning:</strong> this change will reflect in the
              organization&#39;s dashboard
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.isValid}
              onClick={this.updateBANVStatus}
              color='primary'
            >
              Set
            </Button>
            <Button color='secondary' onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
