import * as React from 'react';

export const Icon24PXCross = props => (
  <svg
    onClick={props.onClick}
    xmlns='http://www.w3.org/2000/svg'
    width={props.width ? props.width : '16'}
    height={props.height ? props.height : '16'}
    viewBox='0 0 24 24'
  >
    <g
      fill='none'
      fillRule='evenodd'
      stroke={props.stroke || '#c3c3c3'}
      strokeLinecap='round'
      strokeWidth='3'
    >
      <path d='M4 4l16 16M20 4L4 20' />
    </g>
  </svg>
);
