export const validationError = `
interface ValidationSubError {
  path: string;
  message: string;
}

declare class ValidationError implements Error {
  name = 'ValidationError';
  message = 'Validation failed';
  fields: ValidationSubError[];

  constructor(fields: ValidationSubError[]) {
    this.fields = fields;
  }

  static fromJoi(error: JoiValidationError) {
    const errors = error.details.map(e => ({
      path: e.path.join('.'),
      message: e.message,
    }));

    return new ValidationError(errors);
  }
}
`;
