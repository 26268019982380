import { UUID } from '../../../../shared/domain/uuid';
import { Moment, utc as moment } from 'moment';
import { Construct } from '../../../../shared/domain/construct';
import { LinkedEntities } from '../../../../shared/domain/linked-entities';
import { Requestee } from '../../../../shared/domain/requestee';

export enum PayoutRequestType {
  ClaimPayout = 'claim_payout',
  PolicyRefund = 'policy_refund',
}

export enum PayoutRequestStatus {
  Pending = 'pending',
  Completed = 'completed',
  Rejected = 'rejected',
}

export class PayoutRequest {
  readonly id: UUID;
  readonly organizationId: UUID;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;

  readonly status: PayoutRequestStatus;
  readonly type: PayoutRequestType;
  readonly amount: number;
  readonly description: string;
  readonly linkedEntities: LinkedEntities;
  readonly finalizedAt?: Moment;
  readonly finalizedBy?: Requestee;
  readonly rejectionReason?: string;
  readonly proofOfPaymentId?: UUID;
  readonly actionDate?: Moment;

  constructor(init: Construct<PayoutRequest>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkPayoutRequest) {
    return new PayoutRequest({
      id: UUID.fromString(init.payout_request_id),
      organizationId: UUID.fromString(init.organization_id),
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),

      status: init.status,
      type: init.type,
      amount: init.amount,
      description: init.description,
      linkedEntities: LinkedEntities.fromNetwork(init),
      finalizedAt: init.finalized_at ? moment(init.finalized_at) : undefined,
      finalizedBy: init.finalized_by ? Requestee.fromJSON(init.finalized_by) : undefined,
      rejectionReason: init.rejection_reason,
      proofOfPaymentId: init.proof_of_payment_id ? UUID.fromString(init.proof_of_payment_id) : undefined,
      actionDate: init.action_date ? moment(init.action_date) : undefined,
    });
  }
}

export class NetworkPayoutRequest {
  payout_request_id: string;
  organization_id: string;
  created_at: string;
  created_by: object;

  status: PayoutRequestStatus;
  type: PayoutRequestType;
  amount: number;
  description: string;
  linked_entities: object;
  finalized_at?: string;
  finalized_by?: object;
  rejection_reason?: string;
  proof_of_payment_id?: string;
  action_date?: string;
}

