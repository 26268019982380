import { observable, computed, action } from 'mobx';
import {
  PayoutRequest,
  PayoutRequestType,
  PayoutRequestStatus,
} from '../domain/payout-request';
import { FilterOptions } from '../components/filter-box';
import { PayoutRequestApi } from '../payout-request-api';

export class PayoutRequestListStore {
  @observable isLoaded: boolean = false;
  @observable filterStatus?: PayoutRequestStatus;
  payoutRequests: PayoutRequest[] = [];
  filterType: PayoutRequestType;
  @observable errorMessage?: string;
  // Pagination vars
  @observable pageNumber = 1;
  totalRows: number;
  pageSize = 20;
  // Modal vars
  @observable confirmationModalOpen = false;
  @observable rejectionModalOpen = false;
  @observable selectedPayoutRequest?: PayoutRequest;

  initialize = async (type: PayoutRequestType) => {
    this.isLoaded = false;
    this.filterType = type;
    this.getAllPayoutRequests();
  };

  getAllPayoutRequests = async () => {
    const filters = this.getQueryString();

    try {
      this.totalRows = await PayoutRequestApi.count(filters);
      this.payoutRequests = await PayoutRequestApi.get(filters);
    } catch (err) {
      this.errorMessage = err.toString();
    }

    this.isLoaded = true;
  };

  setFilterStatus = (filterOption: FilterOptions) => {
    this.isLoaded = false;
    if (filterOption) {
      this.filterStatus = filterOption.value as PayoutRequestStatus;
    } else {
      this.filterStatus = undefined;
    }

    this.getAllPayoutRequests();
  };

  setPageNumber = (pageNumber: number) => {
    this.isLoaded = false;
    this.pageNumber = pageNumber;
    this.getAllPayoutRequests();
  };

  getQueryString = () => {
    let filterString = `?page=${this.pageNumber}&page_size=${this.pageSize}&`;
    const queries = [];
    if (this.filterType) {
      queries.push(`types=${this.filterType}`);
    }

    if (this.filterStatus) {
      queries.push(`statuses=${this.filterStatus}`);
    }

    filterString += queries.join('&');
    return filterString;
  };

  @computed get paginationText() {
    const firstResultIndex = this.pageSize * (this.pageNumber - 1) + 1;
    const lastResultIndex =
      this.pageSize * (this.pageNumber - 1) + this.payoutRequests.length;
    return `
      Showing ${firstResultIndex} to ${lastResultIndex} of ${this.totalRows}
    `;
  }

  @computed get maxNumberOfPages() {
    const fullPages = Math.floor(this.totalRows / this.pageSize);
    const halfPages = this.totalRows % this.pageSize > 0 ? 1 : 0;
    return fullPages + halfPages;
  }

  openPayoutConfirmationModal = (payoutRequest: PayoutRequest) => {
    this.selectedPayoutRequest = payoutRequest;
    this.confirmationModalOpen = true;
  };
  closePayoutConfirmationModal = () => {
    this.selectedPayoutRequest = undefined;
    this.confirmationModalOpen = false;
  };
  @action
  confirmPayoutRequest = async (proofOfPayment: File) => {
    if (!this.selectedPayoutRequest) {
      return;
    }

    this.isLoaded = false;

    try {
      await PayoutRequestApi.confirm(
        this.selectedPayoutRequest,
        proofOfPayment,
      );
    } catch (err) {
      this.errorMessage = err.toString();
    }

    this.closePayoutConfirmationModal();
    this.getAllPayoutRequests();
  };

  openPayoutRejectionModal = (payoutRequest: PayoutRequest) => {
    this.selectedPayoutRequest = payoutRequest;
    this.rejectionModalOpen = true;
  };
  closePayoutRejectionModal = () => {
    this.selectedPayoutRequest = undefined;
    this.rejectionModalOpen = false;
  };
  @action
  rejectPayoutRequest = async (reason: string) => {
    if (!this.selectedPayoutRequest) {
      return;
    }

    this.isLoaded = false;

    try {
      await PayoutRequestApi.reject(this.selectedPayoutRequest, reason);
    } catch (err) {
      this.errorMessage = err.toString();
    }

    this.closePayoutRejectionModal();
    this.getAllPayoutRequests();
  };
}
