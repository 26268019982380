//https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-completion-provider-example

export function createBlocksDependencyProposals(range: any, monaco: any) {
  return [
    {
      label: 'Date',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a date input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'input.date',
            key: '${5:example_input_date_block}',
            title: '${6:}',
            description: '${7:}',
            placeholder: '${8:}',
            max_length: 20,
            min_length: 1,
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Time',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a time input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'input.time',
            key: '${5:example_input_time_block}',
            title: '${6:}',
            description: '${7:}',
            placeholder: '${8:}',
            max_length: 20,
            min_length: 1,
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Paragraph',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a paragraph input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'input.paragraph',
            key: '${5:example_input_paragraph_block}',
            title: '${6:}',
            description: '${7:}',
            placeholder: '${8:}',
            max_length: 20,
            min_length: 1,
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Text',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a text input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'input.text',
            key: '${5:example_input_text_block}',
            title: '${6:}',
            description: '${7:}',
            placeholder: '${8:}',
            max_length: 20,
            min_length: 1,
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Dropdown',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a dropdown input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'dropdown',
            key: '${5:example_dropdown}',
            title: '${6:}',
            default_value: '${7:}',
            options: [
              { key: '${8:}', value: '${9:}' },
              { key: '${10:}', value: '${11:}' },
            ],
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Heading',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a heading input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'heading',
            key: '${5:example_heading}',
            title: '${6:}',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Checkbox',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a checkbox input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'checkbox',
            key: '${5:example_checkbox_block}',
            title: '${6:}',
            default_value: false,
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Alert',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a alert input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'alert',
            key: '${5:example_alert_block}',
            markdown: '${6:}',
            color:
              '${7:One of: primary, secondary, success, danger, warning, info, light, dark}',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Radio',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a radio input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'radio',
            key: '${5:example_radio_block}',
            title: '${6:}',
            options: [
              { key: '${7:}', value: '${8:}' },
              { key: '${9:}', value: '${10:}' },
              { key: '${11:}', value: '$12:}' },
            ],
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Annuity request',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a annuity request',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'annuity_request',
            key: '${5:example_annuity_request}',
            title: '${6:}',
            description: '${7:}',
            annuity_type_key: '${8:}',
            policy_id: '${9:{{ claim.policy_id }}}',
            initial_status: '${10:active}',
            reviews: {
              status: '${11:approved}',
            },
            frequency: {
              type: '${12:monthly}',
              time_of_day: '${13:07:00}',
              day_of_month: 1,
            },
            duration: {
              count: 5,
              end_date: '${14:}',
            },
            amount: '${15:{{ claim.policy.sum_assured }}}',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Fulfillment request',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a text input',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'fulfillment_request',
            key: '${5:example_fulfillment_request}',
            title: '${6:}',
            description: '${7:}',
            fulfillment_type_key: '${8:fulfillment_type_key}',
            fulfillment_data: {
              fulfillment_data_1: '${9:}',
              fulfillment_data_2: '${10:}',
            },
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Payout request',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a payout request',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'payout_request',
            key: '${5:example_payout_request}',
            title: '${6:}',
            description: '${7:}',
            amount: '${8:{{ claim.policy.sum_assured }}}',
            payee: {
              amount: '${9:{{ multiply claim.policy.sum_assured 0.14 }}}',
              type: '${10:policyholder}',
              policyholder_id: '${11:{{ claim.policy.policyholder_id }}}',
              percentage: 100,
              payment_details: {
                type: '${12:eft}',
                details: {
                  bank_name: '${13:}',
                  branch_code: '${14:}',
                  account_type: '${15:}',
                  account_number: '${16:}',
                },
              },
            },
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'Group',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a group block',
      insertText: JSON.stringify(
        {
          show_if: '${1:}',
          hide_if: '${2:}',
          disabled_if: '${3:}',
          required_id: '${4:}',
          block: {
            type: 'group',
            key: '${5:example_group}',
            title: '${6:}',
            collapsible: true,
            blocks: [],
          },
        },
        null,
        4,
      ),
      range: range,
    },
  ];
}
