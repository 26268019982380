import React from 'react';
import { Input, Form, FormGroup, Label, Col } from 'reactstrap';
import moment from 'moment';
import { Weekday, weekdayToNumber } from '../../../../../../shared/domain/weekday';
import Images from '../../../../../../components/svg-images';

const getNextRun = data => {
  const now = moment.utc();

  let text;
  let date;

  if (isWeeklyFormData(data)) {
    date = moment
      .utc(data.timeOfDay, 'HH:mm')
      .day(weekdayToNumber(data.dayOfWeek));

    if (now.isAfter(date)) {
      date = date.add(1, 'week');
    }
  } else if (isMonthlyFormData(data)) {
    date = moment.utc(data.timeOfDay, 'HH:mm').date(data.dayOfMonth);

    if (now.isAfter(date)) {
      date = date.add(1, 'month');
    }
  } else if (isDailyFormData(data)) {
    date = moment.utc(data.timeOfDay, 'HH:mm');

    if (now.isAfter(date)) {
      date = date.add(1, 'day');
    }
  }

  if (date) {
    if (now.isSame(date, 'day') && now.isBefore(date)) {
      text = `today at ${date.format('HH:mm')} UTC`;
    } else if (date.isSame(now.clone().add(1, 'day'), 'day')) {
      text = `tomorrow at ${date.format('HH:mm')} UTC`;
    } else {
      text = `on ${date.format('dddd Do MMMM YYYY')} UTC`;
    }

    return (
      <span className='next-run-text'>
        <div className='icon'>
          <Images.info />
        </div>
        <p>The first export will run {text}</p>
      </span>
    );
  }

  return null;
};

const createTimeRange = () => {
  const start = moment().startOf('day');
  const end = moment().endOf('day');
  const dates = [];

  while (start.isSameOrBefore(end)) {
    dates.push(start.format('HH:mm'));
    start.add(30, 'minutes');
  }

  return dates;
};

const timeOfDayOptions = createTimeRange().map(t => ({
  label: t,
  value: t,
}));

const dayOfWeekOptions = Object.values(Weekday).map(e => ({
  label: e[0].toLocaleUpperCase() + e.substr(1),
  value: e,
}));

const dayOfMonthOptions = Array.from({ length: 31 }, (v, i) => i + 1).map(
  e => ({
    label: e.toString(),
    value: e,
  }),
);

export const isDailyFormData = value =>
  !value.dayOfWeek && !value.dayOfMonth &&value && value.timeOfDay;
export const isWeeklyFormData = value =>
  value && value.timeOfDay && value.dayOfWeek;
export const isMonthlyFormData = value =>
  value && value.timeOfDay && value.dayOfMonth;

export class DailyFrequencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeOfDay: (this.props.frequencyData && this.props.frequencyData.timeOfDay) || '00:00',
      validate: {
        timeOfDayState: 'has-success',
      },
    };

    props.changed(this.validateForm(), this.state);
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state);
  }

  validateForm() {
    if (this.state.validate.timeOfDayState === 'has-success') {
      return true;
    } else {
      return false;
    }
  }

  validateTimeOfDay(timeOfDay) {
    if (timeOfDay) {
      this.setState(
        {
          ...this.state,
          timeOfDay: timeOfDay,
          validate: {
            ...this.state.validate,
            timeOfDayState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          timeOfDayState: 'has-danger',
        },
      });
    }
  }

  newExportTableRow(label, value) {
    return (
      <FormGroup row>
        <Label sm={6}>{label}</Label>
        <Col sm={6}>{value}</Col>
      </FormGroup>
    );
  }

  render() {
    return (
      <div>
        <Form style={{ width: '100%' }}>
          {this.newExportTableRow(
            <strong>
                Time of day<sup>*</sup>
            </strong>,
            <Input
              type='select'
              name='timeOfDay'
              value={this.state.timeOfDay}
              onChange={e => this.validateTimeOfDay(e.target.value)}
            >
              {this.renderOptions(timeOfDayOptions)}
            </Input>,
          )}
        </Form>
        {this.state.validate.timeOfDayState === 'has-success' &&
          getNextRun(this.state)}
      </div>
    );
  }

  renderOptions(data) {
    const dataArr = data.map((content, index) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
    return dataArr;
  }

  initialized = (valid, form) => {
    if (!this.props.initialized) {
      return;
    }

    const data = {
      timeOfDay: this.state.timeOfDay,
    };

    this.props.initialized(valid, data);
  };
}

export class WeeklyFrequencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeOfDay: (this.props.frequencyData && this.props.frequencyData.timeOfDay) || '00:00',
      dayOfWeek: (this.props.frequencyData && this.props.frequencyData.dayOfWeek) || 'monday',
      validate: {
        timeOfDayState: 'has-success',
        dayOfWeekState: 'has-success',
      },
    };

    props.changed(this.validateForm(), this.state);
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state);
  }

  validateForm() {
    if (
      this.state.validate.timeOfDayState === 'has-success' &&
      this.state.validate.dayOfWeekState === 'has-success'
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateTimeOfDay(timeOfDay) {
    if (timeOfDay) {
      this.setState(
        {
          ...this.state,
          timeOfDay: timeOfDay,
          validate: {
            ...this.state.validate,
            timeOfDayState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          timeOfDayState: 'has-danger',
        },
      });
    }
  }

  validateDayOfWeek(dayOfWeek) {
    if (dayOfWeek) {
      this.setState(
        {
          ...this.state,
          dayOfWeek: dayOfWeek,
          validate: {
            ...this.state.validate,
            dayOfWeekState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          dayOfWeekState: 'has-danger',
        },
      });
    }
  }

  newExportTableRow(label, value) {
    return (
      <FormGroup row>
        <Label sm={6}>{label}</Label>
        <Col sm={6}>{value}</Col>
      </FormGroup>
    );
  }

  render() {
    return (
      <div>
        <Form style={{ width: '100%' }}>
          {this.newExportTableRow(
            <strong>
                Time of day<sup>*</sup>
            </strong>,
            <Input
              type='select'
              name='timeOfDay'
              value={this.state.timeOfDay}
              onChange={e => this.validateTimeOfDay(e.target.value)}
            >
              {this.renderOptions(timeOfDayOptions)}
            </Input>,
          )}
          {this.newExportTableRow(
            <strong>
                Day of week<sup>*</sup>
            </strong>,
            <Input
              type='select'
              name='dayOfWeek'
              value={this.state.dayOfWeek}
              onChange={e => this.validateDayOfWeek(e.target.value)}
            >
              {this.renderOptions(dayOfWeekOptions)}
            </Input>,
          )}
        </Form>
        {this.state.validate.timeOfDayState === 'has-success' &&
          this.state.validate.dayOfWeekState === 'has-success' &&
          getNextRun(this.state)}
      </div>
    );
  }

  renderOptions(data) {
    const dataArr = data.map((content, index) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
    return dataArr;
  }

  initialized = (valid, form) => {
    if (!this.props.initialized) {
      return;
    }

    const data = {
      timeOfDay: this.state.timeOfDay,
      dayOfWeek: this.state.dayOfWeek,
    };

    this.props.initialized(valid, data);
  };
}

export class MonthlyFrequencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeOfDay: (this.props.frequencyData && this.props.frequencyData.timeOfDay) || '00:00',
      dayOfMonth: (this.props.frequencyData && this.props.frequencyData.dayOfMonth) || 1,
      validate: {
        timeOfDayState: 'has-success',
        dayOfMonthState: 'has-success',
      },
    };

    props.changed(this.validateForm(), this.state);
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state);
  }

  validateForm() {
    if (
      this.state.validate.timeOfDayState === 'has-success' &&
      this.state.validate.dayOfMonthState === 'has-success'
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateTimeOfDay(timeOfDay) {
    if (timeOfDay) {
      this.setState(
        {
          ...this.state,
          timeOfDay: timeOfDay,
          validate: {
            ...this.state.validate,
            timeOfDayState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          timeOfDayState: 'has-danger',
        },
      });
    }
  }

  validateDayOfMonth(dayOfMonth) {
    if (dayOfMonth) {
      this.setState(
        {
          ...this.state,
          dayOfMonth: dayOfMonth,
          validate: {
            ...this.state.validate,
            dayOfMonthState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          dayOfMonthState: 'has-danger',
        },
      });
    }
  }

  newExportTableRow(label, value) {
    return (
      <FormGroup row>
        <Label sm={6}>{label}</Label>
        <Col sm={6}>{value}</Col>
      </FormGroup>
    );
  }

  render() {
    return (
      <div>
        <Form style={{ width: '100%' }}>
          {this.newExportTableRow(
            <strong>
                Time of day<sup>*</sup>
            </strong>,
            <Input
              type='select'
              name='timeOfDay'
              value={this.state.timeOfDay}
              onChange={e => this.validateTimeOfDay(e.target.value)}
            >
              {this.renderOptions(timeOfDayOptions)}
            </Input>,
          )}
          {this.newExportTableRow(
            <strong>
                Day of month<sup>*</sup>
            </strong>,
            <Input
              type='select'
              name='dayOfMonth'
              value={this.state.dayOfMonth}
              onChange={e => this.validateDayOfMonth(e.target.value)}
            >
              {this.renderOptions(dayOfMonthOptions)}
            </Input>,
          )}
        </Form>
        {this.state.validate.timeOfDayState === 'has-success' &&
          this.state.validate.dayOfMonthState === 'has-success' &&
          getNextRun(this.state)}
      </div>
    );
  }

  renderOptions(data) {
    return data.map((content, index) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
  }

  initialized = (valid, form) => {
    if (!this.props.initialized) {
      return;
    }

    const data = {
      timeOfDay: this.state.timeOfDay,
      dayOfMonth: this.state.dayOfMonth,
    };

    this.props.initialized(valid, data);
  };
}
