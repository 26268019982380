import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import { nestedStyleCheckbox } from '../utils';
import {
  capturedBeneficiaryDetails,
  typesOfBeneficiaries,
} from '../config/beneficiaries';
import { TypesOfPolicyholdersCondition } from '../config/product-module-definition-settings-policyholder';
import '../../../../../styles/styles.scss';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import LoadingInPage from '../../../../../loading';
import { ComponentType } from '../../product-module-definitions-billing/components/billing';
import ProductModuleSettingsCheckbox from '../checkbox';
import ProductModuleSettingsTextInput from '../text-input';
import _ from 'lodash';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfPolicyholdersCondition;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
}

interface State {}
@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionSettingsBeneficiaries extends React.Component<
Props,
State
> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(typesOfBeneficiaries).map(checkboxDescription => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleSettingsComponents,
          checkboxDescription,
        );
      });
    }
  }

  renderPolicyholderSettings = (
    params: Params,
    componentType: ComponentType,
  ) => {
    if (componentType === ComponentType.Checkbox) {
      return <ProductModuleSettingsCheckbox params={params} />;
    }
    if (
      componentType === ComponentType.Text ||
      componentType === ComponentType.Number
    ) {
      return (
        <ProductModuleSettingsTextInput
          params={params}
          componentType={componentType}
        />
      );
    }
  };

  showBeneficiaries = () => {
    const { productModuleStore } = this.props;
    if (productModuleStore) {
      const { productModuleDefinitionDraft } = productModuleStore;
      const { settings } = productModuleDefinitionDraft;
      return !!_.get(settings, 'beneficiaries.enabled', false);
    }
    return false;
  };

  render() {
    const {
      productModuleComponentLoadingStateStore,
      productModuleStore,
    } = this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Beneficiaries
              </p>
            </Col>
            <Col xs={9}>
              <p>Defining beneficiaries</p>
              <span>
                A beneficiary is any individual other than the policyholder who
                will receive the benefit or payout of a claim. If beneficiaries
                are enabled on the product, the policyholder should be asked to
                nominate the person/s who will receive the claim benefit.
              </span>
            </Col>
          </Row>

          {this.renderPolicyholderSettings(
            {
              ...typesOfBeneficiaries.enableBeneficiaries,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.showBeneficiaries() &&
            this.renderPolicyholderSettings(
              {
                ...typesOfBeneficiaries.enablePolicyholderIsBeneficiary,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                },
              },
              ComponentType.Checkbox,
            )}
          {this.showBeneficiaries() &&
            this.renderPolicyholderSettings(
              {
                ...typesOfBeneficiaries.maximumNumberOfBeneficiaries,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                },
              },
              ComponentType.Number,
            )}

          {this.showBeneficiaries() &&
            this.renderPolicyholderSettings(
              {
                ...typesOfBeneficiaries.minimumNumberOfBeneficiaries,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                },
              },
              ComponentType.Number,
            )}

          {Object.keys(capturedBeneficiaryDetails).length > 0 && (
            <Row>
              <Col xs={3} />
              <Col xs={9}>
                <div style={nestedStyleCheckbox(true, 0)}>
                  <hr />
                  <p>Captured beneficiary details</p>
                  <span>
                    Root will always require the policyholder’s first name, last
                    name and either / both email address and mobile number.
                    Additional fields, which are optionally required, include:
                    additional contact number and physical address.
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
