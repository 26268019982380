export const coveredItems = [
  {
    covered_item_id: '243050b0-3458-4af4-9f9a-8f4dbe293e79',
    created_at: '2019-03-21T08:10:03.833Z',
    created_by: {
      type: 'api_key',
      id: 'b76079bf-2b1a-4f77-a6d7-3de7f565d2c8',
      owner_id: 'e325c124-7305-11e7-94d6-c3b7b747a46e',
    },
    cover_type: 'monthly',
    monthly_premium: 36449,
    module: {
      make: 'olympus',
      model: 'camera_canon_0',
      usage: 'business',
      excess: {
        type: 'fixed',
        value: 50000,
      },
      perils: {
        accidental_damage: true,
      },
      description: 'hello',
      sum_assured: 6000000,
      benefit_type: 'standard',
      outside_home: true,
      product_type: 'camera',
      retail_value: 6000000,
      purchase_date: '2019-03-21',
      serial_number: '2323423',
      borrow_or_lend: true,
      extreme_sports: false,
      daytime_storage: 'locked_safe',
      existing_damage: true,
      protective_gear: true,
      overnight_storage: 'locked_safe',
    },
    cover_periods: [],
  },
  {
    covered_item_id: '3f974d75-0d44-40e2-8578-16a04b8c4ec3',
    created_at: '2019-03-21T08:10:03.833Z',
    created_by: {
      type: 'api_key',
      id: 'b76079bf-2b1a-4f77-a6d7-3de7f565d2c8',
      owner_id: 'e325c124-7305-11e7-94d6-c3b7b747a46e',
    },
    cover_type: 'on_demand',
    monthly_premium: 0,
    module: {
      make: 'olympus',
      model: 'camera_canon_0',
      usage: 'business',
      excess: {
        type: 'fixed',
        value: 50000,
      },
      perils: {
        accidental_damage: true,
      },
      description: 'hello',
      sum_assured: 6000000,
      benefit_type: 'standard',
      outside_home: true,
      product_type: 'camera',
      retail_value: 6000000,
      purchase_date: '2019-03-21',
      serial_number: '2323423',
      borrow_or_lend: true,
      extreme_sports: false,
      daytime_storage: 'locked_safe',
      existing_damage: true,
      protective_gear: true,
      overnight_storage: 'locked_safe',
    },
    cover_periods: [
      {
        cover_period_id: '853818bb-f513-478c-a6d6-dc482e6bd2c8',
        start_date: '2019-08-08',
        end_date: '2019-08-10',
        premium: 6463,
      },
    ],
  },
];
