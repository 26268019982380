import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class DividerBlock extends Block {

  constructor(init: Construct<DividerBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
    };
  }

  static fromJSON(init: any) {
    return new DividerBlock({
      type: init.type,
      key: init.key,
    });
  }
}