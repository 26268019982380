import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { ProductModuleDefinitionSchemaIndexStructure } from '../../product-module-json-to-html/orchestration';
import '../../../../styles/styles.scss';
import { ProductModuleJsonHtml } from '../../product-module-json-to-html';

interface Props {
  productModuleKey: string;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface State {
  showSchema: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionClaimBlocksSchema extends React.Component<Props,State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }


  renderFullScreenModal = () => {
    const { productModuleStore } = this.injected;
    const productModuleDefinitionSchemaId = productModuleStore.productModuleDefinitionDraft[ProductModuleDefinitionSchemaIndexStructure.claimBlocksSchemaId];
    return (
      <ProductModuleJsonHtml
        productModuleDefinitionSchemaId={productModuleDefinitionSchemaId}
        toDbKey={'claim_blocks_schema_json'}
        breadcrumbDocName={'Claim Blocks schema'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  async componentDidMount() {
    const { productModuleKey, productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  render() {
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Blocks schema
              </p>
            </Col>
            <Col xs={9}>
              <p>Claim Block schema form</p>
              <span>
                Blocks are single units of functionality. They manifest as a user interface component, like a text input field or a dropdown menu,
                and a state that’s saved on a claim, like the text that was entered into the input field or the option selected from the dropdown. Blocks’ states can also be read and updated through the API.
                Each type of Block has its own configuration options and input validations that’s applied to users interacting with the Block on the dashboard or through the API.
                A claim workflow is built up using blocks and display conditions in a claim schema. This schema is a JSON array of objects each containing a Block and it’s Display Conditions.
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <p>Claim Block schema form</p>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
