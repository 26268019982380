import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore } from '../../product-definition/stores/product-module-store';
import moment from 'moment';
import { ProductModuleDefinition } from '../../../domain/product-module-definition';
import LoadingInPage from '../../../loading';
import marked from 'marked';
import { buttonText } from '../../../../shared/helpers/button-text-loading';
import Images from '../../../../components/svg-images';

interface Props {
  productModuleKey: string;
  redirect: (key: string) => any;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface State {
  showMarkdownEditor: boolean;
  isLoading: boolean;
  bumpSandbox: boolean;
  bumpProduction: boolean;
  showBumpModal: boolean;
  showCancelBumpModal: boolean;
  showBumpWarning: boolean;
  bumpWarningMessage: string;
}

@inject('productModuleStore')
@observer
export default class ProductModuleVersionInfo extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showMarkdownEditor: false,
      isLoading: false,
      bumpSandbox: false,
      bumpProduction: false,
      showBumpModal: false,
      showCancelBumpModal: false,
      showBumpWarning: false,
      bumpWarningMessage: '',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { productModuleStore, productModuleKey } = this.injected;
    await productModuleStore.init(productModuleKey, true);

  }

  onProductModuleDefinitionSelect = (productModuleDefinition?: ProductModuleDefinition) => {
    const { productModuleStore, redirect } = this.injected;

    productModuleDefinition && productModuleStore.setProductModulePrimary(productModuleDefinition);
    redirect(productModuleStore.productModule.key);
  };

  handleMarkdownSave = async () => {
    const { productModuleStore } = this.injected;
    this.setState({ isLoading: true });

    await productModuleStore.updateProductModuleReadme({
      productModuleId: productModuleStore.productModule.productModuleId,
      markdown: productModuleStore.readmeMarkdown,
    });
    this.setState({ showMarkdownEditor: false, isLoading: false });
  }

  renderMarkdownCard = (title: string) => {
    const { productModuleStore } = this.injected;
    if (productModuleStore.productModuleDefinitionDraft)
      return <Card>
        <CardHeader>
          <b>{title}</b>
          {
            this.state.showMarkdownEditor
              ? <Button
                color='primary'
                className='show-markdown-editor-button-style'
                onClick={() => this.handleMarkdownSave()}
                disabled={!productModuleStore.isLoaded || this.state.isLoading}
              >
                {buttonText({ text: 'Save', loading: !productModuleStore.isLoaded || this.state.isLoading })}
              </Button>
              : <Button
                color='link'
                style={{ float: 'right', margin: 0, padding: 0, minWidth: 0 }}
                onClick={() => this.setState({ showMarkdownEditor: true })}
              >
                Edit
              </Button>
          }
        </CardHeader>
        <CardBody style={{ padding: this.state.showMarkdownEditor ? 0 : '1.25rem' }}>
          {
            this.state.showMarkdownEditor
              ? <Input
                type="textarea"
                name="markdownEditor"
                style={{ border: 'none', minHeight: '50vh' }}
                value={productModuleStore.readmeMarkdown}
                onChange={(e) => productModuleStore.readmeMarkdown = e.target.value}
              />
              : <div dangerouslySetInnerHTML={{ __html: marked(productModuleStore.readmeMarkdown || '') }}></div>
          }
        </CardBody>
      </Card>;
    return <></>;
  }

  renderSidePanel = () => {
    const { productModuleStore } = this.injected;
    const contents = [];

    if (productModuleStore.productModuleDefinitionDraft) {
      const { versionMajor, versionMinor, createdAt } = productModuleStore.productModuleDefinitionDraft;
      contents.push(<div key='draft'>
        <h4>Draft</h4>
        <div>Version: {versionMajor}.{versionMinor}</div>
        <div>Last edit: {moment(createdAt).format('D MMM YYYY')}</div>
      </div>);
    }
    if (productModuleStore.productModuleDefinitionLive) {
      const { versionMajor, versionMinor, publishedAt } = productModuleStore.productModuleDefinitionLive;
      contents.push(<hr key='hr' />);
      contents.push(<div key='published'>
        <h4>Published</h4>
        <div>Version: {versionMajor}.{versionMinor}</div>
        <div>Published: {moment(publishedAt).format('D MMM YYYY')}</div>
        <a href='#'
          style={{ marginLeft: 0, marginBottom: 0, marginTop: 0 }}
          onClick={() => this.onProductModuleDefinitionSelect(productModuleStore.productModuleDefinitionLive)}
        >
          View this version
        </a>
      </div>);

      const bumpSandboxApplicationsCount = productModuleStore.versionBumpCounts.sandbox.applications;
      const bumpSandboxPoliciesCount = productModuleStore.versionBumpCounts.sandbox.policies;
      const canBumpSandbox = bumpSandboxApplicationsCount > 0 || bumpSandboxPoliciesCount > 0;

      const bumpProductionApplicationsCount = productModuleStore.versionBumpCounts.production.applications;
      const bumpProductionPoliciesCount = productModuleStore.versionBumpCounts.production.policies;
      const canBumpProduction = bumpProductionApplicationsCount > 0 || bumpProductionPoliciesCount > 0;

      const bumpModalApplicationsCount =
        ( this.state.bumpProduction ? bumpProductionApplicationsCount : 0 )
        + ( this.state.bumpSandbox ? bumpSandboxApplicationsCount : 0 );

      const bumpModalPoliciesCount =
        ( this.state.bumpProduction ? bumpProductionPoliciesCount : 0 )
        + ( this.state.bumpSandbox ? bumpSandboxPoliciesCount : 0 );

      contents.push(<div key='version-bump' className='version-bump-form'>
        {!this.state.isLoading && (
          <>
            <hr/>
            <h4>Version bump <span title="Version bumps are performed for both policies and applications"><Images.info /></span></h4>
            <FormGroup check className='version-bump-input'>
              <Label check>
                <Input
                  type='checkbox'
                  disabled={!canBumpSandbox}
                  checked={this.state.bumpSandbox}
                  onChange={() => this.setState({ bumpSandbox: !this.state.bumpSandbox })}
                />
                <span>
                  <b>Sandbox</b><br/>
                  <sup>{bumpSandboxApplicationsCount} Applications, {bumpSandboxPoliciesCount} Policies</sup>
                </span>
              </Label>
            </FormGroup>
            <FormGroup check className='version-bump-input'>
              <Label check>
                <Input
                  type='checkbox'
                  disabled={!canBumpProduction}
                  checked={this.state.bumpProduction}
                  onChange={() => this.setState({ bumpProduction: !this.state.bumpProduction })}
                />
                <span>
                  <b>Production</b><br/>
                  <sup>{bumpProductionApplicationsCount} Applications, {bumpProductionPoliciesCount} Policies</sup>
                </span>
              </Label>
            </FormGroup>

            <Button
              color='primary'
              disabled={!canBumpProduction && !canBumpSandbox}
              outline
              onClick={() => this.showBumpModal() }
            >
              Queue bump
            </Button>

            <Button
              color='danger'
              onClick={() => this.showCancelBumpModal() }
            >
              Cancel bump(s)
            </Button>

            {this.state.showBumpWarning && (
              <Alert color="warning">
                { this.state.bumpWarningMessage }
              </Alert>
            )}

            {this.state.showBumpModal && (
              <>
                <Modal
                  isOpen={this.state.showBumpModal}
                  onClosed={() => this.closeBumpModal()}
                >
                  <ModalHeader>Bump policies</ModalHeader>
                  <ModalBody>
                    <p>Are you sure you want to bump all policies to the latest published version of this product module?</p>
                    <p>On confrmation, this will queue the bump of { bumpModalApplicationsCount } applications and { bumpModalPoliciesCount } policies.</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => this.closeBumpModal()}>Cancel</Button>
                    <Button color='primary' onClick={() => this.bumpConfirmed()}>Confirm</Button>
                  </ModalFooter>
                </Modal>
              </>
            )}

            {this.state.showCancelBumpModal && (
              <>
                <Modal
                  isOpen={this.state.showCancelBumpModal}
                  onClosed={() => this.closeBumpModal()}
                >
                  <ModalHeader>Cancel version bump</ModalHeader>
                  <ModalBody>
                    <p>Are you sure you want to cancel any pending bump for policies or application, for this product module?</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => this.closeBumpModal()}>Cancel</Button>
                    <Button color='primary' onClick={() => this.cancelVersionBumps()}>Confirm</Button>
                  </ModalFooter>
                </Modal>
              </>
            )}
          </>
        )}
      </div>);
    }
    return contents;
  };

  showBumpModal() {
    if (!this.state.bumpProduction && !this.state.bumpSandbox) {
      this.setState({
        showBumpWarning: true,
        bumpWarningMessage: 'You need to select at least one environment to bump',
      });
      return;
    }
    this.setState({
      showBumpWarning: false,
      showBumpModal: true,
      showCancelBumpModal: false,
    });
  }

  closeBumpModal() {
    this.setState({ showBumpModal: false, showCancelBumpModal: false });
  }

  showCancelBumpModal() {
    this.setState({
      showBumpModal: false,
      showCancelBumpModal: true,
      showBumpWarning: false,
    });
  }

  async cancelVersionBumps() {
    const { productModuleStore } = this.injected;
    await productModuleStore.cancelVersionBumps();
    this.setState({
      showCancelBumpModal: false,
      showBumpWarning: true,
      bumpWarningMessage: 'Cancellations have been queued',
    });
  }

  async bumpConfirmed() {
    const { productModuleStore } = this.injected;
    await productModuleStore.queueVersionBump({
      sandbox: this.state.bumpSandbox,
      production: this.state.bumpProduction,
    });
    this.setState({
      showBumpWarning: true,
      bumpProduction: false,
      bumpSandbox: false,
      bumpWarningMessage: 'Bump has been queued',
    });
    this.closeBumpModal();
  }

  render() {
    const { productModuleStore } = this.injected;
    if (productModuleStore.productModuleDefinitionDraft) {
      return <div>
        <Row>
          <Col sm={9} style={{ paddingLeft: '1.5rem' }}>
            <div style={{ paddingTop: '1.5rem', display: 'flow-root' }}>
              <h3 style={{ lineHeight: 'inherit' }}>
                {productModuleStore.productModule.name}
                <Button
                  style={{ float: 'right', margin: 0 }}
                  color='primary'
                  disabled={!!productModuleStore.productModule.reviewId}
                  onClick={() => this.onProductModuleDefinitionSelect(productModuleStore.productModuleDefinitionDraft)}>
                  View draft
                </Button>
                {
                  !!productModuleStore.productModule.reviewId
                  && <i style={{ float: 'right', padding: '0.375rem 0.75rem' }}>{'Can\'t edit whilst product module is in review.'}</i>
                }
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={9} style={{ paddingLeft: '1.5rem' }}>
            {this.renderMarkdownCard('README.md')}
          </Col>
          <Col sm={3}>{this.renderSidePanel()}</Col>
        </Row>
      </div>;
    }
    return <LoadingInPage />;
  }
}
