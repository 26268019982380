import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import '../../../../../styles/styles.scss';
import {
  typesOfPolicyholders,
} from '../config/product-module-definition-settings-policyholder';
import _ from 'lodash';
import {
  ProductModuleComponentLoadingStateStore,
  StoreIndex,
} from '../../../stores/product-module-component-loading-state-store';
import { ProductModuleSettingsPolicyholderStore } from './store';
import { debounce } from '../../../../../../helpers/debounce';
import LoadingInPage from '../../../../../loading';
import { Params } from '../checkbox';
import ProductModuleSettingsCheckbox from '../checkbox';
import { SavingState } from '../../../util';

interface ProductModuleDefinitionSettingsPolicyHolder {
  productModuleSettingsPolicyholderStore: ProductModuleSettingsPolicyholderStore;
}
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}
interface State {
  [k: string]: boolean;
}
@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
class ProductModuleDefinitionSettingsPolicyHolder extends React.Component<
  Props,
  State
  > {
  constructor(props: any) {
    super(props);
    this.productModuleSettingsPolicyholderStore = new ProductModuleSettingsPolicyholderStore();
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(typesOfPolicyholders).map(checkboxDescription => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleSettingsComponents,
          checkboxDescription,
        );
      });
    }
  }

  onCheckBoxChange = async (
    path: any,
    condition: any,
    value: any,
    key: string,
  ) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        key,
        SavingState.Saving,
      );
    }
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        const settingsItemValue = settingsItem.indexOf(value);
        settingsItemValue !== -1
          ? settingsItem.splice(settingsItemValue, 1)
          : settingsItem.push(value);
        _.set(settings, path, settingsItem);

        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      } else {
        _.set(settings, path, settingsItem);
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      }
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        key,
        SavingState.Saved,
      );
      debounce(
        key,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleSettingsComponents,
            key,
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  renderPolicyholderSettings = (
    params: Params,
    productModuleComponentLoadingStateStore: ProductModuleComponentLoadingStateStore,
  ) => {
    return <ProductModuleSettingsCheckbox params={params} />;
  };

  defaultValue = (params: Params) => {
    const { path, displayCheckValue } = params;

    const { productModuleStore } = this.props;
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        return !!settingsItem.find(val => val === displayCheckValue);
      }
      return settingsItem === displayCheckValue;
    }
  };

  render() {
    const {
      productModuleComponentLoadingStateStore,
      productModuleStore,
    } = this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Policyholder
              </p>
            </Col>
            <Col xs={9}>
              <p>Types of policyholders</p>
              <span>
                Policyholders can either be individual natural people or legal
                entities, like companies. You can define whether one or both are
                allowed for this product, and what forms of identification are
                accepted.
              </span>
            </Col>
          </Row>

          {this.renderPolicyholderSettings(
            {
              ...typesOfPolicyholders.natural,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            productModuleComponentLoadingStateStore,
          )}

          {this.defaultValue({ ...typesOfPolicyholders.natural }) &&
            this.renderPolicyholderSettings(
              {
                ...typesOfPolicyholders.southAfrican,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                  index: 1,
                },
              },
              productModuleComponentLoadingStateStore,
            )}

          {this.defaultValue({ ...typesOfPolicyholders.natural }) &&
            this.renderPolicyholderSettings(
              {
                ...typesOfPolicyholders.nonSouthAfrican,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                  index: 0,
                },
              },
              productModuleComponentLoadingStateStore,
            )}

          {this.renderPolicyholderSettings(
            {
              ...typesOfPolicyholders.policyLegalEntitiy,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            productModuleComponentLoadingStateStore,
          )}
          {Object.keys(this.renderPolicyholderSettings).length > 0 && (
            <Row>
              <Col xs={3} />
              <Col xs={9}>
                <hr />
                <p>Captured policyholder details</p>
                <span>
                  Root will always require the policyholder’s first name, last
                  name and either / both email address and mobile number.
                  Additional fields, which are optionally required, include:
                  additional contact number and physical address.
                </span>
              </Col>
            </Row>
          )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}

export default ProductModuleDefinitionSettingsPolicyHolder;
