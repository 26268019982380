import { ajax } from '../helpers';
import { Feature } from './domain/feature';
import { OrganizationFeature } from './domain/organization-feature';

export class FeatureApi {
  static async get() {
    const response = await ajax({
      path: '/admin/features',
      type: 'GET',
    });

    return response.map(Feature.fromNetwork);
  }

  static async getOrganizationFeatures(params: { organizationId: string }) {
    const { organizationId } = params;
    const response = await ajax({
      path: `/admin/organizations/${organizationId}/features`,
      type: 'GET',
    });

    return response.map(OrganizationFeature.fromNetwork);
  }

  static async createOrganizationFeatureRequest(params: {
    featureKey: string;
    organizationId: string;
    sandbox: boolean;
    production: boolean;
  }) {
    const { featureKey, organizationId, production, sandbox } = params;
    await ajax({
      path: `/admin/organizations/${organizationId}/features`,
      type: 'POST',
      data: {
        feature_key: featureKey,
        sandbox_enabled: sandbox,
        production_enabled: production,
      },
    });
  }

  static async createFeatureRequest(params: {
    featureKey: string;
    description: string;
    sandboxByDefault: boolean;
  }) {
    const { featureKey, description, sandboxByDefault } = params;
    await ajax({
      path: '/admin/features',
      type: 'POST',
      data: {
        feature_key: featureKey,
        sandbox_by_default: sandboxByDefault,
        description,
      },
    });
  }

  static async bulkUpdateFeatureFlags(params: {
    featureKey: string;
    production?: boolean;
    sandbox?: boolean;
  }) {
    await ajax({
      path: '/admin/features/bulk-update',
      type: 'POST',
      data: {
        feature_key: params.featureKey,
        production: params.production,
        sandbox: params.sandbox,
      },
    });
  }
}
