import React, { Component } from 'react';
import {
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ModalFooter,
  Alert,
  ModalHeader,
} from 'reactstrap';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import { FullScreenModal } from '../../../../../../components/modals/full-screen';
import '../../../../../styles/code-window.scss';
import '../../../../../styles/styles.scss';
import { Icon24PXCross } from '../../../../../../components/icons/icon-24-px-cross';
import CodeWindow from '../../../../../utils/code-editor/code-editor';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore } from '../../../stores/product-module-store';
import LoadingInPage from '../../../../../loading';
import { debounce } from '../../../../../../helpers/debounce';
import { FulfillmentType } from '../fulfillment-types/domain/fulfillment-type';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import _ from 'lodash';
import { GeneralModal } from '../../../modals';
import { AnnuityType } from '../annuity/annuity-type';
import { SavingState } from '../../../util';

export enum SchemaType {
  fulfillmentType = 'FulfillmentTypes',
  annuityType = 'AnnuityTypes',
}

interface ProductModuleJson {
  modalContent: any;
  syncValidity?: (valid: boolean) => void;
}

interface State {
  isSaving: boolean;
  errorMessage: string;
  showSaveModal: boolean;
  contentChanged: boolean;
  types?: AnnuityType[] | FulfillmentType[];
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface Props {
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  schemaType: SchemaType;
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  breadcrumbDocName: string;
  closeDocument: () => void;
}

let timeout: any = null;
@inject('productModuleStore')
@observer
class ProductModuleJson extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      errorMessage: '',
      showSaveModal: false,
      contentChanged: false,
    };
    this.modalContent = {
      title: 'Schema Saved',
      body: `Your ${_.startCase(
        this.props.schemaType,
      )} schema has successfully been saved`,
      submit: 'Ok',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  handleCodeChange = async (params: { code: any }) => {
    const { code } = params;

    try {
      const parsedData = JSON.parse(code);
      debounce(
        'parse-product-module-definition-fulfillment-types',
        async () => {
          const types = parsedData.map((data: any) =>
            this.props.schemaType === SchemaType.annuityType
              ? new AnnuityType({ ...data })
              : new FulfillmentType({ ...data }),
          );
          this.setState({ types, errorMessage: '' });
        },
        250,
      );

      this.setState({ errorMessage: '' });
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleComponentLoadingStateStore,
    } = this.props;
    const { productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
    const {
      fulfillmentTypes,
      annuityTypes,
    } = productModuleStore.productModuleDefinitionDraft.settings.claims;
    this.setState({
      types:
        this.props.schemaType === SchemaType.fulfillmentType
          ? fulfillmentTypes
            ? fulfillmentTypes
            : []
          : annuityTypes
            ? annuityTypes
            : [],
    });
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.productModuleSettingsComponents,
        this.props.schemaType,
      );
    }
  }

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  submitModal = () => {
    this.closeSaveModal();
  };

  saveDraftMethod = async () => {
    const { productModuleComponentLoadingStateStore } = this.props;
    const { productModuleStore } = this.injected;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          this.props.schemaType,
          SavingState.Saving,
        );
      }
      if (productModuleStore) {
        const { settings } = productModuleStore.productModuleDefinitionDraft;
        const { types } = this.state;

        _.set(
          settings,
          this.props.schemaType === SchemaType.annuityType
            ? 'claims.annuityTypes'
            : 'claims.fulfillmentTypes',
          types,
        );
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId.toString(),
          data: { settings_json: settings },
        });
        this.setState({ showSaveModal: true });
      }
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          this.props.schemaType,
          SavingState.Saved,
        );

        debounce(
          this.props.schemaType,
          () =>
            productModuleComponentLoadingStateStore.updateComponentLoadingState(
              StoreIndex.productModuleSettingsComponents,
              this.props.schemaType,
              SavingState.Default,
            ),
          3000,
        );
      }
    }, 1200);
  };

  render() {
    const { breadcrumbDocName, productModuleKey } = this.props;
    const { productModuleStore } = this.injected;
    if (!productModuleStore.isLoaded || !this.state.types) {
      return <LoadingInPage />;
    }
    const lockedComponent = productModuleStore.lockedComponent;

    return (
      <FullScreenModal isOpen={true}>
        <GeneralModal
          show={this.state.showSaveModal}
          onclose={this.closeSaveModal}
          submitModal={this.submitModal}
          modalContent={this.modalContent}
        />
        <ModalHeader style={{ paddingBottom: 19 }}>
          <div
            className='Rectangle'
            style={{ display: 'inline-block', paddingTop: 7 }}
          >
            <a
              onClick={(e) => {
                e.preventDefault;
                this.props.closeDocument();
              }}
            >
              <Icon24PXCross />
            </a>
          </div>
          <div style={{ display: 'inline-block', paddingTop: 17 }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href='/product-modules'>
                  Product modules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  href={`/product-modules/${productModuleKey}`}
                >
                  {productModuleStore.productModule.name}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  onClick={(e) => {
                    e.preventDefault;
                    this.props.closeDocument();
                  }}
                >
                  Product definition
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>{breadcrumbDocName}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </ModalHeader>
        <ModalBody className='zero-padding'>
          <div className='template-editor'>
            <div className='template-editor-error-message'>
              {this.state.errorMessage && (
                <Alert color='danger'>{this.state.errorMessage}</Alert>
              )}
            </div>
            <CodeWindow
              width='100%'
              key='Code Window'
              defaultValue={JSON.stringify(this.state.types)}
              language='json'
              handleCodeChange={(code: any) => this.handleCodeChange({ code })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='right-content'>
            <Button
              disabled={lockedComponent || !!this.state.errorMessage}
              color='secondary'
              onClick={async () => this.saveDraftMethod()}
            >
              {this.state.isSaving ? 'Saving...' : 'Save draft'}
            </Button>
          </div>
        </ModalFooter>
      </FullScreenModal>
    );
  }
}

export default ProductModuleJson;
