import React from 'react';
import {
  Badge,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Alert,
} from 'reactstrap';
import PageHeader from '../../components/page-header';
import { ajax, alphabetically } from '../../helpers';
import ToggleSwitch from '../../components/toggle-switch';
export default class ProductModules extends React.Component {
  state = {
    modal: false,
    loading: true,
    finalProductModules: null,
    productModule: null,
    error: false,
    errorMessage: null,
  };

  updateOrgProductModule = async (
    productModuleId,
    liveChecked,
    sandboxChecked,
  ) => {
    const { organizationId } = this.props.params;
    try {
      const updatedProductModule = await ajax({
        path: `insurance/admin/organizations/${organizationId}/product-modules/${productModuleId}`,
        type: 'PATCH',
        data: {
          sandbox: sandboxChecked,
          live: liveChecked,
        },
      });
      this.toggle();
      this.populateProductModules();
      return updatedProductModule;
    } catch (error) {
      console.dir(error);
      this.toggle();
      this.populateProductModules();
      this.setState({ errorMessage: JSON.parse(error.message), error: true });
    }
  };

  productModuleData = productModule => {
    this.setState({ productModule: productModule, modal: true, error: false });
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  fetchOrgProductModules = async () => {
    const { params } = this.props;
    try {
      const orgProductModules = await ajax({
        path: `insurance/admin/organizations/${
          params.organizationId
        }/product-modules`,
      });
      return orgProductModules.sort(alphabetically('name'));
    } catch (error) {
      console.dir(error);
    }
  };

  populateProductModules = async () => {
    const orgProductModules = await this.fetchOrgProductModules();
    this.setState({ finalProductModules: orgProductModules, loading: false });
  };

  componentDidMount() {
    this.populateProductModules();
  }

  render() {
    const {
      finalProductModules,
      loading,
      modal,
      productModule,
      error,
      errorMessage,
    } = this.state;
    const customTableHeadCell = {
      width: '120px',
    };
    const tableCellOverride = {
      verticalAlign: 'baseline',
    };
    if (loading) {
      return (
        <div>
          <i>Loading product modules...</i>
        </div>
      );
    }
    return (
      <div>
        {error && <Alert color='danger'>{`${errorMessage.message}`}</Alert>}
        <br />
        <PageHeader title='Product modules' />
        <Table>
          <thead>
            <tr>
              <th>Module name</th>
              <th>Module type</th>
              <th style={customTableHeadCell}>Test mode</th>
              <th style={customTableHeadCell}>Live mode</th>
              <th style={customTableHeadCell} />
            </tr>
          </thead>
          <tbody>
            {finalProductModules &&
              finalProductModules.map((productModule, index) => {
                return (
                  <tr key={index}>
                    <td style={tableCellOverride}>
                      {productModule.name}
                      {productModule.restricted === true && (
                        <Badge color='info' style={{ marginLeft: '0.62em' }} pill>
                          Private
                        </Badge>
                      )}
                    </td>
                    <td style={tableCellOverride}>{productModule.key}</td>
                    {
                      <td
                        style={tableCellOverride}
                        className={
                          productModule.sandbox ? 'success' : 'default'
                        }
                      >
                        {productModule.sandbox ? 'Enabled' : 'Disabled'}
                      </td>
                    }
                    {
                      <td
                        style={tableCellOverride}
                        className={productModule.live ? 'success' : 'default'}
                      >
                        {productModule.live ? 'Enabled' : 'Disabled'}
                      </td>
                    }
                    {
                      <td style={tableCellOverride}>
                        <Button
                          color='link'
                          onClick={() => this.productModuleData(productModule)}
                        >
                          Configure
                        </Button>
                      </td>
                    }
                  </tr>
                );
              })}
          </tbody>
          {modal && (
            <ConfigurationModal
              modal={modal}
              productModule={productModule}
              toggle={this.toggle}
              updateOrgProductModule={this.updateOrgProductModule}
            />
          )}
        </Table>
      </div>
    );
  }
}

class ConfigurationModal extends React.Component {
  constructor(props) {
    super(props);

    const { productModule } = props;

    this.state = {
      liveChecked: productModule.live || false,
      sandboxChecked: productModule.sandbox || false,
    };
  }

  handleLiveChange = liveChecked => {
    this.setState({ liveChecked });
  };

  handleSandboxChange = sandboxChecked => {
    this.setState({ sandboxChecked });
  };

  render() {
    const { modal, productModule, updateOrgProductModule, toggle } = this.props;
    const { liveChecked, sandboxChecked } = this.state;
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>{productModule.name}</ModalHeader>
        <ModalBody style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: 'auto' }}>{productModule.key}</span>
          <ToggleSwitch
            id={'sandbox-toggle'}
            leftText={'Test'}
            checked={this.state.sandboxChecked}
            onChange={this.handleSandboxChange}
            onColor={'#1fc881'}
            offColor={'#c3c3c3'}
          />
          <ToggleSwitch
            id={'live-toggle'}
            leftText={'Live'}
            checked={this.state.liveChecked}
            onChange={this.handleLiveChange}
            onColor={'#1fc881'}
            offColor={'#c3c3c3'}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() =>
              updateOrgProductModule(
                productModule.product_module_id,
                liveChecked,
                sandboxChecked,
              )
            }
          >
            Confirm
          </Button>
          <Button color='secondary' onClick={() => toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
