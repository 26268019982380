import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Role } from '../domain/role';
import { alphabetically } from '../../../../helpers';

interface Props {
  roles: Role[];
  onChange: (roleId: string) => string;
  selectedRoleId: string;
}

export const RolesList = (props: Props) => {
  const { roles, onChange, selectedRoleId } = props;

  const isRoleChecked = (roleId: string) => roleId === selectedRoleId;

  const computeEnvironmentAccess = (props: { hasSandboxAccess: boolean; hasLiveAccess: boolean }) => {
    const { hasSandboxAccess, hasLiveAccess } = props;


    if (!hasSandboxAccess && !hasLiveAccess) {
      return 'none';
    }

    const messageParts = [];

    if (hasSandboxAccess) {
      messageParts.push('Sandbox');
    }

    if (hasLiveAccess) {
      messageParts.push('Live');
    }

    return messageParts.join(', ');
  };

  if (!roles) {
    return <div>Loading roles...</div>;
  }

  return roles.sort(alphabetically('name')).map(role => (
    <FormGroup key={role.roleId} check style={{marginTop: '10px'}}>
      <Label check style={{display: 'flex', alignItems: 'center'}}>
        <Input
          type='radio'
          name='roles'
          checked={isRoleChecked(role.roleId)}
          value={role.roleId}
          onChange={e => onChange(e.currentTarget.value)}
        />
        {role.name}
      </Label>
      <div style={{marginLeft: '24px'}}>
        <small>Environment Access: <em>{computeEnvironmentAccess(role)}</em></small>
      </div>
      <div style={{marginLeft: '24px'}}>
        <small>{role.description}</small>
      </div>

    </FormGroup>
  ));
};
