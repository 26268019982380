import { Weekday } from '../../shared/domain/weekday';
import { Month } from '../../shared/domain/month';
import moment from 'moment';

const convertTimeToLocal = (time: string) => moment.utc(time, 'HH:mm').local().format('HH:mm');
const convertTimeToUTC = (time: string) => moment(time, 'HH:mm').utc().format('HH:mm');

export enum ScheduledFunctionFrequencyType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

type ScheduledFunctionFrequencyInit<T> = Pick<T, Exclude<{
  [K in keyof T]: T[K] extends Function ? never : K
}[keyof T], 'type'>>;

export interface ScheduledFunctionFrequency {
  readonly type: ScheduledFunctionFrequencyType;
  readonly timeOfDay: string;
  toNetwork(): object;
}

abstract class BaseScheduledFunctionFrequency implements ScheduledFunctionFrequency {
  abstract readonly type: ScheduledFunctionFrequencyType;
  abstract readonly timeOfDay: string;

  abstract toNetwork(): object;
}

export class Daily extends BaseScheduledFunctionFrequency {
  readonly type: ScheduledFunctionFrequencyType = ScheduledFunctionFrequencyType.Daily;
  readonly timeOfDay: string;

  constructor(init: ScheduledFunctionFrequencyInit<Daily>) {
    super();
    Object.assign(this, init);
  }

  toNetwork(): object {
    return {
      type: this.type,
      time_of_day: convertTimeToUTC(this.timeOfDay),
    };
  }

  static fromNetwork(network: any): Daily {
    return new Daily({
      timeOfDay: convertTimeToLocal(network.time_of_day),
    });
  }
}

export class Weekly extends BaseScheduledFunctionFrequency {
  readonly type: ScheduledFunctionFrequencyType = ScheduledFunctionFrequencyType.Weekly;
  readonly dayOfWeek: Weekday;
  readonly timeOfDay: string;

  constructor(init: ScheduledFunctionFrequencyInit<Weekly>) {
    super();
    Object.assign(this, init);
  }

  toNetwork(): object {
    return {
      type: this.type,
      time_of_day: convertTimeToUTC(this.timeOfDay),
      day_of_week: this.dayOfWeek,
    };
  }

  static fromNetwork(network: any): Weekly {
    return new Weekly({
      dayOfWeek: network.day_of_week,
      timeOfDay: convertTimeToLocal(network.time_of_day),
    });
  }
}

export class Monthly extends BaseScheduledFunctionFrequency {
  readonly type: ScheduledFunctionFrequencyType = ScheduledFunctionFrequencyType.Monthly;
  readonly dayOfMonth: number;
  readonly timeOfDay: string;

  constructor(init: ScheduledFunctionFrequencyInit<Monthly>) {
    super();
    Object.assign(this, init);
  }

  toNetwork(): object {
    return {
      type: this.type,
      time_of_day: convertTimeToUTC(this.timeOfDay),
      day_of_month: this.dayOfMonth,
    };
  }

  static fromNetwork(network: any): Monthly {
    return new Monthly({
      dayOfMonth: network.day_of_month,
      timeOfDay: convertTimeToLocal(network.time_of_day),
    });
  }
}

export class Yearly extends BaseScheduledFunctionFrequency {
  readonly type: ScheduledFunctionFrequencyType = ScheduledFunctionFrequencyType.Yearly;
  readonly monthOfYear: Month;
  readonly dayOfMonth: number;
  readonly timeOfDay: string;

  constructor(init: ScheduledFunctionFrequencyInit<Yearly>) {
    super();
    Object.assign(this, init);
  }

  toNetwork(): object {
    return {
      type: this.type,
      time_of_day: convertTimeToUTC(this.timeOfDay),
      day_of_month: this.dayOfMonth,
      month_of_year: this.monthOfYear,
    };
  }

  static fromNetwork(network: any): Yearly {
    return new Yearly({
      monthOfYear: network.month_of_year,
      dayOfMonth: network.day_of_month,
      timeOfDay: convertTimeToLocal(network.time_of_day),
    });
  }
}

export class ScheduledFunctionFrequencyFactory {
  static fromNetwork(network: any): ScheduledFunctionFrequency {
    switch (network.type) {
      case 'daily': return Daily.fromNetwork(network);
      case 'weekly': return Weekly.fromNetwork(network);
      case 'monthly': return Monthly.fromNetwork(network);
      case 'yearly': return Yearly.fromNetwork(network);
      default: throw new Error(`Unknown scheduled function frequency type: ${network.type}`);
    }
  }
}
