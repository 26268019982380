import React from 'react';
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';
import { TemplateDataSource, prettyDataSource } from '../../../domain/templates/template';
import Images from '../../../../../../components/svg-images';
import { observer, inject } from 'mobx-react';
import { TemplateViewStore } from '../../../stores/template-view-store';
import { OrganizationProductModule } from '../../../../../domain/organization-product-module';
import Select from 'react-select';

interface Props {
  changed: (valid: boolean, state: any) => void;
  productModules: OrganizationProductModule[];
  updating: boolean;
}

interface SelectOptions {
  label: string;
  value: string;
}

interface Injected extends Props {
  templateViewStore: TemplateViewStore;
}

interface State {
  loading: boolean;
  templateName: string;
  description: string;
  dataSource: TemplateDataSource;
  productModuleId?: string;
  productModules: OrganizationProductModule[];
  restricted: boolean;
  validate: {
    templateNameState: string;
    descriptionState: string;
  };
}

@inject('templateViewStore')
@observer
export class TemplateForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { templateViewStore } = this.injected;
    const template = templateViewStore.template;

    this.state = {
      loading: false,
      templateName: '',
      description: '',
      dataSource: template ? template.dataSource : TemplateDataSource.policyholders,
      productModuleId: template ? template.productModuleId : undefined,
      productModules: [],
      restricted: true,
      validate: {
        templateNameState: this.injected.templateViewStore.template
          ? 'has-success'
          : 'has-danger',
        descriptionState: this.injected.templateViewStore.template
          ? 'has-success'
          : 'has-danger',
      },
    };
  }


  get injected() {
    return this.props as Injected;
  }

  componentDidMount() {
    const { templateViewStore } = this.injected;
    const template = templateViewStore.template;
    if (template) {
      this.setState(
        {
          templateName: template.templateName,
          description: template.description,
          dataSource: template.dataSource,
          productModuleId: template.productModuleId,
          restricted: template.restricted,
        },
        () => this.props.changed(this.validateForm(), this.state),
      );
    } else {
      this.setState(
        {
          productModuleId: undefined,
        },
        () => this.props.changed(this.validateForm(), this.state),
      );
    }
  }

  validateForm = () => {

    if (
      this.state.validate.templateNameState === 'has-success' &&
      this.state.validate.descriptionState === 'has-success'
    ) {
      return true;
    } else {
      return false;
    }
  };

  validateDescription = (description: string) => {
    const descriptionState = description ? 'has-success' : 'has-danger';

    this.setState(
      {
        ...this.state,
        description: description,
        validate: {
          ...this.state.validate,
          descriptionState: descriptionState,
        },
      },
      () => {
        this.props.changed(this.validateForm(), this.state);
      },
    );
  }

  validateTemplateName = (templateName: string) => {
    const templateNameState = templateName ? 'has-success' : 'has-danger';

    this.setState(
      {
        ...this.state,
        templateName: templateName,
        validate: {
          ...this.state.validate,
          templateNameState: templateNameState,
        },
      },
      () => {
        this.props.changed(this.validateForm(), this.state);
      },
    );
  }

  newExportTableRow = (label: JSX.Element, value: JSX.Element) => {
    return (
      <FormGroup row>
        <Label sm={6}>{label}</Label>
        <Col sm={6}>{value}</Col>
      </FormGroup>
    );
  }

  render() {
    return (
      <Card className='new-export-modal' style={{ width: '50%' }}>
        <CardHeader>
          <h5>Export template details</h5>
        </CardHeader>
        <CardBody style={{ paddingRight: '8px' }}>
          <Form style={{ width: '100%' }}>
            {this.newExportTableRow(
              <strong>
                Export template name<sup>*</sup>
              </strong>,
              <Input
                type='text'
                defaultValue={this.state.templateName}
                onChange={e => this.validateTemplateName(e.target.value)}
              />,
            )}
            {this.newExportTableRow(
              <div>
                <p>
                  <strong>Description<sup>*</sup></strong>
                </p>
                <p style={{ color: '#7a7a7a', fontSize: '12px' }}>
                  Describe the data format
                </p>
              </div>,
              <Input
                type='textarea'
                value={this.state.description}
                style={{ height: '100px' }}
                onChange={e => this.validateDescription(e.target.value)}
              />,
            )}
            {this.newExportTableRow(
              <strong>
                Data source<sup>*</sup>
              </strong>,
              <Select
                options={this.renderDataSourceOptions()}
                defaultValue={this.renderDataSourceOptions().find((dataSource) => dataSource.value === this.state.dataSource)}
                onChange={(selectedOption: SelectOptions) =>
                  this.setState(
                    { ...this.state, dataSource: selectedOption.value as TemplateDataSource },
                    () => {
                      this.props.changed(this.validateForm(), this.state);
                    },
                  )
                }
              />,
            )}
            {this.newExportTableRow(
              <strong>
                Product module
              </strong>,
              <Select
                isClearable
                defaultValue={
                  this.getProductModuleOptions({ organizationProductModule: this.props.productModules })
                    .find((productModule) => productModule.value === this.state.productModuleId)}
                options={this.getProductModuleOptions({ organizationProductModule: this.props.productModules })}
                onChange={(selectedOption: SelectOptions | null) =>
                  this.setState({ productModuleId: selectedOption ? selectedOption.value : undefined },
                    () => {
                      this.props.changed(this.validateForm(), this.state);
                    },
                  )
                }
              />,
            )}
            {this.newExportTableRow(
              <strong>
                Permissions<sup>*</sup>
                <Images.lock />
              </strong>,
              <div style={{ verticalAlign: 'top', display: 'flex' }}>
                <div>
                  <Input
                    type='checkbox'
                    checked={this.state.restricted}
                    disabled={this.props.updating}
                    onChange={() =>
                      this.setState(
                        { ...this.state, restricted: !this.state.restricted },
                        () => {
                          this.props.changed(this.validateForm(), this.state);
                        },
                      )
                    }
                  />
                </div>
                <div>
                  <span>
                    Limit exports and template editing to Root-admin users
                    only.
                  </span>
                </div>
              </div>,
            )}
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderDataSourceOptions() {
    return Object.values(TemplateDataSource).map(d => {
      return {
        label: prettyDataSource(d),
        value: d,
      };
    });
  }

  renderOptions = (data: any) => {
    return data.map((content: any) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
  }

  getProductModuleOptions = (params: { organizationProductModule: OrganizationProductModule[] }) => {
    return (params.organizationProductModule || []).map((productModule) => ({
      label: productModule.name,
      value: productModule.productModuleId,
    }));
  }
}
