import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export enum FulfillmentRequestStatus {
  Pending = 'pending',
  Successful = 'successful',
  Rejected = 'rejected',
}

export class FulfillmentRequestBlockState extends BlockState {
  readonly fulfillmentRequestId?: string = '';
  readonly status?: FulfillmentRequestStatus;

  constructor(init: Construct<FulfillmentRequestBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      fulfillment_request_id: this.fulfillmentRequestId,
      status: this.status,
    };
  }

  static fromJSON(init: any) {
    return new FulfillmentRequestBlockState({
      type: init.type,
      fulfillmentRequestId: init.fulfillment_request_id,
      status: init.status,
    });
  }
}
