export const productModuleApplication = `
declare class Application {
  package_name: string;
  sum_assured: number;
  base_premium: number;
  monthly_premium: number;
  module: { type: string; [key: string]: any };
  input_data?: { [key: string]: any };

  constructor(init: Application) {
    Object.assign(this, init);
  };
}
`;
