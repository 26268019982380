import ajax from '../../helpers/ajax';
import { apiKeyMap } from '../../helpers/maps';

export const createApiKey = (name, readOnly) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/api-keys',
      data: {
        name,
        read_only: readOnly,
      },
    }).then(result => {
      dispatch({ type: 'NEW_API_KEY', apiKey: apiKeyMap(result) });
      return { ...apiKeyMap(result), token: result.token };
    });
  };
};

export const deleteApiKey = apiKeyId => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'DELETE',
      path: '/api-keys/' + apiKeyId,
    }).then(result => {
      dispatch({ type: 'DELETED_API_KEY', apiKeyId });
      return result;
    });
  };
};
