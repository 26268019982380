import * as React from 'react';

export const Icon12plus = (props: { fill?: string; style?: any }) => (
  <svg
    style={{ marginTop: -4 }}
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 14 14'
  >
    <g
      fill='none'
      fillRule='evenodd'
      stroke='#757575'
      strokeLinecap='round'
      strokeWidth='2'
    >
      <path d='M7 1.343v11.314M12.657 7H1.343' />
    </g>
  </svg>
);
