import React from 'react';
import { Table, Card, CardHeader, CardBody, Button } from 'reactstrap';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys, getRandValue } from '../../../helpers';
import { statusBadge } from './../payment-batches/payment-batches-table';
import { PaginationComponent } from '../../../components/pagination';

export default class CardPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: null,
      loading: true,
      page: 0,
      pageSize: 50,
    };
  }

  componentDidMount() {
    this.fetchPendingPayments();
  }

  async fetchPendingPayments() {
    this.setState({ loading: true });

    const queryString = 'status=pending&payment_method_type=card&include=payment_method';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payments?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const payments = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payments?${queryString}${pagination}`,
    })).map(toCamelCaseKeys);

    this.setState({ payments, loading: false, total: total.headers.get('X-Total-Count')});
  }

  submitBatch = async () => {
    if (this.state.payments.length === 0) {
      return window.alert('No pending card payments.');
    }

    if (
      !window.confirm(
        'Are you sure you want to submit these payments to peach?',
      )
    ) {
      return;
    }

    this.setState({ loading: true });
    const qs = 'batch_type=card';

    try {
      const response = await ajax({
        type: 'POST',
        path: `/insurance/admin/payments/submit?${qs}`,
        raw: true,
      });

      if (response.status === 204) {
        window.alert(
          `No pending card payments for ${
            this.state.paymentDate
              ? this.state.paymentDate.format('YYYY-MM-DD')
              : 'today'
          }.`,
        );
      } else if (response.status > 299) {
        const body = await response.json();

        if (body.error) {
          console.error(
            'Error submitting batch: ',
            body && JSON.stringify(body.error, null, 2),
          );

          this.setState({
            error: (
              <div>
                Error submitting batch:{' '}
                <a href={body.error.more_info}>{body.error.message}</a>
              </div>
            ),
          });
        }

        return;
      }

      this.fetchPendingPayments();
    } catch (e) {
      console.error(e);
      this.setState({ error: e.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { payments, loading } = this.state;

    return (
      <div className='bottom-padded'>
        <Card>
          <CardHeader>
            Card payments ({payments && payments.length})
            <div className='pull-right'>
              <Button
                color='danger'
                onClick={this.submitBatch}
                disabled={loading}
              >
                Submit batch to peach
              </Button>{' '}
            </div>
          </CardHeader>
          <CardBody>
            {loading ? <div>Loading...</div> : this.renderTable()}
          </CardBody>
        </Card>
      </div>
    );
  }

  renderTable() {
    const { page, pageSize, total } = this.state;

    return this.state.payments.length ? (
      <div>
        <Table hover>
          <thead>
            <tr>
              <td>
                <b>Amount</b>
              </td>
              <td>
                <b>Status</b>
              </td>
              <td>
                <b>Payment method type</b>
              </td>
              <td>
                <b>Created at</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {this.state.payments
              .sort((a, b) => (a.created_at < b.created_at ? -1 : 1))
              .map(o => this.renderRow(o))}
          </tbody>
        </Table>
        <PaginationComponent
          page={page || 0}
          limit={pageSize}
          total={total || 0}
          disabled={false}
          goToPage={page => {
            this.setState({
              page,
            }, async () => await this.fetchPendingPayments());
          }}
        />
      </div>
    ) : (
      'No pending card payments'
    );
  }

  renderRow(payment) {
    console.log(payment.status);
    return (
      <tr key={payment.paymentId}>
        <td>R {getRandValue(payment.amount)}</td>
        <td>{statusBadge(payment.status)}</td>
        <td>{payment.paymentMethod ? payment.paymentMethod.type : '-'}</td>
        <td>{payment.createdAt}</td>
      </tr>
    );
  }
}
