import React from 'react';
import { FormGroup, CardBody, Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import _ from 'lodash';
import CoolingOffPeriodCompletedChild from './components/cooling-off-period-completed-child';
import { CoolingOffPeriodOutput } from '../../store-interfaces';
import { CoolingOffPeriodRulesConfig } from './config';
import { nestedStyleCheckbox } from '../../../../utils';

interface Props {
  coolingOffPeriodRules: CoolingOffPeriodOutput;
  coolingOffPeriodNotifications?: CoolingOffPeriodOutput | null;
}

interface State {}

@observer
class CoolingOffPeriodCompletedCard extends React.Component<Props, State> {
  getValue = (index: number, path: string, dropdown: boolean = false) => {
    let value = _.get(this.props.coolingOffPeriodRules[index], path);
    if (value && dropdown) {
      value = _.startCase(value).toLowerCase();
    }
    return value && value !== undefined ? value : null;
  };

  renderChildren = (storeVariable: CoolingOffPeriodOutput | null) => {
    const components = [];

    if (storeVariable) {
      for (let i = 0; i < Object.keys(storeVariable).length; i++) {
        const value = this.getValue(
          i,
          CoolingOffPeriodRulesConfig.applyCoolingOffPeriodOf.outputPaths.value,
        );
        const type = this.getValue(
          i,
          CoolingOffPeriodRulesConfig.applyCoolingOffPeriodOf.outputPaths.type,
          true,
        );
        const applyTo = this.getValue(
          i,
          CoolingOffPeriodRulesConfig.applyTo.outputPaths,
          true,
        );

        const refundType = this.getValue(
          i,
          CoolingOffPeriodRulesConfig.refundThePolicyHolder.outputPaths,
          true,
        );

        components.push(
          <div key={`CoolingOff_period_${storeVariable}_${i}`}>
            <CoolingOffPeriodCompletedChild
              type={type}
              value={value}
              applyTo={applyTo}
              refundType={refundType}
              index={i}
            />
          </div>,
        );
      }
    }
    return components;
  };

  render() {
    const { coolingOffPeriodRules, coolingOffPeriodNotifications } = this.props;
    const notificationsLength = coolingOffPeriodNotifications
      ? Object.keys(coolingOffPeriodNotifications).length
      : null;
    const rulesLength = Object.keys(coolingOffPeriodRules).length;

    return (
      <Row>
        <Col>
          <CardBody
            className='white-background'
            style={{ marginLeft: -17, marginBottom: 0, paddingBottom: 0 }}
          >
            <FormGroup>
              <p className='product-module-heading-description-spacing'>
                The cooling off period is the time frame after a policy is
                issued when a claim will be invalid.
              </p>
            </FormGroup>
            {rulesLength && (
              <CardBody style={nestedStyleCheckbox(true, 1)}>
                <h5>Cooling off period rules</h5>
                <hr className='horizontal-line-without-padding-bottom' />
                {this.renderChildren(coolingOffPeriodRules)}
              </CardBody>
            )}
          </CardBody>
          {notificationsLength && notificationsLength > 0 && (
            <div>
              <CardBody
                className='white-background'
                style={{ paddingBottom: 0, marginBottom: -14 }}
              >
                <h5>Notifications</h5>
                <hr />
              </CardBody>
              <CardBody className='white-background'>
                <FormGroup inline>
                  {this.renderChildren(coolingOffPeriodNotifications || null)}
                </FormGroup>
              </CardBody>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default CoolingOffPeriodCompletedCard;
