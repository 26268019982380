export const memberQuotePackage = `
declare class MemberQuotePackage {
  monthly_premium: number;
  module: object;

  constructor(init: MemberQuotePackage) {
    Object.assign(this, init);
  };
}
`;
