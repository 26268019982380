import React from 'react';
import { connect } from 'react-redux';
import { getApplications } from '../actions';
import { Table, Label, Container } from 'reactstrap';
import Button from '../../components/loading-button';
import Icon from '../../components/icon';
import moment from 'moment';
import CreateUserModal from './create-user-modal';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';

const highestStatus = docs => {
  if (docs.find(d => d.state === 'approved')) {
    return 'approved';
  } else if (docs.find(d => d.state === 'in_review')) {
    return 'in_review';
  } else if (docs.find(d => d.state === 'declined')) {
    return 'declined';
  } else {
    return 'pending_upload';
  }
};

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewUserModal: false,
    };
  }

  componentWillMount() {
    return this.props.dispatch(getApplications());
  }

  renderApplications() {
    return (
      <Table hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Cellphone Number</th>
            <th>Details</th>
            <th>ID Doc</th>
            <th>PoA Doc</th>
          </tr>
        </thead>
        <tbody>
          {this.props.applications.map(a => this.renderApplicationRow(a))}
        </tbody>
      </Table>
    );
  }

  renderLabel(status) {
    let style;
    let title;
    switch (status) {
      case 'pending_upload':
        style = 'default';
        title = 'Pending Upload';
        break;
      case 'in_review':
        style = 'warning';
        title = 'Requires Review';
        break;
      case 'approved':
        style = 'success';
        title = 'Approved';
        break;
      case 'declined':
        style = 'danger';
        title = 'Rejected';
        break;
      default:
        break;
    }
    return <Label color={style}>{title}</Label>;
  }

  renderApplicationRow(application) {
    const detailsStatus = application.homeAffairs.matchedAt
      ? 'approved'
      : 'in_review'; // TODO
    const idDocStatus = highestStatus(
      application.documents.filter(d => d.type === 'id'),
    );
    const addressDocStatus = highestStatus(
      application.documents.filter(d => d.type === 'address'),
    );
    return (
      <tr
        key={application.applicationId}
        onClick={() =>
          this.props.router.push(
            '/users/applications/' + application.applicationId,
          )
        }
      >
        <td>{moment(application.createdAt).fromNow()}</td>
        <td>{application.firstName + ' ' + application.lastName}</td>
        <td>{application.email}</td>
        <td>{application.cellphone}</td>
        <td>{this.renderLabel(detailsStatus)}</td>
        <td>{this.renderLabel(idDocStatus)}</td>
        <td>{this.renderLabel(addressDocStatus)}</td>
      </tr>
    );
  }

  closeModal() {
    this.setState({ openNewUserModal: false });
  }

  render() {
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <Container>
          <div className='card'>
            <div className='card-header'>
              Applications
              <Button
                color='primary'
                className='pull-right'
                onClick={() => this.setState({ openNewUserModal: true })}
              >
                <Icon name='plus' /> New
              </Button>
            </div>
            <div className='card-block'>{this.renderApplications()}</div>
          </div>
          <CreateUserModal
            open={this.state.openNewUserModal}
            onClose={this.closeModal.bind(this)}
            dispatch={this.props.dispatch}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let applications = state.applications.sort(
    (a, b) => moment(a.createdAt) - moment(b.createdAt),
  );
  const page = ownProps.location.pathname.split('/').slice(-1)[0];
  if (page === 'invited') {
    applications = applications.filter(a => {
      const idPending =
        highestStatus(a.documents.filter(d => d.type === 'id')) ===
        'pending_upload';
      const addressPending =
        highestStatus(a.documents.filter(d => d.type === 'address')) ===
        'pending_upload';
      return idPending || addressPending;
    });
  }
  if (page === 'pending-review') {
    applications = applications.filter(a => {
      const idReady =
        highestStatus(a.documents.filter(d => d.type === 'id')) !==
        'pending_upload';
      const addressReady =
        highestStatus(a.documents.filter(d => d.type === 'address')) !==
        'pending_upload';

      const matched = a.homeAffairs.matchedAt;
      const idDone =
        highestStatus(a.documents.filter(d => d.type === 'id')) === 'approved';
      const addressDone =
        highestStatus(a.documents.filter(d => d.type === 'address')) ===
        'approved';

      return (idReady || idDone) && (addressReady || addressDone) && !matched;
      //TODO need an explicit way to know an application has been issued
    });
  }
  return {
    applications,
    page,
  };
};

export default connect(mapStateToProps)(Applications);

const sideBarSchema = {
  name: 'Users',
  routes: () => {
    return [
      {
        title: true,
        name: 'Filter',
      },
      {
        name: 'All',
        url: '/users/all',
      },
      {
        name: 'Invited',
        url: '/users/applications/invited',
      },
      {
        name: 'Pending review',
        url: '/users/applications/pending-review',
      },
    ];
  },
};
