import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import { observer } from 'mobx-react';
import moment from 'moment';
import '../styles/datepicker/datepicker.scss';

@observer
export class DatePicker extends Component<any, any> {
  onChange = (date: Date | null) => {
    const { formStore, keyVal, validators } = this.props;
    if (date) {
      const momentToString = moment(date)
        .format('YYYY-MM-DD')
        .toString();
      this.props.onChange(momentToString);
      formStore.updateComponentValid(keyVal, validators, true);
      formStore.updateValidTotalDirect(
        keyVal,
        !!formStore.componentValid[keyVal].validation.valid,
      );
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, undefined);
      formStore.updateComponentValid(keyVal, validators, true);
      formStore.updateValidTotalDirect(this.props.keyVal, false);
    }
  };

  componentDidMount() {
    const { keyVal, formStore, validators } = this.props;
    formStore.updateValidTotal(
      keyVal,
      formStore.inputState[keyVal],
      validators,
    );
  }

  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, null);
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, null);
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  valid = (valid: boolean) => {
    return valid ? '' : 'is-invalid';
  };

  render() {
    const options = this.props.componentProps;
    const dropDownMode =
      options && options.dropDownMode ? options.dropDownMode : 'select';
    const autoComplete = options && options.autoComplete ? 'on' : 'off';
    const showMonthDropdown =
      options && options.showMonthDropdown ? options.showMonthDropdown : true;
    const showYearDropdown =
      options && options.showYearDropdown ? options.showYearDropdown : true;

    const { validators, keyVal, formStore, componentProps } = this.props;

    const vaild = formStore.componentValid[keyVal].validation.valid;

    return (
      <div className='form-group col-sm-6'>
        <label className='form-label' htmlFor={this.props.keyVal}>
          {this.props.label}
        </label>
        <ReactDatePicker
          id={keyVal}
          className={`form-control
          ${this.valid(vaild)} react-datepicker-height `}
          {...componentProps}
          key={`date-picker-${keyVal}`}
          style={{ borderColor: this.valid }}
          onChange={date => this.onChange(date)}
          isClearable={this.props.required === true ? false : true}
          selected={
            formStore.inputState && formStore.inputState[keyVal]
              ? new Date(formStore.inputState[keyVal])
              : undefined
          }
          dateFormat='yyyy-MM-dd'
          placeholderText={this.props.placeholder}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
          dropdownMode={dropDownMode}
          autoComplete={autoComplete}
          onBlur={() =>
            formStore.updateComponentValid(keyVal, validators, true)
          }
        />
        <div className='date-picker-parent'>
          {!formStore.componentValid[keyVal].validation.valid
            ? formStore.componentValid[keyVal].validation.failCondition
            : ''}
        </div>
      </div>
    );
  }
}

export default DatePicker;
