import React from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys } from '../../../helpers';
import UpdateRequestsTable from './update-requests-table';
import { PaginationComponent } from './../../../components/pagination';

export default class PolicyUpdateRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updateRequests: null,
      loading: true,
      requestType: props.requestType,
      page: 0,
      pageSize: 50,
    };
  }

  componentDidMount() {
    this.fetchUpdateRequests();
  }

  batchUpdateRequests = async requestType => {
    try {
      await ajax({
        type: 'POST',
        path: '/insurance/admin/update-requests/action',
        data: {
          type: `${requestType}`,
        },
      });
    } catch (e) {
      window.alert(`Error:\n${e.message}`);
    }
  };

  fetchUpdateRequests = async () => {
    this.setState({ loading: true });

    const queryString = `status=pending&include=policy&type=${this.state.requestType}`;
    const pagination = `&page=${this.state.page + 1}&page_size=${
      this.state.pageSize
    }`;
    try {
      const updateRequests = (await ajax({
        type: 'GET',
        path: `/insurance/admin/update-requests?${queryString}${pagination}`,
      }))
        .map(toCamelCaseKeys)
        .sort(
          (a, b) =>
            a.createdAt < b.createdAt ? -1 : a.policyId < b.policyId ? -1 : 1, // Ensure fixed ordering
        );
      this.setState({ updateRequests, loading: false });
    } catch (e) {
      window.alert(`Error: ${e.message}`);
    }

    this.setState({ loading: false });
  };

  handleClick = async () => {
    const { requestType } = this.state;
    if (
      !window.confirm(
        `Are you sure you want to ${requestType} to all policies?`,
      )
    ) {
      return;
    }
    await this.batchUpdateRequests(requestType);
    await this.fetchUpdateRequests();
  };

  render() {
    const { updateRequests, requestType, page, pageSize } = this.state;

    return (
      <div className='padded'>
        <Card>
          <CardHeader>
            Pending {requestType} requests (
            {updateRequests && updateRequests.length}){' '}
            {this.state.loading && <i>Loading....</i>}
            {updateRequests && updateRequests.length > 0 ? (
              <span className='pull-right'>
                <Button color='primary' onClick={this.handleClick}>
                  Submit {requestType} requests
                </Button>
              </span>
            ) : (
              <div> </div>
            )}
          </CardHeader>
          <CardBody>
            {updateRequests && updateRequests.length ? (
              <div>
                <UpdateRequestsTable
                  updateRequests={updateRequests}
                  refresh={this.fetchUpdateRequests}
                />
              </div>
            ) : (
              <div>No {requestType} requests</div>
            )}
            <PaginationComponent
              page={page || 0}
              limit={pageSize}
              disabled={false}
              goToPage={page => {
                this.setState(
                  {
                    page,
                  },
                  async () => await this.fetchUpdateRequests(),
                );
              }}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
