import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';
import { Moment } from 'moment';
import { AnnuityRequestStatus } from './annuity-request-status';
import { AnnuityFrequencyType } from './annuity-frequency-type';
import { AnnuityRequestReviewStatus } from './annuity-request-review-status';

export class AnnuityRequestBlock extends Block {
  title?: string;
  description?: string;
  annuityTypeKey?: string;
  policyId: string;
  initialStatus: AnnuityRequestStatus;
  reviews: {
    status: AnnuityRequestReviewStatus;
    questionnaire?: object;
  };
  frequency: {
    type: AnnuityFrequencyType;
    timeOfDay: string;
    dayOfMonth: number;
    monthOfYear: number;
  };
  duration: {
    count?: number;
    endDate?: Moment;
  };
  amount: number;

  constructor(init: Construct<AnnuityRequestBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      annuity_type_key: this.annuityTypeKey,
      policy_id: this.policyId,
      initial_status: this.initialStatus,
      reviews: {
        status: this.reviews.status,
        questionnaire: this.reviews.questionnaire,
      },
      frequency: {
        type: this.frequency.type,
        time_of_day: this.frequency.timeOfDay,
        day_of_month: this.frequency.dayOfMonth,
        month_of_year: this.frequency.monthOfYear,
      },
      duration: this.duration.count
        ? { count: this.duration.count }
        : { end_date: this.duration.endDate },
      amount: this.amount,
    };
  }

  static fromJSON(init: any) {
    return new AnnuityRequestBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      annuityTypeKey: init.annuity_type_key,
      policyId: init.policy_id,
      initialStatus: init.initial_status,
      reviews: {
        status: init.reviews.status,
        questionnaire: init.reviews.questionnaire,
      },
      frequency: {
        type: init.frequency.type,
        timeOfDay: init.frequency.time_of_day,
        dayOfMonth: init.frequency.day_of_month,
        monthOfYear: init.frequency.month_of_year,
      },
      duration: init.duration.count
        ? { count: init.duration.count }
        : { endDate: init.duration.end_date },
      amount: init.amount,
    });
  }
}
