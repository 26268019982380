import { useEffect, useMemo, useState } from 'react';
import { getRoles } from '../roles-api';
import { defaultPagination } from '../../../../shared/domain/pagination';
import { UUID } from '../../../../shared/domain/uuid';
import { Role } from '../domain/role';

export const useRolesList = (organizationId: string) => {
  const [rolesList, setRolesList] = useState<Role[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const load = async () => {
      try {
        const roles = await getRoles({
          pagination: { page: defaultPagination.page, pageSize: 99 },
          organizationId: UUID.fromString(organizationId),
        });

        setRolesList(roles);
      } catch (error) {
        setError(JSON.parse(error.message).message);
      }
    };

    organizationId && load();

  }, [organizationId]);

  return useMemo(() => ({roles: rolesList, error}), [rolesList, error]);
};
