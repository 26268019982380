import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import { toSentenceCase } from '../../helpers';
import { get, renderValue } from '../helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class PeachCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    const env = runtimeEnv();
    const peachCallbackUrl =
      env.REACT_APP_COLLECT_HOST +
      '/peach-callbacks/' +
      this.props.params.peachCallbackId;
    const peachCallback = await get(peachCallbackUrl);

    this.setState({ peachCallback });
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Peach Callback</CardHeader>
          <CardBody>
            {this.state.peachCallback ? (
              <Table size='sm'>
                <tbody>
                  {Object.keys(this.state.peachCallback)
                    .filter(k => k !== 'responseXml')
                    .map(k => (
                      <tr key={k}>
                        <td>
                          <b>{toSentenceCase(k)}</b>
                        </td>
                        <td>{renderValue(k, this.state.peachCallback[k])}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />

        <Card>
          <CardHeader>Response Payload</CardHeader>
          <CardBody>
            {this.state.peachCallback ? (
              <div>
                {renderValue(
                  'responseXml',
                  this.state.peachCallback.responseXml,
                )}
              </div>
            ) : (
              <div>
                <i>Loading...</i>
              </div>
            )}
          </CardBody>
        </Card>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(PeachCallback);
