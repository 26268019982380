import React from 'react';
import ProductModuleDefinitionClaimBlocksSchema from './product-module-definition-claim-blocks-schema';
import ProductModuleDefinitionClaimSchema from './product-module-definition-claim-schema';
import ProductModuleDefinitionClaimChecklist from './product-module-definition-claim-checklist';
import ProductModuleDefinitionClaimAnnuityTypes from './product-module-definition-claim-annuity-types';
import ProductModuleDefinitionClaimFulfillmentTypes from './product-module-definition-claim-fulfillment-types';

interface Props {
  productModuleKey: string;
}

export default class ProductModuleDefinitionClaims extends React.Component<
Props,
any
> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <ProductModuleDefinitionClaimBlocksSchema
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionClaimSchema
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionClaimAnnuityTypes
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionClaimFulfillmentTypes
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionClaimChecklist
          productModuleKey={this.props.productModuleKey}
        />
      </div>
    );
  }
}
