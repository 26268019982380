import { observable, computed } from 'mobx';
import { debounce } from '../../../../helpers/debounce';
import { Pagination } from '../../../../shared/domain/pagination';
import { DataExportApi } from '../data-export-api';
import { DataExportRun } from '../domain/data-export-run';

export class DataExportRunListStore {
  @observable scheduledDataExportId: string;
  @observable dataExportRuns: DataExportRun[] = [];
  @observable isLoading = true;
  @observable total = 0;
  @observable pagination: Pagination = { page: 0, pageSize: 10 };
  @observable organizationId: string = '';

  load = async (params: {
    organizationId: string;
    scheduledDataExportId: string;
    pagination?: Pagination;
  }) => {
    const { scheduledDataExportId, organizationId, pagination } = params;
    this.isLoading = true;
    this.organizationId = organizationId;
    this.dataExportRuns = [];
    this.scheduledDataExportId = scheduledDataExportId;
    if (pagination) {
      this.pagination = pagination;
    }

    try {
      const { dataExportRuns, total } = await DataExportApi.runs({
        organizationId,
        scheduledDataExportId,
        page: pagination ? pagination.page : this.pagination.page + 1,
        pageSize: pagination ? pagination.pageSize : this.pagination.pageSize,
      });
      this.dataExportRuns = dataExportRuns;
      this.total = total;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !this.isLoading && this.dataExportRuns.length === 0;
  }

  setDataExportRunListStoreLoadingTrue = () => {
    this.isLoading = true;
  }

  goToPage = (pageNumber: number) => {
    this.pagination.page = pageNumber;
    debounce('go-to-page', () => this.load({
      organizationId: this.organizationId,
      scheduledDataExportId: this.scheduledDataExportId,
    }));
  }
}

export const dataExportRunListStore = new DataExportRunListStore();
