import { BlockType } from './block-type';

export abstract class Block {
  readonly type: BlockType;
  readonly key: string;

  abstract toJSON(): {
    type: BlockType;
    key: string;
  };
}
