import React from 'react';
import { CardBody, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import '../../../../../../../../styles/styles.scss';
import { observer, inject } from 'mobx-react';
import '../../styles/general.scss';
import { Store, ObservablesTemp, Observables } from '../../store';
import GracePeriodRule from './components/grace-period-rule';
// import GracePeriodNotification from './components/grace-period-notification-rule';
import { FormOptions } from './config';
import { flattenObject } from '../../utils';
import { savePolicyLifecycle } from '../../orchestration';

import { nestedStyleCheckbox } from '../../../../utils';
import { ProductModuleStore } from '../../../../../../stores/product-module-store';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../../../../stores/product-module-component-loading-state-store';
import { debounce } from '../../../../../../../../../helpers/debounce';
import { SavingState } from '../../../../../../util';

enum enumOfState {
  countRules = 'countRules',
  countNotifications = 'countNotifications',
}

interface Props {
  store: Store;
  productModuleStore?: ProductModuleStore;
  closeWindow: () => void;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface State {
  countRules: number;
  countNotifications: number;
}

const gracePeriodKey = 'gracePeriod';
@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
class GracePeriod extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      countRules: FormOptions.startingGracePeriodRules,
      countNotifications: 0,
    };
  }

  componentWillMount() {
    const { store, productModuleComponentLoadingStateStore } = this.props;
    store.updatePrimaryToTemp(
      Observables.gracePeriodRules,
      ObservablesTemp.gracePeriodRulesTemp,
    );
    this.setState({
      countRules:
        Object.keys(store.lifecycleStoreTemp.gracePeriodRulesTemp).length ||
        FormOptions.startingGracePeriodRules,
    });
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.policyLifecycleComponents,
        gracePeriodKey,
      );
    }
  }

  saveAndClose = async () => {
    const {
      store,
      closeWindow,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    store.updateTempToPrimary(
      Observables.gracePeriodRules,
      ObservablesTemp.gracePeriodRulesTemp,
    );
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        gracePeriodKey,
        SavingState.Saving,
      );
    }
    if (productModuleStore) {
      await savePolicyLifecycle(
        store.lifecycleStore,
        productModuleStore.productModuleDefinitionDraft,
      );
      productModuleStore.init(productModuleStore.loadedProductModuleKey, true);
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        gracePeriodKey,
        SavingState.Saved,
      );

      debounce(
        gracePeriodKey,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.policyLifecycleComponents,
            gracePeriodKey,
            SavingState.Default,
          ),
        3000,
      );
    }
    closeWindow();
  };

  cancelAndClose = () => {
    const { store, closeWindow } = this.props;
    store.updatePrimaryToTemp(
      Observables.gracePeriodRules,
      ObservablesTemp.gracePeriodRulesTemp,
    );
    closeWindow();
  };

  showSaveButton = (store: Store) => {
    let valid = true;
    const { lifecycleStoreTemp } = store;
    const { gracePeriodRulesTemp } = lifecycleStoreTemp;
    const gracePeriodRulesTempFlat = flattenObject(gracePeriodRulesTemp);

    for (const key in gracePeriodRulesTempFlat) {
      if (!gracePeriodRulesTempFlat[key]) {
        return (valid = false);
      }
    }
    return valid;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      countRules:
        Object.keys(nextProps.store.lifecycleStoreTemp.gracePeriodRulesTemp)
          .length || FormOptions.startingGracePeriodRules,
    });
  }

  addRule = (countState: enumOfState) => {
    let countTemp: number = this.state[countState];
    countTemp++;
    this.setState({ [countState]: countTemp } as Pick<State, keyof State>);
  };

  reduceCount = (countState: enumOfState) => {
    let count = this.state[countState];
    count--;
    this.setState({ [countState]: count } as Pick<State, keyof State>);
  };

  renderChildrenRules = (count: number) => {
    const components = [];
    const { store } = this.props;

    for (let i = 0; i < count; i++) {
      components.push(
        <GracePeriodRule
          index={i}
          allowList={FormOptions.allowListRules}
          reduceCount={() => this.reduceCount(enumOfState.countRules)}
          key={`${GracePeriodRule}_${i}`}
          store={this.props.store}
          removeFromLifeCycleStore={(
            lifecycleObservable: ObservablesTemp,
            index: number,
          ) => store.removeFromLifeCycleStore(lifecycleObservable, index)}
        />,
      );
    }
    return components;
  };

  renderChildrenNotifications = (count: number) => {
    // const components = [];
    // const { store } = this.props;
    // for (let i = 0; i < count; i++) {
    //   components.push(
    //     <GracePeriodNotification
    //       index={i + 1}
    //       allowList={FormOptions.allowListNotifications}
    //       reduceCount={() => this.reduceCount(enumOfState.countNotifications)}
    //       key={`${GracePeriodNotification}_${i}`}
    //       store={this.props.store}
    //       gracePeriodNotificationsTemp={null}
    //       updateLifeCycleStore={(
    //         lifecycleObservable: ObservablesTemp,
    //         path: string,
    //         value: string,
    //         index: number,
    //       ) => store.updateLifeCycleStore(lifecycleObservable, path, value, index)}
    //       removeFromLifeCycleStore={(lifecycleObservable: ObservablesTemp, index: number) =>
    //         store.removeFromLifeCycleStore(lifecycleObservable, index)
    //       }
    //     />,
    //   );
    // }
    // return components;
  };

  render() {
    const allowListRules = FormOptions.allowListRules;
    const allowListNotifications = FormOptions.allowListNotifications;
    const showNotifications = FormOptions.showNotifications;
    const { countRules, countNotifications } = this.state;

    return (
      <div className='product-module-definition-lifecycle-rules-position'>
        <p className='product-module-heading-description-spacing'>
          The conditions under which a policy will be lapsed after non-payment
          of a premium.
        </p>
        <Row>
          <Col xs={12}>
            <CardBody style={nestedStyleCheckbox(true, 1)}>
              <h5
                style={{
                  fontWeight: 'bold',
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                Grace period rules
              </h5>
              <Form>
                {this.renderChildrenRules(countRules)}
                {allowListRules && (
                  <div style={{ marginTop: 0 }}>
                    <FormGroup className='product-modules-form-group-align'>
                      <Button
                        onClick={() => this.saveAndClose()}
                        color='primary'
                        className='product-module-definition-lifecycle-component-editing-buttons'
                        disabled={!this.showSaveButton(this.props.store)}
                      >
                        Done
                      </Button>
                      <Button
                        className='product-module-definition-lifecycle-component-editing-buttons'
                        color='link'
                        onClick={() => this.addRule(enumOfState.countRules)}
                      >
                        + Add rule
                      </Button>
                      <Button
                        className='product-module-definition-lifecycle-component-editing-buttons'
                        onClick={() => this.cancelAndClose()}
                        color='link'
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </div>
                )}
              </Form>
              {showNotifications && <hr />}
              {showNotifications && (
                <div>
                  <h5
                    style={{
                      fontWeight: 'bold',
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    Grace period notifications
                  </h5>
                  <Form>
                    {this.renderChildrenNotifications(countNotifications)}
                    {allowListNotifications && (
                      <div style={{ marginTop: 0 }}>
                        <FormGroup className='product-modules-form-group-align'>
                          <Button
                            style={{ marginRight: -3 }}
                            onClick={() => this.saveAndClose()}
                            color='primary'
                            className='product-module-definition-lifecycle-component-editing-buttons'
                            disabled={!this.showSaveButton(this.props.store)}
                          >
                            Done
                          </Button>
                          <Button
                            color='link'
                            className='product-module-definition-lifecycle-component-editing-buttons'
                            onClick={() =>
                              this.addRule(enumOfState.countNotifications)
                            }
                          >
                            + Add rule
                          </Button>
                          <Button
                            className='product-module-definition-lifecycle-component-editing-buttons'
                            onClick={() => this.cancelAndClose()}
                            color='link'
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </div>
                    )}
                  </Form>
                </div>
              )}
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

export default GracePeriod;
