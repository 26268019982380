const getQueryParams = query => {
  if (!query) {
    return {};
  }
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

export const getParams = () => {
  const queryParams = getQueryParams(window.location.search);
  const pageIndex = parseInt(queryParams.i, 10) || 0;
  const searchQuery = queryParams.s || '';
  return { pageIndex, searchQuery };
};

export const setParams = (pageIndex, searchQuery) => {
  const safe = encodeURIComponent(searchQuery);
  const queries = [];
  if (pageIndex > 0) {
    queries.push('i=' + pageIndex);
  }
  if (searchQuery && searchQuery !== '') {
    queries.push('s=' + safe);
  }
  history.pushState({}, '', '?' + queries.join('&')); // eslint-disable-line
};
