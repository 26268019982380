import { observable, computed } from 'mobx';
import { ReportApi, ReportFilters } from './report-api';
import { Report } from '../domain/reports';
import { Pagination, defaultPagination } from '../../../../shared/domain/pagination';

export class ReportListStore {
  @observable reports: Report[] = [];
  @observable isLoading = true;
  @observable filters: ReportFilters = {};
  @observable pagination: Pagination = defaultPagination;
  @observable total = 0;


  load = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    this.isLoading = true;
    try {
      const { reports, total } = await ReportApi.get({
        organizationId,
        pagination: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        },
        includes: [],
        filters: this.filters,
      });
      this.reports = reports;
      this.total = total;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  };

  clear = () => {
    this.reports = [];
    this.filters = {};
    this.total = 0;
    this.pagination = defaultPagination;
    this.isLoading = false;
  }

  filter = (params: { filters: ReportFilters; organizationId: string }) => {
    const { filters, organizationId } = params;
    this.pagination.page = 0;
    this.filters = filters;
    this.load({
      organizationId,
    });
  };

  goToPage = (params: { pageNumber: number; organizationId: string } ) => {
    const { organizationId, pageNumber } = params;
    this.pagination.page = pageNumber + 1;
    this.load({
      organizationId,
    });
  }

  @computed get isEmpty() {
    return !this.isLoading && this.reports.length === 0;
  }

  setReportListStoreIsLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const reportListStore = new ReportListStore();
