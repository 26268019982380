import ajax from '../../helpers/ajax';
import { applicationsMap } from '../../helpers/maps';

export const getApplications = userId => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'GET',
      path: '/admin/applications',
    }).then(result => {
      dispatch({
        type: 'SET_APPLICATIONS',
        applications: result.map(applicationsMap),
      });
    });
  };
};

export const createUser = (firstName, email, bankAccess, offerzenMake) => {
  return dispatch => {
    return ajax({
      dispatch: dispatch,
      type: 'POST',
      path: '/admin/invite-beta-user',
      data: {
        first_name: firstName,
        email: email,
        bank_access: bankAccess,
        offerzen_make: offerzenMake,
      },
    });
  };
};
