import React, {ReactElement} from 'react';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { GroupBlock as Block } from '../domain/group-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { GroupBlockState } from '../domain/states/group-block-state';
import { Card, CardBody, CardHeader, CardTitle, Collapse } from 'reactstrap';
import { Icon16PXChevron } from '../../../../../../components/icons/icon-16-chevron';

interface Props {
  block: Block;
  blockState: GroupBlockState;
  childBlocks: React.ReactNodeArray;
}

interface State {
  groupBlockState?: BlockState;
  isOpen: boolean;
}

@observer
export class GroupBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: true,
      groupBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const {block, childBlocks} = this.props;
    const {isOpen, groupBlockState } = this.state;

    const handleToggle = () => block.collapsible && this.setState(({ isOpen }) => ({isOpen: !isOpen}));

    if (!groupBlockState) {
      return null;
    }

    return (
      <Card className='blocks-details-card'>
        <CardHeader onClick={handleToggle} style={{cursor: block.collapsible ? 'pointer': 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <CardTitle tag="h5">{block.title}</CardTitle>
          {block.collapsible && <Icon16PXChevron rotate={isOpen} />}
        </CardHeader>

        <Collapse isOpen={isOpen}>
          <CardBody>
            {React.Children.map(childBlocks, (child: ReactElement) => React.cloneElement(child, { ...child.props, key: block.key  }))}
          </CardBody>
        </Collapse>

      </Card>
    );
  }
}
