/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 24 24'
      >
        <g fill='none' fillRule='evenodd'>
          <circle cx='12' cy='12' r='12' fill='#1FC881' />
          <path
            fill='#FFF'
            d='M10.414 13.343l-3.172-3.171L5 12.414l4.293 4.293a1.582 1.582 0 0 0 2.242 0L19 9.242 16.758 7l-6.344 6.343z'
          />
        </g>
      </svg>
    );
  }
}
