import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import { DataExportRunLogStore } from '../stores/data-export-run-log-store';
import Images from '../../../../components/svg-images';
import { ajax } from '../../../../helpers';
import { DataExportRunListStore } from '../stores/data-export-run-list-store';
import { Environment } from '../../../../shared/domain/environment';
import { DataExportViewStore } from '../stores/data-export-view-store';
import { DataExportRunStatus, DataExportRun } from '../domain/data-export-run';
import { ScheduledDataExport } from '../domain/scheduled-data-export';
import { PaginationComponent } from '../../../../components/pagination';

interface RunLogProps {
  dataExportRun: DataExportRun;
  organizationId: string;
}

interface Injected extends RunLogProps {
  dataExportRunLogStore: DataExportRunLogStore;
}

interface RunLogState {}

@inject('dataExportRunLogStore')
@observer
class RunLog extends React.Component<RunLogProps, RunLogState> {
  constructor(props: RunLogProps) {
    super(props);

    this.injected.dataExportRunLogStore.setDataExportRunLogListStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  componentDidMount() {
    const { dataExportRunLogStore, dataExportRun } = this.injected;
    dataExportRunLogStore.load({
      dataExportRunId: dataExportRun.dataExportRunId,
      organizationId: this.props.organizationId,
    });
  }

  render() {
    const { dataExportRunLogStore } = this.injected;

    const { isLoading, dataExportRunLogItems } = dataExportRunLogStore;

    return (
      <div className='data-export-run-logs'>
        {isLoading && <span>Loading...</span>}
        {!isLoading && (
          <table className='no-hover'>
            <tbody>
              {dataExportRunLogItems.map((l, i) => (
                <tr key={`${l.dataExportRunId}-${i}`}>
                  <td style={{ width: '250px' }}>
                    {l.createdAt.toISOString()}
                  </td>
                  <td style={{ width: '100px' }}>[{l.level}]</td>
                  <td>{l.content}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

const sortDataExportRuns = (a: DataExportRun, b: DataExportRun) => {
  if (a.createdAt.isBefore(b.createdAt)) {
    return 1;
  } else if (a.createdAt.isAfter(b.createdAt)) {
    return -1;
  }

  return 0;
};

interface HistoryProps {
  organizationId: string;

}

interface Injected extends HistoryProps {
  dataExportRunListStore: DataExportRunListStore;
  dataExportViewStore: DataExportViewStore;
}

interface HistoryState {
  selectedLogId?: string;
}

@inject('dataExportRunListStore')
@inject('dataExportViewStore')
@observer
export class History extends React.Component<HistoryProps, HistoryState> {
  constructor(props: HistoryProps) {
    super(props);

    this.state = {
      selectedLogId: undefined,
    };

    this.injected.dataExportRunListStore.setDataExportRunListStoreLoadingTrue();
    this.injected.dataExportViewStore.setDataExportViewStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  downloadFile = async (dataExport: ScheduledDataExport, dataExportRun: DataExportRun) => {
    if (!dataExportRun.fileId) {
      return;
    }

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    const response = await ajax({
      path: `/apps/${this.props.organizationId}/insurance/data-export-runs/${dataExportRun.dataExportRunId}/attachment`,
      type: 'get',
      sandbox: dataExport.environment === Environment.Sandbox,
      raw: true,
    });

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    anchor.href = objectUrl;
    anchor.download = 'export.csv';
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
  };

  async componentDidMount() {
    const { dataExportRunListStore, dataExportViewStore, organizationId } = this.injected;

    await Promise.all([
      dataExportRunListStore.load({
        scheduledDataExportId: window.location.pathname.split('/')[4],
        organizationId,
      }),
      dataExportViewStore.load({
        scheduledDataExportId: window.location.pathname.split('/')[4],
        organizationId,
      }),
    ]);
  }

  renderLoading() {
    return (
      <table>
        <thead>
          <tr>
            <th className='data-export-heading-item'>
              DATE
            </th>
            <th className='data-export-heading-item'>
              STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: '250px' }} />
            <td />
          </tr>
          <tr>
            <td style={{ width: '250px' }} />
            <td />
          </tr>
          <tr>
            <td style={{ width: '250px' }} />
            <td />
          </tr>
        </tbody>
      </table>
    );
  }

  iconForStatus(status: DataExportRunStatus) {
    switch (status) {
      case DataExportRunStatus.Complete:
        return <Images.success />;
      case DataExportRunStatus.Failed:
        return <Images.error />;
      case DataExportRunStatus.InProgress:
        return <Images.inProgress />;
    }
  }

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>This export hasn&#39;t been run yet!</p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  renderLoaded(dataExportViewStore: DataExportViewStore, dataExportRunListStore: DataExportRunListStore) {
    if (!dataExportViewStore.dataExport) {
      return null;
    }

    const { dataExport } = dataExportViewStore;
    const { selectedLogId } = this.state;
    const {
      dataExportRuns,
      isLoading,
      total,
      pagination,
    } = dataExportRunListStore;
    const pageChangeHandler = (newPage: number) => dataExportRunListStore.goToPage(newPage);

    return (
      <div>
        <table className='data-export-details-table'>
          <thead>
            <tr>
              <th style={{
                width: '20%',
              }}
              className='data-export-heading-item-run-history'>
                DATE
              </th>
              <th className='data-export-heading-item-run-history'>
                STATUS
              </th>
              <th className='data-export-heading-item-run-history' />
            </tr>
          </thead>
          <tbody>
            {dataExportRuns
              .slice()
              .sort(sortDataExportRuns)
              .map(run => {
                const logsVisible = selectedLogId === run.dataExportRunId;
                const rows = [
                  <tr
                    className={`data-export-history-item ${
                      logsVisible ? 'logs-visible' : ''
                    }`}
                    key={run.dataExportRunId}
                  >
                    <td>
                      <strong>{run.createdAt.format('YYYY-MM-DD')}</strong>
                    </td>
                    <td className='data-export-details-table-item-label'>
                      <div className='status'>
                        <div className='status-icon'>
                          {this.iconForStatus(run.status)}
                        </div>
                        <div>
                          <span>
                            <strong>{run.prettyStatus()}</strong>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      className='data-export-details-table-item-label'
                      style={{ textAlign: 'right' }}
                    >
                      {run.fileId && <Button color='link'>
                        <a
                          href='#'
                          onClick={e => {
                            e.preventDefault();
                            this.downloadFile(dataExport, run);
                          }}
                        >
                          Download file
                        </a>
                      </Button>}
                      {run.status !== DataExportRunStatus.InProgress &&
                        selectedLogId !== run.dataExportRunId && (
                        <Button
                          color='link'
                          onClick={() =>
                            this.setState({
                              selectedLogId: run.dataExportRunId,
                            })
                          }
                        >
                          Show logs
                        </Button>
                      )}
                      {run.status !== DataExportRunStatus.InProgress &&
                        selectedLogId === run.dataExportRunId && (
                        <Button
                          color='link'
                          onClick={() =>
                            this.setState({ selectedLogId: undefined })
                          }
                        >
                          Hide logs
                        </Button>
                      )}
                    </td>
                  </tr>,
                ];

                if (selectedLogId === run.dataExportRunId) {
                  rows.push(
                    <tr key={`${run.dataExportRunId}-logs`}>
                      <td colSpan={3}>
                        <RunLog
                          organizationId={this.props.organizationId}
                          dataExportRun={run}
                        />
                      </td>
                    </tr>,
                  );
                }

                return rows;
              })}
          </tbody>
        </table>
        <PaginationComponent
          page={pagination.page || 0}
          limit={pagination.pageSize}
          total={total || 0}
          disabled={isLoading}
          goToPage={pageChangeHandler}
        />
      </div>
    );
  }

  render() {
    const { dataExportRunListStore, dataExportViewStore } = this.injected;

    const { isEmpty } = dataExportRunListStore;
    const isLoading = dataExportRunListStore.isLoading || dataExportViewStore.isLoading;

    return (
      <div className='section no-section-divider' id='run-history'>
        <div className='data-export-heading'>
          <h4 style={{ flex: 1 }}>Run history</h4>
        </div>
        {isLoading && this.renderLoading()}
        {!isLoading && isEmpty && this.renderEmpty()}
        {!isLoading &&
          !isEmpty &&
          this.renderLoaded(dataExportViewStore, dataExportRunListStore)}
      </div>
    );
  }
}
