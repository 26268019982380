import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { AnnuityRequestBlock as Block } from '../domain/annuity-request-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { AnnuityRequestBlockState } from '../domain/states/annuity-request-block-state';
import Button from 'reactstrap/lib/Button';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { AnnuityFrequencyType } from '../domain/annuity-frequency-type';
import { intToOrdinalNumberString } from '../../../../../../shared/helpers/numbers';

interface Props {
  disabled?: boolean;
  block: Block;
  blockState: AnnuityRequestBlockState;
}

const getPrettyAnnuityFrequency = (params: {
  type: AnnuityFrequencyType;
  timeOfDay: string;
  dayOfMonth: number;
  monthOfYear: number;
}) => {
  const { type, timeOfDay, dayOfMonth, monthOfYear } = params;

  switch (type) {
    case AnnuityFrequencyType.Monthly:
      return `the ${intToOrdinalNumberString(
        dayOfMonth,
      )} of every month at ${timeOfDay} UTC`;
    case AnnuityFrequencyType.Yearly:
      return `the ${intToOrdinalNumberString(dayOfMonth)} of ${prettyMonth(
        monthOfYear,
      )} at ${timeOfDay} UTC`;
    default:
      return '';
  }
};

const prettyMonth = (num: number) => {
  switch (num) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
};

interface State {
  UIState: BlockUIState;
  annuityRequestBlockState?: BlockState;
}

@observer
export class AnnuityRequestBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      annuityRequestBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { annuityRequestBlockState } = this.state;
    if (!annuityRequestBlockState) {
      return null;
    }

    return (
      <Card className='blocks-details-card'>
        <CardBody>
          <div className='heading'>
            <div className='title'>
              <h4
                style={{
                  display: 'inline-block',
                  margin: '0 0 16px 0',
                  width: '50%',
                }}
              >
                {block.title}
              </h4>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                {blockState.annuityRequestId === undefined ? (
                  <Button  color='primary' disabled={this.state.UIState === BlockUIState.Error || disabled}>
                    Create annuity payout schedule
                  </Button>
                ) : (
                  <a>
                    View request
                  </a>
                )}
              </div>
            </div>
            <hr />
          </div>
          <div className='summary blocks-ui'>
            <table className='no-hover summary-table' style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>Description</td>
                  <td className='align-right'>{block.description}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className='align-right'>
                    R {block.amount / 100.00}
                  </td>
                </tr>
                <tr>
                  <td>Frequency</td>
                  <td className='align-right'>{`Payouts will occur ${getPrettyAnnuityFrequency(
                    {
                      ...block.frequency,
                    },
                  )}`}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td className='align-right'>
                    {block.duration.count
                      ? `A total of ${block.duration.count} payouts will occur.`
                      : `Payouts will end on the ${moment(
                        block.duration.endDate,
                      ).format('DD MMM YYYY')}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  }
}
