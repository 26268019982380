const policy = {
  policy_id: 'bac3db77-56fa-432c-8e87-afc2e666ed70',
  scheme_type: 'individual',
  created_at: '2019-03-18T13:23:21.793Z',
  created_by: {
    type: 'api_key',
    id: '05f00887-5b47-469b-83a7-1f03ffea95a1',
    owner_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  policy_number: '1J1NY5IG6Q',
  policyholder_id: '4e42531a-8304-4073-ad3f-803bce4fde84',
  package_name: 'Funeral Cover: Main Member',
  sum_assured: 3200000,
  base_premium: 2704,
  monthly_premium: 3477,
  billing_amount: 3477,
  billing_frequency: 'monthly',
  billing_day: 1,
  start_date: '2019-03-18T13:23:21.000Z',
  end_date: '2020-03-18T13:23:21.000Z',
  cancelled_at: null,
  reason_cancelled: null,
  app_data: null,
  module: {
    age: 24,
    type: 'root_funeral',
    cover_amount: 3200000,
    spouse_included: false,
    children_included: false,
    extended_family_included: false,
  },
  schedule_versions: [{ version: 1, created_at: '2019-03-18T13:23:28.739Z' }],
  current_version: 1,
  terms_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/terms/terms.pdf',
  policy_schedule_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/schedule/schedule_latest.pdf',
  claim_ids: [],
  complaint_ids: [],
  status: 'pending_initial_payment',
  balance: 0,
  currency: 'ZAR',
  beneficiaries: [
    {
      policyholder_id: 'e3ea736e-fb3e-4628-90d2-72eae2772c25',
      percentage: 100,
      relationship: 'other',
      first_name: 'John',
      last_name: 'Smith',
    },
  ],
  covered_people: [
    {
      covered_person_id: 'c0faceaa-46e6-4a4a-981d-22e31adc2abf',
      first_name: 'John',
      last_name: 'Smith',
      id: {
        type: 'id',
        number: '9401014800081',
        country: 'ZA',
      },
      date_of_birth: '1994-01-01',
      cellphone: '+27831234567',
      email: 'elrich@root.co.za',
      relationship: 'other',
      initials: 'JS',
      title: 'mr',
    },
  ],
};

const policyholder = {
  policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  type: 'individual',
  first_name: 'Erlich',
  last_name: 'Bachman',
  company_name: 'Random Corp',
  registration_number: '123CantCatchMe321',
  id: {
    type: 'id',
    number: '9401014800081',
    country: 'ZA',
  },
  email: 'elrich@root.co.za',
  cellphone: '022 232 2323',
  phone_other: '022 232 3232',
  date_of_birth: '19940101',
  gender: 'non-binary',
  created_at: '"2019-03-01T06:54:33.731Z',
  app_data: null,
  created_by: {
    type: 'user',
    id: 'c7bc530c-7291-4574-96ed-1018c9cd45c8',
    ownerId: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  address: {
    line_1: 'Milky Way',
    line_2: 'Universe',
    code: 0,
  },
};

const member = {
  member_id: '5cbdf75c-9e6f-4f84-9440-561f68231087',
  created_at: '2019-03-18T13:23:21.793Z',
  updated_at: '2019-03-18T13:23:21.793Z',
  status: 'active',
  external_id: '12345ABCDE',
  start_date: '2019-03-18T13:23:21.000Z',
  end_date: '2020-03-18T13:23:21.000Z',
  created_by: {
    type: 'api_key',
    id: '05f00887-5b47-469b-83a7-1f03ffea95a1',
    owner_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  policy_id: 'bac3db77-56fa-432c-8e87-afc2e666ed70',
  first_name: 'Erlich',
  last_name: 'Bachman',
  id: {
    type: 'id',
    number: '9401014800081',
    country: 'ZA',
  },
  email: 'elrich@root.co.za',
  cellphone: '022 232 2323',
  date_of_birth: '19940101',
  gender: 'non-binary',
  beneficiaries: [
    {
      policyholder_id: 'e3ea736e-fb3e-4628-90d2-72eae2772c25',
      percentage: 100,
      relationship: 'other',
      first_name: 'John',
      last_name: 'Smith',
    },
  ],
  app_data: null,
  module: {},
  terms_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/members/5cbdf75c-9e6f-4f84-9440-561f68231087/terms/terms.pdf',
  certificate_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/members/5cbdf75c-9e6f-4f84-9440-561f68231087/certificate/certificate_latest.pdf',
  certificate_versions: [{ version: 1, created_at: '2019-03-18T13:23:28.739Z' }],
};

const paymentMethod = {
  payment_method_id: '0157b955-8ffd-4a14-8625-8b2787a1684c',
  environment: 'sandbox',
  type: 'card',
  created_at: '2018-01-29T11:58:19.194Z',
  created_by: { type: 'system' },
  policyholder_id: 'aa1d0811-6a9e-4fed-a196-22ec28886883',
  bin: '424242',
  holder: 'E gro',
  card_brand: 'VISA',
  expiry_year: '2020',
  expiry_month: '10',
  last_4_digits: '4242',
  registration_id: null,
  raw_checkout_status: null,
};

const application = {
  application_id: '0157b955-8ffd-4a14-8625-8b2787a1684c',
  created_at: '2019-03-18T13:23:21.793Z',
  updated_at: '2019-03-18T13:23:21.793Z',
  created_by: {
    type: 'api_key',
    id: '05f00887-5b47-469b-83a7-1f03ffea95a1',
    owner_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  policyholder_id: '4e42531a-8304-4073-ad3f-803bce4fde84',
  policy_id: 'bac3db77-56fa-432c-8e87-afc2e666ed70',
  package_name: 'Funeral Cover: Main Member',
  sum_assured: 3200000,
  base_premium: 2704,
  monthly_premium: 3477,
  module: {
    age: 24,
    type: 'root_funeral',
    cover_amount: 3200000,
    spouse_included: false,
    children_included: false,
    extended_family_included: false,
  },
  currency: 'ZAR',
};

const schedule = {
  policy,
  policyholder,
  sandox: true,
};

const policyAnniversary = {
  policy,
  policyholder,
  sandox: true,
};

const welcomeLetter = {
  policy,
  policyholder,
  sandbox: true,
};

const memberCertificate = {
  policy,
  policyholder,
  member,
  payment_method: paymentMethod,
};

const quoteSummary = {
  policyholder,
  application,
};

export const mergeVariableStubs = {
  schedule,
  welcomeLetter,
  memberCertificate,
  quoteSummary,
  policyAnniversary,
};
