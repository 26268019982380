import { browserHistory } from 'react-router';
import ajax from './ajax';

const events = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];
const timeout = 2 * 60 * 1000; // 2 min
const allowedTime = 15 * 60 * 1000; // 15 min

let timeoutId;
let lastActive;
let dispatch;

// Listen to events and set lastActive to moment()
const handleEvent = e => {
  lastActive = new Date();
};

// Add event listeners
const addEventListeners = () => {
  events.forEach(e => document.addEventListener(e, handleEvent));
};

const removeEventListeners = () => {
  events.forEach(e => document.removeEventListener(e, handleEvent));
};

// Kill the timeout
export const stopCheck = () => {
  clearTimeout(timeoutId);
  lastActive = undefined;
  removeEventListeners();
};

// This runs every 5 mins
const doCheckAndRefresh = () => {
  // Check if user was active less than 5 mins ago
  clearTimeout(timeoutId);
  if (!lastActive) {
    console.error('Something went wrong, lastActive is not set.');
  }
  if (lastActive.getTime() > new Date().getTime() - allowedTime) {
    // If lastActive is within limits, continue
    // So, fetch a new login token
    ajax({
      dispatch,
      type: 'POST',
      path: '/accounts/refresh-login-token',
    })
      .then(r => {
        // Reset timeout to check again in allowed minutes
        timeoutId = setTimeout(doCheckAndRefresh, timeout);
        const newToken = r.login_token;
        window.localStorage.setItem('login_token', newToken);
      })
      .catch(e => {
        ajax({ dispatch, type: 'POST', path: '/accounts/logout' })
          .catch(err => err)
          .then(r => {
            console.error('Token refresh failed:', e);
            stopCheck();
            window.localStorage.clear();
            browserHistory.push('/login');
          });
      });
  } else {
    // If user was not active recently enough, log them out
    ajax({ dispatch, type: 'POST', path: '/accounts/logout' })
      .catch(err => err)
      .then(r => {
        console.log('Session inactive, logging out.');
        stopCheck();
        window.localStorage.clear();
        browserHistory.push('/login');
      });
  }
};

// Set the timeout and start the show.
export const startCheckIfNeeded = _dispatch => {
  if (!lastActive) {
    lastActive = new Date();
    dispatch = _dispatch;
    timeoutId = setTimeout(doCheckAndRefresh, timeout);
    addEventListeners();
  }
};
