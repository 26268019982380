import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { ObservablesTemp, Store } from '../../store';
import _ from 'lodash';
import { ValueType } from 'react-select/lib/types';
import { debounce } from '../../../../../../../../../helpers/debounce';

interface Props {
  store: Store;
  observable: ObservablesTemp;
  path: string;
  index: number;
  defaultValue?: SelectOptions;
  options: SelectOptions[];
  placeHolder?: string;
  isDisabled?: boolean;
  optionsSpliced?: SelectOptions[];
}

interface SelectOptions {
  value: string;
  label: string;
}

interface State {
  invalid: boolean;
  value: string | null | undefined;
}

@observer
export default class SelectInputLifecycle extends React.Component<
Props,
State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invalid: false,
      value: null,
    };
  }

  componentWillUnmount() {
    const { store, observable, path, index } = this.props;
    store.removeInput(observable, path, index);
  }

  componentDidMount() {
    const { path, defaultValue, store, observable } = this.props;
    const storeValue = this.getValue();

    if (storeValue) {
      this.setState({ value: storeValue });
    } else {
      store.updateLifeCycleStore(
        observable,
        path,
        defaultValue ? defaultValue.value : '',
        this.props.index,
      );
      this.setState({ value: defaultValue ? defaultValue.value : '' });
    }
  }

  getValue = () => {
    const { store, observable, index, path } = this.props;
    const value = _.get(store.lifecycleStoreTemp[observable][index], path);
    return value && value !== undefined ? value : '';
  };

  validSet = (value: string | null | undefined) => {
    const invalid = value && value.length > 0 ? false : true;
    this.setState({ invalid });
  };

  updateInput = (
    value: string | null,
    path: string,
    submitCheck: boolean = false,
  ) => {
    const { observable } = this.props;
    if (submitCheck) {
      this.validSet(value);
    }
    this.setState({ value });
    this.updateStore(observable, path, value, this.props.index);
  };

  updateStore = (
    observable: ObservablesTemp,
    path: string,
    value: string | null,
    index: number,
  ) => {
    debounce(
      'updating-select-input',
      () =>
        this.props.store.updateLifeCycleStore(observable, path, value, index),
      500,
    );
  };

  selectOptionValue = (value: string | null | undefined) => {
    const selectedOption: ValueType<SelectOptions> = this.props.options.find(
      options => options.value === value,
    );
    return selectedOption;
  };

  selectOptions = () => {
    const { optionsSpliced, options } = this.props;
    if (optionsSpliced) {
      return optionsSpliced;
    }
    return options;
  };

  render() {
    const { path, isDisabled } = this.props;

    return (
      <Select
        key='refund_the_policy_holder'
        isDisabled={isDisabled}
        styles={customStyles}
        className={this.state.invalid ? 'is-invalid' : ''}
        value={this.selectOptionValue(this.state.value)}
        options={this.props.options}
        placeholder={this.props.placeHolder}
        onBlur={() => this.validSet(this.state.value)}
        onChange={(value: SelectOptions) => {
          value
            ? this.updateInput(value.value, path, true)
            : this.updateInput(null, path, true);
        }}
      />
    );
  }
}

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '33px',
  }),
  input: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
    marginTop: '-1px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '33px',
    maxWidth: '31px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    height: '31px',
    borderLeft: '1px solid hsl(0,0%,80%)',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#c3c3c3',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    maxHeight: '31px',
    maxWidth: '31px',
    paddingTop: '0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
  }),
};
