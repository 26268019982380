/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='114px'
        height='100px'
        viewBox='0 0 114 100'
        enableBackground='new 0 0 114 100'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <g>
              <line
                fill='none'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                x1='2'
                y1='67.695'
                x2='2'
                y2='67.695'
              />
              <path
                fill='none'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeDasharray='0,7.8521'
                d='
            M2.554,59.863C6.355,33.195,29.289,12.691,57,12.691c29.05,0,52.85,22.533,54.862,51.079'
              />
              <line
                fill='none'
                stroke='#FFFFFF'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                x1='112'
                y1='67.695'
                x2='112'
                y2='67.695'
              />
            </g>
          </g>
          <ellipse fill='#0E131D' cx='57' cy='96.528' rx='34.311' ry='2.187' />
          <g>
            <polygon
              fill='#9EC2E7'
              stroke='#9DC3ED'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              points='32.689,89.762 33.534,92.665 35.91,92.665 36.728,89.762    '
            />
            <polygon
              fill='#9EC2E7'
              stroke='#9DC3ED'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              points='77.272,89.762 78.117,92.665 80.494,92.665 81.311,89.762     '
            />
          </g>
          <polygon
            fill='#9DC3ED'
            stroke='#9EC2E7'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            points='71.522,1.785 42.028,1.785 29.003,88.921 84.997,88.921   '
          />
          <polygon
            fill='#202B40'
            points='78.473,67.986 68.442,5.379 45.124,5.379 35.369,67.986   '
          />
          <circle
            fill='#FFFFFF'
            stroke='#FFFFFF'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            cx='57'
            cy='67.695'
            r='5.772'
          />
          <rect
            x='68.142'
            y='6.537'
            transform='matrix(0.905 0.4253 -0.4253 0.905 24.4042 -25.2671)'
            fill='#FFFFFF'
            stroke='#FFFFFF'
            strokeWidth='0.5'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            width='1.293'
            height='70.966'
          />
          <polygon
            fill='#CAE0FF'
            stroke='#CAE0FF'
            strokeWidth='3'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            points='32.133,67.986 29.003,88.921 84.997,88.921 81.76,67.986  '
          />
          <circle
            fill='#FFFFFF'
            stroke='#202B40'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            cx='80.3'
            cy='17.571'
            r='3.697'
          />
        </g>
      </svg>
    );
  }
}
