export const productModulePolicyMembersUpdatedChanges = `
declare class ProductModulePolicyMembersUpdatedChanges {
  monthly_premium: number;
  module: object;

  constructor(init: ProductModulePolicyMembersUpdatedChanges) {
    Object.assign(this, init);
  };
}
`;

export const policyMembersUpdatedChanges = `
declare class ProductModulePolicyMembersUpdatedChanges {
  monthly_premium: number;
  module: object;

  constructor(init: ProductModulePolicyMembersUpdatedChanges) {
    Object.assign(this, init);
  };
}
`;

