import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { HeadingBlock as Block } from '../domain/heading-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { HeadingBlockState } from '../domain/states/heading-block-state';
import { FormGroup } from 'reactstrap';

interface Props {
  block: Block;
  blockState: HeadingBlockState;
}

interface State {
  UIState: BlockUIState;
  headingBlockState?: BlockState;
}

@observer
export class HeadingBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      headingBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { block } = this.props;
    const { headingBlockState } = this.state;
    if (!headingBlockState) {
      return null;
    }

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <h3>{block.title}</h3>
      </FormGroup>
    );
  }
}
