import { Construct } from '../../shared/domain/construct';

export class Feature {
  readonly featureKey: string;
  readonly description: string;
  readonly sandboxByDefault: boolean;

  constructor(init: Construct<Feature>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkFeature {
    return {
      feature_key: this.featureKey,
      description: this.description,
      sandbox_by_default: this.sandboxByDefault,
    };
  }

  static fromNetwork(init: NetworkFeature) {
    return new Feature({
      featureKey: init.feature_key,
      description: init.description,
      sandboxByDefault: init.sandbox_by_default,
    });
  }
}

export interface NetworkFeature {
  feature_key: string;
  description: string;
  sandbox_by_default: boolean;
}
