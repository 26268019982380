import React from 'react';
import { Card, CardHeader, CardBody, Alert } from 'reactstrap';
import Button from '../../../components/loading-button';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys, parseCSV, getRandValue } from '../../../helpers';
import moment from 'moment';
import PaymentsTable from '../payments-table';
import { FileModal } from '../../../components/file-modal';
import DatePicker from 'react-datepicker';
import { browserHistory } from 'react-router';
import { PaginationComponent } from '../../../components/pagination';

import 'react-datepicker/dist/react-datepicker.css';

export default class DebitOrderPayments extends React.Component {
  constructor(props) {
    super(props);

    const { query } = browserHistory.getCurrentLocation();

    this.state = {
      payments: [],
      loading: true,
      debitOrderType: props.sameDay ? 'debit_order_same_day' : 'debit_order_two_day',
      showFileModal: false,
      paymentDate: query.payment_date ? moment(query.payment_date) : '',
      error: '',
      page: 0,
      pageSize: 50,
    };
  }

  componentDidMount() {
    this.fetchPendingPayments();
  }

  downloadExport = async () => {
    let queryString = `batch_type=debit_order_${this.state.debitOrderType}`;

    const paymentDate = this.state.paymentDate;

    if (paymentDate) {
      queryString = queryString.concat(
        `&payment_date=${paymentDate.format('YYYY-MM-DD')}`,
      );
    }

    const response = await ajax({
      type: 'GET',
      path: `/insurance/admin/payments/batch?${queryString}`,
      raw: true,
    });

    if (response.status === 204) {
      window.alert(
        `No pending ${
          this.state.debitOrderType
        } debit order payments for today.`,
      );
    } else if (response.status !== 200) {
      const body = await response.json();

      console.error(
        'Error downloading export: ',
        body && JSON.stringify(body.error),
      );

      this.setState({
        error: (
          <div>
            Error downloading export:{' '}
            <a href={body.error.more_info}>{body.error.message}</a>
          </div>
        ),
      });
    } else {
      const blob = await response.blob();
      const uri = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const filename = `${moment().format(
        'YYYY_MM_DD',
      )}_${this.state.debitOrderType.toUpperCase()}.csv`;

      element.setAttribute('href', uri);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  submitBatch = async () => {
    if (this.state.payments.length === 0) {
      return window.alert(
        `No pending ${this.state.debitOrderType} debit order payments for ${
          this.state.paymentDate
            ? this.state.paymentDate.format('YYYY-MM-DD')
            : 'today'
        }.`,
      );
    }

    if (
      !window.confirm(
        'Are you sure you want to submit these payments to peach?',
      )
    ) {
      return;
    }

    this.setState({ loading: true });
    let qs = `batch_type=debit_order_${this.state.debitOrderType}`;

    const paymentDate = this.state.paymentDate;

    if (paymentDate) {
      qs = qs.concat(`&payment_date=${paymentDate.format('YYYY-MM-DD')}`);
    }

    try {
      const response = await ajax({
        type: 'POST',
        path: `/insurance/admin/payments/submit?${qs}`,
        raw: true,
      });

      if (response.status === 204) {
        window.alert(
          `No pending ${this.state.debitOrderType} debit order payments for ${
            this.state.paymentDate
              ? this.state.paymentDate.format('YYYY-MM-DD')
              : 'today'
          }.`,
        );
      } else if (response.status > 299) {
        const body = await response.json();

        if (body.error) {
          console.error(
            'Error submitting batch: ',
            body && JSON.stringify(body.error, null, 2),
          );

          this.setState({
            error: (
              <div>
                Error submitting batch:{' '}
                <a href={body.error.more_info}>{body.error.message}</a>
              </div>
            ),
          });
        }

        return;
      }

      this.fetchPendingPayments();
    } catch (e) {
      console.error(e);
      this.setState({ error: e.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchPendingPayments = async () => {
    this.setState({ loading: true });

    const queryString = `collection_type=${
      this.state.debitOrderType
    }&include=payment_method,policy,billing_provider&status=pending`;

    const paymentDate = this.state.paymentDate;

    if (paymentDate) {
      queryString = queryString.concat(
        `&payment_date=${paymentDate.format('YYYY-MM-DD')}`,
      );
    }

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payments?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${
      this.state.page + 1
    }&page_size=${
      this.state.pageSize}`;

    const payments = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payments?${queryString}${pagination}`,
    }))
      .map(toCamelCaseKeys)
      .sort(
        (a, b) =>
          a.createdAt < b.createdAt ? -1 : a.policyId < b.policyId ? -1 : 1, // Ensure fixed ordering
      );

    this.setState({ payments, loading: false, total: total.headers.get('X-Total-Count') });
  };

  async markAsSubmitted(file) {
    const data = await parseCSV(file);
    const payments = data.map(e => ({
      payment_id: e['CustNo'],
      status: 'submitted',
    }));

    await ajax({
      type: 'PATCH',
      path: '/insurance/admin/payments',
      data: payments,
    });

    await this.fetchPendingPayments();
  }

  closeModal() {
    this.setState({ showFileModal: false });
  }

  handlePaymentDateChanged = date => {
    const location = {
      ...browserHistory.getCurrentLocation(),
    };
    location.query = {
      ...location.query,
      payment_date: date ? moment(date).format('YYYY-MM-DD') : undefined,
    };
    browserHistory.push(location);
    this.setState({ paymentDate: moment(date) }, this.fetchPendingPayments);
  };

  handleDateCleared = () => {
    const location = {
      ...browserHistory.getCurrentLocation(),
    };
    location.query = {
      ...location.query,
      payment_date: undefined,
    };
    browserHistory.push(location);
    this.setState({ paymentDate: '' }, this.fetchPendingPayments);
  };

  render() {
    const { debitOrderType, error, loading, paymentDate, payments, page, pageSize, total, showFileModal } = this.state;

    return (
      <div className='bottom-padded'>
        <Card>
          <CardHeader>
            {debitOrderType === 'debit_order_same_day' ? 'Same ' : 'Two '} day
            debit orders ({payments && payments.length}
            {' - '}
            {payments &&
              'R' +
                getRandValue(
                  payments.reduce((a, c) => a + c.amount, 0),
                )}
            ){' '}
            <DatePicker
              placeholderText='Filter by payment date'
              value={
                paymentDate &&
                moment(paymentDate).format('YYYY-MM-DD')
              }
              onChange={this.handlePaymentDateChanged}
            />{' '}
            <Button
              style={{
                display: paymentDate ? 'inline-block' : 'none',
              }}
              onClick={this.handleDateCleared}
            >
              X
            </Button>
            <div className='pull-right'>
              <Button
                color='primary'
                onClick={this.downloadExport}
                disabled={loading}
              >
                Download CSV
              </Button>{' '}
              <Button
                color='warning'
                onClick={() => this.setState({ showFileModal: true })}
                disabled={loading}
              >
                Mark batch file as submitted
              </Button>{' '}
              <Button
                color='danger'
                onClick={this.submitBatch}
                disabled={loading}
              >
                Submit batch to peach
              </Button>{' '}
            </div>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert color='danger'>{error}</Alert>
            )}
            {loading ? (
              <div>Loading...</div>
            ) : payments && payments.length > 0 ? (
              <div>
                <PaymentsTable
                  payments={payments}
                  refresh={this.fetchPendingPayments}
                />
                <PaginationComponent
                  page={page || 0}
                  limit={pageSize}
                  total={total || 0}
                  disabled={false}
                  goToPage={page => {
                    this.setState({
                      page,
                    }, async () => await this.fetchPendingPayments());
                  }}
                />
              </div>
            ) : (
              <div>No pending payments</div>
            )}
          </CardBody>
          <FileModal
            open={showFileModal}
            onClose={this.closeModal.bind(this)}
            handleSubmit={this.markAsSubmitted.bind(this)}
            inputLabel='Select submitted batch file'
            confirmMessage={file =>
              `Are you sure CSV file '${
                file.name
              }' was submitted to Peach Payments?`
            }
            header='Mark batch as submitted'
            description='Upload the CSV batch file submitted to Peach Payments to mark the payments as submitted.'
            submitText='Mark as submitted'
          />
        </Card>
      </div>
    );
  }
}
