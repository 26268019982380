import { UUID } from './uuid';
import { Construct } from './construct';

export class LinkedEntities {
  policyId?: UUID;
  policyholderId?: UUID;
  claimId?: UUID;
  complaintId?: UUID;
  memberId?: UUID;
  paymentId?: UUID;
  beneficiaryId?: UUID;

  constructor(init: Construct<LinkedEntities>) {
    Object.assign(this, init);
  }

  toNetwork() {
    return {
      policy_id: this.policyId && this.policyId.toString(),
      policyholder_id: this.policyholderId && this.policyholderId.toString(),
      claim_id: this.claimId && this.claimId.toString(),
      complaint_id: this.complaintId && this.complaintId.toString(),
      member_id: this.memberId && this.memberId.toString(),
      payment_id: this.paymentId && this.paymentId.toString(),
      beneficiary_id: this.beneficiaryId && this.beneficiaryId.toString(),
    };
  }

  static fromNetwork(json: any) {
    return new LinkedEntities({
      policyId: json.policy_id && UUID.fromString(json.policy_id),
      policyholderId: json.policyholder_id && UUID.fromString(json.policyholder_id),
      claimId: json.claim_id && UUID.fromString(json.claim_id),
      complaintId: json.complaint_id && UUID.fromString(json.complaint_id),
      memberId: json.member_id && UUID.fromString(json.member_id),
      paymentId: json.payment_id && UUID.fromString(json.payment_id),
      beneficiaryId: json.beneficiary_id && UUID.fromString(json.beneficiary_id),
    });
  }
}
