import { Environment } from '../../../../shared/domain/environment';
import { ReportType } from './report-type';
import { EmailRecipient } from '../../outbound-notifications/domain/email-recipient';
import moment, { Moment } from 'moment';
import { Requestee } from '../../../../shared/domain/requestee';
import { Construct } from '../../../../shared/domain/construct';


export class Report {
  readonly reportId: string;
  readonly organizationId: string;
  readonly environment: Environment;
  readonly config: {
    type: ReportType;
    recipients: EmailRecipient[];
  };
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly archivedAt?: Moment;
  readonly archivedBy?: Requestee;

  constructor(init: Construct<Report>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkReport {
    return {
      report_id: this.reportId,
      organization_id: this.organizationId,
      environment: this.environment,
      config: this.config,
      created_at: this.createdAt.toISOString(),
      created_by: this.createdBy.toJSON(),
      archived_at: this.archivedAt ? this.archivedAt.toISOString() : undefined,
      archived_by: this.archivedBy && this.archivedBy.toJSON(),
    };
  }

  static fromNetwork(init: NetworkReport): Report {
    return new Report({
      reportId: init.report_id,
      organizationId: init.organization_id,
      environment: init.environment,
      config: init.config as {
        type: ReportType;
        recipients: EmailRecipient[];
      },
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      archivedAt: init.archived_at ? moment(init.archived_at) : undefined,
      archivedBy: init.archived_by ? Requestee.fromJSON(init.archived_by) : undefined,
    });
  }
}

export class NetworkReport {
  report_id: string;
  organization_id: string;
  environment: Environment;
  config: object;
  created_at: string;
  created_by: object;
  archived_at?: string;
  archived_by?: object;
}
