import React from 'react';
import {
  Col,
  FormGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
} from 'reactstrap';
import '../../../../../../../../../styles/styles.scss';
import { observer } from 'mobx-react';
import _ from 'lodash';
import '../../../styles/general.scss';
import { ObservablesTemp, Store } from '../../../store';
import SelectInputLifecycle from '../../form-components/select-input';
import InputLifecycle from '../../form-components/text-input';
import { CoolingOffPeriodRulesConfig } from '../config';
import { Icon24PXCross } from '../../../../../../../../../../components/icons/icon-24-px-cross';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';

interface Props {
  index: number;
  allowList: boolean;
  reduceCount: () => void;
  removeFromLifeCycleStore: (
    lifecycleObservable: ObservablesTemp,
    index: number,
  ) => void;
  store: Store;
}

interface State {}

@observer
class CoolingOffPeriodRule extends React.Component<Props, State> {
  getValue = (path: string) => {
    const { store, index } = this.props;
    const value = _.get(
      store.lifecycleStoreTemp.coolingOffPeriodRulesTemp[index],
      path,
    );
    return value && value !== undefined ? value : '';
  };

  displaySkippedPayments = (path: string) => {
    const value = this.getValue(path);
    return value && value !== 'period_after_missed_payment';
  };

  displayPeriod = (path: string) => {
    const value = this.getValue(path);
    return (
      value &&
      value !== 'skipped_payments_over_policy_term' &&
      value !== 'consecutive_skipped_payments'
    );
  };

  render() {
    const {
      refundThePolicyHolder,
      applyTo,
      applyCoolingOffPeriodOf,
    } = CoolingOffPeriodRulesConfig;
    return (
      <Card style={{ marginBottom: 20 }}>
        <CardHeader
          className='rule-header'
          style={{ backgroundColor: 'white', paddingBottom: 0 }}
        >
          <Row>
            <Col md={4} sm={12}>
              <div className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'>
                <Icon12ArrowRight />
              </div>
              <h6 className='completed-card-rule-heading'>Rule</h6>
            </Col>
            <Col md={8} sm={12} style={{ paddingTop: 0, marginTop: 0 }}>
              {this.props.allowList && (
                <Button
                  className='remove-rule-button'
                  color='link'
                  onClick={() => {
                    this.props.removeFromLifeCycleStore(
                      ObservablesTemp.coolingOffPeriodRulesTemp,
                      this.props.index,
                    );
                    this.props.reduceCount();
                  }}
                >
                  Remove
                  {
                    <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                      <Icon24PXCross
                        height={'10'}
                        width={'10'}
                        stroke={'#007bff'}
                      />
                    </div>
                  }
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup row style={{ marginBottom: 15 }}>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>
                Apply a cooling-off period of
              </label>
            </Col>
            <Col xs={3} sm={2}>
              <InputLifecycle
                observable={ObservablesTemp.coolingOffPeriodRulesTemp}
                store={this.props.store}
                index={this.props.index}
                path={applyCoolingOffPeriodOf.outputPaths.value}
                placeHolder='20'
              />
            </Col>
            <Col xs={3} sm={4} style={{ paddingLeft: 0 }}>
              <SelectInputLifecycle
                observable={ObservablesTemp.coolingOffPeriodRulesTemp}
                store={this.props.store}
                options={applyCoolingOffPeriodOf.selectOptions.metric}
                index={this.props.index}
                path={applyCoolingOffPeriodOf.outputPaths.type}
                placeHolder={
                  applyCoolingOffPeriodOf.selectOptions.metric[0].label
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row className='row-form-spacing'>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>To</label>
            </Col>
            <Col xs={3} sm={6} style={{ paddingLeft: 16, paddingBottom: 10 }}>
              <SelectInputLifecycle
                observable={ObservablesTemp.coolingOffPeriodRulesTemp}
                store={this.props.store}
                options={applyTo.selectOptions}
                index={this.props.index}
                path={applyTo.outputPaths}
                defaultValue={applyTo.selectOptions[0]}
                placeHolder={applyTo.selectOptions[0].label}
                isDisabled={true}
              />
            </Col>
          </FormGroup>
          <FormGroup row className='row-form-spacing'>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>
                Refund the policyholder for
              </label>
            </Col>
            <Col xs={3} sm={6}>
              <SelectInputLifecycle
                observable={ObservablesTemp.coolingOffPeriodRulesTemp}
                store={this.props.store}
                options={refundThePolicyHolder.selectOptions}
                index={this.props.index}
                path={refundThePolicyHolder.outputPaths}
                placeHolder={'Select'}
              />
            </Col>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

export default CoolingOffPeriodRule;
