import ajax from '../../helpers/ajax';
import { usersMap } from '../../helpers/maps';

export const initAccount = () => {
  return dispatch => {
    // const storage = window.localStorage;
    dispatch({ type: 'ACCOUNT_INIT_START' });
    return Promise.all([
      ajax({ dispatch: dispatch, type: 'GET', path: '/admin/users' }),
    ])
      .then(result => {
        const users = result[0];

        dispatch({ type: 'SET_USERS', users: users.map(usersMap) });

        dispatch({ type: 'ACCOUNT_INIT_SUCCESS' });
      })
      .catch(error => {
        console.log('Init Error:', error);
        // Err, this happened. Let's kick the user // or not
        // storage.clear();
        dispatch({ type: 'ACCOUNT_INIT_FAILED' });
      });
  };
};
