// Template
const user = {
  address: {
    line1: undefined,
    line2: undefined,
    city: undefined,
    areaCode: undefined,
    country: undefined,
  },
  bankNumber: undefined,
  cellphone: undefined,
  companyName: undefined,
  dateOfBirth: undefined,
  email: undefined,
  appTOTP: undefined,
  firstName: undefined,
  id: undefined,
  lastName: undefined,
  profilePictureUrl: undefined,
};

export default (state = user, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.user;
    case 'DISABLED_APP_OTP':
      return { ...state, appTOTP: false };
    case 'ENABLED_APP_OTP':
      return { ...state, appTOTP: true };
    default:
      return state;
  }
};
