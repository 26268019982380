export const complaint = {
  complaint_id: 'd51d8d9e-602a-481d-a5e8-7f458bfee9a9',
  complaint_number: '1234',
  created_at: '2019-01-28T12:06:52.310Z',
  complaint_status: 'Pending',
  complainant: {
    first_name: 'Elrich',
    last_name: 'Gro',
    id_number: '9302129983086',
    cellphone: '022 232 3232',
    email: 'elrich@root.co.za',
  },
  policy_id: '2617a851-8d04-41b5-bce1-1b51b51b5bnm',
  member_id: '17a8519e-1b5c-481d-a5e8-7f458bfee9a9',
  policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  app_data: '{}',
  created_by: {
    type: 'user',
    id: 'c7bc530c-7291-4574-96ed-1018c9cd45c8',
    ownerId: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
};
