/**
 * This file contains templates to use when markdown files are empty on a product module definition.
 */

export const changelogTemplate = `## Change request

**Author:** [insert name]  
**Reviewer:** [insert name]  
**Summary:**  [insert short one-line summary]  

**Level of urgency:** [low|medium|high]  

**Description of changes:**  
[insert description]  

**What was changed?**  
- [item 1]
- [item 2]

#### Author checklist

**Change considerations:**  

[ ] Impact on claims blocks & workflow considered?  
[ ] Impact on reporting templates and jobs considered?  
[ ] Impact on communications templates considered?  
[ ] Client system integrations considered?  

**Stakeholders notified:**  

[ ] Platform operations team (reporting)  
[ ] Delivery team  
[ ] Client  

#### Reviewer checklist  

[ ] The change request description adequately describes what this change does and what it is for.  
[ ] Impact on claims blocks & workflow considered & reviewed?  
[ ] Impact on reporting templates and jobs considered & reviewed?  
[ ] Impact on communications templates considered & reviewed?  

I, as the reviewer, hereby mark this change request [approved | declined].
`;


export const readmeTemplate = `## Getting started
This product module does not yet have a README file.

You can add a README file to a product module to communicate important information about the project.

Use a readme to describe aspects around the product module such as:  
- What the product is  
- Who the client, underwriting insurer and IP owner are  
- Links to any related files on Google Drive  
- Who's maintains and contributes to the project  
- What are the outstanding tasks still to complete in this module  
`;
