import React from 'react';
import { Card, CardHeader, CardBody, Alert, Table } from 'reactstrap';

export default class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // const url = process.env.REACT_APP_COLLECT_HOST + '/organizations';
    // const organizations = await get(url);
    // TODO do proper fetching of layoutTemplateSet and templateSets

    const organization = {
      organizationId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
      userId: '66a48342-9d30-11e7-9eda-0315c970dabe',
      productName: 'Hero Life',
      productDescription:
        'Hero Financial Services - Taking back control of your finances.',
      productIcon:
        'https://s3-eu-west-1.amazonaws.com/rootbank-production/client-app-icons/hero-0044cb2a-a9bf-11e7-b01a-af0365aad91b/client-app-icon-Thursday_2017_10_19__12-16-51-505-jpeg',
      productWebsite: 'https://herolife.co.za',
      reviewedAt: '2017-12-14 11:19:06.64387',
      archivedAt: null,
      createdAt: '2017-10-05 11:19:06.64387',
      productContactNumber: null,
    };

    const layoutTemplateSet = {
      templateKey: 'message_layout',
      templateName: 'Message layout',
      templates: {
        email: {
          draft: {
            templateId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
            templateKey: 'message_layout',
            templateName: 'Message layout',
            templateType: 'email',
            thumbnailUrl:
              'http://www.childcaremagazine.us/list/25203/Stripo-Fashion-Holiday-newsletter-Happy-Birthday-Special-Offer-email-web.png',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: null,
          },
          live: {
            templateId: '0044cb2a-11e7-a9bf-b01a-af0365aad91b',
            templateKey: 'message_layout',
            templateName: 'Message layout',
            templateType: 'email',
            thumbnailUrl:
              'https://cms-assets.tutsplus.com/uploads/users/30/posts/9386/final_image/email-buil-final.png',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: '2018-09-06 11:19:06.64387',
          },
        },
        sms: {
          draft: {
            templateId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
            templateKey: 'message_layout',
            templateName: 'Message layout',
            templateType: 'sms',
            thumbnailUrl:
              'http://mediad.publicbroadcasting.net/p/shared/npr/styles/x_large/nprshared/201805/459499094.jpg',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: null,
          },
          live: null,
        },
      },
    };
    const templateSets = new Array(20)
      .fill(0)
      .map(a => ({ ...layoutTemplateSet, templateKey: Math.random() }));

    this.setState({ organization, layoutTemplateSet, templateSets });
  }

  renderTemplateSetRow(templateSet) {
    return (
      <tr
        key={templateSet.templateKey}
        onClick={() =>
          this.props.router.push(
            `/organizations/${this.props.params.organizationId}/templates/${
              templateSet.templateKey
            }`,
          )
        }
      >
        <td>
          {templateSet.templateName}
          <p>
            <code>{templateSet.templateKey}</code>
          </p>
        </td>

        {/* Emails */}
        <td>
          <div className='template-thumbnail-container'>
            {!templateSet.templates.email.live ? (
              <img src='/images/template-disabled.png' alt='Live template' />
            ) : (
              <img
                src={templateSet.templates.email.live.thumbnailUrl}
                alt='Live template'
              />
            )}
            Live
          </div>
          <div className='template-thumbnail-container'>
            <img
              src={templateSet.templates.email.draft.thumbnailUrl}
              alt='Draft template'
            />
            Draft
          </div>
        </td>

        {/* SMS */}
        <td>
          <div className='template-thumbnail-container'>
            {!templateSet.templates.sms.live ? (
              <img src='/images/template-disabled.png' alt='Live template' />
            ) : (
              <img
                src={templateSet.templates.sms.live.thumbnailUrl}
                alt='Live template'
              />
            )}
            Live
          </div>
          <div className='template-thumbnail-container'>
            <img
              src={templateSet.templates.sms.draft.thumbnailUrl}
              alt='Draft template'
            />
            Draft
          </div>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        <Alert color='danger'>⚠️ This page is still under construction!</Alert>

        <Card>
          <CardHeader>Layouts</CardHeader>
          <CardBody>
            <Table hover borderless>
              <tbody>
                {this.state.layoutTemplateSet ? (
                  this.renderTemplateSetRow(this.state.layoutTemplateSet)
                ) : (
                  <tr>
                    <td>Loading template set...</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>Templates</CardHeader>
          <CardBody>
            <Table hover borderless>
              <tbody>
                {this.state.templateSets ? (
                  this.state.templateSets.map(
                    this.renderTemplateSetRow.bind(this),
                  )
                ) : (
                  <tr>
                    <td>Loading templates sets...</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}
