import { observable, action } from 'mobx';
import { ajax } from '../../../../helpers';
import { RequestTypes } from '../product-module-javascript';

interface ApiRequest {
  type: RequestTypes;
  path: string;
  label: string;
}

const baseUrl = 'insurance';

export class PolicyIssuingFlowStore {
  @observable.deep apiData: { [k: string]: any } = {};
  @observable organizationProductModules: any[] = [];
  @observable organizationId: string = '';
  @observable organizationName: string = 'No org selected';
  @observable requestStatus: string = '';
  @observable endpointListDropdown: any = [];
  @observable selectedEndpoint: ApiRequest = {
    type: RequestTypes.get,
    path: '',
    label: '',
  };

  @action getOrgProductModules = async (organizationId: string) => {
    return ajax({
      path: `insurance/admin/organizations/${organizationId}/product-modules`,
      type: 'GET',
    });
  };

  @action setOrganization = (params: {
    organizationId: string;
    organizationName: string;
    productModuleId?: string;
    productModuleDefinitionId?: string;
  }) => {
    const { organizationId, organizationName } = params;
    this.organizationId = organizationId;
    this.organizationName = organizationName;
    this.updateRequestSimulatorDropdown(params);
  };

  @action apiRequest = async (data: any) => {
    let requestData = {
      type: this.selectedEndpoint.type,
      path: this.selectedEndpoint.path,
    };
    requestData = data && this.selectedEndpoint.type === RequestTypes.post ? { ...requestData, ...{ data } } : requestData;

    try {
      this.apiData = await ajax({ ...requestData, sandbox: true });
      this.requestStatus = 'Success';
    } catch (err) {
      try {
        this.apiData = JSON.parse(err.message);
        this.requestStatus = 'Error';
      } catch (err) {
        console.log(' err.Error', err.message);
      }
    }
  };

  @action updateRequestSimulatorDropdown = (params: {
    organizationId?: string;
    productModuleId?: string;
    productModuleDefinitionId?: string;
  }) => {
    const { productModuleDefinitionId } = params;
    const endPointOptions = [
      {
        label: 'POST /v1/insurance/quotes',
        value: {
          path: `/apps/${this.organizationId}/${baseUrl}/quotes?version=${productModuleDefinitionId}`,
          type: RequestTypes.post,
        },
      },
      {
        label: 'POST /v1/insurance/applications',
        value: {
          path: `/apps/${this.organizationId}/${baseUrl}/applications`,
          type: RequestTypes.post,
        },
      },
      {
        label: 'POST /v1/insurance/policies',
        value: {
          path: `/apps/${this.organizationId}/${baseUrl}/policies`,
          type: RequestTypes.post,
        },
      },
      {
        label: 'POST /v1/insurance/policyholder (indervidual)',
        value: {
          path: `/apps/${this.organizationId}/${baseUrl}/policyholders`,
          type: RequestTypes.post,
        },
      },
      {
        label: 'GET /v1/insurance/policyholder (indervidual)',
        value: {
          path: `/apps/${this.organizationId}/${baseUrl}/policyholders?page_size=10&page=1`,
          type: RequestTypes.get,
        },
      },
    ];

    this.endpointListDropdown = endPointOptions;
    this.selectedEndpoint =  { ...endPointOptions[0].value, label: endPointOptions[0].label };
  };

  @action updateSelectedEndpoint = (value: ApiRequest) => {
    this.selectedEndpoint = value;
  };
}

export const policyIssuingFlowStore = new PolicyIssuingFlowStore();
