import React, { useEffect, useState } from 'react';
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col, Alert,
} from 'reactstrap';
import { addUserToOrganizationRole, searchUsers } from './team-members-api';
import { RolesList } from '../roles/components/roles-list';
import { useRolesList } from '../roles/hooks/use-roles-list';

export const AddUserModal = ({ show, onClose, organizationId, organizationOwnerId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [error, setError] = useState('');

  const {roles, error: rolesListError} = useRolesList(organizationId);

  useEffect(()=> {
    setError(rolesListError);
  }, [rolesListError]);

  useEffect(() => {
    const load = async () => {
      try {
        const users = await searchUsers({searchTerm});
        setUsers(users.filter(user => user.user_id !== organizationOwnerId));
        setError('');
      } catch (error) {
        setError('Error searching users');
      }
    };

    searchTerm && load();
  }, [searchTerm]);

  const searchHandler = e => setSearchTerm(e.currentTarget.value);

  const roleChangeHandler = value => setSelectedRoleId(value);

  const userSelectHandler = e => setSelectedUserId(e.currentTarget.getAttribute('data-user-id'));

  const submitHandler = async () => {
    try {
      await addUserToOrganizationRole({
        organizationId,
        userId: selectedUserId,
        roleId: selectedRoleId,
      });
      closeHandler();
    } catch(error) {
      setError(JSON.parse(error.message).message);
    }
  };

  const closeHandler = () => {
    setSearchTerm('');
    setUsers([]);
    setSelectedUserId('');
    setSelectedRoleId('');
    setError('');
    onClose();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={closeHandler}>
        <ModalHeader toggle={closeHandler}>
        Add a team member
        </ModalHeader>
        <ModalBody>
          {error && <Alert color='danger'>{error}</Alert>}
          <Row>
            <Col xs={12}>
              <h6 style={{marginTop: 0}} className='dialog'>Search for user by name</h6>
            </Col>
          </Row>
          <Input
            type='text'
            placeholder='Search for a user by name'
            value={searchTerm}
            onChange={searchHandler}
          />

          <ListGroup
            style={{ display: 'block', maxHeight: 300, overflowY: 'scroll' }}
          >
            {users.map(user => (
              <ListGroupItem
                style={{ height: 50 }}
                key={user.user_id}
                active={selectedUserId === user.user_id}
                data-user-id={user.user_id}
                tag='button'
                action
                onClick={userSelectHandler}
              >
                {user.first_name} {user.last_name}
              </ListGroupItem>
            ))}
          </ListGroup>
          <Row>
            <Col xs={12}>
              <h6 className='dialog'>Assign role</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ height: '500px', overflowY: 'scroll' }}>
              <RolesList roles={roles} onChange={roleChangeHandler} selectedRoleId={selectedRoleId} />
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button outline onClick={closeHandler}>Cancel</Button>
          <Button
            color='primary'
            onClick={submitHandler}
            disabled={!selectedUserId || !selectedRoleId}
          >
          Add member
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
