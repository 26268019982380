import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { AlertBlock as Block } from '../domain/alert-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { AlertBlockState } from '../domain/states/alert-block-state';
import Alert from 'reactstrap/lib/Alert';
import { FormGroup } from 'reactstrap';

interface Props {
  block: Block;
  blockState: AlertBlockState;
}

interface State {
  UIState: BlockUIState;
  alertBlockState?: BlockState;
}

@observer
export class AlertBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      alertBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { block } = this.props;
    const { alertBlockState } = this.state;
    if (!alertBlockState) {
      return null;
    }

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Alert color={block.color}>
          {block.markdown}
        </Alert>
      </FormGroup>
    );
  }
}
