/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='30px'
        height='30px'
        viewBox='0 0 30 30'
        enableBackground='new 0 0 30 30'
        xmlSpace='preserve'
      >
        <circle fill='#538C27' cx='15' cy='15' r='15' />
        <g>
          <g>
            <path
              fill='#FFFFFF'
              d='M14.008,21.292h-3.63c-3.469,0-6.292-2.822-6.292-6.292s2.822-6.292,6.292-6.292h3.63v2h-3.63
                c-2.366,0-4.292,1.926-4.292,4.292s1.925,4.292,4.292,4.292h3.63V21.292z'
            />
          </g>
          <g>
            <path
              fill='#FFFFFF'
              d='M19.623,21.292h-3.62v-2h3.62c2.366,0,4.291-1.926,4.291-4.292s-1.925-4.292-4.291-4.292h-3.62v-2h3.62
                c3.469,0,6.291,2.822,6.291,6.292S23.092,21.292,19.623,21.292z'
            />
          </g>
          <g>
            <rect x='10.547' y='14' fill='#FFFFFF' width='8.917' height='2' />
          </g>
        </g>
      </svg>
    );
  }
}
