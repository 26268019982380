import moment from 'moment';

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_APPLICATIONS':
      return action.applications;
    case 'APPROVED_APPLICATION':
      return state.filter(a => a.applicationId !== action.applicationId);
    case 'APPROVED_DOCUMENT':
      return state.map(a => {
        if (a.applicationId === action.applicationId) {
          const docs = a.documents.map(d => {
            if (d.id === action.documentId) {
              d.state = 'approved';
            }
            return d;
          });
          a.documents = docs;
        }
        return a;
      });
    case 'REJECTED_DOCUMENT':
      return state.map(a => {
        if (a.applicationId === action.applicationId) {
          const docs = a.documents.map(d => {
            if (d.id === action.documentId) {
              d.state = 'declined';
            }
            return d;
          });
          a.documents = docs;
        }
        return a;
      });
    case 'APPROVED_DETAILS':
      return state.map(a => {
        if (a.applicationId === action.applicationId) {
          a.homeAffairs.matchedAt = moment();
        }
        return a;
      });
    case 'FETCHED_APPLICATION_IDENTITY':
      return state.map(a => {
        if (a.applicationId === action.applicationId) {
          a.homeAffairs = {
            firstName: action.homeAffairs.name,
            lastName: action.homeAffairs.surname,
            birthDate: action.homeAffairs.birthDate,
            deceased: action.homeAffairs.deceased,
            emigrated: action.homeAffairs.emigrated,
          };
          return JSON.parse(JSON.stringify(a));
        }
        return a;
      });
    default:
      return state;
  }
};
