/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='240px'
        height='140px'
        viewBox='0 0 240 140'
        enableBackground='new 0 0 240 140'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <path
              fill='#0E131D'
              d='M215.769-0.143H24.229c-2.606,0-4.726,2.279-4.726,5.089v129.73c0,2.811,2.119,5.092,4.726,5.092h191.54
          c2.608,0,4.729-2.281,4.729-5.092V4.946C220.497,2.136,218.377-0.143,215.769-0.143z'
            />
            <g opacity='0.5'>
              <path
                fill='#FFFFFF'
                d='M39.853,117.999h-4.686v-7.563h4.686v0.85H36.11v2.425h3.549v0.839H36.11v2.6h3.742V117.999z'
              />
              <path
                fill='#FFFFFF'
                d='M43.111,117.999h-0.943v-7.563h2.835c1.458,0,2.41,0.891,2.41,2.253c0,1.055-0.57,1.872-1.519,2.171
            l1.749,3.14h-1.105l-1.619-2.993h-1.808V117.999z M43.111,114.172h1.808c0.97,0,1.521-0.524,1.521-1.451
            c0-0.906-0.587-1.446-1.562-1.446h-1.766V114.172z'
              />
              <path
                fill='#FFFFFF'
                d='M54.263,117.999h-4.591v-7.563h0.942v6.713h3.648V117.999z'
              />
              <path
                fill='#FFFFFF'
                d='M56.374,117.999v-7.563h0.943v7.563H56.374z'
              />
              <path
                fill='#FFFFFF'
                d='M59.563,114.214c0-2.374,1.336-3.904,3.407-3.904c1.646,0,2.897,1.002,3.14,2.511h-0.955
            c-0.24-0.991-1.104-1.641-2.185-1.641c-1.478,0-2.437,1.195-2.437,3.034c0,1.856,0.948,3.039,2.44,3.039
            c1.111,0,1.908-0.532,2.181-1.455h0.955c-0.347,1.493-1.467,2.326-3.14,2.326C60.885,118.124,59.563,116.609,59.563,114.214z'
              />
              <path
                fill='#FFFFFF'
                d='M73.39,117.999v-3.444h-4.151v3.444h-0.942v-7.563h0.942v3.271h4.151v-3.271h0.943v7.563H73.39z'
              />
              <path
                fill='#FFFFFF'
                d='M80.147,117.999v-7.563h2.866c1.296,0,2.172,0.755,2.172,1.887c0,0.786-0.578,1.52-1.306,1.651v0.042
            c1.026,0.131,1.687,0.854,1.687,1.86c0,1.331-0.953,2.123-2.562,2.123H80.147z M81.092,113.695h1.415
            c1.115,0,1.723-0.446,1.723-1.264c0-0.749-0.512-1.162-1.43-1.162h-1.708V113.695z M82.863,117.165
            c1.136,0,1.734-0.462,1.734-1.337s-0.619-1.32-1.823-1.32h-1.683v2.657H82.863z'
              />
              <path
                fill='#FFFFFF'
                d='M91.865,115.834h-3.01l-0.759,2.165h-0.99l2.788-7.563h0.933l2.787,7.563h-0.989L91.865,115.834z
             M89.135,115.031h2.452l-1.21-3.447h-0.032L89.135,115.031z'
              />
              <path
                fill='#FFFFFF'
                d='M95.051,114.214c0-2.374,1.336-3.904,3.406-3.904c1.646,0,2.898,1.002,3.141,2.511h-0.955
            c-0.24-0.991-1.105-1.641-2.186-1.641c-1.478,0-2.437,1.195-2.437,3.034c0,1.856,0.948,3.039,2.441,3.039
            c1.111,0,1.907-0.532,2.181-1.455h0.955c-0.348,1.493-1.468,2.326-3.141,2.326C96.372,118.124,95.051,116.609,95.051,114.214z'
              />
              <path
                fill='#FFFFFF'
                d='M108.875,117.999v-3.444h-4.15v3.444h-0.943v-7.563h0.943v3.271h4.15v-3.271h0.943v7.563H108.875z'
              />
              <path
                fill='#FFFFFF'
                d='M118.932,117.999v-5.828h-0.037l-2.411,5.774h-0.796l-2.411-5.774h-0.036v5.828h-0.881v-7.563h1.096
            l2.609,6.31h0.042l2.611-6.31h1.095v7.563H118.932z'
              />
              <path
                fill='#FFFFFF'
                d='M126.509,115.834h-3.01l-0.759,2.165h-0.99l2.789-7.563h0.931l2.789,7.563h-0.99L126.509,115.834z
             M123.777,115.031h2.453l-1.211-3.447h-0.032L123.777,115.031z'
              />
              <path
                fill='#FFFFFF'
                d='M130.197,117.999v-7.563h0.916l4.142,5.938h0.047v-5.938h0.934v7.563h-0.918l-4.141-5.918h-0.047v5.918
            H130.197z'
              />
            </g>
            <g>
              <image
                overflow='visible'
                width='203'
                height='41'
                xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMsAAAApCAYAAACbd926AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
    GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACMVJREFUeNrsXYtu47oOpGT3/P/3
    bizxYoEG4CX4GMp2mmJjwEjSpsloxOFLskv0OT7H5/gcn+NzXHm0X/a51sG/FDv/45hfiZ3fAWh7
    UyfAvxR3hP03Yn5n7PwKY29vTAbfKPh2Iy5+wby9i7duN7+/yjXfAaQFr9sPTiQXiGjF13dO3Oqk
    tR8UDhdetwuxt5Oi5eLzy7xqE4/tBrG0xQnj5PfeRLUXGmI2UVycgysxtqI4OBlTW8DebuYafVwW
    S3NE0hLh3OnxNBHsPM/G04IxXjWBiLgj3BpLxnO7kW8UMyW8IrbSbubaGwOvisUTiD67Y4RXT2Am
    koiEaCzkvD6LP5o8TnDTopO6KrJwEbfHNcL5GdEzIHB5zsI4/u/YF4XSxSnF0hKju9PgpkHG833Z
    WJDJPJMieuKewQRac1DBemVUeeKZidFZ9tMBZ3s2K+EAs+a5fz/Kn+m/bZZgdsDbeCL5e26JcK4W
    S0SC9ZydsXQ1Ho27FwWD1lFa3NPAboklw3sl34hTmo7BRbg1fmsMdMI5eVxPA/f4/uypnEB47IWO
    ixbIps5uCKc6gQzmy9q4hjGJ0xFLJPgGiL4teLbM4IaD28PsOagr0xkrolh4x/f3s4G7AVyvih5N
    EzXPT6EMFUlaFl32QlSRg9u/z008bk6kQTwFKpLIUwwxiUMMloOJ25T4PcE3IM1hwCN7uLsyQMlH
    hhnx0KvRxBPKUIY3HbFYzvUKrlG+tbgP5VhKHbEdjCo6sjzF8iWe74ZgKoNHvEU0cYcwuqfnsMQi
    DU4LvoOGiKYtM/FwQ03gUB7N4n0LuO4ncv/MOw8DdxNimQBuj+uVjASpATXmv5//UH/TxeMQ33mq
    ZrEM7UucnmA6MPhqSJUR5FATZxH6/N2mxL4HUbIDUdKqpzJxa9zdMTxSmDeD5x10Tiup43SEovmO
    RH4l15TwHUVuiZmMiPl8bNXIEvX3u2FwfyfwPyUaSYLl8c4UldrgeuD1pxCLh/1LvbaM0EtzolRR
    G9t0ook2hKG+w8P7BURzInz1O+P8MNIYNj7DckxfzqPHdeRgo+6clXZJzPK9m+qOSd7YcohZge/V
    LTqy/Cce98DYEC+HFGjDiCbyvd1oHfdEMF6E3ECxZAW8JRQp6qkiITnRUON9/i4SdsVBWSI/1Gcj
    6yw9wZ1x3ROxRHifPG+K6ymEUi4TdsKOqEDek5QMjS5Ze9USCilPMQS+qYyuLwjGS3M83FkxfDji
    3pxWbE+iuYyGmbBR52QJpSmD21UnrAdNCUQ0WUpJSQS3+Nb1yTTssVfquh0UChmG5xX9Osyi3i4j
    ojuRZADrIxF2r2mBGKFX0A+BuRupixTJAe4qsNr1Wtj9BN9sROeh3i+dUktqJK9Nj9a71vxlWYfH
    tZfilY6d1o8oRetOixOdPJlLyuJxU3lxFkYZjJYtSNWqYpmqj0+i22JNVlvkuSfrXWgHT+PXDqnS
    qeLg+xDsO8C1187WXFsO9NSxByQ2sJ+N1DwUpAZWMdWNiWvAZEf7rDhYLERqNYT4bBtHhGkCGFex
    RobtCYUM4XCCMeKWC07Lwy/bvROsOSo2EjrbLLJUtmtIlW/KyzLh10J4e6YmcFor4WwUeNHJRvoX
    GVTWMo5y6xnsPmgAF9a+J8QIshZ9hXOLb+nwPF65gJ8B46/aSlk8e0JmA7s8hwilspgnp/uArFVE
    RbI85c88wUixDOczt6AVzcU0THZkLMwavz51Jy/CqqPd5gg7W6uwFvIQzN72IrkzQeL22udW6hd1
    Hs9wLe0EdS6mWNjpnugW3XPwD2fSZJSReWrWPuZklf74/s6HeB5NpBT9c1IO8vdZkdOKjorDqOU6
    FM4/Ar8chxZLM4zuSJoGaEcsKu41do9ni3OdiXSx6NoDbCxq0i2puVCun+cfYwwDiDTlAj/b9uD1
    38dChybzHNprWAZneRBShhfVFvJ7v5wxZmmYt1Kvcf9x8E9lbAjeUVjNj1bsPQf1cHB7IucENzk2
    FXXDvFLA49oTjGUjUI1VScMkmUfQyp3JwiS6v2oGq8iH8hR68g5j24i3K9aLZofq1LSCWCKRR0If
    SiwcFNsW1+hKPi9gf4C40U2Q0pbQNS0qcv0IcA/yL+eg1chCKmI8B/EwBjDE4Ff3iFntwZHkpo/A
    Y5DTifK81FHchZAtSs4gn34E0RDpng1gt0GldZylv146NhXulvCNct3AbMfCOwyH6tnIDGyk1Dom
    9YEjCKeZUFCvg6zia1KGUeRzMHkcpAI7+buPkYgYNSiG0ZzQxbKXKrFTN1b3h6HbizwHNYJUhowV
    fcuwva0uHcBe2Vp0OM7Kw05nahbPK1hRZVN7cqqb+1b2WukOx1BGK1OYzJMelF8vsiJyq118BB0l
    BvFuRuFc3ea+yvcg/xocFvUWwvVK+kgUXwToOVavVklTMbRmIbKvVdZbN6IrD6tXwCEb5qJ1iqhG
    IWc9ZdX4KlfqWbunpyFwSlr3/aRQzvA9Aq7JEfpMbKWaPhLF2/MtjhFbWVpnoUAcejVVLioddP56
    /Mz4OFlQjLaOR8Y3aO0a92zxlpMFuhnsKPA8c+XeB62wIFzhO8LdEvH1k1xnAs8WJ+H1lUoaJtco
    ZrAFIbr7SPW2QlycRGQl1hJMU0I/c6eaFcwTxBzhvYrrM9ipgL29Adce9tM32SPK7wPl5Zpn79hh
    EXJmv4+FLzO6M1ERxepFQkrwEp2/3xkSZSgxtOxeZ/SGXJeEUgFlTUIkjKtuVMcgMQR2NNANnmfu
    Y8Wg0CnwzEzxnSjvuoXuKnZrTYjenOvSfY5XSG1JtLlq0laEUxl4u3kMlfuIIbivwNmKHHGR95+0
    Fz7B9S130c9IQF/ThcaHvkawoZNWvW4Gubk2AR76VVyfxezheDeuX/L/WZC/f9VNwWmRgFXcV/7r
    g+rf/gTXZzH/Vq5vJ/on/sEO/6O4f+qfGfE/aiMvBd/eYbA/wA3/Qtz84fpzfI7P8Tk+x+d4xfE/
    AQYAEkyrj66UCiUAAAAASUVORK5CYII='
                transform='matrix(1 0 0 1 18 49.3418)'
              />
              <g>
                <g>
                  <path
                    fill='#FFFFFF'
                    d='M36.455,66.545h-0.041l-2.546,1.789v-1.658l2.573-1.809h1.739v9.846h-1.726V66.545z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M44.15,69.002h1.188c1.01,0,1.699-0.621,1.699-1.502c0-0.859-0.601-1.433-1.706-1.433
                c-1.017,0-1.699,0.594-1.769,1.515h-1.637c0.095-1.767,1.453-2.914,3.487-2.914c1.938,0,3.316,1.059,3.316,2.594
                c0,1.221-0.818,2.102-2.033,2.313v0.041c1.474,0.109,2.43,1.01,2.43,2.382c0,1.74-1.583,2.913-3.733,2.913
                c-2.164,0-3.569-1.187-3.658-2.941h1.68c0.081,0.901,0.818,1.503,1.993,1.503c1.131,0,1.931-0.637,1.931-1.543
                c0-0.996-0.752-1.604-1.974-1.604H44.15V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M54.153,69.002h1.188c1.01,0,1.699-0.621,1.699-1.502c0-0.859-0.601-1.433-1.706-1.433
                c-1.017,0-1.699,0.594-1.767,1.515h-1.639c0.096-1.767,1.454-2.914,3.486-2.914c1.938,0,3.317,1.059,3.317,2.594
                c0,1.221-0.818,2.102-2.033,2.313v0.041c1.475,0.109,2.43,1.01,2.43,2.382c0,1.74-1.583,2.913-3.732,2.913
                c-2.165,0-3.57-1.187-3.658-2.941h1.679c0.082,0.901,0.819,1.503,1.993,1.503c1.132,0,1.931-0.637,1.931-1.543
                c0-0.996-0.751-1.604-1.974-1.604h-1.214V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M66.749,66.34v-0.033H61.63v-1.439h6.853v1.465l-4.211,8.381h-1.843L66.749,66.34z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M82.037,66.545h-0.041l-2.546,1.789v-1.658l2.573-1.809h1.74v9.846h-1.727V66.545z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M89.732,69.002h1.188c1.01,0,1.699-0.621,1.699-1.502c0-0.859-0.602-1.433-1.706-1.433
                c-1.018,0-1.699,0.594-1.769,1.515h-1.638c0.097-1.767,1.454-2.914,3.487-2.914c1.938,0,3.317,1.059,3.317,2.594
                c0,1.221-0.819,2.102-2.034,2.313v0.041c1.475,0.109,2.43,1.01,2.43,2.382c0,1.74-1.583,2.913-3.732,2.913
                c-2.164,0-3.569-1.187-3.658-2.941h1.68c0.081,0.901,0.818,1.503,1.992,1.503c1.133,0,1.932-0.637,1.932-1.543
                c0-0.996-0.751-1.604-1.974-1.604h-1.214V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M99.735,69.002h1.188c1.01,0,1.699-0.621,1.699-1.502c0-0.859-0.601-1.433-1.706-1.433
                c-1.017,0-1.699,0.594-1.767,1.515h-1.639c0.096-1.767,1.453-2.914,3.487-2.914c1.938,0,3.316,1.059,3.316,2.594
                c0,1.221-0.818,2.102-2.033,2.313v0.041c1.474,0.109,2.43,1.01,2.43,2.382c0,1.74-1.583,2.913-3.733,2.913
                c-2.164,0-3.569-1.187-3.658-2.941h1.68c0.081,0.901,0.818,1.503,1.993,1.503c1.131,0,1.93-0.637,1.93-1.543
                c0-0.996-0.75-1.604-1.972-1.604h-1.215V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M112.331,66.34v-0.033h-5.119v-1.439h6.852v1.465l-4.21,8.381h-1.843L112.331,66.34z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M127.619,66.545h-0.041l-2.547,1.789v-1.658l2.573-1.809h1.739v9.846h-1.725V66.545z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M135.312,69.002h1.188c1.011,0,1.7-0.621,1.7-1.502c0-0.859-0.601-1.433-1.708-1.433
                c-1.015,0-1.697,0.594-1.767,1.515h-1.638c0.095-1.767,1.453-2.914,3.486-2.914c1.938,0,3.316,1.059,3.316,2.594
                c0,1.221-0.816,2.102-2.033,2.313v0.041c1.475,0.109,2.43,1.01,2.43,2.382c0,1.74-1.582,2.913-3.731,2.913
                c-2.164,0-3.571-1.187-3.659-2.941h1.68c0.081,0.901,0.82,1.503,1.993,1.503c1.132,0,1.932-0.637,1.932-1.543
                c0-0.996-0.753-1.604-1.974-1.604h-1.216V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M145.316,69.002h1.188c1.009,0,1.698-0.621,1.698-1.502c0-0.859-0.601-1.433-1.705-1.433
                c-1.018,0-1.7,0.594-1.768,1.515h-1.638c0.096-1.767,1.453-2.914,3.486-2.914c1.938,0,3.318,1.059,3.318,2.594
                c0,1.221-0.821,2.102-2.034,2.313v0.041c1.475,0.109,2.43,1.01,2.43,2.382c0,1.74-1.584,2.913-3.734,2.913
                c-2.164,0-3.568-1.187-3.656-2.941h1.679c0.082,0.901,0.818,1.503,1.991,1.503c1.132,0,1.932-0.637,1.932-1.543
                c0-0.996-0.75-1.604-1.973-1.604h-1.215V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M157.911,66.34v-0.033h-5.117v-1.439h6.851v1.465l-4.209,8.381h-1.842L157.911,66.34z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M173.201,66.545h-0.042l-2.546,1.789v-1.658l2.574-1.809h1.74v9.846h-1.727V66.545z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M180.896,69.002h1.188c1.009,0,1.698-0.621,1.698-1.502c0-0.859-0.602-1.433-1.705-1.433
                c-1.019,0-1.7,0.594-1.769,1.515h-1.637c0.096-1.767,1.453-2.914,3.486-2.914c1.938,0,3.317,1.059,3.317,2.594
                c0,1.221-0.82,2.102-2.033,2.313v0.041c1.475,0.109,2.43,1.01,2.43,2.382c0,1.74-1.584,2.913-3.734,2.913
                c-2.165,0-3.568-1.187-3.657-2.941h1.679c0.082,0.901,0.818,1.503,1.992,1.503c1.132,0,1.931-0.637,1.931-1.543
                c0-0.996-0.749-1.604-1.972-1.604h-1.215V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M190.898,69.002h1.188c1.009,0,1.698-0.621,1.698-1.502c0-0.859-0.602-1.433-1.705-1.433
                c-1.019,0-1.701,0.594-1.769,1.515h-1.638c0.097-1.767,1.454-2.914,3.487-2.914c1.938,0,3.317,1.059,3.317,2.594
                c0,1.221-0.82,2.102-2.033,2.313v0.041c1.474,0.109,2.43,1.01,2.43,2.382c0,1.74-1.584,2.913-3.734,2.913
                c-2.164,0-3.568-1.187-3.657-2.941h1.68c0.081,0.901,0.817,1.503,1.992,1.503c1.131,0,1.93-0.637,1.93-1.543
                c0-0.996-0.749-1.604-1.973-1.604h-1.214V69.002z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M203.493,66.34v-0.033h-5.117v-1.439h6.851v1.465l-4.209,8.381h-1.843L203.493,66.34z'
                  />
                </g>
              </g>
            </g>
            <g opacity='0.6'>
              <circle fill='#EB001B' cx='180.147' cy='113.865' r='11.661' />
              <circle fill='#F79E1B' cx='194.812' cy='113.865' r='11.661' />
              <path
                fill='#FF5F00'
                d='M183.151,113.865c0,3.661,1.69,6.923,4.33,9.062c2.638-2.139,4.327-5.4,4.327-9.062
            s-1.689-6.924-4.327-9.062C184.842,106.941,183.151,110.204,183.151,113.865z'
              />
            </g>
            <g opacity='0.6'>
              <g>
                <g>
                  <path
                    fill='#FFFFFF'
                    d='M69.61,23.874c-0.216-0.139-0.427-0.228-0.626-0.266c-0.763-0.148-1.376-0.009-1.852,0.294
                c-0.173,0.11-0.328,0.241-0.465,0.394v-0.342c0-0.127-0.102-0.23-0.225-0.23h-1.28c-0.124,0-0.225,0.103-0.225,0.23v6.929
                c0,0.127,0.101,0.232,0.225,0.232h1.341c0.123,0,0.226-0.105,0.226-0.232v-3.62c0-0.284,0.031-0.556,0.092-0.812
                c0.06-0.247,0.151-0.468,0.274-0.658c0.119-0.184,0.265-0.325,0.443-0.432c0.361-0.213,0.861-0.213,1.434,0.054
                c0.056,0.026,0.12,0.027,0.178,0.003c0.057-0.024,0.101-0.072,0.124-0.131l0.427-1.134
                C69.74,24.048,69.701,23.932,69.61,23.874z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M77.535,24.605c-0.341-0.338-0.757-0.6-1.236-0.781c-0.477-0.18-1.011-0.271-1.594-0.271
                c-0.562,0-1.087,0.091-1.557,0.271c-0.475,0.182-0.887,0.443-1.228,0.781c-0.341,0.338-0.614,0.752-0.807,1.227
                c-0.193,0.479-0.291,1.01-0.291,1.588c0,0.586,0.089,1.12,0.265,1.588c0.179,0.475,0.443,0.886,0.785,1.229
                c0.342,0.335,0.755,0.599,1.23,0.779c0.469,0.181,1.008,0.27,1.602,0.27c0.553,0,1.073-0.089,1.548-0.269
                c0.481-0.182,0.896-0.445,1.236-0.78c0.34-0.339,0.613-0.749,0.807-1.222c0.193-0.469,0.292-1.007,0.292-1.595
                c0-0.573-0.09-1.106-0.266-1.581C78.144,25.359,77.879,24.945,77.535,24.605z M74.705,25.095c0.612,0,1.092,0.198,1.469,0.602
                c0.373,0.404,0.562,0.983,0.562,1.722c0,0.359-0.051,0.686-0.153,0.972c-0.102,0.282-0.242,0.526-0.42,0.726
                c-0.177,0.198-0.39,0.353-0.633,0.462c-0.492,0.22-1.16,0.219-1.66,0c-0.248-0.111-0.463-0.266-0.639-0.462
                c-0.177-0.198-0.314-0.443-0.411-0.724c-0.097-0.285-0.146-0.613-0.146-0.974c0-0.352,0.05-0.676,0.147-0.968
                c0.096-0.285,0.234-0.531,0.41-0.728c0.176-0.197,0.391-0.354,0.639-0.462C74.121,25.152,74.401,25.095,74.705,25.095z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M87.108,24.605c-0.342-0.338-0.759-0.6-1.237-0.781c-0.476-0.18-1.012-0.271-1.593-0.271
                c-0.563,0-1.087,0.091-1.559,0.271c-0.473,0.182-0.886,0.443-1.228,0.781c-0.341,0.337-0.612,0.75-0.807,1.227
                c-0.193,0.479-0.29,1.01-0.29,1.588c0,0.585,0.09,1.12,0.266,1.588c0.179,0.475,0.442,0.886,0.785,1.229
                c0.341,0.335,0.755,0.599,1.229,0.779c0.47,0.181,1.009,0.27,1.603,0.27c0.553,0,1.073-0.089,1.547-0.269
                c0.48-0.182,0.896-0.445,1.237-0.783c0.34-0.338,0.611-0.747,0.806-1.219c0.193-0.47,0.293-1.007,0.293-1.595
                c0-0.573-0.092-1.106-0.268-1.581C87.717,25.359,87.451,24.945,87.108,24.605z M84.278,25.095c0.612,0,1.093,0.198,1.468,0.602
                c0.374,0.404,0.563,0.983,0.563,1.722c0,0.359-0.052,0.686-0.154,0.972c-0.101,0.282-0.243,0.526-0.421,0.726
                c-0.176,0.198-0.389,0.353-0.632,0.462c-0.49,0.22-1.159,0.219-1.659,0c-0.248-0.109-0.463-0.264-0.639-0.462
                c-0.177-0.198-0.314-0.443-0.411-0.724c-0.097-0.286-0.146-0.613-0.146-0.974c0-0.35,0.05-0.676,0.146-0.968
                c0.097-0.285,0.234-0.531,0.41-0.728c0.177-0.197,0.392-0.354,0.64-0.462C83.695,25.152,83.975,25.095,84.278,25.095z'
                  />
                  <path
                    fill='#FFFFFF'
                    d='M94.527,25.208c0.123,0,0.224-0.102,0.224-0.23v-1.023c0-0.127-0.101-0.23-0.224-0.23H92.8v-1.87
                c0-0.129-0.1-0.231-0.224-0.231h-1.342c-0.124,0-0.225,0.102-0.225,0.231v1.87h-1.177c-0.125,0-0.227,0.103-0.227,0.23v1.023
                c0,0.128,0.102,0.23,0.227,0.23h1.177v3.688c0,0.749,0.212,1.314,0.628,1.681c0.405,0.357,1.009,0.538,1.793,0.538h1.097
                c0.123,0,0.224-0.105,0.224-0.232v-1.078c0-0.128-0.101-0.232-0.224-0.232h-0.915c-0.303,0-0.522-0.059-0.639-0.171
                c-0.115-0.11-0.174-0.319-0.174-0.62v-3.574H94.527z'
                  />
                </g>
              </g>
              <g>
                <path
                  fill='#7092FF'
                  d='M45.706,30.801v-4.4h1.293c0.292,0.439,0.782,0.705,1.315,0.705c0.873,0,1.582-0.708,1.582-1.581
              c0-0.873-0.709-1.583-1.582-1.583c-0.533,0-1.023,0.268-1.315,0.706h-2.793c-0.141,0-0.256,0.114-0.256,0.256v6.518
              c0,0.068,0.027,0.133,0.074,0.182l5.158,4.952c0.048,0.049,0.114,0.074,0.181,0.074h1.757c0.104,0,0.197-0.062,0.237-0.157
              s0.019-0.206-0.056-0.279L45.706,30.801z'
                />
                <path
                  fill='#7092FF'
                  d='M57.966,36.343l-0.003-20.025c0-0.142-0.115-0.256-0.256-0.256H35.794c-0.142,0-0.256,0.114-0.256,0.256
              v20.067c0,0.141,0.114,0.256,0.256,0.256h0.328c0.026,0,0.051-0.005,0.076-0.013h0.838c0.142,0,0.257-0.114,0.257-0.256V17.816
              h18.915v17.275l-4.646-4.207h1.922c0.142,0,0.257-0.112,0.257-0.256V20.442c0-0.142-0.115-0.256-0.257-0.256H39.985
              c-0.143,0-0.258,0.114-0.258,0.256v15.93c0,0.142,0.115,0.256,0.258,0.256h1.242c0.142,0,0.256-0.114,0.256-0.256V21.94h10.503
              v7.19h-3.212c-0.143,0-0.256,0.114-0.256,0.256v0.926c0,0.071,0.027,0.138,0.079,0.186l6.416,6.061
              c0.048,0.045,0.11,0.07,0.176,0.07h2.521c0.072,0,0.143-0.031,0.191-0.085C57.95,36.489,57.973,36.416,57.966,36.343z'
                />
              </g>
            </g>
          </g>
          <path
            fill='none'
            stroke='#5E97FC'
            strokeWidth='4'
            strokeMiterlimit='10'
            d='M230,83.263c0,2.041-1.655,3.697-3.698,3.697
        H13.698c-2.044,0-3.698-1.656-3.698-3.697V56.364c0-2.043,1.654-3.699,3.698-3.699h212.604c2.043,0,3.698,1.656,3.698,3.699V83.263
        z'
          />
        </g>
      </svg>
    );
  }
}
