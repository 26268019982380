import * as uuid from 'uuid';

export class UUID {
  // eslint-disable-next-line no-useless-constructor
  private constructor(private str: string) {}

  toString() {
    return this.str;
  }

  static fromString(str: string) {
    if (!str) {
      throw new Error('Unable to create UUID from null value');
    }

    return new UUID(str);
  }

  static random() {
    return new UUID(uuid());
  }
}
