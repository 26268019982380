import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Badge,
} from 'reactstrap';
import { TemplateForm } from './forms/template-form';
import {
  TemplateFieldsForm,
  TemplateFieldData,
} from './forms/template-fields-form';
import { TemplateField } from '../../domain/templates/template-field';
import { HelpersModal } from './handlebars-helpers-modal';
import { TemplateDataSource, prettyDataSource, Template } from '../../domain/templates/template';
import CodeMirror from 'react-codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/3024-night.css';
import { SlideInModal } from '../../components/slide-in-modal';
import { ConfirmExitTemplateModal } from './confirm-exit-template-modal';
import { TemplateListStore } from '../../stores/template-list-store';
import { TemplateViewStore } from '../../stores/template-view-store';
import { inject, observer } from 'mobx-react';
import { OrganizationProductModule } from '../../../../domain/organization-product-module';
import { TemplateApi } from '../../template-api';

export interface TemplateData {
  fields: any;
  dataSource: TemplateDataSource;
  productModuleId: string;
  templateName: string;
  description: string;
  restricted: boolean;
  filter?: string;
}

interface Props {
  updating: boolean;
  organizationProductModules: OrganizationProductModule[];
  close: () => void;
}

interface Injected extends Props {
  templateViewStore: TemplateViewStore;
  templateListStore: TemplateListStore;
}

interface State {
  loading: boolean;
  templateCreated: boolean;
  templateDataValid: boolean;
  templateData: TemplateData;
  templateFieldsDataValid: boolean;
  templateFieldsData: TemplateFieldData[];
  showMergeVarsModal: boolean;
  showHelpersModal: boolean;
  showConfirmExitTemplateModal: boolean;
  mergeVars: any;
  helpers: object;
  touched: boolean;
  filter: string;
}

@inject(
  'templateViewStore',
  'templateListStore',
)
@observer
export class NewTemplateModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      templateCreated: false,
      templateDataValid: this.props.updating ? true : false,
      templateData: {
        fields: [],
        dataSource: TemplateDataSource.policies,
        productModuleId: '',
        templateName: '',
        description: '',
        restricted: false,
      },
      templateFieldsDataValid: this.props.updating ? true : false,
      templateFieldsData: [],
      showMergeVarsModal: false,
      showHelpersModal: false,
      showConfirmExitTemplateModal: false,
      mergeVars: undefined,
      helpers: {},
      touched: false,
      filter: '',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderDataSourceOptions() {
    return this.renderOptions(
      Object.values(TemplateDataSource).map(d => {
        return {
          label: prettyDataSource(d),
          value: d,
        };
      }),
    );
  }

  renderOptions = (data: any) => {
    return data.map((content: any) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
  };

  showHelpers = () => {
    this.setState({
      showHelpersModal: true,
      showMergeVarsModal: false,
    });
  };

  showMergeVars = async () => {
    this.setState({
      showMergeVarsModal: true,
      showHelpersModal: false,
    });

    const organizationId = window.location.pathname.split('/')[2];

    if (this.props.organizationProductModules) {
      this.setState({
        mergeVars: await TemplateApi.getDataExportTemplateMergeVariables({
          dataSource: this.state.templateData && this.state.templateData.dataSource,
          organizationId,
        }),
      });
    };
  };

  render() {
    const { organizationProductModules, templateViewStore } = this.injected;
    const {
      templateCreated,
      templateData,
      templateDataValid,
      templateFieldsData,
      templateFieldsDataValid,
      mergeVars,
      showMergeVarsModal,
      showHelpersModal,
    } = this.state;

    let productModuleName = '';

    if (!templateData) {
      productModuleName = 'No product module selected';
    } else if (templateData.productModuleId) {
      const productModule =
        (organizationProductModules || [] as any).find((p: OrganizationProductModule) => p.productModuleId === templateData.productModuleId);
      productModuleName = productModule ? productModule.name.toUpperCase() : '';
    }
    const templateDataSource = templateData ? (TemplateDataSource as any)[templateData.dataSource] : '';
    return (
      <Modal isOpen={true} centered={true} className='full-screen-modal'>
        <ModalHeader className='exports-modal-header'>
          {!templateCreated && this.props.updating && 'Update export template'}
          {!templateCreated &&
            !this.props.updating &&
            'Create new export template'}
          {templateCreated && templateData && templateData.templateName}

          {templateCreated && (
            <Badge color='primary' pill className='template-creation-badges'>
              {templateData && prettyDataSource(templateData.dataSource).toUpperCase()}
            </Badge>
          )}

          {templateCreated && (
            <Badge color='primary' pill className='template-creation-badges'>
              {productModuleName}
            </Badge>
          )}
          <div>
            {templateCreated && (
              <Button color='link' onClick={() => this.showHelpers()}>
                Helpers{' '}
              </Button>
            )}
            {templateCreated && (
              <Button color='link' onClick={() => this.showMergeVars()}>
                Merge vars{' '}
              </Button>
            )}
          </div>
        </ModalHeader>
        <ModalBody style={{ overflow: 'auto' }}>
          {!templateCreated && (
            <TemplateForm
              productModules={organizationProductModules}
              changed={this.onTemplateChanged}
              updating={this.props.updating}
            />
          )}
          {templateCreated && organizationProductModules && (
            <TemplateFieldsForm
              templateData={templateData}
              productModules={organizationProductModules}
              templateFieldsData={
                this.props.updating ? templateFieldsData : []
              }
              updating={this.props.updating}
              changed={this.onTemplateFieldsChanged}
            />
          )}

          {mergeVars && templateData && templateData.dataSource && (
            <SlideInModal
              title={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <h5>Merge variables</h5>
                  <Input
                    style={{ width: '200px' }}
                    type='select'
                    value={templateDataSource}
                    disabled={true}
                  >
                    <option>{templateDataSource}</option>
                  </Input>
                </div>
              }
              isOpen={showMergeVarsModal}
              toggle={() =>
                this.setState({
                  showMergeVarsModal: !showMergeVarsModal,
                })
              }
            >
              <CodeMirror
                options={{
                  // mode: 'javascript',
                  lineNumbers: true,
                  lineWrapping: true,
                  // json: true,
                  // statementIndent: 2,
                }}
                value={JSON.stringify(mergeVars, null, 8)}
                className='merge-vars-code-window template-code-mirror'
              />
            </SlideInModal>
          )}

          <SlideInModal
            title={<h5>Helpers</h5>}
            isOpen={showHelpersModal}
            toggle={() =>
              this.setState({
                showHelpersModal: !showHelpersModal,
              })
            }
          >
            <HelpersModal />
          </SlideInModal>

          <ConfirmExitTemplateModal
            isOpen={this.state.showConfirmExitTemplateModal}
            close={() => this.props.close()}
            submit={() => this.submit()}
            dismiss={() =>
              this.setState({ showConfirmExitTemplateModal: false })
            }
            dataValid={templateDataValid && templateFieldsDataValid}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <Button color='link' outline onClick={() => { templateViewStore.clear(); this.props.close(); }}>
            Cancel
          </Button>

          {!templateCreated && (
            <Button
              color='primary'
              onClick={() =>
                this.setState({ ...this.state, templateCreated: true })
              }
              disabled={!templateDataValid}
            >
              {this.props.updating ? 'Next' : 'Create'}
            </Button>
          )}

          {templateCreated && (
            <Button color='link' disabled>
              Import schema
            </Button>
          )}
          {templateCreated && (
            <Button color='link' disabled>
              Download schema
            </Button>
          )}

          {templateCreated && (
            <Button
              color='primary'
              onClick={this.submit}
              disabled={!templateFieldsDataValid}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  onTemplateChanged = (
    templateDataValid: boolean,
    templateData: TemplateData,
  ) => {
    this.setState({
      ...this.state,
      templateDataValid: templateDataValid,
      templateData: templateData,
    });
  };

  onTemplateFieldsChanged = (
    templateFieldsDataValid: boolean,
    templateFieldsData: TemplateFieldData[],
    filter: string,
  ) => {
    this.setState({
      ...this.state,
      templateFieldsDataValid,
      templateFieldsData,
      filter,
    });
  };

  submit = async () => {
    const { templateListStore, templateViewStore } = this.injected;
    const {
      templateDataValid,
      templateData,
      templateFieldsDataValid,
      templateFieldsData,
      filter,
    } = this.state;

    if (
      templateDataValid &&
      templateData &&
      templateFieldsDataValid &&
      templateFieldsData
    ) {
      this.setState({ loading: true });

      const sequencedFields = TemplateField.addSequence(templateFieldsData).map(
        (t: any) => {
          return {
            sequence: t.sequence,
            name: t.name,
            value: t.value,
          };
        },
      );

      const data = {
        templateName: templateData.templateName,
        description: templateData.description,
        dataSource: templateData.dataSource,
        productModuleId: templateData.productModuleId,
        fields: sequencedFields,
        restricted: templateData.restricted,
        filter,
      };

      const organizationId = window.location.pathname.split('/')[2];
      if (this.props.updating) {
        const result = await templateViewStore.update({ organizationId, payload: data as Template });
        if (!result || !result.error) {
          this.props.close();
        }
      } else {
        const newTemplate = await templateListStore.create({
          organizationId,
          payload: data as Template,
        });

        if (newTemplate && newTemplate.error) {
          return;
        }

        window.location.pathname = `/organizations/${organizationId}/exports/templates/${newTemplate.templateId}`;
        this.props.close();
      }
    }
  };
}
