import { Moment, utc as moment } from 'moment';
import { Construct } from '../../shared/domain/construct';
import { Requestee } from '../../shared/domain/requestee';
import { PolicyActivationEvent } from '../components/product-definition/interface';
import { AnnuityType } from '../components/product-definition/views/product-module-definition-claims/annuity/annuity-type';
import { FulfillmentType } from '../components/product-definition/views/product-module-definition-claims/fulfillment-types/domain/fulfillment-type';

export class ProductModuleDefinition {
  readonly productModuleDefinitionId: string;
  readonly productModuleId: string;
  readonly versionMinor: number;
  readonly versionMajor: number;
  readonly termsFileId?: string;
  readonly scheduleId: string;
  readonly policyAnniversaryId?: string;
  readonly welcomeLetterId: string;
  readonly memberCertificateId: string;
  readonly claimBlocksSchemaId: string;
  readonly claimSchemaId: string;
  readonly documentationId: string;
  readonly quoteSchemaId: string;
  readonly applicationSchemaId: string;
  readonly publishedAt?: Moment;
  readonly publishedBy?: Requestee;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly settings: ProductModuleDefinitionSettings;
  readonly codeId: string;
  readonly quoteSummaryId?: string;
  readonly readmeMarkdownId?: string;
  readonly changelogMarkdownId?: string;

  constructor(init: Construct<ProductModuleDefinition>) {
    Object.assign(this, init);
  }

  toDb(): DbProductModuleDefinition {
    return {
      product_module_definition_id: this.productModuleDefinitionId,
      product_module_id: this.productModuleId,
      version_minor: this.versionMinor.toString(),
      version_major: this.versionMajor.toString(),
      terms_file_id: this.termsFileId && this.termsFileId,
      policy_anniversary_file_id: this.policyAnniversaryId,
      schedule_id: this.scheduleId,
      welcome_letter_id: this.welcomeLetterId,
      member_certificate_id: this.memberCertificateId,
      claim_blocks_schema_id: this.claimBlocksSchemaId,
      claim_schema_id: this.claimSchemaId,
      quote_schema_id: this.quoteSchemaId,
      documentation_id: this.documentationId,
      application_schema_id: this.applicationSchemaId,
      published_at: this.publishedAt && this.publishedAt.toISOString(),
      published_by: this.publishedBy && this.publishedBy.toJSON(),
      created_at: this.createdAt.toISOString(),
      created_by: this.createdBy.toJSON(),
      settings: this.settings,
      code_id: this.codeId,
      quote_summary_id: this.quoteSummaryId && this.quoteSummaryId,
      readme_markdown_id: this.readmeMarkdownId && this.readmeMarkdownId,
      changelog_markdown_id:
        this.changelogMarkdownId && this.changelogMarkdownId,
    };
  }

  static fromNetwork(init: DbProductModuleDefinition): ProductModuleDefinition {
    return new ProductModuleDefinition({
      productModuleDefinitionId: init.product_module_definition_id,
      productModuleId: init.product_module_id,
      publishedAt: init.published_at ? moment(init.published_at) : undefined,
      publishedBy: init.published_by && Requestee.fromJSON(init.published_by),
      versionMinor: parseInt(init.version_minor),
      versionMajor: parseInt(init.version_major),
      termsFileId: init.terms_file_id ? init.terms_file_id : undefined,
      scheduleId: init.schedule_id,
      welcomeLetterId: init.welcome_letter_id,
      claimBlocksSchemaId: init.claim_blocks_schema_id,
      claimSchemaId: init.claim_schema_id,
      applicationSchemaId: init.application_schema_id,
      quoteSchemaId: init.quote_schema_id,
      policyAnniversaryId: init.policy_anniversary_file_id,
      memberCertificateId: init.member_certificate_id,
      documentationId: init.documentation_id,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      settings: init.settings as ProductModuleDefinitionSettings,
      codeId: init.code_id,
      quoteSummaryId: init.quote_summary_id,
      readmeMarkdownId: init.readme_markdown_id,
      changelogMarkdownId: init.changelog_markdown_id,
    });
  }
}

export enum GracePeriodOptions {
  ConsecutiveSkippedPayments = 'consecutive_skipped_payments',
  SkippedPaymentsOverPolicyTerm = 'skipped_payments_over_policy_term',
  SkippedPaymentsWithinPeriod = 'skipped_payments_within_period',
  PeriodAfterMissedPayment = 'period_after_missed_payment',
}

export enum GracePeriodNotificationTriggers {
  BeforeTheGracePeriodExpires = 'before_the_grace_period_expires',
  OnTheGracePeriodExpiryDate = 'on_the_grace_period_expiry_date',
}

export enum WaitingPeriodNotificationTriggers {
  OnTheWaitingPeriodExpiryDate = 'on_the_waiting_period_expiry_date',
  BeforeTheWaitingPeriodExpires = 'before_the_waiting_period_expires',
}

export enum CoolingOffPeriodOptions {
  AllPremiums = 'all_premiums',
  None = 'none',
}

export enum PeriodOf {
  Days = 'days',
  Months = 'months',
}

export enum ApplyRulesTo {
  Policy = 'the_full_policy',
}

export interface GracePeriodPeriodConfig {
  rules: [
    {
      lapseAfter: GracePeriodOptions;
      skippedPayments?: number;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: GracePeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export interface CoolingOffPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
      refundType: CoolingOffPeriodOptions;
    },
  ];
}

export interface CoolingOffPeriodRule {
  applyTo: ApplyRulesTo;
  period: {
    type: PeriodOf;
    value: number;
  };
  refundType: CoolingOffPeriodOptions;
}

export interface WaitingPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: WaitingPeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export interface ProductModuleDefinitionSettings {
  canIssuePolicyThroughDashboard: boolean;
  welcomeLetterEnabled: boolean;
  policySchemeType: PolicySchemeType;
  canUpdateSumAssured: boolean;
  policyholder: {
    entityTypes: PolicyholderEntityType[];
    idTypes: IdentificationType[];
  };
  policyAnniversary?: {
    enabled: boolean;
    daysBeforeAnniversary: number;
  };
  beneficiaries: {
    enabled: boolean;
    min: number;
    max: number;
  };
  coveredPeople: {
    enabled: boolean;
    min: number;
    max: number;
    policyholderIsBeneficiary: boolean;
  };
  coveredItems: {
    enabled: boolean;
    maxSumAssured: number;
  };
  billing: {
    paymentMethodTypes: PaymentMethodType[];
    currency: Currency;
    premiumType: PolicyBillingFrequency;
    paymentReference: string;
    proRataBillingOnIssue?: boolean;
    proRataBillingDisabled?: boolean;
    proRataMinimum: number;
    retries: {
      retryFailedPayments: number;
      daysBetweenFailedPaymentRetries: number;
    };
    billBeforeWeekend: boolean;
    naedoArrearsPolicies: boolean;
    primaryMethod?: {
      type: PaymentMethodType;
      strategy: DebitOrderPaymentMethodStrategy;
    };
    enableBillingOnSandbox: boolean;
  };
  lifecycle: {
    policyActivationEvent: PolicyActivationEvent;
    coolingOffPeriod: CoolingOffPeriodConfig;
    gracePeriod: GracePeriodPeriodConfig;
    waitingPeriod: WaitingPeriodConfig;
    canRequote?: boolean;
    canReactivate?: boolean;
    notTakenUpEnabled?: boolean;
  };
  claims: {
    claimChecklistItems: string[];
    fulfillmentTypes?: FulfillmentType[];
    annuityTypes: AnnuityType[];
  };
  documents?: {
    documentLabelListItems: DocumentLabelListItems[];
  };
}

export enum DebitOrderPaymentMethodStrategy {
  TwoDay = 'two_day',
  SameDay = 'same_day',
  Naedo = 'naedo',
  BestEffort = 'best_effort',
}

export interface DocumentLabelListItems {
  label: string;
  required: boolean;
}

export interface DbProductModuleDefinition {
  product_module_definition_id: string;
  product_module_id: string;
  version_minor: string;
  version_major: string;
  terms_file_id?: string;
  documentation_id: string;
  schedule_id: string;
  quote_schema_id: string;
  application_schema_id: string;
  application_schema?: object;
  claim_blocks_schema_id: string;
  claim_schema_id: string;
  welcome_letter_id: string;
  member_certificate_id: string;
  published_at?: string;
  published_by?: object;
  created_at: string;
  created_by: object;
  settings: object;
  code_id: string;
  quote_summary_id?: string;
  policy_anniversary_file_id?: string;
  readme_markdown_id?: string;
  changelog_markdown_id?: string;
}

export enum PaymentMethodType {
  DebitOrder = 'debit_order',
  Card = 'card',
  Eft = 'eft',
  External = 'external',
}

export enum PolicyBillingFrequency {
  Monthly = 'monthly',
  OnceOff = 'once_off',
}

export enum ReactivationOptionType {
  Reinstatement = 'reinstatement',
  Recommencement = 'recommencement',
}

export enum PolicySchemeType {
  Individual = 'individual',
  Group = 'group',
}

export enum PolicyholderEntityType {
  Individual = 'individual',
  Company = 'company',
}

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
}

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
}

export enum Currency {
  ZAR = 'ZAR',
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  KRW = 'KRW',
  JPY = 'JPY',
  CNY = 'CNY',
  INR = 'INR',
}
