/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='110px'
        height='110px'
        viewBox='0 0 110 110'
        enableBackground='new 0 0 110 110'
        xmlSpace='preserve'
      >
        <g>
          <circle fill='#FFFFFF' cx='48.22' cy='58.151' r='44.642' />
          <path
            fill='#E6E7E8'
            d='M8.855,58.151c0-23.77,18.577-43.192,42.003-44.559c-0.874-0.051-1.752-0.084-2.639-0.084
        c-24.655,0-44.642,19.987-44.642,44.643c0,24.654,19.987,44.641,44.642,44.641c0.887,0,1.765-0.033,2.639-0.084
        C27.432,101.34,8.855,81.918,8.855,58.151z'
          />
          <g>
            <path
              fill='#202B40'
              stroke='#202B40'
              strokeMiterlimit='10'
              d='M13.578,52.022h-1.265v-1.264c0-0.173-0.14-0.313-0.313-0.313
          s-0.313,0.14-0.313,0.313v1.264h-1.265c-0.174,0-0.313,0.141-0.313,0.313c0,0.174,0.14,0.314,0.313,0.314h1.265v1.264
          c0,0.173,0.14,0.313,0.313,0.313s0.313-0.14,0.313-0.313V52.65h1.265c0.173,0,0.312-0.14,0.312-0.314
          C13.89,52.163,13.75,52.022,13.578,52.022z'
            />
            <path
              fill='#202B40'
              stroke='#202B40'
              strokeMiterlimit='10'
              d='M42.933,88.997h-1.209v-1.209c0-0.166-0.135-0.301-0.301-0.301
          c-0.164,0-0.299,0.135-0.299,0.301v1.209h-1.209c-0.166,0-0.299,0.133-0.299,0.299s0.133,0.301,0.299,0.301h1.209v1.207
          c0,0.166,0.135,0.301,0.299,0.301c0.166,0,0.301-0.135,0.301-0.301v-1.207h1.209c0.166,0,0.299-0.135,0.299-0.301
          S43.099,88.997,42.933,88.997z'
            />
            <path
              fill='#202B40'
              stroke='#202B40'
              strokeMiterlimit='10'
              d='M60.956,79.118h-1.607V77.51c0-0.221-0.178-0.398-0.4-0.398
          c-0.219,0-0.398,0.178-0.398,0.398v1.607h-1.607c-0.221,0-0.398,0.18-0.398,0.398c0,0.223,0.178,0.4,0.398,0.4h1.607v1.607
          c0,0.221,0.18,0.398,0.398,0.398c0.223,0,0.4-0.178,0.4-0.398v-1.607h1.607c0.221,0,0.398-0.178,0.398-0.4
          C61.354,79.297,61.177,79.118,60.956,79.118z'
            />
            <path
              fill='#202B40'
              stroke='#202B40'
              strokeMiterlimit='10'
              d='M33.957,66.553h-1.459v-1.459c0-0.201-0.162-0.363-0.361-0.363
          s-0.361,0.162-0.361,0.363v1.459h-1.461c-0.199,0-0.361,0.162-0.361,0.361c0,0.201,0.162,0.363,0.361,0.363h1.461v1.459
          c0,0.199,0.162,0.361,0.361,0.361s0.361-0.162,0.361-0.361v-1.459h1.459c0.201,0,0.363-0.162,0.363-0.363
          C34.32,66.715,34.158,66.553,33.957,66.553z'
            />
            <path
              fill='#202B40'
              stroke='#202B40'
              strokeMiterlimit='10'
              d='M27.324,31.155h-1.09v-1.09c0-0.15-0.121-0.271-0.271-0.271
          c-0.148,0-0.27,0.121-0.27,0.271v1.09h-1.09c-0.15,0-0.271,0.122-0.271,0.271c0,0.15,0.121,0.271,0.271,0.271h1.09v1.09
          c0,0.15,0.121,0.271,0.27,0.271c0.15,0,0.271-0.121,0.271-0.271v-1.09h1.09c0.149,0,0.27-0.121,0.27-0.271
          C27.593,31.276,27.473,31.155,27.324,31.155z'
            />
          </g>
          <line
            fill='#FFFFFF'
            stroke='#202B40'
            strokeWidth='3'
            strokeMiterlimit='10'
            x1='43.232'
            y1='11.607'
            x2='14.79'
            y2='93.065'
          />
          <g>
            <g>
              <path
                fill='#CAE0FF'
                d='M83.468,77.797c-0.117,1.094-1.062,1.695-2.104,1.336L56.573,70.59c-1.041-0.357-1.599-1.502-1.24-2.543
            l14.671-42.581c0.359-1.04,1.502-1.598,2.543-1.24l24.793,8.542c1.039,0.358,1.412,1.414,0.828,2.347L86.985,52.979
            c-0.584,0.933-1.156,2.59-1.273,3.684L83.468,77.797z'
              />
              <path
                fill='#CAE0FF'
                d='M83.468,77.797c-0.117,1.094-1.062,1.695-2.104,1.336L56.573,70.59c-1.041-0.357-1.599-1.502-1.24-2.543
            l14.671-42.581c0.359-1.04,1.502-1.598,2.543-1.24l24.793,8.542c1.039,0.358,1.412,1.414,0.828,2.347L86.985,52.979
            c-0.584,0.933-1.156,2.59-1.273,3.684L83.468,77.797z'
              />
            </g>
            <g>
              <path
                fill='#CAE0FF'
                d='M67.628,65.049c-0.359,1.041-1.504,1.598-2.543,1.24L29.976,54.192c-1.041-0.358-1.599-1.502-1.24-2.542
            L43.408,9.067c0.359-1.04,1.502-1.598,2.543-1.24L81.06,19.925c1.041,0.358,1.598,1.502,1.24,2.542L67.628,65.049z'
              />
              <path
                fill='#CAE0FF'
                d='M67.628,65.049c-0.359,1.041-1.504,1.598-2.543,1.24L28.111,53.582L44.089,7.209l36.97,12.716
            c1.041,0.358,1.598,1.502,1.24,2.542L67.628,65.049z'
              />
            </g>
            <line
              fill='none'
              stroke='#9DC3ED'
              strokeWidth='3'
              strokeMiterlimit='10'
              x1='46.193'
              y1='8.337'
              x2='30.451'
              y2='53.425'
            />
            <polyline
              fill='none'
              stroke='#9DC3ED'
              strokeWidth='3'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              points='82.731,28.129      68.698,67.959 56.364,70.329     '
            />
            <line
              fill='none'
              stroke='#9DC3ED'
              strokeWidth='3'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='61.091'
              y1='65.042'
              x2='56.708'
              y2='66.143'
            />
            <g>
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M80.718,27.042l16.623,5.727
            c1.039,0.358,1.412,1.414,0.828,2.347L86.985,52.979c-0.584,0.933-1.156,2.59-1.273,3.684l-2.244,21.135
            c-0.117,1.094-1.062,1.695-2.104,1.336L56.573,70.59c-1.041-0.357-1.599-1.502-1.24-2.543l1.578-4.574'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M80.718,27.042l16.623,5.727
            c1.039,0.358,1.412,1.414,0.828,2.347L86.985,52.979c-0.584,0.933-1.156,2.59-1.273,3.684l-2.244,21.135
            c-0.117,1.094-1.062,1.695-2.104,1.336L56.573,70.59c-1.041-0.357-1.599-1.502-1.24-2.543l1.578-4.574'
              />
            </g>
            <g>
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M67.628,65.049
            c-0.359,1.041-1.504,1.598-2.543,1.24L29.976,54.192c-1.041-0.358-1.599-1.502-1.24-2.542L43.408,9.067
            c0.359-1.04,1.502-1.598,2.543-1.24L81.06,19.925c1.041,0.358,1.598,1.502,1.24,2.542L67.628,65.049z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M67.628,65.049
            c-0.359,1.041-1.504,1.598-2.543,1.24L28.111,53.582L44.089,7.209l36.97,12.716c1.041,0.358,1.598,1.502,1.24,2.542
            L67.628,65.049z'
              />
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      fill='#202B40'
                      d='M84.546,50.015c0.191,0.067,0.402-0.04,0.471-0.239l0.547-1.586c0.068-0.198-0.031-0.411-0.225-0.478
                  l-2.68-0.923l1-2.902c0.068-0.199-0.031-0.413-0.223-0.479l-2.084-0.718c-0.191-0.066-0.402,0.041-0.471,0.239l-1,2.902
                  l-1.828-0.629c-0.191-0.066-0.404,0.04-0.471,0.237l-0.547,1.586c-0.068,0.199,0.033,0.413,0.225,0.479l1.828,0.63
                  l-1.973,5.72c-0.4,1.164-0.375,2.152,0.074,2.943c0.439,0.771,1.279,1.375,2.496,1.795l1.701,0.586
                  c0.193,0.066,0.404-0.041,0.473-0.24l0.576-1.674c0.068-0.197-0.031-0.412-0.225-0.479l-1.42-0.488
                  c-0.467-0.162-0.779-0.371-0.9-0.607c-0.119-0.234-0.098-0.588,0.064-1.055l1.91-5.544L84.546,50.015z'
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      fill='#202B40'
                      d='M53.951,30.407c-0.26-0.329-0.539-0.58-0.828-0.745c-1.104-0.639-2.131-0.752-3.031-0.534
                  c-0.326,0.079-0.639,0.198-0.932,0.362l0.182-0.529c0.068-0.197-0.033-0.411-0.225-0.477L47.13,27.8
                  c-0.193-0.066-0.404,0.04-0.473,0.237l-3.703,10.75c-0.068,0.198,0.033,0.413,0.225,0.479l2.082,0.717
                  c0.191,0.066,0.402-0.042,0.471-0.239l1.936-5.617c0.15-0.44,0.346-0.845,0.576-1.209c0.225-0.351,0.488-0.646,0.777-0.875
                  c0.283-0.222,0.584-0.363,0.92-0.432c0.674-0.137,1.449,0.13,2.195,0.848c0.074,0.07,0.174,0.107,0.275,0.102
                  c0.102-0.009,0.195-0.057,0.262-0.138l1.27-1.531C54.06,30.749,54.062,30.546,53.951,30.407z'
                    />
                    <path
                      fill='#202B40'
                      d='M65.856,35.78c-0.35-0.707-0.854-1.337-1.502-1.874c-0.643-0.533-1.424-0.959-2.328-1.271
                  c-0.873-0.301-1.734-0.441-2.561-0.414c-0.832,0.026-1.613,0.213-2.322,0.556c-0.711,0.343-1.353,0.838-1.908,1.473
                  c-0.555,0.636-0.99,1.412-1.301,2.308c-0.312,0.91-0.459,1.784-0.436,2.604c0.023,0.831,0.213,1.613,0.562,2.324
                  c0.348,0.705,0.85,1.334,1.49,1.869c0.63,0.529,1.419,0.957,2.341,1.275c0.857,0.295,1.713,0.433,2.545,0.409
                  c0.842-0.024,1.627-0.21,2.338-0.551c0.707-0.341,1.348-0.833,1.902-1.462c0.551-0.626,0.99-1.406,1.305-2.317
                  c0.307-0.891,0.453-1.765,0.434-2.595C66.396,37.275,66.206,36.491,65.856,35.78z M61.204,35.026
                  c0.949,0.327,1.588,0.892,1.955,1.721c0.363,0.826,0.348,1.826-0.047,2.974c-0.191,0.557-0.447,1.034-0.758,1.422
                  c-0.309,0.385-0.658,0.688-1.039,0.902c-0.381,0.214-0.793,0.34-1.23,0.381c-0.879,0.078-1.916-0.281-2.574-0.888
                  c-0.326-0.304-0.578-0.66-0.744-1.059c-0.168-0.402-0.252-0.854-0.25-1.343c0-0.492,0.098-1.028,0.291-1.587
                  c0.188-0.547,0.439-1.025,0.748-1.424c0.301-0.392,0.646-0.698,1.025-0.91c0.377-0.212,0.795-0.338,1.238-0.375
                  C60.267,34.803,60.733,34.864,61.204,35.026z'
                    />
                    <path
                      fill='#202B40'
                      d='M73.042,47.104c-0.248-0.127-0.484-0.269-0.678-0.448c-0.326-0.303-0.578-0.658-0.744-1.06
                  c-0.168-0.401-0.252-0.853-0.252-1.341c0.002-0.497,0.1-1.03,0.293-1.589c0.188-0.544,0.439-1.025,0.746-1.425
                  c0.303-0.39,0.648-0.699,1.025-0.91c0.379-0.211,0.795-0.337,1.24-0.375c0.26-0.021,0.527,0.01,0.797,0.06l0.83-2.418
                  c-0.674-0.182-1.34-0.28-1.98-0.258c-0.832,0.026-1.613,0.213-2.324,0.556c-0.709,0.341-1.35,0.836-1.906,1.473
                  c-0.557,0.635-0.992,1.411-1.301,2.308c-0.312,0.907-0.459,1.782-0.436,2.605c0.023,0.83,0.211,1.612,0.562,2.323
                  c0.348,0.705,0.85,1.334,1.488,1.869c0.502,0.419,1.127,0.753,1.812,1.039L73.042,47.104z'
                    />
                  </g>
                </g>
              </g>
            </g>
            <line
              fill='none'
              stroke='#202B40'
              strokeWidth='2'
              strokeMiterlimit='10'
              x1='55.518'
              y1='68.178'
              x2='66.062'
              y2='66.397'
            />
          </g>
          <line
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='102.308'
            y1='30.604'
            x2='106.208'
            y2='27.476'
          />
          <line
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='98.62'
            y1='28.805'
            x2='98.958'
            y2='25.739'
          />
          <line
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='103.356'
            y1='34.863'
            x2='106.423'
            y2='35.201'
          />
        </g>
      </svg>
    );
  }
}
