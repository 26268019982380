import { observable, computed, action } from 'mobx';
import { FeatureApi } from '../feature-api';
import { OrganizationFeature } from '../domain/organization-feature';

export class OrganizationFeatureListStore {
  @observable features: OrganizationFeature[] = [];
  @observable isLoading = true;
  @observable organization = '';

  load = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    this.isLoading = true;
    this.organization = organizationId;
    try {
      this.features = await FeatureApi.getOrganizationFeatures({ organizationId });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  };

  @action createOrganizationFeatureRequest = async (params: {
    featureKey: string;
    organizationId: string;
    sandbox: boolean;
    production: boolean;
  }) => {
    this.isLoading = true;
    const { featureKey, organizationId, sandbox, production } = params;

    try {
      await FeatureApi.createOrganizationFeatureRequest({
        featureKey,
        organizationId,
        sandbox,
        production,
      });

      this.features = await FeatureApi.getOrganizationFeatures({ organizationId });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }

  };

  @computed get isEmpty() {
    return !this.isLoading && this.features.length === 0;
  }

  setOrganizationFeatureListStoreLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const organizationFeatureListStore = new OrganizationFeatureListStore();
