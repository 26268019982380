import { ajax } from '../../../helpers/index';
import { Pagination } from '../../../shared/domain/pagination';
import { UUID } from '../../../shared/domain/uuid';
import { fromNetwork, Role } from './domain/role';

export interface RolesFilters {
  search?: string;
}

export const getRoles = async (params: {
  filters?: RolesFilters;
  pagination: Pagination;
  organizationId: UUID;
}): Promise<Role[]> => {
  const { organizationId, filters, pagination } = params;
  const queryString = queryHelper({ filters, pagination });

  const roles = await ajax({
    type: 'GET',
    path: `organizations/${organizationId}/roles?${queryString}`,
  });

  return (roles || []).map(fromNetwork);

};

/// Helpers ///
function queryHelper(params: { filters: RolesFilters | undefined; pagination: Pagination }) {
  const { filters, pagination } = params;

  const query: [string, string | number][] = [];

  if (filters && filters.search) {
    query.push(['search', filters.search]);
  }

  query.push(['page', pagination.page], ['page_size', pagination.pageSize]);

  return query.map(([key, value]) => `${key}=${value}`).join('&');
}
