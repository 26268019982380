import React from 'react';
import { Route, IndexRoute } from 'react-router';

// Components
import Communications from './components/communications';

export default (
  <Route>
    <IndexRoute component={Communications} />
  </Route>
);
