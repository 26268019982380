import React from 'react';
import { Card, CardHeader, CardBody, Badge, Table } from 'reactstrap';
import { withData } from '../../helpers';
import moment from 'moment';

const Organizations = ({ loading, error, organizations, router }) => (
  <div>
    <Card>
      <CardHeader>
        Organizations
        <div className='pull-right' />
      </CardHeader>
      <CardBody>
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          <div>Error loading organizations: {error}</div>
        ) : (
          <Table hover>
            <tbody>
              {organizations.map(organization => (
                <tr
                  key={organization.clientId}
                  onClick={() =>
                    router.push('/organizations/' + organization.clientId)
                  }
                >
                  <td>
                    {organization.productName}{' '}
                    {moment(organization.reviewedAt).isValid() ? (
                      <Badge color='primary'>Live</Badge>
                    ) : null}
                  </td>
                  <td>{organization.productWebsite}</td>
                  <td>
                    {moment(organization.createdAt).format('DD MMM YYYY')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  </div>
);

export default withData({
  prop: 'organizations',
  path: props => `/admin/users/${props.params.userId}/client-apps`,
})(Organizations);
