import React from 'react';
import { ApiError } from './error';
import { Alert } from 'reactstrap';

interface Props {
  error: ApiError;
}
interface State {
}

export class ErrorDisplay extends React.Component<Props, State> {
  render() {
    const { error } = this.props;
    return (
      <div style={{ marginTop: 32 }}>
        <Alert color='danger'>{`${error.error && error.error.message || 'Something went wrong'} ${
          error.error && error.error.errors
            ? `: ${error.error && error.error.errors[0].message}`
            : 'Please try again or contact support@root.co.za if the problem persists.'
        }`}</Alert>
      </div>
    );
  }
}

