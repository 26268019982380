import React from 'react';
import { Container, Table, Badge, Input, Alert } from 'reactstrap';
import moment from 'moment';
import { withData, alphabetically } from '../../helpers';
import PageHeader from '../../components/page-header';
import Pager from '../../components/pager';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';

class Organizations extends React.Component {
  state = {
    search: '',
    page: 1,
    pageSize: 10,
  };

  get liveOrganizations() {
    if (this.routeFilter === 'testing') {
      return [];
    }
    return this.props.organizations.filter(o => o.reviewedAt);
  }

  get sandboxOrganizations() {
    if (this.routeFilter === 'approved') {
      return [];
    }
    return this.props.organizations.filter(o => !o.reviewedAt);
  }

  handleSearchChanged = e => {
    const search = e.target.value;
    this.setState({ search });
  };

  get routeFilter() {
    const filter = this.props.router.location.pathname.split('/')[2];
    return filter;
  }

  applySearchFilter = o => {
    const text = this.state.search;
    if (!text) {
      return true;
    }

    if (o.productName && o.productName.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }

    if (o.productDescription && o.productDescription.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }

    if (o.productWebsite && o.productWebsite.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }

    if (o.productContactNumber && o.productContactNumber.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }

    return false;
  };

  renderOrganizationRow = organization => {
    return (
      <tr
        className = 'table-row-pointer'
        key={organization.clientId}
        onClick={() =>
          this.props.router.push('/organizations/' + organization.clientId)
        }
      >
        <td>{organization.productName}</td>
        <td>
          {organization.reviewedAt ? (
            <Badge color='primary'>Live</Badge>
          ) : <Badge color='warning'>Test</Badge>}
        </td>
        <td>{organization.id}</td>
        <td>{moment(organization.createdAt).format('DD MMM YYYY')}</td>
      </tr>
    );
  };

  renderOrganizationsTable(organizations) {
    const { page, pageSize } = this.state;
    return (
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Organization ID</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {organizations
            .filter(this.applySearchFilter)
            .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
            .map(this.renderOrganizationRow)}
        </tbody>
      </Table>
    );
  }

  renderSearchInput = () => {
    return (
      <div className='pull-right'>
        <Input
          name='search'
          placeholder='Search'
          type='text'
          value={this.state.search}
          onChange={this.handleSearchChanged}
          style={{ margin: '24px' }}
        />
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <Container style={{ maxWidth: '100%' }}>
          <i>Loading organizations...</i>
        </Container>
      );
    }

    if (this.props.error) {
      return (
        <Alert color='danger'>
          Failed to load organizations: {this.props.error}
        </Alert>
      );
    }

    const { page, pageSize } = this.state;
    const allOrganizations = this.liveOrganizations.concat(
      this.sandboxOrganizations,
    ).sort(alphabetically('productName'));
    /* add a page if there is a remainder */
    const iPages =
      Math.floor(
        allOrganizations.filter(this.applySearchFilter).length / pageSize,
      ) +
      (allOrganizations.filter(this.applySearchFilter).length % pageSize > 0
        ? 1
        : 0);
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <Container>
          <PageHeader title={''} rightComponent={this.renderSearchInput()} />
          {this.renderOrganizationsTable(allOrganizations)}
          <Pager
            currentPage={page}
            maxPages={iPages}
            maxPagers={2}
            urlBase={'/organizations/page'}
            onClick={page => {
              this.setState({ page });
            }}
          />
        </Container>
      </div>
    );
  }
}

export default withData({
  prop: 'organizations',
  path: '/admin/client-apps',
})(Organizations);

const sideBarSchema = {
  name: 'Organizations',
  routes: () => [
    {
      title: true,
      name: 'View',
    },
    {
      name: 'Approved',
      url: '/organizations/approved',
    },
    {
      name: 'Testing',
      url: '/organizations/testing',
    },
    {
      name: 'All',
      url: '/organizations/all',
    },
  ],
};
