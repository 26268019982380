import React from 'react';
import { CardBody, Row, Col, Button, Input } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../stores/product-module-component-loading-state-store';
import { Icon16PXTick } from '../../../../../components/icons/icon-16-px-tick';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import uuid from 'uuid';
import { debounce } from '../../../../../helpers/debounce';
import _ from 'lodash';
import { DocumentLabelListItems } from '../../../../domain/product-module-definition';
import { SavingState } from '../../util';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  productModuleKey: string;
}

interface State {
  showDocument: boolean;
  documentLabelListItems: DocumentLabelListItems[];
}

let timeout: any = null;
const loadingKeyName = 'DocumentLabels';

@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionDocumentLabels extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDocument: false,
      documentLabelListItems: [],
    };
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
      const {
        documents,
      } = productModuleStore.productModuleDefinitionDraft.settings;
      if (documents) {
        const { documentLabelListItems } = documents;
        this.setState({ documentLabelListItems });
      }
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.productModuleSettingsComponents,
        loadingKeyName,
      );
    }
  }

  removeListItem = async (index: number) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        loadingKeyName,
        SavingState.Saving,
      );
    }

    if (productModuleStore) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      if (settings.documents) {
        const { documentLabelListItems } = settings.documents;
        documentLabelListItems.splice(index, 1);
        _.set(
          settings,
          'documents.documentLabelListItems',
          documentLabelListItems,
        );
        this.setState({ documentLabelListItems });
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      }
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        loadingKeyName,
        SavingState.Saved,
      );

      debounce(
        loadingKeyName,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleSettingsComponents,
            loadingKeyName,
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  documentLabelsMap = (lockedComponent: boolean) => {
    const { documentLabelListItems } = this.state;
    if (documentLabelListItems) {
      return documentLabelListItems.map((documentLabelListItem, index) => {
        return (
          <div
            style={{ width: 530 }}
            key={`claim-checklist ${uuid().toString()}`}
          >
            <a
              style={{ float: 'right' }}
              onClick={e => {
                e.preventDefault;
                if (!lockedComponent) {
                  this.removeListItem(index);
                }
              }}
            >
              <Icon24PXCross />
            </a>
            <Input
              style={{ marginBottom: 10, width: 500, minHeight: 40 }}
              type='textarea'
              id={`claim-checklist ${index}`}
              defaultValue={documentLabelListItem.label}
              onChange={e => this.updateListItem(e.target.value, index)}
              disabled={lockedComponent}
            />
          </div>
        );
      });
    }
    return <div></div>;
  };

  updateListItem = (label: string, index: number) => {
    const documentLabelListItems = this.state.documentLabelListItems;
    documentLabelListItems[index] = {
      label,
      required: false,
    };
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          loadingKeyName,
          SavingState.Saving,
        );
      }
      if (productModuleStore) {
        const { settings } = productModuleStore.productModuleDefinitionDraft;

        if (settings.documents) {
          const { documentLabelListItems } = settings.documents;
          documentLabelListItems[index] = {
            label,
            required: false,
          };

          _.set(
            settings,
            'claims.documentLabelListItems',
            documentLabelListItems,
          );
          await productModuleStore.createProductModuleDefinition({
            productModuleId: productModuleStore.productModule.productModuleId,
            data: { settings_json: settings },
          });
        }
      }
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          loadingKeyName,
          SavingState.Saved,
        );

        debounce(
          loadingKeyName,
          () =>
            productModuleComponentLoadingStateStore.updateComponentLoadingState(
              StoreIndex.productModuleSettingsComponents,
              loadingKeyName,
              SavingState.Default,
            ),
          3000,
        );
      }
    }, 1200);
  };

  addListItem = async () => {
    const { productModuleStore } = this.props;
    if (productModuleStore) {
      this.setState({
        documentLabelListItems: [
          ...this.state.documentLabelListItems,
          { label: '', required: false },
        ],
      });
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const documentLabelListItems: DocumentLabelListItems[] = [];
      if (settings.documents) {
        documentLabelListItems.push(
          ...settings.documents.documentLabelListItems,
        );
      }
      documentLabelListItems.push({
        label: '',
        required: false,
      });

      _.set(
        settings,
        'documents.documentLabelListItems',
        documentLabelListItems,
      );
      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: { settings_json: settings },
      });
    }
  };

  render() {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (!productModuleStore || !productModuleComponentLoadingStateStore) {
      return <LoadingInPage />;
    }

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleSettingsComponents[
        loadingKeyName
      ];

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Document labels
              </p>
            </Col>
            <Col xs={9}>
              <p className='document-labels-heading'>Document labels</p>

              <div
                className='product-module-definitions-enable-item-checkbox'
                style={{ marginTop: -19 }}
              >
                {saving === SavingState.Saving && (
                  <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                    Saving
                  </div>
                )}
                {saving === SavingState.Saved && (
                  <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                    <Icon16PXTick
                      style={{ marginRight: 5, paddingBottom: 3 }}
                    />
                    Saved
                  </div>
                )}
              </div>
              <div>
                {this.documentLabelsMap(productModuleStore.lockedComponent)}
              </div>
              <Button
                color='primary'
                className='product-module-definition-horizontal-card-button'
                style={{ marginTop: 10 }}
                onClick={e => this.addListItem()}
                disabled={productModuleStore.lockedComponent}
              >
                Add label
              </Button>
            </Col>
          </Row>
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
