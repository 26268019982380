import * as React from 'react';

export const Icon16PXTick = (props: { fill?: string; style?: any }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 -3 12 12'
    style={{ ...props.style }}
  >
    <g fill='none' fillRule='evenodd'>
      <circle cx='8' cy='8' r='8' fill={props.fill || '#F6F6F6'} />
      <path
        fill='#1fc881'
        d={`M3.68541796,4.61169199 L8.31605896,0.27046605 C8.71897123,-0.107264205 9.35180703,
        -0.0868501471 9.72953729,0.316062125 C10.1072675,0.718974397 10.0868535,1.3518102 9.68394121,
        1.72954046 L4.35060788,6.72954046 C3.95833345,7.09729774 3.34547067,7.08902011 2.96327174,
        6.71080242 L0.296605075,4.07191353 C-0.0959584661,3.6834392 -0.0992734205,3.0502829 0.289200917,2.65771935 C0.677675255,
        2.26515581 1.31083156,2.26184086 1.7033951,2.6503152 L3.68541796,4.61169199 Z`}
      ></path>
    </g>
  </svg>
);
