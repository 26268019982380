import ajax from '../../helpers/ajax';
import { ProductModuleDefinition } from '../domain/product-module-definition';
import { ProductModule } from '../domain/product-module';
import { ProductModuleDefinitionCode } from '../domain/product-module-definition-code';
import { ProductModuleDefinitionDocument } from '../domain/product-module-definition-document';
import { ProductModuleDefinitionSchema } from '../domain/product-module-definition-schema';
import { ProductModuleDefinitionScheduledFunction } from '../domain/product-module-definition-scheduled-function';
import { ProductModuleData } from './product-definition/modals';
import { ProductModuleDefinitionMarkdown } from '../domain/product-module-definition-markdown';
import { ProductModuleDefinitionAlterationHook } from '../domain/product-module-definition-alteration-hooks';

const baseUrl = '/insurance/admin';
const productModulesUrl = `${baseUrl}/product-modules`;

export const createProductModule = async (
  data: ProductModuleData,
  cloneFromProductModuleDefinitionId?: string,
) => {
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}`,
    data: {
      name: data.name,
      key: data.key,
      restricted: data.restricted,
      clone_from_product_module_definition_id: cloneFromProductModuleDefinitionId,
      owned_by_organization_id: data.ownedByOrganizationId,
    },
  });

  return ProductModule.fromNetwork(result);
};

export const createProductModuleDefinition = async (
  productModuleId: string,
  data: any,
) => {
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/definitions`,
    data,
  });

  return ProductModuleDefinition.fromNetwork(result);
};

export const createHtmlToPDF = async (data: any) => {
  return ajax({
    type: 'POST',
    path: `${baseUrl}/html-to-pdf`,
    data,
    raw: true,
  });
};

export const publishProductModule = async (productModuleId: string) => {
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/publish`,
  });

  return ProductModule.fromNetwork(result);
};

export const sendProductModuleDefinitionToReview = async (
  productModuleId: string,
) => {
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/send-to-review`,
  });

  return ProductModule.fromNetwork(result);
};

export const revokeProductModuleDefinintionReview = async (
  productModuleId: string,
  data: any,
) => {
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/revoke-review`,
    data,
  });

  return ProductModule.fromNetwork(result);
};

export const getProductModuleDefinition = async (
  productModuleId: string,
  productDefinitionId: string,
) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}`,
  });

  return ProductModuleDefinition.fromNetwork(result);
};

export const getProductModuleDefinitionTerms = async (
  productModuleId: string,
  productDefinitionId: string,
) => {
  return ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}/terms`,
  });
};

export const getProductModuleDefinitionSchema = async (
  productModuleId: string,
  productDefinitionId: string,
  productModuleDefinitionSchemaId: string,
) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}/schemas/${productModuleDefinitionSchemaId}`,
  });

  return ProductModuleDefinitionSchema.fromNetwork(result);
};

export const getProductModuleDefinitionDocuments = async (
  productModuleId: string,
  productDefinitionId: string,
  productModuleDefinitionDocumentId: string,
) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}/documents/${productModuleDefinitionDocumentId}`,
  });

  return ProductModuleDefinitionDocument.fromNetwork(result);
};

export const getProductModuleDefinitionCode = async (
  productModuleId: string,
  productDefinitionId: string,
  productModuleDefinitionCodeId: string,
) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}/code/${productModuleDefinitionCodeId}`,
  });

  return ProductModuleDefinitionCode.fromNetwork(result);
};

export const getProductModuleDefinitionMarkdown = async (
  productModuleId: string,
  productDefinitionId: string,
  productModuleDefinitionMarkdownId: string,
) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productDefinitionId}/markdown/${productModuleDefinitionMarkdownId}`,
  });

  return ProductModuleDefinitionMarkdown.fromNetwork(result);
};

export const getProductModuleByKey = async (productModuleKey: string) => {
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleKey}`,
  });

  return ProductModule.fromNetwork(result);
};

export const getProductModules = async (params: {
  productModuleName: string;
  page: number;
  pageSize: number;
}) => {
  const { productModuleName, page, pageSize } = params;

  const queryString = productModuleName ? `&search=${productModuleName}` : '';

  const total = await ajax({
    type: 'HEAD',
    path: `${productModulesUrl}?${queryString}`,
    raw: true,
  });

  const pagination = `page=${page + 1}&page_size=${pageSize}`;

  const response = await ajax({
    type: 'GET',
    path: `${productModulesUrl}?${pagination}${queryString}`,
  });

  const rowCount = total.headers.get('X-Total-Count');

  return {
    response: (response || []).map(ProductModule.fromNetwork),
    rowCount,
  };
};

export const getAllProductModuleDefinitions = async (params: {
  productModuleId: string;
}) => {
  const { productModuleId } = params;
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions`,
  });

  return result.map(ProductModuleDefinition.fromNetwork);
};

export const getProductModuleDefinitionScheduledFunctions = async (params: {
  productModuleId: string;
  productModuleDefinitionId: string;
}) => {
  const { productModuleId, productModuleDefinitionId } = params;
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productModuleDefinitionId}/scheduled-functions`,
  });

  return result.map(ProductModuleDefinitionScheduledFunction.fromNetwork);
};

export const getProductModuleDefinitionAlterationHooks = async (params: {
  productModuleId: string;
  productModuleDefinitionId: string;
}) => {
  const { productModuleId, productModuleDefinitionId } = params;
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/definitions/${productModuleDefinitionId}/alteration-hooks`,
  });

  return result.map(ProductModuleDefinitionAlterationHook.fromNetwork);
};

export const updateProductModuleReadme = async (productModuleId: string, markdown: string) => {
  const result = await ajax({
    type: 'PUT',
    path: `${productModulesUrl}/${productModuleId}/readme`,
    data: { markdown },
  });
  return ProductModule.fromNetwork(result);
};

export const updateProductModuleChangelog = async (productModuleId: string, markdown: string) => {
  const result = await ajax({
    type: 'PUT',
    path: `${productModulesUrl}/${productModuleId}/changelog`,
    data: { markdown },
  });
  return ProductModule.fromNetwork(result);
};

export const getVersionBumpCount = async (params: {
  productModuleId: string;
  sandbox: boolean;
}) => {
  const { productModuleId, sandbox } = params;
  const result = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/version-bump-count`,
    sandbox,
  });
  return result;
};

export const queueVersionBump = async (params: {
  productModuleId: string;
  sandbox: boolean;
}) => {
  const { productModuleId, sandbox } = params;
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/queue-version-bump`,
    sandbox,
  });
  return result;
};

export const cancelVersionBumps = async (params: {
  productModuleId: string;
}) => {
  const { productModuleId } = params;
  const result = await ajax({
    type: 'POST',
    path: `${productModulesUrl}/${productModuleId}/cancel-queued-version-bump`,
    sandbox: false,
  });
  return result;
};
