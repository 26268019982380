import { Report } from '../domain/reports';
import { ajax } from '../../../../helpers';
import { Pagination } from '../../../../shared/domain/pagination';
import { ReportRun } from '../domain/report-run';
import { EmailRecipient } from '../../outbound-notifications/domain/email-recipient';
import { ReportType } from '../domain/report-type';


export  interface ReportFilters {}

export interface ReportIncludes {}

export class ReportApi {
  public static async get(params: {
    filters?: ReportFilters;
    includes?: ReportIncludes[];
    pagination: Pagination;
    organizationId: string;
  }): Promise<{ reports: Report[]; total: number }> {
    const { organizationId, pagination } = params;
    const query: [string, string | number][] = [];

    if (params.filters) {}

    if (params.includes && params.includes.length > 0) {
      query.push(['include', Object.values(params.includes).join(',')]);
    }

    query.push(
      ['page', pagination.page],
      ['page_size', pagination.pageSize],
    );

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');

    const total = await ajax({
      type: 'HEAD',
      path: `/apps/${organizationId}/insurance/reports?${queryString}`,
      raw: true,
    });

    const rowCount = total.headers.get('X-Total-Count');
    const reports = await ajax({
      type: 'GET',
      path: `/apps/${organizationId}/insurance/reports?${queryString}`,
    });

    return {
      reports: (reports || []).map(Report.fromNetwork),
      total: parseInt(rowCount || '0'),
    };
  }

  public static async updateRecipients(params: {
    reportId: string;
    organizationId: string;
    recipients: EmailRecipient[];
  }): Promise<Report> {
    const { reportId, recipients, organizationId } = params;

    const result = await ajax({
      type: 'PATCH',
      path: `/apps/${organizationId}/insurance/reports/${reportId}`,
      data: {
        recipients,
      },
    });

    return Report.fromNetwork(result);
  }

  public static async createReport(params: {
    organizationId: string;
    recipients: EmailRecipient[];
    type: ReportType;
  }): Promise<Report> {
    const { type, recipients, organizationId } = params;

    const result = await ajax({
      type: 'POST',
      path: `/apps/${organizationId}/insurance/reports/`,
      data: {
        type,
        recipients,
      },
    });

    return Report.fromNetwork(result);
  }

  public static async getById(params: {
    reportId: string;
    organizationId: string;
  }): Promise<Report> {
    const { reportId, organizationId } = params;
    const query: [string, string | number][] = [];

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');

    const result = await ajax({
      type: 'GET',
      path: `/apps/${organizationId}/insurance/reports/${reportId}?${queryString}`,
    });

    return Report.fromNetwork(result);
  }

  public static async getReportRuns(params: {
    filters?: ReportFilters;
    includes?: ReportIncludes[];
    pagination: Pagination;
    organizationId: string;
    reportId: string;
  }): Promise<{ reportRuns: ReportRun[]; total: number }> {
    const { organizationId, pagination, reportId } = params;
    const query: [string, string | number][] = [];

    if (params.filters) {}

    if (params.includes && params.includes.length > 0) {
      query.push(['include', Object.values(params.includes).join(',')]);
    }

    query.push(
      ['page', pagination.page],
      ['page_size', pagination.pageSize],
    );

    const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');

    const total = await ajax({
      type: 'HEAD',
      path: `/apps/${organizationId}/insurance/reports/${reportId}/runs?${queryString}`,
      raw: true,
    });

    const rowCount = total.headers.get('X-Total-Count');

    const reportRuns = await ajax({
      type: 'GET',
      path: `/apps/${organizationId}/insurance/reports/${reportId}/runs?${queryString}`,
    });

    return {
      reportRuns: (reportRuns || []).map(ReportRun.fromNetwork),
      total: parseInt(rowCount || '0'),
    };
  }

  public static async archive(params: { organizationId: string; reportId: string }) {
    const { organizationId, reportId } = params;
    const report = await ajax({
      path: `/apps/${organizationId}/insurance/reports/${reportId}/archive`,
      type: 'PATCH',
    });

    return Report.fromNetwork(report);
  }
}
