import { channelCompilers } from '../../../../../organizations/components/outbound-notifications/utils/channel-compilers';
import {
  createHtmlToPDF,
  getProductModuleDefinitionDocuments,
} from '../../../product-modules-api';
import { ProductModuleDefinition } from '../../../../domain/product-module-definition';
import { ProductModuleDefinitionDocumentIndexStructure } from '../../product-module-documentation/orchestration';

// converting html written in code window to pdf (base64)
export const htmlToPdf = async (
  code: string,
  testMergeVars: boolean,
  mergeVars: object,
) => {
  try {
    const codeData = code || ' ';
    const mergeData = testMergeVars
      ? await MergeVarsIntoHtml(code, mergeVars)
      : codeData;

    const data = { html: mergeData || ' ' };

    const pdfRaw = await createHtmlToPDF(data);
    const blob = await pdfRaw.blob();
    const url = URL.createObjectURL(blob);

    return {
      templateHTML: code,
      templateBase64: url,
      loadingHtml: false,
      processingDocument: false,
    };
  } catch (err) {
    return {
      templateHTML: 'default',
      templateBase64: '',
      loadingHtml: false,
      processingDocument: false,
    };
  }
};

// Including merge variables in the html to pdf
export const MergeVarsIntoHtml = async (
  templateHTML: string,
  mergeVars: object,
) => {
  const templateHTMLParsed = JSON.stringify(templateHTML).replace(
    /\{\{+\}\}/g,
    '',
  );
  const response = await channelCompilers.html(
    JSON.parse(templateHTMLParsed),
    mergeVars,
  );
  return response;
};

export const establishState = async (
  productModuleDefinition: ProductModuleDefinition,
  docType: ProductModuleDefinitionDocumentIndexStructure,
) => {
  const document = productModuleDefinition[docType];
  if (document) {
    const productModuleDefinitionDocument = await getProductModuleDefinitionDocuments(
      productModuleDefinition.productModuleId,
      productModuleDefinition.productModuleDefinitionId,
      document.toString(),
    );

    let productModuleHtmlDoc = 'Default';
    if (
      productModuleDefinitionDocument &&
      productModuleDefinitionDocument.html
    ) {
      productModuleHtmlDoc = productModuleDefinitionDocument.html;
    }

    return {
      templateHTML: productModuleHtmlDoc,
      templateHtmlOriginal: productModuleHtmlDoc,
    };
  }
  return {
    templateHTML: '<div></div>',
    templateHtmlOriginal: '<div></div>',
  };
};
