import React from 'react';
import ReactDOM from 'react-dom';
import '../../../styles/resizable-column-panel.scss';

interface Props {
  children: any;
  updatingResize: (number: any) => void;
  headerComponents: any[];
}

interface State {
  isDragging: boolean;
  panels: number[];
  currentPanel: number;
  initialPos: number;
  delta: number;
}

export default class ResizableColumnPanel extends React.Component<
Props,
State
> {
  eventHandler = null;

  constructor(props: any) {
    super(props);

    this.state = {
      isDragging: false,
      panels: [300, 600],
      currentPanel: 0,
      initialPos: 0,
      delta: 0,
    };
  }
  componentDidMount() {
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode) {
      domNode.addEventListener('mousemove', this.resizePanel);
      domNode.addEventListener('mouseup', this.stopResize);
      domNode.addEventListener('mouseleave', this.stopResize);
    }
  }
  startResize = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    this.setState({
      isDragging: true,
      currentPanel: index,
      initialPos: event.clientX,
    });
  };

  stopResize = () => {
    if (this.state.isDragging) {
      this.setState(({ panels, currentPanel, delta }) => ({
        isDragging: false,
        panels: {
          ...panels,
          [currentPanel]: (panels[currentPanel] || 0) - delta,
          [currentPanel - 1]: (panels[currentPanel - 1] || 0) + delta,
        },
        delta: 0,
      }));
      this.props.updatingResize(this.state.initialPos);
    }
  };

  resizePanel = (event: any) => {
    if (this.state.isDragging) {
      const delta = event.clientX - this.state.initialPos;
      this.setState({ delta: delta });
    }
  };

  render() {
    const rest = this.props.children.slice(1);
    return (
      <div
        className='panel-container-column'
        style={{ height: '100%' }}
        onMouseUp={() => this.stopResize()}
      >
        <div
          className='panel-column'
          style={{
            width: `calc(100% - ${this.state.panels[1]}px)`,
            margin: 0,
            padding: 0,
          }}
        >
          {this.props.headerComponents[0].header}
          {this.props.children[0]}
        </div>
        {[].concat(
          ...rest.map((child: any, i: number) => {
            return [
              <div
                onMouseDown={e => this.startResize(e, i + 1)}
                key={'resizer_' + i}
                style={
                  this.state.currentPanel === i + 1
                    ? { left: this.state.delta }
                    : {}
                }
                className='resizer-column'
              ></div>,
              <div
                key={'panel_' + i}
                className='panel-column'
                style={{ width: this.state.panels[i + 1] }}
              >
                {this.state.panels[i + 1] > 200 &&
                  this.props.headerComponents[1].header}
                {child}
              </div>,
            ];
          }),
        )}
      </div>
    );
  }
}
