export const FormOptions = {
  allowListRules: true,
  allowListNotifications: true,
  showNotifications: true,
  startingCountRules: 1,
  startingCountNotifications: 1,
  maxCountRules: 5,
  maxCountNotifications: 2,
};

export const waitingPeriodNotificationsSelectOptions = {
  selectOption1: {
    label: 'On the waiting period expiry date',
    value: 'on_the_waiting_period_expiry_date',
  },
  selectOption2: {
    label: 'Before the waiting period expires',
    value: 'before_the_waiting_period_expires',
  },
};

export const WaitingPeriodRulesConfig = {
  to: {
    selectWaitingPeriodRules: [
      {
        label: 'The full policy',
        value: 'the_full_policy',
      },
    ],
    outputPaths: 'applyTo',
  },
  applyWaitingPeriodOf: {
    selectWaitingPeriodRules: {
      type: [
        {
          label: 'Days',
          value: 'days',
        },
        {
          label: 'Months',
          value: 'months',
        },
      ],
    },
    outputPaths: {
      type: 'period.type',
      value: 'period.value',
    },
  },
};

export const WaitingPeriodNotificationsConfig = {
  notifyThePolicyholder: {
    selectWaitingPeriodNotifications: {
      trigger: [
        {
          label: waitingPeriodNotificationsSelectOptions.selectOption2.label,
          value: waitingPeriodNotificationsSelectOptions.selectOption2.value,
        },
        {
          label: waitingPeriodNotificationsSelectOptions.selectOption1.label,
          value: waitingPeriodNotificationsSelectOptions.selectOption1.value,
        },
      ],
      type: [
        {
          label: 'Days',
          value: 'days',
        },
        {
          label: 'Months',
          value: 'months',
        },
      ],
    },
    outputPaths: {
      trigger: 'trigger',
      value: 'period.value',
      type: 'period.type',
    },
    options: {
      form: {
        allowList: true,
      },
      selectWaitingPeriodNotifications: {
        reducing: true,
      },
      metric: {
        reducing: false,
      },
    },
  },
};
