import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Alert,
  Row,
  Col,
  Button,
} from 'reactstrap';
import moment from 'moment';

export default class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // const url = process.env.REACT_APP_COLLECT_HOST + '/organizations';
    // const organizations = await get(url);
    // TODO do proper fetching

    const organization = {
      organizationId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
      userId: '66a48342-9d30-11e7-9eda-0315c970dabe',
      productName: 'Hero Life',
      productDescription:
        'Hero Financial Services - Taking back control of your finances.',
      productIcon:
        'https://s3-eu-west-1.amazonaws.com/rootbank-production/client-app-icons/hero-0044cb2a-a9bf-11e7-b01a-af0365aad91b/client-app-icon-Thursday_2018_10_19__12-16-51-505-jpeg',
      productWebsite: 'https://herolife.co.za',
      reviewedAt: '2018-12-14 11:19:06.64387',
      archivedAt: null,
      createdAt: '2018-10-05 11:19:06.64387',
      productContactNumber: null,
    };

    const templateSet = {
      templateKey: 'policy_issued',
      templateName: 'Policy issued',
      templates: {
        email: {
          draft: {
            templateId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
            templateKey: 'policy_issued',
            templateName: 'Policy issued',
            templateType: 'email', // email, sms, schedule
            thumbnailUrl:
              'http://www.childcaremagazine.us/list/25203/Stripo-Fashion-Holiday-newsletter-Happy-Birthday-Special-Offer-email-web.png',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: null,
          },
          live: {
            templateId: '0044cb2a-11e7-a9bf-b01a-af0365aad91b',
            templateKey: 'policy_issued',
            templateName: 'Policy issued',
            templateType: 'email',
            thumbnailUrl:
              'https://cms-assets.tutsplus.com/uploads/users/30/posts/9386/final_image/email-buil-final.png',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: '2018-09-06 11:19:06.64387',
          },
        },
        sms: {
          draft: {
            templateId: '0044cb2a-a9bf-11e7-b01a-af0365aad91b',
            templateKey: 'policy_issued',
            templateName: 'Policy issued',
            templateType: 'sms',
            thumbnailUrl:
              'http://mediad.publicbroadcasting.net/p/shared/npr/styles/x_large/nprshared/201805/459499094.jpg',
            createdAt: '2018-09-05 11:19:06.64387',
            publishedAt: null,
          },
          live: null,
        },
      },
    };

    this.setState({ organization, templateSet });
  }

  handleEditTemplate(template) {
    const path = `/organizations/${
      this.props.params.organizationId
    }/templates/${template.templateKey}/edit/${template.templateType}`;
    this.props.router.push(path);
  }

  handlePublishTemplate(templateId) {
    console.log('Publish template', templateId);
    const confirmed = confirm(
      'Are you sure you want to publish this template?',
    ); // eslint-disable-line no-undef
    if (confirmed) {
      // TODO ping API to mark template as published (i.e. set published_at)
      // TODO update state's live version to draft's version. Alternatively, refresh page
    }
  }

  handleDisableTemplate(templateId) {
    console.log('Disable template', templateId);
    const confirmed = confirm(
      'Are you sure you want to disable this template?',
    ); // eslint-disable-line no-undef
    if (confirmed) {
      // TODO ping API to disable that specific template (could be SMS or email or document)
      // TODO on success update state to indicate it as disabled
    }
  }

  renderTemplateControlCard(title, live, draft) {
    return (
      <Card>
        <CardHeader>{title}</CardHeader>
        <CardBody>
          <Row>
            <Col sm='2'>
              <div className='template-thumbnail-container'>
                <img src={draft.thumbnailUrl} alt='Draft template' />
                Draft
              </div>
            </Col>
            <Col>
              <Button onClick={this.handleEditTemplate.bind(this, draft)}>
                Edit
              </Button>{' '}
              <Button
                onClick={this.handlePublishTemplate.bind(
                  this,
                  draft.templateId,
                )}
              >
                Publish
              </Button>
              <p>
                <i>
                  <small>
                    Last modified: {moment(draft.createdAt).fromNow()}
                  </small>
                </i>
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm='2'>
              <div className='template-thumbnail-container'>
                {!live ? (
                  <img
                    src='/images/template-disabled.png'
                    alt='Live template'
                  />
                ) : (
                  <img src={live.thumbnailUrl} alt='Live template' />
                )}
                Live
              </div>
            </Col>
            <Col>
              {!live ? (
                <i>
                  <small>
                    This template is disabled and will not fire off when the
                    event is triggered.
                    <br />
                    Publish draft to enable it.
                  </small>
                </i>
              ) : (
                <div>
                  <Button
                    color='danger'
                    onClick={this.handleDisableTemplate.bind(
                      this,
                      live.templateId,
                    )}
                  >
                    Disable
                  </Button>
                  <p>
                    <i>
                      <small>
                        Published: {moment(live.publishedAt).fromNow()}
                      </small>
                    </i>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  render() {
    if (!this.state.templateSet) return null;
    return (
      <div>
        <Alert color='danger'>⚠️ This page is still under construction!</Alert>

        <Card>
          <CardBody>
            <p>
              <b>Template name:</b> {this.state.templateSet.templateName}
            </p>
            <p>
              <b>Template key:</b>{' '}
              <code>{this.state.templateSet.templateKey}</code>
            </p>
          </CardBody>
        </Card>

        {this.renderTemplateControlCard(
          'Email Template',
          this.state.templateSet.templates.email.live,
          this.state.templateSet.templates.email.draft,
        )}
        {this.renderTemplateControlCard(
          'SMS Template',
          this.state.templateSet.templates.sms.live,
          this.state.templateSet.templates.sms.draft,
        )}
      </div>
    );
  }
}
