/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='100px'
        height='80px'
        viewBox='0 0 100 80'
        enableBackground='new 0 0 100 80'
        xmlSpace='preserve'
      >
        <circle
          fill='none'
          stroke='#CED5D9'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeDasharray='4,6'
          cx='48.258'
          cy='33.013'
          r='30.5'
        />
        <path
          fill='#CED5D9'
          d='M48.258,57.815c7.038,0,13.429-2.752,18.171-7.233c-0.414-1.372-1.468-3.107-4.124-4.035
      c-3.083-1.08-6.604-1.557-8.888-2.788c-1.252-0.672-1.404-2.36-0.302-3.252c1.887-1.528,2.928-3.951,3.488-6.411
      c1.057-0.696,3.67-5.123,1.121-5.794c0.523-2.378,0.062-4.975-0.252-6.295c1.569-2.888,0.376-5.328-0.243-6.273
      c-0.118-0.19-0.401-0.168-0.505,0.027c-2.019,3.789-9.793-1.494-14.912,2.546c-4.556,3.6-3.538,8.57-3.106,10.042
      c-1.948,0.793,0.223,5.03,1.204,5.734c0.559,2.468,1.599,4.892,3.488,6.424c1.103,0.892,0.95,2.58-0.306,3.252
      c-2.283,1.231-5.798,1.708-8.882,2.788c-2.659,0.928-3.712,2.663-4.124,4.035C34.828,55.063,41.22,57.815,48.258,57.815z'
        />
      </svg>
    );
  }
}
