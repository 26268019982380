import { observable, computed } from 'mobx';
import { DataExportApi } from '../data-export-api';
import { DataExportRunLogItem } from '../domain/data-export-run-log-item';

export class DataExportRunLogStore {
  @observable dataExportRunLogItems: DataExportRunLogItem[] = [];
  @observable isLoading = true;

  load = async (params: { organizationId: string; dataExportRunId: string }) => {
    const { organizationId, dataExportRunId } = params;
    this.isLoading = true;
    this.dataExportRunLogItems = [];
    try {
      this.dataExportRunLogItems = await DataExportApi.logItems({
        organizationId,
        dataExportRunId,
      });
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !this.isLoading && this.dataExportRunLogItems.length === 0;
  }

  setDataExportRunLogListStoreLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const dataExportRunLogStore = new DataExportRunLogStore();
