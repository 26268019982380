import React from 'react';
import { Media } from 'reactstrap';

export default class Loading extends React.Component {
  render() {
    return (
      <div className='full-loading'>
        <div className='loader'>
          <Media object src='/images/lego-alfred-full.png' alt='Alfred Logo' />
        </div>
      </div>
    );
  }
}
