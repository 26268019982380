import React from 'react';
import ProductModuleDefinitionWelcomeLetter from './product-module-definition-welcome-letter';
import ProductModuleDefinitionPolicySchedule from './product-module-definition-policy-schedule';
import ProductModuleDefinitionMemberCertificate from './product-module-definition-member-certificate';
import ProductModuleDefinitionTermsFile from './product-module-definition-terms-file';
import ProductModuleDefinitionDocumentLabels from './product-module-definition-labels';
import ProductModuleDefinitionPolicyQuoteSummary from './product-module-definition-quote-summary';
import ProductModuleDefinitionApiDocumentation from './product-module-definition-api-documentation';
import ProductModuleDefinitionPolicyAnniversary from './product-module-definition-policy-anniversary';

interface Props {
  productModuleKey: string;
}

export default class ProductModuleDefinitionDocuments extends React.Component<
Props,
any
> {
  render() {
    return (
      <div>
        <ProductModuleDefinitionWelcomeLetter
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionPolicySchedule
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionMemberCertificate
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionPolicyAnniversary
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionTermsFile
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionPolicyQuoteSummary
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionApiDocumentation
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionDocumentLabels
          productModuleKey={this.props.productModuleKey}
        />
      </div>
    );
  }
}
