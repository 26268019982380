import { LifecycleStoreOutput } from './store';

export interface WaitingPeriodRulesOutput {
  [k: string]: {
    apply_to: string;
    period: {
      type: string;
      value: number;
    };
  };
}

export interface WaitingPeriodNotificationsOutput {
  [k: string]: {
    trigger: string;
    period?: {
      type: string;
      value: number;
    };
  };
}

export interface CoolingOffPeriodOutput {
  [k: string]: {
    apply_to: string;
    period?: {
      type: string;
      value: number;
    };
    refund_type: string;
  };
}

export interface GracePeriodOutput {
  [k: string]: {
    lapse_after: string;
    skipped_payments?: number;
    period?: {
      type: string;
      value: number;
    };
  };
}

export interface ProductModuleJson {
  id: string;
  json: LifecycleStoreFromDb;
  created_at: string;
  created_by: string;
}

export interface ModalContent {
  title: string;
  body: string;
  submit: string;
}

export interface LifecycleStoreFromDb {
  waitingPeriodRules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  waitingPeriodNotifications: [
    {
      trigger: WaitingPeriodNotificationTriggers;
      period?: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  coolingOffPeriodRules: [
    {
      applyTo: ApplyRulesTo;
      period?: {
        type: PeriodOf;
        value: number;
      };
      refundType: CoolingOffPeriodOptions;
    },
  ];
  gracePeriodRules: [
    {
      lapseAfter: GracePeriodOptions;
      skippedPayments?: number;
      period?: {
        type: PeriodOf;
        value: number;
      };
    },
  ];

  renewalRules: [];
  premiumEscalationRules: [];
  expiryRules: [];
}

export enum GracePeriodOptions {
  ConsecutiveSkippedPayments = 'consecutive_skipped_payments',
  SkippedPaymentsOverPolicyTerm = 'skipped_payments_over_policy_term',
  SkippedPaymentsWithinPeriod = 'skipped_payments_within_period',
  PeriodAfterMissedPayment = 'period_after_missed_payment',
}

export enum WaitingPeriodNotificationTriggers {
  OnTheWaitingPeriodExpiryDate = 'on_the_waiting_period_expiry_date',
  BeforeTheWaitingPeriodExpires = 'before_the_waiting_period_expires',
}

export enum CoolingOffPeriodOptions {
  AllPremiums = 'all_premiums',
  None = 'none',
}

export enum PeriodOf {
  Days = 'days',
  Months = 'months',
}

export enum ApplyRulesTo {
  Policy = 'the_full_policy',
}

export interface ProductModuleDefinitionSettings {
  canIssuePolicyThroughDashboard: boolean;
  policySchemeType: PolicySchemeType;
  policyholder: {
    entityTypes: PolicyholderEntityType[];
    idTypes: IdentificationType[];
  };
  beneficiaries: {
    enabled: boolean;
    min: number;
    max: number;
  };
  covered_items: {
    enabled: boolean;
    maxSumAssured: number;
  };
  billing: {
    paymentMethodTypes: PaymentMethodType[];
    currency: Currency;
    premiumType: PolicyBillingFrequency;
    paymentReference: string;
    proRataBillingOnIssue?: boolean;
    proRataBillingDisabled?: boolean;
    proRataMinimum: number;
    retries: {
      retryFailedPayments: number;
      daysBetweenFailedPaymentRetries: number;
    };
    billBeforeWeekend: boolean;
    naedoArrearsPolicies: boolean;
    enableBillingOnSandbox: boolean;
  };
  lifecycle: LifecycleStoreOutput;
  claims: {
    claimChecklistItems: string[];
  };
}

export interface CoolingOffPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
      refundType: CoolingOffPeriodOptions;
    },
  ];
}

export interface GracePeriodPeriodConfig {
  rules: [
    {
      lapseAfter: GracePeriodOptions;
      skippedPayments?: number;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: GracePeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export interface WaitingPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: WaitingPeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export enum GracePeriodNotificationTriggers {
  BeforeTheGracePeriodExpires = 'before_the_grace_period_expires',
  OnTheGracePeriodExpiryDate = 'on_the_grace_period_expiry_date',
}

export enum PolicySchemeType {
  Individual = 'individual',
  Group = 'group',
}

export enum PolicyholderEntityType {
  Individual = 'individual',
  Company = 'company',
}

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
}

export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
}

export enum PaymentMethodType {
  DebitOrder = 'debit_order',
  Card = 'card',
  Eft = 'eft',
  External = 'external',
}

export enum Currency {
  ZAR = 'ZAR',
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  KRW = 'KRW',
  JPY = 'JPY',
  CNY = 'CNY',
  INR = 'INR',
}

export enum PolicyBillingFrequency {
  Monthly = 'monthly',
  OnceOff = 'once_off',
}
