import React from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { toCamelCaseKeys } from '../../../helpers';
import { PaymentAlertsTable } from './payment-alerts-table';
import { ajax } from '../../../helpers';
import { PaginationComponent } from '../../../components/pagination';
import { RouteComponentProps } from 'react-router';

export interface Alert {
  paymentAlertId: string;
  environment: string;
  type: string;
  description: string;
  policyId?: string;
  paymentId?: string;
  paymentBatchId?: string;
  resolvedAt: object;
  resolvedBy: string;
  policy: object;
}

interface Props {
  router: RouteComponentProps;
}

interface State {
  alerts: Alert[];
  loading: boolean;
  page: number;
  pageSize: number;
  total: number;
}

export class PaymentAlertsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      alerts: [],
      loading: true,
      page: 0,
      pageSize: 10,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchPaymentAlerts();
  }

  componentWillReceiveProps() {
    this.fetchPaymentAlerts();
  }

  get status() {
    return this.props.router.location.pathname
      .split('/')
      .splice(-1)
      .pop();
  }

  async fetchPaymentAlerts() {
    this.setState({ loading: true });
    const status = this.status;
    const statusQuery = status === 'resolved';
    const queryString = `?resolved=${statusQuery}&include=policy`;

    const total = await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payment-alerts${queryString}`,
      raw: true,
    });

    const pagination = `&page=${this.state.page + 1}&page_size=${
      this.state.pageSize
    }`;

    const alerts = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payment-alerts${queryString}${pagination}`,
    })).map(toCamelCaseKeys);

    this.setState({
      alerts,
      loading: false,
      total: total.headers.get('X-Total-Count'),
    });
  }

  resolveAllAlerts = async () => {
    const { alerts } = this.state;

    await Promise.all(
      alerts.map(alert => this.resolveAlert(alert.paymentAlertId)),
    );
  };

  resolveAlert = async (paymentAlertId: string) => {
    await ajax({
      type: 'POST',
      path: `/insurance/admin/payment-alerts/${paymentAlertId}/resolve`,
    });

    await this.fetchPaymentAlerts();
  };

  render() {
    const { alerts, loading, page, pageSize, total } = this.state;

    return (
      <div>
        <div className='bottom-padded'>
          <Card>
            <CardHeader>
              Payment alerts
              {this.status === 'unresolved' && (
                <div className='pull-right'>
                  <Button
                    color='primary'
                    onClick={() => this.resolveAllAlerts()}
                    disabled={loading}
                  >
                    Resolve all alerts
                  </Button>
                </div>
              )}
            </CardHeader>
            <CardBody>
              {loading ? (
                <div>Loading...</div>
              ) : alerts && alerts.length > 0 ? (
                <div>
                  <PaymentAlertsTable
                    alerts={alerts}
                    resolved={this.status === 'resolved'}
                    resolveAlert={this.resolveAlert}/>
                  <PaginationComponent
                    page={page || 0}
                    limit={pageSize}
                    total={total || 0}
                    disabled={false}
                    goToPage={page => {
                      this.setState(
                        {
                          page,
                        },
                        async () => await this.fetchPaymentAlerts(),
                      );
                    }}
                  />
                </div>
              ) : (
                <div>{`No ${this.status} payment alerts`}</div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
