import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h16v16H0z' />
          <path
            fill='#3D3D3D'
            d='M6 7h4V6a2 2 0 1 0-4 0v1zM4 7V6a4 4 0 1 1 8 0v1a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1z'
          />
        </g>
      </svg>
    );
  }
}
