import React, { Component } from 'react';
import {
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ModalFooter,
  ModalHeader,
  Alert,
} from 'reactstrap';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-night.css';
import { FullScreenModal } from '../../../../components/modals/full-screen';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import { GeneralModal } from '../modals';
import {
  ProductModuleDefinitionDocumentIndexStructure,
} from './orchestration';
import CodeWindow from '../../../utils/code-editor/code-editor';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../stores/product-module-store';
import '../../../styles/styles.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { debounce } from '../../../../helpers/debounce';
import * as yaml from 'js-yaml';
import { getProductModuleDefinitionSchema } from '../../product-modules-api';

interface Props extends RouteComponentProps {
  docType: ProductModuleDefinitionDocumentIndexStructure;
  toDbKey: string;
  productModuleKey: string;
  breadcrumbDocName: string;
  closeDocument: () => any;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  params: {
    productModuleKey: string;
  };
}
interface ProductModuleDocumentationImpl {
  modalContent: {
    title: string;
    body: string;
    submit: string;
  };
}

interface State {
  showSaveModal: boolean;
  yaml: string;
  isLoading: boolean;
}

@inject('productModuleStore')
@observer
class ProductModuleDocumentationImpl extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSaveModal: false,
      yaml: '',
      isLoading: true,
    };

    this.modalContent = {
      title: 'Schema Saved',
      body: 'Your schema has successfully been saved',
      submit: 'Ok',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { productModuleStore, params } = this.injected;

    this.setState({ isLoading: true }, async () => {
      try {
        await productModuleStore.init(params.productModuleKey);

        const productModuleDocumentationJSON = await getProductModuleDefinitionSchema(
          productModuleStore.productModuleDefinitionDraft.productModuleId,
          productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
          productModuleStore.productModuleDefinitionDraft.documentationId,
        );

        const yamlContent = yaml.dump(productModuleDocumentationJSON.jsonContent);
        this.setState({ yaml: yamlContent, isLoading: false });
      } catch (error) {
        console.error(`Could not convert yaml to json for productModuleId: ${productModuleStore.productModuleDefinitionDraft.productModuleId}`);
        this.setState({ yaml: '', isLoading: false });
      }
    });
  }

  saveDraftMethod = async () => {
    const { productModuleStore } = this.injected;
    try {
      const yamlJSON = yaml.safeLoad(this.state.yaml);

      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: {
          documentation_json: yamlJSON,
        },
      });

      this.setState({ showSaveModal: true });
    } catch (error) {
      productModuleStore.updateErrors({
        errors: [
          {
            path: error.name,
            message: error.message,
          },
        ],
      });
    }
  };

  handleCodeChange = async (yaml: string) => {
    debounce('api-document-changed', () => {
      this.setState({ yaml });
    });
  };

  render() {
    const { productModuleStore, productModuleKey } = this.injected;
    const lockedComponent = productModuleStore.lockedComponent;

    return (
      <FullScreenModal isOpen>
        <GeneralModal
          show={this.state.showSaveModal}
          submitModal={() => this.setState({ showSaveModal: false })}
          modalContent={this.modalContent}
        />
        <ModalHeader style={{ paddingBottom: 19 }}>
          <div className='Rectangle' style={{ display: 'inline-block', paddingTop: 9 }}>
            <a onClick={this.props.closeDocument}><Icon24PXCross /></a>
          </div>
          <div style={{ display: 'inline-block', paddingTop: 17 }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href='/product-modules'>
                  Product modules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href={`/product-modules/${productModuleKey}`}>
                  {productModuleStore.productModule && productModuleStore.productModule.name}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Documentation</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </ModalHeader>
        <div className='documentation-error-wrapper'>
          {productModuleStore.errors.map((error, index) =>
            <Alert
              key={`errorMessage-${index}`}
              color='danger'>{`Message: ${error.message} path: ${error.path}`}
            </Alert>)}
        </div>
        <ModalBody className='no-padding' style={{ padding: 0 }}>
          <div className='template-editor' onKeyDown={(event) => {
            if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
              event.preventDefault();
              this.saveDraftMethod();
            }
          }}>
            {!this.state.isLoading && (
              <CodeWindow
                width={'100%'}
                defaultValue={this.state.yaml}
                language='yaml'
                handleCodeChange={(code: string) =>
                  this.handleCodeChange(code)
                }
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='right-content'>
            <Button
              disabled={lockedComponent}
              color='secondary'
              onClick={() => this.saveDraftMethod()}>
              Save draft
            </Button>
          </div>
        </ModalFooter>
      </FullScreenModal>
    );
  }
}

export const ProductModuleDocumentation = withRouter(ProductModuleDocumentationImpl);
