import { observable, action } from 'mobx';
import { DataExportApi } from '../data-export-api';
import { ScheduledDataExport } from '../domain/scheduled-data-export';
import { DetailsData } from '../views/modals/new-export-modal';
import { Moment } from 'moment';

export class DataExportViewStore {
  @observable isLoading = true;
  @observable dataExport?: ScheduledDataExport;
  @observable error: string = '';

  load = async (params: { organizationId: string; scheduledDataExportId: string }) => {
    const { organizationId, scheduledDataExportId } = params;
    this.isLoading = true;

    try {
      this.dataExport = await DataExportApi.single({
        organizationId,
        scheduledDataExportId,
      });
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @action pause = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    if (this.dataExport) {
      this.isLoading = true;
      try {
        this.dataExport = await DataExportApi.pause({
          organizationId,
          scheduledDataExportId: this.dataExport.scheduledDataExportId,
        });
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action resume = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    if (this.dataExport) {
      this.isLoading = true;
      try {
        this.dataExport = await DataExportApi.resume({
          organizationId,
          scheduledDataExportId: this.dataExport.scheduledDataExportId,
        });
      } catch (error) {
        this.error = error;
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action archive = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    if (this.dataExport) {
      this.isLoading = true;
      try {
        await DataExportApi.archive({
          organizationId,
          scheduledDataExportId: this.dataExport.scheduledDataExportId,
        });
        this.dataExport = undefined;
      } catch (error) {
        this.error = error;
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action trigger = async (params: { organizationId: string; fromExcl?: Moment; toIncl?: Moment }) => {
    const { fromExcl, organizationId, toIncl } = params;
    if (this.dataExport) {
      this.isLoading = true;
      try {
        this.dataExport = await DataExportApi.trigger({
          organizationId,
          scheduledDataExportId: this.dataExport.scheduledDataExportId,
          fromExcl: fromExcl ? fromExcl.toISOString() : undefined,
          toIncl: toIncl ? toIncl.toISOString() : undefined,
        });
      } catch (error) {
        this.error = error;
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action update = async (params: { organizationId: string; payload: DetailsData }) => {
    const { organizationId, payload } = params;
    if (this.dataExport) {
      this.isLoading = true;
      try {
        this.dataExport = await DataExportApi.update({
          organizationId,
          scheduledDataExportId: this.dataExport.scheduledDataExportId,
          payload,
        });
      } catch (error) {
        this.error = error;
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  setDataExportViewStoreLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const dataExportViewStore = new DataExportViewStore();
