import React from 'react';
import { Card, CardBody, Badge } from 'reactstrap';
import ToggleSwitch from './toggle-switch';

export const ConfigToggle = props => {
  const content = (
    <div>
      <div className='heading'>
        <strong>{props.title}</strong>
        {!props.noToggleSwitch &&
          (!props.toggleSwitchProps.disabled ? (
            <ToggleSwitch {...props.toggleSwitchProps} />
          ) : (
            <Badge
              color={props.toggleSwitchProps.checked ? 'success' : 'secondary'}
              pill
            >
              <div style={{ marginTop: 1 }}>
                {props.toggleSwitchProps.checked ? 'ON' : 'OFF'}
              </div>
            </Badge>
          ))}
      </div>
      <div>
        {props.subTitle && <div style={{ marginTop: 8 }}>{props.subTitle}</div>}
      </div>
    </div>
  );

  if (props.noCardWrapper) return content;

  return (
    <Card>
      <CardBody className='horizontal' style={{ flexDirection: 'column' }}>
        {content}
      </CardBody>
    </Card>
  );
};
