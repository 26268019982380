import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { DetailsForm } from './forms/details-form';
import { DestinationForm } from './forms/destination-form';
import {
  isWeeklyFormData,
  isMonthlyFormData,
} from './forms/frequency-form';

import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { TemplateListStore } from '../../stores/template-list-store';
import { DataExportListStore } from '../../stores/data-export-list-store';
import { DataExportViewStore } from '../../stores/data-export-view-store';
import { ScheduledDataExportAdapter } from '../../domain/scheduled-data-export-adapter';
import { Weekly, Monthly, Daily, ScheduledDataExportFrequencyType } from '../../domain/scheduled-data-export-frequency';
import { Environment } from '../../../../../shared/domain/environment';
import { ExportType } from '../../domain/export-type';
import { DataExportDataRange } from '../../domain/data-export-data-range';

export interface ExportData {
  adapter: ScheduledDataExportAdapter;
}

export interface FrequencyData {
  timeOfDay: any;
  dayOfWeek: any;
  dayOfMonth: any;
}

export interface DetailsData {
  environment: Environment;
  name: string;
  exportType: ExportType;
  dataRange: DataExportDataRange;
  templateId?: string;
  restricted: boolean;
  frequency: Weekly | Daily | Monthly;
  frequencyType?: ScheduledDataExportFrequencyType;
  adapter: ScheduledDataExportAdapter;
}

interface FormDetailsData {
  environment: Environment;
  name: string;
  exportType: ExportType;
  dataRange: DataExportDataRange;
  templateId?: string;
  restricted: boolean;
  frequency?: Weekly | Daily | Monthly;
  frequencyType?: ScheduledDataExportFrequencyType;
  adapter?: ScheduledDataExportAdapter;
}

interface Props {
  close: () => void;
  editing: boolean;
  exportData?: ExportData;
  fromTemplate?: boolean;
}
interface Injected extends Props {
  templateListStore: TemplateListStore;
  dataExportListStore: DataExportListStore;
  dataExportViewStore: DataExportViewStore;
}

interface State {
  loading: boolean;
  touched: boolean;
  editing: boolean;
  detailsValid: boolean;
  frequencyValid: boolean;
  adapterValid: boolean;
  detailsData: FormDetailsData;
  frequencyData: FrequencyData;
  adapter?: ScheduledDataExportAdapter;
}

@inject(
  'templateListStore',
  'dataExportListStore',
  'dataExportViewStore',
)
@observer
export class NewExportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      touched: false,
      editing: false,
      detailsValid: props.exportData ? true : false,
      frequencyValid: props.exportData ? true : false,
      adapterValid: props.exportData ? true : false,
      detailsData: {
        environment: Environment.Sandbox,
        exportType: ExportType.PolicyExport,
        dataRange: DataExportDataRange.Today,
        name: '',
        restricted: true,
      },
      frequencyData: {
        timeOfDay: '',
        dayOfWeek: '',
        dayOfMonth: '',
      },
      adapter: this.props.exportData ? this.props.exportData.adapter : undefined,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  getValid = () => {
    return this.state.detailsValid;
  }

  closeBtn = (
    <a href='#' onClick={() => this.props.close()}>
      <Icon24PXCross />
    </a>
  );

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    return (
      <Modal
        isOpen={true}
        centered={true}
        className='full-screen-modal'
      >
        <ModalHeader className='exports-modal-header' close={this.closeBtn}>
            New export
        </ModalHeader>
        <ModalBody style={{ overflow: 'auto' }}>
          <DetailsForm
            autoFocus
            detailsData={this.props.exportData as any}
            changed={this.onDetailsChanged}
            frequencyChanged={this.onFrequencyChanged}
            initialized={this.onDetailsChanged}
            touched={this.state.touched}
            fromTemplate={this.props.fromTemplate}
          />

          <DestinationForm
            autoFocus
            adapter={this.props.exportData && this.props.exportData.adapter}
            changed={this.onDestinationChanged}
            initialized={this.onDestinationChanged}
            touched={this.state.touched}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <Button
            color='link'
            disabled={this.state.loading}
            onClick={this.props.close}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={
              !this.props.exportData &&
              (this.state.frequencyValid === false ||
              this.state.detailsValid === false ||
              this.state.adapterValid === false)
            }
            onClick={this.submit}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  onDetailsChanged = (valid: boolean, detailsData: DetailsData) => {
    this.setState({ detailsValid: valid, detailsData: detailsData });
  };

  onFrequencyChanged = (valid: boolean, frequencyData: FrequencyData) => {
    this.setState({ frequencyValid: valid, frequencyData: frequencyData });
  };

  onDestinationChanged = (valid: boolean, adapter: ScheduledDataExportAdapter) => {
    this.setState({
      adapterValid: valid,
      adapter,
    });
  };

  submit = async () => {
    const { dataExportListStore, dataExportViewStore } = this.injected;

    const {
      adapterValid,
      adapter,
      detailsValid,
      detailsData,
      frequencyValid,
      frequencyData,
    } = this.state;

    if (
      (adapterValid &&
      adapter &&
      detailsValid &&
      detailsData &&
      frequencyValid &&
      frequencyData) || this.props.editing
    ) {
      this.setState({ loading: true });
      let frequency;
      const timeOfDay = frequencyData.timeOfDay;

      if (isWeeklyFormData(frequencyData)) {
        frequency = new Weekly({
          timeOfDay,
          dayOfWeek: frequencyData.dayOfWeek,
        });
      } else if (isMonthlyFormData(frequencyData)) {
        frequency = new Monthly({
          timeOfDay,
          dayOfMonth: frequencyData.dayOfMonth,
        });
      } else {
        frequency = new Daily({ timeOfDay });
      }

      if (!adapter) {
        return;
      }

      if (!this.props.editing) {
        const dataExport = await dataExportListStore.create({ organizationId: this.organizationId, payload: {
          environment: detailsData.environment,
          name: detailsData.name,
          frequency: frequency,
          exportType: detailsData.exportType,
          templateId: detailsData.templateId,
          dataRange: detailsData.dataRange,
          adapter,
          restricted: detailsData.restricted,
        }});
        this.props.close();
        window.location.pathname = `/organizations/${this.organizationId}/exports/${dataExport.scheduledDataExportId}`;
      } else {
        await dataExportViewStore.update({ organizationId: this.organizationId, payload: {
          environment: detailsData.environment,
          name: detailsData.name,
          frequency: frequency,
          exportType: detailsData.exportType,
          templateId: detailsData.templateId,
          dataRange: detailsData.dataRange,
          adapter,
          restricted: detailsData.restricted,
        } });
        this.props.close();
      }
    }
    this.setState({ touched: true });
  };
}
