/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='80px'
        height='80px'
        viewBox='0 0 80 80'
        enableBackground='new 0 0 80 80'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='
          M42.937,3.718v31.521h31.521C74.458,17.831,60.345,3.718,42.937,3.718z'
          />
          <path
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='
          M36.178,10.277c-17.519,0-31.719,14.202-31.719,31.719c0,17.52,14.2,31.721,31.719,31.721c17.521,0,31.72-14.201,31.72-31.721
          h-31.72V10.277z'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='49.242'
            y1='4.395'
            x2='49.242'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='55.544'
            y1='6.553'
            x2='55.544'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='61.849'
            y1='10.558'
            x2='61.849'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='68.154'
            y1='16.322'
            x2='68.154'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='49.242'
            y1='4.395'
            x2='49.242'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='55.544'
            y1='6.553'
            x2='55.544'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='61.849'
            y1='10.558'
            x2='61.849'
            y2='35.239'
          />
          <line
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            x1='68.154'
            y1='16.322'
            x2='68.154'
            y2='35.239'
          />
        </g>
      </svg>
    );
  }
}
