import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, FormGroup, Label, Col } from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { DataExportViewStore } from '../../stores/data-export-view-store';
import DatePicker from 'react-datepicker';
import { Moment, utc as moment } from 'moment';
import { inject, observer } from 'mobx-react';
import { buttonText } from '../../../../../shared/helpers/button-text-loading';

enum RunMode {
  Configured = 'configured',
  Custom = 'custom',
}

interface Props {
  close: () => void;
  open: boolean;
}

interface Injected extends Props {
  dataExportViewStore: DataExportViewStore;
}

interface State {
  runMode: RunMode;
  from: Moment;
  to: Moment;
}

const initialState = (): State => ({
  runMode: RunMode.Configured,
  from: moment(),
  to: moment(),
});


@inject('dataExportViewStore')
@observer
export class TriggerDataExportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = initialState();
  }

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  close = () => {
    this.setState(initialState());
    this.props.close();
  }

  render() {
    const { dataExportViewStore } = this.injected;
    return (
      <Modal isOpen={this.props.open} toggle={this.close} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={this.close} />
          <h4 className='exports-prompt-modal-header-text'>Run export now</h4>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <Form style={{ width: '100%' }}>
            <FormGroup>
              <Label>
                <strong>Data range</strong>
              </Label>
              <Col>
                <Input
                  type='select'
                  value={this.state.runMode}
                  onChange={(e) => this.setState({ runMode: e.target.value as RunMode })}
                >
                  <option value={RunMode.Configured}>
                    As configured
                  </option>
                  <option value={RunMode.Custom}>
                    Custom date range
                  </option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup hidden={this.state.runMode === RunMode.Configured}>
              <Label>
                <strong>From</strong>
              </Label>
              <DatePicker
                value={this.state.from.format('YYYY-MM-DD')}
                onChange={d => this.setState({ ...this.state, from: (d && moment(d)) || this.state.from })}
              />
            </FormGroup>
            <FormGroup hidden={this.state.runMode === RunMode.Configured}>
              <Label>
                <strong>To</strong>
              </Label>
              <DatePicker
                value={this.state.to.format('YYYY-MM-DD')}
                onChange={d => this.setState({ ...this.state, to: (d && moment(d)) || this.state.to })}
              />
            </FormGroup>
          </Form>
          <p>
            Are you sure you want to trigger a manual run for the{' '}
            <strong>
              {dataExportViewStore.dataExport && dataExportViewStore.dataExport.name || '-'}
            </strong>
            export?
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button onClick={this.triggerDataExport} color='primary'>
            {buttonText({ text: 'Run export now', loading: dataExportViewStore.isLoading })}
          </Button>
          <Button onClick={this.close} color='link'>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  triggerDataExport = async () => {
    const { dataExportViewStore } = this.injected;
    const from = this.state.runMode === RunMode.Custom ? moment(this.state.from) : undefined;
    const to = this.state.runMode === RunMode.Custom ? moment(this.state.to) : undefined;

    const result = await dataExportViewStore.trigger({ organizationId: this.organizationId, fromExcl: from, toIncl: to });

    if (result && result.error) {
      return console.warn(result.error);
    }

    this.close();
  };
}
