import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export enum PayoutRequestStatus {
  Pending = 'pending',
  Completed = 'completed',
  Rejected = 'rejected',
}

export class PayoutRequestBlockState extends BlockState {
  readonly payoutRequestId?: string = '';
  readonly status?: PayoutRequestStatus;

  constructor(init: Construct<PayoutRequestBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      payout_request_id: this.payoutRequestId,
      status: this.status,
    };
  }

  static fromJSON(init: any) {
    return new PayoutRequestBlockState({
      type: init.type,
      payoutRequestId: init.payout_request_id,
      status: init.status,
    });
  }
}
