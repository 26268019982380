import { observable, computed } from 'mobx';
import { debounce } from '../../helpers/debounce';
import { Pagination } from '../../shared/domain/pagination';
import { ProductModuleCodeRun } from '../domain/product-module-code-run';
import { getProductModuleCodeRuns, ProductModuleCodeRunFilters } from '../product-module-api';

export class ProductModuleCodeRunListStore {
  @observable productModuleId: string;
  @observable productModuleCodeRuns: ProductModuleCodeRun[] = [];
  @observable isLoading = true;
  @observable total = this.productModuleCodeRuns.length;
  @observable pagination: Pagination = { page: 0, pageSize: 10 };
  @observable filters: ProductModuleCodeRunFilters = {};

  load = async (params: {
    productModuleId: string;
    filters?: ProductModuleCodeRunFilters;
  }) => {
    const { productModuleId, filters } = params;

    this.isLoading = true;
    this.productModuleCodeRuns = [];
    this.productModuleId = productModuleId;

    const { productModuleCodeRuns, total } = await getProductModuleCodeRuns({
      productModuleId,
      page: this.pagination.page + 1,
      pageSize: this.pagination.pageSize,
      filters: filters ? filters : this.filters,
    });

    this.productModuleCodeRuns = productModuleCodeRuns;
    this.isLoading = false;
    this.total = total;
  };

  @computed get isEmpty() {
    return !this.isLoading && this.productModuleCodeRuns.length === 0;
  }

  setProductModuleCodeRunListStoreLoading = (params: { isLoading: boolean }) => {
    this.isLoading = params.isLoading;
  };

  filter = (params: { filters: ProductModuleCodeRunFilters }) => {
    const { filters } = params;
    this.pagination.page = 0;
    this.filters = filters;

    this.load({ productModuleId: this.productModuleId, filters });
  }

  clear = () => {
    this.pagination.page = 0;
    this.filters = {};

    this.load({ productModuleId: this.productModuleId });
  }

  goToPage = (pageNumber: number) => {
    this.pagination.page = pageNumber;
    debounce('go-to-page', () =>
      this.load({
        productModuleId: this.productModuleId,
      }),
    );
  };
}

export const productModuleCodeRunListStore = new ProductModuleCodeRunListStore();
