/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='209px' height='50px' viewBox='0 0 209 50' version='1.1'>
        <defs>
          <polygon
            id='path-1'
            points='0 48.5708338 0 0.33064949 48.8380367 0.33064949 48.8380367 48.5708338 7.08680769e-16 48.5708338'
          />
          <polygon
            id='path-3'
            points='49.1722222 0.33064949 49.1722222 48.5708338 0.334185556 48.5708338 0.334185556 0.33064949 49.1722222 0.33064949'
          />
          <polygon
            id='path-5'
            points='0 49.1571685 208.75 49.1571685 208.75 0.916067711 0 0.916067711'
          />
        </defs>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-28'
            transform='translate(-538.000000, -226.000000)'
          >
            <g id='Page-1' transform='translate(538.000000, 226.000000)'>
              <path
                d='M47.4557406,25.0368014 C47.4557406,37.6047812 37.1407072,47.7918647 24.4190183,47.7918647 C11.6945461,47.7918647 1.38229611,37.6047812 1.38229611,25.0368014 C1.38229611,12.469738 11.6945461,2.28173808 24.4190183,2.28173808 C37.1407072,2.28173808 47.4557406,12.469738 47.4557406,25.0368014 Z'
                id='Fill-1'
                fill='#FE6B76'
              />
              <g id='Group-5' transform='translate(0.000000, 0.586151)'>
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1' />
                </mask>
                <g id='Clip-4' />
                <path
                  d='M24.4189256,3.06070723 C12.4784256,3.06070723 2.76459222,12.6566906 2.76459222,24.4502834 C2.76459222,36.2447927 12.4784256,45.8407761 24.4189256,45.8407761 C36.3594256,45.8407761 46.0732589,36.2447927 46.0732589,24.4502834 C46.0732589,12.6566906 36.3594256,3.06070723 24.4189256,3.06070723 M24.4189256,48.5708338 C10.9540867,48.5708338 -0.000185555556,37.7504942 -0.000185555556,24.4502834 C-0.000185555556,11.1509891 10.9540867,0.33064949 24.4189256,0.33064949 C37.8837644,0.33064949 48.8380367,11.1509891 48.8380367,24.4502834 C48.8380367,37.7504942 37.8837644,48.5708338 24.4189256,48.5708338'
                  id='Fill-3'
                  fill='#FFFFFF'
                  mask='url(#mask-2)'
                />
              </g>
              <path
                d='M87.4339633,25.0368014 C87.4339633,37.6047812 77.11893,47.7918647 64.3972411,47.7918647 C51.6736967,47.7918647 41.3605189,37.6047812 41.3605189,25.0368014 C41.3605189,12.469738 51.6736967,2.28173808 64.3972411,2.28173808 C77.11893,2.28173808 87.4339633,12.469738 87.4339633,25.0368014'
                id='Fill-6'
                fill='#09DCD1'
              />
              <path
                d='M64.3972411,3.64685859 C52.4567411,3.64685859 42.7429078,13.242842 42.7429078,25.0364348 C42.7429078,36.8309441 52.4567411,46.4269275 64.3972411,46.4269275 C76.3377411,46.4269275 86.0515744,36.8309441 86.0515744,25.0364348 C86.0515744,13.242842 76.3377411,3.64685859 64.3972411,3.64685859 M64.3972411,49.1569852 C50.9324022,49.1569852 39.9790578,38.3366456 39.9790578,25.0364348 C39.9790578,11.7371405 50.9324022,0.916800859 64.3972411,0.916800859 C77.86208,0.916800859 88.8163522,11.7371405 88.8163522,25.0364348 C88.8163522,38.3366456 77.86208,49.1569852 64.3972411,49.1569852'
                id='Fill-8'
                fill='#FFFFFF'
              />
              <path
                d='M28.0245483,19.409345 C26.8972983,19.4313394 25.7774706,19.6989382 25.0547317,20.1012529 C25.3924428,19.2498854 26.1077594,18.0814317 27.5356094,17.8312452 L27.345415,16.77368 C26.4816539,16.9258081 25.817365,17.3088776 25.3070872,17.7835906 C25.2365761,17.4161004 25.1141094,17.0559418 24.9378317,16.7168611 C24.2355039,15.3742849 22.8419817,14.5577419 21.300015,14.5870678 L20.9799317,14.598065 L20.8231372,14.800597 C19.8823706,16.0130396 19.4054928,17.2978804 20.1087483,18.6450388 C20.2098761,18.8365736 21.7889539,20.0554312 23.2576261,20.2203894 C23.1945372,20.2203894 20.6505706,19.5513923 20.5874817,19.5513923 C17.793015,19.6054619 14.8278372,21.1432387 14.8797928,25.2983517 C14.954015,31.1433696 18.5510094,34.1025359 20.0929761,35.1041986 C20.490065,35.3617166 20.974365,35.4863517 21.5301039,35.4753545 C22.8020872,35.4515272 24.080565,34.708299 24.1529317,34.666143 C24.1584983,34.6624773 24.3087983,34.5717503 24.5667206,34.5671681 C24.7365039,34.5644188 24.9164928,34.5992433 25.1001928,34.6734745 C25.3024483,34.7788644 26.518765,35.3800453 27.7063206,35.3571344 L27.7072483,35.3571344 C28.2629872,35.3470537 28.739865,35.202257 29.1258206,34.9309925 C30.6297483,33.8715945 34.1079872,30.7767959 33.9521206,24.9336109 C33.8426428,20.7784978 30.8171594,19.3561918 28.0245483,19.409345'
                id='Fill-10'
                fill='#FFFFFF'
              />
              <path
                d='M127.411815,25.0368014 C127.411815,37.6047812 117.096782,47.7918647 104.375093,47.7918647 C91.6515483,47.7918647 81.3383706,37.6047812 81.3383706,25.0368014 C81.3383706,12.469738 91.6515483,2.28173808 104.375093,2.28173808 C117.096782,2.28173808 127.411815,12.469738 127.411815,25.0368014'
                id='Fill-12'
                fill='#5E97FC'
              />
              <path
                d='M104.375,3.64685859 C92.4345,3.64685859 82.7206667,13.242842 82.7206667,25.0364348 C82.7206667,36.8309441 92.4345,46.4269275 104.375,46.4269275 C116.3155,46.4269275 126.029333,36.8309441 126.029333,25.0364348 C126.029333,13.242842 116.3155,3.64685859 104.375,3.64685859 M104.375,49.1569852 C90.9101611,49.1569852 79.9558889,38.3366456 79.9558889,25.0364348 C79.9558889,11.7371405 90.9101611,0.916800859 104.375,0.916800859 C117.839839,0.916800859 128.794111,11.7371405 128.794111,25.0364348 C128.794111,38.3366456 117.839839,49.1569852 104.375,49.1569852'
                id='Fill-14'
                fill='#FFFFFF'
              />
              <path
                d='M167.38911,25.0368014 C167.38911,37.6047812 157.075004,47.7918647 144.352388,47.7918647 C131.628843,47.7918647 121.315666,37.6047812 121.315666,25.0368014 C121.315666,12.469738 131.628843,2.28173808 144.352388,2.28173808 C157.075004,2.28173808 167.38911,12.469738 167.38911,25.0368014'
                id='Fill-16'
                fill='#FBC667'
              />
              <path
                d='M144.352759,3.64685859 C132.412259,3.64685859 122.698426,13.242842 122.698426,25.0364348 C122.698426,36.8309441 132.412259,46.4269275 144.352759,46.4269275 C156.293259,46.4269275 166.007092,36.8309441 166.007092,25.0364348 C166.007092,13.242842 156.293259,3.64685859 144.352759,3.64685859 M144.352759,49.1569852 C130.88792,49.1569852 119.933648,38.3366456 119.933648,25.0364348 C119.933648,11.7371405 130.88792,0.916800859 144.352759,0.916800859 C157.817598,0.916800859 168.770942,11.7371405 168.770942,25.0364348 C168.770942,38.3366456 157.817598,49.1569852 144.352759,49.1569852'
                id='Fill-18'
                fill='#FFFFFF'
              />
              <path
                d='M207.367889,25.0368014 C207.367889,37.6047812 197.052856,47.7918647 184.331167,47.7918647 C171.607623,47.7918647 161.294445,37.6047812 161.294445,25.0368014 C161.294445,12.469738 171.607623,2.28173808 184.331167,2.28173808 C197.052856,2.28173808 207.367889,12.469738 207.367889,25.0368014'
                id='Fill-20'
                fill='#E6E7E8'
              />
              <g id='Group-24' transform='translate(159.577778, 0.586151)'>
                <mask id='mask-4' fill='white'>
                  <use xlinkHref='#path-3' />
                </mask>
                <g id='Clip-23' />
                <path
                  d='M24.7532967,3.06070723 C12.8127967,3.06070723 3.09896333,12.6566906 3.09896333,24.4502834 C3.09896333,36.2447927 12.8127967,45.8407761 24.7532967,45.8407761 C36.6937967,45.8407761 46.40763,36.2447927 46.40763,24.4502834 C46.40763,12.6566906 36.6937967,3.06070723 24.7532967,3.06070723 M24.7532967,48.5708338 C11.2884578,48.5708338 0.334185556,37.7504942 0.334185556,24.4502834 C0.334185556,11.1509891 11.2884578,0.33064949 24.7532967,0.33064949 C38.2181356,0.33064949 49.1724078,11.1509891 49.1724078,24.4502834 C49.1724078,37.7504942 38.2181356,48.5708338 24.7532967,48.5708338'
                  id='Fill-22'
                  fill='#FFFFFF'
                  mask='url(#mask-4)'
                />
              </g>
              <path
                d='M184.331074,34.0820994 C183.567513,34.0820994 182.948686,33.4708378 182.948686,32.7166123 L182.948686,17.3571737 C182.948686,16.6029483 183.567513,15.9916866 184.331074,15.9916866 C185.094636,15.9916866 185.713463,16.6029483 185.713463,17.3571737 L185.713463,32.7166123 C185.713463,33.4708378 185.094636,34.0820994 184.331074,34.0820994'
                id='Fill-25'
                fill='#FFFFFF'
              />
              <path
                d='M192.106131,26.4020135 L176.556575,26.4020135 C175.793014,26.4020135 175.174186,25.7907519 175.174186,25.0365265 C175.174186,24.282301 175.793014,23.6719558 176.556575,23.6719558 L192.106131,23.6719558 C192.869692,23.6719558 193.488519,24.282301 193.488519,25.0365265 C193.488519,25.7907519 192.869692,26.4020135 192.106131,26.4020135'
                id='Fill-27'
                fill='#FFFFFF'
              />
              <path
                d='M72.9696294,22.6190645 C74.5848906,21.0208031 74.5848906,18.4217954 72.9696294,16.8253669 C71.3506572,15.2271055 68.7185517,15.2289384 67.1032906,16.8253669 L65.3859739,18.5226032 L71.2504572,24.3163007 L72.9696294,22.6190645 Z'
                id='Fill-29'
                fill='#FFFFFF'
              />
              <path
                d='M55.9584528,27.8334839 C55.8257806,27.9654504 55.7413528,28.1377401 55.7200139,28.3219434 L55.1336583,33.5373709 C55.1076806,33.7848081 55.1948917,34.0294961 55.3720972,34.2063679 C55.5288917,34.3621617 55.7394972,34.4464737 55.9584528,34.4464737 C55.9890694,34.4464737 56.0196861,34.4446408 56.0512306,34.4418915 L61.3302861,33.8627051 C61.5167694,33.8416271 61.6902639,33.7582315 61.8247917,33.6280979 L70.077375,25.474582 L64.2128917,19.6808845 L55.9584528,27.8334839 Z'
                id='Fill-31'
                fill='#FFFFFF'
              />
              <path
                d='M112.686033,16.0227538 L96.0259278,16.0227538 C95.59915,16.0227538 95.2503056,16.3288428 95.2503056,16.751319 L95.2503056,29.2276554 C95.2503056,29.6098085 95.59915,29.9543878 96.0259278,29.9543878 L112.724072,29.9543878 C113.15085,29.9543878 113.499694,29.6492151 113.499694,29.2276554 L113.499694,16.751319 C113.461656,16.3288428 113.112811,16.0227538 112.686033,16.0227538'
                id='Fill-33'
                fill='#FFFFFF'
              />
              <path
                d='M107.609976,32.5955514 L105.130026,32.5955514 L105.130026,31.3711952 L103.581564,31.3711952 L103.581564,32.5955514 L101.06172,32.5955514 C100.63587,32.5955514 100.287953,32.9025569 100.287953,33.3232002 C100.287953,33.7438435 100.63587,34.050849 101.06172,34.050849 L107.609976,34.050849 C108.036753,34.050849 108.38467,33.7438435 108.38467,33.3232002 C108.38467,32.9025569 108.036753,32.5955514 107.609976,32.5955514'
                id='Fill-35'
                fill='#FFFFFF'
              />
              <mask id='mask-6' fill='white'>
                <use xlinkHref='#path-5' />
              </mask>
              <g id='Clip-38' />
              <path
                d='M147.720778,32.1664768 L152.048861,32.1664768 L152.048861,25.0329524 L147.720778,25.0329524 L147.720778,32.1664768 Z M136.657956,32.1582289 L146.277156,32.1582289 L146.277156,25.0329524 L136.657956,25.0329524 L136.657956,32.1582289 Z M136.343439,16.4826205 C136.051189,16.5119464 135.790483,16.7355563 135.719972,17.0159852 L133.793906,24.1430947 C133.684428,24.5619051 134.055539,25.0329524 134.494378,25.0329524 L135.216189,25.0329524 L135.216189,32.8712148 C135.216189,33.2442036 135.560394,33.5842007 135.938,33.5842007 C141.556622,33.5777856 147.155761,33.5915321 152.768817,33.5915321 C153.14735,33.5915321 153.490628,33.251535 153.490628,32.8785463 L153.490628,25.0329524 L154.212439,25.0329524 C154.649422,25.0329524 155.020533,24.5619051 154.911056,24.1430947 L152.9887,17.0159852 C152.907983,16.7135619 152.605528,16.4826205 152.289156,16.4826205 L136.343439,16.4826205 Z'
                id='Fill-37'
                fill='#FFFFFF'
                mask='url(#mask-6)'
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
