import { Construct } from '../shared/construct';

interface Errors {
  path: string;
  message: string;
}

interface Error {
  moreInfo?: string;
  type?: string;
  traceId?: string;
  message?: string;
  errors?: Errors[];
  code?: number;
}

export class ApiError {
  readonly error?: Error;

  constructor(init: Construct<ApiError>) {
    Object.assign(this, init);
  }

  public static fromNetwork(init: NetworkError): ApiError {
    return new ApiError({
      error: {
        message: init.error && init.error.message,
        errors: init.error && init.error.errors,
        type: init.error && init.error.type,
        moreInfo: init.error && init.error.more_info,
        traceId: init.error && init.error.trace_id,
        code: init.error && init.error.code,
      },
    });
  }
}

export class NetworkError {
  error?: {
    more_info: string;
    type: string;
    trace_id?: string;
    message: string;
    errors?: Errors[];
    code?: number;
  };
}
