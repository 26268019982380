export const policyholder = {
  policyholder: {
    policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
    type: 'individual',
    first_name: 'Erlich',
    last_name: 'Bachman',
    company_name: 'Random Corp',
    registration_number: '123CantCatchMe321',
    id: {
      type: 'id',
      number: '9401014800081',
      country: 'ZA',
    },
    email: 'elrich@root.co.za',
    cellphone: '022 232 2323',
    phone_other: '022 232 3232',
    date_of_birth: '19940101',
    gender: 'non-binary',
    created_at: '"2019-03-01T06:54:33.731Z',
    app_data: null,
    created_by: {
      type: 'user',
      id: 'c7bc530c-7291-4574-96ed-1018c9cd45c8',
      ownerId: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
    },
    address: {
      line_1: 'Milky Way',
      line_2: 'Universe',
      code: 0,
    },
  },
  scheduled_data_export: {
    scheduled_data_export_id: 'e1027376-c95e-44aa-9583-ad4bade15c31',
    environment: 'sandbox',
    created_at: '2019-08-08T14:32:50.485Z',
    created_by: {
      type: 'api_key',
      id: 'd1d9254e-491c-468d-aa26-969cfb2a7db4',
      owner_id: '00000000-0000-0000-0000-000000000001',
    },
    updated_at: '2019-08-08T14:32:50.486Z',
    status: 'active',
    name: 'Premiums Delta',
    frequency: {
      'type': 'daily',
      'time_of_day': '07:00',
    },
    adapter: {
      'type': 'https',
      'url': 'https://localhost:9999',
      'batch_size': null,
    },
    export_type: 'policy_export',
    data_range: 'week_to_date',
  },
  data_export_run: {
    data_export_run_id: '1ed3f855-1332-4ba2-ab78-c7a722dbdc3b',
    scheduled_data_export_id: 'e1027376-c95e-44aa-9583-ad4bade15c31',
    created_at: '2019-08-08T14:32:52.310Z',
    completed_at: '2019-08-08T14:32:55.541Z',
    status: 'complete',
    file_id: '680ad650-b9e9-11e9-bdc2-2f5fa4b113d7',
    from: '2019-08-07T14:32:52.310Z',
    to: '2019-08-08T14:32:52.310Z',
  },
};
