import * as React from 'react';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';

interface SlideInModalProps {
  title: any;
  isOpen: boolean;
  toggle: () => void;
}

export const SlideInModal: React.SFC<SlideInModalProps> = props => {
  return (
    <div
      className={`slide-in-modal ${props.isOpen ? 'open' : ''}`}>
      <div className='slide-in-modal-header'>
        {props.title}
        <CloseBtn toggle={props.toggle}/>
      </div>
      <div className='slide-in-modal-body'>
        {props.children}
      </div>
    </div>
  );
};

export const CloseBtn: React.SFC<{toggle: () => void}> = (props) => {
  return (
    <a
      href='#'
      onClick={props.toggle}>
      <Icon24PXCross />
    </a>
  );
};
