/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='141.732px'
        height='100px'
        viewBox='0 0 141.732 79.37'
        enableBackground='new 0 0 141.732 79.37'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='white'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M105.903,23.493H38.028
              c-1.751,0-3.175,1.481-3.175,3.303v15.811c0.022,0.146,0.045,0.293,0.045,0.446c0,1.657-1.343,3-3,3h-1.959h-9.188
              c-1.657,0-3,1.343-3,3s1.343,3,3,3h13.915c0.064,0,0.123-0.015,0.187-0.019v0.019h5.231c1.657,0,3,1.343,3,3s-1.343,3-3,3h-5.231
              v0.03H26.46c-1.657,0-3,1.343-3,3s1.343,3,3,3h8.394h0.731c1.657,0,3,1.343,3,3s-1.343,3-3,3h-0.731H15.486c-1.657,0-3,1.343-3,3
              s1.343,3,3,3h26.333c0.033,0,0.064-0.009,0.097-0.01h63.987c1.751,0,3.176-1.482,3.176-3.305V26.796
              C109.079,24.975,107.654,23.493,105.903,23.493z'
          />
          <g>
            <g>
              <g>
                <circle fill='#6C7D93' cx='62.079' cy='43.72' r='2.059' />
              </g>
              <g>
                <circle fill='#6C7D93' cx='81.853' cy='43.72' r='2.059' />
              </g>
            </g>
          </g>
          <g>
            <g>
              <circle fill='#FFFFFF' cx='59.079' cy='50.677' r='2.059' />
            </g>
            <g>
              <circle fill='#FFFFFF' cx='84.853' cy='50.677' r='2.059' />
            </g>
          </g>
          <g>
            <g>
              <path
                fill='#6C7D93'
                d='M71.966,58.519c-3.537,0-6.414-2.877-6.414-6.414c0-0.553,0.447-1,1-1s1,0.447,1,1
                      c0,2.434,1.98,4.414,4.414,4.414c2.435,0,4.416-1.98,4.416-4.414c0-0.553,0.447-1,1-1s1,0.447,1,1
                      C78.382,55.642,75.504,58.519,71.966,58.519z'
              />
            </g>
          </g>
          <path
            fill='white'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M19.71,61.053c0-1.657-1.343-3-3-3H9.378
              c-1.657,0-3,1.343-3,3s1.343,3,3,3h7.332C18.367,64.053,19.71,62.71,19.71,61.053z'
          />
        </g>
        <line
          fill='#FFFFFF'
          stroke='#CED5D9'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='131.091'
          y1='7.217'
          x2='131.091'
          y2='17.16'
        />
        <line
          fill='#FFFFFF'
          stroke='#CED5D9'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='136.062'
          y1='12.188'
          x2='126.119'
          y2='12.188'
        />
        <line
          fill='#CED5D9'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='109.079'
          y1='2.578'
          x2='109.079'
          y2='9.189'
        />
        <line
          fill='#CED5D9'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='112.385'
          y1='5.884'
          x2='105.773'
          y2='5.884'
        />
        <line
          fill='#CED5D9'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='121.834'
          y1='43.025'
          x2='121.834'
          y2='47.803'
        />
        <line
          fill='#CED5D9'
          stroke='#FFFFFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeMiterlimit='10'
          x1='124.223'
          y1='45.414'
          x2='119.445'
          y2='45.414'
        />
        <circle
          fill='none'
          stroke='#CED5D9'
          strokeWidth='2'
          strokeMiterlimit='10'
          cx='86.228'
          cy='11.091'
          r='1.375'
        />
        <circle
          fill='none'
          stroke='#CED5D9'
          strokeWidth='2'
          strokeMiterlimit='10'
          cx='120.903'
          cy='24.493'
          r='2.375'
        />
      </svg>
    );
  }
}
