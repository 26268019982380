// prettier-ignore
import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  Alert,
} from 'reactstrap';
import { ajax } from '../../../../helpers';
import { isValidImei } from '../../../../helpers/valid-imei';

/** Output of the map function passed to withData in overview.js */
interface Policy {
  appData: Record<string, unknown> | null;
  organizationId: string;
  policyId: string;
  policyNumber: string;
  [key: string]: unknown;
}

interface Props {
  onClose: () => void;
  onPolicyUpdated: () => void;
  policy?: Policy;
  show: boolean;
}

interface State {
  isValid: boolean;
  error: string;
  imei: string;
  make: string;
  model: string;
  devicePrice: string;
}

interface AppDataUpdate {
  imei?: string;
  make?: string;
  model?: string;
  device_price?: number;
}

export class UpdateAppDataModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isValid: false,
      error: '',
      imei: '',
      make: '',
      model: '',
      devicePrice: '',
    };
  }

  submit = async () => {
    const { policy } = this.props;

    if (!policy) {
      return;
    }

    const { imei, make, model, devicePrice } = this.state;

    try {
      const dataToUpdate: AppDataUpdate = {
        ...(imei && { imei: imei.trim() }),
        ...(make && { make: make.trim() }),
        ...(model && { model: model.trim() }),
        ...(devicePrice && { device_price: parseInt(devicePrice, 10) }),
      };

      this.validate(dataToUpdate);

      const data = {
        app_data: {
          ...policy.appData,
          ...dataToUpdate,
        },
      };

      const path = `/insurance/admin/apps/${policy.organizationId}/policies/${policy.policyId}`;
      try {
        await ajax({
          path,
          type: 'PATCH',
          data,
        });
      } catch (error) {
        throw JSON.parse(error.message);
      }
      this.props.onClose();
      this.props.onPolicyUpdated();
    } catch (error) {
      if (error.message === 'Validation failed') {
        this.setState({ error: error.errors[0].message });
      } else {
        this.setState({ error: error.message });
      }
    }
  };

  /** @see https://github.com/RootBank/product-module-mrprice_device/blob/main/mrprice_device/code/03-quote.js */
  validate(data: AppDataUpdate) {
    if (Object.keys(data).length === 0) {
      throw new Error('All fields are empty - no data to update');
    }

    const { imei, make, model, device_price: devicePrice } = data;

    if (make !== undefined) {
      if (make.length === 0) {
        throw new Error('Make cannot be empty');
      }
    }

    if (model !== undefined) {
      if (model.length === 0) {
        throw new Error('Model cannot be empty');
      }
    }

    if (imei !== undefined) {
      if (!isValidImei(imei)) {
        throw new Error('IMEI number is invalid');
      }
    }

    if (devicePrice !== undefined) {
      if (devicePrice <= 0) {
        throw new Error('Device price must be a positive integer');
      }
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>Update app data</ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Alert color='info'>
                  Only completed fields will be updated. Empty inputs will be
                  ignored.
                </Alert>
              </FormGroup>
              {this.state.error && (
                <Alert color='danger'>{this.state.error}</Alert>
              )}
              <FormGroup>
                <Label>IMEI number</Label>
                <Input
                  onChange={(e) =>
                    this.setState({
                      imei: e.target.value,
                    })
                  }
                  type='text'
                  name='imei'
                  id='imei'
                />
              </FormGroup>
              <FormGroup>
                <Label>Make</Label>
                <Input
                  onChange={(e) =>
                    this.setState({
                      make: e.target.value,
                    })
                  }
                  type='text'
                  name='make'
                  id='make'
                />
              </FormGroup>
              <FormGroup>
                <Label>Model</Label>
                <Input
                  onChange={(e) =>
                    this.setState({
                      model: e.target.value,
                    })
                  }
                  type='text'
                  name='model'
                  id='model'
                />
              </FormGroup>
              <FormGroup>
                <Label>Device price (in cents)</Label>
                <Input
                  onChange={(e) =>
                    this.setState({
                      devicePrice: e.target.value,
                    })
                  }
                  type='number'
                  name='device_price'
                  id='device_price'
                />
              </FormGroup>
            </Form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.submit} color='primary'>
              Update
            </Button>

            <Button onClick={this.props.onClose} color='secondary'>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
