import ajax from '../../helpers/ajax';
import { stopCheck } from '../../helpers/token-refresh';

export const logout = () => {
  return dispatch => {
    const storage = window.localStorage;
    stopCheck();
    return ajax({ dispatch: dispatch, type: 'POST', path: '/accounts/logout' })
      .catch(e => console.log('Logout Error:', e))
      .then(r => {
        console.log('Clearing storage before dispatching LOGGED_OUT');
        storage.clear();
        dispatch({ type: 'LOGGED_OUT' });
      });
  };
};
