import React from 'react';
import { Button } from 'reactstrap';
import {
  ajax,
} from '../../../helpers';
import { Payment } from '../../../shared/domain/payment';

interface Props {
  disabled: boolean;
  payment: Payment;
  refresh: () => void;
}

interface State {}

export class CancelPaymentButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  cancelPayment = async (paymentId: string) => {
    try {
      const data = [{
        payment_id: paymentId,
        status: 'cancelled',
      }];

      const payment = await ajax({
        path: 'insurance/admin/payments',
        type: 'PATCH',
        data,
      });
      return payment;
    } catch (error) {
      alert(`An error occured trying to cancel this payment ${error.message}`);
    }
  };

  handleClick = async (payment: Payment) => {
    if (
      !window.confirm(
        'Are you sure you want to cancel this payment?',
      )
    ) {
      return;
    }

    await this.cancelPayment(payment.paymentId);
    await this.props.refresh();
  };

  render() {
    const { payment } = this.props;

    return (
      <div>
        <Button
          disabled={this.props.disabled}
          color='link'
          style={{
            color: this.props.disabled ? 'grey' : 'red',
            minWidth: 'auto',
            display: 'contents',
          }}
          onClick={() => !payment.finalizedAt ?
            this.handleClick(payment) :
            alert(`This payment has already been finalised on: ${payment.finalizedAt}`)}
        >
          Cancel
        </Button>
      </div>
    );
  }
}
