import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import PendingBanv from '../payment-methods/components/pending';
import ProcessingBanv from '../payment-methods/components/processing';
import PendingUpdateRequests from '../policy-lifecycle/components/pending';

import Accounts from '../collect/components/accounts';
import Account from '../collect/components/account';
import Request from '../collect/components/request';
import PeachBatches from '../collect/components/peach-batches';
import PeachBatch from '../collect/components/peach-batch';
import PeachCallbacks from '../collect/components/peach-callbacks';
import PeachCallback from '../collect/components/peach-callback';

// Components
import PendingPayments from './components/pending';
import ProcessingPayments from './components/processing';
import { PaymentAlertsContainer } from './components/alerts/payment-alerts-container';
import { PaymentsContainer } from './index';
import PaymentBatches from './components/payment-batches/payment-batches';
import PaymentBatchOverview from './components/payment-batches/overview';
import { PaymentBatchesContainer } from './components/payment-batches/payment-batches-container';
import PaymentBatchPayments from './components/payment-batches/payments';
import { PayoutRequestList } from './components/payout-requests/payout-request-list';

export default (
  <Route>
    <Route name='payments' component={PaymentsContainer}>
      <Route path='/payments/alerts/resolved' component={PaymentAlertsContainer}/>
      <Route path='/payments/alerts/unresolved' component={PaymentAlertsContainer}/>

      <IndexRedirect to='/payment-batches/pending' />
      <Route path='/payments/pending' component={PendingPayments} />
      <Route path='/payments/processing' component={ProcessingPayments} />
      <Route path='/payments/banv/pending' component={PendingBanv} />
      <Route path='/payments/banv/processing' component={ProcessingBanv} />

      <Route path='/payment-batches/pending' component={PaymentBatches} />
      <Route path='/payment-batches/submitted' component={PaymentBatches} />

      <Route
        path='/payments/update-requests/pending'
        component={PendingUpdateRequests}
      />

      <Route path='/payments/accounts' component={Accounts} />
      <Route path='/payments/accounts/:accountId' component={Account} />
      <Route path='/payments/requests/:requestId' component={Request} />

      <Route path='/payments/peach-batches' component={PeachBatches} />
      <Route
        path='/payments/peach-batches/:peachBatchId'
        component={PeachBatch}
      />
      <Route path='/payments/peach-callbacks' component={PeachCallbacks} />
      <Route
        path='/payments/peach-callbacks/:peachCallbackId'
        component={PeachCallback}
      />
    </Route>
    <Route name='payment-batches' component={PaymentBatchesContainer}>
      <Route
        path='/payment-batches/:paymentBatchId'
        component={PaymentBatchOverview}
      />
      <Route
        path='/payment-batches/:paymentBatchId/payments'
        component={PaymentBatchPayments}
      />
    </Route>
    <Route name='payout-requests' component={PaymentsContainer}>
      <Route path='/payout-requests/policy-refunds' component={PayoutRequestList} />
      <Route path='/payout-requests/claim-payouts' component={PayoutRequestList} />
    </Route>
  </Route>
);
