import React from 'react';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import { Container } from 'reactstrap';

export const PaymentBatchesContainer = props => {
  return (
    <div className='container-with-sidebar'>
      <SidebarSecondary
        router={props.router}
        route={props.route}
        subRoutes={sideBarSchema.routes(
          props.router.params,
          props.router.location.query,
        )}
        title={sideBarSchema.name}
      />
      <Container>
        {props.children}
      </Container>
    </div>
  );
};

const sideBarSchema = {
  name: 'Payment Batch',
  routes: (params, query) => {
    const { paymentBatchId } = params;
    return [
      {
        title: true,
        name: 'View',
      },
      {
        name: 'Overview',
        url: `/payment-batches/${paymentBatchId}`,
      },
      {
        name: 'Payments',
        url: `/payment-batches/${paymentBatchId}/payments`,
      },
    ];
  },
};
