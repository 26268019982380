import React from 'react';
import { Alert, Table, Button } from 'reactstrap';
import { withData, alphabetically } from '../../../helpers';
import PageHeader from '../../../components/page-header';
import { AddUserModal } from './add-user-modal';
import { EditUserModal } from './edit-user-modal';
import { TeamMemberRow } from './team-member';
import { History } from 'history';
import { User } from '../../../users/domain/user';
import { removeUser } from './team-members-api';

interface Props {
  params: {
    organizationId: string;
  };
  users: User[];
  fetch: (params: {
    path: string;
    type: string;
    data: {
      role_name: string;
    };
  }) => any;
  fetchUsers: () => any;
  router: History;
  loading: boolean;
  error: string;
}

class TeamMembers extends React.Component<Props, any> {
  state = {
    showAddUserModal: false,
    showEditUserModal: false,
    selectedUser: undefined,
    removeUserError: '',
  };

  showAddUserModal = () => this.setState({ showAddUserModal: true });

  closeAddUserModal = () => {
    this.setState({ showAddUserModal: false }, () => this.props.fetchUsers());
  }

  showEditUserModal = () => this.setState({ showEditUserModal: true });

  closeEditUserModal = () =>  this.setState(
    { showEditUserModal: false, selectedUser: undefined },
    () => this.props.fetchUsers());

  clickEditUser = (user: User) => {
    this.setState(
      {
        selectedUser: user,
      },
      () => {
        this.showEditUserModal();
      },
    );
  };

  removeUserHandler = async (user: User) => {
    if (
      !window.confirm(
        `Are you sure you want remove ${user.firstName} ${
          user.lastName
        } as a team member?`,
      )
    ) {
      return;
    }

    try {
      await removeUser({organizationId: this.props.params.organizationId, userId: user.userId});
      this.props.fetchUsers();
    } catch (error) {
      this.setState({removeUserError: JSON.parse(error.message).message});
    }
  }

  editUserDetails = (userId: string) => {
    this.props.router.push('/users/' + userId);
  }

  renderUserRow = (user: User) => (
    <TeamMemberRow
      key={user.userId}
      user={user}
      onRemove={this.removeUserHandler}
      onEditClick={() => this.clickEditUser(user)}
      editUserDetails={(userId: string) => this.editUserDetails(userId)}
    />
  );

  getOrganizationOwnerId = (users: User[]) => {
    const organizationOwner = users.find(user => user.isOwner);
    return organizationOwner && organizationOwner.userId;
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading Team members...</i>
        </div>
      );
    }

    if (this.props.error) {
      return (
        <Alert color='danger'>
          Error fetching team members: {this.props.error}
        </Alert>
      );
    }

    return (
      <div>
        <br />
        <PageHeader
          title='Team members'
          rightComponent={
            <Button
              className='pull-right'
              onClick={this.showAddUserModal}
              color='primary'
            >
              Add
            </Button>
          }
        />
        { (this.state.removeUserError) && (
          <Alert color='danger'>
        Error removing member: {this.state.removeUserError}
          </Alert>
        )
        }
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{this.props.users.sort(alphabetically('firstName')).map(this.renderUserRow)}</tbody>
        </Table>

        {this.state.showAddUserModal && <AddUserModal
          onClose={this.closeAddUserModal}
          show={this.state.showAddUserModal}
          organizationId={this.props.params.organizationId}
          organizationOwnerId={this.getOrganizationOwnerId(this.props.users)}
        />}

        {this.state.selectedUser && (
          <EditUserModal
            onClose={this.closeEditUserModal}
            show={this.state.showEditUserModal}
            organizationId={this.props.params.organizationId}
            user={this.state.selectedUser}
          />
        )}
      </div>
    );
  }
}

export default withData({
  path: (props: Props) => `/organizations/${props.params.organizationId}/users`,
  prop: 'users',
})(TeamMembers as any);
