import React, { Component } from 'react';

export class Paragraph extends Component<any, any> {
  render() {
    const { componentProps, label } = this.props;

    return (
      <div className={`form-group col-sm-${componentProps.colWidth || 12}`}>
        <p>{label}</p>
      </div>
    );
  }
}

export default Paragraph;
