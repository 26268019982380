import { observable, action } from 'mobx';
import { ajax, toCamelCaseKeys } from '../../../../helpers';

export class ProductModuleOrganizationStore {
  @observable organizations: any[];
  @observable page: number = 0;
  @observable pageSize = 10;
  @observable totalOrganizationRowsCount = 10;
  @observable organizationName: string = '';

  @action setActivePage = (page: number) => {
    this.page = page;
  };

  @action setOrganizationName = (organizationName: string) => {
    this.organizationName = organizationName;
  };

  @action getAllOrganizations = async () => {
    const queryString = this.organizationName
      ? `search=${this.organizationName}`
      : '';

    const total = await ajax({
      type: 'HEAD',
      path: `/insurance/admin/organizations?${queryString}`,
      raw: true,
    });

    const pagination = `page=${this.page + 1}&page_size=${this.pageSize}`;

    const response = await ajax({
      path: `/insurance/admin/organizations?${pagination}&${queryString}`,
      type: 'GET',
    });

    this.totalOrganizationRowsCount = total.headers.get('X-Total-Count');
    this.organizations = response.map(toCamelCaseKeys);
  };
}

export const productModuleOrganizationStore = new ProductModuleOrganizationStore();
