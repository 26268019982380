import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { nestedStyleCheckbox } from '../../product-module-definition-settings/utils';
import _ from 'lodash';
import {
  defaultCheckboxValue,
  savedBadgeRender,
  savingBadgeRender,
} from '../orchestration';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import { debounce } from '../../../../../../helpers/debounce';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import { BillingParams } from './billing';
import { SavingState } from '../../../util';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  params: BillingParams;
}
@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
export default class ProductModuleSettingsCheckbox extends React.Component<
Props,
any
> {
  constructor(props: Props) {
    super(props);
  }

  onCheckBoxChange = async (
    params: BillingParams,
    value: string | number | boolean | null,
  ) => {
    const { key, path, reset } = params;
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleBillingComponents,
        key,
        SavingState.Saving,
      );
    }
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      let settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        const settingsItemValue = settingsItem.indexOf(value);
        settingsItemValue !== -1
          ? settingsItem.splice(settingsItemValue, 1)
          : settingsItem.push(value);
        _.set(settings, path, settingsItem);
      } else {
        settingsItem = !settingsItem;
        if (reset) {
          reset.forEach(val => {
            const resetConditionMet = val.comparisonValues.indexOf(
              settingsItem,
            );
            if (resetConditionMet !== -1) {
              _.set(settings, val.path, val.default);
            }
          });
        }
        _.set(settings, path, settingsItem);
      }
      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: { settings_json: settings },
      });
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleBillingComponents,
        key,
        SavingState.Saved,
      );

      debounce(
        key,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleBillingComponents,
            key,
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  render() {
    const {
      label,
      index,
      displayCheckValue,
      lockedComponent,
      description,
      indent,
      key,
      title,
    } = this.props.params;

    const productModuleStore = this.props.productModuleStore;
    const productModuleComponentLoadingStateStore = this.props
      .productModuleComponentLoadingStateStore;

    const nested = indent ? true : false;

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleBillingComponents[key];

    return (
      <Row
        key={`${_.snakeCase(label)}_${index}`}
        className='product-module-settings-input-component-wrapper'
      >
        <Col xs={3}>{title}</Col>
        <Col xs={9}>
          <div style={nestedStyleCheckbox(nested, index)}>
            <div className='product-module-definitions-enable-item-checkbox '>
              <Input
                onChange={() =>
                  this.onCheckBoxChange(this.props.params, displayCheckValue)
                }
                disabled={lockedComponent}
                type='checkbox'
                defaultChecked={defaultCheckboxValue(
                  { ...this.props.params },
                  productModuleStore,
                )}
              />
            </div>
            <div className='settings-description-label-div'>
              <p style={{ fontWeight: 'bold' }}>{label}</p>
            </div>
            {savedBadgeRender(saving)}
            {savingBadgeRender(saving)}
            <div className='product-module-heading-description-spacing'>
              <span>{description}</span>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
