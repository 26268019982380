export const invalidRequestError = `
declare class InvalidRequestError implements Error {
  name = 'InvalidRequestError';
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
`;

export const notFoundRequestError = `
declare class NotFoundError implements Error {
  name = 'NotFoundError';
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
`;
