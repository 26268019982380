/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='73.084px'
        height='66.458px'
        viewBox='0 0 73.084 66.458'
        enableBackground='new 0 0 73.084 66.458'
        xmlSpace='preserve'
      >
        <path
          fill='#202B40'
          d='M36.542,0.875c-9.168,0-16.628,7.459-16.628,16.628s7.46,16.628,16.628,16.628
        c9.169,0,16.628-7.459,16.628-16.628S45.711,0.875,36.542,0.875z'
        />
        <path
          fill='#5E97FC'
          d='M65.198,16.253H54.922c0.028,0.415,0.063,0.828,0.063,1.25c0,0.253-0.028,0.499-0.038,0.75h10.251
        c2.358,0,4.277,1.653,4.277,3.686v3.814H53.034c-0.343,0.69-0.716,1.362-1.139,2h17.58v32.314c0,2.032-1.919,3.686-4.277,3.686
        H7.886c-2.358,0-4.277-1.653-4.277-3.686V27.753H20.82c-0.423-0.638-0.796-1.31-1.139-2H3.608v-3.814
        c0-2.032,1.919-3.686,4.277-3.686h9.883c-0.011-0.251-0.038-0.497-0.038-0.75c0-0.422,0.035-0.835,0.063-1.25H7.886
        c-3.462,0-6.277,2.55-6.277,5.686v38.129c0,3.135,2.815,5.686,6.277,5.686h57.312c3.461,0,6.277-2.551,6.277-5.686V21.938
        C71.476,18.803,68.659,16.253,65.198,16.253z'
        />
        <g>
          <g>
            <path
              fill='#FFFFFF'
              d='M43.149,17.917c0-0.89-0.72-1.61-1.607-1.606l-10.004,0.004c-0.888,0-1.607,0.721-1.607,1.607l0.004,5.634
            c0,0.888,0.72,1.607,1.606,1.607l10.005-0.005c0.888,0,1.607-0.721,1.607-1.607L43.149,17.917z M36.97,21.008v1.322
            c0,0.156-0.129,0.284-0.285,0.284h-0.285c-0.157,0-0.285-0.128-0.285-0.284v-1.322c-0.404-0.168-0.688-0.566-0.688-1.031
            c0-0.616,0.499-1.116,1.116-1.116s1.117,0.5,1.117,1.116C37.659,20.442,37.374,20.84,36.97,21.008z'
            />
            <path
              fill='#FFFFFF'
              d='M33.429,12.524c-0.001-1.63,1.324-2.956,2.954-2.957s2.956,1.325,2.957,2.955l0.001,2.604
            c0,0.345,0.279,0.623,0.622,0.623h0.481c0.344,0,0.622-0.278,0.622-0.623l-0.001-2.605c-0.002-2.583-2.102-4.681-4.684-4.679
            c-2.581,0.001-4.68,2.103-4.679,4.682l0.001,2.603c0,0.345,0.279,0.623,0.623,0.623h0.48c0.344,0,0.622-0.278,0.622-0.623
            L33.429,12.524z'
            />
          </g>
        </g>
        <g>
          <path
            fill='#5E97FC'
            d='M14.63,45.753c0,1.09-0.883,1.973-1.972,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C13.747,43.78,14.63,44.663,14.63,45.753L14.63,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M22.591,45.753c0,1.09-0.883,1.973-1.972,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C21.708,43.78,22.591,44.663,22.591,45.753L22.591,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M30.553,45.753c0,1.09-0.883,1.973-1.972,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C29.67,43.78,30.553,44.663,30.553,45.753L30.553,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M38.515,45.753c0,1.09-0.883,1.973-1.973,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C37.632,43.78,38.515,44.663,38.515,45.753L38.515,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M46.476,45.753c0,1.09-0.883,1.973-1.973,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C45.593,43.78,46.476,44.663,46.476,45.753L46.476,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M54.437,45.753c0,1.09-0.883,1.973-1.972,1.973l0,0c-1.089,0-1.972-0.883-1.972-1.973l0,0
          c0-1.09,0.883-1.973,1.972-1.973l0,0C53.554,43.78,54.437,44.663,54.437,45.753L54.437,45.753z'
          />
          <path
            fill='#5E97FC'
            d='M62.398,45.753c0,1.09-0.883,1.973-1.972,1.973l0,0c-1.09,0-1.973-0.883-1.973-1.973l0,0
          c0-1.09,0.883-1.973,1.973-1.973l0,0C61.516,43.78,62.398,44.663,62.398,45.753L62.398,45.753z'
          />
        </g>
      </svg>
    );
  }
}
