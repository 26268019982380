import { observable } from 'mobx';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { ajax } from '../../../../../../helpers';
import { BlockSchema } from '../domain/block-schema';
import { blockSchemaFactory } from '../domain/block-schema-factory';
import { InputTextBlockState } from '../domain/states/input-text-block-state';
import { InputNumberBlockState } from '../domain/states/input-number-block-state';
import { InputTimeBlockState } from '../domain/states/input-time-block-state';
import { InputCurrencyBlockState } from '../domain/states/input-currency-block-state';
import { CheckboxBlockState } from '../domain/states/checkbox-block-state';
import { PayoutRequestBlockState } from '../domain/states/payout-request-block-state';
import { FulfillmentRequestBlockState } from '../domain/states/fulfillment-request-block-state';
import { AnnuityRequestBlockState } from '../domain/states/annuity-request-block-state';
import { DropdownBlockState } from '../domain/states/dropdown-block-state';
import { HeadingBlockState } from '../domain/states/heading-block-state';
import { DividerBlockState } from '../domain/states/divider-block-state';
import { AlertBlockState } from '../domain/states/alert-block-state';
import { InputParagraphBlockState } from '../domain/states/input-paragraph-block-state';
import { RadioBlockState } from '../domain/states/radio-block-state';
import { MarkdownBlockState } from '../domain/states/markdown-block-state';
import { InputDateBlockState } from '../domain/states/input-date-block-state';
import { mergeVarStubs } from '../../../../../../organizations/components/data-exports/merge-var-stubs';
import { GroupBlockState } from '../domain/states/group-block-state';

export class BlockStore {
  @observable isLoaded = false;
  @observable error = '';
  @observable blocksSchema: BlockSchema[];
  @observable unparsedBlocksSchema: BlockSchema[];
  @observable blocksStates: {
    [key: string]:
    | InputTextBlockState
    | InputNumberBlockState
    | InputTimeBlockState
    | InputCurrencyBlockState
    | CheckboxBlockState
    | PayoutRequestBlockState
    | FulfillmentRequestBlockState
    | AnnuityRequestBlockState
    | DropdownBlockState
    | HeadingBlockState
    | DividerBlockState
    | AlertBlockState
    | InputParagraphBlockState
    | RadioBlockState
    | MarkdownBlockState
    | InputDateBlockState
    | GroupBlockState;
  };

  clearStore = () => {
    this.blocksStates = {};
    this.blocksSchema = [];
  };

  init = async (rawSchema: any) => {
    this.isLoaded = false;
    this.blocksStates = {};
    this.blocksSchema = [];

    try {
      const fullSchema = computeFlatSchema(rawSchema);

      const rawBlocksSchema = fullSchema.map((rs: any) =>
        blockSchemaFactory.createBlockSchema(rs),
      );

      this.unparsedBlocksSchema = rawSchema;

      for (const blockSchema of rawBlocksSchema) {
        const blockState = blockStateFactory.createBlock({
          type: blockSchema.type,
          key: blockSchema.key,
        });

        if (blockState) {
          this.blocksStates[blockSchema.key] = blockState;
        }
      }

      await ajax({
        path: '/insurance/admin/handlebars',
        type: 'post',
        data: [
          {
            content: JSON.stringify(rawSchema),
            merge_vars: {
              block_states: this.blocksStates,
              ...mergeVarStubs.claim,
              policyholder: {
                ...mergeVarStubs.policyholder,
              },
              policy: {
                ...mergeVarStubs.policy,
              },
            },
            fake_nip: true,
          },
        ],
      });
      this.blocksSchema = rawSchema;
      this.error = '';
    } catch (error) {
      this.error = error;
    }

    this.isLoaded = true;
  };
}

//// Helpers
const computeFlatSchema = (rawSchema: any) => {
  return rawSchema.flatMap((schema: any) => {
    const children = schema.block.blocks;

    return (children) ? [schema, ...computeFlatSchema(children)] : schema;
  });
};
