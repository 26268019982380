import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Card, CardBody, Popover, PopoverBody } from 'reactstrap';
import { EditTemplateModal } from './modals/edit-template-modal';
import { NewTemplateModal } from './modals/new-template-modal';
import { NewExportModal } from './modals/new-export-modal';
import { TemplateViewStore } from '../stores/template-view-store';
import { TemplateListStore } from '../stores/template-list-store';
import { DataExportListStore } from '../stores/data-export-list-store';
import { ArchiveTemplateModal } from './modals/archive-template-modal';
import uuid from 'uuid';
import Images from '../../../../components/svg-images';
import { OrganizationProductModule } from '../../../domain/organization-product-module';
import { ProductModule } from '../../../../product-modules/domain/product-module';

interface Props {
  organizationProductModules: OrganizationProductModule[];
}
interface State {
  popoverOpen: boolean;
  showArchiveModal: boolean;
  showEditModal: boolean;
  showConfirmEditModal: boolean;
  showNewExportFromTemplateModal: boolean;
}
interface Injected extends Props {
  templateListStore: TemplateListStore;
  dataExportListStore: DataExportListStore;
  templateViewStore: TemplateViewStore;
}

interface State {}

@inject(
  'dataExportListStore',
  'templateListStore',
  'templateViewStore',
)
@observer
export class Details extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      popoverOpen: false,
      showArchiveModal: false,
      showEditModal: false,
      showConfirmEditModal: false,
      showNewExportFromTemplateModal: false,
    };

    this.injected.templateListStore.setTemplateListStoreLoadingTrue();
    this.injected.templateViewStore.setTemplateViewStoreLoadingTrue();
    this.injected.dataExportListStore.setDataExportListStoreLoadingTrue();
  }


  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  componentDidMount() {
    const { templateViewStore, templateListStore, dataExportListStore } = this.injected;
    templateViewStore.load({
      organizationId: this.organizationId,
      templateId: window.location.pathname.split('/')[5],
    });

    templateListStore.load({ organizationId: this.organizationId });
    dataExportListStore.load({ organizationId: this.organizationId });
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  archiveOnClick = () => {
    this.setState({ showArchiveModal: true }, () => this.toggle());
  }

  editOnClick = () => {
    this.setState({ showConfirmEditModal: true }, () => this.toggle());
  }

  render() {
    const { templateViewStore, organizationProductModules } = this.injected;
    const { template } = templateViewStore;
    const productModule = (organizationProductModules || [] as any).find((p: ProductModule | OrganizationProductModule) => p.productModuleId === (template && template.productModuleId));

    if (templateViewStore.isLoading) {
      return <div>Loading ...</div>;
    }
    return (
      <div>
        <Card className='template-detail-card' style={{ marginTop: '48px' }}>
          <CardBody style={{ display:'flex', verticalAlign:'middle'}}>
            <h5 style={{ justifyContent: 'flex-start', marginRight: 'auto' }}>Export template</h5>
            <Button
              color='primary'
              style={{ justifyContent:'flex-end' }}
              onClick={() => this.setState({ showNewExportFromTemplateModal: true })}>New export</Button>
            <Button
              outline
              color='secondary'
              style={{ justifyContent:'flex-end' }}
              className='ellipsisButton'
              id={`template-popover-${template && template.templateId || uuid().toString()}`}
              type='button'
              onClick={this.toggle}>
              &#183;&#183;&#183;
            </Button>
            <Popover
              className='popoverBox'
              placement='right'
              isOpen={this.state.popoverOpen}
              target={`template-popover-${template && template.templateId}` || uuid().toString()}>
              <PopoverBody>
                <Button
                  className='popoverListItem'
                  outline
                  color='secondary'
                  onClick={() => this.editOnClick()}>
                  Edit template
                </Button>
                <hr/>
                <Button
                  className='popoverListItem'
                  outline
                  color='danger'
                  onClick={() => this.archiveOnClick()}>
                  Archive template
                </Button>
              </PopoverBody>
            </Popover>
          </CardBody>
        </Card>
        <Card className='template-detail-card' style={{ borderTop: 'none', borderRadius: '0 0 4px 4px' }}>
          <CardBody>
            <h3>{template && template.templateName || ''}</h3>
            <table className='data-export-details-table'>
              <tbody>
                <tr>
                  <td className='data-export-details-table-item-label'>
                    Description
                  </td>
                  <td className='data-export-details-table-item-value'>
                    {template && template.description || ''}
                  </td>
                </tr>
                <tr>
                  <td className='data-export-details-table-item-label'>
                    Data source
                  </td>
                  <td className='data-export-details-table-item-value'>
                    {template && template.dataSource || ''}
                  </td>
                </tr>
                <tr>
                  <td className='data-export-details-table-item-label'>
                    Product module
                  </td>
                  <td className='data-export-details-table-item-value'>
                    {productModule && productModule.name || ''}
                  </td>
                </tr>
                <tr>
                  <td className='data-export-details-table-item-label'>
                    Permissions
                  </td>
                  {template && <td className='data-export-details-table-item-value'>
                    {<span style={{ padding: '5px' }}>{template.restricted ? <Images.lock/> : <Images.success/>}</span>}
                    {template.restricted ? 'Root-admin only exports and editing' : 'Open to exports and editing'}
                  </td>}
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
        {this.state.showArchiveModal && (
          <ArchiveTemplateModal
            close={() => this.setState({ showArchiveModal: false })}
          />
        )}
        {this.state.showConfirmEditModal && (
          <EditTemplateModal
            submit={() => this.setState({ showEditModal: true, showConfirmEditModal: false })}
            close={() => this.setState({ showConfirmEditModal: false })}
          />
        )}
        {this.state.showEditModal && template &&
          <NewTemplateModal
            updating={true}
            organizationProductModules={this.props.organizationProductModules}
            close={() => this.setState({ showEditModal: false })}
          />
        }
        {this.state.showNewExportFromTemplateModal && (
          <NewExportModal
            editing={false}
            fromTemplate={true}
            close={() =>
              this.setState({ showNewExportFromTemplateModal: false })
            }
          />
        )}
      </div>
    );
  }
}
