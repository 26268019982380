import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NewExportModal } from './data-exports/views/modals/new-export-modal';
import { ArchiveTemplateModal } from './data-exports/views/modals/archive-template-modal';
import { NewTemplateModal } from './data-exports/views/modals/new-template-modal';
import { EditTemplateModal } from './data-exports/views/modals/edit-template-modal';
import { TemplateViewStore } from './data-exports/stores/template-view-store';
import { ScheduledDataExport } from './data-exports/domain/scheduled-data-export';
import { DataExportListStore } from './data-exports/stores/data-export-list-store';
import { TemplateListStore } from './data-exports/stores/template-list-store';
import { OrganizationProductModule } from '../domain/organization-product-module';
import { TemplateRow } from './export-templates-row';
import { PaginationComponent } from '../../components/pagination';
import { Input } from 'reactstrap';
import { debounce } from '../../helpers/debounce';

const EmptyRow = () => (
  <tr>
    <td />
    <td />
    <td />
    <td />
    <td />
  </tr>
);

const EmptyTableBody = () => (
  <tbody>
    <EmptyRow />
    <EmptyRow />
    <EmptyRow />
  </tbody>
);

const TemplatesTableHeader = () => (
  <thead>
    <tr>
      <th className='data-export-heading-item'>EXPORT NAME</th>
      <th className='data-export-heading-item'>DATA SOURCE</th>
      <th className='data-export-heading-item'>PRODUCT</th>
      <th className='data-export-heading-item'>ACTIVE INTEGRATIONS</th>
      <th className='data-export-heading-item' />
    </tr>
  </thead>
);

const TemplateTableBody = (params: {
  scheduledDataExports: ScheduledDataExport[];
  templates: any;
  organizationProductModules: OrganizationProductModule[];
  organizationId: string;
  warningEdit: () => void;
  confirmEdit: () => void;
  archive: any;
  newExport: any;
}) => {
  const {
    archive,
    confirmEdit,
    scheduledDataExports,
    templates,
    newExport,
    organizationProductModules,
    warningEdit,
  } = params;
  return (
    <tbody>
      {templates.map((t: any) => (
        <TemplateRow
          key={t.templateId}
          isDefault={false}
          template={t}
          productModules={organizationProductModules}
          scheduledDataExports={scheduledDataExports}
          warningEdit={warningEdit}
          confirmEdit={confirmEdit}
          archive={archive}
          newExport={newExport}
        />
      ))}
    </tbody>
  );
};

interface ExportTemplatesTableProps {
  organizationProductModules: OrganizationProductModule[];
}

interface ExportTemplatesTableInjected extends ExportTemplatesTableProps {
  templateViewStore: TemplateViewStore;
  dataExportListStore: DataExportListStore;
  templateListStore: TemplateListStore;
}

interface ExportTemplatesTableState {
  showConfirmEditTemplateModal: boolean;
  showEditTemplateModal: boolean;
  showArchiveTemplateModal: boolean;
  showNewExportFromTemplateModal: boolean;
  currentTemplate: any;
  showWarningEditTemplateModal: boolean;
  searchValue: string;
}

@inject('dataExportListStore', 'templateListStore', 'templateViewStore')
@observer
export class ExportTemplatesTable extends Component<ExportTemplatesTableProps, ExportTemplatesTableState> {
  constructor(props: ExportTemplatesTableProps) {
    super(props);

    this.state = {
      showConfirmEditTemplateModal: false,
      showEditTemplateModal: false,
      showArchiveTemplateModal: false,
      showNewExportFromTemplateModal: false,
      currentTemplate: {},
      showWarningEditTemplateModal: false,
      searchValue: '',
    };

    this.injected.dataExportListStore.setDataExportListStoreLoadingTrue();
    this.injected.templateListStore.setTemplateListStoreLoadingTrue();
  }

  get injected() {
    return this.props as ExportTemplatesTableInjected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  componentDidMount() {
    const { dataExportListStore, templateListStore } = this.injected;

    dataExportListStore.load({ organizationId: this.organizationId });
    templateListStore.load({
      organizationId: this.organizationId,
      search: this.state.searchValue,
    });
  }

  archiveTemplate() {
    const { templateViewStore } = this.injected;
    templateViewStore.archive({ organizationId: this.organizationId });
  }

  handleSearchChanged = async (event: any) => {
    const { templateListStore } = this.injected;

    const searchValue = event.target.value;
    this.setState({
      searchValue,
    });

    debounce(
      'searchDataExports',
      async () => {
        await templateListStore.load({
          organizationId: this.organizationId,
          search: searchValue,
        });
      },
      500,
    );
  };

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>You have no existing templates.</p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  render() {
    const { templateListStore, dataExportListStore } = this.injected;
    const { templates, isLoading, isEmpty, total, pagination } =
      templateListStore;
    const { scheduledDataExports } = dataExportListStore;
    return (
      <div>
        <TemplateSearchBox
          searchValue={this.state.searchValue}
          handleSearchChanged={this.handleSearchChanged}
        />
        <table style={{ width: '100%' }}>
          <TemplatesTableHeader />
          {isLoading && <EmptyTableBody />}
          {!isLoading && isEmpty && this.renderEmpty()}
          {!isLoading && (
            <TemplateTableBody
              scheduledDataExports={scheduledDataExports}
              templates={templates}
              organizationProductModules={this.props.organizationProductModules}
              organizationId={this.organizationId}
              warningEdit={() =>
                this.setState({ showWarningEditTemplateModal: true })
              }
              confirmEdit={() =>
                this.setState({ showConfirmEditTemplateModal: true })
              }
              archive={(template: any) =>
                this.setState({
                  showArchiveTemplateModal: true,
                  currentTemplate: template,
                })
              }
              newExport={() =>
                this.setState({ showNewExportFromTemplateModal: true })
              }
            />
          )}
        </table>
        {templates.length > 0 && (
          <PaginationComponent
            page={pagination.page || 0}
            limit={pagination.pageSize}
            total={total || 0}
            disabled={false}
            goToPage={(page: number) =>
              templateListStore.goToPage(page, this.state.searchValue)
            }
          />
        )}
        {this.state.showWarningEditTemplateModal && (
          <EditTemplateModal
            submit={() => this.setState({ showConfirmEditTemplateModal: true })}
            close={() => this.setState({ showWarningEditTemplateModal: false })}
          />
        )}
        {this.state.showConfirmEditTemplateModal && (
          <NewTemplateModal
            updating={true}
            organizationProductModules={this.props.organizationProductModules}
            close={() => this.setState({ showConfirmEditTemplateModal: false })}
          />
        )}
        {this.state.showArchiveTemplateModal && (
          <ArchiveTemplateModal
            close={() => this.setState({ showArchiveTemplateModal: false })}
          />
        )}
        {this.state.showNewExportFromTemplateModal && (
          <NewExportModal
            editing={false}
            close={() =>
              this.setState({ showNewExportFromTemplateModal: false })
            }
          />
        )}
      </div>
    );
  }
}

const TemplateSearchBox = (params: {
  searchValue: string;
  handleSearchChanged: (event: any) => void;
}) => (
  <div className='pull-right'>
    <Input
      name='search'
      placeholder='Search'
      type='text'
      value={params.searchValue}
      onChange={params.handleSearchChanged}
    />
  </div>
);
