/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='152.186px'
        height='121.342px'
        viewBox='0 0 152.186 121.342'
        enableBackground='new 0 0 152.186 121.342'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#0E131D'
            d='M80.578,62.183c0-3.221,0.311-6.367,0.877-9.424H70.489c0.566,3.057,0.877,6.203,0.877,9.424
        c0,18.955-10.263,35.504-25.526,44.422v9.76h60.505v-9.621C90.948,97.857,80.578,81.238,80.578,62.183z'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M79.542,116.427
        c0.014-0.238,0.036-0.473,0.036-0.715c0-6.824-5.533-12.357-12.358-12.357c-6.824,0-12.357,5.533-12.357,12.357
        c0,0.242,0.022,0.477,0.036,0.715H79.542z'
          />
          <g>
            <line
              fill='#9DC3ED'
              stroke='#9DC3ED'
              strokeWidth='2'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='75.797'
              y1='13.131'
              x2='75.766'
              y2='6.94'
            />
            <g>
              <path
                fill='#CAE0FF'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M53.334,50.258
            c0,0-15.335,28.594,3.555,44.277c0,0,0.536-14.564,12.362-15.832L53.334,50.258z'
              />
              <path
                fill='#CAE0FF'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M98.624,50.033
            c0,0,15.614,28.443-3.122,44.314c0,0-0.678-14.562-12.515-15.719L98.624,50.033z'
              />
            </g>
            <path
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='M99.041,51.033
          c0.086,17.428-10.17,31.607-22.904,31.668C63.402,82.763,53.01,68.687,52.926,51.26c-0.086-17.423,10.14-37.46,22.875-37.521
          C88.531,13.674,98.955,33.608,99.041,51.033z'
            />
            <circle
              fill='#9DC3ED'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              cx='75.925'
              cy='39.072'
              r='3.792'
            />
            <circle
              fill='#CAE0FF'
              stroke='#CAE0FF'
              strokeMiterlimit='10'
              cx='75.741'
              cy='4.235'
              r='3.236'
            />
            <path
              fill='#CAE0FF'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='M80.578,91.123
          c0.006,1.822-1.461,3.303-3.277,3.312l-2.213,0.012c-1.818,0.008-3.301-1.463-3.311-3.277L71.59,52.855
          c-0.008-1.822,1.46-3.303,3.279-3.311l2.213-0.014c1.816-0.006,3.298,1.463,3.308,3.281L80.578,91.123z'
            />
            <line
              fill='none'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='59.598'
              y1='25.916'
              x2='92.065'
              y2='25.916'
            />
          </g>
          <path
            fill='#0E131D'
            d='M145.888,109.619c-1.04-3.213-4.052-5.537-7.609-5.537c-0.707,0-1.388,0.102-2.041,0.273
        c-0.58-7.527-6.858-13.459-14.535-13.459c-8.06,0-14.593,6.533-14.593,14.594c0,2.113,0.459,4.115,1.267,5.93
        c-0.404-0.121-0.823-0.207-1.267-0.207c-1.444,0-2.714,3.701-3.523,4.77c-8.213-0.502-46.089-0.625-53.073-0.314
        c0.221-0.826,0.35-4.689,0.35-5.586c0-5.521-4.478-10-10-10c-0.328,0-0.651,0.02-0.971,0.049c-2.11-5.551-7.465-9.504-13.757-9.504
        c-6.291,0-11.646,3.953-13.757,9.504c-0.319-0.029-0.643-0.049-0.971-0.049c-5.522,0-10,4.479-10,10c0,5.523,4.478,10,10,10
        c2.516,0,132.912,0,134.121,0c2.899,0,5.25-2.35,5.25-5.25C150.778,112.056,148.616,109.806,145.888,109.619z'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M11.461,107.082
        c-0.059-0.535-0.089-1.076-0.089-1.627c0-8.189,6.639-14.828,14.828-14.828c6.294,0,11.672,3.922,13.824,9.455'
          />
          <path
            fill='none'
            stroke='#202B40'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M35.755,102.972
        c1.455-1.518,3.504-2.463,5.773-2.463c3.749,0,6.896,2.58,7.764,6.062'
          />
          <path
            fill='#0E131D'
            d='M91.799,116.365c0.009-0.15,0.023-0.301,0.023-0.453c0-4.344-3.521-7.865-7.865-7.865
        c-4.343,0-7.864,3.521-7.864,7.865c0,0.152,0.015,0.303,0.023,0.453H91.799z'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M93.746,110.07
        c-1.194-2.824-3.99-4.805-7.248-4.805'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M78.205,110.834
        c-1.81-4.279-6.047-7.281-10.984-7.281c-5.369,0-9.91,3.549-11.4,8.43'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M108.066,111.568
        c-0.88-1.889-1.371-3.994-1.371-6.213c0-8.135,6.594-14.729,14.728-14.729c7.543,0,13.762,5.672,14.625,12.982'
          />
          <path
            fill='#0E131D'
            stroke='#202B40'
            strokeMiterlimit='10'
            d='M111.382,113.427c0.012-0.191,0.029-0.381,0.029-0.574
        c0-5.492-4.451-9.943-9.942-9.943c-5.49,0-9.941,4.451-9.941,9.943c0,0.193,0.019,0.383,0.029,0.574'
          />
        </g>
      </svg>
    );
  }
}
