/**
 * These helpers are used to split or compile product module code into virtual files from specific
 * comments that also store json payloads.
 *
 * When extending in the future, more data can easily be added to the JSON blobs in the comments.
 */

// Example "fully compiled" product module code (remove fist line of comments)
// // Code that lives in Main.js
// var maincode = "hello";
// /* VIRTUAL FILE SPLIT MARK | BLOB_START { "file_name": "file1" } BLOB_END */
// // Code that lives in file1.js
// var codeFile1 = "hello";
// /* VIRTUAL FILE SPLIT MARK | BLOB_START { "file_name": "file2" } BLOB_END */
// // Code that lives in file2.js
// var codeFile2 = "hello";


export interface VirtualFile {
  fileName: string;
  code: string;
}

export const splitCodeIntoVirtualFiles = (fullCode: string): VirtualFile[] => {

  const getMatches = (s: string, regex: RegExp, index?: number): string[] => {
    const i = index || (index = 1); // default to the first capturing group
    const matches = [];
    let match;
    while (match = regex.exec(s))
      matches.push(match[i]);
    return matches;
  };

  const fileSplitRegex = /\/\* VIRTUAL FILE SPLIT MARK \| BLOB_START \{[a-zA-Z\d\"\:\_\s]+\} BLOB_END \*\/\n/g;
  const codeSnippets = fullCode.split(fileSplitRegex);
  if (codeSnippets[0] === '\n')
    codeSnippets.shift();

  const blobRegex = /\/\* VIRTUAL FILE SPLIT MARK \| BLOB_START (\{[a-zA-Z\d\"\:\_\s]+\}) BLOB_END \*\/\n/g;
  const rawBlobs: any = getMatches(fullCode, blobRegex);
  const jsonBlobs: any = rawBlobs.map(JSON.parse);

  const fileNames: string[] = [];
  if (jsonBlobs.length < codeSnippets.length) // If the first section doesnt have a blob
    if (codeSnippets[0] !== '\n')
      fileNames.push('Main.js');
  jsonBlobs.forEach((b: any) => fileNames.push(b.file_name + '.js'));

  const files: VirtualFile[] = codeSnippets.map((s, i) => ({ fileName: fileNames[i], code: s }));
  return files;
};

export const compileFullCodeFromVirtualFiles = (files: VirtualFile[]): string => {
  const fullCode = files.map(f => {
    const blob = { file_name: f.fileName.replace(/.js/, '') };
    if (f.fileName.replace(/.js/, '') === 'Main')
      return f.code; // Do not add a split mark for Main.js, just leave it at the top
    return `/* VIRTUAL FILE SPLIT MARK | BLOB_START ${JSON.stringify(blob)} BLOB_END */${'\n'}${f.code}`;
  }).reduce((snippet, compiled) => snippet + compiled);

  return fullCode;
};
