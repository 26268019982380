import React, { Component } from 'react';
import Switch from 'react-switch';

export default class ToggleSwitch extends Component<any, any> {
  state = {
    checked: false,
  };

  componentDidMount() {
    this.props.onToggleInputChange(this.state.checked);
  }

  render() {
    const { id, leftText, rightText, onColor, keyVal } = this.props;

    return (
      <div className='form-group col-sm-6'>
        <label className='form-label' htmlFor={keyVal}>
          {this.props.label}
        </label>
        <label htmlFor={id} className='toggle-switch-label-container'>
          {leftText && <span className='toggle-switch-text'>{leftText}</span>}
          <Switch
            key={`radio-input-${keyVal}`}
            handleDiameter={20}
            onColor={onColor || '#e04870'}
            offColor={'#c3c3c3'}
            height={24}
            width={48}
            id={id}
            uncheckedIcon={false}
            checkedIcon={false}
            checked={this.state.checked}
            onChange={() => {
              this.setState(
                (currentState: any) => ({
                  checked: currentState.checked === true ? false : true,
                }),
                () => {
                  this.props.onToggleInputChange(this.state.checked);
                },
              );
            }}
          />
          {rightText && <span className='toggle-switch-text'>{rightText}</span>}
        </label>
      </div>
    );
  }
}
