import React from 'react';
import { inject, observer } from 'mobx-react';
import PageHeader from '../../../components/page-header';
import { OrganizationFeatureListStore } from '../../../features/stores/organization-feature-list-store';
import RenderOrganizationFeatureListTable from '../../../features/views/feature-list/organization-feature-table';
import { Button } from 'reactstrap';
import { AddOrganizationFeatureFlag } from '../../../features/views/feature-list/new-organization-feature-modal';
import { FeatureListStore } from '../../../features/stores/feature-list-store';

interface Props {
  organizationFeatureListStore?: OrganizationFeatureListStore;
  featureListStore?: FeatureListStore;
  params: {
    organizationId: string;
  };
}

interface State {
  showNewFeatureFlag: boolean;
}

@inject('organizationFeatureListStore')
@inject('featureListStore')
@observer
class OrganizationFeatures extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNewFeatureFlag: false,
    };
  }

  async componentDidMount() {
    const { organizationFeatureListStore, featureListStore, params } = this.props;
    if (organizationFeatureListStore) {
      await organizationFeatureListStore.load({ organizationId: params.organizationId });
    }
    if (featureListStore) {
      await featureListStore.load();
    }
  }

  renderEditInput = () => {
    return (
      <div className='pull-right'>
        <Button
          color='primary'
          onClick={() => this.setState({ showNewFeatureFlag: true })}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          New
        </Button>
      </div>
    );
  };

  render() {
    const { organizationFeatureListStore, featureListStore, params } = this.props;
    const { showNewFeatureFlag } = this.state;

    if (!organizationFeatureListStore || !featureListStore) {
      return <div>Loading ...</div>;
    }

    return (
      <div>
        {showNewFeatureFlag && (
          <AddOrganizationFeatureFlag
            open={showNewFeatureFlag}
            close={() => this.setState({ showNewFeatureFlag: false })}
            features={featureListStore.features}
            organizationId={params.organizationId}
          />
        )}
        <div style={{ marginLeft: 5 }}>
          <br />
          <PageHeader
            title={'Summary'}
            rightComponent={this.renderEditInput()}
          />
        </div>
        <RenderOrganizationFeatureListTable
          rows={organizationFeatureListStore.features}
          isLoaded={true}
          selected={(organizationFeature, index) => {}}
        />
      </div>
    );
  }
}

export default OrganizationFeatures;
