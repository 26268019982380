import { Moment, utc as moment } from 'moment';
import { Construct } from '../../shared/domain/construct';
import { Requestee } from '../../shared/domain/requestee';


enum ProductModuleDefinitionMarkdownType {
  Readme = 'readme',
  Changelog = 'changelog'
}
export class ProductModuleDefinitionMarkdown {
  readonly productModuleDefinitionMarkdownId: string;
  readonly markdown: string;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly type: ProductModuleDefinitionMarkdownType;

  constructor(init: Construct<ProductModuleDefinitionMarkdown>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionMarkdown): ProductModuleDefinitionMarkdown {
    return new ProductModuleDefinitionMarkdown({
      productModuleDefinitionMarkdownId: init.product_module_definition_markdown_id,
      markdown: init.markdown,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      type: init.type,
    });
  }
}

export interface NetworkProductModuleDefinitionMarkdown {
  product_module_definition_markdown_id: string;
  markdown: string;
  created_at: string;
  created_by: object;
  type: ProductModuleDefinitionMarkdownType;
}
