import { observable, action } from 'mobx';
import { SavingState } from '../util';

export enum StoreIndex {
  policyLifecycleComponents = 'policyLifecycleComponents',
  productModuleSettingsComponents = 'productModuleSettingsComponents',
  productModuleDocuments = 'productModuleDocuments',
  productModuleBillingComponents = 'productModuleBillingComponents',
}

export class ProductModuleComponentLoadingStateStore {
  @observable.deep policyLifecycleComponents: any = {};
  @observable.deep productModuleSettingsComponents: any = {};
  @observable.deep productModuleDocuments: any = {};
  @observable.deep productModuleBillingComponents: any = {};

  @action addComponentToStore = (
    dataSetName: StoreIndex,
    componentKeyName: string,
  ) => {
    this[dataSetName] = {
      ...this[dataSetName],
      [componentKeyName]: false,
    };
  };

  @action updateComponentLoadingState = (
    dataSetName: StoreIndex,
    componentKeyName: string,
    value: SavingState,
  ) => {
    this[dataSetName] = {
      ...this[dataSetName],
      [componentKeyName]: value,
    };
  };

  @action getComponentLoadingState = (
    dataSetName: StoreIndex,
    componentKeyName: string,
  ) => {
    return this[dataSetName as StoreIndex][componentKeyName];
  };
}

export const productModuleComponentLoadingStateStore = new ProductModuleComponentLoadingStateStore();

export class GracePeriodStore extends ProductModuleComponentLoadingStateStore {}
