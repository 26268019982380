import React from 'react';
import { observer, inject } from 'mobx-react';
import { TemplateViewStore } from './stores/template-view-store';
import { TemplateListStore } from './stores/template-list-store';
import { DataExportListStore } from './stores/data-export-list-store';
import { DataExportTableBody, DataExportsTableHeader } from '../running-exports';
import { Template } from './domain/templates/template';
import { ScheduledDataExport } from './domain/scheduled-data-export';

interface Props {}
interface Injected extends Props {
  templateListStore: TemplateListStore;
  dataExportListStore: DataExportListStore;
  templateViewStore: TemplateViewStore;
}

interface State {}

@inject('dataExportListStore')
@inject('templateListStore')
@inject('templateViewStore')
@observer
export class ActiveExports extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.injected.templateListStore.setTemplateListStoreLoadingTrue();
    this.injected.dataExportListStore.setDataExportListStoreLoadingTrue();
    this.injected.templateViewStore.setTemplateViewStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  componentDidMount() {
    const { templateViewStore, templateListStore, dataExportListStore } = this.injected;

    templateViewStore.load({
      organizationId: this.organizationId,
      templateId: window.location.pathname.split('/')[5],
    });

    templateListStore.load({
      organizationId: this.organizationId,
    });

    dataExportListStore.load({
      organizationId: this.organizationId,
    });
  }

  getActiveExportsRows(templates: Template[], scheduledDataExports: ScheduledDataExport[]) {
    return (
      <DataExportTableBody
        templates={templates}
        scheduledDataExports={scheduledDataExports}
      />
    );
  }

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>This template has no active exports!</p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  render() {
    const { templateViewStore, dataExportListStore, templateListStore } = this.injected;

    const { scheduledDataExports } = dataExportListStore;
    const { templates } = templateListStore;
    const { template } = templateViewStore;
    const activeExports = template && template.getActiveExports(scheduledDataExports) || [];

    return (
      <div>
        {activeExports.length > 0 ? (
          <table style={{ width: '100%' }}>
            <DataExportsTableHeader />
            {this.getActiveExportsRows(templates, activeExports)}
          </table>
        ) : (
          this.renderEmpty()
        )}
      </div>
    );
  }
}
