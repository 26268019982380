export const addAlert = (headline, message, alertType) => {
  return dispatch => {
    dispatch({
      type: 'ADD_ALERT',
      headline,
      message,
      alertType,
    });
  };
};

export const removeAlert = alert => {
  return dispatch => {
    dispatch({
      type: 'REMOVE_ALERT',
      alert,
    });
  };
};
