import React from 'react';
import { CardBody, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import '../../../../../../../../styles/styles.scss';
import { observer, inject } from 'mobx-react';
import '../../styles/general.scss';
import { Store, ObservablesTemp, Observables } from '../../store';
import WaitingPeriodRule from './components/waiting-period-rule';
import WaitingPeriodNotification from './components/waiting-period-notification-rule';
import { FormOptions } from './config';
import { flattenObject, displayBasedOnCount } from '../../utils';
import { savePolicyLifecycle } from '../../orchestration';
import {
  ProductModuleComponentLoadingStateStore,
  StoreIndex,
} from '../../../../../../stores/product-module-component-loading-state-store';
import { ProductModuleStore } from '../../../../../../stores/product-module-store';
import { nestedStyleCheckbox } from '../../../../utils';
import { debounce } from '../../../../../../../../../helpers/debounce';
import { SavingState } from '../../../../../../util';

export enum StateEnum {
  countRules = 'countRules',
  countNotifications = 'countNotifications',
}

interface Props {
  store: Store;
  productModuleStore?: ProductModuleStore;
  closeWindow: () => void;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface State {
  countRules: number;
  countNotifications: number;
}

const waitingPeriodKey = 'waitingPeriod';
@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
class WaitingPeriod extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      countRules: FormOptions.startingCountRules,
      countNotifications: FormOptions.startingCountNotifications,
    };
  }

  saveAndClose = async () => {
    const {
      store,
      closeWindow,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    store.updateTempToPrimary(
      Observables.waitingPeriodNotifications,
      ObservablesTemp.waitingPeriodNotificationsTemp,
    );
    store.updateTempToPrimary(
      Observables.waitingPeriodRules,
      ObservablesTemp.waitingPeriodRulesTemp,
    );
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        waitingPeriodKey,
        SavingState.Saving,
      );
    }

    if (productModuleStore) {
      await savePolicyLifecycle(
        store.lifecycleStore,
        productModuleStore.productModuleDefinitionDraft,
      );
      productModuleStore.init(productModuleStore.loadedProductModuleKey, true);
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        waitingPeriodKey,
        SavingState.Saved,
      );

      debounce(
        waitingPeriodKey,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.policyLifecycleComponents,
            waitingPeriodKey,
            SavingState.Default,
          ),
        3000,
      );
    }
    closeWindow();
  };

  cancelAndClose = () => {
    const { store, closeWindow } = this.props;
    store.updatePrimaryToTemp(
      Observables.waitingPeriodNotifications,
      ObservablesTemp.waitingPeriodNotificationsTemp,
    );
    store.updatePrimaryToTemp(
      Observables.waitingPeriodRules,
      ObservablesTemp.waitingPeriodRulesTemp,
    );
    closeWindow();
  };

  componentWillMount() {
    const { store, productModuleComponentLoadingStateStore } = this.props;

    store.updatePrimaryToTemp(
      Observables.waitingPeriodNotifications,
      ObservablesTemp.waitingPeriodNotificationsTemp,
    );
    store.updatePrimaryToTemp(
      Observables.waitingPeriodRules,
      ObservablesTemp.waitingPeriodRulesTemp,
    );

    this.setState({
      countRules:
        Object.keys(store.lifecycleStoreTemp.waitingPeriodRulesTemp).length ||
        FormOptions.startingCountRules,
      countNotifications:
        Object.keys(store.lifecycleStoreTemp.waitingPeriodNotificationsTemp)
          .length || FormOptions.startingCountNotifications,
    });

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.policyLifecycleComponents,
        'gracePeriod',
      );
    }
  }

  showSaveButton = (store: Store) => {
    let valid = true;

    const { lifecycleStoreTemp } = store;
    const {
      waitingPeriodRulesTemp,
      waitingPeriodNotificationsTemp,
    } = lifecycleStoreTemp;

    const waitingPeriodRulesTempFlat = flattenObject(waitingPeriodRulesTemp);

    const waitingPeriodNotificationsTempFlat = flattenObject(
      waitingPeriodNotificationsTemp,
    );

    for (const key in waitingPeriodRulesTempFlat) {
      if (!waitingPeriodRulesTempFlat[key]) {
        return (valid = false);
      }
    }

    for (const key in waitingPeriodNotificationsTempFlat) {
      if (!waitingPeriodNotificationsTempFlat[key]) {
        return (valid = false);
      }
    }
    return valid;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      countRules:
        Object.keys(nextProps.store.lifecycleStoreTemp.waitingPeriodRulesTemp)
          .length || FormOptions.startingCountRules,
      countNotifications:
        Object.keys(
          nextProps.store.lifecycleStoreTemp.waitingPeriodNotificationsTemp,
        ).length || FormOptions.startingCountNotifications,
    });
  }

  addRule = (countState: StateEnum) => {
    let countTemp: number = this.state[countState];
    countTemp++;
    this.setState({ [countState]: countTemp } as Pick<State, keyof State>);
  };

  reduceCount = (countState: StateEnum) => {
    let count = this.state[countState];
    count--;
    this.setState({ [countState]: count } as Pick<State, keyof State>);
    if (count === 0) {
      this.setState({ countNotifications: 0 });
      this.props.store.resetLifecycleStoreTemp(
        ObservablesTemp.waitingPeriodNotificationsTemp,
      );
    }
  };

  renderChildrenRules = (count: number) => {
    const components = [];
    const { store } = this.props;

    for (let i = 0; i < count; i++) {
      components.push(
        <WaitingPeriodRule
          index={i}
          allowList={FormOptions.allowListRules}
          reduceCount={() => this.reduceCount(StateEnum.countRules)}
          key={`${WaitingPeriodRule}_${i}`}
          store={this.props.store}
          waitingPeriodRulesTemp={
            store.lifecycleStoreTemp.waitingPeriodRulesTemp
          }
          removeFromLifeCycleStore={(lifecycleObservable: ObservablesTemp) =>
            store.removeFromLifeCycleStore(lifecycleObservable, i)
          }
        />,
      );
    }
    return components;
  };

  renderChildrenNotifications = (count: number) => {
    const components = [];
    const { store } = this.props;

    for (let i = 0; i < count; i++) {
      components.push(
        <WaitingPeriodNotification
          index={i}
          allowList={FormOptions.allowListNotifications}
          reduceCount={() => this.reduceCount(StateEnum.countNotifications)}
          key={`${WaitingPeriodNotification}_${i}`}
          store={this.props.store}
          waitingPeriodNotificationsTemp={
            store.lifecycleStoreTemp.waitingPeriodNotificationsTemp
          }
          removeFromLifeCycleStore={(lifecycleObservable: ObservablesTemp) =>
            store.removeFromLifeCycleStore(lifecycleObservable, i)
          }
        />,
      );
    }
    return components;
  };

  addButton = (type: StateEnum) => {
    return (
      <Button
        className='product-module-definition-lifecycle-component-editing-buttons'
        color='link'
        onClick={() => this.addRule(type)}
      >
        + Add {type === StateEnum.countRules ? 'Rule' : 'Notification'}
      </Button>
    );
  };

  render() {
    const allowListRules = FormOptions.allowListRules;
    const showNotifications = FormOptions.showNotifications;
    const { countRules, countNotifications } = this.state;
    const addRulesDisplay =
      allowListRules &&
      displayBasedOnCount(this.state.countRules, FormOptions.maxCountRules);

    const showDoneInRules = showNotifications && this.state.countRules > 0;

    return (
      <div className='product-module-definition-lifecycle-rules-position'>
        <p className='product-module-heading-description-spacing'>
          The period after a policy is issued when a claim will be invalid.
        </p>
        <Row>
          <Col xs={12}>
            <CardBody style={nestedStyleCheckbox(true, 1)}>
              <h5 className='lifecycle-component-heading'>
                Waiting period rules
              </h5>

              <Form>
                {this.renderChildrenRules(countRules)}
                <FormGroup className='product-modules-form-group-align'>
                  {!showDoneInRules && (
                    <Button
                      disabled={!this.showSaveButton(this.props.store)}
                      onClick={() => this.saveAndClose()}
                      color='primary'
                      className='product-module-definition-lifecycle-component-editing-buttons'
                    >
                      Done
                    </Button>
                  )}
                  {addRulesDisplay && this.addButton(StateEnum.countRules)}
                  {!showDoneInRules && (
                    <Button
                      onClick={() => this.cancelAndClose()}
                      color='link'
                      className='product-module-definition-lifecycle-component-editing-buttons'
                    >
                      Cancel
                    </Button>
                  )}
                </FormGroup>
              </Form>
              {showNotifications && countRules > 0 && (
                <div>
                  <h5 className='lifecycle-component-heading'>Notifications</h5>
                  <div>
                    {this.renderChildrenNotifications(countNotifications)}
                    {
                      <Form>
                        <FormGroup style={{ marginLeft: -8, marginTop: 20 }}>
                          {showDoneInRules && (
                            <Button
                              disabled={!this.showSaveButton(this.props.store)}
                              onClick={() => this.saveAndClose()}
                              color='primary'
                              className='product-module-definition-lifecycle-component-editing-buttons'
                            >
                              Done
                            </Button>
                          )}
                          {this.addButton(StateEnum.countNotifications)}
                          {showDoneInRules && (
                            <Button
                              onClick={() => this.cancelAndClose()}
                              color='link'
                              className='product-module-definition-lifecycle-component-editing-buttons'
                            >
                              Cancel
                            </Button>
                          )}
                        </FormGroup>
                      </Form>
                    }
                  </div>
                </div>
              )}
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WaitingPeriod;
