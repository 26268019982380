/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='85px'
        height='87.555px'
        viewBox='0 0 85 87.555'
        enableBackground='new 0 0 85 87.555'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#CCDEF3'
            d='M84.125,33.719c0-3.14-2.867-5.684-6.402-5.684H8.208c-3.537,0-6.4,2.544-6.4,5.684v46.248
        c0,3.139,2.863,5.683,6.4,5.683h69.515c3.535,0,6.402-2.544,6.402-5.683V33.719z'
          />
          <g>
            <path
              fill='#212B40'
              d='M16.388,63.441c0,1.323-1.07,2.394-2.392,2.394l0,0c-1.322,0-2.393-1.07-2.393-2.394l0,0
          c0-1.321,1.07-2.392,2.393-2.392l0,0C15.317,61.049,16.388,62.12,16.388,63.441L16.388,63.441z'
            />
            <path
              fill='#212B40'
              d='M26.045,63.441c0,1.323-1.071,2.394-2.393,2.394l0,0c-1.32,0-2.391-1.07-2.391-2.394l0,0
          c0-1.321,1.07-2.392,2.391-2.392l0,0C24.974,61.049,26.045,62.12,26.045,63.441L26.045,63.441z'
            />
            <path
              fill='#212B40'
              d='M35.702,63.441c0,1.323-1.072,2.394-2.391,2.394l0,0c-1.323,0-2.394-1.07-2.394-2.394l0,0
          c0-1.321,1.071-2.392,2.394-2.392l0,0C34.629,61.049,35.702,62.12,35.702,63.441L35.702,63.441z'
            />
            <path
              fill='#212B40'
              d='M45.36,63.441c0,1.323-1.072,2.394-2.395,2.394l0,0c-1.32,0-2.393-1.07-2.393-2.394l0,0
          c0-1.321,1.072-2.392,2.393-2.392l0,0C44.288,61.049,45.36,62.12,45.36,63.441L45.36,63.441z'
            />
            <path
              fill='#212B40'
              d='M55.014,63.441c0,1.323-1.07,2.394-2.393,2.394l0,0c-1.32,0-2.393-1.07-2.393-2.394l0,0
          c0-1.321,1.072-2.392,2.393-2.392l0,0C53.944,61.049,55.014,62.12,55.014,63.441L55.014,63.441z'
            />
            <path
              fill='#212B40'
              d='M64.67,63.441c0,1.323-1.07,2.394-2.393,2.394l0,0c-1.318,0-2.391-1.07-2.391-2.394l0,0
          c0-1.321,1.072-2.392,2.391-2.392l0,0C63.6,61.049,64.67,62.12,64.67,63.441L64.67,63.441z'
            />
            <path
              fill='#212B40'
              d='M74.327,63.441c0,1.323-1.07,2.394-2.391,2.394l0,0c-1.322,0-2.393-1.07-2.393-2.394l0,0
          c0-1.321,1.07-2.392,2.393-2.392l0,0C73.256,61.049,74.327,62.12,74.327,63.441L74.327,63.441z'
            />
          </g>
          <path
            fill='#9FC3E7'
            d='M4.275,29.241c-1.5,1.04-2.468,2.657-2.468,4.479v46.248c0,1.821,0.968,3.438,2.468,4.478V29.241z'
          />
          <path
            fill='#FFFFFF'
            d='M84.125,39.559v-5.84c0-3.14-2.867-5.684-6.402-5.684H8.208c-3.537,0-6.4,2.544-6.4,5.684v5.84H84.125z'
          />
          <line
            fill='none'
            stroke='#212B40'
            strokeWidth='3'
            strokeMiterlimit='10'
            x1='0'
            y1='39.559'
            x2='85'
            y2='39.559'
          />
          <path
            fill='#212B40'
            stroke='#212B40'
            strokeMiterlimit='10'
            d='M69.176,28.339c0,14.601-11.836,26.436-26.432,26.436
        c-14.601,0-26.437-11.835-26.437-26.436c0-14.598,11.836-26.434,26.437-26.434C57.34,1.906,69.176,13.742,69.176,28.339z'
          />
          <path
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M56.62,12.074
        c4.592,3.921,7.504,9.753,7.504,16.266c0,11.81-9.572,21.382-21.379,21.382c-11.811,0-21.383-9.572-21.383-21.382
        c0-11.808,9.572-21.38,21.383-21.38'
          />
          <path
            fill='#CCDEF3'
            d='M53.204,27.996c0-1.274-1.033-2.31-2.307-2.303h-0.682v-4.137c-0.002-3.704-3.016-6.711-6.717-6.708
        c-3.699,0.002-6.705,3.015-6.705,6.711l0.002,4.139h-0.24c-1.271,0-2.305,1.033-2.305,2.306l0.008,8.075
        c0,1.272,1.031,2.305,2.303,2.305l14.342-0.007c1.271,0,2.305-1.035,2.305-2.305L53.204,27.996z M39.266,21.559
        c0-2.336,1.898-4.236,4.232-4.238c2.34-0.002,4.24,1.899,4.24,4.235l0.004,4.137l-8.475,0.005L39.266,21.559z'
          />
          <polyline
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            points='55.641,19.753
        55.637,11.256 64.135,11.256   '
          />
          <path
            fill='#9FC3E7'
            d='M36.692,25.698h-0.137c-1.271,0-2.305,1.033-2.305,2.306l0.008,8.075c0,1.272,1.031,2.305,2.303,2.305
        h0.131V25.698z'
          />
        </g>
      </svg>
    );
  }
}
