import { Construct } from './construct';

export enum RequesteeType {
  ApiKey = 'api_key',
  User = 'user',
  LegacyApiKey = 'legacy_api_key',
  LegacyUserApiKey = 'legacy_user_api_key',
  System = 'system',
}

export class Requestee {

  public type: RequesteeType;
  public id?: string;
  public ownerId?: string;

  constructor(init: Construct<Requestee>) {
    Object.assign(this, init);
  }


  toJSON(): object {
    return {
      type: this.type,
      id: this.id && this.id.toString(),
      ownerId: this.ownerId && this.ownerId.toString(),
    };
  }

  static fromJSON(data: any) {
    return new Requestee({
      type: data.type,
      id: data.id,
      ownerId: data.ownerId,
    });
  }

  static fromNetwork(data: any) {
    return new Requestee({
      type: data.type,
      id: data.id,
      ownerId: data.owner_id,
    });
  }

  static apiKey(apiKeyId: string, ownerId: string) {
    return new Requestee({
      type: RequesteeType.ApiKey,
      id: apiKeyId,
      ownerId,
    });
  }

  static user(userId: string) {
    return new Requestee({
      type: RequesteeType.User,
      id: userId,
    });
  }

  static legacyUserApiKey(userId: string) {
    return new Requestee({
      type: RequesteeType.LegacyUserApiKey,
      id: userId,
    });
  }

  static legacyApiKey(orgId: string) {
    return new Requestee({
      type: RequesteeType.LegacyApiKey,
      id: orgId,
    });
  }
}
