import React from 'react';
import ReactDOM from 'react-dom';
import ajax from '../../helpers/ajax';
import { initAccount } from '../actions/init-account';
import { connect } from 'react-redux';
import {
  Row,
  FormGroup,
  CardGroup,
  Card,
  CardBody,
  Media,
  Input,
  InputGroup,
  Label,
  Container,
  Col,
} from 'reactstrap';
import { Alert } from '../../components/alerts/alerts';
import Images from '../../components/svg-images';
import Button from '../../components/loading-button';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOTP: false,
      loading: false,
      otpType: undefined,
      errorMessage: undefined,
      email: undefined,
      password: undefined,
      resendingOtp: false,
    };
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this.refs.email).focus();
    history.replaceState({}, document.title, '/login'); // eslint-disable-line
  }

  resendOtp() {
    this.setState({ resendingOtp: false });
    const email = this.state.email;
    const password = this.state.password;
    // this.setState({ loading: true });
    ajax({
      dispatch: this.props.dispatch,
      type: 'POST',
      path: '/accounts/login',
      data: { email, password },
    })
      .then((result) => {
        this.setState({ loading: false });
        this.setState({ errorMessage: undefined });
        this.setState({ resendingOtp: true });
      })
      .catch((error) => {
        error = JSON.parse(error.message);
        if (error.message === '2FA required.') {
          this.setState({ resendingOtp: true });
          ReactDOM.findDOMNode(this.refs.otp).value = '';
          ReactDOM.findDOMNode(this.refs.otp).focus();
        } else {
          this.setState({ loading: false });
          this.setState({ errorMessage: error.message });
          this.setState({ resendingOtp: false });
          ReactDOM.findDOMNode(this.refs.password).value = '';
          console.error('Login Error:', error.message);
        }
      });
  }

  handleLogin(e) {
    e.preventDefault();
    const storage = window.localStorage;

    if (!this.state.showOTP) {
      const email = ReactDOM.findDOMNode(this.refs.email).value.toLowerCase();
      const password = ReactDOM.findDOMNode(this.refs.password).value;

      this.setState(
        { email, password, loading: true, resendingOtp: false },
        () => {
          ajax({
            dispatch: this.props.dispatch,
            type: 'POST',
            path: '/accounts/login',
            data: { email, password },
          })
            .then((result) => {
              if (result.success) {
                storage.setItem('user_id', result.user_id);
                storage.setItem('user_state', 'activated');
                storage.setItem('login_token', result.login_token);
                storage.setItem('user_email', email);
                return this.props.dispatch(initAccount());
              }
            })
            .then((result) => {
              if (
                this.props.router.location.state &&
                this.props.router.location.state.nextPathname
              ) {
                this.props.router.push(
                  this.props.router.location.state.nextPathname,
                );
              } else {
                this.props.router.push('analytics');
              }
            })
            .catch((error) => {
              error = JSON.parse(error.message);

              this.setState({ loading: false });
              this.setState({ errorMessage: undefined });
              if (error.message === '2FA required.') {
                this.setState({ showOTP: true });
                this.setState({ otpType: error.otp });
                ReactDOM.findDOMNode(this.refs.otp).value = '';
                ReactDOM.findDOMNode(this.refs.otp).focus();
              } else {
                this.setState({ loading: false });
                this.setState({ errorMessage: error.message });
                ReactDOM.findDOMNode(this.refs.password).value = '';
                console.error('Login Error:', error.message);
              }
            });
        },
      );
    } else {
      const code = ReactDOM.findDOMNode(this.refs.otp).value;
      const email = this.state.email;
      const password = this.state.password;
      this.setState({ loading: true });
      this.setState({ resendingOtp: false });
      this.setState({ errorMessage: undefined });
      ajax({
        dispatch: this.props.dispatch,
        type: 'POST',
        path: '/accounts/login',
        data: { email, password, code },
      })
        .then((result) => {
          storage.setItem('user_id', result.user_id);
          storage.setItem('user_state', 'activated');
          storage.setItem('login_token', result.login_token);
          storage.setItem('user_email', email);
          return this.props.dispatch(initAccount());
        })
        .then((result) => {
          if (
            this.props.router.location.state &&
            this.props.router.location.state.nextPathname
          ) {
            this.props.router.push(
              this.props.router.location.state.nextPathname,
            );
          } else {
            this.props.router.push('analytics');
          }
        })
        .catch((error) => {
          error = JSON.parse(error.message);
          this.setState({ loading: false });
          this.setState({ errorMessage: error.message });
        });
    }
  }

  renderEmailPasswordPage() {
    const errorMessage = this.state.errorMessage;
    return (
      <CardGroup>
        <Card className='text-white bg-primary' style={{ width: '40%' }}>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Media
              object
              src='/images/lego-alfred-upper.png'
              alt='Alfred Logo'
              style={{ width: '60%' }}
            />
            <h4 className='mt-4'>Welcome to the Batcave.</h4>
          </CardBody>
        </Card>
        <Card className='p-4'>
          <CardBody>
            <h1 className='mb-4'>Login</h1>
            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
            <form onSubmit={this.handleLogin.bind(this)}>
              <InputGroup className='mb-3'>
                <Input type='email' placeholder='Email' ref='email' />
              </InputGroup>
              <InputGroup className='mb-4'>
                <Input type='password' placeholder='Password' ref='password' />
              </InputGroup>
              <Row>
                <Col xs='6'>
                  <Button
                    color='primary'
                    className='px-4'
                    type='submit'
                    loading={this.state.loading}
                  >
                    Login
                  </Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </CardGroup>
    );
  }

  renderOTPPage() {
    const appBased = this.state.otpType === 'app_based';
    const message = appBased
      ? 'Check your 2FA app.'
      : 'Check your phone for an SMS.';
    const icon = appBased ? <Images.appOtpWhite /> : <Images.smsOtpWhite />;
    const errorMessage = this.state.errorMessage;
    const resendingOtp = this.state.resendingOtp;

    return (
      <CardGroup>
        <Card className='text-white bg-primary' style={{ width: '40%' }}>
          <CardBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
            <h2>2 Factor Auth</h2>
            <p>{message}</p>
          </CardBody>
        </Card>
        <Card className='p-4'>
          <CardBody>
            <h1 className='mb-4'>Security Code</h1>
            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
            {resendingOtp && (
              <Alert color='success'>A new code has been sent.</Alert>
            )}
            <form onSubmit={this.handleLogin.bind(this)}>
              <FormGroup className='mb-3'>
                <Label>6-digit verification code</Label>
                <Input
                  type='text'
                  placeholder='6-digit code'
                  name='otp'
                  autoComplete='off'
                  ref='otp'
                />
              </FormGroup>
              <Row>
                <Col xs='6'>
                  <Button
                    color='primary'
                    className='px-4'
                    type='submit'
                    loading={this.state.loading}
                  >
                    Login
                  </Button>
                </Col>
                <Col xs='6' className='text-right'>
                  {appBased ? undefined : (
                    <Button
                      color='link'
                      onClick={this.resendOtp.bind(this)}
                      className='px-0'
                    >
                      Resend code
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </CardGroup>
    );
  }

  render() {
    return (
      <div
        className='flex-row align-items-center'
        style={{ height: '100vh', display: 'flex' }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col md='8'>
              {this.state.showOTP
                ? this.renderOTPPage()
                : this.renderEmailPasswordPage()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect()(Login);
