import moment, { Moment } from 'moment';
import { Construct } from '../../../../shared/domain/construct';

export class DataExportRunLogItem {
  readonly dataExportRunId: string;
  readonly createdAt: Moment;
  readonly level: LogLevel;
  readonly content: string;
  readonly stack?: string;

  constructor(init: Construct<DataExportRunLogItem>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkDataExportRunLogItem): DataExportRunLogItem {
    return new DataExportRunLogItem({
      dataExportRunId: init.data_export_run_id,
      createdAt: moment(init.created_at),
      level: init.level,
      content: init.content,
      stack: init.stack,
    });
  }
}

export interface NetworkDataExportRunLogItem {
  data_export_run_id: string;
  created_at: string;
  level: LogLevel;
  content: string;
  stack?: string;
}

export enum LogLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
