import React from 'react';
import { Route, IndexRedirect } from 'react-router';

// Components
import Users from './components/users';
import User from './components/user';
import Details from './components/details';
import Security from './components/security';
import Organizations from './components/organizations';

// Components
import Applications from '../applications/components/applications';
import Application from '../applications/components/application';

export default (
  <Route name='users'>
    <IndexRedirect to='all' />
    <Route path='all' component={Users} />

    <Route path='applications/invited' component={Applications} />
    <Route path='applications/pending-review' component={Applications} />
    <Route path='applications/:applicationId' component={Application} />

    <Route path=':userId' component={User}>
      <IndexRedirect to='details' />
      <Route path='details' component={Details} />
      <Route path='security' component={Security} />
      <Route path='organizations' component={Organizations} />
    </Route>
  </Route>
);
