import React from 'react';
import DebitOrderPayments from './debit-order-payments';
import CardPayments from './card-payments';

export default class PendingPayments extends React.Component {
  render() {
    return (
      <div>
        <DebitOrderPayments sameDay />
        <DebitOrderPayments />
        <CardPayments />
      </div>
    );
  }
}
