import React from 'react';
import { Row, Col } from 'reactstrap';
import { nestedStyleCheckbox } from '../../product-module-definition-settings/utils';
import _ from 'lodash';
import {
  savedBadgeRender,
  savingBadgeRender,
  selectOptionDefault,
  customStyles,
  updateSavingState,
} from '../orchestration';
import {
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import { debounce } from '../../../../../../helpers/debounce';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { BillingParams } from './billing';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import { SavingState } from '../../../util';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  params: BillingParams;
}

interface SelectOptions {
  label: string;
  value: string;
}
let timeout: any = null;

@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
export default class ProductModuleSettingsSelect extends React.Component<
Props,
any
> {
  constructor(props: Props) {
    super(props);
  }

  updateSelectInput = async (
    params: BillingParams,
    key: string,
    path: string,
    value: SelectOptions | null,
  ) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    updateSavingState(
      key,
      SavingState.Saving,
      productModuleComponentLoadingStateStore,
    );

    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      if (params.reset) {
        const currentVal = value ? value.value : null;
        params.reset.forEach(val => {
          const resetConditionMet = val.comparisonValues.indexOf(currentVal);
          if (resetConditionMet !== -1) {
            _.set(settings, val.path, val.default);
          }
        });
      }
      if (value) {
        const updateSettingsValue = Array.isArray(value)
          ? value.map(value => value.value)
          : value.value;

        _.set(settings, path, updateSettingsValue);
      } else {
        const settingsAtPath = _.get(settings, path, null);
        if (settingsAtPath) {
          if (Array.isArray(settingsAtPath)) {
            _.set(settings, path, []);
          } else {
            _.set(settings, path, null);
          }
        }
      }
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
        if (productModuleComponentLoadingStateStore) {
          updateSavingState(
            key,
            SavingState.Saved,
            productModuleComponentLoadingStateStore,
          );

          debounce(
            key,
            () =>
              updateSavingState(
                key,
                SavingState.Default,
                productModuleComponentLoadingStateStore,
              ),
            3000,
          );
        }
      }, 1000);
    }
  };

  render() {
    const {
      label,
      index,
      path,
      lockedComponent,
      description,
      indent,
      key,
      options,
      multipleSelect,
      title,
    } = this.props.params;

    const productModuleStore = this.props.productModuleStore;
    const productModuleComponentLoadingStateStore = this.props
      .productModuleComponentLoadingStateStore;

    const nested = indent ? true : false;

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleBillingComponents[key];

    return (
      <Row style={{ paddingBottom: 20, marginBottom: 0 }}>
        <Col xs={3}>{title}</Col>
        <Col xs={9}>
          <div style={nestedStyleCheckbox(nested, index)}>
            <div className='product-module-definitions-enable-item-checkbox '>
              <div style={{ verticalAlign: 'middle', display: 'inline-block' }}>
                <div className='settings-description-label-div'>
                  <p style={{ fontWeight: 'bold' }}>{label}</p>
                </div>
                {savedBadgeRender(saving)}
                {savingBadgeRender(saving)}
                <Select
                  key='refund_the_policy_holder'
                  isDisabled={lockedComponent}
                  styles={customStyles}
                  defaultValue={selectOptionDefault(
                    { ...this.props.params },
                    productModuleStore,
                  )}
                  options={options}
                  isMulti={multipleSelect ? multipleSelect : false}
                  onChange={(value: SelectOptions) =>
                    this.updateSelectInput({ ...this.props.params }, key, path, value)
                  }
                />
              </div>
              {description && <p style={{ paddingTop: 8 }}>{description}</p>}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
