/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='120px' height='90px' viewBox='0 0 120 45' version='1.1'>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-53'
            transform='translate(-698.000000, -260.000000)'
          >
            <g id='Group-4' transform='translate(698.000000, 260.000000)'>
              <path
                d='M54.5356569,23 L63.1061669,23'
                id='Line'
                stroke='#E1EBF0'
                strokeWidth='2'
                strokeLinecap='round'
              />
              <polyline
                id='Rectangle-2'
                stroke='#E1EBF0'
                strokeWidth='2'
                strokeLinecap='round'
                points='59.7321716 28.0355339 64.8399459 23.267767 59.7321716 18.5 59.7321716 18.5'
              />
              <rect
                id='Rectangle-7-Copy'
                fill='#E1EBF0'
                x='0'
                y='0'
                width='45'
                height='45'
                rx='5'
              />
              <rect
                id='Rectangle-7-Copy-2'
                fill='#E1EBF0'
                x='75'
                y='0'
                width='45'
                height='45'
                rx='5'
              />
              <g
                id='Group-2'
                transform='translate(8.000000, 9.000000)'
                fill='#FFFFFF'
              >
                <path
                  d='M12.8448466,19.2911559 L12.8448466,13.6121559 L14.4799217,13.6121559 C14.8482799,14.1780971 15.4686863,14.5216853 16.1421963,14.5216853 C17.2433852,14.5216853 18.1394126,13.6060676 18.1394126,12.4800088 C18.1394126,11.35395 17.2433852,10.4378029 16.1421963,10.4378029 C15.4686863,10.4378029 14.8482799,10.7816559 14.4799217,11.3473324 L10.9509882,11.3473324 C10.772508,11.3473324 10.6271853,11.4955676 10.6271853,11.6782147 L10.6271853,20.0918912 C10.6271853,20.1797735 10.6616379,20.2642147 10.7225129,20.3261559 L17.2379453,26.7190676 C17.2985612,26.7810088 17.3811957,26.8156853 17.4666797,26.8156853 L19.6861543,26.8156853 C19.8172297,26.8156853 19.9350939,26.73495 19.9850891,26.6113324 C20.0353433,26.4877147 20.0073667,26.3455676 19.9148886,26.2510676 L12.8448466,19.2911559 Z'
                  id='Fill-10'
                />
                <path
                  d='M28.3327538,26.4470029 L28.3301634,0.595561765 C28.3301634,0.413179412 28.1850997,0.264679412 28.0063605,0.264679412 L0.3238029,0.264679412 C0.145322742,0.264679412 0,0.413179412 0,0.595561765 L0,26.5007382 C0,26.6839147 0.145322742,26.8316206 0.3238029,26.8316206 L0.738270613,26.8316206 C0.771168987,26.8316206 0.803549278,26.8260618 0.833339144,26.8157382 L1.89334032,26.8157382 C2.07182048,26.8157382 2.21714322,26.6677676 2.21714322,26.4848559 L2.21714322,2.53029706 L26.1127611,2.53029706 L26.1127611,24.8309735 L20.2446754,19.4007971 L22.6716429,19.4007971 C22.8509002,19.4007971 22.9954458,19.2528265 22.9954458,19.0699147 L22.9954458,5.91932647 C22.9954458,5.73667941 22.8509002,5.58844412 22.6716429,5.58844412 L5.6178508,5.58844412 C5.43885256,5.58844412 5.2940479,5.73667941 5.2940479,5.91932647 L5.2940479,26.4848559 C5.2940479,26.6677676 5.43885256,26.8157382 5.6178508,26.8157382 L7.18738822,26.8157382 C7.36638646,26.8157382 7.51119112,26.6677676 7.51119112,26.4848559 L7.51119112,7.85432647 L20.7790797,7.85432647 L20.7790797,17.1351794 L16.7219589,17.1351794 C16.5427016,17.1351794 16.398156,17.2836794 16.398156,17.4660618 L16.398156,18.6614735 C16.398156,18.7525324 16.4344219,18.8398853 16.4991825,18.9018265 L24.6038396,26.7252088 C24.6641964,26.7834441 24.7437224,26.8157382 24.8260979,26.8157382 L28.0110232,26.8157382 C28.1029832,26.8157382 28.1910576,26.7752382 28.2527097,26.7050912 C28.3141027,26.6346794 28.3428564,26.5407088 28.3327538,26.4470029'
                  id='Fill-12'
                />
              </g>
              <g
                id='Group-3'
                transform='translate(84.000000, 10.000000)'
                stroke='#FFFFFF'
                strokeWidth='2'
              >
                <rect
                  id='Rectangle-4'
                  x='0'
                  y='0'
                  width='27.0344828'
                  height='27'
                />
                <path
                  d='M1.27272727,18 L26.7272727,18'
                  id='Line'
                  strokeLinecap='square'
                />
                <path
                  d='M1.27272727,9 L26.7272727,9'
                  id='Line-Copy'
                  strokeLinecap='square'
                />
                <path
                  d='M13.5172414,0.35 L13.5172414,25'
                  id='Line'
                  strokeLinecap='square'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
