import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
} from 'reactstrap';
import { ajax, renderValue, withData, toSentenceCase, toCamelCaseKeysTopLevelOnly } from '../../../helpers';
import CardEntry from '../../../components/card-entry';
import { UpdateAppDataModal } from './modals/update-app-data-modal';

class SetPolicyStatusModal extends React.Component {
  state = {
    status: '',
    reason: '',
    refund: false,
    isValid: false,
    error: '',
  };

  setPaymentStatus = async () => {
    const { policy } = this.props;

    if (!this.state.isValid) return;

    try {
      switch (this.state.status) {
        case 'active': {
          await ajax({
            path: `/insurance/internal/apps/${policy.organizationId}/policies/${
              policy.policyId
            }/activate`,
            type: 'post',
          });

          break;
        }
        case 'cancelled': {
          await ajax({
            path: `/insurance/internal/apps/${policy.organizationId}/policies/${
              policy.policyId
            }/cancel`,
            type: 'post',
            data: {
              reason: this.state.reason,
              refund: this.state.refund,
            },
          });

          break;
        }
        case 'lapsed': {
          await ajax({
            path: `/insurance/internal/apps/${policy.organizationId}/policies/${
              policy.policyId
            }/lapse`,
            type: 'post',
          });

          break;
        }
        case 'expired': {
          await ajax({
            path: '/insurance/admin/policies/expire',
            type: 'post',
            data: {
              policy_ids: [policy.policyId],
            },
          });

          break;
        }
        case 'not_taken_up': {
          await ajax({
            path: '/insurance/admin/policies/mark-not-taken-up',
            type: 'post',
            data: {
              policy_ids: [policy.policyId],
            },
          });

          break;
        }
        default: {
          return;
        }
      }

      this.props.onClose();
      this.props.onPolicyStatusUpdated();
    } catch (e) {
      try {
        const error = JSON.parse(e.message);
        if (error.message === 'Validation failed') {
          this.setState({ error: error.errors[0].message });
        } else {
          this.setState({ error: error.message });
        }
      } catch (_) {
        this.setState({ error: e.message });
      }
    }
  };

  setStatus = ({ target }) => {
    if (target.value) {
      this.setState({
        status: target.value,
        isValid: this.state.status === 'cancelled' ? !!this.state.reason : true,
        error: '',
      });
    } else {
      this.setState({ status: null, isValid: false });
    }
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Change the status of this policy
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='policyStatusSelect'>Select status:</Label>
              <Input
                onChange={e => this.setStatus(e)}
                type='select'
                name='select'
                id='policyStatusSelect'
              >
                <option value=''>Select...</option>
                <option value='active'>Active</option>
                <option value='cancelled'>Cancelled</option>
                <option value='lapsed'>Lapsed</option>
                <option value='expired'>Expired</option>
                <option value='not_taken_up'>Not taken up</option>
              </Input>
            </FormGroup>
            {this.state.status === 'cancelled' ? (
              <div>
                <FormGroup>
                  <Label for='reason'>Reason:</Label>
                  <Input
                    id='reason'
                    placeholder={'Reason'}
                    onChange={e =>
                      this.setState({
                        reason: e.target.value,
                        isValid: !!e.target.value,
                      })
                    }
                    value={this.state.reason}
                  />
                </FormGroup>
                <FormGroup check>
                  <Input
                    id='refund'
                    type='checkbox'
                    onChange={e => this.setState({ refund: e.target.checked })}
                    value={this.state.refund}
                  />
                  <Label check for='refund'>
                    Issue a refund
                  </Label>
                </FormGroup>
              </div>
            ) : (
              ''
            )}
            <br />
            <Alert color='warning'>
              <strong>Warning:</strong> this change will reflect in the
              organization&#39;s dashboard, and the policyholder may be notified.
            </Alert>
            {this.state.error && (
              <Alert color='danger'>{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.isValid}
              onClick={this.setPaymentStatus}
              color='primary'
            >
              Set
            </Button>
            <Button color='secondary' onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class MonitorRequestModal extends React.Component {
  state = {
    error: '',
    action: '',
  };

  triggerSubmit = async () => {
    const { policy } = this.props;
    if (!policy) {
      this.setState({ error: 'Invalid policy' });
      return;
    }
    if (!this.state.action) {
      this.setState({ error: 'Please select request type' });
      return;
    }

    try {
      switch (this.state.action) {
        case 'CREATE':
          await ajax({
            path: `/insurance/admin/apps/${policy.organizationId}/policies/monitor-create`,
            type: 'post',
            data: {
              policy_ids: [
                policy.policyId,
              ],
            },
          });
          break;
        case 'CANCEL':
          await ajax({
            path: `/insurance/admin/apps/${policy.organizationId}/policies/monitor-cancel`,
            type: 'post',
            data: {
              policy_ids: [
                policy.policyId,
              ],
            },
          });
          break;
        default:
          break;
      }
      this.setState({ action: null });
      this.props.onClose();
    } catch (err) {
      const error = JSON.parse(err.message);
      if (error.message === 'Validation failed') {
        this.setState({ error: error.errors[0].message });
      } else {
        this.setState({ error: error.message });
      }
    }
  }

  setStatus = ({ target }) => {
    this.setState({ action: target.value });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Trigger Monitor Request
          </ModalHeader>
          <ModalBody>
            <Alert color='warning'>
              <strong>Warning:</strong> Please note this triggers an asynchronous request and will take some time to reflect.
            </Alert>
            <FormGroup>
              <Label for='monitorRequestSelect'>Select request:</Label>
              <Input
                onChange={e => this.setStatus(e)}
                type='select'
                name='select'
                id='monitorRequestSelect'
              >
                <option value=''>Select...</option>
                <option value='CREATE'>CREATE</option>
                <option value='CANCEL'>CANCEL</option>
              </Input>
            </FormGroup>
            {this.state.error && (
              <Alert color='danger'>{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.triggerSubmit} color='primary'>
              Submit
            </Button>
            <Button color='secondary' onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class PolicyOverview extends React.Component {
  state = {
    showSetPolicyStatusModal: false,
    showMonitorRequestModal: false,
    showUpdateAppDataModal: false,
  };

  allowMonitorModal(policy) {
    const monitorModules = [
      'mrprice_device',
      'mrprice_mobile_device',
      'mrprice_instore_device_cash',
    ];
    return policy && policy.module && monitorModules.includes(this.props.policy.module.type);
  }

  allowUpdateAppDataModal(policy) {
    return (
      policy && policy.module && policy.module.type === 'mrprice_mobile_device'
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading policy...</i>
        </div>
      );
    }

    return (
      <Container style={{ padding: 0 }}>
        <Card>
          <CardHeader>
            Policy details{' '}
            <Button
              onClick={() => this.setState({ showSetPolicyStatusModal: true })}
            >
              Change policy status
            </Button>
            {this.allowMonitorModal(this.props.policy) && (
              <>
                Monitor
                <Button
                  onClick={() =>
                    this.setState({ showMonitorRequestModal: true })
                  }
                >
                  Trigger request
                </Button>
                {this.allowUpdateAppDataModal(this.props.policy) && (
                  <Button
                    onClick={() =>
                      this.setState({ showUpdateAppDataModal: true })
                    }
                  >
                    Update app data
                  </Button>
                )}
              </>
            )}
          </CardHeader>
          <CardBody>
            {Object.keys(this.props.policy).map((key, index) => (
              <CardEntry key={key} index={index}>
                <div style={{ padding: '2px' }}>
                  <b>{toSentenceCase(key)}</b>
                </div>
                <div style={{ width: '70%', padding: '2px' }}>
                  {renderValue(key, this.props.policy[key])}
                </div>
              </CardEntry>
            ))}
          </CardBody>
        </Card>
        <SetPolicyStatusModal
          onClose={() => this.setState({ showSetPolicyStatusModal: false })}
          show={this.state.showSetPolicyStatusModal}
          policy={this.props.policy}
          onPolicyStatusUpdated={this.props.fetchPolicy}
        />
        <MonitorRequestModal
          onClose={() => this.setState({ showMonitorRequestModal: false })}
          show={this.state.showMonitorRequestModal}
          policy={this.props.policy}
        />
        <UpdateAppDataModal
          onClose={() => this.setState({ showUpdateAppDataModal: false })}
          show={this.state.showUpdateAppDataModal}
          policy={this.props.policy}
          onPolicyUpdated={this.props.fetchPolicy}
        />
      </Container>
    );
  }
}

export default withData({
  path: props => `/insurance/admin/policies/${props.params.policyId}`,
  prop: 'policy',
  map: toCamelCaseKeysTopLevelOnly,
})(PolicyOverview);
