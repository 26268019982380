/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <g fill='none' fillRule='evenodd'>
          <path fill='#FD3238' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0' />
          <g fill='#FFF'>
            <path d='M7 12.333a.97.97 0 0 1 .295-.707 1.032 1.032 0 0 1 .734-.293 1.069 1.069 0 0 1 .731.292.968.968 0 0 1 .298.708.991.991 0 0 1-.298.714 1.062 1.062 0 0 1-.731.287c-.148 0-.284-.026-.41-.077a.982.982 0 0 1-.62-.924zM9 2.667v4.06c0 .436-.025.868-.076 1.296-.05.429-.117.865-.2 1.31H7.276c-.083-.445-.15-.881-.2-1.31A11.04 11.04 0 0 1 7 6.728V2.667h2z' />
          </g>
        </g>
      </svg>
    );
  }
}
