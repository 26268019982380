import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import ProductModuleJson, { SchemaType } from './modals/product-module-json';

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface Props {
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionClaimFulfillmentTypes extends React.Component<
Props,State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    return (
      <ProductModuleJson
        schemaType={SchemaType.fulfillmentType}
        breadcrumbDocName={'Claims fulfillment types'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  async componentDidMount() {
    const { productModuleKey } = this.props;
    const { productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  render() {
    const { productModuleStore } = this.injected;

    if (!productModuleStore.isLoaded) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Claim fulfillment types
              </p>
            </Col>
            <Col xs={9}>
              <p>
                The fulfillment types available to this product module are
                defined here.
              </p>
              <span>
                Claims can result in monetary payouts or through fulfillment of
                non-cash benefits. Monetary payouts are processed as Payout
                Requests on Root and non-monetary payouts are processed as
                Fulfillment Requests. Fulfillment Requests are the construct
                that facilitates any benefit that needs to make its way to a
                customer that’s not a funds transfer. It is coupled into the
                fulfillment tooling that finance teams use on Root. To use
                Fulfilments, a Fulfillment Type needs to be created. This is the
                fulfillment definition that’s referenced when implementing
                Fulfillment Requests.
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
