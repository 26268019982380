import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { PayoutRequestBlock as Block } from '../domain/payout-request-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { PayoutRequestBlockState } from '../domain/states/payout-request-block-state';
import Button from 'reactstrap/lib/Button';
import { Card, CardBody } from 'reactstrap';

interface Props {
  disabled?: boolean;
  block: Block;
  blockState: PayoutRequestBlockState;
}

interface State {
  UIState: BlockUIState;
  payoutRequestBlockState?: BlockState;
  showModal: boolean;
}

@observer
export class PayoutRequestBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      payoutRequestBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
      showModal: false,
    };
  }

  checkRequiredFields() {
    const { amount, payee } = this.props.block;
    return amount &&
      payee.paymentDetails.details.bankName &&
      payee.paymentDetails.details.branchCode &&
      payee.paymentDetails.details.accountType &&
      payee.paymentDetails.details.accountNumber
      ? BlockUIState.Normal
      : BlockUIState.Error;
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { payoutRequestBlockState } = this.state;
    if (!payoutRequestBlockState) {
      return null;
    }

    const {
      bankName,
      branchCode,
      accountType,
      accountNumber,
    } = block.payee.paymentDetails.details;
    return (
      <Card className='blocks-details-card'>
        <CardBody>
          <div className='heading'>
            <div className='title'>
              <h4
                style={{
                  display: 'inline-block',
                  margin: '0 0 16px 0',
                  width: '50%',
                }}
              >
                {block.title}
              </h4>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                {blockState.payoutRequestId === undefined ? (
                  <Button
                    color='primary'
                    disabled={this.checkRequiredFields() === BlockUIState.Error || disabled}
                  >
                    Create payout request
                  </Button>
                ) : (
                  <a>
                    View request
                  </a>
                )}
              </div>
            </div>
            <hr />
          </div>
          <div className='summary blocks-ui'>
            <table className='no-hover summary-table' style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>Description</td>
                  <td className='align-right'>{block.description}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className='align-right'>
                    R {block.amount / 100.00}
                  </td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td className='align-right'>
                    {block.payee.paymentDetails.type &&
                    block.payee.paymentDetails.type.trim()
                      ? block.payee.paymentDetails.type
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Bank name</td>
                  <td className='align-right'>
                    {bankName && bankName.trim() ? bankName : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Branch code</td>
                  <td className='align-right'>
                    {branchCode && branchCode.trim() ? branchCode : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Account type</td>
                  <td className='align-right'>
                    {accountType && accountType.trim() ? accountType : '-'}
                  </td>
                </tr>
                <tr>
                  <td>Account number</td>
                  <td className='align-right'>
                    {accountNumber && accountNumber.trim()
                      ? accountNumber
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  }
}
