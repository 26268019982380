import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Button,
  FormFeedback,
} from 'reactstrap';
import { Icon24PXCross } from '../../../../../../components/icons/icon-24-px-cross';
import { SFTPAuthenticationMethod } from '../../../domain/data-export-adapter';
import Select from 'react-select';
import {
  HTTPSAdapter,
  SFTPAdapter,
  S3Adapter,
  ScheduledDataExportAdapter,
  ScheduledDataExportAdapterType,
} from '../../../domain/scheduled-data-export-adapter';

interface Header {
  index: number;
  key: string;
  value: string;
  valid: boolean;
}

interface Props {
  autoFocus: boolean;
  adapter?: ScheduledDataExportAdapter;
  changed: (valid: boolean, state: ScheduledDataExportAdapter) => void;
  initialized: (valid: boolean, state: ScheduledDataExportAdapter) => void;
  touched: boolean;
}

interface HTTPSProps extends Props {
  adapter?: HTTPSAdapter;
}

interface HTTPSState {
  adapter: HTTPSAdapter;
  headers: Header[];
  headerIndex: number;
  validation: {
    url: boolean;
  };
}

class HTTPSDestinationForm extends React.Component<HTTPSProps, HTTPSState> {
  constructor(props: HTTPSProps) {
    super(props);

    let headerIndex = 0;
    let headers: Header[] = [];

    if (this.props.adapter && this.props.adapter.headers) {
      headers = Object.keys(this.props.adapter.headers).map((key) => ({
        index: headerIndex++,
        key,
        value: (this.props.adapter as any).headers[key],
        valid: true,
      }));
    }

    this.state = {
      adapter: this.props.adapter || new HTTPSAdapter({ url: '' }),
      headers,
      headerIndex,
      validation: {
        url: this.props.adapter ? true : false,
      },
    };
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state.adapter);
  }

  validateForm() {
    const invalidHeader = this.state.headers.find((h) => !h.valid);

    if (this.state.validation.url && !invalidHeader) {
      return true;
    }

    return false;
  }

  validateUrl(url: string) {
    if (url) {
      this.setState(
        {
          ...this.state,
          adapter: new HTTPSAdapter({ ...this.state.adapter, url }),
          validation: {
            ...this.state.validation,
            url: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          url: false,
        },
      });
    }
  }

  addHeader = () => {
    const nextIndex = this.state.headerIndex + 1;

    this.setState(
      {
        ...this.state,
        headerIndex: nextIndex,
        headers: [
          ...this.state.headers,
          { index: nextIndex, key: '', value: '', valid: false },
        ],
        adapter: new HTTPSAdapter({
          ...this.state.adapter,
          headers: this.state.headers.reduce(
            (a, b) => ({ ...a, [b.key]: b.value }),
            {},
          ),
        }),
      },
      () => {
        this.props.changed(this.validateForm(), this.state.adapter);
      },
    );
  };

  removeHeader = (index: number) => {
    const headers = this.state.headers.filter((h) => h.index !== index);

    this.setState(
      {
        ...this.state,
        headers,
        adapter: new HTTPSAdapter({
          ...this.state.adapter,
          headers: headers.reduce((a, b) => ({ ...a, [b.key]: b.value }), {}),
        }),
      },
      () => {
        this.props.changed(this.validateForm(), this.state.adapter);
      },
    );
  };

  onHeaderKeyChanged = (index: number, key: string) => {
    const headers = this.state.headers.map((h) => {
      if (h.index === index) {
        if (key) {
          h.key = key;
          h.valid = true;
        } else {
          h.valid = false;
        }
      }

      return h;
    });

    this.setState(
      {
        ...this.state,
        adapter: new HTTPSAdapter({
          ...this.state.adapter,
          headers: headers.reduce((a, b) => ({ ...a, [b.key]: b.value }), {}),
        }),
        headers,
      },
      () => {
        this.props.changed(this.validateForm(), this.state.adapter);
      },
    );
  };

  onHeaderValueChanged = (index: number, value: string) => {
    const headers = this.state.headers.map((h) => {
      if (h.index === index) {
        if (value) {
          h.value = value;
          h.valid = true;
        } else {
          h.valid = false;
        }
      }

      return h;
    });

    this.setState(
      {
        ...this.state,
        adapter: new HTTPSAdapter({
          ...this.state.adapter,
          headers: headers.reduce((a, b) => ({ ...a, [b.key]: b.value }), {}),
        }),
        headers,
      },
      () => {
        this.props.changed(this.validateForm(), this.state.adapter);
      },
    );
  };

  render() {
    return (
      <div>
        <FormGroup row>
          <Label sm={6}>
            <strong>
              URL<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='url'
              value={this.state.adapter.url}
              onChange={(e) => this.validateUrl(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>Headers</strong>
          </Label>
        </FormGroup>
        {this.state.headers.map((h) => (
          <FormGroup key={h.index} row>
            <Col sm={3}>
              <Input
                placeholder='Key'
                type='text'
                value={h.key}
                onChange={(e) =>
                  this.onHeaderKeyChanged(h.index, e.target.value)
                }
              />
            </Col>
            <Col sm={8}>
              <Input
                placeholder='Value'
                type='text'
                value={h.value}
                onChange={(e) =>
                  this.onHeaderValueChanged(h.index, e.target.value)
                }
              />
            </Col>
            <Col sm={1}>
              <div>
                <a href='#' onClick={() => this.removeHeader(h.index)}>
                  <Icon24PXCross />
                </a>
              </div>
            </Col>
          </FormGroup>
        ))}
        <FormGroup row>
          <Col sm={12}>
            <Button color='primary' onClick={this.addHeader}>
              Add header
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

interface SFTPProps extends Props {
  adapter?: SFTPAdapter;
}

interface SFTPState {
  adapter: SFTPAdapter;
  validation: {
    host: boolean;
    port: boolean;
    path: boolean;
    username: boolean;
    password: boolean;
    privateKey: boolean;
    passphrase: boolean;
  };
}

class SFTPDestinationForm extends React.Component<SFTPProps, SFTPState> {
  constructor(props: SFTPProps) {
    super(props);

    this.state = {
      adapter:
        this.props.adapter ||
        new SFTPAdapter({
          host: '',
          port: 22,
          path: '/',
          authenticationMethod: SFTPAuthenticationMethod.Password,
          username: '',
          password: '',
          privateKey: '',
          passphrase: '',
        }),
      validation: {
        host: false,
        port: true,
        path: true,
        username: false,
        password: false,
        privateKey: false,
        passphrase: false,
      },
    };
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state.adapter);
  }

  validateForm() {
    const { validation } = this.state;
    const { host, port, path, username, password, privateKey } = validation;

    if (host && port && path && ((username && password) || privateKey)) {
      return true;
    } else {
      return false;
    }
  }

  validateHost(host: string) {
    if (host) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, host }),
          validation: {
            ...this.state.validation,
            host: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          host: false,
        },
      });
    }
  }

  validatePort(port: number) {
    if (port) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, port }),
          validation: {
            ...this.state.validation,
            port: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          port: false,
        },
      });
    }
  }

  validateDirectory(path: string) {
    if (path && path.match(/^(?:[^\/]*\/)+$/)) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, path }),
          validation: {
            ...this.state.validation,
            path: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          path: false,
        },
      });
    }
  }

  validateUsername(username: string) {
    if (username) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, username }),
          validation: {
            ...this.state.validation,
            username: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          username: false,
        },
      });
    }
  }

  validatePassword(password: string) {
    if (password) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, password }),
          validation: {
            ...this.state.validation,
            password: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          password: false,
        },
      });
    }
  }

  validatePrivateKey(privateKey: string) {
    if (privateKey) {
      this.setState(
        {
          ...this.state,
          adapter: new SFTPAdapter({ ...this.state.adapter, privateKey }),
          validation: {
            ...this.state.validation,
            privateKey: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          privateKey: false,
        },
      });
    }
  }

  get authenticationMethodOptions() {
    return [
      {
        label: 'Password',
        value: 'password',
      },
      {
        label: 'Private key',
        value: 'private_key',
      },
    ];
  }

  get defaultValue() {
    const { adapter } = this.state;
    return adapter
      ? adapter.authenticationMethod
      : SFTPAuthenticationMethod.Password;
  }

  render() {
    return (
      <div>
        <FormGroup row>
          <Label sm={6}>
            <strong>
              Host<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='host'
              value={this.state.adapter.host}
              onChange={(e) => this.validateHost(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>
              Port<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Input
              type='number'
              name='port'
              min={0}
              max={65535}
              value={this.state.adapter.port}
              onChange={(e) => this.validatePort(parseInt(e.target.value))}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>
              Directory<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='path'
              value={this.state.adapter.path}
              onChange={(e) => this.validateDirectory(e.target.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={6}>
            <strong>
              Authentication method<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Select
              defaultValue={this.defaultValue}
              options={this.authenticationMethodOptions as any}
              onChange={(e: any) =>
                this.setState({
                  ...this.state,
                  adapter: new SFTPAdapter({
                    ...this.state.adapter,
                    authenticationMethod: e.value as SFTPAuthenticationMethod,
                  }),
                  validation: {
                    ...this.state.validation,
                  },
                })
              }
              name='authentication_method'
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={6}>Username</Label>
          <Col sm={6}>
            <Input
              type='text'
              name='username'
              value={this.state.adapter.username}
              onChange={(e) => this.validateUsername(e.target.value)}
            />
          </Col>
        </FormGroup>

        {this.state.adapter.authenticationMethod ===
          SFTPAuthenticationMethod.Password && (
          <FormGroup row>
            <Label sm={6}>Password</Label>
            <Col sm={6}>
              <Input
                type='password'
                name='password'
                value={this.state.adapter.password}
                onChange={(e) => this.validatePassword(e.target.value)}
                invalid={!this.state.validation.password}
              />
              {!this.state.validation.password && (
                <FormFeedback>Password is required</FormFeedback>
              )}
            </Col>
          </FormGroup>
        )}

        {this.state.adapter.authenticationMethod ===
          SFTPAuthenticationMethod.PrivateKey && (
          <>
            <FormGroup row>
              <Label sm={6}>Private key</Label>
              <Col sm={6}>
                <Input
                  type='textarea'
                  name='private_key'
                  value={this.state.adapter.privateKey}
                  onChange={(e) => this.validatePrivateKey(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={6}>Passphrase</Label>
              <Col sm={6}>
                <Input
                  type='password'
                  name='passphrase'
                  value={this.state.adapter.passphrase}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        ...this.state,
                        adapter: new SFTPAdapter({
                          ...this.state.adapter,
                          passphrase: e.target.value,
                        }),
                        validation: {
                          ...this.state.validation,
                          passphrase: true,
                        },
                      },
                      () => {
                        this.props.changed(
                          this.validateForm(),
                          this.state.adapter,
                        );
                      },
                    );
                  }}
                />
              </Col>
            </FormGroup>
          </>
        )}
      </div>
    );
  }
}

interface S3Props extends Props {
  adapter?: S3Adapter;
}

interface S3State {
  adapter: S3Adapter;
  validation: {
    region: boolean;
    bucketName: boolean;
    bucketPath: boolean;
    secretAccessKey: boolean;
    accessKeyId: boolean;
  };
}

class S3DestinationForm extends React.Component<S3Props, S3State> {
  constructor(props: S3Props) {
    super(props);

    this.state = {
      adapter:
        this.props.adapter ||
        new S3Adapter({
          region: '',
          bucketName: '',
          bucketPath: '',
          secretAccessKey: '',
          accessKeyId: '',
        }),
      validation: {
        region: false,
        bucketName: false,
        bucketPath: false,
        secretAccessKey: false,
        accessKeyId: false,
      },
    };
  }

  componentDidMount() {
    this.props.changed(this.validateForm(), this.state.adapter);
  }

  validateForm() {
    if (
      this.state.validation.region &&
      this.state.validation.bucketName &&
      this.state.validation.bucketPath &&
      this.state.validation.secretAccessKey &&
      this.state.validation.accessKeyId
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateRegion(region: string) {
    if (region) {
      this.setState(
        {
          adapter: new S3Adapter({ ...this.state.adapter, region }),
          validation: {
            ...this.state.validation,
            region: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          region: false,
        },
      });
    }
  }

  validateBucketName(bucketName: string) {
    if (bucketName) {
      this.setState(
        {
          ...this.state,
          adapter: new S3Adapter({ ...this.state.adapter, bucketName }),
          validation: {
            ...this.state.validation,
            bucketName: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          bucketName: false,
        },
      });
    }
  }

  validateBucketPath(bucketPath: string) {
    if (bucketPath && bucketPath.match(/^(?:[^\/]*\/)+$/)) {
      this.setState(
        {
          ...this.state,
          adapter: new S3Adapter({ ...this.state.adapter, bucketPath }),
          validation: {
            ...this.state.validation,
            bucketPath: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          bucketPath: false,
        },
      });
    }
  }

  validateSecretAccessKey(secretAccessKey: string) {
    if (secretAccessKey) {
      this.setState(
        {
          ...this.state,
          adapter: new S3Adapter({ ...this.state.adapter, secretAccessKey }),
          validation: {
            ...this.state.validation,
            secretAccessKey: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          secretAccessKey: false,
        },
      });
    }
  }

  validateAccessKeyId(accessKeyId: string) {
    if (accessKeyId) {
      this.setState(
        {
          ...this.state,
          adapter: new S3Adapter({ ...this.state.adapter, accessKeyId }),
          validation: {
            ...this.state.validation,
            accessKeyId: true,
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state.adapter);
        },
      );
    } else {
      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          accessKeyId: false,
        },
      });
    }
  }

  render() {
    return (
      <div>
        <FormGroup row>
          <Label sm={6}>
            <strong>Region</strong>
            <sup>*</sup>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='region'
              value={this.state.adapter.region}
              onChange={(e) => this.validateRegion(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>
              Bucket name<sup>*</sup>
            </strong>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='bucket_name'
              value={this.state.adapter.bucketName}
              onChange={(e) => this.validateBucketName(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>Bucket path</strong>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='path'
              value={this.state.adapter.bucketPath}
              onChange={(e) => this.validateBucketPath(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>Access key ID</strong>
            <sup>*</sup>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='path'
              value={this.state.adapter.accessKeyId}
              onChange={(e) => this.validateAccessKeyId(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={6}>
            <strong>Secret access key</strong>
            <sup>*</sup>
          </Label>
          <Col sm={6}>
            <Input
              type='text'
              name='path'
              value={this.state.adapter.secretAccessKey}
              onChange={(e) => this.validateSecretAccessKey(e.target.value)}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}
interface State {
  deliveryMethod: ScheduledDataExportAdapterType;
}

export class DestinationForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deliveryMethod: this.props.adapter
        ? this.props.adapter.type
        : ScheduledDataExportAdapterType.SFTP,
    };
  }

  renderOptions = (data: any) => {
    return data.map((content: any) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
  };

  renderAdapterTypeOptions() {
    return Object.values(ScheduledDataExportAdapterType).map((d) => (
      <option key={d} value={d}>
        {d.toUpperCase()}
      </option>
    ));
  }

  render() {
    return (
      <Card className='new-export-modal'>
        <CardHeader>
          <h5>Configure destination</h5>
        </CardHeader>
        <CardBody>
          <Form style={{ width: '100%' }}>
            <FormGroup row>
              <Label sm={6}>
                <strong>
                  Delivery method<sup>*</sup>
                </strong>
              </Label>
              <Col sm={6}>
                <Input
                  type='select'
                  name='deliveryMethod'
                  value={this.state.deliveryMethod}
                  onChange={(e) =>
                    this.setState({
                      deliveryMethod: e.target
                        .value as ScheduledDataExportAdapterType,
                    })
                  }
                >
                  {this.renderAdapterTypeOptions()}
                </Input>
              </Col>
            </FormGroup>
            {this.state.deliveryMethod ===
              ScheduledDataExportAdapterType.SFTP && (
              <SFTPDestinationForm
                autoFocus={this.props.autoFocus}
                adapter={
                  this.props.adapter &&
                  this.props.adapter.type ===
                    ScheduledDataExportAdapterType.SFTP
                    ? (this.props.adapter as SFTPAdapter)
                    : undefined
                }
                changed={this.props.changed}
                initialized={this.props.initialized}
                touched={this.props.touched}
              />
            )}

            {this.state.deliveryMethod ===
              ScheduledDataExportAdapterType.HTTPS && (
              <HTTPSDestinationForm
                autoFocus={this.props.autoFocus}
                adapter={
                  this.props.adapter &&
                  this.props.adapter.type ===
                    ScheduledDataExportAdapterType.HTTPS
                    ? (this.props.adapter as HTTPSAdapter)
                    : undefined
                }
                changed={this.props.changed}
                initialized={this.props.initialized}
                touched={this.props.touched}
              />
            )}

            {this.state.deliveryMethod ===
              ScheduledDataExportAdapterType.S3 && (
              <S3DestinationForm
                autoFocus={this.props.autoFocus}
                adapter={
                  this.props.adapter &&
                  this.props.adapter.type === ScheduledDataExportAdapterType.S3
                    ? (this.props.adapter as S3Adapter)
                    : undefined
                }
                changed={this.props.changed}
                initialized={this.props.initialized}
                touched={this.props.touched}
              />
            )}
          </Form>
        </CardBody>
      </Card>
    );
  }
}
