import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { ProductModuleDefinitionSchemaIndexStructure } from '../../product-module-json-to-html/orchestration';
import '../../../../styles/styles.scss';
import { ProductModuleJsonHtml } from '../../product-module-json-to-html';

interface Props {
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionClaimSchema extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    const { productModuleStore } = this.injected;
    const productModuleDefinitionSchemaId = productModuleStore.productModuleDefinitionDraft[ProductModuleDefinitionSchemaIndexStructure.claimSchemaId];
    return (
      <ProductModuleJsonHtml
        productModuleDefinitionSchemaId={productModuleDefinitionSchemaId}
        toDbKey={'claim_schema_json'}
        breadcrumbDocName={'Claims description schema'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  async componentDidMount() {
    const { productModuleKey, productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  render() {
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Claim schema
              </p>
            </Col>
            <Col xs={9}>
              <p>Claim schema form</p>
              <span>
                A claimant is any person initiating a claim on a policy. For
                some products the claimant will have to be the same person as
                the policyholder - an example is a policyholder claiming for a
                stolen device. In other cases the claimant may be someone else -
                an example is someone claiming on behalf of a hospitalized or
                deceased family member.
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <p>Claim schema form</p>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
