import { observable, action } from 'mobx';
import uuid from 'uuid';
import _ from 'lodash';
import {
  InputState,
  ModelChildCount,
  ComponentValid,
  Validator,
  FormStoreModelList,
  InputStateValid,
} from './dynamic-form/interfaces/index';
import { validityCheck } from './orchestration';

export class FormStore {
  //this is the value from the text input
  @observable.deep inputState: InputState = {};

  @observable.deep model: FormStoreModelList[] = [];

  @observable.deep modelTransformed: FormStoreModelList[] = [];

  @observable.deep modelChildrenCount: ModelChildCount = {};

  @observable.deep modelChildrenHoldState: any = {};

  @observable.deep componentValid: ComponentValid = {};

  @observable valid: InputStateValid = {};

  @observable submitResult: boolean = false;

  @observable.deep nestedState: DynamicFormValues = {};

  @observable hideAddAndSubtract: boolean = false;

  //directly interacts with the property above
  @action updateInputState = (
    key: string,
    value: string | boolean | null | number,
  ) => {
    Object.assign(this.inputState, { [key]: value });
  };

  @action updateComponentValid = (
    key: string,
    validators: Validator[],
    onBlurCheck: boolean = false,
  ) => {
    const validation = validityCheck(
      key,
      validators,
      this.inputState,
      null,
      onBlurCheck,
    );
    Object.assign(this.componentValid, { [key]: { validators, validation } });
  };

  @action updateModelChildrenHoldState = (data: any) => {
    this.modelChildrenHoldState = { ...this.modelChildrenHoldState, ...data };
  };

  @action setInputState = (data: InputState) => {
    this.inputState = data;
  };

  @action updateValidTotal = (
    key: string,
    value: any,
    validators?: Validator[],
  ) => {
    if (validators) {
      const keyValue: InputState = {
        [key]: value,
      };
      const validation = validityCheck(key, validators, keyValue, null, true);
      this.valid = { ...this.valid, ...{ [key]: validation.valid } };
    } else {
      Object.assign(this.valid, { [key]: value });
    }
  };

  @action updateValidTotalDirect = (key: string, value: boolean) => {
    Object.assign(this.valid, { [key]: value });
  };

  @action updateModel = (model: FormStoreModelList[]) => {
    this.model = model;
    this.modelTransformed = model;
    try {
      this.modelTransformed.forEach((model, index) => {
        if (model.type && model.type === 'list') {
          const modelList = this.modelTransformed[index];
          const maxNumber = modelList.maxNumber && modelList.maxNumber > 25 ? 25: modelList.maxNumber;
          if (maxNumber) {
            for (let i = 0; i < maxNumber; i++) {
              this.modelTransformed[index].key = [
                ...(this.modelTransformed[index].key
                  ? this.modelTransformed[index].key
                  : []),
                uuid(),
              ];
            }
            let parentKey = this.modelTransformed[index].parentKey;
            if (!parentKey) {
              parentKey = uuid();
              this.modelTransformed[index].parentKey = parentKey;
              Object.assign(this.modelChildrenCount, {
                [parentKey]: 1,
              });
            }
          }
          const childList = model.components;
          childList.forEach((childListModel, childIndex) => {
            if (childListModel.type && childListModel.type === 'list') {
              const maxNumberChildList = childListModel && childListModel.maxNumber && childListModel.maxNumber > 25 ? 25 : childListModel.maxNumber;
              if (maxNumberChildList) {
                for (let i = 0; i < maxNumberChildList; i++) {
                  this.modelTransformed[index].components[childIndex].key = [
                    ...(this.modelTransformed[index].components[childIndex].key
                      ? this.modelTransformed[index].components[childIndex].key
                      : []),
                    uuid(),
                  ];
                }
                let parentKeyChildList = this.modelTransformed[index]
                  .components[childIndex].parentKey;
                if (!parentKeyChildList) {
                  parentKeyChildList = uuid();
                  this.modelTransformed[index].components[
                    childIndex
                  ].parentKey = parentKeyChildList;
                  Object.assign(this.modelChildrenCount, {
                    [parentKeyChildList]: 1,
                  });
                }
              }
            }
          });
        }
      });
    } catch (err) {
      console.log('error in updateModel in the formstore', err);
    }
  };

  @action stateModel = () => {
    for (const key in this.componentValid) {
      if (this.componentValid[key]) {
        this.updateComponentValid(
          key,
          this.componentValid[key].validators,
          true,
        );
      }
    }

    let valid = true;
    for (const key in this.valid) {
      if (!this.valid[key]) {
        valid = false;
      }
    }

    let nestedState: DynamicFormValues = {};

    this.modelTransformed.forEach(input => {
      if (input.type === 'list' && input.maxNumber) {
        const children = input.components;
        const maxNumber = input.maxNumber > 25 ? 25 : input.maxNumber;
        if (children) {
          for (let i = 0; i < maxNumber; i++) {
            let keyVal: any = {};
            children.forEach(childComponent => {
              const { props, type, components } = childComponent;
              if (childComponent.outputPath && childComponent.key) {
                const path: string[] = childComponent.outputPath.split('.');
                const componentKey =
                  path.length > 1 ? path[path.length - 1] : childComponent.key;
                const outputPath =
                  path.length > 1
                    ? path.slice(0, path.length - 1).join('.')
                    : path[0];

                const inputStateValue = this.inputState[
                  componentKey + '_' + input.key[i]
                ];
                if (isNotNullOrUndefined(inputStateValue)) {
                  const childKey = `${childComponent.key}_${input.key[i]}`;
                  const value =
                    props && props.prefix && type !== 'currency'
                      ? `${props.prefix}${this.inputState[childKey]}`
                      : this.inputState[childKey];

                  keyVal = {
                    ...keyVal,
                    ...{ [childComponent.key.toString()]: value },
                  };
                }
                const nestedStateInject = input.props && input.props.arrayValues ? Object.values(keyVal)[0] : keyVal;
                if (outputPath && Object.keys(keyVal).length !== 0) {
                  _.set(nestedState, `${outputPath}[${i}]`, nestedStateInject);
                }
              } else if (
                type === 'list' &&
                components &&
                childComponent.maxNumber &&
                childComponent.outputPathAppendTo
              ) {
                const maxNumber = childComponent.maxNumber > 25 ? 25 : childComponent.maxNumber;
                for (let j = 0; j < maxNumber; j++) {
                  components.forEach(nestedChildComponent => {
                    const {
                      props: nestedChildProps,
                      type: nestedChildType,
                    } = nestedChildComponent;
                    if (
                      nestedChildComponent.outputPath &&
                      nestedChildComponent.key
                    ) {
                      const componentKey = nestedChildComponent.key;
                      const outputPath = nestedChildComponent.outputPath;

                      const inputStateValue = this.inputState[
                        `${componentKey}_[${i}]_${childComponent.key[j]}`
                      ];
                      if (isNotNullOrUndefined(inputStateValue)) {
                        const childKey = `${nestedChildComponent.key}_[${i}]_${
                          childComponent.key[j]
                        }`;
                        const value =
                          nestedChildProps &&
                          nestedChildProps.prefix &&
                          nestedChildType !== 'currency'
                            ? `${nestedChildProps.prefix}${
                              this.inputState[childKey]
                            }`
                            : this.inputState[childKey];

                        if (outputPath && value) {
                          let nestedStatePath = '';
                          if (outputPath.split('.').length > 1) {
                            const outputPathSplit = outputPath.split('.');
                            const pathPrimary = outputPathSplit[0].toString();
                            const pathSecondary = outputPathSplit
                              .slice(1, outputPathSplit.length)
                              .join('.');
                            nestedStatePath = `${
                              childComponent.outputPathAppendTo
                            }[${i}]${pathPrimary}[${j}]${pathSecondary}`;

                            //check this
                            // const nestedStateInject = childComponent.props && childComponent.props.arrayValues ? Object.values(value)[0] : value;
                            _.set(nestedState, `${nestedStatePath}`, value);
                          } else {
                            nestedStatePath = `${
                              childComponent.outputPathAppendTo
                            }[${i}]${outputPath}[${j}]`;

                            //check this
                            // const nestedStateInject = childComponent.props && childComponent.props.arrayValues ? Object.values(value)[0] : value;
                            _.set(
                              nestedState,
                              `${nestedStatePath}.${nestedChildComponent.key.toString()}`,
                              value,
                            );
                          }
                        }
                        console.log('this.nestedState', nestedState);
                      }
                    }
                  });
                }
              } else {
                const inputStateValue = this.inputState[
                  childComponent.key + '_' + input.key[i]
                ];
                if (
                  isNotNullOrUndefined(inputStateValue) &&
                  isNotNullOrUndefined(childComponent.key)
                ) {
                  const childKey = childComponent.key + '_' + input.key[i];
                  const value =
                    props && props.prefix && type !== 'currency'
                      ? `${props.prefix}${this.inputState[childKey]}`
                      : this.inputState[childKey];
                  nestedState = {
                    ...nestedState,
                    [childComponent.key.toString()]: value,
                  };
                }
              }
            });
          }
        }
      } else {
        if (input.outputPath && input.key && typeof input.key === 'string') {
          const inputStateValue = this.inputState[input.key];
          if (isNotNullOrUndefined(inputStateValue)) {
            const outputPathArray = input.outputPath.split('.');
            const path = outputPathArray;
            const value =
              input.props && input.props.prefix && input.type !== 'currency'
                ? `${input.props.prefix}${inputStateValue}`
                : inputStateValue;

            if (outputPathArray.length === 1) {
              Object.assign(nestedState, {
                [path[0]]: value,
              });
            } else {
              const obj = {};
              objectNesting(obj, path, value);
              nestedState = mergeDeep(obj, false, nestedState);
            }
          }
        } else {
          if (
            typeof input.key === 'string' &&
            isNotNullOrUndefined(this.inputState[input.key])
          ) {
            const value =
              input.props && input.props.prefix && input.type !== 'currency'
                ? `${input.props.prefix}${this.inputState[input.key]}`
                : this.inputState[input.key];
            Object.assign(nestedState, {
              [input.key]: value,
            });
          }
        }
      }
    });

    console.log('nested state', JSON.stringify(nestedState));
    console.log('valid', valid);

    if (valid && Object.keys(nestedState).length) {
      this.nestedState = nestedState;
      this.submitResult = true;
    }
  };

  @action onLoadClaim = (claimData: string) => {
    this.modelTransformed.forEach((component: FormStoreModelList) => {
      if (component.outputPath) {
        const value = _.get(claimData, component.outputPath);
        if (!Array.isArray(component.key)) {
          this.updateInputState(component.key, value);
        }
      } else {
        const value = _.get(claimData, component.key);
        if (!Array.isArray(component.key)) {
          this.updateInputState(component.key, value);
        }
      }
    });
  };

  @action updateModelChildrenCount = (key: string, value: number) => {
    Object.assign(this.modelChildrenCount, {
      [key]: this.modelChildrenCount[key] + value,
    });
  };

  @action resetModelChildrenCount = (key: string, value: number) => {
    this.modelChildrenCount[key] = value;
  };
}

const store = new FormStore();

export default store;

function objectNesting(obj: any, keyPath: string[], value: string) {
  const lastKeyIndex: number = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
      obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
  }
}

function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

const isNotNullOrUndefined = (value: any) => {
  try {
    if (value !== null && value !== undefined) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

interface FormValue {
  [k: string]: string | FormValue;
}

interface FormList {
  [k: string]: FormValue[][];
}

interface DynamicFormValues {
  [k: string]: FormValue | FormList | string;
}
