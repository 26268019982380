import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  Alert,
} from 'reactstrap';
import {
  ajax,
} from '../../../../helpers';

interface Policy {
  [key: string]: any;
  module: {
    type: string;
  };
}

interface Props {
  onClose: () => void;
  onLedgerEntryAdded: () => void;
  policy?: Policy;
  show: boolean;
}

interface State {
  isValid: boolean;
  error: string;
  paymentId?: string;
  createdAt?: string;
  description?: string;
  amount: number;
  ledgerEntryType: LedgerEntryType;
}

enum LedgerEntryType {
  Debit = 'debit',
  Credit = 'credit',
}

class AddLedgerEntryModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isValid: false,
      error: '',
      description: '',
      amount: 0,
      ledgerEntryType: LedgerEntryType.Debit,
    };
  }

  componentDidMount = () => {
    // Set state to defualt value
    this.setState({
      isValid: false,
      error: '',
      description: '',
      amount: 0,
      ledgerEntryType: LedgerEntryType.Debit,
    });
  }

  componentDidUpdate = () => {
    let isValid = false;
    if (
      this.state.amount &&
      this.state.description
    ) {
      isValid = true;
    }

    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  }

  addLedgerEntry = async () => {
    const { policy } = this.props;
    const { amount, ledgerEntryType, description, paymentId } = this.state;

    if (!policy) {
      return;
    }

    if (amount < 0) {
      this.setState({ error: 'The "amount" field needs to be positive' });
      return;
    }

    const data = [{
      policy_id: policy.policy_id,
      organization_id: policy.organization_id,
      payment_id: !paymentId || paymentId === '' ? undefined : paymentId,
      description,
      amount,
    }];

    try {
      await ajax({
        path: `/insurance/admin/policies-ledger/${ledgerEntryType}`,
        type: 'post',
        data,
      });

      this.props.onClose();
      this.props.onLedgerEntryAdded();
    } catch (err) {
      const error = JSON.parse(err.message);
      if (error.message === 'Validation failed') {
        this.setState({ error: error.errors[0].message });
      } else {
        this.setState({ error: error.message });
      }
    }
  }

  onModalOpen = () => {
    this.setState({
      isValid: false,
      error: '',
      description: '',
      amount: 0,
      ledgerEntryType: LedgerEntryType.Debit,
      paymentId: undefined,
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          toggle={this.props.onClose}
          onOpened={() => this.onModalOpen()}
        >
          <ModalHeader toggle={this.props.onClose}>
            Add new ledger entry for this policy
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Policy number: {this.props.policy && this.props.policy.policy_number}</Label>
              </FormGroup>
              <Alert color='warning'>
                <strong>Warning:</strong> this change will reflect on the
                policy&#39;s ledger, and the policyholder may be billed.
              </Alert>
              {this.state.error && (
                <Alert color='danger'>{this.state.error}</Alert>
              )}
              <FormGroup>
                <Label>Description</Label>
                <Input
                  onChange={e => this.setState({ description: e.target.value })}
                  name='ledgerEntryDesc'
                  id='ledgerEntryDesc'
                  placeholder='Description'
                />
              </FormGroup>
              <FormGroup>
                <Label>Amount (in cents with no decimals)</Label>
                <Input
                  onChange={e => this.setState({ amount: parseInt(e.target.value) })}
                  type='number'
                  name='amount'
                  id='amount'
                  placeholder='Ledger entry amount'
                />
              </FormGroup>
              <FormGroup>
                <Label>Type of ledger entry</Label>
                <Input
                  onChange={e => this.setState({ ledgerEntryType: e.target.value.toLowerCase() as LedgerEntryType })}
                  type="select"
                  name="ledgerEntryTypeSelect"
                  id="ledgerEntryTypeSelect">
                  <option>Debit</option>
                  <option>Credit</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Payment ID (optional)</Label>
                <Input
                  type='text'
                  id='paymentId'
                  onChange={e => this.setState({ paymentId: e.target.value })}
                />
              </FormGroup>
            </Form>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={!this.state.isValid}
              onClick={this.addLedgerEntry}
              color='primary'
            >
              Add ledger entry
            </Button>

            <Button onClick={this.props.onClose} color='secondary'>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AddLedgerEntryModal;
