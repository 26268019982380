import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { DropdownBlock as Block } from '../domain/dropdown-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { DropdownBlockState } from '../domain/states/dropdown-block-state';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: DropdownBlockState;
}

interface State {
  UIState: BlockUIState;
  dropdownBlockState?: BlockState;
}

@observer
export class DropdownBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      dropdownBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  getOptions() {
    return this.props.block.options.map(option => ({
      label: option.value,
      value: option.key,
    }));
  }

  render() {
    const { disabled, block, required } = this.props;
    const { dropdownBlockState } = this.state;
    if (!dropdownBlockState) {
      return null;
    }

    const options = this.props.block.options ? this.getOptions() : [];

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label><b>{block.title}</b></Label>
        <br />
        {block.description && (
          <span className='form-label'>{block.description}</span>
        )}
        <Select
          className='blocks-dropdown'
          label={block.title}
          disabled={disabled}
          key={block.key}
          name={block.key}
          clearable={!required}
          options={options}
        />
      </FormGroup>
    );
  }
}
