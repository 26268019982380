export default (state = [], action) => {
  switch (action.type) {
    case 'SET_ROOTCODE':
      return action.rootCode;
    case 'UPDATE_ROOTCODE':
      if (state.find(r => r.cardId === action.rootCode.cardId)) {
        return state.map(r => {
          if (r.cardId === action.rootCode.cardId) {
            return action.rootCode;
          }
          return r;
        });
      } else {
        return [...state, action.rootCode];
      }
    case 'UPDATED_CONFIG_VARIABLES':
      return state.map(c => {
        if (c.cardId === action.cardId) {
          c.configVariables = action.configVariables;
        }
        return c;
      });
    default:
      return state;
  }
};
