import { ajax, getBase64FromFile } from '../../../helpers';
import { PayoutRequest, PayoutRequestStatus } from './domain/payout-request';

export class PayoutRequestApi {
  static async count(filterString: string) {
    const countResponse = await ajax({
      path: '/insurance/admin/payout-requests' + filterString,
      type: 'HEAD',
      raw: true,
    });

    const count = countResponse.headers.get('X-Total-Count');
    return parseInt(count, 10);
  }

  static async get(filterString: string) {
    const response = await ajax({
      path: '/insurance/admin/payout-requests' + filterString,
      type: 'GET',
    });

    return response.map(PayoutRequest.fromNetwork);
  }

  static async confirm(payoutRequest: PayoutRequest, proofOfPaymentFile: File) {
    const requestBody = {
      status: PayoutRequestStatus.Completed,
      proof_of_payment: {
        file_name: proofOfPaymentFile.name,
        description: 'proof_of_payment_' + payoutRequest.id.toString(),
        file_base64: (await getBase64FromFile(proofOfPaymentFile)).split(
          'data:application/pdf;base64,',
        )[1],
        file_type: proofOfPaymentFile.type,
      },
    };

    await ajax({
      path: `/insurance/admin/${payoutRequest.organizationId.toString()}/payout-requests/${payoutRequest.id.toString()}`,
      type: 'PATCH',
      data: requestBody,
    });
  }

  static async reject(payoutRequest: PayoutRequest, reason: string) {
    const requestBody = {
      status: PayoutRequestStatus.Rejected,
      reason,
    };

    await ajax({
      path: `/insurance/admin/${payoutRequest.organizationId.toString()}/payout-requests/${payoutRequest.id.toString()}`,
      type: 'PATCH',
      data: requestBody,
    });
  }
}
