import React from 'react';
import Select from 'react-select';
import { Col, Form, Input, Label, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from './components/product-definition/stores/product-module-store';
import { ProductModuleCodeRunListStore } from './stores/product-module-code-run-list-store';
import { debounce } from '../helpers/debounce';


const options = [
  { value: 'in_progress', label: 'In Progress' },
  { value: 'complete', label: 'Complete' },
  { value: 'failed', label: 'Failed' },
];

interface Searchable {
  labelText: string;
  id: string;
  placeHolder: string;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Props { }

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  productModuleCodeRunListStore: ProductModuleCodeRunListStore;
}

interface State {
  policyId?: string;
  functionName?: string;
  status?: { label: string; value: string };
}

@inject('productModuleStore', 'productModuleCodeRunListStore')
@observer
export class RunListSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      policyId: '',
      functionName: '',
      status: undefined,
    };
  }

  get injected() {
    return this.props as Injected;
  }


  onPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const policyId = event.target.value;
    this.setState({ policyId });
    debounce('update-product-module-code-policy-id-filter', () => {
      this.injected.productModuleCodeRunListStore.filters.policyId = policyId;
      this.injected.productModuleCodeRunListStore.filter({
        filters: this.injected.productModuleCodeRunListStore.filters,
      });
    }, 500);
  }

  onFunctionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const functionName = event.target.value;
    this.setState({ functionName });
    debounce('update-product-module-code-function-name-filter', () => {
      this.injected.productModuleCodeRunListStore.filters.functionName = functionName;
      this.injected.productModuleCodeRunListStore.filter({
        filters: this.injected.productModuleCodeRunListStore.filters,
      });
    }, 500);
  }

  onStatusChange = (event: { value: string; label: string }) => {
    const status = event && event.value || undefined;
    this.setState({ status: event });
    debounce('update-product-module-code-status-filter', () => {
      this.injected.productModuleCodeRunListStore.filters.status = status;
      this.injected.productModuleCodeRunListStore.filter({
        filters: this.injected.productModuleCodeRunListStore.filters,
      });
    }, 500);
  }

  onClearFilterClick = () => {
    this.injected.productModuleCodeRunListStore.clear();
    this.setState({ policyId: '', functionName: '', status: undefined });
  }

  getRunLogFilterItems = () => {
    return [
      {
        labelText: 'Filter by Policy ID',
        id: 'policyId',
        placeHolder: 'Enter Policy ID',
        value: this.state.policyId,
        onChange: this.onPolicyChange,
      },
      {
        labelText: 'Filter by Function Name',
        id: 'functionName',
        placeHolder: 'Enter Function Name',
        value: this.state.functionName,
        onChange: this.onFunctionChange,
      },
    ];
  }


  renderSearchForm = (searchValues: Searchable, index: number) => (
    <Col key={`${searchValues.id}_${index}`}>
      <Label
      >{searchValues.labelText}
      </Label>
      <Input
        id={searchValues.id}
        placeholder={searchValues.placeHolder}
        type='text'
        value={searchValues.value}
        onChange={searchValues.onChange}>
      </Input>
    </Col>
  )


  renderDropDown = () => (
    <Col>
      <Label
        title='Policy ID'
        for='policyID'
      >Filter by status
      </Label>
      <Select
        onChange={this.onStatusChange}
        options={options}
        defaultValue={this.state.status}
        isClearable
      >
      </Select>
    </Col>
  );

  render() {
    return (
      <Form>
        <Row>
          {this.getRunLogFilterItems().map((item, index) => {
            return this.renderSearchForm(item, index);
          })}
          {this.renderDropDown()}
          <Col>
            <div style={{ marginTop: 35, color: '#007bff', cursor: 'pointer' }}>
              <a onClick={this.onClearFilterClick}>Clear Filters</a>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };
}
