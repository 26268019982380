export default (state = [], action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      const newAlert = {
        id: new Date().getTime(),
        type: action.alertType,
        headline: action.headline,
        message: action.message,
      };
      return [...state, newAlert];
    case 'REMOVE_ALERT':
      const idx = state.indexOf(action.alert);
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    default:
      return state;
  }
};
