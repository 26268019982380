import React from 'react';
import { Breadcrumb, Container, BreadcrumbItem } from 'reactstrap';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import PageHeader from '../../../components/page-header';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../product-definition/stores/product-module-store';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';
import { ProductModuleCodeRunListStore } from '../../stores/product-module-code-run-list-store';
import {
  ProductModuleCodeRun,
  ProductModuleCodeRunStatus,
} from '../../domain/product-module-code-run';
import Images from '../../../components/svg-images';
import { DomainEntityTable } from '../../../components/domain/tables/domain-entity-table';
import { PaginationComponent } from '../../../components/pagination';
import LoadingInPage from '../../loading';
import { RunListSearch } from '../../run-list-search';
import { ApiError } from '../../../errors/error';
import { ErrorDisplay } from '../../../errors/error-display';

interface Props extends RouteComponentProps {
  route: any;
  params: {
    productModuleKey: string;
  };
  router: History;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  productModuleCodeRunListStore: ProductModuleCodeRunListStore;
}

interface State {
  error?: ApiError;
}

@observer
export class ProductModuleCodeLogsTable extends DomainEntityTable<ProductModuleCodeRun> { }

@inject('productModuleStore', 'productModuleCodeRunListStore')
@observer
class ProductModuleRunListViewImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
    };
    const { productModuleCodeRunListStore, productModuleStore } = this.injected;

    productModuleCodeRunListStore.setProductModuleCodeRunListStoreLoading({ isLoading: true });
    productModuleStore.setProductModuleStoreIsLoading();
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { params } = this.props;
    const { productModuleStore, productModuleCodeRunListStore } = this.injected;

    try {
      await productModuleStore.init(params.productModuleKey);
      await productModuleCodeRunListStore.load({
        productModuleId: productModuleStore.productModule.productModuleId,
        filters: {},
      });
    } catch (error) {
      this.setState({ error: new ApiError(error) });
    } finally {
      productModuleCodeRunListStore.setProductModuleCodeRunListStoreLoading({ isLoading: false });
    }
  }

  componentWillUnmount() {
    const {productModuleCodeRunListStore } = this.injected;
    productModuleCodeRunListStore.clear();
  }

  redirect = (key: string) => {
    const { router } = this.props;
    router.push(`/product-modules/${key}/product-definition`);
  };

  renderStatus = (params: { productModuleCodeRun: ProductModuleCodeRun }) => {
    const { productModuleCodeRun } = params;
    return (
      <div>
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.Complete && (
          <Images.success />
        )}
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.Failed && <Images.error />}
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.InProgress && (
          <Images.inProgress />
        )}
        <strong className='run-log-status-text'>{productModuleCodeRun.prettyStatus()}</strong>
      </div>
    );
  };

  onRowClick = (produceModuleCodeRunId: string) => {
    const { router } = this.props;

    router.push(
      `/product-modules/${this.props.params.productModuleKey}/runs/${produceModuleCodeRunId}`,
    );
  };

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>There are no completed run logs for this product module.</p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  renderWithCodeLogs() {
    const { productModuleStore, productModuleCodeRunListStore } = this.injected;
    const { productModuleCodeRuns } = productModuleCodeRunListStore;
    const isLoading = !productModuleStore.isLoaded || productModuleCodeRunListStore.isLoading;

    return <div style={{ marginTop: 32 }}>
      <ProductModuleCodeLogsTable
        rows={productModuleCodeRuns}
        getRowProps={({ productModuleCodeRunId }) => ({
          onClick: () => {
            this.onRowClick(productModuleCodeRunId);
          },
        })}
        columns={[
          this.DateColumn,
          this.PolicyColumn,
          this.FunctionNameColumn,
          this.StatusColumn,
        ]}
        isLoaded={!isLoading}
      />
    </div>;
  }

  render() {
    const { productModuleStore, productModuleCodeRunListStore, router } = this.injected;
    const { productModuleCodeRuns, pagination, total } = productModuleCodeRunListStore;
    const isLoading = !productModuleStore.isLoaded || productModuleCodeRunListStore.isLoading;

    if (
      !productModuleStore.productModule ||
      !productModuleStore.productModuleDefinitionDraft
    ) {
      return <LoadingInPage />;
    }


    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={router}
          route={this.props.route}
          subRoutes={this.sidebarSchemaRender().routes()}
          title={productModuleStore.productModule.name}
        />
        <Container className='container-positioning'>
          <Breadcrumb>
            <BreadcrumbItem>
              <a className='breadcrumb-a-tag-link' href='/product-modules'>
                Product modules
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a
                className='breadcrumb-a-tag-link'
                href={`/product-modules/${this.props.params.productModuleKey}`}
              >
                {productModuleStore.productModule.name}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Run logs</BreadcrumbItem>
          </Breadcrumb>
          <div style={{ marginLeft: '1.5rem', paddingTop: '1.5rem' }}>
            <PageHeader title={'Run logs'} />
          </div>
          <div style={{ marginLeft: 20 }}>
            {this.state.error && <ErrorDisplay error={this.state.error}/>}
            <RunListSearch />
            {!isLoading && !productModuleCodeRuns.length && this.renderEmpty()}
            {!isLoading && productModuleCodeRuns.length > 0 && this.renderWithCodeLogs()}
            {!isLoading && <PaginationComponent
              page={pagination.page}
              limit={pagination.pageSize}
              total={total || 0}
              disabled={false}
              goToPage={page => productModuleCodeRunListStore.goToPage(page)}
            />}
          </div>
        </Container>
      </div>
    );
  }


  DateDisplay: React.SFC<ProductModuleCodeRun> = ({ createdAt }) => (
    <div className='table-cell-single'>{createdAt.format('DD MMM YYYY HH:mm')}</div>
  );

  DateColumn = {
    heading: 'Date',
    element: this.DateDisplay,
  };

  PolicyDisplay: React.SFC<ProductModuleCodeRun> = ({ policyId }) => (
    <div className='table-cell-single'>{policyId}</div>
  );

  PolicyColumn = {
    heading: 'Policy Id',
    element: this.PolicyDisplay,
  };

  FunctionNameDisplay: React.SFC<ProductModuleCodeRun> = ({ functionName }) => (
    <div className='table-cell-single'><code>{functionName}</code></div>
  );

  FunctionNameColumn = {
    heading: 'FunctionName',
    element: this.FunctionNameDisplay,
  }

  StatusDisplay: React.SFC<ProductModuleCodeRun> = (productModuleCodeRun) => (
    <div className='table-cell-single'>{this.renderStatus({ productModuleCodeRun })}</div>
  );

  StatusColumn = {
    heading: 'Status',
    element: this.StatusDisplay,
  }


  Routes = [
    {
      name: 'Overview',
      url: `/product-modules/${this.props.params.productModuleKey}`,
    },
    {
      name: 'Deployment',
      url: `/product-modules/${this.props.params.productModuleKey}/deployment`,
    },
    {
      name: 'Version history',
      url: `/product-modules/${this.props.params.productModuleKey}/version-history`,
    },
    {
      name: 'Run logs',
      url: `/product-modules/${this.props.params.productModuleKey}/runs`,
    },
  ];

  sidebarSchemaRender = () => {
    return {
      routes: () => {
        return this.Routes;
      },
    };
  };
}

export const ProductModuleRunListView = withRouter(
  ProductModuleRunListViewImpl,
);
