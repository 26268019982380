import { TypesOfPolicyholdersCondition } from './product-module-definition-settings-policyholder';

export const typesOfCoveredPeople: any = {
  enableCoveredPeople: {
    label: 'Enable covered people on a policy.',
    description:
      'Policies can have covered people added.',
    indent: 0,
    path: 'coveredPeople.enabled',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: true,
    key: 'enableCoveredPeople',
    reset: [
      {
        path: 'coveredPeople.min',
        default: 0,
        comparisonValues: [0, null, false],
      },
      {
        path: 'coveredPeople.max',
        default: 0,
        comparisonValues: [0, null, false],
      },
    ],
  },
  maximumNumberOfCoveredPeople: {
    label: 'Maximum number of covered people',
    description: 'The maximum number of covered people supported by the policy.',
    indent: 1,
    index: 0,
    path: 'coveredPeople.max',
    condition: TypesOfPolicyholdersCondition.equals,
    key: 'maximumNumberOfCoveredPeople',
  },
  minimumNumberOfCoveredPeople: {
    label: 'Minimum number of covered people',
    description: 'The minumum number of covered people supported by the policy.',
    indent: 1,
    index: 0,
    path: 'coveredPeople.min',
    condition: TypesOfPolicyholdersCondition.equals,
    key: 'minimumNumberOfCoveredPeople',
  },
};
