import React from 'react';
import { FormGroup, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  WaitingPeriodRulesConfig,
  WaitingPeriodNotificationsConfig,
} from './config';
import {
  WaitingPeriodRulesOutput,
  WaitingPeriodNotificationsOutput,
} from '../../store-interfaces';
import WaitingPeriodNotificationsCompletedChild from './components/waiting-period-notifications-completed-child';
import WaitingPeriodRulesCompletedChild from './components/waiting-period-rules-completed-child';
import { nestedStyleCheckbox } from '../../../../utils';

interface Props {
  waitingPeriodRules: WaitingPeriodRulesOutput;
  waitingPeriodNotifications: WaitingPeriodNotificationsOutput;
}

interface State {}

@observer
class WaitingPeriodCompletedCard extends React.Component<Props, State> {
  getValue = (
    storeVariable: WaitingPeriodRulesOutput | WaitingPeriodNotificationsOutput,
    index: number,
    path: string,
    dropdown: boolean = false,
  ) => {
    let value = _.get(storeVariable[index], path);
    if (value && dropdown) {
      value = _.startCase(value).toLowerCase();
    }
    return value && value !== undefined ? value : null;
  };

  renderChildren = (
    storeVariable: WaitingPeriodRulesOutput | WaitingPeriodNotificationsOutput,
    storeType: string,
  ) => {
    const components = [];

    for (let i = 0; i < Object.keys(storeVariable).length; i++) {
      if (storeType === 'waitingPeriodRules') {
        components.push(this.childrenRules(storeVariable, i));
      }
      if (storeType === 'waitingPeriodNotifications') {
        components.push(this.childrenNotifications(storeVariable, i));
      }
    }
    return components;
  };

  render() {
    const { waitingPeriodRules, waitingPeriodNotifications } = this.props;
    const notificationsLength = Object.keys(waitingPeriodNotifications).length;
    const rulesLength = Object.keys(waitingPeriodRules).length;

    return (
      <div>
        <CardBody
          className='white-background'
          style={{ marginLeft: -17, marginBottom: 0, paddingBottom: 0 }}
        >
          <FormGroup row>
            <p className='product-module-heading-description-spacing'>
              The waiting period is the time frame after a policy is issued when
              a claim will be invalid.
            </p>
          </FormGroup>
          {rulesLength > 0 && (
            <CardBody style={nestedStyleCheckbox(true, 1)}>
              <h5>Waiting period rules</h5>
              <hr />
              {this.renderChildren(waitingPeriodRules, 'waitingPeriodRules')}
            </CardBody>
          )}
        </CardBody>
        {notificationsLength > 0 && (
          <div>
            <CardBody className='white-background' style={nestedStyleCheckbox(true, 0)}>
              <h5>Notifications</h5>

              <FormGroup inline style={{ marginBottom: 0 }}>
                {this.renderChildren(
                  waitingPeriodNotifications,
                  'waitingPeriodNotifications',
                )}
              </FormGroup>
            </CardBody>
          </div>
        )}
      </div>
    );
  }

  childrenRules = (
    storeVariable: WaitingPeriodRulesOutput | WaitingPeriodNotificationsOutput,
    i: number,
  ) => {
    const value = this.getValue(
      storeVariable,
      i,
      WaitingPeriodRulesConfig.applyWaitingPeriodOf.outputPaths.value,
    );
    const applyTo = `to ${this.getValue(
      storeVariable,
      i,
      WaitingPeriodRulesConfig.to.outputPaths,
      true,
    )}`;
    const type = this.getValue(
      storeVariable,
      i,
      WaitingPeriodRulesConfig.applyWaitingPeriodOf.outputPaths.type,
    );

    return (
      <div key={`waiting_period_${storeVariable}_${i}`}>
        <WaitingPeriodRulesCompletedChild
          value={value}
          applyTo={applyTo}
          type={type}
          index={i}
        />
        <hr />
      </div>
    );
  };

  childrenNotifications = (
    storeVariable: WaitingPeriodRulesOutput | WaitingPeriodNotificationsOutput,
    i: number,
  ) => {
    const value = this.getValue(
      storeVariable,
      i,
      WaitingPeriodNotificationsConfig.notifyThePolicyholder.outputPaths.value,
    );
    const trigger = this.getValue(
      storeVariable,
      i,
      WaitingPeriodNotificationsConfig.notifyThePolicyholder.outputPaths
        .trigger,
      true,
    );
    const type = this.getValue(
      storeVariable,
      i,
      WaitingPeriodNotificationsConfig.notifyThePolicyholder.outputPaths.type,
    );

    return (
      <div key={`waiting_period_${storeVariable}_${i}`}>
        <hr />
        <WaitingPeriodNotificationsCompletedChild
          value={value}
          trigger={trigger}
          type={type}
          index={i}
        />
      </div>
    );
  };
}

export default WaitingPeriodCompletedCard;
