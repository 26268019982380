import React from 'react';
import { Link } from 'react-router';
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import { withData, ajax, toCamelCaseKeys, renderValue } from '../../../helpers';
import { SetBANVStatusModal } from '../policyholder/set-banv-status-modal';
import Highlight from 'react-highlight';
import { createNewDebiCheckMandate, getDebiCheckMandate, DebiCheckMandateStatus, resubmitBanvRequest } from './policy-api';

const prettyMethodType = (type) => {
  switch (type) {
    case 'debit_order':
      return 'Debit order';
    case 'card':
      return 'Card';
    default:
      return 'Unknown';
  }
};

const prettyBankName = (name) => {
  switch (name) {
    case 'absa':
      return 'Absa';
    case 'african_bank':
      return 'African Bank';
    case 'al_baraka':
      return 'Al Baraka';
    case 'bank_of_lisbon':
      return 'Bank of Lisbon';
    case 'bidvest':
      return 'Bidvest';
    case 'capitec':
      return 'Capitec';
    case 'discovery_bank':
      return 'Discovery Bank';
    case 'fnb':
      return 'FNB';
    case 'investec':
      return 'Investec';
    case 'ithala':
      return 'Ithala';
    case 'mercantile':
      return 'Mercantile Bank';
    case 'nedbank':
      return 'Nedbank';
    case 'old_mutual':
      return 'Old Mutual';
    case 'postbank':
      return 'Postbank';
    case 'sasfin':
      return 'Sasfin';
    case 'standard_bank':
      return 'Standard Bank';
    case 'tyme_bank':
      return 'TymeBank';
    case 'ubank':
      return 'Ubank';
    default:
      return name;
  }
};

const prettyBANVStatus = (type) => {
  switch (type) {
    case 'pending':
      return 'Pending';
    case 'verified':
      return 'Verified';
    case 'failed_verification':
      return 'Failed verification';
    default:
      return 'Unknown';
  }
};

const prettyDebiCheckMandateStatus = type => {
  switch (type) {
    case DebiCheckMandateStatus.Pending:
      return 'Pending';
    case DebiCheckMandateStatus.Submitted:
      return 'Submitted';
    case DebiCheckMandateStatus.Processing:
      return 'Processing';
    case DebiCheckMandateStatus.Active:
      return 'Active';
    case DebiCheckMandateStatus.Rejected:
      return 'Rejected';
    case DebiCheckMandateStatus.Suspended:
      return 'Suspended';
    case DebiCheckMandateStatus.Cancelled:
      return 'Cancelled';
    case DebiCheckMandateStatus.NoResponse:
      return 'No response';
    default:
      return 'Unknown';
  }
};

class PolicyPaymentMethod extends React.Component {
  state = {
    showSetBANVStatusModal: false,
    showCreateNewDebicheckMandateModal: false,
    showResubmitBanvModal: false,
    paymentMethod: null,
    debiCheckMandate: null,
    loading: false,
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.policy && this.props.policy) {
      await this.fetchPaymentMethod();

      if (this.props.policy.debicheckMandateId) {
        await this.fetchDebiCheckMandate();
      }
    }
  }

  fetchPaymentMethod = async () => {
    this.setState({ loading: true });

    const { organizationId, paymentMethodId } = this.props.policy;

    try {
      const paymentMethod = toCamelCaseKeys(
        await ajax({
          path: `/apps/${organizationId}/insurance/admin/payment-methods/${paymentMethodId}`,
        }),
      );

      this.setState({
        paymentMethod,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchDebiCheckMandate = async () => {
    this.setState({ loading: true });

    const { policyId, organizationId } = this.props.policy;

    try {
      const debiCheckMandate = await getDebiCheckMandate({ policyId, organizationId });

      this.setState({
        debiCheckMandate,
        loading: false,
      });

    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  }

  createNewMandate = async () => {
    const { policyId, organizationId } = this.props.policy;

    await createNewDebiCheckMandate({
      policyId,
      organizationId,
    });

    await this.fetchDebiCheckMandate();
    window.location.reload();
  }

  resubmitBanv = async () => {
    const { organizationId, paymentMethodId } = this.props.policy;

    await resubmitBanvRequest({
      paymentMethodId,
      organizationId,
    }).catch(error => {
      console.dir(error);
    });

  };

  openSetBANVStatusModal = () =>
    this.setState({ showSetBANVStatusModal: true });
  closeSetBANVStatusModal = () =>
    this.setState({ showSetBANVStatusModal: false });

  openCreateNewDebicheckMandateModal = () =>
    this.setState({ showCreateNewDebicheckMandateModal: true });
  closeCreateNewDebicheckMandateModal = () =>
    this.setState({ showCreateNewDebicheckMandateModal: false });
  openResubmitBanvModal = () =>
    this.setState({ showResubmitBanvModal: true });
  closeResubmitBanvModal = () =>
    this.setState({ showResubmitBanvModal: false });

  renderCardPaymentMethod = () => {
    const card = this.state.paymentMethod;
    return [
      <tr key='verification-details-response'>
        <td>
          <strong>Details:</strong>
        </td>
        <td>
          <Highlight className='json'>
            {JSON.stringify(card, null, 2)}
          </Highlight>
        </td>
      </tr>,
    ];
  };

  renderDebitOrderMethod = () => {
    const bankDetails = this.state.paymentMethod.bankDetails;
    const useDebiCheck = !!this.props.policy.debicheckMandateId && this.state.debiCheckMandate;

    let debiCheckDetails;

    const debitOrderDetails = [
      <tr key='bank-details'>
        <td>
          <strong>Bank details:</strong>
        </td>
        <td>
          <Table size='sm' borderless>
            <tbody>
              <tr>
                <td>
                  <strong>Account holder:</strong>
                </td>
                <td>{bankDetails.accountHolder}</td>
              </tr>
              <tr>
                <td>
                  <strong>Bank:</strong>
                </td>
                <td>{prettyBankName(bankDetails.bank)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Branch code:</strong>
                </td>
                <td>{bankDetails.branchCode}</td>
              </tr>
              <tr>
                <td>
                  <strong>Account number:</strong>
                </td>
                <td>{bankDetails.accountNumber}</td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>,
      <tr key='banv-status'>
        <td>
          <strong>Verification status:</strong>
        </td>
        <td>
          <Table size='sm' borderless>
            <tbody>
              <tr>
                <td>{prettyBANVStatus(bankDetails.banvStatus)}</td>
              </tr>
              <tr>
                <td>
                  <Button color='primary' onClick={this.openSetBANVStatusModal}>
                    Set status...
                  </Button>
                </td>
                <td>
                  <Button
                    color='primary'
                    onClick={this.openResubmitBanvModal}>
                        Resubmit BANV request
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>,
      <tr key='verification-details-submitted-at'>
        <td>
          <strong>Verification submitted at:</strong>
        </td>
        <td>
          {bankDetails.banvDetails.banvSubmittedAt
            ? bankDetails.banvDetails.banvSubmittedAt
            : '-'}
        </td>
      </tr>,
      <tr key='verification-details-set-by'>
        <td>
          <strong>Verification status set by:</strong>
        </td>
        <td>
          {bankDetails.banvDetails.banvStatusSetBy ? (
            bankDetails.banvDetails.banvStatusSetBy.type === 'user' ? (
              <Link to={`/users/${bankDetails.banvDetails.banvStatusSetBy.id}`}>
                {bankDetails.banvDetails.banvStatusSetBy.id}
              </Link>
            ) : (
              bankDetails.banvDetails.banvStatusSetBy.type
            )
          ) : (
            '-'
          )}
        </td>
      </tr>,
      <tr key='verification-details-response'>
        <td>
          <strong>Verification response:</strong>
        </td>
        <td>
          {bankDetails.banvDetails.banvResponse ? (
            <Highlight className='json'>
              {JSON.stringify(bankDetails.banvDetails.banvResponse, null, 2)}
            </Highlight>
          ) : (
            '-'
          )}
        </td>
      </tr>,
    ];

    if (useDebiCheck) {
      debiCheckDetails = [
        <tr key='debicheck-mandate-status'>
          <td>
            <strong>DebiCheck mandate status:</strong>
          </td>
          <td>
            <Table size='sm' borderless>
              <tbody>
                <tr>
                  <td>{prettyDebiCheckMandateStatus(this.state.debiCheckMandate.status)}</td>
                </tr>
                <tr>
                  <td>
                    <Button
                      color='primary'
                      onClick={this.openCreateNewDebicheckMandateModal}>
                        Create new mandate request
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>,
      ];
    }

    return [
      debitOrderDetails,
      debiCheckDetails,
    ];
  };

  renderMethod = () => {
    switch (this.state.paymentMethod.type) {
      case 'debit_order':
        return this.renderDebitOrderMethod();
      case 'card':
        return this.renderCardPaymentMethod();
      default:
        return null;
    }
  };

  render = () => {
    if (this.props.loading || this.state.loading) {
      return (
        <div>
          <i>Loading payment method...</i>
        </div>
      );
    }

    if (!this.state.paymentMethod) {
      return (
        <Card>
          <CardBody>No payment method set</CardBody>
        </Card>
      );
    }

    return (
      <div>
        <Container style={{ padding: 0 }}>
          <Card>
            <CardBody>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Payment method id:</strong>
                    </td>
                    <td>
                      {renderValue(
                        'paymentMethodId',
                        this.state.paymentMethod.paymentMethodId,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Payment method type:</strong>
                    </td>
                    <td>{prettyMethodType(this.state.paymentMethod.type)}</td>
                  </tr>
                  {this.renderMethod()}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>

        <SetBANVStatusModal
          onClose={this.closeSetBANVStatusModal}
          show={this.state.showSetBANVStatusModal}
          organizationId={this.state.paymentMethod.organizationId}
          paymentMethodId={this.state.paymentMethod.paymentMethodId}
          onBANVStatusUpdated={this.fetchPaymentMethod}
        />

        {this.state.showCreateNewDebicheckMandateModal && (
          <>
          <Modal
            isOpen={this.state.showCreateNewDebicheckMandateModal}
            onClosed={() => this.closeCreateNewDebicheckMandateModal()}
          >
            <ModalHeader>Create New DebiCheck Mandate</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to create a new DebiCheck mandate for this policy? Any new mandate will have to be approved by the policyholder</p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.closeCreateNewDebicheckMandateModal()}>Cancel</Button>
              <Button color='primary' onClick={() => {
                this.createNewMandate(); this.closeCreateNewDebicheckMandateModal(); }}>Confirm</Button>
            </ModalFooter>
          </Modal>
    </>
        )}
        {this.state.showResubmitBanvModal && (
          <>
          <Modal
            isOpen={this.state.showResubmitBanvModal}
            onClosed={() => this.closeResubmitBanvModal()}
          >
            <ModalHeader>Resubmit BANV Request</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to resubmit a BANV request for this policy?</p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.closeResubmitBanvModal()}>Cancel</Button>
              <Button color='primary' onClick={() => {
                this.resubmitBanv(); this.closeResubmitBanvModal(); }}>Confirm</Button>
            </ModalFooter>
          </Modal>
    </>
        )}
      </div>
    );
  };
}


export default withData({
  path: (props) => `/insurance/admin/policies/${props.params.policyId}`,
  prop: 'policy',
})(PolicyPaymentMethod);
