/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='106.25px'
        height='107.833px'
        viewBox='0 0 106.25 107.833'
        enableBackground='new 0 0 106.25 107.833'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#D1D3D4'
            d='M31.107,66.012c-2.579-1.273-5.471-2.008-8.541-2.008c-10.682,0-19.339,8.658-19.339,19.34
        c0,10.678,8.657,19.337,19.339,19.337c3.07,0,5.962-0.734,8.541-2.009V66.012z'
          />
          <path
            fill='#F1F2F2'
            d='M31.107,77.017c-1.994-2.9-5.332-4.804-9.117-4.804c-6.109,0-11.06,4.951-11.06,11.06
        c0,6.107,4.951,11.06,11.06,11.06c3.786,0,7.124-1.904,9.117-4.804V77.017z'
          />
          <path
            fill='#D1D3D4'
            d='M31.107,73.642l-4.936,4.937c-2.438-1.967-6.017-1.82-8.282,0.446c-2.423,2.424-2.423,6.354,0,8.778
        L30.888,100.8c0.074-0.035,0.145-0.077,0.219-0.114V73.642z'
          />
          <circle fill='#FFFFFF' cx='22.198' cy='68.178' r='2.086' />
          <circle fill='#FFFFFF' cx='22.198' cy='98.471' r='2.086' />
          <circle fill='#FFFFFF' cx='11.489' cy='72.615' r='2.087' />
          <circle fill='#FFFFFF' cx='7.051' cy='83.325' r='2.085' />
          <path
            fill='#D1D3D4'
            d='M31.107,83.229l-0.236-0.001l-4.203-4.203c-2.424-2.425-6.354-2.425-8.779,0
        c-2.423,2.424-2.423,6.354,0,8.778L30.888,100.8c0.074-0.035,0.145-0.077,0.219-0.114V83.229z'
          />
          <circle fill='#FFFFFF' cx='11.489' cy='94.036' r='2.087' />
          <path
            fill='#FFFFFF'
            d='M31.107,81.742h-8.199c-0.968,0-1.753,0.857-1.753,1.914c0,1.055,0.785,1.914,1.753,1.914h8.199V81.742z'
          />
        </g>
        <path
          fill='#D1D3D4'
          d='M31.107,45.161c-2.882,1.364-6.433,0.864-8.814-1.518l-5.02-5.021c-0.577-0.574-0.577-1.508,0-2.083
      c0.575-0.577,1.509-0.577,2.085,0l5.021,5.022c1.852,1.852,4.846,1.868,6.729,0.066v-6.963l-4.938-4.939
      c-0.573-0.575-0.573-1.51,0-2.084c0.576-0.573,1.509-0.573,2.085,0.002l2.854,2.854V20.205c-2.579-1.274-5.471-2.009-8.541-2.009
      c-10.68,0-19.339,8.659-19.339,19.337c0,10.68,8.659,19.34,19.339,19.34c3.07,0,5.962-0.735,8.541-2.009V45.161z M18.94,47.423
      c-0.576,0.577-1.509,0.577-2.085,0l-4.998-4.998c-3.027-3.028-3.027-7.952,0-10.983c3.027-3.024,7.952-3.024,10.979,0l4.999,5.001
      c0.576,0.575,0.576,1.508,0.002,2.083c-0.577,0.577-1.51,0.577-2.084,0l-4.999-4.999c-1.88-1.877-4.936-1.877-6.813,0
      c-1.878,1.879-1.878,4.934,0,6.815l4.999,4.996C19.516,45.916,19.516,46.851,18.94,47.423z'
        />
        <g>
          <g>
            <path
              fill='#5E97FC'
              d='M92.31,4.327c-0.391-0.391-1.023-0.391-1.414,0L87.032,8.19c-0.391,0.39-0.391,1.023,0,1.414
          c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l3.863-3.863C92.7,5.351,92.7,4.718,92.31,4.327z'
            />
            <path
              fill='#5E97FC'
              d='M82.787,0.381c-0.553,0-1,0.448-1,1v5.284c0,0.552,0.447,1,1,1s1-0.448,1-1V1.381
          C83.787,0.829,83.34,0.381,82.787,0.381z'
            />
            <path
              fill='#5E97FC'
              d='M95.254,12.849H89.95c-0.553,0-1,0.448-1,1s0.447,1,1,1h5.304c0.553,0,1-0.448,1-1
          S95.807,12.849,95.254,12.849z'
            />
          </g>
          <g>
            <path
              fill='#202B40'
              d='M80.408,74.694v18.953h-41.84V16.253h41.84v24.562h4.399V16.653c0-2.571-2.103-4.675-4.673-4.675H38.841
          c-2.569,0-4.672,2.104-4.672,4.675v85.536c0,2.57,2.103,4.672,4.672,4.672h41.293c2.57,0,4.673-2.102,4.673-4.672V74.694H80.408z
           M60.424,103.758c-1.938,0-3.504-1.569-3.504-3.504c0-1.936,1.566-3.505,3.504-3.505c1.935,0,3.503,1.569,3.503,3.505
          C63.927,102.189,62.358,103.758,60.424,103.758z'
            />
          </g>
          <g>
            <path
              fill='#5E97FC'
              d='M105.25,65.792c0,2.979-2.417,5.393-5.396,5.393H48.058c-2.978,0-5.394-2.414-5.394-5.393V49.612
          c0-2.979,2.416-5.394,5.394-5.394h51.796c2.979,0,5.396,2.415,5.396,5.394V65.792z'
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M50.089,60.726l0.125-2.414l-2.038,1.303l-0.461-0.821l2.142-1.091l-2.142-1.093l0.461-0.84l2.038,1.322
              l-0.125-2.416h0.964l-0.125,2.416l2.014-1.322l0.486,0.84l-2.145,1.093l2.145,1.091l-0.486,0.821l-2.014-1.303l0.125,2.414
              H50.089z'
                />
              </g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M59.33,60.726l0.125-2.414l-2.039,1.303l-0.461-0.821l2.144-1.091l-2.144-1.093l0.461-0.84l2.039,1.322
              l-0.125-2.416h0.966l-0.127,2.416l2.018-1.322l0.484,0.84l-2.144,1.093l2.144,1.091l-0.484,0.821l-2.018-1.303l0.127,2.414
              H59.33z'
                />
              </g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M68.571,60.726l0.127-2.414l-2.039,1.303l-0.461-0.821l2.142-1.091l-2.142-1.093l0.461-0.84l2.039,1.322
              l-0.127-2.416h0.969l-0.128,2.416l2.018-1.322l0.482,0.84l-2.142,1.093l2.142,1.091l-0.482,0.821l-2.018-1.303l0.128,2.414
              H68.571z'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M78.376,60.726l0.126-2.414l-2.038,1.303l-0.463-0.821l2.144-1.091l-2.144-1.093l0.463-0.84l2.038,1.322
              l-0.126-2.416h0.966l-0.126,2.416l2.017-1.322l0.483,0.84l-2.143,1.093l2.143,1.091l-0.483,0.821l-2.017-1.303l0.126,2.414
              H78.376z'
                />
              </g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M87.618,60.726l0.126-2.414l-2.037,1.303l-0.463-0.821l2.144-1.091l-2.144-1.093l0.463-0.84l2.037,1.322
              l-0.126-2.416h0.968l-0.128,2.416l2.018-1.322l0.482,0.84l-2.142,1.093l2.142,1.091l-0.482,0.821l-2.018-1.303l0.128,2.414
              H87.618z'
                />
              </g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M96.86,60.726l0.125-2.414l-2.036,1.303l-0.462-0.821l2.143-1.091l-2.143-1.093l0.462-0.84l2.036,1.322
              l-0.125-2.416h0.967L97.7,57.092l2.019-1.322l0.482,0.84l-2.142,1.093l2.142,1.091l-0.482,0.821L97.7,58.312l0.127,2.414H96.86z
              '
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
