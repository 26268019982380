/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='238.768px'
        height='62.772px'
        viewBox='0 0 238.768 62.772'
        enableBackground='new 0 0 238.768 62.772'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#4CC0B4'
            d='M164.37,17.545v-0.173c0-1.895-1.491-3.437-3.323-3.437H97.683c-1.833,0-3.324,1.542-3.324,3.437
    l-0.035,17.049c0.037,0.16,0.062,0.326,0.062,0.499v0.357c0,1.212-0.982,2.195-2.195,2.195H79.013
    c-1.207,0.006-2.184,0.984-2.184,2.193v0.357c0,0.943,0.599,1.74,1.434,2.052h14.204c1.211,0,2.193,0.982,2.193,2.194v0.357
    c0,1.212-0.982,2.195-2.193,2.195H92.19v0.015h-4.184c-1.211,0-2.194,0.982-2.194,2.194v0.358c0,1.212,0.983,2.194,2.194,2.194
    h6.539h3.475c1.212,0,2.194,0.982,2.194,2.194v0.358c0,1.212-0.982,2.194-2.194,2.194H74.838c-0.944,0.245-1.644,1.097-1.644,2.116
    v0.358c0,1.212,0.983,2.194,2.195,2.194H94.5h10.241h49.694c0.355,0,3.36,0,3.36,0c5.184,0,9.387-4.742,9.387-9.926V12.546
    C166.833,14.524,165.812,16.268,164.37,17.545z'
          />
          <g>
            <g>
              <path
                fill='#44AFA3'
                d='M158.188,43.786c4.504,0,8.237,3.259,8.995,7.544V10.859c0-5.446-4.414-9.859-9.86-9.859v42.83
        C157.607,43.804,157.896,43.786,158.188,43.786z'
              />
            </g>
          </g>
          <line
            fill='#FFFFFF'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='52.496'
            y1='53.915'
            x2='52.496'
            y2='45.222'
          />
          <line
            fill='#FFFFFF'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='48.149'
            y1='49.568'
            x2='56.842'
            y2='49.568'
          />
          <line
            fill='#CFD5D9'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='72.968'
            y1='23.74'
            x2='67.189'
            y2='23.74'
          />
          <line
            fill='#CFD5D9'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='70.078'
            y1='26.63'
            x2='70.078'
            y2='20.85'
          />
          <line
            fill='#CFD5D9'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='25.537'
            y1='30.502'
            x2='21.36'
            y2='30.502'
          />
          <line
            fill='#CFD5D9'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='23.449'
            y1='32.59'
            x2='23.449'
            y2='28.413'
          />
          <circle
            fill='none'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeMiterlimit='10'
            cx='43.638'
            cy='33.516'
            r='2.833'
          />
          <circle
            fill='none'
            stroke='#D2D3D4'
            strokeWidth='1.4173'
            strokeMiterlimit='10'
            cx='6.532'
            cy='41.907'
            r='2.076'
          />
          <circle
            opacity='0.8'
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            cx='136.384'
            cy='37.472'
            r='11.02'
          />
          <path
            opacity='0.8'
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='
    M117.36,21.544h-7.022l0,0c0,3.092-2.506,5.598-5.597,5.598v20.662c3.091,0,5.596,2.505,5.597,5.597h7.022'
          />
        </g>
      </svg>
    );
  }
}
