import React from 'react';
import { EmailRecipient } from '../../../outbound-notifications/domain/email-recipient';

const RecipientNameCellElement: React.SFC<EmailRecipient> = ({ name }) => {
  return (
    <div className='table-cell-single'>
      {name}
    </div>
  );
};

export const RecipientNameColumn = {
  heading: 'Name',
  element: RecipientNameCellElement,
};

const RecipientEmailCellElement: React.SFC<EmailRecipient> = ({ email }) => {
  return (
    <div className='table-cell-single'>
      {email}
    </div>
  );
};

export const RecipientEmailColumn = {
  heading: 'Email',
  element: RecipientEmailCellElement,
};

