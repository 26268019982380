import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';
import moment, { Moment } from 'moment';

export class InputDateBlockState extends BlockState {
  readonly value?: Moment;

  constructor(init: Construct<InputDateBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      value: this.value && this.value.toISOString(),
    };
  }

  static fromJSON(init: any) {
    return new InputDateBlockState({
      type: init.type,
      value: init.value && moment(init.value),
    });
  }
}
