import * as React from 'react';
import { Input } from 'reactstrap';

export class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.defaultValue) {
      this.state = {
        values: [...this.props.defaultValue],
      };
    } else {
      this.state = {
        values: [],
      };
    }
  }

  onCheckBoxChange = (optionValue, event) => {
    const isOn = event.target.checked;
    const indexInState = this.state.values.indexOf(optionValue);
    const isOnInState = indexInState > -1;

    if (isOn && !isOnInState) {
      this.setState(
        {
          values: [...this.state.values, optionValue],
        },
        () => this.submitChanges(),
      );
    } else if (!isOn && isOnInState) {
      const newArray = [...this.state.values];
      newArray.splice(indexInState, 1);

      this.setState(
        {
          values: newArray,
        },
        () => this.submitChanges(),
      );
    }
  };

  submitChanges = () => {
    this.props.onChange(this.props.identifier, this.state.values);
  };

  render() {
    const { props } = this;
    return (
      <div className='root-input'>
        <label>{props.label}</label>
        <p>{props.description}</p>

        {props.options.map((o, index) => (
          <div key={index} className='checkbox'>
            <Input
              onChange={event => this.onCheckBoxChange(o.value, event)}
              type='checkbox'
              checked={this.state.values.indexOf(o.value) > -1}
            />
            {` ${o.label}`}
          </div>
        ))}
      </div>
    );
  }
}
