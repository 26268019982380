/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='123px'
        height='100px'
        viewBox='-0.407 -1.329 123 100'
        enableBackground='new -0.407 -1.329 123 100'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#CAE0FF'
            d='M97.196,13.793H25.374l-0.002-0.001c-1.478,0-2.72,1.002-3.169,2.383c-0.115,0.352-0.191,0.722-0.191,1.113 v0.001v18.252v30.396c0,1.927,1.507,3.495,3.36,3.495h71.822l0.002,0.001c1.478,0,2.72-1.002,3.169-2.383 c0.115-0.352,0.191-0.722,0.191-1.113v-0.001V47.683V17.288C100.557,15.361,99.05,13.793,97.196,13.793z'
          />
          <g>
            <g>
              <circle fill='#202B40' cx='50.822' cy='35.401' r='2.178' />
              <circle fill='#202B40' cx='71.747' cy='35.401' r='2.179' />
            </g>
            <g>
              <circle fill='#FFFFFF' cx='47.647' cy='42.762' r='2.179' />
              <circle fill='#FFFFFF' cx='74.921' cy='42.762' r='2.179' />
            </g>
            <path
              fill='#202B40'
              d='M67.016,44.274c0,3.163-2.566,5.729-5.73,5.729s-5.729-2.565-5.729-5.729'
            />
          </g>
          <ellipse
            fill='#0E131D'
            cx='60.008'
            cy='91.056'
            rx='40.037'
            ry='3.842'
          />
        </g>
        <g>
          <line
            fill='none'
            stroke='#CAE0FF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='23.455'
            y1='41.612'
            x2='3.452'
            y2='24.327'
          />
          <line
            fill='none'
            stroke='#CAE0FF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='99.739'
            y1='41.612'
            x2='119.742'
            y2='24.327'
          />
        </g>
        <g>
          <polyline
            fill='none'
            stroke='#CAE0FF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            points='46.863,90.482 52.217,90.482 52.217,69.431'
          />
          <polyline
            fill='none'
            stroke='#CAE0FF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            points='73.664,90.482 68.311,90.482 68.311,69.431'
          />
        </g>
        <g>
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='108.449'
            y1='5.908'
            x2='104.315'
            y2='10.043'
          />
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='99.016'
            y1='2'
            x2='99.016'
            y2='7.654'
          />
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='112.359'
            y1='15.342'
            x2='106.683'
            y2='15.342'
          />
        </g>
      </svg>
    );
  }
}
