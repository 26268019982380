import React, { Component } from 'react';
import { ModalBody, Button, ModalFooter, ModalHeader, Table } from 'reactstrap';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-night.css';
import { FullScreenModal } from '../../../../components/modals/full-screen';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import { GeneralModal } from '../modals';
import {
  establishState,
  ProductModuleDefinitionIndexStructure,
} from './orchestration';
import CodeWindow from '../../../utils/code-editor/code-editor';
import moment, { Moment } from 'moment';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../stores/product-module-store';
import LoadingInPage from '../../../loading';
import '../../../styles/resizable-column-panel.scss';
import '../../../styles/styles.scss';
import '../../../styles/code-window.scss';

import { Icon18PXCog } from '../../../../components/icons/icon-18-px-cog';
import Select from 'react-select';
import { PolicyIssuingFlowStore } from '../stores/policy-issuing-flow-store';
import { Icon16PXChevron } from '../../../../components/icons/icon-16-chevron';
import {
  ProductModuleCodeConsole,
  ConsoleData,
} from '../stores/product-module-code-console';
import { OrganizationListStore } from '../../../../organizations/stores/organization-list-store';
import { toSnakeCase } from '../../../../helpers';
import SelectOrganizationModal from '../components/select-organization-modal';
import ResizableColumnPanel from '../components/resizable-column-component';
import ResizableRowPanel from '../components/resizable-row-component';
import { compileFullCodeFromVirtualFiles, splitCodeIntoVirtualFiles, VirtualFile } from './multiple-files-helpers';

interface ProductModuleDefinition {
  product_module_definition_id?: string;
  product_module_id?: string;
  terms_file_id?: string;
  published_at?: Moment;
  created_by?: { id: string; type: string };
  welcome_letter_id?: string;
  schedule_id?: string;
  member_certificate_id?: string;
  version_minor?: number;
  version_major?: number;
  quote_schema_id?: number;
  claim_schema_id?: string;
  settings?: AnalyserNode;
  published_by?: { id: string; type: string };
  code_id?: string;
  application_schema_id?: string;
}

export enum RequestTypes {
  get = 'GET',
  post = 'POST',
}

interface SelectOptions {
  label: string;
  value: {
    type: RequestTypes;
    path: string;
  };
}

interface State {
  showSaveModal: boolean;
  showCancelModal: boolean;
  showOrganizationModal: boolean;
  loading: boolean;
  initialPositionRow: number;
  initialPositionColumn: number;
  snapConsole: boolean;
  snapResponse: boolean;
  unsavedChanges: boolean;
  requestBody: string;
  activeFileName: string;
  files: VirtualFile[];
}

interface ProductModuleJavascript {
  modalContentSaved: {
    title: string;
    body: string;
    submit: string;
  };
  modalContentCancel: {
    title: string;
    body: string;
    submit: string;
    cancel: string;
  };
}

interface Props {
  type: ProductModuleDefinitionIndexStructure;
  toDbKey: string;
  productModuleKey: string;
  breadcrumbCodeName: string;
  closeDocument: () => any;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  organizationListStore: OrganizationListStore;
  policyIssuingFlowStore: PolicyIssuingFlowStore;
}

interface ProductModuleJavascript {
  consoleStore: ProductModuleCodeConsole;
}

const defaultRequest = {
  '_p_o_s_t /v1/insurance/quotes': {
    type: 'root_funeral',
    cover_amount: '2000000',
    age: 26,
    gender: 'male',
    spouse_included: false,
    children_included: false,
    extended_family_included: false,
  },
  '_p_o_s_t /v1/insurance/applications': {
    quote_package_id: 'dfb698b3-961b-4703-bb62-522de2772749',
    policyholder_id: '12be0565-786d-48d2-af70-a352db6bee06',
  },
  '_p_o_s_t /v1/insurance/policies': {
    application_id: '616782ef-6397-4faa-af5d-dc48fc1ed3e9',
  },
  '_g_e_t /v1/insurance/policyholder (indervidual)': {},
  '_p_o_s_t /v1/insurance/policyholder (indervidual)': {
    first_name: 'elrich',
    last_name: 'aviato',
    email: 'ryan@root.co.za',
    id: {
      type: 'id',
      number: '9310115800082',
      country: 'ZA',
    },
    address: {
      line_1: '2b wheelan street',
      line_2: 'rondabosch',
      suburb: 'Cape Town',
      city: 'Cape Town',
      area_code: '12121234',
      country: 'SD',
    },
  },
};

@inject('productModuleStore')
@inject('organizationListStore')
@inject('policyIssuingFlowStore')
@observer
class ProductModuleJavascript extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSaveModal: false,
      showCancelModal: false,
      loading: true,
      initialPositionRow: 0,
      initialPositionColumn: 0,
      snapConsole: false,
      snapResponse: true,
      unsavedChanges: false,
      showOrganizationModal: false,
      requestBody: JSON.stringify(defaultRequest['_p_o_s_t /v1/insurance/quotes']),
      activeFileName: '',
      files: [],
    };

    this.consoleStore = new ProductModuleCodeConsole();
    this.modalContentSaved = {
      title: 'Schema Saved',
      body: 'Your schema has successfully been saved',
      submit: 'Ok',
    };

    this.modalContentCancel = {
      title: 'Warning!',
      body: 'Would you like to save your changes before closing',
      submit: 'Ok',
      cancel: 'No',
    };
  }


  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const {
      productModuleStore,
      type,
      productModuleKey,
    } = this.injected;
    await productModuleStore.init(productModuleKey);

    const productModuleDefinition =
      productModuleStore.productModuleDefinitionDraft;

    const initialState = await establishState(productModuleDefinition, type);

    if (initialState) {
      const initialCode = initialState.productModuleDefinitionCode;
      const files = splitCodeIntoVirtualFiles(initialCode);
      this.setState({
        files,
        activeFileName: files[0].fileName,
        loading: false,
      });
    }
  }

  closeCancelModal = () => {
    this.setState({ showCancelModal: false });
  };

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  submitModal = () => {
    this.closeSaveModal();
  };

  saveDraftMethod = async () => {
    const { productModuleStore, toDbKey, policyIssuingFlowStore } = this.injected;
    const productModule = productModuleStore.productModule;

    // TODO transform files into single file to send
    const data = {
      [toDbKey]: compileFullCodeFromVirtualFiles(this.state.files),
    };

    await productModuleStore.createProductModuleDefinition({
      productModuleId: productModule.productModuleId,
      data,
    });

    policyIssuingFlowStore.updateRequestSimulatorDropdown({
      organizationId: policyIssuingFlowStore.organizationId,
      productModuleId: productModuleStore.productModule.productModuleId,
      productModuleDefinitionId: productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
    });
  };

  moduleCodeHeader = () => {
    return (
      <div className='product-module-code-main-heading-wrapper'>
        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <div className='product-module-code-file-bar'>
            {
              this.state.files.map(file =>
                <div
                  key={file.fileName}
                  className={`product-module-code-file-bar-tab ${file.fileName === this.state.activeFileName ? 'active' : ''}`}
                  onClick={() => this.setState({ activeFileName: file.fileName })}
                >
                  {file.fileName}
                  {
                    file.fileName.toLowerCase() !== 'main.js'
                    && <Icon24PXCross width='12px' height='12px' onClick={(e: any) => {
                      e.stopPropagation();
                      const confirmation = prompt(`Are you sure you want to delete this file and all it\'s code?\n\nThis will persist when you save the code.\n\nPlease type "${file.fileName}" to confirm this action.`);
                      if (confirmation && confirmation === file.fileName) {
                        const files = this.state.files.filter(f => f.fileName !== file.fileName);
                        this.setState({ files, activeFileName: files[0].fileName, unsavedChanges: true });
                      }
                    }} />
                  }
                </div>,
              )
            }
            <div
              className='product-module-code-file-bar-tab add-button'
              onClick={() => {
                let newFileName = prompt('Enter a name for new file', 'My File');
                if (newFileName) {
                  newFileName = newFileName.replace('.js', '') + '.js'; // Make sure we don't sit with .js.js
                  const alreadyUsed = this.state.files.filter(f => f.fileName.toLowerCase() === newFileName!.toLowerCase()).length > 0;
                  if (newFileName.toLowerCase() === 'main.js' || alreadyUsed) {
                    return; // not allowed
                  }
                  const file: VirtualFile = {
                    fileName: newFileName,
                    code: `// File: ${newFileName}\n// Created: ${moment().format('YYYY-MM-DD hh:mm')}\n`,
                  };
                  const files = this.state.files;
                  files.push(file);
                  this.setState({ files, activeFileName: newFileName, unsavedChanges: true });
                }
              }}
            >
              <Icon24PXCross width='12px' height='12px' />
            </div>
          </div>
        </div>
        <div
          className='arrow-right-border-fill heading-badge inline-div-with-padding-left-right'
          style={{ float: 'right' }}
        >
          JS
        </div>
      </div>
    );
  };

  sendRequest = async () => {
    const { policyIssuingFlowStore } = this.injected;
    this.consoleStore.updateIsLoadingResponse(true);

    if (this.state.unsavedChanges) {
      this.saveDraftMethod();
      this.setState({ unsavedChanges: false });
    }

    await policyIssuingFlowStore.apiRequest(
      JSON.parse(this.state.requestBody),
    );
    this.consoleStore.updateResponseData(
      JSON.stringify(policyIssuingFlowStore.apiData),
    );
    this.consoleStore.updateConsoleData({
      timeStamp: `${moment().format('DD-MM-YYYY')} at ${moment().format(
        'HH:mm:ss',
      )}`,
      type: policyIssuingFlowStore.requestStatus,
      description: policyIssuingFlowStore.selectedEndpoint.path,
    });

    const selectedEndpoint = policyIssuingFlowStore.selectedEndpoint;
    const { label } = selectedEndpoint;

    const { localStorage } = window;
    localStorage.setItem(toSnakeCase(label), this.state.requestBody);

    this.consoleStore.updateIsLoadingResponse(false);

  };

  setExampleRequest = (label: string) => {
    const { localStorage } = window;
    const labelSnakeCase = toSnakeCase(label);
    if (Object.keys(localStorage).find((key) => key === toSnakeCase(label))) {
      this.setState({
        requestBody: localStorage[labelSnakeCase],
      }, () => this.consoleStore.updateIsLoadingRequest(false));
    }
    else {
      this.setState({ requestBody: JSON.stringify((defaultRequest as any)[labelSnakeCase]) },
        () => this.consoleStore.updateIsLoadingRequest(false));
    }
  }

  apiSimulatorHeading = (selectedOrg: string, dropdownList: any) => {
    const { policyIssuingFlowStore } = this.injected;
    return (
      <div>
        <div className='product-module-code-main-heading-wrapper'>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <h5 className='product-module-code-font-color'>API simulator</h5>
          </div>
          <div style={{ display: 'inline-block', float: 'right' }}>
            <div
              className='product-module-code-main-heading'
              style={{
                width: 200,
              }}
            >
              <Select
                key='refund_the_policy_holder'
                styles={customStyles}
                defaultValue={dropdownList && dropdownList[0]}
                options={dropdownList}
                onChange={(value: SelectOptions) => {
                  this.consoleStore.updateIsLoadingRequest(true);
                  policyIssuingFlowStore.updateSelectedEndpoint({
                    label: value.label,
                    path: value.value.path,
                    type: value.value.type,
                  }),
                  this.setExampleRequest(value.label);
                }}
              />
            </div>
            <div className='product-module-code-main-heading'>
              <Button
                onClick={() => this.sendRequest()}
                color='primary'
                className='general-button-center-positioning'
                style={{ marginRight: 0 }}
                disabled={selectedOrg === 'No org selected' || this.consoleStore.isLoadingResponse}
              >
                {this.consoleStore.isLoadingResponse ? 'Sending...' : 'Send'}
              </Button>
            </div>
          </div>
        </div>
        <div
          className='product-module-code-background-color'
          style={{ display: 'flex' }}
        >
          <p className='product-module-code-secondary-header-wrapper'>
            Example request
          </p>
        </div>
      </div>
    );
  };

  noResponseData = (message: string) => {
    return (
      <div className='example-response-no-data-outer'>
        <div>
          <div className='example-response-no-data-inner' />
          <p style={{ color: '#082D36', textAlign: 'center' }}>{message}</p>
        </div>
      </div>
    );
  };

  exampleResponseHeader = () => {
    return (
      <div
        className='product-module-code-background-color'
        style={{ display: 'flex' }}
      >
        <p className='product-module-code-secondary-header-wrapper'>
          Example response
        </p>
        <div className='product-module-code-secondary-header'>
          <Icon16PXChevron rotate={!this.state.snapResponse} />
        </div>
      </div>
    );
  };

  consoleHeader = () => {
    return (
      <div
        className='product-module-code-background-color'
        style={{ display: 'flex' }}
        onClick={() => this.setState({ snapConsole: !this.state.snapConsole })}
      >
        <p className='product-module-code-secondary-header-wrapper'>Console</p>
        <div className='product-module-code-secondary-header'>
          <Icon16PXChevron rotate={!this.state.snapConsole} />
        </div>
      </div>
    );
  };

  lastEdited = () => {
    const { productModuleStore } = this.injected;
    return (
      <div
        style={{
          paddingRight: 24,
          fontSize: 12,
          color: '#3D3D3D',
          fontWeight: 300,
        }}
      >
        Edited{' '}
        {moment
          .duration(
            moment()
              .utc()
              .diff(
                productModuleStore.productModuleDefinitionDraft.createdAt,
              ),
          )
          .humanize()}{' '}
        ago
      </div>
    );

    return <div></div>;
  };

  showUnsaved = () => {
    return (
      <div
        style={{
          paddingRight: 24,
          fontSize: 12,
          color: '#3D3D3D',
          fontWeight: 300,
        }}
      >
        <div className='arrow-right-border-fill completed-card-background-color-grey inline-div-with-padding-left-right'>
          Unsaved changes
        </div>
      </div>
    );
  };

  consoleComponent = (consoleData: ConsoleData[]) => {
    if (consoleData.length === 0) {
      return this.noResponseData('No data log');
    }

    return (
      <Table className='product-module-code-console-table product-module-code-background-color'>
        <tbody>
          {consoleData.map((rowData: ConsoleData, index: number) => {
            const errorClassName =
              rowData.type === 'Error'
                ? 'product-module-code-console-rows-error'
                : 'product-module-code-console-rows-success';
            return (
              <tr key={index}>
                <td className='product-module-code-console-rows'>
                  {rowData.timeStamp}
                </td>
                <td
                  className={`product-module-code-console-rows ${errorClassName}`}
                >
                  {rowData.type}
                </td>
                <td className='product-module-code-console-rows'>
                  {rowData.description}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  render() {
    const {
      productModuleStore,
      policyIssuingFlowStore,
    } = this.injected;
    if (
      !productModuleStore.isLoaded ||
      this.state.loading
    ) {
      return <LoadingInPage />;
    }
    const lockedComponent = productModuleStore.lockedComponent;

    let codeWindow = undefined;
    const openFile = this.state.files.find(file => file.fileName === this.state.activeFileName);
    if (openFile) {
      codeWindow = <CodeWindow
        defaultValue={openFile.code}
        language='typescript'
        width='100%'
        toDbKey={'product_module_code'}
        otherFiles={this.state.files.filter(file => file.fileName !== openFile.fileName)}
        initialPos={this.state.initialPositionColumn}
        handleCodeChange={(productModuleCode: string) => {
          const files = this.state.files.map(file => {
            if (file.fileName === openFile!.fileName)
              file.code = productModuleCode;
            return file;
          });
          this.setState({ files }, () => {
            if (!this.state.unsavedChanges) {
              this.setState({ unsavedChanges: true });
            }
          });
        }}
      />;
    }

    return (
      <FullScreenModal isOpen={true}>
        <GeneralModal
          show={this.state.showSaveModal}
          onclose={this.closeSaveModal}
          submitModal={this.submitModal}
          modalContent={this.modalContentSaved}
        />
        <SelectOrganizationModal
          show={this.state.showOrganizationModal}
          productModuleId={productModuleStore.productModule.productModuleId}
          productModuleDefinitionId={productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId}
          policyIssuingFlowStore={policyIssuingFlowStore}
          onClose={() => this.setState({ showOrganizationModal: false })}
        />
        <GeneralModal
          show={this.state.showCancelModal}
          onClose={() => this.props.closeDocument()}
          submitModal={() => {
            this.setState({ showSaveModal: true, unsavedChanges: false });
            this.saveDraftMethod();
            this.setState({ showCancelModal: false });
            this.props.closeDocument();
          }}
          modalContent={this.modalContentCancel}
        />
        <ModalHeader style={{ paddingBottom: 19 }}>
          <div style={{ display: 'inline-block', width: '100%' }}>
            <div style={{ float: 'left' }}>
              <div
                style={{
                  display: 'inline-block',
                  paddingTop: 17,
                  paddingLeft: 16,
                  marginTop: 16,
                }}
              >
                <h5>Product module code</h5>
              </div>
            </div>
            <div
              style={{
                float: 'right',
                height: 59,
                marginTop: 13,
                paddingLeft: 7,
                paddingRight: 7,
                paddingTop: 13,
                borderLeft: '1px solid rgba(61,61,61,0.3)',
              }}
            >
              <div
                className='Rectangle'
                style={{
                  display: 'inline-block',
                  paddingTop: 7,
                }}
              >
                <a
                  onClick={e => {
                    e.preventDefault;
                    if (this.state.unsavedChanges) {
                      this.setState({
                        showCancelModal: !this.state.showCancelModal,
                      });
                    } else {
                      this.props.closeDocument();
                    }
                  }}
                >
                  <Icon24PXCross />
                </a>
              </div>
            </div>
            <div style={{ float: 'right' }}>
              <div
                style={{
                  display: 'inline-block',
                  paddingTop: 17,
                  paddingRight: 16,
                  paddingLeft: 16,
                  marginTop: 16,
                }}
              >
                <h5>{policyIssuingFlowStore.organizationName}</h5>
              </div>
            </div>
            <div style={{ float: 'right', marginTop: 20 }}>
              <Button
                color='primary'
                style={{ width: 94, marginRight: 0 }}
                onClick={() => this.setState({ showOrganizationModal: true })}
              >
                Select Org
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className='no-padding'>
          <ResizableColumnPanel
            headerComponents={[
              { header: this.moduleCodeHeader() },
              {
                header: this.apiSimulatorHeading(
                  policyIssuingFlowStore.organizationName,
                  policyIssuingFlowStore.endpointListDropdown,
                ),
              },
            ]}
            updatingResize={initialPositionColumn =>
              this.setState({ initialPositionColumn })
            }
          >
            <div className='template-editor'>
              <ResizableRowPanel
                defaultPanelHeight={[900, 93]}
                bumpPanelHeightMin={[900, 93]}
                bumpPanelHeightMax={[900, 358]}
                snapPanel={this.state.snapConsole}
                headerComponents={[{ header: this.consoleHeader() }]}
                updatingResize={initialPositionRow =>
                  this.setState({ initialPositionRow })
                }
              >
                {
                  openFile
                  && <div key={openFile!.fileName} className='template-editor' onKeyDown={(event) => {
                    if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
                      event.preventDefault();
                      this.setState({ showSaveModal: true, unsavedChanges: false }, () =>
                        this.saveDraftMethod());
                    }
                  }}>
                    {codeWindow}
                  </div>
                }
                <div className='template-editor product-module-code-background-color'>
                  {this.consoleComponent(this.consoleStore.consoleData)}
                </div>
              </ResizableRowPanel>
            </div>
            <div className='template-editor'>
              <ResizableRowPanel
                headerComponents={[{ header: this.exampleResponseHeader() }]}
                defaultPanelHeight={[900, 399]}
                bumpPanelHeightMin={[900, 134]}
                bumpPanelHeightMax={[900, 399]}
                snapPanel={this.state.snapResponse}
                updatingResize={initialPositionRow =>
                  this.setState({ initialPositionRow })
                }
              >
                <div className='template-editor'>
                  {!this.consoleStore.isLoadingRequest ?
                    (
                      <CodeWindow
                        defaultValue={this.state.requestBody}
                        language='json'
                        width='100%'
                        initialPos={this.state.initialPositionRow}
                        handleCodeChange={(requestBody: string) =>
                          this.setState({ requestBody })
                        }
                      />
                    ) : this.noResponseData('No request data')}
                </div>
                <div className='template-editor'>
                  {!this.consoleStore.isLoadingResponse &&
                    this.consoleStore.responseData.length > 0 ? (
                      <CodeWindow
                        defaultValue={this.consoleStore.responseData}
                        language='json'
                        width='100%'
                        initialPos={this.state.initialPositionRow}
                      />
                    ) : (
                      this.noResponseData('No respose yet')
                    )}
                </div>
              </ResizableRowPanel>
            </div>
          </ResizableColumnPanel>
        </ModalBody>
        <ModalFooter>
          {
            <div className='full-icon'>
              <Icon18PXCog />
            </div>
          }
          <div style={{ paddingLeft: 8 }}>
            {
              <h5 style={{ margin: 0 }}>
                {productModuleStore.productModule.name} product module
              </h5>
            }
          </div>
          <div className='right-content'>
            {lockedComponent ||
              (this.state.unsavedChanges && this.showUnsaved())}
            {this.lastEdited()}
            {
              <Button
                color='primary'
                outline
                style={{ width: 94 }}
                disabled={lockedComponent}
                onClick={() => {
                  if (this.state.unsavedChanges) {
                    this.setState({
                      showCancelModal: !this.state.showCancelModal,
                    });
                  } else {
                    this.props.closeDocument();
                  }
                }}
              >
                Cancel
              </Button>
            }
            {
              <Button
                color='primary'
                style={{ width: 94 }}
                disabled={lockedComponent || !this.state.unsavedChanges}
                onClick={async () => {
                  this.setState({ showSaveModal: true, unsavedChanges: false });
                  this.saveDraftMethod();
                }}
              >
                Save
              </Button>
            }
          </div>
        </ModalFooter>
      </FullScreenModal>
    );
  }
}
export default ProductModuleJavascript;

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    background: 'rgba(255,255,255,0.1)',
    borderColor: '#49666C',
    maxHeight: '33px',
  }),
  input: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
    marginTop: '-1px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '33px',
    maxWidth: '31px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    height: '31px',
    borderLeft: '1px solid rgba(255,255,255,0.1)',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#c3c3c3',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    maxHeight: '31px',
    maxWidth: '31px',
    paddingTop: '0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#F8F8F0',
  }),
};
