import { BlockType } from './block-type';
import { InputTextBlock } from './input-text-block';
import { InputNumberBlock } from './input-number-block';
import { InputTimeBlock } from './input-time-block';
import { InputCurrencyBlock } from './input-currency-block';
import { CheckboxBlock } from './checkbox-block';
import { PayoutRequestBlock } from './payout-request-block';
import { FulfillmentRequestBlock } from './fulfillment-request-block';
import { AnnuityRequestBlock } from './annuity-request-block';
import { DropdownBlock } from './dropdown-block';
import { HeadingBlock } from './heading-block';
import { DividerBlock } from './divider-block';
import { AlertBlock } from './alert-block';
import { InputParagraphBlock } from './input-paragraph-block';
import { RadioBlock } from './radio-block';
import { MarkdownBlock } from './markdown-block';
import { InputDateBlock } from './input-date-block';
import { GroupBlock } from './group-block';

class BlockSchemaFactory {
  createBlockSchema(b: any) {
    switch (b.block.type) {
      case BlockType.Heading:
        return new HeadingBlock({ ...b.block });
      case BlockType.Divider:
        return new DividerBlock({ ...b.block });
      case BlockType.InputText:
        return new InputTextBlock({ ...b.block });
      case BlockType.InputNumber:
        return new InputNumberBlock({ ...b.block });
      case BlockType.InputTime:
        return new InputTimeBlock({ ...b.block });
      case BlockType.InputCurrency:
        return new InputCurrencyBlock({ ...b.block });
      case BlockType.InputParagraph:
        return new InputParagraphBlock({ ...b.block });
      case BlockType.Radio:
        return new RadioBlock({ ...b.block });
      case BlockType.InputDate:
        return new InputDateBlock({ ...b.block });
      case BlockType.Checkbox:
        return new CheckboxBlock({ ...b.block });
      case BlockType.Dropdown:
        return new DropdownBlock({ ...b.block });
      case BlockType.PayoutRequest:
        return new PayoutRequestBlock({ ...b.block });
      case BlockType.FulfillmentRequest:
        return new FulfillmentRequestBlock({ ...b.block });
      case BlockType.AnnuityRequest:
        return new AnnuityRequestBlock({ ...b.block });
      case BlockType.Alert:
        return new AlertBlock({ ...b.block });
      case BlockType.Markdown:
        return new MarkdownBlock({ ...b.block });
      case BlockType.Group:
        return new GroupBlock({ ...b.block });
      default:
        return undefined;
    }
  }
}

export const blockSchemaFactory = new BlockSchemaFactory();
