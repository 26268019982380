import React from 'react';

const cardEntryStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  // padding: '10px'
};

const CardEntry = ({ children, index }) => (
  <div
    style={{
      ...cardEntryStyle,
      background: index % 2 > 0 ? '#EEE' : 'inherit',
    }}
  >
    {children}
  </div>
);

export default CardEntry;
