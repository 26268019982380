import React from 'react';
import { LedgerEntry } from '../../domain/ledger-entry';
import { Table } from 'reactstrap';
import { formatDate, paymentLink, getRandValue, toCamelCaseKeys } from '../../../helpers';

interface Props {
  ledgerEntries: LedgerEntry[];
  refresh: () => any;
}

interface State {}

export class LedgerEntriesTable extends React.Component<Props, State> {

  render() {
    const { ledgerEntries } = this.props;

    return (
      <Table hover className='scrollable-table'>
        <thead>
          <tr>
            <td>Created at</td>
            <td>Payment ID</td>
            <td>Amount</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          {ledgerEntries.map((entry, index) => this.renderRow(entry, index))}
        </tbody>
      </Table>
    );
  }

  renderRow = (ledgerEntry: LedgerEntry, index: number) => {
    return (
      <tr key={index}>
        <td>{formatDate(ledgerEntry.createdAt)}</td>
        <td>{ledgerEntry.payment && paymentLink(toCamelCaseKeys(ledgerEntry.payment))}
        </td>
        <td>R {getRandValue(ledgerEntry.amount)}</td>
        <td>{ledgerEntry.description}</td>
      </tr>
    );
  };
}
