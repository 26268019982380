import React from 'react';
import { observer } from 'mobx-react';
import { DomainEntityTable } from '../../../components/domain/tables/domain-entity-table';
import { OrganizationFeature } from '../../domain/organization-feature';


const Sandbox: React.SFC<OrganizationFeature> = organizationFeature => (
  <span>{organizationFeature.sandbox ? 'Yes' : 'No'}</span>
);

const SandboxColumn = {
  heading: 'Sandbox',
  element: Sandbox,
};

const Production: React.SFC<OrganizationFeature> = organizationFeature => (
  <span>{organizationFeature.production ? 'Yes' : 'No'}</span>
);

const ProductionColumn = {
  heading: 'Production',
  element: Production,
};

const FeatureKeyElement: React.SFC<OrganizationFeature> = organizationFeature => (
  <code>{organizationFeature.featureKey}</code>
);

const FeatureKeyColumn = {
  heading: 'Feature key',
  element: FeatureKeyElement,
};

@observer
export class OrganizationFeatureListTable extends DomainEntityTable<OrganizationFeature> {}

interface Props {
  rows: OrganizationFeature[];
  isLoaded: boolean;
  selected: (organizationFeature: OrganizationFeature, index: number) => void;
}
export default class RenderOrganizationFeatureListTable extends React.Component<Props, any> {
  render() {
    const { rows, isLoaded, selected } = this.props;
    return (
      <OrganizationFeatureListTable
        rows={rows}
        columns={[FeatureKeyColumn, SandboxColumn, ProductionColumn ]}
        isLoaded={isLoaded}
        getRowProps={(organizationFeature: OrganizationFeature, index: number) => ({
          onClick: () => {
            selected(organizationFeature, index);
          },
        })}
      />
    );
  }
}
