import React from 'react';
import { Report } from '../../domain/reports';
import { sentenceCase } from 'sentence-case';


const ConfigTypeAtCellElement: React.SFC<Report> = ({ config }) => {
  return (
    <div className='table-cell-single'>
      {sentenceCase(config.type)}
    </div>
  );
};

export const ConfigTypeColumn = {
  heading: 'Type',
  element: ConfigTypeAtCellElement,
};

const CreatedAtCellElement: React.SFC<Report> = ({ createdAt }) => (
  <div className='table-cell-single'>
    {createdAt.format('YYYY-MM-DD')}
  </div>
);

export const CreatedAtColumn = {
  heading: 'Created At',
  element: CreatedAtCellElement,
};

const EnvironmentCellElement: React.SFC<Report> = ({ environment }) => (
  <div className='table-cell-single'>
    {environment}
  </div>
);

export const EnvironmentColumn = {
  heading: 'Environment',
  element: EnvironmentCellElement,
};
