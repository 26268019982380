import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { FulfillmentRequestBlock as Block } from '../domain/fulfillment-request-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { FulfillmentRequestBlockState } from '../domain/states/fulfillment-request-block-state';
import Button from 'reactstrap/lib/Button';
import { Card, CardBody } from 'reactstrap';

interface Props {
  disabled?: boolean;
  block: Block;
  blockState: FulfillmentRequestBlockState;
}

interface State {
  UIState: BlockUIState;
  fulfillmentRequestBlockState?: BlockState;
}

@observer
export class FulfillmentRequestBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      fulfillmentRequestBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  renderFulfillmentData(obj: any) {
    return typeof obj === 'object' ? (
      Object.keys(obj).map(key => {
        return (
          <tr key={key}>
            <td>{key.replace('_',' ')}: </td>
            {this.renderFulfillmentData(obj[key])}
          </tr>
        );
      })
    ) : (
      <td className='align-right'>{obj && obj.trim() ? obj : '-'}</td>
    );
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { fulfillmentRequestBlockState } = this.state;
    if (!fulfillmentRequestBlockState) {
      return null;
    }

    return (
      <Card className='blocks-details-card'>
        <CardBody>
          <div className='heading'>
            <div className='title'>
              <h4
                style={{
                  display: 'inline-block',
                  margin: '0 0 16px 0',
                  width: '50%',
                }}
              >
                {block.title}
              </h4>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                {blockState.fulfillmentRequestId === undefined ? (
                  <Button color='primary' disabled={this.state.UIState === BlockUIState.Error || disabled}>
                    Create fulfillment request
                  </Button>
                ) : (
                  <a>
                    View request
                  </a>
                )}
              </div>
            </div>
            <hr />
          </div>
          <div className='summary blocks-ui'>
            <table className='no-hover summary-table' style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>Description</td>
                  <td className='align-right'>{block.description}</td>
                </tr>
                {this.renderFulfillmentData(this.props.block.fulfillmentData)}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  }
}
