import React from 'react';
import { Button } from 'reactstrap';
import { LoadingDots } from './loading-dots';

export default class LoadingButton extends React.Component {
  render() {
    const props = Object.assign({}, this.props);
    delete props.loading;
    props.className = props.className || '';
    props.className += ' loading-button';
    if (this.props.loading) {
      props.disabled = true;
      props.className += ' loading';
    }
    return (
      <Button {...props}>
        {this.props.children}
        <LoadingDots />
      </Button>
    );
  }
}
