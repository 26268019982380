import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';

class User extends React.Component {
  render() {
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes(this.props.router.params)}
          title={sideBarSchema.name}
        />
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedUser =
    state.users.filter((u) => u.id === ownProps.params.userId)[0] || {};
  return { selectedUser };
};

export default connect(mapStateToProps)(User);

const sideBarSchema = {
  name: 'Users',
  routes: (params) => {
    const { userId } = params;
    return [
      {
        title: true,
        name: 'View',
      },
      {
        name: 'Details',
        url: `/users/${userId}/details`,
      },
      {
        name: 'Security',
        url: `/users/${userId}/security`,
      },
      {
        name: 'Organizations',
        url: `/users/${userId}/organizations`,
      },
    ];
  },
};
