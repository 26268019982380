/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='56px' height='78px' viewBox='0 0 56 78' version='1.1'>
        <defs>
          <path
            d='M41.1183613,0.979492188 L55.1258187,14.9869496 L46.1160511,14.9869496 C43.3559033,14.9869496 41.1183613,12.7519376 41.1183613,9.98925979 L41.1183613,0.979492188 Z'
            id='path-1'
          />
        </defs>
        <g
          id='Activation'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-31'
            transform='translate(-1014.000000, -306.000000)'
          >
            <g id='Group-6' transform='translate(1014.000000, 305.000000)'>
              <path
                d='M0,5.99551554 C0,3.23656849 2.23471115,1 5.00141161,1 L41.2926129,1 L55.2516375,15.0180485 L55.2516375,73.0430305 C55.2516375,75.8033415 53.0172764,78.0410156 50.250975,78.0410156 L5.00066246,78.0410156 C2.23887284,78.0410156 0,75.797774 0,73.0455001 L0,5.99551554 Z'
                id='Rectangle-16'
                fill='#E2574C'
              />
              <g id='Rectangle'>
                <use fill='#B53629' fillRule='evenodd' xlinkHref='#path-1' />
                <path
                  stroke='#B53629'
                  strokeWidth='1'
                  d='M41.6183613,2.18659897 L53.918712,14.4869496 L46.1160511,14.4869496 C43.6311324,14.4869496 41.6183613,12.4748823 41.6183613,9.98925979 L41.6183613,2.18659897 Z'
                />
              </g>
              <text
                id='PDF'
                fontFamily='ProximaNova-Semibold, Proxima Nova'
                fontSize='17'
                fontWeight='500'
                fill='#FFFFFF'
              >
                <tspan x='12' y='45'>
                  PDF
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
