import { ajax } from '../helpers';
import { Organization } from './domain/organization';

export class OrganizationApi {
  public static async getOrganizationById(params: {
    organizationId: string;
  }) {
    const { organizationId } = params;
    const result = await ajax({
      path: `organizations/${organizationId}`,
      type: 'GET',
    });

    return Organization.fromNetwork(result);
  }

  public static async updateOrganization(params: {
    organizationId: string;
    data: object;
  }) {
    const { organizationId, data } = params;

    const result = await ajax({
      path: `/admin/organizations/${organizationId}`,
      type: 'PATCH',
      data,
    });

    return Organization.fromNetwork(result);
  }
}
