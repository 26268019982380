import React from 'react';
import { Alert as ReactstrapAlert, AlertProps } from 'reactstrap';

export interface CustomAlertProps extends AlertProps {
  color: 'success' | 'primary' | 'warning' | 'danger' | 'info';
  hideIcon?: boolean;
}

export const Alert = ({ style, ...props }: CustomAlertProps) => (
  <ReactstrapAlert
    style={{
      background: backgroundColorFromProps(props),
      borderColor: borderColorFromColorProps(props),
      padding: 0,
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      // margin: '0px',
      ...style,
    }}
    {...props}
    color={props.color}
  >
    {!props.hideIcon ? (
      iconFromColorProps(props)
    ) : (
      <div style={{ margin: '10px' }} />
    )}
    <div
      style={{
        margin: '10px 0',
        display: 'inline-block',
        color: '#3d3d3d',
        width: '100%',
        paddingRight: 10,
      }}
    >
      {props.children}
    </div>
  </ReactstrapAlert>
);

const iconFromColorProps = (props: CustomAlertProps) => {
  switch (props.color) {
    case 'success':
      return (
        <img
          alt='success'
          vertical-align='middle'
          style={{ margin: '10px', height: '100%' }}
          src='/images/icons/icon-16-px-success-green.svg'
        />
      );
    case 'danger':
      return (
        <img
          alt='danger'
          vertical-align='middle'
          style={{ margin: '10px', height: '100%' }}
          src='/images/icons/icon-24-px-error.svg'
        />
      );
    case 'info':
      return (
        <img
          alt='information'
          vertical-align='middle'
          style={{ margin: '10px', height: '100%' }}
          src='/images/icons/icon-16-px-information-blue.svg'
        />
      );
    case 'warning':
      return (
        <img
          alt='information'
          vertical-align='middle'
          style={{ margin: '10px', height: '100%' }}
          src='/images/icons/icon-16-px-warning.svg'
        />
      );
    default:
      return <div />;
  }
};

const borderColorFromColorProps = (props: CustomAlertProps) => {
  switch (props.color) {
    case 'success':
      return '#1fc881';
    case 'danger':
      return '#FF4444';
    case 'info':
      return '#4078fa';
    case 'warning':
      return '#ffe7b8';
    default:
      return 'inherit';
  }
};

const backgroundColorFromProps = (props: CustomAlertProps) => {
  switch (props.color) {
    case 'success':
      return 'rgba(31, 200, 129, 0.1)';
    case 'danger':
      return 'rgba(253, 50, 56, 0.1)';
    case 'info':
      return 'rgba(64, 120, 250, 0.1)';
    case 'warning':
      return 'rgba(255, 238, 204, 0.9)';
    default:
      return 'inherit';
  }
};
