/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='77px'
        height='70.333px'
        viewBox='0 0 77 70.333'
        enableBackground='new 0 0 77 70.333'
        xmlSpace='preserve'
      >
        <path
          fill='#5E97FC'
          d='M51.274,67.27V40.993c-2.033,1.125-4.034,1.665-5.204,1.665c-0.417,0-0.761-0.059-1.054-0.18
        c-1.31-0.538-1.837-1.354-2.049-1.942c-0.429-1.189,0.103-2.247,0.165-2.364l0.109-0.205l0.181-0.146
        c0.012-0.009,1.301-1.08,1.956-2.835c-2.898-2.284-5.086-5.302-6.389-8.678h-5.81c-1.101,0-1.997,0.896-1.997,1.997v4.271H21.09
        v-4.271c0-1.102-0.896-1.997-1.997-1.997H9.132c-1.101,0-1.996,0.896-1.996,1.997v4.271H3l0-0.001c-1.104,0-2,0.896-2,2v32.696
        c0,1.104,0.896,2,2,2h46.275C50.38,69.271,51.275,68.375,51.274,67.27L51.274,67.27z'
        />
        <g>
          <path
            fill='#202B40'
            d='M45.878,40.379c0.009,0,0.071,0.011,0.192,0.011c1.202,0,4.459-1.01,6.893-3.443l0.439-0.439l0.607,0.134
          c1.296,0.287,2.614,0.433,3.919,0.433c9.946,0,18.038-8.092,18.038-18.037C75.967,9.091,67.875,1,57.929,1
          c-9.945,0-18.037,8.091-18.037,18.037c0,5.754,2.792,11.214,7.468,14.604l0.616,0.447l-0.18,0.738
          c-0.575,2.376-2.097,3.98-2.701,4.542c-0.026,0.108-0.045,0.267,0.01,0.41C45.188,39.996,45.463,40.209,45.878,40.379
          C45.876,40.379,45.877,40.379,45.878,40.379z'
          />
        </g>
        <g>
          <path
            fill='#FFFFFF'
            d='M58.54,24.875c-0.51,0-0.9-0.408-0.9-0.9v-4.352c0-1.258-0.662-1.649-1.666-1.649
          c-0.883,0-1.666,0.526-2.074,1.088v4.913c0,0.492-0.406,0.9-0.918,0.9c-0.51,0-0.9-0.408-0.9-0.9v-9.758
          c0-0.476,0.391-0.884,0.936-0.884c0.477,0,0.883,0.408,0.883,0.884v3.468c0.545-0.646,1.6-1.326,2.908-1.326
          c1.768,0,2.65,0.935,2.65,2.6v5.016C59.458,24.466,59.05,24.875,58.54,24.875z'
          />
          <path
            fill='#FFFFFF'
            d='M63.778,14.438c0,0.629-0.51,1.122-1.123,1.122c-0.611,0-1.121-0.493-1.121-1.122
          c0-0.612,0.51-1.105,1.121-1.105C63.269,13.333,63.778,13.826,63.778,14.438z M62.655,24.875c-0.51,0-0.9-0.408-0.9-0.9v-6.63
          c0-0.476,0.391-0.884,0.936-0.884c0.475,0,0.883,0.408,0.883,0.884v6.63C63.573,24.466,63.165,24.875,62.655,24.875z'
          />
        </g>
        <g>
          <g>
            <circle fill='#202B40' cx='16.454' cy='45.175' r='2.016' />
            <circle fill='#202B40' cx='35.819' cy='45.175' r='2.016' />
          </g>
          <g>
            <circle fill='#E6E7E8' cx='13.517' cy='51.987' r='2.016' />
            <circle fill='#E6E7E8' cx='38.758' cy='51.987' r='2.017' />
          </g>
          <path
            fill='none'
            stroke='#202B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M31.441,53.385
          c0,2.929-2.375,5.303-5.304,5.303c-2.926,0-5.302-2.374-5.302-5.303'
          />
        </g>
      </svg>
    );
  }
}
