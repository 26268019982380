import React from 'react';
import ajax from './ajax';
import { toCamelCaseKeys } from '.';

/**
 * Creates a HigherOrderComponent that fetches data for the given child Component
 * passing the data, loading and error states
 * @param {object} options - request options
 * @param {string|function(object): string} options.path - a path, or a function that takes props and returns a path
 * @param {string} options.prop - the prop to assign the resulting data to
 * @param {function=} options.map - a mapper function to convert the data to a different structure upon receipt
 * @returns {function(React.Component): React.Component} WithData(Component)
 */
const withData = ({ path, prop, map }) => Component => {
  return class WithData extends React.Component {
    displayName = `WithData(${Component.displayName})`;

    state = {
      loading: true,
      [prop]: null,
      error: '',
    };

    componentDidMount() {
      this.fetchData();
    }

    /**
     * Fetches data for the component, handling loading and error props
     * @param {object} options
     * @param {string} options.prop
     * @param {string} options.path
     * @param {string} options.type
     * @param {object=} options.data
     * @param {function} options.map
     */
    fetch = async ({ prop, path, type, data, map = toCamelCaseKeys }) => {
      this.setState({ loading: true });
      try {
        const response = await ajax({
          path,
          type,
          data,
        });

        if (prop && response) {
          if (response.error) {
            this.setState({ error: response.message });
          } else {
            const mappedResponse = Array.isArray(response)
              ? response.map(map)
              : map(response);

            this.setState({
              [prop]: mappedResponse,
            });
          }
        } else {
          return response;
        }
      } catch (e) {
        console.error(e);

        this.setState({ error: e.message });
      } finally {
        this.setState({ loading: false });
      }
    };

    fetchData = () =>
      this.fetch({
        prop,
        path: typeof path === 'function' ? path(this.props) : path,
        type: 'GET',
        map,
      });

    render() {
      const props = {
        ...this.props,
        ...this.state,
        fetch: this.fetch,
        [`fetch${prop[0].toUpperCase()}${prop.substring(1)}`]: this.fetchData,
      };

      return <Component {...props} />;
    }
  };
};

export default withData;
