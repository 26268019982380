
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TemplateListStore } from './data-exports/stores/template-list-store';
import { DataExportListStore } from './data-exports/stores/data-export-list-store';
import { Template, DefaultExportTemplates } from './data-exports/domain/templates/template';
import { ScheduledDataExport, ScheduledDataExportStatus } from './data-exports/domain/scheduled-data-export';
import { DataExportRun, DataExportRunStatus } from './data-exports/domain/data-export-run';
import Images from '../../components/svg-images';
import { ExportType } from './data-exports/domain/export-type';
import { DataExportViewStore } from './data-exports/stores/data-export-view-store';
import { TemplateViewStore } from './data-exports/stores/template-view-store';
import { withRouter, RouteComponentProps } from 'react-router';
import { History } from 'history';


interface Props extends RouteComponentProps {
  templates: Template[];
  dataExport: ScheduledDataExport;
  router?: History;
}

interface Injected extends Props {
  templateListStore: TemplateListStore;
  dataExportListStore: DataExportListStore;
  dataExportViewStore: DataExportViewStore;
  templateViewStore: TemplateViewStore;
}

@inject(
  'dataExportViewStore',
  'templateListStore',
  'dataExportListStore',
  'templateViewStore',
)
@observer
class DataExportRowImpl extends Component<Props, any> {
  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  onRowClick = async () => {
    const { dataExport, router } = this.props;
    if (router) {
      router.push(`/organizations/${this.organizationId}/exports/${dataExport.scheduledDataExportId}`);
    }
  }

  templateRow = (params: {
    templates: Template[];
    templateId?: string;
    exportType: ExportType;
  }) => {
    const { exportType, templateId, templates } = params;
    const defaultTemplate = DefaultExportTemplates.find(
      (d) => d.exportType === exportType,
    );

    if (exportType === ExportType.CustomExport) {
      const template = templates.find((t) => t.templateId.toString() === templateId);
      return (
        <div>
          {template ? template.templateName : ''}
        </div>
      );
    } else {
      return (
        <div>
          {(defaultTemplate && defaultTemplate.templateName) || '-'}
        </div>
      );
    }
  };

  latestRun = (params: { run?: DataExportRun }) => {
    const { run } = params;
    if (!run) {
      return (
        <div className='last-run-entry'>
          <Images.latestRunPending />
          <p>
            <strong>Pending</strong>
          </p>
        </div>
      );
    }
    return (
      <div className='last-run-entry'>
        {run.status === DataExportRunStatus.Complete && <Images.success />}
        {run.status === DataExportRunStatus.Failed && <Images.error />}
        {run.status === DataExportRunStatus.InProgress && <Images.inProgress />}
        <p>
          <strong>{run.createdAt.format('YYYY-MM-DD')}</strong>
          <br />
          <strong>{run.prettyStatus()}</strong>
        </p>
      </div>
    );
  };

  render() {
    const { templates, dataExport } = this.props;

    return (
      <tr className='data-export-row' onClick={() => this.onRowClick()}>
        <td className='data-export-row-item'>
          <strong>{dataExport.name}</strong>
        </td>
        <td className='data-export-row-item'>
          {this.templateRow({
            exportType: dataExport.exportType,
            templates: templates,
            templateId: dataExport.templateId,
          })}
        </td>
        <td className='data-export-row-item'>{dataExport.prettyEnvironment()}</td>
        <td className='data-export-row-item'>{dataExport.prettyFrequency()}</td>
        <td className='data-export-row-item'>{dataExport.prettyDataRange()}</td>
        <td className='data-export-row-item'>
          {this.latestRun({ run: dataExport.latestRun })}
        </td>
        <td className='data-export-row-item'>
          {dataExport.status === ScheduledDataExportStatus.Paused ? (
            <span style={{ color: '#7a7a7a' }}>Paused</span>
          ) : (
            dataExport.prettyNextRun()
          )}
        </td>
      </tr>
    );
  }
}

export const DataExportRow = withRouter(DataExportRowImpl);
