
import { ReactivationOptionType } from '../../domain/product-module-definition';

const reactivationOptionTypeString = Object.values(ReactivationOptionType)
  .map((t) => `'${t}'`)
  .join(' | ');

export const reactivationOption = `
declare class ReactivationOption {
  type: ${reactivationOptionTypeString};
  description: string;
  settlementAmount?: number;
  minimumBalanceRequired?: boolean;

  constructor(init: ReactivationOption) {
    Object.assign(this, init);
  };
};
`;
