import React from 'react';
import { Card } from 'reactstrap';
import PageHeader from '../../../components/page-header';

export default class NoProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Card>
          <PageHeader title={'Nothing started yet!'} />
        </Card>
      </div>
    );
  }

  renderLoading() {
    if (!this.state.modules) {
      return <p>Loading...</p>;
    }
  }
}
