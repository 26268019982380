import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { CheckboxBlock as Block } from '../domain/checkbox-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { CheckboxBlockState } from '../domain/states/checkbox-block-state';
import { FormGroup, Input, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: CheckboxBlockState;
}

interface State {
  UIState: BlockUIState;
  checkboxBlockState?: BlockState;
}

@observer
export class CheckboxBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      checkboxBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { checkboxBlockState } = this.state;
    if (!checkboxBlockState) {
      return null;
    }

    return (
      <FormGroup className='blocks-ui' style={{ margin: '0 0 0 0px' }}>
        <Input
          style={{ top: 3, left: 0, right: 3 }}
          type='checkbox'
          name={block.key}
          label={block.title}
          disabled={disabled}
          key={block.key}
          defaultValue={blockState.value ? blockState.value.toString() : ''}
        />
        <Label><b>{block.title}</b></Label>
      </FormGroup>
    );
  }
}
