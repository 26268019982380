import { BlockType } from '../block-type';
import { InputTextBlockState } from './input-text-block-state';
import { InputNumberBlockState } from './input-number-block-state';
import { InputTimeBlockState } from './input-time-block-state';
import { InputCurrencyBlockState } from './input-currency-block-state';
import { CheckboxBlockState } from './checkbox-block-state';
import { PayoutRequestBlockState } from './payout-request-block-state';
import { FulfillmentRequestBlockState } from './fulfillment-request-block-state';
import { AnnuityRequestBlockState } from './annuity-request-block-state';
import { DropdownBlockState } from './dropdown-block-state';
import { HeadingBlockState } from './heading-block-state';
import { DividerBlockState } from './divider-block-state';
import { AlertBlockState } from './alert-block-state';
import { InputParagraphBlockState } from './input-paragraph-block-state';
import { RadioBlockState } from './radio-block-state';
import { MarkdownBlockState } from './markdown-block-state';
import { InputDateBlockState } from './input-date-block-state';
import { GroupBlockState } from './group-block-state';

class BlockStateFactory {
  createBlock(b: any) {
    switch (b.type) {
      case BlockType.Heading:
        return new HeadingBlockState({ ...b });
      case BlockType.Divider:
        return new DividerBlockState({ ...b });
      case BlockType.InputText:
        return new InputTextBlockState({ ...b });
      case BlockType.InputNumber:
        return new InputNumberBlockState({ ...b });
      case BlockType.InputTime:
        return new InputTimeBlockState({ ...b });
      case BlockType.InputCurrency:
        return new InputCurrencyBlockState({ ...b });
      case BlockType.InputParagraph:
        return new InputParagraphBlockState({ ...b });
      case BlockType.Radio:
        return new RadioBlockState({ ...b });
      case BlockType.InputDate:
        return new InputDateBlockState({ ...b });
      case BlockType.Checkbox:
        return new CheckboxBlockState({ ...b });
      case BlockType.Dropdown:
        return new DropdownBlockState({ ...b });
      case BlockType.PayoutRequest:
        return new PayoutRequestBlockState({ ...b });
      case BlockType.FulfillmentRequest:
        return new FulfillmentRequestBlockState({ ...b });
      case BlockType.AnnuityRequest:
        return new AnnuityRequestBlockState({ ...b });
      case BlockType.Alert:
        return new AlertBlockState({ ...b });
      case BlockType.Markdown:
        return new MarkdownBlockState({ ...b });
      case BlockType.Group:
        return new GroupBlockState({ ...b });
      default:
        throw new Error(`Cannot construct block state from unknown block type ${b.type}`);
    }
  }
}

export const blockStateFactory = new BlockStateFactory();
