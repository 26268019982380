import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { InputTimeBlock as Block } from '../domain/input-time-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputTimeBlockState } from '../domain/states/input-time-block-state';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { FormGroup, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputTimeBlockState;
}

interface State {
  UIState: BlockUIState;
  inputTimeBlockState?: BlockState;
}

@observer
export class InputTimeBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputTimeBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { disabled, block, required } = this.props;
    const { inputTimeBlockState } = this.state;
    if (!inputTimeBlockState) {
      return null;
    }

    const { value } = inputTimeBlockState as InputTimeBlockState;

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label>
          <b>{block.title}</b>
        </Label>
        <br />
        {block.description && (
          <span className='form-label'>{block.description}</span>
        )}
        <ReactDatePicker
          onChange={() => {}}
          disabled={disabled}
          required={required}
          id={block.key}
          className='form-control'
          key={block.key}
          isClearable={!this.props.required}
          selected={value ? moment(value, 'HH:mm').toDate() : null}
          timeFormat='HH:mm'
          dateFormat='HH:mm'
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
        />
      </FormGroup>
    );
  }
}
