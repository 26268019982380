export enum BlockType {
  InputText = 'input.text',
  InputCurrency = 'input.currency',
  InputParagraph = 'input.paragraph',
  Radio = 'radio',
  InputNumber = 'input.number',
  InputTime = 'input.time',
  InputDate = 'input.date',
  Checkbox = 'checkbox',
  AnnuityRequest = 'annuity_request',
  PayoutRequest = 'payout_request',
  FulfillmentRequest = 'fulfillment_request',
  Markdown = 'markdown',
  Dropdown = 'dropdown',
  Heading = 'heading',
  Divider = 'divider',
  Alert = 'alert',
  Group = 'group',
}
