import React from 'react';
import ReactDOM from 'react-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Container,
} from 'reactstrap';
import Button from '../../components/loading-button';
import { addAlert } from '../..//alerts';
import { createUser } from '../actions';

export default class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      bankAccess: false,
      offerzenMake: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open === false && nextProps.open === true) {
      this.setState({ show: true });
      // TODO reset forms
    }
  }

  close() {
    this.setState({ show: false });
    this.props.onClose();
  }

  createUser() {
    const firstName = ReactDOM.findDOMNode(this.refs.firstName).value;
    const email = ReactDOM.findDOMNode(this.refs.email).value;
    const bankAccess = this.state.bankAccess;
    const offerzenMake = this.state.offerzenMake;
    this.setState({ loading: true });
    this.props
      .dispatch(createUser(firstName, email, bankAccess, offerzenMake))
      .then(r => {
        location.reload(); // eslint-disable-line
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.dispatch(addAlert('Oops!', error.message, 'danger'));
      });
  }

  render() {
    return (
      <Modal isOpen={this.state.show} onClosed={this.close.bind(this)}>
        <ModalHeader>Invite New User</ModalHeader>
        <ModalBody>
          <form onSubmit={this.createUser.bind(this)}>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                type='text'
                placeholder='Enter First Name'
                ref='firstName'
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input type='email' placeholder='Enter Email' ref='email' />
            </FormGroup>
            <Container>
              {/* This container is a hack to fix checkbox negative margin */}
              <FormGroup>
                <Label check>
                  <Input
                    type='checkbox'
                    checked={this.state.bankAccess}
                    onChange={() =>
                      this.setState({ bankAccess: !this.state.bankAccess })
                    }
                  />{' '}
                  Bank Access
                </Label>
              </FormGroup>
              <FormGroup>
                <Label check>
                  <Input
                    type='checkbox'
                    checked={this.state.offerzenMake}
                    onChange={() =>
                      this.setState({ offerzenMake: !this.state.offerzenMake })
                    }
                  />{' '}
                  Offerzen Make
                </Label>
              </FormGroup>
            </Container>
          </form>
          <p>
            Inviting a user will simply add them to the waiting list and email
            them to complete their application.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.loading} onClick={this.close.bind(this)}>
            Cancel
          </Button>
          <Button
            loading={this.state.loading}
            color='primary'
            onClick={this.createUser.bind(this)}
          >
            Invite User
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
