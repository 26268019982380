import React from 'react';
import { Breadcrumb, Container, BreadcrumbItem } from 'reactstrap';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import PageHeader from '../../../components/page-header';
import ProductModuleVersion from './components/version-history';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../product-definition/stores/product-module-store';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';

interface Props extends RouteComponentProps {
  productModuleStore?: ProductModuleStore;
  params: {
    productModuleKey: string;
  };
  route: any;
  router?: History;
}

@inject('productModuleStore')
@observer
class ProductModuleVersionHistoryImpl extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
  }
  async componentWillMount() {
    const { params, productModuleStore } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(params.productModuleKey);
    }
  }

  redirect = (key: string) => {
    const { router } = this.props;
    if (router) {
      router.push(`/product-modules/${key}/product-definition`);
    }
  };

  render() {
    const { productModuleStore } = this.props;

    if (!productModuleStore || !productModuleStore.productModule) {
      return <div>loading...</div>;
    }
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          route={this.props.route}
          subRoutes={this.sidebarSchemaRender().routes()}
          title={productModuleStore.productModule.name}
          router={this.props.router}
        />
        <Container className='container-positioning'>
          <Breadcrumb>
            <BreadcrumbItem>
              <a className='breadcrumb-a-tag-link' href='/product-modules'>
                Product modules
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a
                className='breadcrumb-a-tag-link'
                href={`/product-modules/${this.props.params.productModuleKey}`}
              >
                {productModuleStore.productModule.name}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Version history</BreadcrumbItem>
          </Breadcrumb>
          <div style={{ marginLeft: '1.5rem', paddingTop: '1.5rem' }}>
            <PageHeader title={'Version history'} />
          </div>
          <ProductModuleVersion
            productModuleKey={this.props.params.productModuleKey}
            redirect={(key) => this.redirect(key)}
          />
        </Container>
      </div>
    );
  }

  Routes = [
    {
      name: 'Overview',
      url: `/product-modules/${this.props.params.productModuleKey}`,
    },
    {
      name: 'Deployment',
      url: `/product-modules/${this.props.params.productModuleKey}/deployment`,
    },
    {
      name: 'Version history',
      url: `/product-modules/${this.props.params.productModuleKey}/version-history`,
    },
    {
      name: 'Run logs',
      url: `/product-modules/${this.props.params.productModuleKey}/runs`,
    },
  ];

  sidebarSchemaRender = () => {
    return {
      routes: () => {
        return this.Routes;
      },
    };
  };
}

export const ProductModuleVersionHistory = withRouter(
  ProductModuleVersionHistoryImpl,
);
