import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import ProductModuleJson, { SchemaType } from './modals/product-module-json';

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface Props {
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionClaimAnnuityTypes extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    return (
      <ProductModuleJson
        schemaType={SchemaType.annuityType}
        breadcrumbDocName={'Claims annuity types'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  async componentDidMount() {
    const { productModuleKey } = this.props;
    const { productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  render() {
    const { productModuleStore } = this.injected;

    if (!productModuleStore.isLoaded) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Claim annuity types
              </p>
            </Col>
            <Col xs={9}>
              <p>
                The annuity types available to this product module are defined here.
              </p>
              <span>
                Certain insurance benefits require that a claim be paid out or fulfilled on a recurring schedule, for example a monthly payment or a weekly voucher, instead of a once-off lump sum. These are defined as annuity claims.
                On Root, annuity claims are executed through Annuity Requests – configurable schedules that generate either Payout Requests of Fulfillment Requests at a consistent frequency for a predefined period of time.
                To create an Annuity Request, an Annuity Type needs to be created. This will define the type of payment that will ultimately be created by the Annuity Request. An Annuity Type definition consists of the following
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <p>Annuity types definition form</p>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
