import React from 'react';
import MonacoEditor, { EditorDidMount, EditorWillMount } from 'react-monaco-editor';
import { editor } from 'monaco-editor';
import { reactivationOption } from '../typescript-defaults/reactivation-option';
import { reQuotePolicy } from '../typescript-defaults/requote-policy';
import { validationError } from '../typescript-defaults/validation-error';
import { invalidRequestError, notFoundRequestError } from '../typescript-defaults/errors';
import { productModulePolicyMembersUpdatedChanges, policyMembersUpdatedChanges } from '../typescript-defaults/product-module-policy-members-updated-changes';
import { productModuleMemberModuleChange } from '../typescript-defaults/product-module-member-module-change';
import { productModulePolicy } from '../typescript-defaults/product-module-policy';
import { productModuleQuotePackage } from '../typescript-defaults/quote-package';
import { productModuleApplication } from '../typescript-defaults/application';
import { memberQuotePackage } from '../typescript-defaults/product-module-member-quote-package';
import { momentImport } from '../typescript-defaults/moment';
import { joiValidation } from '../typescript-defaults/joi-validation-typing';
import { createJsonSchemaDependencyProposals } from './json-schema-intellisense';
import { createBlocksDependencyProposals } from './blocks-intellisense';
import { createFunctionDependencyProposals } from '../code-snippets/product-module-function-snippets';
import { productModuleAlterationPackage } from '../typescript-defaults/alteration-package';
import { applyAlteration } from '../typescript-defaults/apply-alteration-package';

interface IDisposable {
  dispose: () => void;
}
interface CodeWindow {
  editor: editor.IStandaloneCodeEditor;
  extraModels: editor.ITextModel[];
  typescriptItemProvider?: IDisposable;
  jsonItemProvider?: IDisposable;
}

interface Props {
  defaultValue: string;
  language: string;
  handleCodeChange?: (code: string) => void;
  width?: string;
  initialPos?: number;
  toDbKey?: string;
  otherFiles?: { fileName: string; code: string }[];
}

enum LoadingState {
  LoadingComplete = 'loadingComplete',
  InLoading = 'inLoading',
  PendingLoading = 'pendingLoading',
}

interface State {
  loadingState: LoadingState;
  code: string;
}

class CodeWindow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      code: this.props.defaultValue,
      loadingState: LoadingState.PendingLoading,
    };
  }

  componentWillUnmount() {
    this.typescriptItemProvider && this.typescriptItemProvider.dispose();
    this.jsonItemProvider && this.jsonItemProvider.dispose();

    if (this.props.otherFiles) {
      this.extraModels
        .forEach(model => {
          model.dispose();
        });
    }
  }

  editorDidMount: EditorDidMount = (editor, monaco) => {
    editor.focus();
    this.editor = editor;
    this.extraModels = [];

    if (this.props.otherFiles) {
      this.props.otherFiles
        .forEach(file => {
          const uri = monaco.Uri.parse('rootCode://' + file.fileName);
          const model = monaco.editor.createModel(file.code, 'typescript', uri);
          this.extraModels.push(model);
        });
    }

    if (this.state.loadingState === LoadingState.PendingLoading) {
      this.setState({ loadingState: LoadingState.InLoading });

      const toDbKey = this.props.toDbKey || '';
      this.jsonItemProvider = monaco.languages.registerCompletionItemProvider('json', {
        provideCompletionItems: function (model, position) {
          var word = model.getWordUntilPosition(position);
          var range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn,
          };

          let suggestions: any[] = [];
          if (['claim_schema_json', 'application_schema_json', 'quote_schema_json'].includes(toDbKey)) {
            suggestions = createJsonSchemaDependencyProposals(range, monaco);
          }
          else if (['claim_blocks_schema_json'].includes(toDbKey)) {
            suggestions = createBlocksDependencyProposals(range, monaco);
          }

          return {
            suggestions: suggestions,
          };
        },
      });

      if (toDbKey === 'product_module_code') {
        this.typescriptItemProvider = monaco.languages.registerCompletionItemProvider('typescript', {
          provideCompletionItems: function (model, position) {
            var word = model.getWordUntilPosition(position);
            var range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };
            if (word.startColumn === 1) {
              return {
                suggestions: [...createFunctionDependencyProposals(range, monaco)],
              };
            }
            return {
              suggestions: [],
            };
          },
        });

        monaco.languages.typescript.typescriptDefaults.addExtraLib([
          productModuleQuotePackage,
          productModuleApplication,
          applyAlteration,
          productModulePolicy,
          productModuleMemberModuleChange,
          policyMembersUpdatedChanges,
          productModulePolicyMembersUpdatedChanges,
          reactivationOption,
          reQuotePolicy,
          productModuleAlterationPackage,
          validationError,
          invalidRequestError,
          notFoundRequestError,
          memberQuotePackage,
          reactivationOption,
          'var generatePolicyNumber: () => string;',
          'var root;',
          momentImport,
          joiValidation,
        ].join('\n'), 'ts:filename/facts.d.ts');
      }


      monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
      });

      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        lib: ['es6'],
        allowNonTsExtensions: true,
        moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        module: monaco.languages.typescript.ModuleKind.CommonJS,
      });

      if (this.props.language === 'json') {
        try {
          this.setState({
            code: JSON.stringify(JSON.parse(this.props.defaultValue), null, 4),
          });
        } catch (error) {
          this.setState({
            code: '[]',
          });
        }
      }
    }

    this.setState({ loadingState: LoadingState.LoadingComplete });
  };

  editorWillMount: EditorWillMount = (monaco) => {
    monaco.editor.defineTheme('ace', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: '', background: '#041E25' },
        { token: 'emphasis', fontStyle: 'italic' },
        { token: 'strong', fontStyle: 'bold' },
        { token: 'entity.name.function', fontStyle: '', foreground: '#ddbb88' },
        { token: 'support.function', fontStyle: '', foreground: '#9966b8' },
      ],
      colors: {
        // Base
        'editor.background': '#041E25',
        focusBorder: '#596F99',
        'inputOption.activeBorder': '#1D4A87',
        'inputValidation.infoBorder': '#384078',
        'inputValidation.infoBackground': '#051336',
        'inputValidation.warningBackground': '#5B7E7A',
        'inputValidation.warningBorder': '#5B7E7A',
        'inputValidation.errorBackground': '#A22D44',
        'inputValidation.errorBorder': '#AB395B',

        'badge.background': '#0063a5',
        'progressBar.background': '#0063a5',

        'dropdown.background': '#181f2f',
        // "dropdown.foreground": "",
        // "dropdown.border": "",

        'button.background': '#2B3C5D',
        // "button.foreground": "",

        'list.activeSelectionBackground': '#08286b',
        // "list.activeSelectionForeground": "",
        'list.focusBackground': '#08286b',
        'list.hoverBackground': '#061940',
        'list.inactiveSelectionBackground': '#152037',
        'list.dropBackground': '#041D52',
        'list.highlightForeground': '#0063a5',

        'scrollbar.shadow': '#515E91AA',
        'scrollbarSlider.activeBackground': '#3B3F5188',
        'scrollbarSlider.background': '#1F2230AA',
        'scrollbarSlider.hoverBackground': '#3B3F5188',

        // Editor
        'editorWidget.background': '#262641',
        'editorCursor.foreground': '#ddbb88',
        'editorWhitespace.foreground': '#103050',
        'editor.lineHighlightBackground': '#082050',
        'editor.selectionBackground': '#770811',
        'editorIndentGuide.background': '#002952',
        'editorIndentGuide.activeBackground': '#204972',
        'editorHoverWidget.background': '#000c38',
        'editorHoverWidget.border': '#004c18',
        'editorLineNumber.foreground': '#406385',
        'editorLineNumber.activeForeground': '#80a2c2',
        'editorMarkerNavigation.background': '#060621',
        'editorMarkerNavigationError.background': '#AB395B',
        'editorMarkerNavigationWarning.background': '#5B7E7A',
        'editorLink.activeForeground': '#0063a5',
        'editor.findMatchHighlightBackground': '#eeeeee44',
        // Editor: Peek View
        'peekViewResult.background': '#060621',
        'peekViewEditor.background': '#10192c',
        'peekViewTitle.background': '#10192c',
        'peekView.border': '#2b2b4a',
        'peekViewEditor.matchHighlightBackground': '#eeeeee33',
        'peekViewResult.matchHighlightBackground': '#eeeeee44',

        // Editor: Diff
        'diffEditor.insertedTextBackground': '#31958A55',
        'diffEditor.removedTextBackground': '#892F4688',

        // Workbench: Title
        'titleBar.activeBackground': '#10192c',

        // Workbench: Editors
        'editorGroup.border': '#2b2b4a',
        'editorGroup.dropBackground': '#25375daa',
        'editorGroupHeader.tabsBackground': '#1c1c2a',

        // Workbench: Tabs
        'tab.border': '#2b2b4a',
        // "tab.activeBackground": "",
        'tab.inactiveBackground': '#10192c',
        'tab.modifiedBorder': '#0072bf',
        // "tab.activeForeground": "",
        // "tab.inactiveForeground": "",

        // Workbench: Activity Bar
        'activityBar.background': '#051336',

        // Workbench: Panel
        'panel.border': '#2b2b4a',

        // Workbench: Side Bar
        'sideBar.background': '#060621',
        // "sideBarTitle.foreground": "",
        'sideBarSectionHeader.background': '#10192c',

        // Workbench: Status Bar
        'statusBar.background': '#10192c',
        'statusBar.noFolderBackground': '#10192c',
        'statusBar.debuggingBackground': '#10192c',
        // "statusBar.foreground": "",
        'statusBarItem.remoteBackground': '#0063a5',
        'statusBarItem.prominentBackground': '#0063a5',
        'statusBarItem.prominentHoverBackground': '#0063a5dd',

        // Workbench: Debug
        'debugToolBar.background': '#051336',
        'debugExceptionWidget.background': '#051336',
        'debugExceptionWidget.border': '#AB395B',

        // Workbench: Quick Open
        'pickerGroup.border': '#596F99',
        'pickerGroup.foreground': '#596F99',

        // Workbench: Extensions
        'extensionButton.prominentBackground': '#5f8b3b',
        'extensionButton.prominentHoverBackground': '#5f8b3bbb',

        // Workbench: Terminal
        'terminal.ansiBlack': '#111111',
        'terminal.ansiRed': '#ff9da4',
        'terminal.ansiGreen': '#d1f1a9',
        'terminal.ansiYellow': '#ffeead',
        'terminal.ansiBlue': '#bbdaff',
        'terminal.ansiMagenta': '#ebbbff',
        'terminal.ansiCyan': '#99ffff',
        'terminal.ansiWhite': '#cccccc',
        'terminal.ansiBrightBlack': '#333333',
        'terminal.ansiBrightRed': '#ff7882',
        'terminal.ansiBrightGreen': '#b8f171',
        'terminal.ansiBrightYellow': '#ffe580',
        'terminal.ansiBrightBlue': '#80baff',
        'terminal.ansiBrightMagenta': '#d778ff',
        'terminal.ansiBrightCyan': '#78ffff',
        'terminal.ansiBrightWhite': '#ffffff',
      },
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    this.updateDimensions();
  }

  onChange = async (newValue: string) => {
    this.setState({ code: newValue });
    if (this.props.handleCodeChange) {
      this.props.handleCodeChange(newValue);
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.editor.layout();
  }

  render() {
    const code = this.state.code;
    const options: editor.IEditorConstructionOptions = {
      fontFamily: 'Menlo, Monaco, \'Courier New\', monospace',
      fontSize: 12,
      selectOnLineNumbers: true,
      renderIndentGuides: true,
      tabCompletion: true,
      dragAndDrop: true,
    };

    return (
      <MonacoEditor
        width={this.props.width || '50%'}
        height='100%'
        theme={'ace'}
        language={this.props.language || 'javascript'}
        value={code}
        options={options}
        onChange={(newValue, e) => this.onChange(newValue)}
        editorDidMount={this.editorDidMount}
        editorWillMount={this.editorWillMount}
      />
    );
  }
}

export default CodeWindow;
