import { ajax } from '../helpers';
import { ProductModuleCodeRun } from './domain/product-module-code-run';
import { ProductModuleCodeRunLogItem } from './domain/product-module-code-run-log-item';

const baseUrl = '/insurance/admin';
const productModulesUrl = `${baseUrl}/product-modules`;

export interface ProductModuleCodeRunFilters {
  policyId?: string;
  status?: string;
  functionName?: string;
}

export const getProductModuleCodeRuns = async (params: {
  productModuleId: string;
  page: number;
  pageSize: number;
  filters: ProductModuleCodeRunFilters;
}) => {
  const { productModuleId, page, pageSize } = params;
  const path = `${productModulesUrl}/${productModuleId}/runs`;
  const query = [];

  if (params.filters) {
    if (params.filters.policyId) {
      query.push(['policy_id', params.filters.policyId]);
    }

    if (params.filters.functionName) {
      query.push(['function_name', params.filters.functionName]);
    }

    if (params.filters.status) {
      query.push(['statuses', params.filters.status]);
    }
  }

  const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');

  const metadata = await ajax({
    type: 'HEAD',
    path: `${productModulesUrl}/${productModuleId}/runs?${queryString}`,
    raw: true,
  });

  const pagination = `page=${page}&page_size=${pageSize}`;
  const response = await ajax({
    type: 'GET',
    path: `${path}?${pagination}&${queryString}`,
  });

  const total = metadata.headers.get('X-Total-Count');

  return {
    productModuleCodeRuns: (response || []).map(
      ProductModuleCodeRun.fromNetwork,
    ),
    total,
  };
};

export const getProductModuleCodeRunLogItems = async (params: {
  productModuleId: string;
  productModuleCodeRunId: string;
  page: number;
  pageSize: number;
}) => {
  const { productModuleId, productModuleCodeRunId, page, pageSize } = params;
  const metadata = await ajax({
    type: 'HEAD',
    path: `${productModulesUrl}/${productModuleId}/runs/${productModuleCodeRunId}/log-items`,
    raw: true,
  });

  const pagination = `page=${page}&page_size=${pageSize}`;
  const response = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/runs/${productModuleCodeRunId}/log-items?${pagination}`,
  });

  const total = metadata.headers.get('X-Total-Count');

  return {
    productModuleCodeRunLogItems: (response || []).map(
      ProductModuleCodeRunLogItem.fromNetwork,
    ),
    total,
  };
};

export const getProductModuleCodeRun = async (params: {
  productModuleId: string;
  productModuleCodeRunId: string;
}) => {
  const { productModuleId, productModuleCodeRunId } = params;
  const response = await ajax({
    type: 'GET',
    path: `${productModulesUrl}/${productModuleId}/runs/${productModuleCodeRunId}`,
  });

  return {
    productModuleCodeRun: ProductModuleCodeRun.fromNetwork(response),
  };
};
