import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { DataExportViewStore } from '../../stores/data-export-view-store';
import { inject, observer } from 'mobx-react';


interface Props {
  open: boolean;
  close: () => void;
}

interface Injected extends Props {
  dataExportViewStore: DataExportViewStore;
}

interface State {}

@inject('dataExportViewStore')
@observer
export class PauseDataExportModal extends React.Component<Props, State> {

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    const { dataExportViewStore } = this.injected;

    return (
      <Modal isOpen={this.props.open} toggle={this.props.close} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={this.props.close} />
          <h4 className='exports-prompt-modal-header-text'>Pause export</h4>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <p>
            Are you sure you want to pause the{' '}
            <strong>
              {dataExportViewStore.dataExport && dataExportViewStore.dataExport.name}
            </strong>{' '}
            export? When paused, an export will not run until resumed again.
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button color='primary' onClick={this.pauseDataExport}>
            Pause export
          </Button>
          <Button color='link' onClick={this.props.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  pauseDataExport = async () => {
    const { dataExportViewStore } = this.injected;

    try {
      await dataExportViewStore.pause({ organizationId: this.organizationId });
      this.props.close();
    } catch (e) {
      this.props.close();
    }
  };
}
