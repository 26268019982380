import { observable, action } from 'mobx';
import {
  getProductModuleByKey,
  getProductModuleDefinition,
  createProductModuleDefinition,
  updateProductModuleReadme,
  updateProductModuleChangelog,
  getProductModuleDefinitionMarkdown,
  getVersionBumpCount,
  queueVersionBump,
  cancelVersionBumps,
} from '../../product-modules-api';
import { ProductModuleDefinition } from '../../../domain/product-module-definition';
import { ProductModule } from '../../../domain/product-module';
import { changelogTemplate, readmeTemplate } from '../markdown-templates';

export enum ProductModuleVersionTypes {
  Draft = 'draft',
  Review = 'review',
  Live = 'live',
}

export interface ProductModuleDefinitionErrors {
  path: string;
  message: string;
}
export class ProductModuleStore {
  @observable isLoaded = false;
  @observable loadedProductModuleKey = '';
  @observable.deep productModule: ProductModule;
  @observable.deep productModuleDefinitionLive?: ProductModuleDefinition;
  @observable.deep productModuleDefinitionDraft: ProductModuleDefinition;
  @observable.deep productModuleDefinitionReview: ProductModuleDefinition;
  @observable lockedComponent: boolean = true;
  @observable errors: ProductModuleDefinitionErrors[] = [];
  @observable readmeMarkdown: string;
  @observable changelogMarkdown: string;
  @observable versionBumpCounts: {
    sandbox: { policies: number; applications: number };
    production: { policies: number; applications: number };
  };

  init = async (productModuleKey: string, bypassLoadedCheck?: boolean) => {
    if (
      this.loadedProductModuleKey !== productModuleKey ||
      !this.isLoaded ||
      bypassLoadedCheck
    ) {
      this.loadedProductModuleKey = productModuleKey;
      this.isLoaded = true;
      this.errors = [];

      await this.getProductModule(productModuleKey);
      if (this.productModule) {
        const { productModuleId, liveId, draftId, reviewId } = this.productModule;
        if (liveId) {
          await this.getProductModuleDefinition(
            productModuleId,
            liveId,
            ProductModuleVersionTypes.Live,
          );
          await this.getVersionBumpCounts();
        }
        if (reviewId) {
          await this.getProductModuleDefinition(
            productModuleId,
            reviewId,
            ProductModuleVersionTypes.Review,
          );
        }
        await this.getProductModuleDefinition(
          productModuleId,
          draftId,
          ProductModuleVersionTypes.Draft,
        );
        // Load markdowns
        if (this.productModuleDefinitionDraft.readmeMarkdownId) {
          const markdownResponse = await getProductModuleDefinitionMarkdown(productModuleId, draftId, this.productModuleDefinitionDraft.readmeMarkdownId);
          this.readmeMarkdown = markdownResponse.markdown;
        } else {
          this.readmeMarkdown = readmeTemplate;
        }
        if (this.productModuleDefinitionDraft.changelogMarkdownId) {
          const markdownResponse = await getProductModuleDefinitionMarkdown(productModuleId, draftId, this.productModuleDefinitionDraft.changelogMarkdownId);
          this.changelogMarkdown = markdownResponse.markdown;
        } else {
          this.changelogMarkdown = changelogTemplate;
        }
      }
    }
  };

  @action updateErrors = (params: { errors: ProductModuleDefinitionErrors[] }) => {
    const { errors } = params;
    this.errors = errors;
  }

  getProductModule = async (productModuleKey: string) => {
    this.isLoaded = false;
    try {
      this.productModule = await getProductModuleByKey(productModuleKey);
    } catch (error) {
      console.error(`Could not get product module for product module key: ${productModuleKey}`);
    } finally {
      this.isLoaded = true;
    }
  };

  @action getProductModuleDefinition = async (
    productModuleId: string,
    productDefinitionId: string,
    type: ProductModuleVersionTypes,
  ) => {
    const result = await getProductModuleDefinition(
      productModuleId,
      productDefinitionId,
    );
    if (type === ProductModuleVersionTypes.Live) {
      this.productModuleDefinitionLive = result;
    } else if (type === ProductModuleVersionTypes.Review) {
      this.productModuleDefinitionReview = result;
    } else {
      this.productModuleDefinitionDraft = result;
    }
  };

  @action createProductModuleDefinition = async (params: {
    productModuleId: string;
    data: any;
  }) => {
    const { productModuleId, data } = params;
    try {
      const result = await createProductModuleDefinition(productModuleId, data);
      this.errors = [];
      this.productModuleDefinitionDraft = result;
    } catch (error) {
      const message = JSON.parse(error.message);
      this.errors = message && message.errors || [];
      return error;
    }
  };

  @action setProductModulePrimary = (
    productModuleDefinition: ProductModuleDefinition,
  ) => {
    this.productModuleDefinitionDraft = productModuleDefinition;
    // this.lockedComponent =
    //   this.productModule.draftId.toString() ===
    //   productModuleDefinition.productModuleDefinitionId.toString()
    //     ? false
    //     : true;
    this.lockedComponent = true;
  };

  @action updateProductModuleReadme = async (params: {
    productModuleId: string;
    markdown: string;
  }) => {
    this.isLoaded = false;
    const { productModuleId, markdown } = params;
    try {
      const productModule = await updateProductModuleReadme(productModuleId, markdown);
      await this.init(productModule.key, true);
    } catch (error) {
      const message = JSON.parse(error.message);
      this.errors = message && message.errors || [];
      return error;
    } finally {
      this.isLoaded = true;
    }
  };

  @action updateProductModuleChangelog = async (params: {
    productModuleId: string;
    markdown: string;
  }) => {
    const { productModuleId, markdown } = params;
    try {
      const productModule = await updateProductModuleChangelog(productModuleId, markdown);
      await this.init(productModule.key, true);
    } catch (error) {
      const message = JSON.parse(error.message);
      this.errors = message && message.errors || [];
      return error;
    }
  };

  @action getVersionBumpCounts = async () => {
    const productModuleId = this.productModule.productModuleId;
    try {
      const [ production, sandbox ] = await Promise.all([
        getVersionBumpCount({ productModuleId, sandbox: false }),
        getVersionBumpCount({ productModuleId, sandbox: true }),
      ]);
      this.versionBumpCounts = { sandbox, production };
    } catch(error) {
      console.log('Error fetching bump counts:', error);
    }
  };

  @action queueVersionBump = async (params: {
    production: boolean;
    sandbox: boolean;
  }) => {
    const productModuleId = this.productModule.productModuleId;
    try {
      if (params.sandbox) {
        await queueVersionBump({ productModuleId, sandbox: true });
      }
      if (params.production) {
        await queueVersionBump({ productModuleId, sandbox: false });
      }
      return true;
    } catch(error) {
      console.log('Error queuing version bump:', error);
      return false;
    }
  };

  @action cancelVersionBumps = async () => {
    try {
      const productModuleId = this.productModule.productModuleId;
      await cancelVersionBumps({productModuleId});
    } catch(error) {
      console.log('Error cancelling version bump:', error);
    }
  };

  setProductModuleStoreIsLoading = () => {
    this.isLoaded = false;
  }

  productModuleStore: {};
}

export const productModuleStore = new ProductModuleStore();
