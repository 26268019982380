import { BlockState } from './block-state';
import { Construct } from '../../../../../../../shared/construct';

export class GroupBlockState extends BlockState {
  constructor(init: Construct<GroupBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
    };
  }

  static fromJSON(init: any) {
    return new GroupBlockState({
      type: init.type,
    });
  }
}
