import React from 'react';
import {
  Col,
  Button,
  FormGroup,
  Card,
  CardHeader,
  Row,
  CardBody,
} from 'reactstrap';
import '../../../../../../../../../styles/styles.scss';
import { observer } from 'mobx-react';
import _ from 'lodash';
import '../../../styles/general.scss';
import {
  GracePeriodRulesConfig,
  FormOptions,
  selectGracePeriodRulesOptions,
} from '../config';
import { ObservablesTemp, Store } from '../../../store';
import SelectInputLifecycle from '../../form-components/select-input';
import InputLifecycle from '../../form-components/text-input';
import { spliceSelectOptions } from '../../../utils';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';
import { Icon24PXCross } from '../../../../../../../../../../components/icons/icon-24-px-cross';

interface Props {
  index: number;
  allowList: boolean;
  reduceCount: () => void;
  removeFromLifeCycleStore: (
    lifecycleObservable: ObservablesTemp,
    index: number,
  ) => void;
  store: Store;
}

interface State {}

@observer
class GracePeriodRule extends React.Component<Props, State> {
  getValue = (path: string) => {
    const { store, index } = this.props;
    const value = _.get(
      store.lifecycleStoreTemp.gracePeriodRulesTemp[index],
      path,
    );
    return value && value !== undefined ? value : '';
  };

  displaySkippedPayments = (path: string) => {
    const value = this.getValue(path);
    return !value || value !== 'period_after_missed_payment';
  };

  displayPeriod = (path: string) => {
    const value = this.getValue(path);
    if (value && value === 'skipped_payments_within_period') {
      return value;
    } else if (value && value === 'period_after_missed_payment') {
      return value;
    }
    return null;
  };

  render() {
    const {
      applyGracePeriodOf,
      lapseAfter,
      skippedPayments,
    } = GracePeriodRulesConfig;

    const {
      selectOption1,
      selectOption2,
      selectOption3,
      selectOption4,
    } = selectGracePeriodRulesOptions;

    return (
      <Card style={{ marginBottom: 20 }}>
        <CardHeader
          className='rule-header'
          style={{ backgroundColor: 'white', paddingBottom: 0 }}
        >
          <Row>
            <Col md={4} sm={12}>
              <div
                className='arrow-right-border-fill-lifecycle'
                style={{
                  backgroundColor: '#DEDEDE',
                  display: 'inline-block',
                }}
              >
                <Icon12ArrowRight />
              </div>
              <h6 className='completed-card-rule-heading'>
                Rule {FormOptions.allowListRules ? this.props.index + 1 : ''}
              </h6>
            </Col>
            <Col md={8} sm={12} style={{ paddingTop: 0, marginTop: 0 }}>
              {this.props.allowList && (
                <Button
                  className='remove-rule-button'
                  color='link'
                  onClick={() => {
                    this.props.removeFromLifeCycleStore(
                      ObservablesTemp.gracePeriodRulesTemp,
                      this.props.index,
                    );
                    this.props.reduceCount();
                  }}
                >
                  Remove
                  {
                    <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                      <Icon24PXCross
                        height={'10'}
                        width={'10'}
                        stroke={'#007bff'}
                      />
                    </div>
                  }
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup row style={{ marginBottom: 15 }}>
            <Col xs={12} sm={6}>
              <label className='label-policy-lifecycle'>Lapse after</label>
            </Col>
            <Col xs={3} sm={6} style={{ paddingLeft: 16, paddingBottom: 10 }}>
              <SelectInputLifecycle
                observable={ObservablesTemp.gracePeriodRulesTemp}
                store={this.props.store}
                options={lapseAfter.selectGracePeriodRules}
                optionsSpliced={spliceSelectOptions(
                  this.props.store.lifecycleStoreTemp.gracePeriodRulesTemp,
                  lapseAfter.outputPaths,
                  [
                    selectOption1.value,
                    selectOption2.value,
                    selectOption3.value,
                    selectOption4.value,
                  ],
                  lapseAfter.selectGracePeriodRules,
                )}
                index={this.props.index}
                path={lapseAfter.outputPaths}
                placeHolder={'Select...'}
              />
            </Col>
          </FormGroup>
          {this.displaySkippedPayments(lapseAfter.outputPaths) && (
            <FormGroup row className='row-form-spacing'>
              <Col xs={12} sm={6}>
                <label className='label-policy-lifecycle'>
                  Number of skipped payments
                </label>
              </Col>
              <Col
                xs={12}
                sm={2}
                style={{ paddingLeft: 16, paddingBottom: 10 }}
              >
                <InputLifecycle
                  observable={ObservablesTemp.gracePeriodRulesTemp}
                  store={this.props.store}
                  index={this.props.index}
                  path={skippedPayments.outputPaths}
                  placeHolder='3'
                />
              </Col>
              <Col xs={12} sm={3} />
            </FormGroup>
          )}
          {this.displayPeriod(lapseAfter.outputPaths) && (
            <FormGroup row style={{ marginBottom: 15 }}>
              <Col xs={12} sm={6}>
                <label className='label-policy-lifecycle'>
                  {this.displayPeriod(lapseAfter.outputPaths) ===
                  'period_after_missed_payment'
                    ? 'After a period of'
                    : 'Within a period of'}
                </label>
              </Col>
              <Col xs={3} sm={2}>
                <InputLifecycle
                  observable={ObservablesTemp.gracePeriodRulesTemp}
                  store={this.props.store}
                  index={this.props.index}
                  path={applyGracePeriodOf.outputPaths.value}
                  placeHolder='30'
                />
              </Col>
              <Col xs={3} sm={4} style={{ paddingLeft: 0 }}>
                <SelectInputLifecycle
                  observable={ObservablesTemp.gracePeriodRulesTemp}
                  store={this.props.store}
                  options={applyGracePeriodOf.selectGracePeriodRules.type}
                  index={this.props.index}
                  path={applyGracePeriodOf.outputPaths.type}
                  placeHolder={
                    applyGracePeriodOf.selectGracePeriodRules.type[0].label
                  }
                />
              </Col>
            </FormGroup>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default GracePeriodRule;
