import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Button from '../../../components/loading-button';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys, parseCSV } from '../../../helpers';
import {FileModal} from '../../../components/file-modal';
import PaymentMethodsTable from '../payment-methods-table';
import { PaginationComponent } from '../../../components/pagination';

const anyColumnMissing = columns => data =>
  columns.some(column => !data[0][column]);

/**
 * TODO:
 * - paging (10 page size)
 */
export default class ProcessingBanv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: null,
      loading: true,
      showFileModal: false,
      page: 0,
      pageSize: 20,
    };
    window.FileModal = this;
  }

  componentDidMount() {
    this.fetchPaymentMethods();
  }

  async fetchPaymentMethods() {
    this.setState({ loading: true });

    const queryString = 'type=debit_order&banv_status=processing&include=policyholder';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payment-methods?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const paymentMethods = (await ajax({
      type: 'GET',
      path:
        `/insurance/admin/payment-methods?${queryString}${pagination}`,
    }))
      .map(toCamelCaseKeys)
      .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

    this.setState({ paymentMethods, loading: false, total: total.headers.get('X-Total-Count')});
  }

  async updateStatus(file) {
    const data = await parseCSV(file);

    // if these columns are 'Y', account is valid
    const validityColumns = [
      'Account exists',
      'ID number or Company registration match',
      'Account accepts debits',
    ];
    // these columns must exist in order to process the results successfully
    const requiredColumns = validityColumns.concat(['Customer Code']);
    const requiredColumnMissing = anyColumnMissing(requiredColumns);

    if (requiredColumnMissing(data)) {
      return window.alert(
        'File invalid format. Please upload a BANV validation report.',
      );
    }

    const hasCustomerCode = p => !!p['Customer Code'];

    const accountValid = row =>
      validityColumns.every(column => row[column] === 'Y');
    const toAccountVerificationResult = row => ({
      payment_method_id: row['Customer Code'],
      banv_status: accountValid(row) ? 'verified' : 'failed_verification',
      banv_response: row,
    });

    const results = data
      .filter(hasCustomerCode)
      .map(toAccountVerificationResult);

    await ajax({
      type: 'PATCH',
      path: '/insurance/admin/payment-methods',
      data: results,
    });

    await this.fetchPaymentMethods();
  }

  closeModal() {
    this.setState({ showFileModal: false });
  }

  render() {
    const { paymentMethods, loading, page, pageSize, total, showFileModal } = this.state;

    return (
      <div>
        <Card>
          <CardHeader>
            Processing BANV payment methods (
            {paymentMethods && paymentMethods.length})
            <div className='pull-right'>
              <Button
                color='warning'
                onClick={() => this.setState({ showFileModal: true })}
                disabled={loading}
              >
                Upload validation results
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div>Loading...</div>
            ) : paymentMethods &&
              paymentMethods.length > 0 ? (
                <div>
                  <PaymentMethodsTable paymentMethods={paymentMethods} />
                  <PaginationComponent
                    page={page || 0}
                    limit={pageSize}
                    total={total || 0}
                    disabled={false}
                    goToPage={page => {
                      this.setState({
                        page,
                      }, async () => await this.fetchPaymentMethods());
                    }}
                  /></div>
              ) : (
                <div>No payment methods</div>
              )}
          </CardBody>
          <FileModal
            open={showFileModal}
            onClose={this.closeModal.bind(this)}
            handleSubmit={this.updateStatus.bind(this)}
            inputLabel='Select verification report'
            confirmMessage={file =>
              `Are you sure CSV file '${
                file.name
              }' contains validation results fro Peach Payments?`
            }
            header='Update BANV statuses'
            description='Upload validation results from Peach Payments to update BANV statuses'
            submitText='Update BANV statuses'
          />
        </Card>
      </div>
    );
  }
}
