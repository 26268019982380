import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Button from '../../../components/loading-button';
import ajax from '../../../helpers/ajax';
import { toCamelCaseKeys, parseCSV } from '../../../helpers';
import PaymentsTable from '../payments-table';
import { FileModal } from '../../../components/file-modal';
import { PaginationComponent } from '../../../components/pagination';

const anyColumnMissing = columns => data =>
  columns.some(column => !data[0][column]);

/**
 * TODO:
 * - paging (10 page size)
 */
export default class DebitOrderPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: null,
      loading: true,
      showFileModal: false,
      page: 0,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.fetchPayments();
  }

  fetchPayments = async () => {
    this.setState({ loading: true });

    const queryString = 'status=submitted,processing&payment_method_type=debit_order&include=payment_method,policy,billing_provider';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payments?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const payments = (await ajax({
      type: 'GET',
      path:
        `/insurance/admin/payments?${queryString}${pagination}`,
    }))
      .map(toCamelCaseKeys)
      .sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

    this.setState({ payments, loading: false, total: total.headers.get('X-Total-Count') });
  };

  async markAsFailed(file) {
    const data = await parseCSV(file);

    const requiredColumns = ['Customer Code', 'Result'];
    const requiredColumnMissing = anyColumnMissing(requiredColumns);

    if (requiredColumnMissing(data)) {
      return window.alert(
        'File invalid format. Please upload an unpaids report.',
      );
    }
    const payments = data
      .filter(p => p['Result'].startsWith('Rejected'))
      .map(e => ({
        payment_id: e['Customer Code'],
        status: 'failed',
        reason_failed: e['Result'] + (e['Reason'] ? ` - ${e['Reason']}` : ''),
      }));

    await ajax({
      type: 'PATCH',
      path: '/insurance/admin/payments',
      data: payments,
    });

    await this.fetchPayments();
  }

  closeModal() {
    this.setState({ showFileModal: false });
  }

  render() {
    const { page, pageSize, total} = this.state;

    return (
      <div className='bottom-padded'>
        <Card>
          <CardHeader>
            Debit order payments (
            {this.state.payments && this.state.payments.length})
            <div className='pull-right'>
              <Button
                color='warning'
                onClick={() => this.setState({ showFileModal: true })}
                disabled={this.state.loading}
              >
                Upload unpaids report
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {this.state.loading ? (
              <div>Loading...</div>
            ) : this.state.payments && this.state.payments.length > 0 ? (
              <div>
                <PaymentsTable payments={this.state.payments} />
                <PaginationComponent
                  page={page || 0}
                  limit={pageSize}
                  total={total || 0}
                  disabled={false}
                  goToPage={page => {
                    this.setState({
                      page,
                    }, async () => await this.fetchPayments());
                  }}
                />
              </div>
            ) : (
              <div>No payments</div>
            )}
          </CardBody>
          <FileModal
            open={this.state.showFileModal}
            onClose={this.closeModal.bind(this)}
            handleSubmit={this.markAsFailed.bind(this)}
            inputLabel='Select unpaids report'
            confirmMessage={file =>
              `Are you sure CSV file '${
                file.name
              }' is an unpaids report from Peach Payments?`
            }
            header='Mark failed payments'
            description='Upload an unpaids report from Peach Payments to mark payments as failed.'
            submitText='Mark as failed'
          />
        </Card>
      </div>
    );
  }
}
