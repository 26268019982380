import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { OrganizationFeatureListStore } from '../../stores/organization-feature-list-store';
import { Feature } from '../../domain/feature';
import Select from 'react-select';
import _ from 'lodash';

interface Props {
  open: boolean;
  close: () => any;
  organizationFeatureListStore?: OrganizationFeatureListStore;
  features: Feature[];
  organizationId: string;
}

interface SelectOptions {
  label: string;
  value: string;
}

interface State {
  featureKey: string;
  isLoading: boolean;
  featuresSelectOptions: SelectOptions[];
  sandbox: boolean;
  production: boolean;
}

@inject('organizationFeatureListStore')
@observer
export class AddOrganizationFeatureFlag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      featuresSelectOptions: [],
      featureKey: '',
      production: false,
      sandbox: true,
    };
  }

  componentDidMount() {
    const { features } = this.props;
    if (features && features.length > 0) {
      const featuresSelectOptions = features.map(feature => {
        return {
          label: _.startCase(_.toLower(feature.featureKey)),
          value: feature.featureKey,
        };
      });
      this.setState({ featuresSelectOptions });
    }
  }

  updatefeatureKey = async () => {
    const { featureKey, sandbox, production } = this.state;
    const { organizationFeatureListStore, organizationId } = this.props;

    if (organizationFeatureListStore) {
      this.setState({ isLoading: true });
      await organizationFeatureListStore.createOrganizationFeatureRequest({
        featureKey,
        organizationId,
        sandbox,
        production,
      });
      this.setState({ isLoading: false });
      this.props.close();
    }
  };

  selectedFeature = (selectedOption: SelectOptions) => {
    this.setState({
      featureKey: selectedOption.value,
    });
  };

  render() {
    const { organizationFeatureListStore } = this.props;
    if (!organizationFeatureListStore) {
      return null;
    }

    return (
      <Modal isOpen={this.props.open} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>
          New organization feature
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <label className='form-label'>Feature</label>
            <Select
              styles={this.customStyles()}
              onChange={(value: SelectOptions) => this.selectedFeature(value)}
              name='color'
              options={this.state.featuresSelectOptions}
            />
          </FormGroup>
          <FormGroup>
            <p>Sandbox</p>
            <Input
              identifier='sandbox'
              defaultChecked={true}
              type='checkbox'
              onChange={() => {
                this.setState(prevState => ({
                  sandbox: !prevState.sandbox,
                }));
              }}
            />
          </FormGroup>
          <FormGroup>
            <p>Production</p>
            <Input
              identifier='production'
              defaultChecked={false}
              type='checkbox'
              onChange={() => {
                this.setState(prevState => ({
                  production: !prevState.production,
                }));
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.close}>
            Cancel
          </Button>
          <Button
            onClick={() => this.updatefeatureKey()}
            color='primary'
            disabled={!this.state.featureKey}
          >
            {this.state.isLoading ? 'Creating...' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  customStyles = () => {
    const customStyles = {
      control: (base: any) => ({
        ...base,
        boxShadow: 'none',
        minHeight: '1px',
        maxHeight: '34px',
      }),
      container: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '36px',
      }),
      input: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '36px',
        marginTop: '-1px',
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '34px',
        maxWidth: '31px',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        marginTop: '0px',
        height: '33px',
        borderLeft: '1px solid hsl(0,0%,80%)',
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#c3c3c3',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        maxHeight: '33px',
        maxWidth: '33px',
        paddingTop: '0px',
      }),
      singleValue: (provided: any) => ({
        ...provided,
      }),
    };

    return customStyles;
  };
}
