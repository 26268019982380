/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        x='0px'
        y='0px'
        width='102.267px'
        height='105px'
        viewBox='0 0 102.267 105'
        enableBackground='new 0 0 102.267 105'
        xmlSpace='preserve'
      >
        <g>
          <g opacity='0.65'>
            <path
              fill='#0E131D'
              d='M19.061,17.612C8.534,17.612,0,26.146,0,36.67c0,10.526,8.534,19.062,19.061,19.062
            c10.524,0,19.059-8.536,19.059-19.062C38.12,26.146,29.585,17.612,19.061,17.612z M15.488,46.419
            c-0.568,0.568-1.488,0.568-2.056,0l-4.927-4.926c-2.982-2.985-2.982-7.838,0-10.825c2.985-2.98,7.838-2.98,10.823,0l4.926,4.929
            c0.568,0.566,0.568,1.486,0.002,2.053c-0.568,0.568-1.488,0.568-2.055,0l-4.926-4.927c-1.853-1.85-4.865-1.85-6.714,0
            c-1.852,1.853-1.852,4.863,0,6.718l4.926,4.924C16.055,44.933,16.055,45.854,15.488,46.419z M29.614,42.692
            c-2.985,2.985-7.837,2.985-10.822,0l-4.948-4.948c-0.569-0.566-0.569-1.486,0-2.053c0.566-0.568,1.488-0.568,2.055,0l4.948,4.95
            c1.85,1.851,4.863,1.851,6.713,0c1.852-1.854,1.852-4.864,0-6.717l-4.948-4.948c-0.564-0.566-0.564-1.488,0-2.055
            c0.568-0.564,1.488-0.564,2.055,0.002l4.948,4.948C32.598,34.855,32.598,39.71,29.614,42.692z'
            />
            <path
              fill='#0E131D'
              d='M38.118,81.732h-0.002c-0.004-0.646-0.036-1.285-0.103-1.914c-0.023-0.218-0.068-0.427-0.098-0.64
            c-0.061-0.438-0.12-0.878-0.21-1.306c-0.052-0.246-0.125-0.485-0.187-0.728c-0.098-0.386-0.192-0.771-0.312-1.148
            c-0.08-0.248-0.179-0.49-0.269-0.733c-0.131-0.357-0.261-0.715-0.412-1.062c-0.105-0.242-0.226-0.476-0.342-0.711
            c-0.164-0.335-0.327-0.67-0.51-0.995c-0.129-0.229-0.27-0.448-0.408-0.672c-0.195-0.317-0.392-0.63-0.604-0.933
            c-0.15-0.215-0.311-0.42-0.47-0.627c-0.223-0.295-0.451-0.583-0.691-0.863c-0.17-0.198-0.348-0.39-0.527-0.58
            c-0.252-0.269-0.506-0.532-0.773-0.786c-0.084-0.079-0.16-0.171-0.246-0.25L31.939,67.8c-3.393-3.119-7.906-5.04-12.878-5.04
            C8.534,62.76,0,71.294,0,81.822c0,10.523,8.534,19.058,19.061,19.058c2.935,0,5.705-0.682,8.188-1.868l0.014,0.015
            c0.187-0.09,0.365-0.19,0.548-0.284c0.163-0.085,0.327-0.167,0.486-0.254c0.456-0.254,0.902-0.521,1.333-0.811
            c0.12-0.079,0.231-0.166,0.35-0.249c0.334-0.233,0.66-0.478,0.978-0.731c0.143-0.113,0.285-0.231,0.425-0.35
            c0.328-0.277,0.646-0.568,0.955-0.866c0.081-0.079,0.167-0.154,0.248-0.236c0.374-0.375,0.729-0.77,1.07-1.175
            c0.102-0.122,0.199-0.25,0.299-0.374c0.253-0.316,0.497-0.644,0.731-0.977c0.091-0.132,0.185-0.26,0.272-0.394
            c0.298-0.449,0.582-0.91,0.842-1.385c0.035-0.065,0.065-0.133,0.099-0.197c0.225-0.423,0.432-0.854,0.625-1.296
            c0.066-0.15,0.128-0.302,0.191-0.454c0.167-0.412,0.32-0.831,0.459-1.255c0.037-0.114,0.079-0.224,0.114-0.338
            c0.162-0.529,0.3-1.067,0.416-1.616c0.028-0.134,0.049-0.271,0.075-0.407c0.082-0.438,0.149-0.878,0.201-1.324
            c0.019-0.162,0.039-0.323,0.054-0.487c0.053-0.575,0.088-1.155,0.088-1.744C38.12,81.792,38.118,81.764,38.118,81.732z
             M18.698,64.816c1.137,0,2.057,0.922,2.057,2.058c0,1.137-0.92,2.059-2.057,2.059c-1.136,0-2.056-0.922-2.056-2.059
            C16.643,65.738,17.562,64.816,18.698,64.816z M6.688,69.792c0.803-0.803,2.106-0.803,2.91,0c0.802,0.804,0.802,2.105,0,2.909
            c-0.804,0.804-2.107,0.804-2.91,0C5.884,71.897,5.884,70.596,6.688,69.792z M3.771,83.858c-1.137,0-2.058-0.92-2.058-2.055
            c0-1.137,0.921-2.057,2.058-2.057c1.135,0,2.056,0.92,2.056,2.057C5.826,82.938,4.905,83.858,3.771,83.858z M9.598,93.814
            c-0.804,0.802-2.106,0.802-2.91,0c-0.804-0.805-0.804-2.106,0-2.91c0.803-0.802,2.106-0.802,2.91,0
            C10.4,91.708,10.4,93.01,9.598,93.814z M7.592,81.751c0-6.021,4.881-10.899,10.901-10.899c2.881,0,5.495,1.127,7.444,2.952
            l-3.322,3.322c-0.08-0.065-0.17-0.108-0.252-0.167c-0.152-0.111-0.305-0.213-0.465-0.309c-0.177-0.105-0.357-0.199-0.542-0.284
            c-0.166-0.077-0.331-0.15-0.501-0.213c-0.192-0.069-0.386-0.122-0.582-0.173c-0.172-0.043-0.344-0.087-0.52-0.113
            c-0.203-0.035-0.407-0.047-0.612-0.06c-0.172-0.01-0.342-0.026-0.516-0.021c-0.213,0.005-0.422,0.033-0.635,0.062
            c-0.163,0.021-0.328,0.032-0.49,0.066c-0.221,0.049-0.437,0.122-0.653,0.195c-0.147,0.049-0.295,0.085-0.438,0.145
            c-0.237,0.1-0.46,0.231-0.685,0.361c-0.113,0.064-0.232,0.115-0.343,0.188c-0.328,0.22-0.641,0.471-0.93,0.76
            c-2.389,2.39-2.389,6.262,0,8.651l6.218,6.22c-0.704,0.142-1.431,0.217-2.177,0.217C12.473,92.652,7.592,87.771,7.592,81.751z
             M18.698,98.789c-1.136,0-2.056-0.922-2.056-2.058c0-1.135,0.92-2.057,2.056-2.057c1.137,0,2.057,0.922,2.057,2.057
            C20.755,97.867,19.835,98.789,18.698,98.789z M34.68,84.015H19.398c-0.954,0-1.729-0.847-1.729-1.886
            c0-1.042,0.775-1.887,1.729-1.887H34.68c0.954,0,1.726,0.845,1.726,1.887C36.406,83.168,35.634,84.015,34.68,84.015z'
            />
          </g>
          <rect
            x='27.479'
            y='13.328'
            fill='#202B40'
            width='14.932'
            height='91.672'
          />
          <g>
            <g>
              <g>
                <line
                  fill='#CAE0FF'
                  x1='88.814'
                  y1='4.64'
                  x2='85.006'
                  y2='8.446'
                />
                <path
                  fill='#5E97FC'
                  d='M85.006,9.486c-0.266,0-0.531-0.102-0.734-0.305c-0.406-0.406-0.406-1.063,0-1.47l3.807-3.808
                c0.406-0.406,1.064-0.406,1.471,0c0.405,0.406,0.405,1.064,0,1.471l-3.808,3.807C85.539,9.385,85.273,9.486,85.006,9.486z'
                />
              </g>
              <g>
                <line
                  fill='#CAE0FF'
                  x1='80.126'
                  y1='1.04'
                  x2='80.126'
                  y2='6.248'
                />
                <path
                  fill='#5E97FC'
                  d='M80.126,7.287c-0.574,0-1.04-0.465-1.04-1.039V1.04c0-0.575,0.466-1.04,1.04-1.04s1.04,0.465,1.04,1.04
                v5.208C81.167,6.822,80.701,7.287,80.126,7.287z'
                />
              </g>
              <g>
                <line
                  fill='#CAE0FF'
                  x1='92.416'
                  y1='13.328'
                  x2='87.187'
                  y2='13.328'
                />
                <path
                  fill='#5E97FC'
                  d='M92.416,14.367h-5.229c-0.574,0-1.039-0.465-1.039-1.039c0-0.575,0.465-1.04,1.039-1.04h5.229
                c0.575,0,1.04,0.465,1.04,1.04C93.456,13.902,92.991,14.367,92.416,14.367z'
                />
              </g>
            </g>
            <path
              fill='#FFFFFF'
              d='M77.785,73.296v18.681h-41.24V15.697h41.24v24.208h4.334V16.092c0-2.534-2.072-4.607-4.605-4.607H36.814
            c-2.532,0-4.605,2.073-4.605,4.607v84.303c0,2.534,2.073,4.605,4.605,4.605h40.699c2.533,0,4.605-2.071,4.605-4.605V73.296H77.785
            z M58.084,101.942c-1.909,0-3.453-1.548-3.453-3.454s1.544-3.454,3.453-3.454c1.908,0,3.454,1.548,3.454,3.454
            S59.992,101.942,58.084,101.942z'
            />
            <path
              fill='#5E97FC'
              d='M102.267,64.522c0,2.937-2.382,5.315-5.317,5.315H45.898c-2.935,0-5.317-2.379-5.317-5.315V48.575
            c0-2.937,2.382-5.315,5.317-5.315H96.95c2.936,0,5.317,2.379,5.317,5.315V64.522z'
            />
            <g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M47.899,59.529l0.124-2.38l-2.009,1.283l-0.455-0.808l2.111-1.076l-2.111-1.076l0.455-0.828l2.009,1.303
                l-0.124-2.381h0.951l-0.124,2.381l1.985-1.303l0.479,0.828l-2.113,1.076l2.113,1.076l-0.479,0.808l-1.985-1.283l0.124,2.38
                H47.899z'
                />
                <path
                  fill='#FFFFFF'
                  d='M57.007,59.529l0.124-2.38l-2.009,1.283l-0.455-0.808l2.111-1.076l-2.111-1.076l0.455-0.828l2.009,1.303
                l-0.124-2.381h0.954l-0.126,2.381l1.988-1.303l0.477,0.828l-2.113,1.076l2.113,1.076l-0.477,0.808l-1.988-1.283l0.126,2.38
                H57.007z'
                />
                <path
                  fill='#FFFFFF'
                  d='M66.118,59.529l0.123-2.38l-2.009,1.283l-0.455-0.808l2.111-1.076l-2.111-1.076l0.455-0.828l2.009,1.303
                l-0.123-2.381h0.953l-0.127,2.381l1.987-1.303l0.478,0.828l-2.111,1.076l2.111,1.076l-0.478,0.808l-1.987-1.283l0.127,2.38
                H66.118z'
                />
              </g>
              <g>
                <path
                  fill='#FFFFFF'
                  d='M75.78,59.529l0.124-2.38l-2.008,1.283l-0.457-0.808l2.113-1.076l-2.113-1.076l0.457-0.828l2.008,1.303
                l-0.124-2.381h0.952l-0.124,2.381l1.986-1.303l0.478,0.828l-2.112,1.076l2.112,1.076l-0.478,0.808l-1.986-1.283l0.124,2.38
                H75.78z'
                />
                <path
                  fill='#FFFFFF'
                  d='M84.889,59.529l0.124-2.38l-2.009,1.283l-0.455-0.808l2.112-1.076l-2.112-1.076l0.455-0.828l2.009,1.303
                l-0.124-2.381h0.953l-0.127,2.381l1.99-1.303l0.475,0.828l-2.109,1.076l2.109,1.076l-0.475,0.808l-1.99-1.283l0.127,2.38H84.889
                z'
                />
                <path
                  fill='#FFFFFF'
                  d='M93.998,59.529l0.124-2.38l-2.007,1.283l-0.456-0.808l2.112-1.076l-2.112-1.076l0.456-0.828l2.007,1.303
                l-0.124-2.381h0.952l-0.124,2.381l1.989-1.303l0.475,0.828l-2.11,1.076l2.11,1.076l-0.475,0.808l-1.989-1.283l0.124,2.38H93.998
                z'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
