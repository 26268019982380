import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { Report } from '../domain/reports';
import { OrganizationViewStore } from '../../../stores/organization-view-store';
import { ReportListStore } from '../stores/report-list-store';
import { sentenceCase } from 'sentence-case';
import PageHeader from '../../../../components/page-header';
import { History } from 'history';
import { DomainEntityTable } from '../../../../components/domain/tables/domain-entity-table';
import { EmailRecipientType } from '../../outbound-notifications/domain/email-recipient';
import { ReportViewStore } from '../stores/report-view-store';
import { CreateEditReportModal } from './report-view-create-edit';
import { ReportType } from '../domain/report-type';
import { PaginationComponent } from '../../../../components/pagination';
import { OrganizationFeatureListStore } from '../../../../features/stores/organization-feature-list-store';


interface Props {
  router: History;
  organizationId: string;
}

interface Injected extends Props {
  organizationViewStore: OrganizationViewStore;
  reportListStore: ReportListStore;
  reportViewStore: ReportViewStore;
  organizationFeatureListStore: OrganizationFeatureListStore;
}

interface State {
  popoverOpen: boolean;
  page: number;
}

@inject(
  'organizationViewStore',
  'reportListStore',
  'reportViewStore',
  'organizationFeatureListStore',
)
@observer
export class ReportsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      popoverOpen: false,
      page: 0,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  navigateToReport = (params: { reportId: string }) => {
    const { organizationId } = this.injected;
    const { reportId } = params;

    this.props.router.push(`/organizations/${organizationId}/reports/${reportId}`);
  };


  renderNotEnabled() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Reports feature not enabled</h3>
          <br />
          <p>Please enable the reports feature flag to use this feature</p>
          <br />
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>No reports, yet!</h3>
          <br />
          <p>Please configure reports to be able to send to email recipients</p>
          <br />
          <Button
            color='primary'
            style={{ margin: 0 }}
            className='exports-new-button'
            onClick={() => this.createNewReport()}>
              New report
          </Button>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  renderActionButton = () => {
    return (
      <div
        style={{ marginLeft: '1.5rem' }}
        className='pull-right'>
        <Button
          className='pull-right'
          onClick={() => this.createNewReport()}
          color='primary'
        >
          Add report
        </Button>
      </div>
    );
  }

  createNewReport = () => {
    const { reportViewStore } = this.injected;
    reportViewStore.clear();
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  render() {
    const { reportListStore, organizationFeatureListStore } = this.injected;
    const { popoverOpen, page } = this.state;
    const { isLoading: ReportListStoreIsLoading, reports, total } = reportListStore;
    const { isLoading, features } = organizationFeatureListStore;

    const organizationId = window.location.pathname.split('/')[2];
    const reportsEnabled = !ReportListStoreIsLoading && !isLoading && features.find((feature) => feature.featureKey === 'reports');
    const reportsDisabled = !ReportListStoreIsLoading && !isLoading && !features.find((feature) => feature.featureKey === 'reports');

    const displayReportList = !ReportListStoreIsLoading && !isLoading && reports.length > 0;
    const displayEmptyState = !ReportListStoreIsLoading && !isLoading && reports.length === 0;

    return (
      <div>
        {popoverOpen && <CreateEditReportModal
          isNewReport={true}
          isNewRecipient={true}
          name={''}
          type={EmailRecipientType.To}
          email={''}
          reportType={ReportType.DailySalesEmail}
          close={() => this.setState({ popoverOpen: !popoverOpen})}
        />}
        <br />
        {reportsDisabled && this.renderNotEnabled()}
        {displayEmptyState && reportsEnabled && this.renderEmpty()}
        {displayReportList && reportsEnabled && <>
          <PageHeader
            title='Reports'
            rightComponent={this.renderActionButton()}/>
          <ReportsTable
            lean={true}
            hover={true}
            rows={reportListStore.reports}
            columns={[
              ConfigTypeColumn,
              CreatedAtColumn,
              EnvironmentColumn,
            ]}
            isLoaded={!reportListStore.isLoading}
            getRowProps={({ reportId }) => ({
              onClick: () => {
                this.navigateToReport({ reportId });
              },
            })}/>
            <PaginationComponent
              page={page || 0}
              limit={10}
              total={total || 0}
              disabled={false}
              goToPage={page => {
                this.setState(
                  {
                    page,
                  },
                  async () => reportListStore.goToPage({ organizationId, pageNumber: page }),
                );
              }}
            />
        </>}
      </div>
    );
  }
}

class ReportsTable extends DomainEntityTable<Report> {}

const ConfigTypeAtCellElement: React.SFC<Report> = ({ config }) => {
  return (
    <div className='table-cell-single'>
      {sentenceCase(config.type)}
    </div>
  );
};

const ConfigTypeColumn = {
  heading: 'Type',
  element: ConfigTypeAtCellElement,
};

const CreatedAtCellElement: React.SFC<Report> = ({ createdAt }) => (
  <div className='table-cell-single'>
    {createdAt.format('YYYY-MM-DD')}
  </div>
);

const CreatedAtColumn = {
  heading: 'Created At',
  element: CreatedAtCellElement,
};

const EnvironmentCellElement: React.SFC<Report> = ({ environment }) => (
  <div className='table-cell-single'>
    {environment}
  </div>
);

const EnvironmentColumn = {
  heading: 'Environment',
  element: EnvironmentCellElement,
};

