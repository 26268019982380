import React from 'react';
import { ProductModuleDefinitionScheduledFunction } from '../../../../../domain/product-module-definition-scheduled-function';
import { Input, FormGroup, Label, Col, Card, CardHeader, Row, Button, CardBody, FormText } from 'reactstrap';
import Select from 'react-select';
import { ScheduledFunctionFrequencyType, ScheduledFunctionFrequency, Daily, Weekly, Yearly, Monthly } from '../../../../../domain/scheduled-function-frequency';
import { startCase } from 'lodash';
import { Icon12ArrowRight } from '../../../../../../components/icons/icon-12-arrow-right';
import { Icon24PXCross } from '../../../../../../components/icons/icon-24-px-cross';
import { customStyles } from '../../product-module-definition-settings/orchestration';
import { Weekday } from '../../../../../../shared/domain/weekday';
import { Month } from '../../../../../../shared/domain/month';
import moment from 'moment';
import { intToOrdinalNumberString } from '../../../../../../shared/helpers/numbers';
import { PolicyStatus } from '../../../../../../policies/domain/policy-status';
import { ValueType } from 'react-select/lib/types';

const frequencyTypes = Object.values(ScheduledFunctionFrequencyType).map(t => ({
  label: startCase(t),
  value: t,
}));

const createTimeRange = () => {
  const start = moment().startOf('day');
  const end = moment().endOf('day');
  const dates = [];

  while (start.isSameOrBefore(end)) {
    dates.push(start.format('HH:mm'));
    start.add(30, 'minutes');
  }

  return dates;
};

const timeOfDayOptions = createTimeRange().map(t => ({
  label: t,
  value: t,
}));

const dayOfWeekOptions = Object.values(Weekday).map(t => ({
  label: startCase(t),
  value: t,
}));

const dayOfMonthOptions = Array.from({ length: 31 }).map((_, i) => ({
  label: intToOrdinalNumberString(i + 1),
  value: i + 1,
}));

const monthOfYearOptions = Object.values(Month).map(t => ({
  label: startCase(t),
  value: t,
}));

const policyStatusOptions = Object.values(PolicyStatus).map(t => ({
  label: startCase(t),
  value: t,
}));

interface OptionType<T> {
  label: string;
  value: T;
};

export interface EditScheduledFunction {
  functionName: string;
  policyStatuses: PolicyStatus[];
  frequency: ScheduledFunctionFrequency;
}

interface Props {
  scheduledFunctions: ProductModuleDefinitionScheduledFunction[];
  onSave: (newScheduledFunctions: EditScheduledFunction[]) => void;
  onCancel: () => void;
}

interface State {
  scheduledFunctions: EditScheduledFunction[];
}

export class ScheduledFunctionsEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const scheduledFunctions = props.scheduledFunctions.length > 0 ?
      props.scheduledFunctions.map(s => ({
        functionName: s.functionName,
        policyStatuses: s.policyStatuses,
        frequency: s.frequency,
      })) :
      [{
        functionName: '',
        policyStatuses: [PolicyStatus.Active],
        frequency: new Daily({
          timeOfDay: '09:00',
        }),
      }];

    this.state = {
      scheduledFunctions,
    };
  }

  removeItem = (idx: number) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];

    scheduledFunctions.splice(idx, 1);

    this.setState({ scheduledFunctions });
  }

  onSaveClick = () => {
    this.props.onSave(this.state.scheduledFunctions);
  }

  onCancelClick = () => {
    this.props.onCancel();
  }

  onAddClick = () => {
    const newItem: EditScheduledFunction = {
      functionName: '',
      policyStatuses: [PolicyStatus.Active],
      frequency: new Daily({
        timeOfDay: '09:00',
      }),
    };

    this.setState({ scheduledFunctions: [...this.state.scheduledFunctions, newItem] });
  }

  onFunctionNameChanged = (idx: number, functionName: string) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = { ...scheduledFunctions[idx], functionName };

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onPolicyStatusesChanged = (idx: number, policyStatuses: PolicyStatus[]) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = { ...scheduledFunctions[idx], policyStatuses };

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onTypeChanged = (idx: number, type: ScheduledFunctionFrequencyType) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = scheduledFunctions[idx];

    switch (type) {
      case ScheduledFunctionFrequencyType.Daily:
        item.frequency = new Daily({
          timeOfDay: '09:00',
        });
        break;
      case ScheduledFunctionFrequencyType.Weekly:
        item.frequency = new Weekly({
          timeOfDay: '09:00',
          dayOfWeek: Weekday.Monday,
        });
        break;
      case ScheduledFunctionFrequencyType.Monthly:
        item.frequency = new Monthly({
          timeOfDay: '09:00',
          dayOfMonth: 1,
        });
        break;
      case ScheduledFunctionFrequencyType.Yearly:
        item.frequency = new Yearly({
          timeOfDay: '09:00',
          dayOfMonth: 1,
          monthOfYear: Month.January,
        });
        break;
    }

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onTimeOfDayChanged = (idx: number, timeOfDay: string) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = scheduledFunctions[idx];

    if (item.frequency instanceof Daily) {
      item.frequency = new Daily({ ...item.frequency, timeOfDay });
    } else if (item.frequency instanceof Weekly) {
      item.frequency = new Weekly({ ...item.frequency, timeOfDay });
    } else if (item.frequency instanceof Monthly) {
      item.frequency = new Monthly({ ...item.frequency, timeOfDay });
    } else if (item.frequency instanceof Yearly) {
      item.frequency = new Yearly({ ...item.frequency, timeOfDay });
    }

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onDayOfWeekChanged = (idx: number, dayOfWeek: Weekday) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = scheduledFunctions[idx];

    if (item.frequency instanceof Weekly) {
      item.frequency = new Weekly({ ...item.frequency, dayOfWeek });
    }

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onDayOfMonthChanged = (idx: number, dayOfMonth: number) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = scheduledFunctions[idx];

    if (item.frequency instanceof Monthly) {
      item.frequency = new Monthly({ ...item.frequency, dayOfMonth });
    } else if (item.frequency instanceof Yearly) {
      item.frequency = new Yearly({ ...item.frequency, dayOfMonth });
    }

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  onMonthOfYearChanged = (idx: number, monthOfYear: Month) => {
    const scheduledFunctions = [...this.state.scheduledFunctions];
    const item = scheduledFunctions[idx];

    if (item.frequency instanceof Yearly) {
      item.frequency = new Yearly({ ...item.frequency, monthOfYear });
    }

    scheduledFunctions[idx] = item;

    this.setState({ scheduledFunctions });
  }

  get valid() {
    return !!this.state.scheduledFunctions.find(s => {
      if (
        s.functionName === '' ||
        !/^[$A-Z_][0-9A-Z_$]*$/i.test(s.functionName) ||
        !timeOfDayOptions.map(o => o.value).includes(s.frequency.timeOfDay)
      ) {
        return true;
      }

      if (
        s.policyStatuses.length === 0 ||
        s.policyStatuses.find(v => !(policyStatusOptions.map(o => o.value).includes(v)))
      ) {
        return true;
      }

      if (s.frequency instanceof Weekly) {
        if (!dayOfWeekOptions.map(o => o.value).includes(s.frequency.dayOfWeek)) {
          return true;
        }
      } else if (s.frequency instanceof Monthly) {
        if (!dayOfMonthOptions.map(o => o.value).includes(s.frequency.dayOfMonth)) {
          return true;
        }
      } else if (s.frequency instanceof Yearly) {
        if (
          !dayOfMonthOptions.map(o => o.value).includes(s.frequency.dayOfMonth) ||
          !monthOfYearOptions.map(o => o.value).includes(s.frequency.monthOfYear)
        ) {
          return true;
        }
      }

      return false;
    });
  }

  renderItem = (item: EditScheduledFunction, idx: number) => {
    return <Card style={{ marginBottom: 20 }} key={`edit_scheduled_code_${idx}`}>
      <CardHeader
        className='rule-header'
        style={{ backgroundColor: 'white', paddingBottom: 0 }}
      >
        <Row>
          <Col md={4} sm={12}>
            <div
              className='arrow-right-border-fill-lifecycle'
              style={{
                backgroundColor: '#DEDEDE',
                display: 'inline-block',
              }}
            >
              <Icon12ArrowRight />
            </div>
            <h6 className='completed-card-rule-heading'>
              Function {idx + 1}
            </h6>
          </Col>
          <Col md={8} sm={12} style={{ paddingTop: 0, marginTop: 0 }}>
            <Button
              className='remove-rule-button'
              color='link'
              onClick={() => this.removeItem(idx)}
            >
              Remove
              <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                <Icon24PXCross
                  height={'10'}
                  width={'10'}
                  stroke={'#007bff'}
                />
              </div>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Col xs={12} sm={6}>
            <label className='label-policy-lifecycle'>Function name</label>
            <FormText color="muted">
              Function names must be alphanumeric, start with a letter or underscore, and contain no blank spaces.
            </FormText>
          </Col>
          <Col xs={3} sm={6}>
            <Input
              type='text'
              className='scheduled-code-function-name-input'
              value={item.functionName}
              onChange={({ target }) => this.onFunctionNameChanged(idx, target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Frequency type</Label>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              styles={customStyles}
              value={frequencyTypes.find(t => t.value === item.frequency.type)}
              options={frequencyTypes}
              onChange={(selectedOption: ValueType<OptionType<ScheduledFunctionFrequencyType>>) => {
                if (selectedOption) {
                  this.onTypeChanged(idx, (selectedOption as OptionType<ScheduledFunctionFrequencyType>).value);
                }
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Policy statuses</Label>
            <FormText color="muted">
              Functions will only run for policies in the provided statuses. At least one status is required.
            </FormText>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              isMulti
              isClearable={false}
              styles={customStyles}
              value={policyStatusOptions.filter(o => item.policyStatuses.includes(o.value))}
              options={policyStatusOptions}
              onChange={(selectedOption: ValueType<OptionType<PolicyStatus>>) => {
                if (selectedOption) {
                  this.onPolicyStatusesChanged(idx, (selectedOption as OptionType<PolicyStatus>[]).map(v => v.value));
                }
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Time of day</Label>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              styles={customStyles}
              value={timeOfDayOptions.find(t => t.value === item.frequency.timeOfDay)}
              options={timeOfDayOptions}
              onChange={(selectedOption: ValueType<OptionType<string>>) => {
                if (selectedOption) {
                  this.onTimeOfDayChanged(idx, (selectedOption as OptionType<string>).value);
                }
              }}
            />
          </Col>
        </FormGroup>
        {item.frequency instanceof Weekly && <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Day of week</Label>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              styles={customStyles}
              value={dayOfWeekOptions.find(t => t.value === (item.frequency as Weekly).dayOfWeek)}
              options={dayOfWeekOptions}
              onChange={(selectedOption: ValueType<OptionType<Weekday>>) => {
                if (selectedOption) {
                  this.onDayOfWeekChanged(idx, (selectedOption as OptionType<Weekday>).value);
                }
              }}
            />
          </Col>
        </FormGroup>}
        {(item.frequency instanceof Monthly || item.frequency instanceof Yearly) && <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Day of month</Label>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              styles={customStyles}
              value={dayOfMonthOptions.find(t => t.value === (item.frequency as Monthly | Yearly).dayOfMonth)}
              options={dayOfMonthOptions}
              onChange={(selectedOption: ValueType<OptionType<number>>) => {
                if (selectedOption) {
                  this.onDayOfMonthChanged(idx, (selectedOption as OptionType<number>).value);
                }
              }}
            />
          </Col>
        </FormGroup>}
        {item.frequency instanceof Yearly && <FormGroup row>
          <Col xs={12} sm={6}>
            <Label className='label-policy-lifecycle'>Month of year</Label>
          </Col>
          <Col xs={3} sm={6}>
            <Select
              styles={customStyles}
              value={monthOfYearOptions.find(t => t.value === (item.frequency as Yearly).monthOfYear)}
              options={monthOfYearOptions}
              onChange={(selectedOption: ValueType<OptionType<Month>>) => {
                if (selectedOption) {
                  this.onMonthOfYearChanged(idx, (selectedOption as OptionType<Month>).value);
                }
              }}
            />
          </Col>
        </FormGroup>}
      </CardBody>
    </Card>;
  }

  render() {
    return <>
      <Row>
        <Col>
          {this.state.scheduledFunctions.length === 0 && <div>No functions have been scheduled for this definition.</div>}
          {this.state.scheduledFunctions.map(this.renderItem)}
        </Col>
      </Row>
      <FormGroup className='product-modules-form-group-align'>
        <Button
          outline
          color='primary'
          onClick={this.onSaveClick}
          disabled={this.valid}
        >
          Save
        </Button>
        <Button
          color='link'
          onClick={this.onAddClick}
        >
          Add +
        </Button>
        <Button
          color='link'
          onClick={this.onCancelClick}
        >
          Cancel
        </Button>
      </FormGroup>
    </>;
  }
}
