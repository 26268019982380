const checkdigit = require('checkdigit');

export const isValidImei = (value: string) => {
  const is15Digits = /^[0-9]{15}$/.test(value);
  const checksumPasses = checkdigit.mod10.isValid(value);

  const isValid = is15Digits && checksumPasses;

  return isValid;
};
