import React from 'react';
import { observer } from 'mobx-react';
import '../styles/datepicker/datepicker.scss';
import SelectInput from './select-input';
import DatePicker from './date-picker';
import BlankSpace from './blank-space';
import { TextInput } from './text-input';
import { Button, Alert } from 'reactstrap';
import SectionHeader from './section-header';
// import ToggleSwitch from './toggle';
import uuid from 'uuid';
import { FormStore } from '../../formStore';
import Radio from './radio';
import '../styles/list-component/styles.scss';
import {
  FormStoreModelList,
  Validator,
  Options,
  ComponentProps,
  BaseStoreModel,
  DisplayConditions,
} from '../interfaces';
import HorizontalLine from './horizontal-line';
import _ from 'lodash';
import Paragraph from './paragraph';
import CheckboxInput from './check-box-input';
import { CurrencyInput } from './currency-input';
import { NumberInput } from './number-input';
import { InjectObject } from './inject-object';
interface Props {
  formStore: FormStore;
  components: FormStoreModelList[] | BaseStoreModel[];
  parentKey: string[];
  parentKeyLookup: string;
  maxNumber: number;
  className?: string;
  componentProps?: ComponentProps;
  parentIndex?: number | null | undefined;
  outputPathAppendTo?: string | null | undefined;
}
interface State {
  error: string;
}

interface SelectInputInterface {
  value: string;
  label: string;
}

@observer
export class FormList extends React.Component<Props, State> {
  state = {
    error: '',
  };

  onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const targetValue = e.target.value;
    this.props.formStore.updateInputState(key, targetValue);
  };

  onSelectInputChange = (value: SelectInputInterface, key: string) => {
    this.props.formStore.updateInputState(key, null);
    this.props.formStore.updateInputState(key, value.value);
  };

  onDateInputChange = (value: string | null, key: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onToggleInputChange = (key: string, checked: boolean) => {
    this.props.formStore.updateInputState(key, checked);
  };

  onRadioInputChange = (key: string, value: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onCheckboxInputChange = (value: boolean, key: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onCurrencyInputChange = (targetValue: number, key: string) => {
    this.props.formStore.updateInputState(key, targetValue);
  };

  onNumberInputChange = (targetValue: number, key: string) => {
    this.props.formStore.updateInputState(key, targetValue);
  };

  displayConditionsCheck = (val: DisplayConditions, parentKey: string) => {
    const { inputState } = this.props.formStore;
    if (val.condition === '===') {
      if (inputState[`${val.path}_${parentKey}`] !== val.value) {
        if (val.and) {
          const conditionResult = val.and.every((val: any) =>
            this.displayConditionsCheck(val, parentKey),
          );
          if (!conditionResult) {
            return false;
          }
        }
        if (val.or) {
          const conditionResult = val.or.every((val: any) =>
            this.displayConditionsCheck(val, parentKey),
          );
          if (conditionResult) {
            return true;
          }
        }
        return false;
      }
      if (val.and) {
        const conditionResult = val.and.every((val: any) =>
          this.displayConditionsCheck(val, parentKey),
        );
        if (!conditionResult) {
          return false;
        }
      }
    }
    return true;
  };

  optionsCompleted = (options: Options[], index: number, checkType: string) => {
    if (checkType === 'value') {
      return options && options[index] && options[index].value
        ? options[index].value
        : false;
    }
    if (checkType === 'label') {
      return options && options[index] && options[index].label
        ? options[index].label
        : 'not specified';
    }
    return;
  };

  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;
    if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
      formStore.resetModelChildrenCount(parentKeyLookup, 1);
    }
  }

  componentDidMount() {
    const { formStore, parentKeyLookup } = this.props;
    formStore.updateModelChildrenHoldState({ [parentKeyLookup]: true });
  }

  componentTypeFilter = (
    type: string,
    key: string,
    label: string,
    props: ComponentProps,
    options: Options[],
    validators: Validator[],
    components: FormStoreModelList[] | BaseStoreModel[], //need these for nesting lists within lists
    defaultValue: string | null,
    parentIndex: number | null,
    parentKeyChild: string,
    maxNumberChild: number | null,
    parentKeyLookup: string | null,
    outputPathAppendTo: string | null,
  ) => {
    switch (type) {
      case 'select':
        return (
          <SelectInput
            key={key}
            keyVal={key}
            validators={validators}
            formStore={this.props.formStore}
            type={type}
            options={options}
            label={label}
            componentProps={props}
            parentKeyLookup={parentKeyLookup}
            onChange={(val: SelectInputInterface) =>
              this.onSelectInputChange(val, key)
            }
          />
        );
      case 'country':
        return (
          <SelectInput
            key={key}
            keyVal={key}
            validators={validators}
            formStore={this.props.formStore}
            type={type}
            options={options}
            label={label}
            componentProps={props}
            parentKeyLookup={parentKeyLookup}
            onChange={(val: SelectInputInterface) =>
              this.onSelectInputChange(val, key)
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            key={key}
            keyVal={key}
            validators={validators}
            formStore={this.props.formStore}
            label={label}
            options={options}
            componentProps={props}
            parentKeyLookup={parentKeyLookup}
            onChange={(val: string) => this.onDateInputChange(val, key)}
          />
        );
      case 'blank-space':
        return (
          <BlankSpace keyVal={key} key={`blank-space-${uuid().toString()}`} />
        );
      case 'horizontal-line':
        return (
          <HorizontalLine
            keyVal={key}
            key={`blank-space-${uuid().toString()}`}
          />
        );
      case 'paragraph':
        return (
          <Paragraph
            keyVal={key}
            componentProps={props}
            key={`paragraph-${uuid().toString()}`}
            label={label}
          />
        );
      case 'section-header':
        return (
          <SectionHeader
            title={label}
            keyVal={key}
            componentProps={props}
            parentIndex={parentIndex}
            key={`section-header-${uuid().toString()}`}
          />
        );
      case 'checkbox':
        return (
          <CheckboxInput
            key={key}
            validators={validators}
            formStore={this.props.formStore}
            keyVal={key}
            type={type}
            label={label}
            componentProps={props}
            parentKeyLookup={parentKeyLookup}
            defaultValue={defaultValue}
            onCheckboxInputChange={(e: boolean) =>
              this.onCheckboxInputChange(e, key)
            }
          />
        );
      // case 'toggle':
      //   return (
      //     <ToggleSwitch
      //       keyVal={key}
      //       key={key}
      //       label={label}
      //       id={'environment-toggle'}
      //       parentKeyLookup={parentKeyLookup}
      //       leftText={this.optionsCompleted(options, 0, 'label')}
      //       rightText={this.optionsCompleted(options, 1, 'label')}
      //       value1={this.optionsCompleted(options, 0, 'value')}
      //       value2={this.optionsCompleted(options, 1, 'value')}
      //       onToggleInputChange={(checked: boolean) =>
      //         this.onToggleInputChange(key, checked)
      //       }
      //     />
      //   );
      case 'radio':
        return (
          <Radio
            key={key}
            keyVal={key}
            defaultValue={defaultValue}
            options={options}
            label={label}
            formStore={this.props.formStore}
            parentKeyLookup={parentKeyLookup}
            onRadioInputChange={(value: string) =>
              this.onRadioInputChange(key, value)
            }
          />
        );
      case 'currency':
        return (
          <CurrencyInput
            key={key}
            validators={validators}
            formStore={this.props.formStore}
            keyVal={key}
            type={type}
            label={label}
            parentKeyLookup={parentKeyLookup}
            componentProps={props}
            onChange={(targetValue: number) =>
              this.onCurrencyInputChange(targetValue, key)
            }
          />
        );
      case 'number':
        return (
          <NumberInput
            key={key}
            validators={validators}
            formStore={this.props.formStore}
            keyVal={key}
            type={type}
            label={label}
            parentKeyLookup={parentKeyLookup}
            componentProps={props}
            onChange={(targetValue: number) =>
              this.onNumberInputChange(targetValue, key)
            }
          />
        );
      case 'object':
        return (
          <InjectObject
            key={key}
            validators={validators}
            formStore={this.props.formStore}
            keyVal={key}
            type={type}
            label={label}
            parentKeyLookup={parentKeyLookup}
            componentProps={props}
          />
        );
      case 'text':
        return (
          <TextInput
            key={key}
            validators={validators}
            formStore={this.props.formStore}
            keyVal={key}
            type={type}
            label={label}
            componentProps={props}
            parentKeyLookup={parentKeyLookup}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.onTextInputChange(e, key);
            }}
          />
        );
      case 'list':
        if (components && this.props.formStore) {
          if (key && !Array.isArray(key)) {
            const keyParse = key.split(',');
            keyParse.forEach((val, index) => {
              keyParse[index] = `[${[parentIndex]}]_${val}`;
            });

            return (
              <div className='form-group col-sm-10 offset-sm-1'>
                <FormList
                  components={components}
                  formStore={this.props.formStore}
                  parentKey={keyParse}
                  parentKeyLookup={parentKeyChild}
                  maxNumber={maxNumberChild || 1}
                  componentProps={props}
                  parentIndex={parentIndex}
                  outputPathAppendTo={outputPathAppendTo}
                  key={`form-list-${uuid().toString()}`}
                />
              </div>
            );
          }
        } else {
          console.log('not completed form list inputs');
        }
      default:
        console.log(`no type specified for ${key}`);
        return;
    }
  };

  renderForm = () => {
    const {
      formStore,
      components,
      parentKey,
      parentKeyLookup,
      maxNumber,
      componentProps,
    } = this.props;

    formStore.updateModelChildrenHoldState({ [parentKeyLookup]: true });

    if (components) {
      try {
        const modelHigherOrder: FormStoreModelList[][] = Array(
          formStore.modelChildrenCount[parentKeyLookup] || 1,
        ).fill(components);
        let formUI: any;
        const componentsRender = modelHigherOrder.map((model, indexParent) => {
          const renderedKeys: any = {};
          formUI = model.map(m => {
            let key =
              m.type !== 'list'
                ? `${m.key}_${parentKey[indexParent]}`
                : `${m.key}`;
            const type = m.type;
            const props = m.props || {};
            const label = m.label;
            const options = m.options || [];
            const validators = m.validators || [];
            const displayConditions = m.displayConditions || null;
            const components = m.components || null;
            const defaultValue = m.defaultValue || null;
            const parentIndex = indexParent;
            const parentKeyChild = m.parentKey || '';
            const maxNumberChild = m.maxNumber || null;
            const outputPathAppendTo = m.outputPathAppendTo || null;

            if (!Array.isArray(key)) {
              if (renderedKeys[key]) {
                key = uuid().toString();
              } else {
                Object.assign(renderedKeys, { [key]: key });
              }
              this.props.formStore.updateComponentValid(key, validators);
            }
            if (formStore.inputState !== {} && displayConditions) {
              let display;
              try {
                display = displayConditions.every((val: any) =>
                  this.displayConditionsCheck(val, parentKey[indexParent]),
                );
                if (type === 'list') {
                  formStore.updateModelChildrenHoldState({
                    [parentKeyChild]: display,
                  });
                }
              } catch (err) {
                display = true;
                console.log('list component visibility check error', err);
              }
              if (props && props.hiddenComponent) {
                return;
              }
              if (display) {
                return this.componentTypeFilter(
                  type,
                  key,
                  label,
                  props,
                  options,
                  validators,
                  components,
                  defaultValue,
                  parentIndex,
                  parentKeyChild,
                  maxNumberChild,
                  parentKeyLookup,
                  outputPathAppendTo,
                );
              }
            } else {
              if (props && props.hiddenComponent) {
                return;
              }
              return this.componentTypeFilter(
                type,
                key,
                label,
                props,
                options,
                validators,
                components,
                defaultValue,
                parentIndex,
                parentKeyChild,
                maxNumberChild,
                parentKeyLookup,
                outputPathAppendTo,
              );
            }
          });

          const modelChildCount = this.props.formStore.modelChildrenCount[
            parentKeyLookup
          ];

          if (modelChildCount > 1 && !formStore.hideAddAndSubtract) {
            formUI.unshift(
              <div
                key={uuid()}
                className='list-component-remove-button-container'
              >
                <Button
                  id={uuid()}
                  className='list-component-remove-button'
                  color='link'
                  onClick={() => {
                    formStore.updateModelChildrenHoldState({
                      [parentKeyLookup]: true,
                    });
                    const inputState = _.pickBy(
                      formStore.inputState,
                      (value, key) => !_.includes(key, parentKey[indexParent]),
                    );
                    formStore.setInputState(inputState);

                    parentKey.push(parentKey[indexParent]);

                    parentKey.splice(indexParent, 1);

                    formStore.updateModelChildrenCount(parentKeyLookup, -1);
                  }}
                >
                  {componentProps && componentProps.removeButtonLabel
                    ? componentProps.removeButtonLabel
                    : 'remove'}
                </Button>
              </div>,
            );
          }
          return formUI;
        });

        if (formUI.every((element: any) => element === undefined)) {
          return null;
        }

        const modelChildCount = this.props.formStore.modelChildrenCount[
          parentKeyLookup
        ];

        componentsRender.push(
          <div
            className='col-sm-12'
            key={`add-remove-child-${uuid().toString()}`}
            style={{ marginLeft: -6 }}
          >
            {modelChildCount &&
              modelChildCount < maxNumber &&
              !formStore.hideAddAndSubtract && (
              <Button
                className='btn btn-primary btn-sm'
                onClick={() => {
                  formStore.updateModelChildrenHoldState({
                    [parentKeyLookup]: true,
                  });
                  this.props.formStore.updateModelChildrenCount(
                    parentKeyLookup,
                    1,
                  );
                }}
              >
                {componentProps && componentProps.addButtonLabel
                  ? componentProps.addButtonLabel
                  : 'Add'}
              </Button>
            )}
          </div>,
        );

        return componentsRender;
      } catch (err) {
        console.log('error in list components', err);
      }
    }
  };

  render() {
    if (!this.props.formStore && !this.props.components) {
      return <div />;
    }
    return (
      <div
        className='list-copmponent-parent'
        key={`form-list-${uuid().toString()}`}
      >
        <div className='dynamic-form row' style={{ backgroundColor: 'white' }}>
          {this.renderForm()}
          {this.state.error && (
            <Alert color='success'>{this.state.error}</Alert>
          )}
        </div>
      </div>
    );
  }
}

export default FormList;
