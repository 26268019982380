import Papa from 'papaparse';

/**
 * Helpers for CSV exports
 * Borrowed some from https://github.com/abdennour/react-csv/blob/master/src/core.js
 */

/**
 * Simple safari detection based on user agent test
 */
const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Generate CSV from data (array of objects)
 */
const toCSV = data => {
  const headerArray = Object.keys(data[0] || {});
  const headerString = headerArray.join(',');

  const bodyString = data
    .map(row => headerArray.map(h => row[h] || ''))
    .map(row => row.join(','));

  const result = [headerString, ...bodyString].join('\n');

  return result;
};

/**
 * Generate link to download CSV created from data
 */
export const buildCSVURI = data => {
  const csv = toCSV(data);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([csv], { type }); // eslint-disable-line
  const dataURI = `data:${type};charset=utf-8,${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);
};

/**
 * Parse a CSV file
 */
export const parseCSV = file => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete: function(results) {
        if (results.errors && results.errors.length > 0) {
          reject(results.errors);
        } else {
          resolve(results.data);
        }
      },
      error: function(err) {
        reject(err);
      },
    });
  });
};

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
