import * as React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Images from './svg-images';

interface PaginationProps {
  page: number;
  limit: number;
  total: number;
  disabled: boolean;
  goToPage: (page: number) => void;
}

export class PaginationComponent extends React.PureComponent<PaginationProps> {
  get prevDisabled() {
    return this.maxPage === 0 || this.props.page === 0;
  }

  get maxPage() {
    return Math.ceil(this.props.total / this.props.limit);
  }

  get nextDisabled() {
    return this.maxPage === 0 || this.maxPage === this.displayPage;
  }

  get displayPage() {
    return this.props.page + 1;
  }

  render() {
    return (
      <Pagination className={this.props.disabled ? 'pagination pagination-disabled' : 'pagination'}>
        <PaginationItem
          disabled={this.prevDisabled}
          onClick={() => {
            if (!this.prevDisabled) {this.props.goToPage(0);}
          }}>
          <PaginationLink>First</PaginationLink>
        </PaginationItem>
        <PaginationItem
          disabled={this.prevDisabled}
          onClick={() => {
            if (!this.prevDisabled) {this.props.goToPage(this.props.page - 1);}
          }}>
          <PaginationLink>
            <Images.paginationArrowLeft className='pagination-image-button prev' />
          </PaginationLink>
        </PaginationItem>

        {this.displayPage - 2 > 0 && this.maxPage === this.displayPage && (
          <PaginationItem
            onClick={() => this.props.goToPage(this.props.page - 2)}
          >
            <PaginationLink>{this.displayPage - 2}</PaginationLink>
          </PaginationItem>
        )}

        {this.displayPage - 1 > 0 && (
          <PaginationItem
            onClick={() => this.props.goToPage(this.props.page - 1)}
          >
            <PaginationLink>{this.displayPage - 1}</PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem active>
          <PaginationLink>{this.displayPage}</PaginationLink>
        </PaginationItem>

        {this.displayPage + 1 <= this.maxPage && (
          <PaginationItem
            onClick={() => this.props.goToPage(this.props.page + 1)}
          >
            <PaginationLink>{this.displayPage + 1}</PaginationLink>
          </PaginationItem>
        )}
        {this.displayPage + 2 <= this.maxPage && this.props.page === 0 && (
          <PaginationItem
            onClick={() => this.props.goToPage(this.props.page + 2)}
          >
            <PaginationLink>{this.displayPage + 2}</PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem
          disabled={this.nextDisabled}
          onClick={() => {
            if (!this.nextDisabled) { this.props.goToPage(this.props.page + 1); }
          }}>
          <PaginationLink>
            <Images.paginationArrowRight className='pagination-image-button next' />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem
          disabled={this.nextDisabled}
          onClick={() => {
            if (!this.nextDisabled) { this.props.goToPage(this.maxPage - 1); }
          }}>
          <PaginationLink>Last</PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}
