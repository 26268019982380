export const policyCharges = {
  charge: {
    type: 'variable',
    name: 'Root fee',
    description: 'Root fee',
    amount: 0.05,
    calculated: 72.05,
  },
  payment: {
    payment_id: '5b87186a-4215-46cd-9a43-b0150dc9818b',
    policy_id: '3765ce3e-1335-4e42-a475-029c5d8386cc',
    payment_method_id: '0157b955-8ffd-4a14-8625-8b2787a1684c',
    status: 'successful',
    amount: 1441,
    description: null,
    external_ref: null,
    created_at: '2018-01-29T11:58:19.207Z',
    created_by: { type: 'system' },
    finalized_at: '2018-01-29T11:58:19.207Z',
    finalized_by: { type: 'system' },
    payment_date: '2018-01-29T11:58:19.207Z',
    failure_reason: null,
    collection_type: 'debit_order_same_day',
    payment_method: {
      payment_method_id: '0157b955-8ffd-4a14-8625-8b2787a1684c',
      environment: 'sandbox',
      type: 'card',
      created_at: '2018-01-29T11:58:19.194Z',
      created_by: { type: 'system' },
      policyholder_id: 'aa1d0811-6a9e-4fed-a196-22ec28886883',
      bin: '424242',
      holder: 'E gro',
      card_brand: 'VISA',
      expiry_year: '2020',
      expiry_month: '10',
      last_4_digits: '4242',
      registration_id: null,
      raw_checkout_status: null,
    },
    policy: {
      policy_id: '998867b5-b683-4484-bb4c-a50cc62d489d',
      environment: 'sandbox',
      scheme_type: 'individual',
      created_at: '2018-01-29T11:57:19.000Z',
      updated_at: '2018-01-29T11:57:19.000Z',
      status: 'pending_initial_payment',
      policy_number: '1HS4G4KTON',
      package_name: 'Risk Term Policy',
      sum_assured: 50000000,
      monthly_premium: 14900,
      billing_amount: 14900,
      billing_frequency: 'monthly',
      billing_day: 1,
      base_premium: 14900,
      start_date: '2018-01-29T11:57:19.000Z',
      end_date: '2023-01-29T11:57:19.000Z',
      module: { type: 'mmi_hero' },
      app_data: { sdsd: 'sdf', teeest: 234 },
      application_id: '3b727ed8-2834-4b7d-8a37-436c5adab70f',
      policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
      terms_file_id: '932e3980-04eb-11e8-8dba-1bb05ed94c40',
      reason_cancelled: null,
      flushed: true,
      notes: [],
      attachments: [],
      schedule_versions: [
        {
          fileId: '793fc104-074f-11e8-a34a-f31d98d2a7b0',
          version: 1,
          createdAt: '2018-02-01T12:57:38.000Z',
        }],
      created_by: { type: 'system' },
      beneficiaries: [],
      currency: 'ZAR',
    },
    currency: 'ZAR',
    reviewed_at: null,
    reviewed_by: null,
    policyholder: {
      policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
      type: 'individual',
      first_name: 'Erlich',
      last_name: 'Bachman',
      company_name: 'Random Corp',
      registration_number: '123CantCatchMe321',
      id: {
        type: 'id',
        number: '9401014800081',
        country: 'ZA',
      },
      email: 'elrich@root.co.za',
      cellphone: '022 232 2323',
      phone_other: '022 232 3232',
      date_of_birth: '19940101',
      gender: 'non-binary',
      created_at: '"2019-03-01T06:54:33.731Z',
      app_data: null,
      created_by: {
        type: 'user',
        id: 'c7bc530c-7291-4574-96ed-1018c9cd45c8',
        ownerId: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
      },
      address: {
        line_1: 'Milky Way',
        line_2: 'Universe',
        code: 0,
      },
    },
  },
};
