/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='62px' height='41px' viewBox='0 0 62 41' version='1.1'>
        <defs>
          <path
            d='M25.3204084,29.6322677 C24.7278753,30.0016023 22.6118316,29.6322677 17.4758478,29.6322677 C12.339681,29.6322677 8.98390831,30.0016023 8.39137134,29.6322677 C8.23039374,29.5318683 9.61816642,28.1832117 9.63110687,27.998165 C9.66875396,27.4527058 9.93463082,26.2572103 11.4928346,25.7136486 C12.8059466,25.2554917 14.3041556,25.051846 15.2766771,24.5297302 C15.8115692,24.2425708 15.8754869,23.523843 15.4058986,23.1446291 C14.6012002,22.4944101 14.1576837,21.4627048 13.9196493,20.413918 C13.5035789,20.1134774 12.5786962,18.3104549 13.4076985,17.9724428 C13.2233886,17.3461352 12.7894755,15.231859 14.7310101,13.7019441 C16.9121353,11.9833886 20.2226601,14.2299549 21.0828419,12.6180541 C21.127158,12.5349191 21.2467551,12.5269529 21.2983287,12.6062878 C21.5608645,13.0101575 22.0706628,14.0481312 21.4010703,15.2762734 C21.5353785,15.8372849 21.7312637,16.9416909 21.5083233,17.9534677 C22.5945707,18.2392951 21.4806797,20.1231543 21.0310722,20.4177183 C20.7928455,21.465173 20.3493317,22.4951675 19.545614,23.1446291 C19.0762088,23.523843 19.1401395,24.2425708 19.6746393,24.5297302 C20.647357,25.051846 22.1455647,25.2554917 23.4588715,25.7136486 C25.0168779,26.2572103 25.2829574,27.4527058 25.3204084,27.998165 C25.333341,28.1832117 25.4815886,29.5318683 25.3204084,29.6322677 Z'
            id='path-1'
          />
        </defs>
        <g
          id='Pages'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-65'
            transform='translate(-732.000000, -454.000000)'
          >
            <g id='ID' transform='translate(732.000000, 454.000000)'>
              <g id='Group'>
                <rect
                  id='Rectangle-46'
                  stroke='#E1EBF0'
                  strokeWidth='2'
                  x='1'
                  y='1'
                  width='60'
                  height='39'
                  rx='2'
                />
                <rect
                  id='Rectangle-5'
                  stroke='#E1EBF0'
                  strokeWidth='2'
                  x='8.54054054'
                  y='11.877551'
                  width='17.6646432'
                  height='17.6387516'
                />
                <rect
                  id='Rectangle-7-Copy-3'
                  fill='#E1EBF0'
                  x='34.3513514'
                  y='11.7142857'
                  width='21.7837838'
                  height='2.51020408'
                />
                <rect
                  id='Rectangle-7-Copy-4'
                  fill='#E1EBF0'
                  x='34.3513514'
                  y='19.6632653'
                  width='21.7837838'
                  height='2.51020408'
                />
                <rect
                  id='Rectangle-7-Copy-5'
                  fill='#E1EBF0'
                  x='34.3513514'
                  y='27.6122449'
                  width='21.7837838'
                  height='2.51020408'
                />
                <mask id='mask-2' fill='white'>
                  <use xlinkHref='#path-1' />
                </mask>
                <use id='Clip-7' fill='#E1EBF0' xlinkHref='#path-1' />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
