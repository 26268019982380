/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='140px'
        height='70px'
        viewBox='0 0 155.75 82.212'
        enableBackground='new 0 0 155.75 82.212'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#CAE0FF'
            d='M113.967,0.661H42.142c-1.853,0-3.36,1.568-3.36,3.495v18.253c0.023,0.156,0.047,0.312,0.047,0.472
            c0,1.754-1.42,3.175-3.175,3.175h-2.073v0.021h-8.024c-1.461,0-2.646,1.185-2.646,2.645c0,1.402,1.093,2.536,2.471,2.628h18.825
            c1.747,0,3.162,1.415,3.162,3.162c0,1.746-1.415,3.161-3.162,3.161h-5.425H29.9c-1.753,0-3.175,1.421-3.175,3.174
            c0,1.727,1.377,3.123,3.092,3.167h9.928c1.649,0,2.984,1.336,2.984,2.985c0,1.65-1.335,2.986-2.984,2.986H17.959
            c-1.597,0.166-2.846,1.5-2.846,3.142c0,1.753,1.421,3.174,3.174,3.174h23.854h4.011h67.813c1.854,0,3.36-1.568,3.36-3.497V4.156
            C117.327,2.229,115.82,0.661,113.967,0.661z'
          />
          <g>
            <circle fill='#202B40' cx='67.592' cy='22.065' r='2.178' />
            <circle fill='#202B40' cx='88.517' cy='22.065' r='2.179' />
          </g>
          <g>
            <circle fill='#FFFFFF' cx='64.418' cy='29.426' r='2.178' />
            <circle fill='#FFFFFF' cx='91.691' cy='29.426' r='2.178' />
          </g>
          <path
            fill='none'
            stroke='#202B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M83.786,30.938
            c0,3.164-2.567,5.729-5.731,5.729c-3.163,0-5.729-2.565-5.729-5.729'
          />
          <ellipse
            fill='#0E131D'
            cx='76.778'
            cy='77.35'
            rx='40.037'
            ry='2.757'
          />
          <path
            fill='#CAE0FF'
            d='M18.26,40.187h-7.758c-1.753,0-3.175,1.421-3.175,3.174s1.421,3.175,3.175,3.175h7.758
            c1.754,0,3.175-1.421,3.175-3.175S20.014,40.187,18.26,40.187z'
          />
        </g>
      </svg>
    );
  }
}
