import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import { get } from '../helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class PeachCallbacks extends React.Component {
  state = {
    loading: false,
    error: '',
    peachCallbacks: [],
  };

  componentDidMount() {
    this.fetchCallbacks();
  }

  async fetchCallbacks() {
    this.setState({ loading: true });

    const env = runtimeEnv();
    const url = env.REACT_APP_COLLECT_HOST + '/peach-callbacks';

    try {
      const peachCallbacks = await get(url);
      this.setState({ peachCallbacks });
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderPeachCallbackRow(peachCallback) {
    return (
      <tr
        key={peachCallback.peachCallbackId}
        onClick={() =>
          this.props.router.push(
            '/collect/peach-callbacks/' + peachCallback.peachCallbackId,
          )
        }
      >
        <td>{moment(peachCallback.createdAt).format('DD MMM YYYY, hh:mm')}</td>
        <td>{peachCallback.peachBatchId}</td>
      </tr>
    );
  }

  render() {
    return (
      <Card>
        <CardHeader>Peach Callbacks</CardHeader>
        <CardBody>
          {this.state.loading ? (
            <div>
              <i>Loading peach callbacks...</i>
            </div>
          ) : (
            <React.Fragment>
              <div>
                Error: <code>{this.state.error}</code>
              </div>
              <Table hover>
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Batch Id</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.peachCallbacks.map(o =>
                    this.renderPeachCallbackRow(o),
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(PeachCallbacks);
