import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Form,
  FormGroup,
  FormFeedback,
  Label,
} from 'reactstrap';
import { connect } from 'react-redux';

import ajax from '../../helpers/ajax';
import Button from '../../components/loading-button';
import { updateUser } from '../actions';
import {
  ParseError,
  parsePhoneNumberWithError,
  isValidPhoneNumber,
} from 'libphonenumber-js';

const ERROR_MESSAGES = Object.freeze({
  EMPTY_FIELD: 'Empty input fields, cannot update user details',
  INVALID_EMAIL: 'Invalid email address, cannot update user details',
  INVALID_CELL: 'Invalid cellphone number, cannot update user details',
});

class UpdateUserDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: this.props.selectedUser.firstName,
      lastName: this.props.selectedUser.lastName,
      email: this.props.selectedUser.email,
      cellphone: this.props.selectedUser.cellphone,
      validate: {
        firstNameState: 'has-success',
        lastNameState: 'has-success',
        emailState: 'has-success',
        cellphoneState: 'has-success',
      },
    };
  }

  validateFirstName(firstName) {
    if (firstName) {
      this.setState({
        firstName: firstName,
        validate: {
          ...this.state.validate,
          firstNameState: 'has-success',
        },
      });
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          firstNameState: 'has-danger',
        },
      });
    }
  }

  validateLastName(lastName) {
    if (lastName) {
      this.setState({
        lastName: lastName,
        validate: {
          ...this.state.validate,
          lastNameState: 'has-success',
        },
      });
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          lastNameState: 'has-danger',
        },
      });
    }
  }

  validateEmail(email) {
    if (email && email.match(/\S+@\S+.\S+.\S+/)) {
      this.setState({
        email: email,
        validate: {
          ...this.state.validate,
          emailState: 'has-success',
        },
      });
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          emailState: 'has-danger',
        },
      });
    }
  }

  validateCellphone(cellphone) {
    try {
      if (!cellphone) {
        this.setState({
          validate: {
            ...this.state.validate,
            cellphoneState: 'has-danger',
          },
        });
        return;
      }

      // Parse the phone number using libphonenumber-js
      const parsedNumber = parsePhoneNumberWithError(cellphone);

      // Check if the phone number is valid
      if (parsedNumber.isValid()) {
        this.setState({
          cellphone: parsedNumber.formatInternational(), // Store the formatted number
          validate: {
            ...this.state.validate,
            cellphoneState: 'has-success',
          },
        });
      } else {
        this.setState({
          validate: {
            ...this.state.validate,
            cellphoneState: 'has-danger',
          },
        });
      }
    } catch (error) {
      console.error(`Error parsing cellphone number: ${error.message}`);
      this.setState({
        validate: {
          ...this.state.validate,
          cellphoneState: 'has-danger',
        },
      });
    }
  }

  updateUserDetails() {
    const { selectedUser, close, dispatch } = this.props;
    const { firstName, lastName, email, cellphone } = this.state;
    const { firstNameState, lastNameState, emailState, cellphoneState } =
      this.state.validate;

    if (
      firstNameState === 'has-success' &&
      lastNameState === 'has-success' &&
      emailState === 'has-success' &&
      cellphoneState === 'has-success'
    ) {
      ajax({
        type: 'PATCH',
        path: '/admin/user',
        data: {
          user_id: selectedUser.id,
          first_name: firstName,
          last_name: lastName,
          email: email,
          cellphone: cellphone,
        },
      })
        .then((result) => {
          dispatch(
            updateUser(selectedUser.id, firstName, lastName, email, cellphone),
          );
          close();
        })
        .catch((e) => {
          close();
          console.warn('Update failed!');
        });
    }
  }

  render() {
    const { open, close, selectedUser } = this.props;

    const { firstName, lastName, email, cellphone } = selectedUser;

    return (
      <Modal isOpen={open} onClosed={close}>
        <ModalHeader>Update user details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for='user-first-name-input'>First Name</Label>
              <Input
                type='text'
                id='user-first-name-input'
                defaultValue={firstName}
                valid={this.state.validate.firstNameState === 'has-success'}
                invalid={this.state.validate.firstNameState === 'has-danger'}
                onChange={(e) => this.validateFirstName(e.target.value)}
              />
              <FormFeedback>{ERROR_MESSAGES.EMPTY_FIELD}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for='user-last-name-input'>Last Name</Label>
              <Input
                type='text'
                id='user-last-name-input'
                defaultValue={lastName}
                valid={this.state.validate.lastNameState === 'has-success'}
                invalid={this.state.validate.lastNameState === 'has-danger'}
                onChange={(e) => this.validateLastName(e.target.value)}
              />
              <FormFeedback>{ERROR_MESSAGES.EMPTY_FIELD}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for='user-email-input'>Email</Label>
              <Input
                type='email'
                id='user-email-input'
                defaultValue={email}
                valid={this.state.validate.emailState === 'has-success'}
                invalid={this.state.validate.emailState === 'has-danger'}
                onChange={(e) => this.validateEmail(e.target.value)}
              />
              <FormFeedback>{ERROR_MESSAGES.INVALID_EMAIL}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for='user-cellphone-input'>
                Cellphone (international format)
              </Label>
              <Input
                type='text'
                id='user-cellphone-input'
                defaultValue={cellphone}
                valid={this.state.validate.cellphoneState === 'has-success'}
                invalid={this.state.validate.cellphoneState === 'has-danger'}
                onChange={(e) => this.validateCellphone(e.target.value)}
              />
              <FormFeedback>{ERROR_MESSAGES.INVALID_CELL}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Cancel</Button>
          <Button color='primary' onClick={this.updateUserDetails.bind(this)}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect((state) => ({ users: state.users }))(
  UpdateUserDetailsModal,
);


export function cellphoneToInternationalFormat(init) {
  // const number = this.cellphone && this.cellphone.number;
  if (typeof init !== 'string' && !init.number) {
    return undefined;
  }

  try {
    if (typeof init !== 'string') {
      const countryCode = init.country;
      return parsePhoneNumberWithError(
        init.number,
        countryCode,
      ).number.toString();
    }

    if (init.charAt(0) === '+') {
      return parsePhoneNumberWithError(init).number.toString();
    }

    return parsePhoneNumberWithError(init, 'ZA').number.toString();
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error}`);
    }
    return undefined;
  }
}


export const isValidCellphoneNumber = (number, countryCode) => {
  const country = countryCode;

  try {
    const parsedCellphone = parsePhoneNumberWithError(number, country);
    if (!parsedCellphone.isValid() || country !== parsedCellphone.country) {
      return false;
    }

    return isValidPhoneNumber(number, countryCode);
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(`Error parsing cellphone number: ${error.message}`);
    } else {
      console.error(`Error parsing cellphone number: ${error}`);
    }
    return undefined;
  }
};