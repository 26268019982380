import { BlockType } from '../block-type';

export abstract class BlockState {
  readonly type: BlockType;

  abstract toJSON(): {
    type: string;
    [key: string]: any;
  };
}
