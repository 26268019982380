import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export class InputTimeBlockState extends BlockState {
  readonly value?: string;

  constructor(init: Construct<InputTimeBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      value: this.value,
    };
  }

  static fromJSON(init: any) {
    return new InputTimeBlockState({
      type: init.type,
      value: init.value,
    });
  }
}
