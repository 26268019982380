import React from 'react';
import ProductModuleDefinitionQuoteSchema from './product-module-definition-quote-schema';
import ProductModuleDefinitionApplicationSchema from './product-module-definition-application-schema';
import ProductModuleDefinitionCode from './product-module-definition-code';
import { ProductModuleScheduledFunctions } from './product-module-scheduled-functions';
import { ProductModuleDefinitionAlterationHooks } from './product-module-definition-alteration-hooks';

interface Props {
  productModuleKey: string;
}

export default class ProductModuleDefinitionPricing extends React.Component<
Props,
any
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      module: {
        name: '',
      },
    };
  }

  render() {
    return (
      <div>
        <ProductModuleDefinitionQuoteSchema
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionApplicationSchema
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionCode
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleScheduledFunctions />
        <ProductModuleDefinitionAlterationHooks />
      </div>
    );
  }
}
