/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='12'
        viewBox='0 0 8 12'
      >
        <path
          fill='none'
          fillRule='evenodd'
          stroke='#757575'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M2.588 9.464l3.765-3.556-3.765-3.555'
        />
      </svg>
    );
  }
}
