import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { inject, observer } from 'mobx-react';
import { ReportViewStore } from '../../stores/report-view-store';

interface Props {
  close: () => void;
}

interface Injected extends Props {
  reportViewStore: ReportViewStore;
}

interface State {}

@inject('reportViewStore')
@observer
export class ArchiveReportModal extends React.Component<Props, State> {

  get injected() {
    return this.props as Injected;
  }

  archiveReport = async () => {
    const { reportViewStore } = this.injected;
    await reportViewStore.archive();
    this.props.close();
  }

  render() {
    const { reportViewStore } = this.injected;

    return (
      <Modal isOpen={true} toggle={this.props.close} className='reports-prompt-modal'>
        <ModalHeader className='reports-prompt-modal-header'>
          <Icon24PXCross onClick={this.props.close} />
          <div style={{ fontSize: 20 }} className='reports-prompt-modal-header-text'>
            Archive report
          </div>
        </ModalHeader>
        <ModalBody className='reports-prompt-modal-body'>
          <p>
            Are you sure you want to archive this report
            Once archived, a report will not run and cannot be resumed again.
          </p>
        </ModalBody>
        <ModalFooter className='reports-prompt-modal-footer'>
          <Button
            color='danger'
            disabled={reportViewStore.isLoading}
            onClick={this.archiveReport}>
            Archive report
          </Button>
          <br />
          <Button
            color='link'
            disabled={reportViewStore.isLoading}
            onClick={this.props.close}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
