import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { InputNumberBlock as Block } from '../domain/input-number-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputNumberBlockState } from '../domain/states/input-number-block-state';
import { Input, FormGroup, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputNumberBlockState;
}

interface State {
  UIState: BlockUIState;
  inputNumberBlockState?: BlockState;
}

@observer
export class InputNumberBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputNumberBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  validate = (value: string) => {
    const { min, max } = this.props.block;
    try {
      const num = parseFloat(value);

      if (min && num < min) {
        return min;
      }

      if (max && num > max) {
        return max;
      }

      return num;
    } catch (e) {
      return min ? min : 0;
    }
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      inputNumberBlockState: blockStateFactory.createBlock({
        ...this.state.inputNumberBlockState,
        value: this.validate(event.target.value),
      }),
    });
    event.preventDefault();
  };

  render() {
    const { disabled, block, required } = this.props;
    const { inputNumberBlockState } = this.state;
    if (!inputNumberBlockState) {
      return null;
    }

    const { value } = inputNumberBlockState as InputNumberBlockState;

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label>
          <b>{block.title}</b>
        </Label>
        <br />
        <Input
          className='form-control'
          id={block.key}
          required={required}
          disabled={disabled}
          key={block.key}
          type='number'
          min={block.min}
          max={block.max}
          value={value}
          onChange={(e) => this.handleOnChange(e)}
        />
      </FormGroup>
    );
  }
}
