import React from 'react';
import { Button, Tooltip } from 'reactstrap';
import { logout } from '../../account/actions/logout';

const nav = [
  {
    title: true,
    name: 'Organizations',
    image: '/images/sidebar/organizations.png',
    url: '/organizations',
    path: '/organizations',
  },
  {
    title: true,
    name: 'User Management',
    image: '/images/sidebar/users.png',
    url: '/users',
    path: '/users',
  },
  {
    name: 'Product modules',
    url: '/product-modules',
    image: '/images/sidebar/shield.png',
    path: '/product-modules',
  },
  {
    name: 'Payments',
    image: '/images/sidebar/arrows.png',
    url: '/payments',
    path: '/payments',
  },
  {
    name: 'Mailer',
    image: '/images/sidebar/inbox.png',
    url: '/communications',
    path: '/communications',
  },
  {
    name: 'Features',
    image: '/images/sidebar/cards.png',
    url: '/features',
    path: '/features',
  },
];

const LinkedListItem = props => {
  const classes = props.router.isActive(props.path) ? 'active' : '';
  return (
    <li
      id={props.id}
      className={`${props.className ? props.className : ''} ${classes}`}
      onClick={props.router.push.bind(this, props.to)}
    >
      <a>{props.children}</a>
    </li>
  );
};

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltips: nav.map((route, i) => {
        return { name: route.name, path: route.path, visible: false };
      }),
      userTooltipVisible: false,
    };
  }

  toggleTooltip = index => {
    this.setState(currentState => {
      const tooltips = currentState.tooltips;
      tooltips[index].visible = !tooltips[index].visible;
      return {
        tooltips,
      };
    });
  };

  logout = () => {
    this.props
      .dispatch(logout())
      .catch(e => e)
      .then(r => this.props.router.push('/login'));
  };

  render() {
    return (
      <div className='sidebar'>
        <div className='sidebar-primary'>
          <div className='sidebar-header'>
            <a href='/'>
              <img src='/images/lego-alfred-upper.png' alt='Alfred logo' />
            </a>
          </div>
          <ul className='list-unstyled components'>
            {nav.map((item, i) => {
              if (!item.image) {
                return null;
              }
              return (
                <LinkedListItem
                  key={item.path + i + '-li'}
                  path={item.path}
                  to={item.url}
                  router={this.props.router}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    id={item.path.replace('/', '') + i + '-img'}
                  />
                </LinkedListItem>
              );
            })}
            <LinkedListItem
              key={'user-li'}
              className={'user'}
              path= {window.location.pathname}
              to={window.location.pathname}
              router={this.props.router}
            >
              <img src='/images/user.png' alt='Profile' id='user-img' />
            </LinkedListItem>
          </ul>
          {this.state.tooltips.map((tooltip, i) => {
            return (
              <Tooltip
                placement='right'
                isOpen={this.state.tooltips[i].visible}
                key={tooltip.path + i + '-tt'}
                target={tooltip.path.replace('/', '') + i + '-img'}
                toggle={() => {
                  this.toggleTooltip(i);
                }}
                offset='0px, 10px'
                modifiers={{ flip: false }}
              >
                {tooltip.name}
              </Tooltip>
            );
          })}
          <Tooltip
            placement='right'
            isOpen={this.state.userTooltipVisible}
            key='user-tt'
            target='user-img'
            toggle={() => {
              this.setState(currentState => {
                return {
                  userTooltipVisible: !currentState.userTooltipVisible,
                };
              });
            }}
            offset='0px, 5px'
            modifiers={{ flip: false }}
            delay={{
              hide: 1000,
            }}
            innerClassName='user'
          >
            <Button onClick={this.logout} color='link'>Sign out</Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}
