/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='80px'
        height='65px'
        viewBox='0 0 107.896 84.178'
        enableBackground='new 0 0 107.896 84.178'
        xmlSpace='preserve'
      >
        <g>
          <path
            fill='#CCDEF3'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M29.363,54.91V26.57
        c0-9.164,7.429-16.592,16.593-16.592h38.778c9.164,0,16.592,7.428,16.592,16.592v28.34'
          />
          <path
            fill='#12161F'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M29.363,54.91V26.57
        c0-9.164,7.429-16.592,16.593-16.592l0,0c9.164,0,16.592,7.428,16.592,16.592v28.34'
          />
          <path
            fill='#9FC3E7'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M106.896,57.984
        c0,1.699-1.376,3.076-3.076,3.076H25.379c-1.697,0-3.075-1.377-3.075-3.076l0,0c0-1.697,1.378-3.074,3.075-3.074h78.441
        C105.521,54.91,106.896,56.287,106.896,57.984L106.896,57.984z'
          />
          <rect
            x='59.321'
            y='62.273'
            fill='#12161F'
            width='10.557'
            height='21.906'
          />
          <path
            fill='#FFFFFF'
            stroke='#212B40'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M104.612,10.873c0,1.039-0.842,1.881-1.88,1.881
        H88.548c-1.038,0-1.88-0.842-1.88-1.881V2.88C86.668,1.841,87.51,1,88.548,1h14.185c1.038,0,1.88,0.842,1.88,1.881V10.873z'
          />
          <path
            fill='#FFFFFF'
            stroke='#212B40'
            strokeWidth='2'
            strokeMiterlimit='10'
            d='M91.125,25.814c0,0.984-0.798,1.781-1.783,1.781
        h-0.891c-0.984,0-1.783-0.797-1.783-1.781V2.783C86.668,1.798,87.467,1,88.451,1h0.891c0.985,0,1.783,0.799,1.783,1.783V25.814z'
          />
          <g>
            <defs>
              <path
                id='SVGID_1_'
                d='M10.77,22.457c0-1.34,1.096-2.438,2.437-2.438h36.025c1.342,0,2.438,1.098,2.438,2.438v21.686
            c0,1.338-1.096,2.436-2.438,2.436H13.206c-1.341,0-2.437-1.098-2.437-2.436V22.457z'
              />
            </defs>
            <clipPath id='SVGID_2_'>
              <use xlinkHref='#SVGID_1_' overflow='visible' />
            </clipPath>
            <g clipPath='url(#SVGID_2_)'>
              <path
                fill='#FFFFFF'
                d='M10.77,22.019c0-1.1,0.9-2,2-2h36.899c1.101,0,2,0.9,2,2v22.559c0,1.1-0.899,2-2,2H12.77
            c-1.1,0-2-0.9-2-2V22.019z'
              />
            </g>
            <path
              clipPath='url(#SVGID_2_)'
              fill='#F2F2F2'
              d='M51.669,23.705l-18.288,14.74c-1.121,0.906-2.813,0.906-3.936-0.002
          L10.879,23.419c0-1.541-0.07,0.018-0.109,0v2.188l18.02,14.521c0.747,0.605,1.686,0.906,2.626,0.906
          c0.938,0,1.877-0.301,2.625-0.906l17.629-14.209V23.705z'
            />
            <path
              clipPath='url(#SVGID_2_)'
              fill='none'
              stroke='#212B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='
          M51.945,46.537L33.498,31.669c-1.305-1.053-3.261-1.053-4.566,0L10.488,46.537'
            />
            <path
              clipPath='url(#SVGID_2_)'
              fill='#FFFFFF'
              stroke='#212B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              d='
          M52.561,22.537L33.742,37.705c-1.329,1.074-3.326,1.074-4.655,0l-18.82-15.168'
            />
          </g>
          <circle
            fill='#FFFFFF'
            stroke='#212B40'
            strokeWidth='2'
            strokeMiterlimit='10'
            cx='88.896'
            cy='26.705'
            r='3.915'
          />
          <g>
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='3.86'
              y1='13.277'
              x2='6.889'
              y2='16.302'
            />
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='1'
              y1='20.184'
              x2='5.141'
              y2='20.184'
            />
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='10.77'
              y1='10.412'
              x2='10.77'
              y2='14.571'
            />
          </g>
        </g>
      </svg>
    );
  }
}
