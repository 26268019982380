export default (state = [], action) => {
  switch (action.type) {
    case 'SET_USERS':
      return action.users;
    case 'UPDATE_USER':
      return state.map(u => {
        if (u.id === action.userId) {
          u.firstName = action.firstName;
          u.lastName = action.lastName;
          u.cellphone = action.cellphone;
          u.email = action.email;
        }
        return u;
      });
    case 'LOCKED_USER':
      return state.map(u => {
        if (u.id === action.userId) {
          u.locked = true;
        }
        return u;
      });
    case 'UNLOCKED_USER':
      return state.map(u => {
        if (u.id === action.userId) {
          u.locked = false;
        }
        return u;
      });
    case 'DISABLED_APP_TOTP':
      return state.map(u => {
        if (u.id === action.userId) {
          u.appTOTP = false;
        }
        return u;
      });
    case 'ENABLED_INSURANCE':
      return state.map(u => {
        if (u.id === action.userId) {
          u.insuranceAccess = true;
        }
        return { ...u };
      });
    default:
      return state;
  }
};
