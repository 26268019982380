import * as React from 'react';
import { Modal } from 'reactstrap';

export class FullScreenModal extends React.Component {
  render() {
    return (
      <Modal className='full-screen-modal' {...this.props}>
        {this.props.children}
      </Modal>
    );
  }
}
