import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { InputCurrencyBlock as Block } from '../domain/input-currency-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputCurrencyBlockState } from '../domain/states/input-currency-block-state';
import { Input } from 'reactstrap';
import { FormGroup, InputGroup, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputCurrencyBlockState;
}

interface State {
  UIState: BlockUIState;
  inputCurrencyBlockState?: BlockState;
}

@observer
export class InputCurrencyBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputCurrencyBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  getCurrencySymbol (isoCode?: string) {
    try {
      return isoCode ? (0).toLocaleString('en-ZA', {
        style: 'currency',
        currency: isoCode || 'ZAR',
      }).replace('0', '').replace(',00', '') : 'R';
    } catch {
      return 'R';
    }
  }

  render() {
    const { disabled, block, blockState } = this.props;
    const { inputCurrencyBlockState } = this.state;
    if (!inputCurrencyBlockState) {
      return null;
    }

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label className='form-label'>
          <b>{block.title}</b>
        </Label>
        <br />
        {block.description && (
          <span className='form-label'>{block.description}</span>
        )}
        <InputGroup>
          <span className='input-group-text'>
            {this.getCurrencySymbol(block.isoCode)}
          </span>
          <Input
            disabled={disabled}
            key={block.key}
            defaultValue={blockState.value ? blockState.value.toString() : '0'}
            min={block.min}
            max={block.max}
            placeholder={
              block.placeholder ? block.placeholder.toString() : undefined
            }
          />
        </InputGroup>
      </FormGroup>
    );
  }
}
