/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='100px'
        height='21.551px'
        viewBox='0 0 100 21.551'
        enableBackground='new 0 0 100 21.551'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <path fill='#596981' d='M15.771,19.405' />
          </g>
          <g>
            <path fill='#596981' d='M13.292,11.727' />
          </g>
          <g>
            <path
              fill='#596981'
              d='M42.485,16.736c-0.295,0.087-0.633,0.173-1.015,0.253c-0.382,0.081-0.783,0.15-1.202,0.211
          c-0.418,0.059-0.845,0.105-1.277,0.143c-0.435,0.035-0.85,0.056-1.246,0.056c-0.957,0-1.81-0.14-2.559-0.419
          c-0.75-0.279-1.382-0.68-1.896-1.202c-0.514-0.521-0.907-1.153-1.18-1.896c-0.271-0.744-0.408-1.578-0.408-2.504
          c0-0.939,0.119-1.789,0.354-2.545c0.234-0.758,0.574-1.4,1.014-1.93c0.441-0.529,0.981-0.934,1.621-1.212
          c0.639-0.278,1.37-0.419,2.193-0.419c0.456,0,0.863,0.045,1.224,0.132c0.36,0.089,0.724,0.214,1.091,0.375V0.53L42.485,0V16.736
          L42.485,16.736z M35.055,11.223c0,1.014,0.228,1.83,0.685,2.447c0.455,0.617,1.131,0.926,2.027,0.926
          c0.294,0,0.566-0.012,0.816-0.033s0.455-0.048,0.617-0.077V8.51c-0.207-0.131-0.475-0.241-0.806-0.33
          c-0.331-0.088-0.665-0.133-1.003-0.133C35.833,8.049,35.055,9.107,35.055,11.223z'
            />
            <path
              fill='#596981'
              d='M55.465,11.29c0,0.912-0.132,1.746-0.396,2.502c-0.266,0.758-0.648,1.403-1.146,1.94
          c-0.5,0.537-1.1,0.952-1.797,1.246s-1.48,0.44-2.348,0.44c-0.853,0-1.627-0.146-2.326-0.44s-1.297-0.709-1.797-1.246
          s-0.89-1.183-1.168-1.94c-0.279-0.756-0.42-1.59-0.42-2.502c0-0.91,0.145-1.742,0.43-2.492c0.287-0.75,0.684-1.389,1.19-1.918
          c0.509-0.528,1.11-0.939,1.81-1.234c0.697-0.293,1.457-0.441,2.281-0.441c0.838,0,1.605,0.147,2.305,0.441
          c0.697,0.295,1.297,0.706,1.795,1.234c0.5,0.529,0.89,1.168,1.168,1.918C55.325,9.547,55.465,10.379,55.465,11.29z M52.114,11.29
          c0-1.015-0.203-1.812-0.605-2.392c-0.404-0.581-0.98-0.871-1.73-0.871s-1.332,0.291-1.742,0.871
          c-0.412,0.581-0.617,1.377-0.617,2.392c0,1.014,0.205,1.818,0.617,2.414c0.41,0.596,0.992,0.895,1.742,0.895
          s1.326-0.299,1.73-0.895C51.911,13.108,52.114,12.303,52.114,11.29z'
            />
            <path
              fill='#596981'
              d='M56.813,11.311c0-0.838,0.137-1.627,0.408-2.37c0.271-0.741,0.665-1.39,1.18-1.94
          c0.514-0.551,1.139-0.987,1.875-1.312c0.734-0.323,1.571-0.485,2.512-0.485c0.617,0,1.185,0.055,1.699,0.166
          c0.514,0.111,1.014,0.27,1.5,0.475l-0.685,2.625c-0.31-0.118-0.646-0.221-1.015-0.311c-0.367-0.087-0.778-0.132-1.234-0.132
          c-0.971,0-1.693,0.302-2.172,0.903c-0.479,0.604-0.717,1.396-0.717,2.381c0,1.044,0.225,1.854,0.672,2.426
          c0.447,0.573,1.23,0.859,2.35,0.859c0.396,0,0.822-0.036,1.277-0.11c0.455-0.073,0.875-0.19,1.258-0.353l0.462,2.689
          c-0.382,0.162-0.858,0.301-1.433,0.418c-0.573,0.118-1.205,0.178-1.896,0.178c-1.059,0-1.97-0.158-2.732-0.475
          c-0.766-0.314-1.395-0.746-1.887-1.29s-0.853-1.187-1.08-1.93C56.927,12.983,56.813,12.178,56.813,11.311z'
            />
            <path
              fill='#596981'
              d='M73.415,0c0.617,0,1.184,0.062,1.697,0.188c0.516,0.125,0.912,0.246,1.191,0.364l-0.641,2.623
          c-0.295-0.131-0.614-0.23-0.959-0.297c-0.347-0.065-0.666-0.099-0.959-0.099c-0.397,0-0.731,0.056-1.004,0.166
          c-0.271,0.11-0.484,0.261-0.64,0.452c-0.153,0.191-0.265,0.418-0.331,0.684c-0.066,0.264-0.1,0.551-0.1,0.859v0.573h4.058v2.733
          h-4.058v8.863h-3.284V4.896c0-1.499,0.422-2.69,1.269-3.571C70.501,0.442,71.755,0,73.415,0z'
            />
            <path
              fill='#596981'
              d='M88.046,11.29c0,0.912-0.133,1.746-0.396,2.502c-0.265,0.758-0.646,1.403-1.146,1.94
          s-1.1,0.952-1.797,1.246s-1.48,0.44-2.348,0.44c-0.854,0-1.629-0.146-2.326-0.44s-1.297-0.709-1.797-1.246s-0.89-1.183-1.168-1.94
          c-0.279-0.756-0.42-1.59-0.42-2.502c0-0.91,0.145-1.742,0.432-2.492c0.285-0.75,0.683-1.389,1.189-1.918
          c0.508-0.528,1.109-0.939,1.809-1.234c0.697-0.293,1.459-0.441,2.281-0.441c0.838,0,1.606,0.147,2.305,0.441
          C85.36,5.94,85.96,6.351,86.46,6.879c0.498,0.529,0.89,1.168,1.168,1.918C87.907,9.547,88.046,10.379,88.046,11.29z M84.696,11.29
          c0-1.015-0.203-1.812-0.606-2.392c-0.404-0.581-0.981-0.871-1.729-0.871c-0.75,0-1.332,0.291-1.742,0.871
          c-0.412,0.58-0.617,1.377-0.617,2.392c0,1.014,0.205,1.818,0.617,2.414c0.41,0.596,0.992,0.895,1.742,0.895
          c0.748,0,1.325-0.299,1.729-0.895C84.493,13.108,84.696,12.303,84.696,11.29z'
            />
            <path
              fill='#596981'
              d='M94.146,8.764l2.307-3.252h3.395l-4.025,5.641c0.924,1.061,1.742,2.113,2.459,3.159
          c0.717,1.047,1.289,1.979,1.721,2.799h-3.522c-0.118-0.221-0.269-0.497-0.442-0.827c-0.178-0.33-0.375-0.678-0.596-1.037
          c-0.22-0.361-0.449-0.666-0.689-0.916c-0.239-0.25-0.477-0.478-0.709-0.683c-0.223,0.19-0.447,0.399-0.678,0.63
          c-0.229,0.23-0.447,0.52-0.654,0.863c-0.207,0.345-0.409,0.686-0.608,1.023c-0.198,0.338-0.376,0.652-0.53,0.945h-3.343
          c0.185-0.361,0.429-0.795,0.731-1.298c0.304-0.504,0.636-1.022,0.998-1.56c0.361-0.535,0.752-1.056,1.172-1.561
          c0.421-0.505,0.835-0.979,1.238-1.423c-0.721-1.024-1.414-2.009-2.078-2.954c-0.666-0.945-1.323-1.879-1.975-2.805h3.551
          L94.146,8.764z'
            />
          </g>
          <path
            fill='#596981'
            d='M23.185,0.092c-0.225-0.125-0.506-0.12-0.725,0.014L11.803,6.647L1.139,0.106
        c-0.22-0.135-0.522-0.141-0.748-0.014C0.165,0.217-0.001,0.456-0.001,0.713v13.043v0.315c0,0.247,0.16,0.479,0.371,0.606
        l11.059,6.769c0.113,0.07,0.251,0.104,0.38,0.104c0.062,0,0.129-0.008,0.19-0.023c0.056-0.014,0.108-0.011,0.158-0.037
        c0.004-0.002,0.01,0.021,0.014,0.021c0,0,0,0,0.002,0c0.01,0,0.023-0.033,0.033-0.039l11.002-6.78
        c0.211-0.129,0.315-0.372,0.315-0.62v-0.207V0.713C23.526,0.456,23.409,0.217,23.185,0.092z M9.208,13.663
        c-0.144,0.233-0.393,0.363-0.648,0.363c-0.135,0-0.273-0.035-0.396-0.111l-2.235-1.227C5.569,12.467,5.456,12,5.677,11.642
        c0.22-0.358,0.688-0.472,1.046-0.252l2.233,1.226C9.315,12.836,9.428,13.303,9.208,13.663z M6.626,7.799
        c-0.061,0.025-0.109,0.053-0.17,0.078c-0.129,0.056-0.252,0.111-0.379,0.17c-1.476,0.678-2.901,1.611-4.035,2.766
        C1.81,11.04,1.59,11.27,1.271,11.51V1.985l8.222,4.986C8.465,7.135,7.557,7.417,6.626,7.799z M22.253,13.647l-0.625,0.309
        l-9.23,5.612v-3.099l9.855-5.836V13.647L22.253,13.647z M22.253,9.034c0,0.012-0.059,0.023-0.088,0.041l-9.767,5.784v-2.55
        l9.854-5.837V9.034z M22.253,4.872c0,0.012-0.059,0.024-0.088,0.041l-9.767,5.785V7.971c0,0,0.082,0,0.087,0l9.77-5.986v2.887
        H22.253z'
          />
        </g>
      </svg>
    );
  }
}
