import React from 'react';
import { inject, observer } from 'mobx-react';
import { OrganizationViewStore } from '../../stores/organization-view-store';
import { ReportListStore } from './stores/report-list-store';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';
import { ReportsComponent } from './components/reports-component';
import { OrganizationFeatureListStore } from '../../../features/stores/organization-feature-list-store';

interface Props extends RouteComponentProps {
  router: History;
  params: {
    organizationId: string;
  };
}

interface Injected extends Props {
  organizationViewStore: OrganizationViewStore;
  reportListStore: ReportListStore;
  organizationFeatureListStore: OrganizationFeatureListStore;
}

@inject(
  'organizationViewStore',
  'organizationFeatureListStore',
  'reportListStore',
)
@observer
export class ReportsContainer extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    const { organizationViewStore, reportListStore, organizationFeatureListStore } = this.injected;
    organizationViewStore.setOrganizationIsLoadingTrue();
    reportListStore.setReportListStoreIsLoadingTrue();
    organizationFeatureListStore.setOrganizationFeatureListStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const organizationId = window.location.pathname.split('/')[2];
    const { organizationViewStore, reportListStore, organizationFeatureListStore } = this.injected;
    await organizationViewStore.load({ organizationId });
    await organizationFeatureListStore.load({ organizationId });

    const { features } = organizationFeatureListStore;
    if (features.find((feature) => feature.featureKey === 'reports')) {
      reportListStore.load({ organizationId });
    } else {
      reportListStore.clear();
    }
  }

  render() {
    const { params } = this.injected;
    return (
      <ReportsComponent router={this.props.router} organizationId={params.organizationId}/>
    );
  }
}
