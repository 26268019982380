import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import { ProductModuleDefinitionDocumentIndexStructure } from '../../product-module-documentation/orchestration';
import { ProductModuleDocumentation } from '../../product-module-documentation';

interface Props {
  productModuleKey: string;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

interface State {
  showDocument: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionApiDocumentation extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDocument: false,
    };
  }

  async componentDidMount() {
    const { productModuleKey, productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    return (
      <ProductModuleDocumentation
        docType={ProductModuleDefinitionDocumentIndexStructure.DocumentationId}
        toDbKey={'api_documentation'}
        breadcrumbDocName={'API documentation'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showDocument: false })
        }
      />
    );
  };

  render() {
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                API documentation
              </p>
            </Col>
            <Col xs={9}>
              <p>API documentation</p>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  onClick={() =>
                    this.setState({ showDocument: true })
                  }
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showDocument && this.renderFullScreenModal()}
        </CardBody>
        <hr/>
      </div>
    );
  }
}
