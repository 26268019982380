import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { InputTextBlock as Block } from '../domain/input-text-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputTextBlockState } from '../domain/states/input-text-block-state';
import { Input, FormGroup, Label } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputTextBlockState;
}

interface State {
  UIState: BlockUIState;
  inputTextBlockState?: BlockState;
}

@observer
export class InputTextBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputTextBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      inputTextBlockState: blockStateFactory.createBlock({
        ...this.state.inputTextBlockState,
        value: event.target.value,
      }),
    });
    event.preventDefault();
  };

  render() {
    const { disabled, block, blockState } = this.props;
    const { inputTextBlockState } = this.state;
    if (!inputTextBlockState) {
      return null;
    }

    return (
      <FormGroup style={{ width: '50%' }}>
        <Label><b>{block.title}</b></Label>
        <br />
        {block.description && (
          <span className='form-label'>{block.description}</span>
        )}
        <Input
          id={block.key}
          disabled={disabled}
          key={block.key}
          type='text'
          defaultValue={blockState.value}
          min={block.minLength}
          max={block.maxLength}
          placeholder={block.placeholder}
          onChange={(e) => this.handleOnChange(e)}
        />
      </FormGroup>
    );
  }
}
