import { Construct } from '../../shared/domain/construct';
import { UUID } from '../../shared/domain/uuid';

export class OrganizationFeature {
  readonly featureKey: string;
  readonly organizationId: UUID;
  readonly sandbox: boolean;
  readonly production: boolean;

  constructor(init: Construct<OrganizationFeature>) {
    Object.assign(this, init);
  }

  toNetwork(): DbOrganizationFeature {
    return {
      feature_key: this.featureKey,
      organization_id: this.organizationId.toString(),
      sandbox: this.sandbox,
      production: this.production,
    };
  }

  static fromNetwork(init: DbOrganizationFeature) {
    return new OrganizationFeature({
      featureKey: init.feature_key,
      organizationId: UUID.fromString(init.organization_id),
      sandbox: init.sandbox,
      production: init.production,
    });
  }
}

export interface DbOrganizationFeature {
  feature_key: string;
  organization_id: string;
  sandbox: boolean;
  production: boolean;
}
