import { Moment, utc as moment } from 'moment';
import { Requestee } from '../../shared/domain/requestee';
import { Construct } from '../../shared/domain/construct';
import { ProductModuleDefinitionSchema, NetworkProductModuleDefinitionSchema } from './product-module-definition-schema';

export class ProductModuleDefinitionAlterationHook {
  readonly productModuleDefinitionAlterationHookId: string;
  readonly productModuleDefinitionId: string;
  readonly schemaId: string;
  readonly productModuleDefinitionSchema: ProductModuleDefinitionSchema
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly key: string;
  readonly name: string;

  constructor(init: Construct<ProductModuleDefinitionAlterationHook>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionAlterationHook): ProductModuleDefinitionAlterationHook {
    return new ProductModuleDefinitionAlterationHook({
      productModuleDefinitionAlterationHookId: init.product_module_definition_alteration_hook_id,
      productModuleDefinitionId: init.product_module_definition_id,
      productModuleDefinitionSchema: ProductModuleDefinitionSchema.fromNetwork(init.product_module_definition_schema),
      schemaId: init.schema_id,
      key: init.key,
      createdBy: Requestee.fromJSON(init.created_by),
      createdAt: moment(init.created_at),
      name: init.name,
    });
  }
}

export interface NetworkProductModuleDefinitionAlterationHook {
  product_module_definition_alteration_hook_id: string;
  product_module_definition_id: string;
  schema_id: string;
  key: string;
  product_module_definition_schema: NetworkProductModuleDefinitionSchema;
  name: string;
  created_at: string;
  created_by: object;
}
