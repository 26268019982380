import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class MarkdownBlock extends Block {
  readonly markdown: string;

  constructor(init: Construct<MarkdownBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      markdown: this.markdown,
    };
  }

  static fromJSON(init: any) {
    return new MarkdownBlock({
      type: init.type,
      key: init.key,
      markdown: init.markdown,
    });
  }
}