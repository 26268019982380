import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';
import Button from '../../components/loading-button';
import Icon from '../../components/icon';
import moment from 'moment';

import ajax from '../../helpers/ajax';
import { deleteApiKey } from '../actions/api-keys';
import { lockUser, unlockUser, disableAppOTP } from '../actions';
import ResetLinkModal from './modal-reset-link';

const forgotPassword = email =>
  ajax({
    type: 'POST',
    path: '/admin/forgot-password',
    data: { email },
  });

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisableOTPModal: false,
      app_token: null,
      loadingLocking: false,
      loadingDisableOTP: false,
      loadingResetLink: false,
      showResetLinkModal: false,
      resetLink: '',
    };
  }

  resetLink() {
    this.setState({ loadingResetLink: true });
    forgotPassword(this.props.selectedUser.email).then(response => {
      if (response.success) {
        this.setState({
          loadingResetLink: false,
          showResetLinkModal: true,
          resetLink: response.resetLink,
        });
        return;
      }

      this.setState({ loadingResetLink: false });
    });
  }

  closeResetLinkModal() {
    this.setState({ showResetLinkModal: false, resetLink: '' });
  }

  disableOTP() {
    this.setState({ loadingDisableOTP: true });
    this.props
      .dispatch(disableAppOTP(this.props.selectedUser.id))
      .then(r => this.setState({ loadingDisableOTP: false }));
  }

  lockUser() {
    this.setState({ loadingLocking: true });
    this.props
      .dispatch(lockUser(this.props.selectedUser.id))
      .then(r => this.setState({ loadingLocking: false }));
  }

  unlockUser() {
    this.setState({ loadingLocking: true });
    this.props
      .dispatch(unlockUser(this.props.selectedUser.id))
      .then(r => this.setState({ loadingLocking: false }));
  }

  deleteApiKey(key) {
    const result = confirm('Are you sure you want to delete this key?'); // eslint-disable-line
    if (result) {
      this.props.dispatch(deleteApiKey(key.id)).then(r => {
        /* this.props.dispatch(addAlert('Success!', 'Contact `'
         + contactName + '` was successfully archived.', 'success'));*/
      });
    }
  }

  renderKeyRow(key) {
    return (
      <tr key={key.id}>
        <td>{key.name}</td>
        <td>{key.safeToken}</td>
        <td>{moment(key.createdAt).fromNow()}</td>
        <td>
          <div className='pull-right'>
            <Button color='link' onClick={this.deleteApiKey.bind(this, key)}>
              <Icon name='trash' />
            </Button>
          </div>
        </td>
      </tr>
    );
  }

  renderNormalState() {
    return (
      <div className='panel-table'>
        <Table className='full-height'>
          <tbody>{this.props.apiKeys.map(k => this.renderKeyRow(k))}</tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Card className='mb-4'>
          <CardHeader>2FA</CardHeader>
          <CardBody>
            <p>
              {this.props.selectedUser.appTOTP
                ? 'This user has app based 2FA enabled.'
                : 'This user does not have app based 2FA enabled, and will receive SMS-codes instead.'}
            </p>
            {this.props.selectedUser.appTOTP ? (
              <Button
                color={'danger'}
                loading={this.state.loadingDisableOTP}
                onClick={this.disableOTP.bind(this)}
              >
                Disable app-based 2FA
              </Button>
            ) : null}
          </CardBody>
        </Card>
        <Card className='mb-4'>
          <CardHeader>Lock User Account</CardHeader>
          <CardBody>
            <p>
              {this.props.selectedUser.locked
                ? 'This user`s account is locked. They won`t be able to log in or transact.'
                : 'This user`s account is in normal mode.'}
            </p>
            <Button
              color={this.props.selectedUser.locked ? 'danger' : 'success'}
              loading={this.state.loadingLocking}
              onClick={
                this.props.selectedUser.locked
                  ? this.unlockUser.bind(this)
                  : this.lockUser.bind(this)
              }
            >
              {this.props.selectedUser.locked ? 'Unlock' : 'Lock'} user account
            </Button>
          </CardBody>
        </Card>
        <Card className='mb-4'>
          <CardHeader>Reset password link</CardHeader>
          <CardBody>
            {
              <Button color={'success'} onClick={this.resetLink.bind(this)}>
                Request reset link
              </Button>
            }
          </CardBody>

          <ResetLinkModal
            open={this.state.showResetLinkModal}
            close={this.closeResetLinkModal.bind(this)}
            email={this.props.selectedUser.email}
            resetLink={this.state.resetLink}
          />
        </Card>
        <Card className='mb-4'>
          <CardHeader>API Keys</CardHeader>
          <CardBody>
            {this.props.apiKeys.length === 0 ? (
              <div>No API keys.</div>
            ) : (
              this.renderNormalState()
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedUser =
    state.users.filter(u => u.id === ownProps.params.userId)[0] || {};
  const apiKeys = state.apiKeys;
  return { selectedUser, apiKeys };
};

export default connect(mapStateToProps)(Security);
