import React from 'react';
import { Breadcrumb, Container, BreadcrumbItem } from 'reactstrap';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import ProductModuleDeploymentComponent from './components/deployment';
import {
  ProductModuleStore,
  ProductModuleVersionTypes,
} from '../product-definition/stores/product-module-store';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import LoadingInPage from '../../loading';
import { withRouter, RouteComponentProps } from 'react-router';
import { History } from 'history';
import { productModuleRoutes } from '../../utils/helper-functions';

interface Props extends RouteComponentProps {
  params: {
    productModuleKey: string;
  };
  route: any;
  router?: History;
}
interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}
interface State {
  isLoading: boolean;
}
@inject('productModuleStore')
@observer
class ProductModuleDeploymentImpl extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  redirect = (key: string) => {
    const { router } = this.props;
    if (router) {
      router.push(`/product-modules/${key}/product-definition`);
    }
  };

  async componentDidMount() {
    const { productModuleStore, params } = this.injected;
    this.setState({ isLoading: true });

    await productModuleStore.init(params.productModuleKey);
    await productModuleStore.getProductModuleDefinition(
      productModuleStore.productModule.productModuleId,
      productModuleStore.productModule.draftId,
      ProductModuleVersionTypes.Draft,
    );

    this.setState({ isLoading: false });
  }

  render() {
    const { productModuleStore, router } = this.injected;
    if (
      !productModuleStore.productModule ||
      !productModuleStore.productModuleDefinitionDraft
    ) {
      return <LoadingInPage />;
    }
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={router}
          route={this.props.route}
          subRoutes={productModuleRoutes(productModuleStore.productModule)}
          title={_.startCase(this.props.params.productModuleKey)}
        />
        <Container className='container-positioning'>
          <Breadcrumb style={{ backgroundColor: 'white' }}>
            <BreadcrumbItem>
              <a className='breadcrumb-a-tag-link' href='/product-modules'>
                Product modules
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a
                className='breadcrumb-a-tag-link'
                href={`/product-modules/${this.props.params.productModuleKey}`}
              >
                {productModuleStore.productModule.name}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Deployment</BreadcrumbItem>
          </Breadcrumb>
          {!this.state.isLoading && (
            <ProductModuleDeploymentComponent
              productModuleKey={this.props.params.productModuleKey}
              redirect={key => this.redirect(key)}
            />
          )}
        </Container>
      </div>
    );
  }
}

export const ProductModuleDeployment = withRouter(ProductModuleDeploymentImpl);
