// Template
// [
//   {
//     id,
//     userId,
//     cardId,
//     categoryId,
//     contactId,
//     amount,
//     action,
//     method,
//     description,
//     tags,
//     createdAt,
//   },
// ]

export default (state = [], action) => {
  switch (action.type) {
    case 'SET_TRANSACTIONS':
      return action.transactions.sort((a, b) => b.createdAt - a.createdAt);
    case 'UPDATED_TRANSACTION':
      return state.map(t => {
        if (t.id === action.transaction.id) {
          return action.transaction;
        }
        return t;
      });
    case 'ADD_TRANSACTION_TAGS':
      return state.map(c => {
        if (c.id === action.transactionId) {
          c.tagId = action.tagId;
        }
        return c;
      });
    case 'REMOVE_TRANSACTION_TAGS':
      return state.map(c => {
        if (c.id === action.transactionId) {
          c.tagId = null;
        }
        return c;
      });
    default:
      return state;
  }
};
