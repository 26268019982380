import React, { Component } from 'react';
import Switch from 'react-switch';

export default class ToggleSwitch extends Component {
  render() {
    const { id, leftText, rightText, onColor } = this.props;
    return (
      <label htmlFor={id} className='toggle-switch-label-container'>
        {leftText && <span className='toggle-switch-text'>{leftText}</span>}
        <Switch
          handleDiameter={20}
          onColor={onColor || '#e04870'}
          offColor={'#c3c3c3'}
          height={24}
          width={48}
          id={id}
          uncheckedIcon={false}
          checkedIcon={false}
          {...this.props}
        />
        {rightText && <span className='toggle-switch-text'>{rightText}</span>}
      </label>
    );
  }
}
