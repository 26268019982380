import { observable } from 'mobx';
import { ProductModuleCodeRun } from '../domain/product-module-code-run';
import { getProductModuleCodeRun } from '../product-module-api';

export class ProductModuleCodeRunStore {
  @observable isLoading = true;
  @observable productModuleCodeRun?: ProductModuleCodeRun;
  @observable error: string = '';

  load = async (params: {
    productModuleId: string;
    productModuleCodeRunId: string;
  }) => {
    const { productModuleId, productModuleCodeRunId } = params;
    this.isLoading = true;

    const { productModuleCodeRun } = await getProductModuleCodeRun({
      productModuleId,
      productModuleCodeRunId,
    });

    this.productModuleCodeRun = productModuleCodeRun;
  }

  setProductModuleCodeRunViewStoreLoading = (params: { isLoading: boolean }) => {
    this.isLoading = params.isLoading;
  };
}

export const productModuleCodeRunStore = new ProductModuleCodeRunStore();
