import React from 'react';
import { Input, Button, Popover, PopoverBody } from 'reactstrap';
import Images from '../../../../../../components/svg-images';
import { debounce } from '../../../../../../helpers/debounce';
import { TemplateField } from './template-fields-form';

interface Props {
  duplicateColumn: (index: number) => void;
  deleteColumn: (index: number) => void;
  reorderColumn: (direction: string, index: number) => void;
  compileHandlebars: () => void;
  index: number;
  numRows: number;
  templateField: TemplateField;
  handlebarResult: string;
}

interface State {
  handlebarsResult: string;
  hasChanged: boolean;
  popoverOpen: boolean;
  templateField: TemplateField;
  prevIndex: number;
}

interface DisplayProps extends Props {
  enterEdit: (index: number) => void;
}

interface DisplayState extends State {
  revertField: TemplateField;
}

export class DisplayTemplateFieldRow extends React.Component<DisplayProps, DisplayState> {
  constructor(props: DisplayProps) {
    super(props);

    this.state = {
      hasChanged: false,
      popoverOpen: false,
      templateField: props.templateField,
      revertField: props.templateField,
      handlebarsResult: '',
      prevIndex: -1,
    };
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  reorderColumn = (direction: string) => {
    if (direction === 'up') {
      this.props.reorderColumn(direction, this.props.index);
    } else {
      this.props.reorderColumn(direction, this.props.index);
    }
  };

  render() {
    const { templateField } = this.props;

    return (
      <tr>
        <td className='data-export-row-item' style={{ width: '4%' }}>
          {this.props.index === 0 ? (
            <a href='#' className='inactiveChevron'>
              <Images.chevronUpDisabled />
            </a>
          ) : (
            <a
              href='#'
              className='activeChevron'
              onClick={() => this.reorderColumn('up')}
            >
              <Images.chevronUp />
            </a>
          )}

          {this.props.index === this.props.numRows - 1 ? (
            <a href='#' className='inactiveChevron'>
              <Images.chevronDownDisabled />
            </a>
          ) : (
            <a
              href='#'
              className='activeChevron'
              onClick={() => this.reorderColumn('down')}
            >
              <Images.chevronDown />
            </a>
          )}
        </td>
        <td
          className='data-export-row-item'
          style={{ width: '20%' }}
          onClick={() => this.props.enterEdit(this.props.index)}
        >
          <strong style={{ verticalAlign: 'middle' }}>
            {templateField.name}
          </strong>
        </td>
        <td
          className='data-export-row-item'
          style={{
            width: '40%',
            fontFamily: 'Source Code Pro',
            verticalAlign: 'middle',
          }}
          onClick={() => this.props.enterEdit(this.props.index)}
        >
          {templateField.value}
        </td>
        <td
          className='data-export-row-item'
          onClick={() => this.props.enterEdit(this.props.index)}
        >
          {this.props.handlebarResult}
        </td>
        <td className='data-export-row-item' style={{ width: '10%' }}>
          <Button
            outline
            color='secondary'
            className='ellipsisButton'
            id={`popover-${this.props.index}`}
            type='button'
            onClick={this.toggle}
          >
            &#183;&#183;&#183;
          </Button>
          <Popover
            placement='bottom'
            isOpen={this.state.popoverOpen}
            target={`popover-${this.props.index}`}
            toggle={this.toggle}
            onBlur={this.toggle}
          >
            <PopoverBody>
              <Button
                className='popoverListItem'
                outline
                color='secondary'
                onMouseDown={e => {
                  e.preventDefault();
                  this.props.duplicateColumn(this.props.index);
                  this.setState({ popoverOpen: false });
                }}
              >
                Duplicate column
              </Button>
              <Button
                className='popoverListItem'
                outline
                color='danger'
                onMouseDown={e => {
                  e.preventDefault();
                  this.props.deleteColumn(this.props.index);
                  this.setState({ popoverOpen: false });
                }}
              >
                Delete
              </Button>
            </PopoverBody>
          </Popover>
        </td>
      </tr>
    );
  }
}

interface EditProps extends Props {
  revertField: TemplateField;
  validateName: (index: number, name: string) => void;
  validateValue: (index: number, value: string) => void;
  confirmEdit: () => void;
  updating: boolean;
  invalidateTemplateFields: () => void;
}

export class EditTemplateFieldRow extends React.Component<EditProps, State> {
  constructor(props: EditProps) {
    super(props);

    this.state = {
      hasChanged: false,
      popoverOpen: false,
      templateField: props.templateField,
      handlebarsResult: '',
      prevIndex: -1,
    };
  }

  revert = () => {
    this.props.validateName(this.props.index, this.props.revertField.name);
    this.props.validateValue(this.props.index, this.props.revertField.value);
    this.props.confirmEdit();
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  reorderColumn = (direction: string) => {
    if (direction === 'up') {
      this.props.reorderColumn(direction, this.props.index);
    } else {
      this.props.reorderColumn(direction, this.props.index);
    }
  };

  handleValueChange = (value: string) => {
    debounce(
      'compile-handlebars-template',
      async () => {
        this.props.compileHandlebars();
      },
      250,
    );
  };

  render() {
    const { templateField } = this.props;

    return (
      <tr>
        <td className='data-export-row-item' style={{ width: '4%' }}>
          {this.props.index === 0 ? (
            <a href='#' className='inactiveChevron'>
              <Images.chevronUpDisabled />
            </a>
          ) : (
            <a
              href='#'
              className='activeChevron'
              onClick={() => this.reorderColumn('up')}
            >
              <Images.chevronUp />
            </a>
          )}

          {this.props.index === this.props.numRows - 1 ? (
            <a href='#' className='inactiveChevron'>
              <Images.chevronDownDisabled />
            </a>
          ) : (
            <a
              href='#'
              className='activeChevron'
              onClick={() => this.reorderColumn('down')}
            >
              <Images.chevronDown />
            </a>
          )}
        </td>
        <td className='data-export-row-item' style={{ width: '20%' }}>
          <Input
            type='textarea'
            style={{ height: '3rem' }}
            value={templateField.name}
            onMouseDown={e => e.stopPropagation()}
            onChange={e => {
              templateField.name = e.target.value;
              this.setState(
                {
                  templateField: {
                    ...this.state.templateField,
                    name: templateField.name,
                  },
                },
                () =>
                  this.props.validateName(this.props.index, templateField.name),
              );
            }}
          />
        </td>
        <td className='data-export-row-item' style={{ width: '40%' }}>
          <Input
            type='textarea'
            style={{ fontFamily: 'Source Code Pro', height: '3rem' }}
            value={templateField.value}
            onMouseDown={e => e.stopPropagation()}
            onChange={e => {
              templateField.value = e.target.value;
              this.setState(
                {
                  templateField: {
                    ...this.state.templateField,
                    value: templateField.value,
                  },
                },
                () =>
                  this.props.validateValue(
                    this.props.index,
                    templateField.value,
                  ),
              );

              this.handleValueChange(templateField.value);
            }}
          />
        </td>
        <td className='data-export-row-item'>{this.props.handlebarResult}</td>
        <td className='data-export-row-item' style={{ width: '10%' }}>
          <div>
            {this.props.updating && (
              <Button
                outline
                color='secondary'
                onMouseDown={e => {
                  e.preventDefault();
                  this.revert();
                }}
              >
                Revert
              </Button>
            )}
            <Button
              outline
              color='secondary'
              className='ellipsisButton'
              id={`popover-${this.props.index}`}
              type='button'
              onClick={this.toggle}
            >
              &#183;&#183;&#183;
            </Button>
            <Popover
              placement='bottom'
              isOpen={this.state.popoverOpen}
              target={`popover-${this.props.index}`}
              toggle={this.toggle}
              onBlur={this.toggle}
            >
              <PopoverBody>
                <Button
                  className='popoverListItem'
                  outline
                  color='secondary'
                  onMouseDown={e => {
                    e.preventDefault();
                    this.props.duplicateColumn(this.props.index);

                    this.setState({ popoverOpen: false });
                  }}
                >
                  Duplicate column
                </Button>
                <Button
                  className='popoverListItem'
                  outline
                  color='danger'
                  onMouseDown={e => {
                    e.preventDefault();
                    this.props.deleteColumn(this.props.index);
                    this.setState({ popoverOpen: false });
                  }}
                >
                  Delete
                </Button>
              </PopoverBody>
            </Popover>
          </div>
        </td>
      </tr>
    );
  }
}

interface FilterRowProps {
  filter?: string;
  handlebarResult?: string;
  compileHandlebars: () => void;
  validateValue: (filter: string) => void;
}

interface FilterRowState {
  filter: string;
}

export class FilterRow extends React.Component<FilterRowProps, FilterRowState> {
  constructor(props: FilterRowProps) {
    super(props);

    this.state = {
      filter: this.props.filter || '',
    };
  }

  handleValueChange = (value: string) => {
    debounce(
      'compile-handlebars-template',
      async () => {
        this.props.compileHandlebars();
      },
      250,
    );
  };

  render() {
    return (
      <tr>
        <td className='scheduled-export-row-item' style={{ width: '40%' }}>
          <Input
            type='textarea'
            style={{ fontFamily: 'Source Code Pro', height: '3rem' }}
            value={this.state.filter}
            onMouseDown={e => e.stopPropagation()}
            onChange={e => {
              this.setState(
                {
                  filter: e.target.value,
                },
                () => {
                  this.props.validateValue(this.state.filter);
                  this.handleValueChange(this.state.filter);
                },
              );
            }}
          />
        </td>
        <td className='scheduled-export-row-item'>{this.props.handlebarResult}</td>
      </tr>
    );
  }
}
