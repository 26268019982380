import { Moment, utc as moment } from 'moment';
import { Requestee } from '../../shared/domain/requestee';
import { Construct } from '../../shared/domain/construct';
import { BaseStoreModel } from '../components/product-definition/product-module-json-to-html/dynamic-form/interfaces';

export class ProductModuleDefinitionSchema {
  readonly productModuleDefinitionSchemaId: string;
  readonly jsonContent: BaseStoreModel[];
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly type: ProductModuleDefinitionSchemaType;

  constructor(init: Construct<ProductModuleDefinitionSchema>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionSchema): ProductModuleDefinitionSchema {
    return new ProductModuleDefinitionSchema({
      productModuleDefinitionSchemaId: init.product_module_definition_schema_id,
      jsonContent: init.json,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      type: init.type,
    });
  }
}


export interface NetworkProductModuleDefinitionSchema {
  readonly product_module_definition_schema_id: string;
  readonly json: any;
  readonly created_at: string;
  readonly created_by: object;
  readonly type: ProductModuleDefinitionSchemaType;
}

export enum ProductModuleDefinitionSchemaType {
  ClaimSchema = 'claim_schema',
  ClaimBlocksSchema = 'claim_blocks_schema',
  ApplicationSchema = 'application_schema',
  QuoteSchema = 'quote_schema',
}

