import React from 'react';
import { connect } from 'react-redux';
import {
  InputGroup,
  Input,
  Table,
  Card,
  CardHeader,
  CardBody,
  Container,
} from 'reactstrap';
import { getParams, setParams } from '../helpers/search';
import { filterUsers } from '../helpers/filter-users';
import { formatCellphone } from '../helpers/format-cellphone';
import SidebarSecondary from '../../components/sidebar/SidebarSecondary';

class Users extends React.Component {
  constructor(props) {
    super(props);
    const { searchQuery } = getParams();
    const searchFilteredUsers = filterUsers(props.users, searchQuery);
    this.state = { searchQuery, searchFilteredUsers };
  }

  componentWillReceiveProps(nextProps) {
    const { searchQuery } = getParams();
    const searchFilteredUsers = filterUsers(nextProps.users, searchQuery);
    this.setState({ searchFilteredUsers });
  }

  handleSearchChange(e) {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    setParams(this.state.pageIndex, searchQuery);
    const searchFilteredUsers = filterUsers(this.props.users, searchQuery);
    this.setState({ searchFilteredUsers });
  }

  renderUserRow(user) {
    return (
      <tr
        key={user.id}
        onClick={() => this.props.router.push('/users/' + user.id)}
      >
        <td>
          {user.firstName} {user.lastName}
        </td>
        <td>{user.email}</td>
        <td>{formatCellphone(user.cellphone)}</td>
        <td>{user.bankNumber}</td>
      </tr>
    );
  }

  render() {
    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <Container>
          <Card>
            <CardHeader>
              All Users (bank and insurance)
              <div className='pull-right'>
                <InputGroup>
                  <Input
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange.bind(this)}
                    type='text'
                    placeholder='Search'
                  />
                </InputGroup>
              </div>
            </CardHeader>
            <CardBody>
              {this.state.searchFilteredUsers.length === 0 ? (
                <div>No users.</div>
              ) : (
                <Table size='sm' hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Cellphone</th>
                      <th>Bank Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchFilteredUsers.map((u) =>
                      this.renderUserRow(u),
                    )}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (state.users.length === 0) return { users: [] };
  const users = state.users.sort((a, b) =>
    a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1,
  );
  return { users };
};

export default connect(mapStateToProps)(Users);

const sideBarSchema = {
  name: 'Users',
  routes: () => {
    return [
      {
        title: true,
        name: 'Filter',
      },
      {
        name: 'All',
        url: '/users/all',
      },
      {
        name: 'Invited',
        url: '/users/applications/invited',
      },
      {
        name: 'Pending review',
        url: '/users/applications/pending-review',
      },
    ];
  },
};
