import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

/**
 * This class is simply here for the sake of consistency, the block whose
 * state it represents is a display element only (an alert), thus no input will ever
 * be captured and persisted.
 */
export class AlertBlockState extends BlockState {

  constructor(init: Construct<AlertBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
    };
  }

  static fromJSON(init: any) {
    return new AlertBlockState({
      type: init.type,
    });
  }
}