import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { Input } from 'reactstrap';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import {
  ProductModuleComponentLoadingStateStore,
  StoreIndex,
} from '../../stores/product-module-component-loading-state-store';
import { TypesOfPolicyholdersCondition } from '../product-module-definition-settings/config/product-module-definition-settings-policyholder';
import { nestedStyleCheckbox } from '../product-module-definition-settings/utils';
import { debounce } from '../../../../../helpers/debounce';
import { Icon16PXTick } from '../../../../../components/icons/icon-16-px-tick';
import { documents } from './config/documents';
import _ from 'lodash';
import { SavingState } from '../../util';
import { ProductModuleDefinitionDocumentIndexStructure } from '../../product-module-documentation/orchestration';
import ProductModuleHtmlDoc from '../../product-module-html-to-documents';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  productModuleKey: string;
}

interface State {
  showDocument: boolean;
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfPolicyholdersCondition;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
}

@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionWelcomeLetter extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDocument: false,
    };
  }

  async componentWillMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(documents).map(checkboxDescription => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleDocuments,
          checkboxDescription,
        );
      });
    }
  }
  renderFullScreenModal = () => {
    return (
      <ProductModuleHtmlDoc
        docType={ProductModuleDefinitionDocumentIndexStructure.WelcomeLetterId}
        toDbKey={'welcome_letter_html'}
        breadcrumbDocName={'Welcome Letter'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showDocument: !this.state.showDocument })
        }
      />
    );
  };

  defaultValue = (params: Params) => {
    const { path, displayCheckValue } = params;

    const { productModuleStore } = this.props;
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        return !!settingsItem.find(val => val === displayCheckValue);
      }
      return settingsItem === displayCheckValue;
    }
  };
  onCheckBoxChange = async (
    path: any,
    condition: any,
    value: any,
    key: string,
  ) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleDocuments,
        key,
        SavingState.Saving,
      );
    }
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      let settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        const settingsItemValue = settingsItem.indexOf(value);
        settingsItemValue !== -1
          ? settingsItem.splice(settingsItemValue, 1)
          : settingsItem.push(value);
        _.set(settings, path, settingsItem);
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      } else {
        settingsItem = !settingsItem;
        _.set(settings, path, settingsItem);
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      }
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleDocuments,
        key,
        SavingState.Saved,
      );
      debounce(
        key,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleDocuments,
            key,
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  renderDocumentsEnabled = (
    params: Params,
    productModuleComponentLoadingStateStore: ProductModuleComponentLoadingStateStore,
    title?: string,
  ) => {
    const {
      label,
      index,
      path,
      condition,
      displayCheckValue,
      lockedComponent,
      description,
      key,
    } = params;

    const indexSet = index !== null || index != undefined ? index : 1;

    const saving =
      !!productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleDocuments[key];

    return (
      <Row
        key={`${_.snakeCase(label)}_${indexSet}`}
        style={{ paddingTop: 10, marginBottom: -2 }}
      >
        <Col xs={3}>{title}</Col>
        <Col xs={9}>
          <div style={nestedStyleCheckbox(false, indexSet)}>
            <div className='product-module-definitions-enable-item-checkbox '>
              <Input
                defaultChecked={this.defaultValue({ ...params })}
                disabled={lockedComponent}
                onChange={() => {
                  this.onCheckBoxChange(
                    path,
                    condition,
                    displayCheckValue,
                    key,
                  );
                }}
                type='checkbox'
              />
            </div>
            <div className='settings-description-label-div'>
              <p style={{ fontWeight: 'bold' }}>{label}</p>
            </div>
            <div className='product-module-definitions-enable-item-checkbox '>
              {saving === SavingState.Saved && (
                <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                  <Icon16PXTick style={{ marginRight: 5, paddingBottom: 3 }} />
                  Saved
                </div>
              )}
              {saving === SavingState.Saving && (
                <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                  Saving
                </div>
              )}
            </div>
            {/* {this.savingComponentBadge(key, productModuleComponentLoadingStateStore)} */}
            <div className='product-module-heading-description-spacing'>
              <span>{description}</span>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      productModuleComponentLoadingStateStore,
      productModuleStore,
    } = this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <div>
            {this.renderDocumentsEnabled(
              {
                ...documents.welcomeLetter,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                  index: 0,
                },
              },
              productModuleComponentLoadingStateStore,
              'Welcome letter',
            )}
          </div>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  onClick={() =>
                    this.setState({ showDocument: !this.state.showDocument })
                  }
                  className='product-module-definition-horizontal-card-button'
                  color='primary'
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
        {this.state.showDocument && this.renderFullScreenModal()}
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
