import moment, { Moment } from 'moment';
import { Construct } from '../../../../../shared/domain/construct';
import { Requestee } from '../../../../../shared/domain/requestee';
import { ScheduledDataExport } from '../scheduled-data-export';
import { TemplateField } from './template-field';
import { ExportType } from '../export-type';

export interface DefaultExportTemplates {
  templateId: string;
  templateName: string;
  dataSource: string;
  productModuleId: string;
  exportType?: string;
}

export enum TemplateDataSource {
  policyholders = 'policyholders',
  policies = 'policies',
  claims = 'claims',
  complaints = 'complaints',
  payments = 'payments',
  policyPremiums = 'policy_premiums',
  policyCharges = 'policy_charges',
  paymentMethods = 'payment_methods',
  applications = 'applications',
}

export const prettyDataSource = (dataSource: string) =>
  dataSource
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const DefaultExportTemplates: DefaultExportTemplates[] = [
  {
    exportType: ExportType.PolicyExport,
    templateName: 'Policies export',
    dataSource: 'policies',
    productModuleId: '',
    templateId: '',
  },
  {
    exportType: ExportType.PolicyholderExport,
    templateName: 'Policyholders export',
    dataSource: 'policyholders',
    productModuleId: '',
    templateId: '',
  },
  {
    exportType: ExportType.ClaimExport,
    templateName: 'Claims export',
    dataSource: 'claims',
    productModuleId: '',
    templateId: '',
  },
  {
    exportType: ExportType.ComplaintExport,
    templateName: 'Complaints export',
    dataSource: 'complaints',
    productModuleId: '',
    templateId: '',
  },
];

export class Template {
  readonly templateId: string;
  readonly templateName: string;
  readonly description: string;
  readonly dataSource: TemplateDataSource;
  readonly productModuleId: string;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly updatedAt: Moment;
  readonly fields: any[];
  readonly restricted: boolean;
  readonly isActive: boolean;
  readonly filter?: string;

  constructor(init: Construct<Template>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkTemplate) {
    return new Template({
      templateId: init.template_id,
      templateName: init.template_name,
      description: init.description,
      dataSource: init.data_source,
      productModuleId: init.product_module_id,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
      updatedAt: moment(init.updated_at),
      fields: init.fields.map(TemplateField.fromNetwork),
      restricted: init.restricted,
      isActive: init.is_active,
      filter: init.filter,
    });
  }

  getActiveExports(scheduledDataExports: ScheduledDataExport[]) {
    return scheduledDataExports.filter(
      (s) => s.templateId !== null && s.templateId === this.templateId,
    );
  }
}
interface NetworkTemplate {
  template_id: string;
  template_name: string;
  description: string;
  data_source: TemplateDataSource;
  product_module_id: string;
  created_at: string;
  created_by: object;
  updated_at: string;
  fields: any[];
  restricted: boolean;
  is_active: boolean;
  filter?: string;
}
