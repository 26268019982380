import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { FeatureApi } from '../../feature-api';
import { Feature } from '../../domain/feature';
import { sentenceCase } from 'sentence-case';
import Select from 'react-select';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  feature: Feature;
}

export const BulkUpdateFeatureModal = (props: Props) => {
  const { isOpen, onClose, feature } = props;

  const execute = async () => {
    const getEnvString = (env: boolean | undefined) => {
      if (env === undefined) {
        return 'unchanged';
      }
      return env ? 'enabled' : 'disabled';
    };

    const envString = `Sandbox will be ${getEnvString(
      sandbox,
    )}\nProduction will be ${getEnvString(production)}`;

    const typedFeatureKey = prompt(
      `You are about to bulk update the ${feature.featureKey} feature flag for all organizations. \n\n${envString} \n\nIf you would like to continue, please confirm by typing ${feature.featureKey}`,
    );

    if (typedFeatureKey !== feature.featureKey) {
      if (!typedFeatureKey) {
        alert('Bulk updating of feature cancelled');
      } else {
        alert(
          'You entered the incorrect feature key. Bulk updating of feature cancelled.',
        );
      }
      return;
    }

    setIsLoading(true);

    try {
      await FeatureApi.bulkUpdateFeatureFlags({
        featureKey: feature.featureKey,
        production: production,
        sandbox: sandbox,
      });
      alert('successfully updated');
    } catch (error) {
      alert('There was an error bulk updating the feature flag');
    }

    setIsLoading(false);
    onClose();
  };

  const [sandbox, setSandbox] = useState<boolean | undefined>(undefined);
  const [production, setProduction] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Bulk Update: {sentenceCase(props.feature.featureKey)}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <span>
            <span style={{ fontWeight: 'bold' }}>Feature Key</span>:{' '}
            <code>{props.feature.featureKey}</code>
          </span>
        </FormGroup>
        <FormGroup>
          <Label>Sandbox</Label>
          <Select
            defaultValue={envOptions[0]}
            name='sandbox'
            options={envOptions}
            onChange={(e: any) => setSandbox(e.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Production</Label>
          <Select
            defaultValue={envOptions[0]}
            name='production'
            options={envOptions}
            onChange={(e: any) => setProduction(e.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={execute}
          color='primary'
          disabled={sandbox === undefined && production === undefined}
        >
          {isLoading ? 'Updating...' : 'Bulk update'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const envOptions: { value: boolean | undefined; label: string }[] = [
  { value: undefined, label: 'Unchanged' },
  { value: true, label: 'Enable' },
  { value: false, label: 'Disable' },
];
