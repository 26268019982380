import { InputBlock } from './input-block';
import { Construct } from '../../../../../../shared/domain/construct';
import { BlockType } from './block-type';

export class InputNumberBlock extends InputBlock {
  readonly type: BlockType.InputNumber;
  readonly min?: number;
  readonly max?: number;
  readonly placeholder?: number;
  readonly defaultValue?: number;

  constructor(init: Construct<InputNumberBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      placeholder: this.placeholder,
      default_value: this.defaultValue,
      min: this.min,
      max: this.max,
    };
  }

  static fromJSON(init: any) {
    return new InputNumberBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      placeholder: init.placeholder,
      defaultValue: init.default_value,
      min: init.min,
      max: init.max,
    });
  }
}
