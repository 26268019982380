/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='42px' height='31px' viewBox='0 0 42 31' version='1.1'>
        <title>Upload</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id='Pages'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-65'
            transform='translate(-434.000000, -263.000000)'
            stroke='#E1EBF0'
            strokeWidth='2'
          >
            <g id='Group' transform='translate(423.000000, 264.000000)'>
              <g id='Upload' transform='translate(12.000000, 0.000000)'>
                <path
                  d='M28.8411413,21.5476871 C32.0107842,21.5744319 34.3740131,21.5930897 34.9018866,21.5930897 C37.5764185,21.5930897 39.7451745,19.4436239 39.7451745,16.7920641 C39.7451745,14.1398419 37.5764185,11.9903761 34.9018866,11.9903761 L34.9018866,11.9903761 C34.7001108,11.9903761 34.5010075,12.0042863 34.305913,12.0294573 C34.3493415,11.6989231 34.3794075,11.3644145 34.3794075,11.0219573 C34.3794075,6.73362393 30.8730514,3.25672222 26.5475657,3.25672222 C24.6781327,3.25672222 22.9630382,3.90851709 21.6160844,4.9928547 C20.0666866,2.28366667 17.1382646,0.450824786 13.7722163,0.450824786 C8.78928879,0.450824786 4.75043165,4.45565385 4.75043165,9.39578205 C4.75043165,9.63225641 4.76245802,9.8660812 4.78049758,10.0979188 C2.17611956,10.6377692 0.219161319,12.9263376 0.219161319,15.6693077 C0.219161319,18.8123632 2.79013275,21.3605897 5.96108659,21.3605897 C6.53676174,21.3605897 8.46399448,21.3746238 11.0715544,21.3957959'
                  id='Stroke-19'
                />
                <polygon
                  id='Stroke-23'
                  strokeLinejoin='round'
                  points='27.0406351 17.3333374 19.9823015 11.0792468 12.923968 17.3333374 15.9667911 17.3333374 15.9667911 28.2713301 23.997812 28.2713301 23.997812 17.3333374'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
