import {
  getProductModuleDefinitionCode,
} from '../../../product-modules-api';
import { ProductModuleDefinition } from '../../../../domain/product-module-definition';

export enum ProductModuleDefinitionIndexStructure {
  CodeId = 'codeId',
}

export const establishState = async (
  productModuleDefinition: ProductModuleDefinition,
  type: ProductModuleDefinitionIndexStructure,
) => {
  const productModuleDefinitionCode = await getProductModuleDefinitionCode(
    productModuleDefinition.productModuleId.toString(),
    productModuleDefinition.productModuleDefinitionId.toString(),
    productModuleDefinition[type].toString(),
  );

  return {
    productModuleDefinitionCode: productModuleDefinitionCode.code,
  };
};
