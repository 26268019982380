import { observable } from 'mobx';
import { ReportApi, ReportFilters } from './report-api';
import { Report } from '../domain/reports';
import { EmailRecipient } from '../../outbound-notifications/domain/email-recipient';
import { ReportType } from '../domain/report-type';

export class ReportViewStore {
  @observable report?: Report;
  @observable isLoading = false;
  @observable isLoadingArchive = false;
  @observable filters: ReportFilters = {};
  @observable organizationId = '';
  @observable reportId = '';

  load = async (params: { organizationId: string; reportId: string }) => {
    const { organizationId, reportId } = params;
    this.organizationId = organizationId;
    this.reportId = reportId;

    this.isLoading = true;
    try {
      const report = await ReportApi.getById({
        organizationId,
        reportId,
      });
      this.report = report;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  };

  clear = () => {
    this.report = undefined;
    this.filters = {};
    this.isLoading = false;
    this.isLoadingArchive = false;
    this.organizationId = '';
    this.reportId = '';
  }

  setReportViewStoreIsLoadingTrue = () => {
    this.isLoading = true;
  }

  archive = async () => {
    this.isLoadingArchive = true;

    try {
      const report = await ReportApi.archive({
        organizationId: this.organizationId,
        reportId: this.reportId,
      });
      this.report = report;
      this.isLoadingArchive = false;
    } catch (error) {
      this.isLoadingArchive = false;
      return error;
    }
  }

  createReport = async (params: { recipients: EmailRecipient[]; organizationId: string; type: ReportType }) => {
    const { recipients, organizationId, type } = params;
    this.isLoading = true;
    try {
      const report = await ReportApi.createReport({
        organizationId,
        recipients,
        type,
      });
      this.report = report;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  }

  addOrUpdateRecipients = async (params: { recipients: EmailRecipient[] }) => {
    const { recipients } = params;
    this.isLoading = true;
    try {
      const report = await ReportApi.updateRecipients({
        organizationId: this.organizationId,
        reportId: this.reportId,
        recipients,
      });
      this.report = report;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  }
}

export const reportViewStore = new ReportViewStore();
