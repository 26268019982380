/* global fetch */

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const waitForReAuth = new Promise((resolve, reject) => {
  const checkToken = setInterval(() => {
    if (window.localStorage.getItem('reauth_status') === 'complete') {
      clearInterval(checkToken);
      window.localStorage.removeItem('reauth_status');
      resolve();
    } else if (window.localStorage.getItem('reauth_status') === 'cancelled') {
      window.localStorage.removeItem('reauth_status');
      reject(new Error('Login required.'));
    }
  }, 500);
});

const requestOTP = (url) =>
  fetch(url + 'accounts/send-otp', {
    // eslint-disable-line no-undef
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + window.localStorage.getItem('read_only_token'),
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      email: window.localStorage.getItem('user_email'),
      user_id: window.localStorage.getItem('user_id'),
    }),
  });

/**
 * Makes authenticated requests to the Root platform, choosing the correct auth method based on the given parameters
 * @param {object} options
 * @param {string} options.path - root platform path, without a leading /
 * @param {string} options.type - request method, default
 * @param {object|array=} options.data - request body
 * @param {function=} options.dispatch - a redux store dispatch function
 * @param {boolean=} options.raw - send back the raw response - used to get headers and such
 * @param {boolean=} options.sandbox - use sandbox environment
 */
const ajax = async ({ path, type, data, dispatch, sandbox, raw }) => {
  const storage = window.localStorage;
  const isSandbox = window.location.search.includes('sandbox=true');
  const loginToken = storage.getItem('login_token');
  const env = runtimeEnv();
  const url =
    sandbox || isSandbox
      ? env.REACT_APP_SANDBOX_API_HOST
      : env.REACT_APP_API_HOST;

  // Check if valid login-token
  if (
    type !== 'GET' &&
    path !== '/accounts/logout' &&
    path !== '/accounts/login'
  ) {
    if (loginToken) {
      const decoded = jwtDecode(loginToken);
      const exp = decoded.exp * 1000;
      if (moment(exp).isBefore(moment())) {
        console.log(
          'LoginToken expired...',
          moment(exp).format(),
          moment().format(),
        );
        storage.setItem('reauth_status', 'pending');

        // Token expired, time to re-auth
        requestOTP(url); // sends SMS if enabled
        dispatch({
          type: 'SHOW_MODAL',
          modalType: 'RE-AUTH',
          modalProps: {},
        });
        return waitForReAuth;
      }
    }
  }

  let token =
    storage.getItem('login_token') || storage.getItem('read_only_token');

  // If admin endpoint, use your admin login_token
  if (path === '/accounts/refresh-login-token' || path.includes('admin')) {
    token = storage.getItem('login_token');
  }

  const options = {
    method: type,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  if (data) {
    options.headers['Content-Type'] = 'application/json; charset=utf-8';
    options.body = JSON.stringify(data);
  }

  // Make the request
  const response = await fetch(url + path.replace(/^\//, ''), options);

  if (raw) {
    return response;
  }

  if (response.status >= 400) {
    const body = await response.json();
    throw new Error(body && JSON.stringify(body.error, null, 2));
  } else if (response.status !== 204) {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.startsWith('application/json')) {
      const body = await response.json();
      return body;
    } else {
      return response.body;
    }
  }
};

export default ajax;
