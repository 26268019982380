export const FormOptions = {
  allowListRules: true,
  allowListNotifications: false,
  showNotifications: false,
  startingGracePeriodRules: 0,
  maxCountGracePeriodRules: 4,
};

export const selectGracePeriodRulesOptions = {
  selectOption1: {
    label: 'Consecutive skipped payments',
    value: 'consecutive_skipped_payments',
  },
  selectOption2: {
    label: 'Skipped payments over policy term',
    value: 'skipped_payments_over_policy_term',
  },
  selectOption3: {
    label: 'Skipped payments within period',
    value: 'skipped_payments_within_period',
  },
  selectOption4: {
    label: 'A period after a missed payment',
    value: 'period_after_missed_payment',
  },
};

export const GracePeriodRulesConfig = {
  lapseAfter: {
    selectGracePeriodRules: [
      {
        label: selectGracePeriodRulesOptions.selectOption1.label,
        value: selectGracePeriodRulesOptions.selectOption1.value,
      },
      {
        label: selectGracePeriodRulesOptions.selectOption2.label,
        value: selectGracePeriodRulesOptions.selectOption2.value,
      },
      {
        label: selectGracePeriodRulesOptions.selectOption3.label,
        value: selectGracePeriodRulesOptions.selectOption3.value,
      },
      {
        label: selectGracePeriodRulesOptions.selectOption4.label,
        value: selectGracePeriodRulesOptions.selectOption4.value,
      },
    ],
    outputPaths: 'lapseAfter',
  },
  skippedPayments: {
    outputPaths: 'skippedPayments',
  },
  applyGracePeriodOf: {
    selectGracePeriodRules: {
      type: [
        {
          label: 'Days',
          value: 'days',
        },
        {
          label: 'Months',
          value: 'months',
        },
      ],
    },
    outputPaths: {
      type: 'period.type',
      value: 'period.value',
    },
  },
};

export const GracePeriodNotificationsConfig = {
  notifyThePolicyholder: {
    selectGracePeriodNotifications: {
      trigger: [
        {
          label: 'Before the grace period expires',
          value: 'before_the_grace_period_expires',
        },
        {
          label: 'On the grace period expiry date',
          value: 'on_the_grace_period_expiry_date',
        },
      ],
      type: [
        {
          label: 'Days',
          value: 'days',
        },
        {
          label: 'Months',
          value: 'months',
        },
      ],
    },
    outputPaths: {
      trigger: 'trigger',
      value: 'period.value',
      type: 'period.type',
    },
    options: {
      form: {
        allowList: true,
      },
      selectGracePeriodNotifications: {
        reducing: true,
      },
      metric: {
        reducing: false,
      },
    },
  },
};
