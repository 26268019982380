import { ajax } from './../../../../helpers';

// may live outside, thinking ...
export const channelCompilers = {
  email: async (mjml: string, mergeVars: any) => {
    try {
      const result = await ajax({
        path: '/insurance/admin/handlebars',
        type: 'post',
        data: [
          {
            content: mjml,
            merge_vars: mergeVars,
            fake_nip: false,
          },
        ],
      });

      const template = result && result.result ? result.result[0] : null;

      const url =
        process.env.NODE_ENV === 'development'
          ? `http://localhost:${process.env.SERVER_PORT ||
              3001}/template-editor/mjml-to-html`
          : '/template-editor/mjml-to-html';
      /* const url = process.env.NODE_ENV === 'development'
       ? 'https://2739c789.ngrok.io/template-editor/mjml-to-html' : '/template-editor/mjml-to-html';*/
      const response = await fetch(url, {
        // eslint-disable-line no-undef
        method: 'POST',
        body: JSON.stringify({ mjml: template, timestamp: Date.now(), mergeVars }),
        headers: { 'content-type': 'application/json' },
      });

      const respJson = await response.json();
      return respJson.html;
    } catch(e) {
      console.log(e);
    }
  },
  sms: async (text: string, mergeVars: any) => {
    try {
      const result = await ajax({
        path: '/insurance/admin/handlebars',
        type: 'post',
        data: [
          {
            content: text,
            merge_vars: mergeVars,
            fake_nip: false,
          },
        ],
      });

      const template = result && result.result ? result.result[0] : null;

      return template;
    } catch(e) {
      console.error(e);
    }
  },
  html: async (html: string, mergeVars: any) => {
    try {
      const result = await ajax({
        path: '/insurance/admin/handlebars',
        type: 'post',
        data: [
          {
            content: html,
            merge_vars: mergeVars,
            fake_nip: false,
          },
        ],
      });

      const template = result && result.result ? result.result[0] : null;

      return template;
    } catch (e) {
      console.error(e);
    }
  },
};
