import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { Icon16PXCopy } from '../../components/icons/icon-16-copy';
import { copyToClipboard } from '../../payments/components/payout-requests/components/table-helpers';

import UpdateUserDetailsModal from './modal-update-user-details';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: false,
      idLoading: false,
      addressLoading: false,
      openUpdateUserModal: false,
      selectedUser: props.selectedUser,
    };
  }

  render() {
    return (
      <div>
        <Card>
          <CardHeader>
            User Details
            <Button
              color='primary'
              className='pull-right'
              onClick={() => this.setState({ openUpdateUserModal: true })}
            >
              Edit user
            </Button>
            <UpdateUserDetailsModal
              open={this.state.openUpdateUserModal}
              close={() => this.setState({ openUpdateUserModal: false })}
              selectedUser={this.state.selectedUser}
            />
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <b>User</b>
                  </td>
                  <td>
                    {this.state.selectedUser.firstName}{' '}
                    {this.state.selectedUser.lastName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>User ID</b>
                  </td>
                  <td>{this.state.selectedUser.id}</td>
                </tr>
                <tr>
                  <td>
                    <b>ID Number</b>
                  </td>
                  <td>{this.state.selectedUser.idNumber}</td>
                </tr>
                <tr>
                  <td>
                    <b>Bank Number</b>
                  </td>
                  <td>{this.state.selectedUser.bankNumber}</td>
                </tr>
                <tr>
                  <td>
                    <b>Email</b>
                  </td>
                  <td>{this.state.selectedUser.email}</td>
                </tr>
                <tr>
                  <td>
                    <b>Cellphone</b>
                  </td>
                  <td>{this.state.selectedUser.cellphone}</td>
                </tr>
                {this.state.selectedUser.activationLink && (
                  <tr>
                    <td>
                      <b>Activation Link</b>
                    </td>
                    <td>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyToClipboard(
                            this.state.selectedUser.activationLink,
                          );
                        }}
                      >
                        {this.state.selectedUser.activationLink}{' '}
                        <Icon16PXCopy />
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const idState = state.userKyc.idState;
  const addressState = state.userKyc.addressState;
  const idPhoto = state.userKyc.idPhoto;
  const addressPhoto = state.userKyc.addressPhoto;
  const user = state.user;
  const selectedUser =
    state.users.filter((u) => u.id === ownProps.params.userId)[0] || {};
  return {
    user,
    selectedUser,
    idState,
    addressState,
    idPhoto,
    addressPhoto,
  };
};

export default connect(mapStateToProps)(UserDetails);
