/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='119.768px'
        height='99px'
        viewBox='0 0 119.768 99'
        enableBackground='new 0 0 119.768 99'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <g>
              <g>
                <path
                  fill='#0E131D'
                  d='M78.48,44.888c-1.65,0-3,1.35-3,3V83.77c0,1.65,1.35,3,3,3h34.908c1.65,0,3-1.35,3-3V47.888
              c0-1.65-1.35-3-3-3H78.48z M107.428,81.132c-1.068,0-1.068,0-1.068,0c-0.9-0.647-2.373-0.647-3.273,0c0,0,0,0-1.068,0
              c-2.057,0-3.725-1.669-3.725-3.725c0-2.059,1.668-3.726,3.725-3.726c1.068,0,1.068,0,1.068,0c0.9,0.647,2.373,0.647,3.273,0
              c0,0,0,0,1.068,0c2.059,0,3.727,1.667,3.727,3.726C111.154,79.463,109.486,81.132,107.428,81.132z'
                />
              </g>
            </g>
            <g>
              <path
                fill='#CAE0FF'
                d='M89.135,94c0,1.65-1.35,3-3,3h-51.99c-1.65,0-3-1.35-3-3V22.076c0-1.65,1.35-3,3-3h51.99
            c1.65,0,3,1.35,3,3V94z'
              />
              <path
                fill='none'
                stroke='#CAE0FF'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M89.135,94c0,1.65-1.35,3-3,3h-51.99
            c-1.65,0-3-1.35-3-3V22.076c0-1.65,1.35-3,3-3h51.99c1.65,0,3,1.35,3,3V94z'
              />
            </g>
            <g>
              <path
                fill='#9DC3ED'
                d='M80.678,49.984c0,1.65-1.35,3-3,3H42.803c-1.65,0-3-1.35-3-3V38.159c0-1.65,1.35-3,3-3h34.875
            c1.65,0,3,1.35,3,3V49.984z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M80.678,49.984c0,1.65-1.35,3-3,3H42.803
            c-1.65,0-3-1.35-3-3V38.159c0-1.65,1.35-3,3-3h34.875c1.65,0,3,1.35,3,3V49.984z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M49.759,63.928c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.957
            c0.55,0,1,0.45,1,1V63.928z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M49.759,63.928
            c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.957c0.55,0,1,0.45,1,1V63.928z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M65.219,63.928c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.956
            c0.55,0,1,0.45,1,1V63.928z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M65.219,63.928
            c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.956c0.55,0,1,0.45,1,1V63.928z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M80.678,63.928c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.955
            c0.551,0,1,0.45,1,1V63.928z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M80.678,63.928
            c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.955c0.551,0,1,0.45,1,1V63.928z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M49.759,76.634c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.957
            c0.55,0,1,0.45,1,1V76.634z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M49.759,76.634
            c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.957c0.55,0,1,0.45,1,1V76.634z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M65.219,76.634c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.956
            c0.55,0,1,0.45,1,1V76.634z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M65.219,76.634
            c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.956c0.55,0,1,0.45,1,1V76.634z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M80.678,76.634c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.955
            c0.551,0,1,0.45,1,1V76.634z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M80.678,76.634
            c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.115c0-0.55,0.45-1,1-1h7.955c0.551,0,1,0.45,1,1V76.634z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M49.759,89.34c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.957
            c0.55,0,1,0.45,1,1V89.34z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M49.759,89.34
            c0,0.55-0.45,1-1,1h-7.957c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.957c0.55,0,1,0.45,1,1V89.34z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M65.219,89.34c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.956
            c0.55,0,1,0.45,1,1V89.34z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M65.219,89.34
            c0,0.55-0.45,1-1,1h-7.956c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.956c0.55,0,1,0.45,1,1V89.34z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M80.678,89.34c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.955
            c0.551,0,1,0.45,1,1V89.34z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M80.678,89.34
            c0,0.55-0.449,1-1,1h-7.955c-0.55,0-1-0.45-1-1v-4.114c0-0.55,0.45-1,1-1h7.955c0.551,0,1,0.45,1,1V89.34z'
              />
            </g>
            <line
              fill='none'
              stroke='#202B40'
              strokeWidth='2'
              strokeLinecap='round'
              strokeMiterlimit='10'
              x1='39.702'
              y1='29.383'
              x2='80.578'
              y2='29.383'
            />
            <g>
              <path
                fill='#FFFFFF'
                stroke='#202B40'
                strokeWidth='2'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                d='M90.369,8.946
            C90.369,5.109,87.258,2,83.422,2H51.095c-3.838,0-6.947,3.109-6.947,6.946c0,0.035,0.005,0.067,0.005,0.101h-0.005v20.336h32.327
            V8.946H90.369z'
              />
              <path
                fill='none'
                stroke='#202B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M76.476,8.946c0-3.837,3.11-6.946,6.946-6.946
            s6.947,3.109,6.947,6.946'
              />
            </g>
          </g>
          <g>
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='22.453'
              y1='9.758'
              x2='26.262'
              y2='13.565'
            />
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='18.854'
              y1='18.449'
              x2='24.063'
              y2='18.449'
            />
            <line
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='31.145'
              y1='6.154'
              x2='31.145'
              y2='11.386'
            />
          </g>
        </g>
      </svg>
    );
  }
}
