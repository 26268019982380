import React from 'react';
import { inject, observer } from 'mobx-react';
import { ReportRunListStore } from '../stores/report-runs-list-store';
import { DomainEntityTable } from '../../../../components/domain/tables/domain-entity-table';
import { ReportRun } from '../domain/report-run';
import { ReportRunCreatedAtColumn } from './utils/report-run-list-columns';
import { PaginationComponent } from '../../../../components/pagination';
interface Props {
  router?: History;
}
interface Injected extends Props {
  reportRunListStore: ReportRunListStore;
}
interface State {
  page: number;
}

@inject('reportRunListStore')
@observer
export class ReportRunListView extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      page: 0,
    };

    const { reportRunListStore } = this.injected;
    reportRunListStore.setReportRunListStoreIsLoadingTrue();
  }

  async componentDidMount() {
    const { reportRunListStore } = this.injected;
    const reportId = window.location.pathname.split('/')[4];
    const organizationId = window.location.pathname.split('/')[2];

    await reportRunListStore.load({
      organizationId,
      reportId,
    });
  }

  get injected() {
    return this.props as Injected;
  }

  renderEmpty() {
    return (
      <>
      <h3>Report runs</h3>
      <hr />
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>This report has not been run yet!</p>
        </div>
        <img style={{ width: 300 }} src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
      </>
    );
  }

  render() {
    const { reportRunListStore } = this.injected;
    const organizationId = window.location.pathname.split('/')[2];
    const reportId = window.location.pathname.split('/')[4];
    const { reportRuns, isLoading, isEmpty, total } = reportRunListStore;
    const { page } = this.state;

    return (
      <>
      {!isLoading && isEmpty && this.renderEmpty()}
      {!isLoading && !isEmpty &&
        <>
          <h3>Report runs</h3>
          <br />
          <ReportRunListTable
            rows={reportRuns}
            columns={[
              ReportRunCreatedAtColumn,
            ]}
            isLoaded={true}
          />
          <PaginationComponent
            page={page || 0}
            limit={10}
            total={total || 0}
            disabled={false}
            goToPage={page => {
              this.setState(
                {
                  page,
                },
                async () => reportRunListStore.goToPage({ organizationId, pageNumber: page, reportId }),
              );
            }}
          />
        </>}
      </>
    );
  }
}
@observer
class ReportRunListTable extends DomainEntityTable<ReportRun>{}
