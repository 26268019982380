import React from 'react';
import { Modal, ModalBody, Input } from 'reactstrap';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import { DomainEntityTable } from '../../../../components/domain/tables/domain-entity-table';
import { PolicyIssuingFlowStore } from '../stores/policy-issuing-flow-store';
import { inject, observer } from 'mobx-react';
import LoadingInPage from '../../../loading';
import { PaginationComponent } from '../../../../components/pagination';
import { ProductModuleOrganizationStore } from '../stores/product-module-organization-store';
import { debounce } from '../../../../helpers/debounce';
import { ProductModuleStore } from '../stores/product-module-store';
import { OrganizationProductModule } from '../../../../organizations/domain/organization-product-module';

interface Props {
  onClose: () => void;
  show: boolean;
  productModuleId: string;
  productModuleDefinitionId: string;
  policyIssuingFlowStore?: PolicyIssuingFlowStore;
  productModuleOrganizationStore?: ProductModuleOrganizationStore;
  productModuleStore?: ProductModuleStore;
}

interface State {
  isLoaded: boolean;
  organizationProductModules: any[];
}

interface OrganizationProductModulesColumns {
  organizationId: string;
  name: string;
  sandboxEnabled: boolean;
}
@inject('policyIssuingFlowStore')
@inject('productModuleOrganizationStore')
@inject('productModuleStore')
@observer
class SelectOrganizationModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoaded: true,
      organizationProductModules: [],
    };
  }

  fetchEnabledOrganizations = async (organizationNameFilter?: string) => {
    const {
      productModuleId,
      policyIssuingFlowStore,
      productModuleOrganizationStore,
    } = this.props;
    if (policyIssuingFlowStore && productModuleOrganizationStore) {
      await productModuleOrganizationStore.getAllOrganizations();
      const organizationProductModules: any = await productModuleOrganizationStore.organizations.map(
        async organization => {
          let orgProductModules = await policyIssuingFlowStore.getOrgProductModules(
            organization.organizationId,
          );
          orgProductModules = orgProductModules.map(OrganizationProductModule.fromNetwork);
          if (orgProductModules) {
            const productModuleInOrg: OrganizationProductModule = orgProductModules.find(
              (productModule: OrganizationProductModule) =>
                productModule.productModuleId.toString() === productModuleId,
            );
            const sandboxEnabled = productModuleInOrg.sandbox;
            if (productModuleInOrg) {
              return {
                ...organization,
                sandboxEnabled,
              };
            }
          }
        },
      );
      Promise.all(organizationProductModules).then(organizationProductModules =>
        this.setState({ organizationProductModules }),
      );
    }
  };

  async componentDidMount() {
    await this.fetchEnabledOrganizations();
  }

  filterByOrganizationName = async () => {
    await debounce(
      'filterProductModuleCodeOrganizations',
      async () => await this.fetchEnabledOrganizations(),
      300,
    );
  };

  closeModal = async() => {
    const { productModuleOrganizationStore } = this.props;
    if (productModuleOrganizationStore) {
      productModuleOrganizationStore.setOrganizationName('');
    }
    await this.fetchEnabledOrganizations();
    this.props.onClose();
  };

  render() {
    const {
      policyIssuingFlowStore,
      productModuleOrganizationStore,
      productModuleStore,
    } = this.props;
    if (
      !policyIssuingFlowStore ||
      !productModuleOrganizationStore ||
      !productModuleStore
    ) {
      return <LoadingInPage />;
    }

    const OrganizationNameCellElement: React.SFC<
    OrganizationProductModulesColumns
    > = organization => {
      if (policyIssuingFlowStore) {
        return (
          <div
            onClick={() => {
              policyIssuingFlowStore.setOrganization({
                organizationId: organization.organizationId,
                organizationName: organization.name,
                productModuleDefinitionId: productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
              });
              this.closeModal();
            }}
            className='table-cell-single'
          >
            {organization.name}
          </div>
        );
      }
      return <div>Loading ...</div>;
    };

    const OrganizationIdCellElement: React.SFC<
    OrganizationProductModulesColumns
    > = organization => {
      if (policyIssuingFlowStore) {
        return (
          <div
            onClick={() => {
              policyIssuingFlowStore.setOrganization({
                organizationId: organization.organizationId,
                organizationName: organization.name,
                productModuleDefinitionId: productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
              });
              this.closeModal();
            }}
            className='table-cell-single'
          >
            {organization.organizationId}
          </div>
        );
      }
      return <div>Loading ...</div>;
    };

    const OrganizationSandboxEnabledCellElement: React.SFC<
    OrganizationProductModulesColumns
    > = organization => {
      if (policyIssuingFlowStore) {
        return (
          <div
            onClick={() => {
              policyIssuingFlowStore.setOrganization({
                organizationId: organization.organizationId,
                organizationName: organization.name,
                productModuleDefinitionId: productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
              });
              this.closeModal();
            }}
            className='table-cell-single'
          >
            {organization.sandboxEnabled.toString()}
          </div>
        );
      }
      return <div>Loading ...</div>;
    };

    const OrganizationName = {
      heading: 'productName',
      element: OrganizationNameCellElement,
    };

    const OrganizationId = {
      heading: 'Org Id',
      element: OrganizationIdCellElement,
    };

    const OrganizationSandboxEnabled = {
      heading: 'Sandbox enabled',
      element: OrganizationSandboxEnabledCellElement,
    };

    return (
      <Modal isOpen={this.props.show} size='lg'>
        <div className='organizations-select-modal-wrapper'>
          <div style={{ float: 'left' }}>
            <div className='organizations-modal-header'>
              Select Organization
            </div>
          </div>
          <div className='organizations-modal-close-button'>
            <Icon24PXCross onClick={() => this.closeModal()} />
          </div>
        </div>
        <ModalBody>
          <div className='organizations-modal-search-input-wrapper'>
            <Input
              type='text'
              placeholder='search'
              onChange={e => {
                productModuleOrganizationStore.setOrganizationName(
                  e.target.value,
                );
                debounce(
                  'filterByOrganizationName',
                  () => this.filterByOrganizationName(),
                  500,
                );
              }}
            />
          </div>
          <DomainEntityTable
            rows={this.state.organizationProductModules}
            columns={[
              OrganizationName,
              OrganizationId,
              OrganizationSandboxEnabled,
            ]}
            isLoaded={this.state.isLoaded}
          />
          <div className='organizations-modal-pagging-component-wrapper'>
            <PaginationComponent
              page={productModuleOrganizationStore.page}
              limit={productModuleOrganizationStore.pageSize}
              total={
                productModuleOrganizationStore.totalOrganizationRowsCount || 0
              }
              disabled={false}
              goToPage={page => {
                productModuleOrganizationStore.setActivePage(page);
                this.fetchEnabledOrganizations();
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default SelectOrganizationModal;
