import * as React from 'react';
import { observer } from 'mobx-react';
import '../styles/text-input/styles.scss';

@observer
export class InjectObject extends React.Component<any, any> {
  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, {});
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, {});
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  componentDidMount() {
    const { keyVal, formStore } = this.props;
    formStore.updateValidTotalDirect(keyVal, true);
  }

  render() {
    return null;
  }
}
