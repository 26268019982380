import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { observer, inject } from 'mobx-react';
import { DataExportViewStore } from '../../stores/data-export-view-store';

interface Props {
  open: boolean;
  close: () => void;
}

interface Injected extends Props {
  dataExportViewStore: DataExportViewStore;
}

interface State {}

@inject('dataExportViewStore')
@observer
export class ResumeDataExportModal extends React.Component<Props, State> {

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    const { dataExportViewStore } = this.injected;
    return (
      <Modal isOpen={this.props.open} toggle={this.props.close} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={this.props.close} />
          <h4 className='exports-prompt-modal-header-text'>Resume export</h4>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <p>
            Are you sure you want to resume the{' '}
            <strong>
              {dataExportViewStore.dataExport && dataExportViewStore.dataExport.name}
            </strong>{' '}
            export?
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button
            color='primary'
            className='vertical'
            onClick={this.resumeDataExport}
          >
            Resume export
          </Button>
          <Button color='link' className='vertical' onClick={this.props.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  resumeDataExport = async () => {
    const { dataExportViewStore } = this.injected;
    const result = await dataExportViewStore.resume({ organizationId: this.organizationId });

    if (result && result.error) {
      return console.warn(result.error);
    }
    this.props.close();
  };
}
