import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';
import { Moment } from 'moment';

export interface Payee {
  amount: string;
  type: string;
  policyholderId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Moment;
  identification: {
    type: string;
    number: string;
    country: string;
  };
  percentage: string;
  cellphone: string;
  email: string;
  paymentDetails: {
    type: string;
    details: {
      bankName: string;
      branchCode: string;
      accountType: string;
      accountNumber: string;
    };
  };
}

const payeeToJSON = (payee: Payee) => {
  return {
    amount: payee.amount,
    type: payee.type,
    policyholder_id: payee.policyholderId,
    first_name: payee.firstName,
    last_name: payee.lastName,
    date_of_birth: payee.dateOfBirth,
    identification: payee.identification,
    percentage: payee.percentage,
    cellphone: payee.cellphone,
    email: payee.email,
    payment_details: payee.paymentDetails ? {
      type: payee.paymentDetails.type,
      details: {
        bank_name: payee.paymentDetails.details.bankName,
        branch_code: payee.paymentDetails.details.branchCode,
        account_type: payee.paymentDetails.details.accountType,
        account_number: payee.paymentDetails.details.accountNumber,
      },
    } : undefined,
  };
};

const payeeFromJSON = (init: any) => {
  return {
    amount: init.amount,
    type: init.type,
    policyholderId: init.policyholder_id,
    firstName: init.first_name,
    lastName: init.last_name,
    dateOfBirth: init.date_of_birth,
    identification: init.identification,
    percentage: init.percentage,
    cellphone: init.cellphone,
    email: init.email,
    paymentDetails: {
      type: init.payment_details.type,
      details: {
        bankName: init.payment_details.details.bank_name,
        branchCode: init.payment_details.details.branch_code,
        accountType: init.payment_details.details.account_type,
        accountNumber: init.payment_details.details.account_number,
      },
    },
  };
};

export class PayoutRequestBlock extends Block {
  readonly title: string;
  readonly description?: string;
  readonly amount: number;
  readonly payee: Payee;

  constructor(init: Construct<PayoutRequestBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      amount: this.amount,
      payee: this.payee && payeeToJSON(this.payee),
    };
  }

  static fromJSON(init: any) {
    return new PayoutRequestBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      amount: init.amount,
      payee: init.payee && payeeFromJSON(init.payee),
    });
  }
}
