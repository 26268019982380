import * as React from 'react';
import { PayoutRequest, PayoutRequestStatus } from '../domain/payout-request';
import { OrganizationDisplay } from './organization-info';
import { UUID } from '../../../../shared/domain/uuid';
import { Icon16PXCopy } from '../../../../components/icons/icon-16-copy';
import { LinkedEntities } from '../../../../shared/domain/linked-entities';
import { Button } from 'reactstrap';
import { startCase } from 'lodash';
import { Icon16PXInProgress } from '../../../../components/icons/icon-16-px-in-progress';
import { Icon16PXCheck } from '../../../../components/icons/icon-16-px-success';
import { Icon16PXCross } from '../../../../components/icons/icon-16-px-cross';

export const FinalisePayout = (props: {
  payoutRequest: PayoutRequest;
  onConfirm: (payoutRequest: PayoutRequest) => void;
  onReject: (payoutRequest: PayoutRequest) => void;
}) => {
  return props.payoutRequest.status === PayoutRequestStatus.Pending ? (
    <div>
      <Button
        color='success'
        outline
        style={{ margin: '0 4px' }}
        onClick={() => props.onConfirm(props.payoutRequest)}
      >
        Confirm
      </Button>
      <Button
        color='danger'
        outline
        style={{ margin: '0 4px' }}
        onClick={() => props.onReject(props.payoutRequest)}
      >
        Reject
      </Button>
    </div>
  ) : (
    <div />
  );
};

export const PolicyLink = (props: {
  policyId?: UUID;
  linkedEntities?: LinkedEntities;
}) => {
  if (props.policyId) {
    return (
      <>
        {
          <a
            target='_'
            href={`/policies/${props.policyId.toString()}/overview`}
          >
            {props.policyId.toString().split('-')[0]}...
          </a>
        }
      </>
    );
  }

  if (props.linkedEntities) {
    return (
      <>
        {props.linkedEntities && props.linkedEntities.policyId && (
          <a
            target='_'
            href={`/policies/${props.linkedEntities.policyId.toString()}/overview`}
          >
            {props.linkedEntities.policyId.toString().split('-')[0]}...
          </a>
        )}
      </>
    );
  }

  return <>Policy not found</>;
};

export const FormatAmount = (props: { amount: number; currency?: string }) => (
  <>
    {(props.amount / 100).toLocaleString('en-ZA', {
      style: 'currency',
      currency: props.currency || 'ZAR',
    })}
  </>
);

export const OrganizationLink = (props: { organizationId: UUID }) => (
  <OrganizationDisplay organizationId={props.organizationId} />
);

export const ShortId = (props: { id: UUID }) => {
  return (
    <>
      <a
        href='#'
        onClick={() => copyToClipboard(props.id.toString())}
        className='domain-entity-table-hover-link'
      >
        {props.id.toString().split('-')[0]}...
        <div className='domain-entity-table-hover-icon'>
          <Icon16PXCopy />
        </div>
      </a>
    </>
  );
};

export const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  window.alert('Copied to clipboard');
};

export const PayoutRequestStatusDisplay = (props: {
  status: PayoutRequestStatus;
  rejectionReason?: string;
}) => {
  switch (props.status) {
    case PayoutRequestStatus.Completed:
      return (
        <>
          <Icon16PXCheck style={{ marginRight: 8 }} />
          {startCase(props.status)}
        </>
      );
    case PayoutRequestStatus.Pending:
      return (
        <>
          <Icon16PXInProgress style={{ marginRight: 8 }} />
          {startCase(props.status)}
        </>
      );
    case PayoutRequestStatus.Rejected:
      return (
        <div className='domain-entity-table-tooltip-icon-wrapper'>
          <Icon16PXCross
            fill='red'
            className='domain-entity-table-tooltip-trigger'
            style={{ marginRight: 8 }}
          />
          {props.rejectionReason && (
            <div className='domain-entity-table-tooltip'>
              <div className='domain-entity-table-tooltip-header'>Reason</div>
              <div className='domain-entity-table-tooltip-body'>
                {props.rejectionReason}
              </div>
            </div>
          )}
          {startCase(props.status)}
        </div>
      );
    default:
      return <div />;
  }
};
