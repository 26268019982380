export interface Role {
  roleId: string;
  organizationId: string;
  name: string;
  permissions: string[];
  description: string;
  hasLiveAccess: boolean;
  hasSandboxAccess: boolean;
  userCount?: number;
}

export interface NetworkRole {
  role_id: string;
  organization_id: string;
  name: string;
  description: string;
  permissions: string[];
  has_live_access: boolean;
  has_sandbox_access: boolean;
  user_count?: number;
}

export const fromNetwork = (init: NetworkRole): Role => ({
  roleId: init.role_id,
  organizationId: init.organization_id,
  name: init.name,
  permissions: init.permissions,
  description: init.description,
  hasLiveAccess: init.has_live_access,
  hasSandboxAccess: init.has_sandbox_access,
  userCount: init.user_count,
});
