import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import ProductModuleJavascript from '../../product-module-javascript';
import { ProductModuleDefinitionIndexStructure } from '../../product-module-javascript/orchestration';
import LoadingInPage from '../../../../loading';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionCode extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  async componentDidMount() {
    const { productModuleKey, productModuleStore } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
  }

  renderFullScreenModal = () => {
    return (
      <ProductModuleJavascript
        type={ProductModuleDefinitionIndexStructure.CodeId}
        toDbKey={'code_string'}
        breadcrumbCodeName={'Product module code'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  render() {
    const { productModuleStore } = this.props;

    if (!productModuleStore || !productModuleStore.isLoaded) {
      return <LoadingInPage />;
    }
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Product module code
              </p>
            </Col>
            <Col xs={9}>
              <span>
                The product module code is where the quote and application logic and validation is
                written for a module. Through this window the quote, application
                and policy issuing endpoints can be tested.
              </span>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top ' />
      </div>
    );
  }
}
