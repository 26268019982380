import React from 'react';
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  CardText,
  Button,
} from 'reactstrap';
import { renderValue, withData, toSentenceCase } from '../../../helpers';
import CardEntry from '../../../components/card-entry';

class PolicyholderOverview extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading policyholder...</i>
        </div>
      );
    }

    return (
      <Container style={{ padding: 0 }}>
        <Card>
          <CardHeader style={{ display: 'flex', alignItems: 'center' }}>
            Policyholder details
            <Button style={{ marginLeft: 'auto' }}>Edit</Button>
          </CardHeader>
          <CardBody>
            {Object.keys(this.props.policyholder).map((key, index) => (
              <CardEntry key={key} index={index}>
                <div style={{ padding: '2px' }}>
                  <b>{toSentenceCase(key)}</b>
                </div>
                <CardText className='overview-value'>
                  {renderValue(key, this.props.policyholder[key])}
                </CardText>
              </CardEntry>
            ))}
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withData({
  path: (props) =>
    `/insurance/admin/policyholders/${props.params.policyholderId}`,
  prop: 'policyholder',
})(PolicyholderOverview);
