import React from 'react';
import { CardBody, Row, Col, Button, FormGroup } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { ProductModuleDefinitionScheduledFunctionStore } from '../../../stores/product-module-definition-scheduled-function-store';
import { ScheduledFunctionPreview } from './preview';
import { ScheduledFunctionsEdit, EditScheduledFunction } from './edit';
import { SavingState } from '../../../util';
import { Icon16PXTick } from '../../../../../../components/icons/icon-16-px-tick';

interface Injected {
  productModuleStore: ProductModuleStore;
  productModuleDefinitionScheduledFunctionStore: ProductModuleDefinitionScheduledFunctionStore;
}

interface Props {}

interface State {
  isEditing: boolean;
  savingState: SavingState;
}

@inject('productModuleStore', 'productModuleDefinitionScheduledFunctionStore')
@observer
export class ProductModuleScheduledFunctions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false,
      savingState: SavingState.Default,
    };

    this.injected.productModuleDefinitionScheduledFunctionStore.setLoading();
  }

  componentDidMount() {
    const { productModuleDefinitionDraft } = this.injected.productModuleStore;
    const { productModuleId, productModuleDefinitionId } = productModuleDefinitionDraft;

    this.injected.productModuleDefinitionScheduledFunctionStore.init({
      productModuleId,
      productModuleDefinitionId,
    });
  }

  get injected() {
    return this.props as Injected;
  }

  onSave = async (newScheduledFunctions: EditScheduledFunction[]) => {
    let { productModuleDefinitionDraft } = this.injected.productModuleStore;

    this.setState({ isEditing: false, savingState: SavingState.Saving });
    this.injected.productModuleDefinitionScheduledFunctionStore.isLoading = true;

    await this.injected.productModuleStore.createProductModuleDefinition({
      productModuleId: productModuleDefinitionDraft.productModuleId,
      data: {
        scheduled_functions: newScheduledFunctions.map(s => ({
          function_name: s.functionName,
          policy_statuses: s.policyStatuses,
          frequency: s.frequency.toNetwork(),
        })),
      },
    });

    productModuleDefinitionDraft = this.injected.productModuleStore.productModuleDefinitionDraft;
    const { productModuleId, productModuleDefinitionId } = productModuleDefinitionDraft;

    await this.injected.productModuleDefinitionScheduledFunctionStore.init({
      productModuleId,
      productModuleDefinitionId,
    });

    this.setState({ savingState: SavingState.Saved });

    setTimeout(() => this.setState({ savingState: SavingState.Default }), 2000);
  }

  onEditClick = () => {
    this.setState({ isEditing: true });
  }

  onCancel = () => {
    this.setState({ isEditing: false });
  }

  render() {
    const { productModuleDefinitionScheduledFunctions, isLoading, isEmpty } = this.injected.productModuleDefinitionScheduledFunctionStore;
    const { lockedComponent } = this.injected.productModuleStore;

    return <div>
      <CardBody className='product-module-definition-horizontal-card'>
        <Row>
          <Col xs={3}>
            <p className='product-module-definition-key-header'>
              Scheduled functions
            </p>
          </Col>
          <Col xs={9}>
            <p>Configure product module functions to run on a given schedule to perform recurring, time-based actions against policies.</p>
            <Row>
              <Col>
                <CardBody
                  className='white-background'
                  style={{ marginLeft: -17, marginBottom: 0, paddingBottom: 0 }}
                >
                  {isEmpty && !this.state.isEditing && <div>No functions have been scheduled for this definition.</div>}
                  {!isEmpty && !this.state.isEditing && <ScheduledFunctionPreview
                    scheduledFunctions={productModuleDefinitionScheduledFunctions || []}
                    isLoading={isLoading}
                  />}
                  {this.state.isEditing && !lockedComponent && <ScheduledFunctionsEdit
                    scheduledFunctions={productModuleDefinitionScheduledFunctions || []}
                    onCancel={this.onCancel}
                    onSave={this.onSave}
                  />}
                </CardBody>
              </Col>
            </Row>
            {!this.state.isEditing && !lockedComponent && <FormGroup className='product-modules-form-group-align'>
              <Button
                outline
                color='primary'
                disabled={isLoading}
                onClick={this.onEditClick}
              >
                Edit
              </Button>
              {this.state.savingState === SavingState.Saving && (
                <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                  Saving
                </div>
              )}
              {this.state.savingState === SavingState.Saved && (
                <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                  <Icon16PXTick
                    style={{ marginRight: 5, paddingBottom: 3 }}
                  />
                  Saved
                </div>
              )}
            </FormGroup>}
          </Col>
        </Row>
      </CardBody>
      <hr className='hr-with-no-margin-bottom-top' />
    </div>;
  }
}
