import { ajax } from '../../../helpers';

export enum PolicyStatus {
  PendingInitialPayment = 'pending_initial_payment',
  NotTakenUp = 'not_taken_up',
  Cancelled = 'cancelled',
  Active = 'active',
  Lapsed = 'lapsed',
  Expired = 'expired',
}

export interface PolicyholderFilters {
  status?: PolicyStatus[];
  id_number?: string;
  createdBy?: string;
  policyholderId?: string;
};

export const getPolicyholders = async (params: {
  filters?: PolicyholderFilters;
  page: number;
  pageSize: number;
  environment: string;
  organizationId: string;
}) => {
  const { organizationId, environment } = params;
  const query: [string, string | number][] = [];

  if (params.filters) {
    if (params.filters.id_number) {
      query.push(['id_number', params.filters.id_number]);
    }

    if (params.filters.policyholderId) {
      query.push(['policyholder_id', params.filters.policyholderId]);
    }

    if (params.filters.status && params.filters.status.length !== 0) {
      query.push(['status', params.filters.status.join(',')]);
    }

    if (params.filters.createdBy) {
      query.push(['created_by', params.filters.createdBy]);
    }
  }

  query.push(
    ['page', params.page],
    ['page_size', params.pageSize],
  );

  const queryString = query.map(([key, value]) => `${key}=${value}`).join('&');


  const total = await ajax({
    type: 'HEAD',
    path: `/apps/${organizationId}/insurance/policyholders?${queryString}`,
    raw: true,
    sandbox: environment === 'sandbox',
  });

  const response = await ajax({
    type: 'GET',
    path: `/apps/${organizationId}/insurance/policyholders?${queryString}`,
    sandbox: environment === 'sandbox',
  });

  const rowCount = total.headers.get('X-Total-Count');

  return {
    response,
    rowCount,
  };
};
