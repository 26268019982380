import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { observer } from 'mobx-react';
import { BlockState } from '../domain/states/block-state';
import { DividerBlock as Block } from '../domain/divider-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { DividerBlockState } from '../domain/states/divider-block-state';

interface Props {
  block: Block;
  blockState: DividerBlockState;
}

interface State {
  UIState: BlockUIState;
  dividerBlockState?: BlockState;
}

@observer
export class DividerBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      dividerBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { dividerBlockState } = this.state;
    if (!dividerBlockState) {
      return null;
    }

    return (<hr style={{ height: '2px', color: '#f6f6f6' }}/>);
  }
}
