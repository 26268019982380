import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export class AnnuityRequestBlockState extends BlockState {
  readonly annuityRequestId?: string;

  constructor(init: Construct<AnnuityRequestBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      annuity_request_id: this.annuityRequestId,
    };
  }

  static fromJSON(init: any) {
    return new AnnuityRequestBlockState({
      type: init.type,
      annuityRequestId: init.annuity_request_id,
    });
  }
}
