import React from 'react';
import { Container, Card, CardBody, Button } from 'reactstrap';
import {
  ajax,
  toSentenceCase,
  renderValue,
  toCamelCaseKeys,
} from '../../../helpers';
import CardEntry from '../../../components/card-entry';
import { LedgerEntry } from '../../domain/ledger-entry';
import { LedgerEntriesTable } from './ledger-entries-table';
import { PaginationComponent } from '../../../components/pagination';
import AddLedgerEntryModal from './modals/add-ledger-entry-modal';

interface Props {
  loading: boolean;
  params: {
    policyId: string;
  };
}

interface State {
  policy?: Policy;
  ledgerEntries?: LedgerEntry[];
  loading: boolean;
  total: number;
  page: number;
  pageSize: number;
  showAddLedgerEntryModal: boolean;
}

interface Policy {
  [key: string]: any;
  module: {
    type: string;
  };
}

export class PolicyLedger extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      total: 0,
      page: 0,
      pageSize: 10,
      showAddLedgerEntryModal: false,
    };
  }

  componentDidMount = () => {
    this.fetchPolicy();
    this.fetchPolicyLedgerEntries();
  };

  fetchPolicy = async () => {
    const { policyId } = this.props.params;

    const policy = await ajax({
      type: 'GET',
      path: `/insurance/admin/policies/${policyId}`,
    });

    this.setState({ policy });
  };

  fetchPolicyLedgerEntries = async () => {
    this.setState({ loading: true });

    const { policyId } = this.props.params;
    const resourceUrl = `/insurance/admin/policies/${policyId}/ledger`;

    const total = (await ajax({
      type: 'HEAD',
      path: resourceUrl,
      raw: true,
    }));

    const pagination = `&page=${
      this.state.page + 1
    }&page_size=${this.state.pageSize}`;

    const ledgerEntries = (await ajax({
      type: 'GET',
      path: `${resourceUrl}?include=payment${pagination}`,
    })).map(toCamelCaseKeys) as LedgerEntry[];

    this.setState({ ledgerEntries, total: total.headers.get('X-Total-Count'), loading: false });

    // Refresh policy to ensure balance matches new ledger entries
    this.fetchPolicy();
  }

  renderPolicySummary = () => {
    const { policy } = this.state;

    if (!policy) {
      return 'Loading policy...';
    }

    return (
      <div>
        {[
          'status',
          'billing_day',
          'monthly_premium',
          'balance',
          'start_date',
          'end_date',
        ].map((key, index) => (
          <CardEntry key={key} index={index}>
            <div style={{ padding: '2px' }}>
              <b>{toSentenceCase(key)}</b>
            </div>
            <div style={{ width: '70%', padding: '2px' }}>
              {renderValue(key, policy[key])}
            </div>
          </CardEntry>
        ))}
        <CardEntry index={6}>
          <div style={{ padding: '2px' }}>
            <b>{'Module'}</b>
          </div>
          <div style={{ width: '70%', padding: '2px' }}>
            {policy.module.type}
          </div>
        </CardEntry>
      </div>
    );
  };

  render() {
    const { ledgerEntries, policy, page, pageSize, total } = this.state;
    if (this.props.loading) {
      return (
        <div>
          <i>Loading ledger entries...</i>
        </div>
      );
    }

    return (
      <div>
        <Container style={{ padding: 0 }}>
          <Card>
            <CardBody>
              <h5>Policy summary</h5>
              {policy && this.renderPolicySummary()}
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  <h5>Ledger entries</h5>
                </span>
                <span>
                  <Button
                    color='primary'
                    onClick={() => this.setState({ showAddLedgerEntryModal: true })}
                  >
                    Add ledger entry
                  </Button>
                </span>
              </div>
              <div>
                {!ledgerEntries || ledgerEntries.length === 0 ? (
                  <p>This policy has no ledger entries</p>
                ) : (
                  <div>
                    <LedgerEntriesTable
                      ledgerEntries={ledgerEntries}
                      refresh={async () => await this.fetchPolicyLedgerEntries()}
                    />
                    <PaginationComponent
                      page={page || 0}
                      limit={pageSize}
                      total={total || 0}
                      disabled={false}
                      goToPage={page => {
                        this.setState({
                          page,
                        }, async () => await this.fetchPolicyLedgerEntries());
                      }}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>

          <AddLedgerEntryModal
            onClose={() => this.setState({ showAddLedgerEntryModal: false })}
            onLedgerEntryAdded={async () => await this.fetchPolicyLedgerEntries()}
            show={this.state.showAddLedgerEntryModal}
            policy={this.state.policy}
          />
        </Container>
      </div>
    );
  }
}
