import React from 'react';
import { observer } from 'mobx-react';
import { TextInput } from './components/text-input';
import { SelectInput } from './components/select-input';
import { DatePicker } from './components/date-picker';
import './styles/datepicker/datepicker.scss';
import BlankSpace from './components/blank-space';
import FormList from './components/list';
import { Button } from 'reactstrap';
import SectionHeader from './components/section-header';
import ToggleSwitch from './components/toggle';
import uuid from 'uuid';
import { FormStore } from '../formStore';
import Radio from './components/radio';
import './styles/parent/styles.scss';
import {
  FormStoreModelList,
  DisplayConditions,
  ComponentProps,
  Options,
  Validator,
  BaseStoreModel,
} from './interfaces';
import HorizontalLine from './components/horizontal-line';
import Paragraph from './components/paragraph';
import CheckboxInput from './components/check-box-input';
import { CurrencyInput } from './components/currency-input';
import { NumberInput } from './components/number-input';
import { InjectObject } from './components/inject-object';

interface Props {
  onSubmit: (model: FormStoreModelList[]) => void;
  formStore: FormStore;
  className: string;
}

export interface FilterOptions {
  value: string;
  label: string;
}

interface SelectInputInterface {
  value: string;
  label: string;
}

interface State {}

@observer
export class DynamicForm extends React.Component<Props, State> {
  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (this.props.onSubmit) {
      this.props.formStore.stateModel();
      console.log(
        'nested state output for schema -',
        JSON.stringify(this.props.formStore.nestedState),
      );
    }
  };

  onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const targetValue = e.target.value;
    this.props.formStore.updateInputState(key, targetValue);
  };

  onSelectInputChange = (value: SelectInputInterface, key: string) => {
    this.props.formStore.updateInputState(key, null);
    this.props.formStore.updateInputState(key, value.value);
  };

  onDateInputChange = (value: string, key: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onToggleInputChange = (key: string, checked: boolean) => {
    this.props.formStore.updateInputState(key, checked);
  };

  onRadioInputChange = (key: string, value: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onCheckboxInputChange = (value: boolean, key: string) => {
    this.props.formStore.updateInputState(key, value);
  };

  onNumberInputChange = (targetValue: number, key: string) => {
    this.props.formStore.updateInputState(key, targetValue);
  };

  onCurrencyInputChange = (targetValue: number, key: string) => {
    this.props.formStore.updateInputState(key, targetValue);
  };

  onArrayInputChange = (targetValue: number, key: string) => {
    this.props.formStore.updateInputState(key, targetValue);
  };

  displayConditionsCheck = (val: DisplayConditions) => {
    const { inputState } = this.props.formStore;
    if (val.condition === '===') {
      if (inputState[val.path] !== val.value) {
        if (val.and) {
          const conditionResult = val.and.every(this.displayConditionsCheck);
          if (!conditionResult) {
            return false;
          }
        }
        if (val.or) {
          const conditionResult = val.or.every(this.displayConditionsCheck);
          if (conditionResult) {
            return true;
          }
        }
        return false;
      }
      if (val.and) {
        const conditionResult = val.and.every(this.displayConditionsCheck);
        if (!conditionResult) {
          return false;
        }
      }
    }
    if (val.condition === '!==') {
      if (inputState[val.path] === val.value) {
        if (val.and) {
          const conditionResult = val.and.every(this.displayConditionsCheck);
          if (!conditionResult) {
            return false;
          }
        }
        if (val.or) {
          const conditionResult = val.or.every(this.displayConditionsCheck);
          if (conditionResult) {
            return true;
          }
        }
        return false;
      }
      if (val.and) {
        const conditionResult = val.and.every(this.displayConditionsCheck);
        if (!conditionResult) {
          return false;
        }
      }
    }
    return true;
  };

  optionsCompleted = (options: Options[], index: number, checkType: string) => {
    if (checkType === 'value') {
      return options && options[index] && options[index].value
        ? options[index].value
        : false;
    }
    if (checkType === 'label') {
      return options && options[index] && options[index].label
        ? options[index].label
        : 'not specified';
    }
  };

  componentTypeFilter = (
    type: string,
    key: string | string[],
    label: string,
    props: ComponentProps,
    options: Options[],
    validators: Validator[],
    components: FormStoreModelList[] | BaseStoreModel[], //need these for nesting lists within lists
    parentKey: string,
    maxNumber: number | null,
    defaultValue: string | null,
  ) => {
    switch (type) {
      case 'select':
        if (!Array.isArray(key))
          return (
            <SelectInput
              key={key}
              keyVal={key}
              validators={validators}
              formStore={this.props.formStore}
              type={type}
              options={options}
              label={label}
              componentProps={props}
              onChange={(val: SelectInputInterface) =>
                this.onSelectInputChange(val, key)
              }
            />
          );
      case 'country':
        if (!Array.isArray(key))
          return (
            <SelectInput
              key={key}
              keyVal={key}
              validators={validators}
              formStore={this.props.formStore}
              type={type}
              options={options}
              label={label}
              componentProps={props}
              onChange={(val: SelectInputInterface) =>
                this.onSelectInputChange(val, key)
              }
            />
          );
      case 'date-picker':
        if (!Array.isArray(key))
          return (
            <DatePicker
              key={key}
              keyVal={key}
              validators={validators}
              formStore={this.props.formStore}
              label={label}
              options={options}
              componentProps={props}
              onChange={(val: string) => this.onDateInputChange(val, key)}
            />
          );
      case 'blank-space':
        return (
          <BlankSpace keyVal={key} key={`blank-space-${uuid().toString()}`} />
        );
      case 'horizontal-line':
        return (
          <HorizontalLine
            keyVal={key}
            key={`blank-space-${uuid().toString()}`}
          />
        );

      case 'paragraph':
        return (
          <Paragraph
            keyVal={key}
            componentProps={props}
            key={`paragraph-${uuid().toString()}`}
            label={label}
          />
        );
      case 'section-header':
        return (
          <SectionHeader
            title={label}
            keyVal={key}
            componentProps={props}
            key={`section-header-${uuid().toString()}`}
          />
        );
      case 'checkbox':
        if (!Array.isArray(key))
          return (
            <CheckboxInput
              key={key}
              validators={validators}
              formStore={this.props.formStore}
              keyVal={key}
              type={type}
              label={label}
              componentProps={props}
              defaultValue={defaultValue}
              onCheckboxInputChange={(e: boolean) =>
                this.onCheckboxInputChange(e, key)
              }
            />
          );
      case 'toggle':
        if (!Array.isArray(key))
          return (
            <ToggleSwitch
              keyVal={key}
              key={key}
              label={label}
              id={'environment-toggle'}
              leftText={this.optionsCompleted(options, 0, 'label')}
              rightText={this.optionsCompleted(options, 1, 'label')}
              value1={this.optionsCompleted(options, 0, 'value')}
              value2={this.optionsCompleted(options, 1, 'value')}
              onToggleInputChange={(checked: boolean) =>
                this.onToggleInputChange(key, checked)
              }
            />
          );
      case 'radio':
        if (!Array.isArray(key))
          return (
            <Radio
              key={key}
              keyVal={key}
              defaultValue={defaultValue}
              options={options}
              label={label}
              formStore={this.props.formStore}
              onRadioInputChange={(value: string) =>
                this.onRadioInputChange(key, value)
              }
            />
          );
      case 'list':
        if (components && this.props.formStore && Array.isArray(key)) {
          return (
            <FormList
              components={components}
              formStore={this.props.formStore}
              parentKey={key}
              parentKeyLookup={parentKey}
              maxNumber={maxNumber || 1}
              componentProps={props}
              key={`form-list-${uuid().toString()}`}
            />
          );
        } else {
          console.log('not completed form list inputs');
        }
      case 'text':
        if (!Array.isArray(key))
          return (
            <TextInput
              key={key}
              validators={validators}
              formStore={this.props.formStore}
              keyVal={key}
              type={type}
              label={label}
              componentProps={props}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.onTextInputChange(e, key)
              }
            />
          );
      case 'number':
        if (!Array.isArray(key))
          return (
            <NumberInput
              key={key}
              validators={validators}
              formStore={this.props.formStore}
              keyVal={key}
              type={type}
              label={label}
              componentProps={props}
              onChange={(targetValue: number) =>
                this.onNumberInputChange(targetValue, key)
              }
            />
          );
      case 'currency':
        if (!Array.isArray(key))
          return (
            <CurrencyInput
              key={key}
              validators={validators}
              formStore={this.props.formStore}
              keyVal={key}
              type={type}
              label={label}
              componentProps={props}
              onChange={(targetValue: number) =>
                this.onCurrencyInputChange(targetValue, key)
              }
            />
          );
      case 'object':
        if (!Array.isArray(key))
          return (
            <InjectObject
              key={key}
              validators={validators}
              formStore={this.props.formStore}
              keyVal={key}
              type={type}
              label={label}
              componentProps={props}
            />
          );
      default:
        console.log(`no type specified for ${key}`);
        return;
    }
  };

  renderForm = (formStore: FormStore) => {
    const model = formStore.modelTransformed;
    const renderedKeys: any = {};
    try {
      const formUI = model.map(m => {
        let key = m.key;
        const type = m.type;
        const props = m.props || {};
        const label = m.label;
        const options = m.options || [];
        const validators = m.validators || [];
        const displayConditions = m.displayConditions || null;
        const components = m.components || null;
        const parentKey = m.parentKey || '';
        const maxNumber = m.maxNumber || null;
        const defaultValue = m.defaultValue || null;

        if (!Array.isArray(key)) {
          if (renderedKeys[key]) {
            key = uuid().toString();
          } else {
            Object.assign(renderedKeys, { [key]: key });
          }
          this.props.formStore.updateComponentValid(key, validators);
        }

        if (formStore.inputState !== {} && displayConditions) {
          let display;
          try {
            display = displayConditions.every(this.displayConditionsCheck);
            if (type === 'list') {
              formStore.updateModelChildrenHoldState({ [parentKey]: display });
            }
          } catch (err) {
            console.log(
              `error in display conditions for key ${key} error ${err}`,
            );
            display = true;
          }
          if (props && props.hiddenComponent) {
            return;
          }
          if (display) {
            return this.componentTypeFilter(
              type,
              key,
              label,
              props,
              options,
              validators,
              components,
              parentKey,
              maxNumber,
              defaultValue,
            );
          }
        } else {
          if (props && props.hiddenComponent) {
            return;
          }
          return this.componentTypeFilter(
            type,
            key,
            label,
            props,
            options,
            validators,
            components,
            parentKey,
            maxNumber,
            defaultValue,
          );
        }
        return null;
      });

      return formUI;
    } catch (err) {}
  };

  render() {
    if (!this.props.formStore) {
      return <div />;
    }

    return (
      <div className='parent-form-oute' key='formParent'>
        <form
          className='dynamic-form row'
          style={{ backgroundColor: 'white' }}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            this.onSubmit(e);
          }}
        >
          {this.renderForm(this.props.formStore)}
          <div
            className='form-group col-sm-12'
            style={{ paddingLeft: 1, backgroundColor: 'white' }}
          >
            <Button onClick={e => this.onSubmit(e)}>Submit</Button>
          </div>
        </form>
      </div>
    );
  }
}

export default DynamicForm;
