import React from 'react';
import { CardBody, Row, Col, Button, FormGroup, Alert } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { AlterationHooksPreview } from './preview';
import { ProductModuleDefinitionAlterationHooksEdit, ProductModuleDefinitionAlterationHookFormData } from './edit';
import { SavingState } from '../../../util';
import { Icon16PXTick } from '../../../../../../components/icons/icon-16-px-tick';
import { ProductModuleDefinitionAlterationHooksListStore } from '../../../stores/product-module-definition-alteration-hooks-list-store';
import { ProductModuleDefinitionAlterationHook } from '../../../../../domain/product-module-definition-alteration-hooks';
import _ from 'lodash';

interface Injected {
  productModuleStore: ProductModuleStore;
  productModuleDefinitionAlterationHooksListStore: ProductModuleDefinitionAlterationHooksListStore;
}

interface Props {}

interface State {
  error?: any;
  isEditing: boolean;
  savingState: SavingState;
  productModuleDefinitionAlterationHooks: ProductModuleDefinitionAlterationHook[];
}

@inject('productModuleStore', 'productModuleDefinitionAlterationHooksListStore')
@observer
export class ProductModuleDefinitionAlterationHooks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { productModuleDefinitionAlterationHooks } = this.injected.productModuleDefinitionAlterationHooksListStore;

    this.state = {
      isEditing: false,
      savingState: SavingState.Default,
      productModuleDefinitionAlterationHooks,
    };

    this.injected.productModuleDefinitionAlterationHooksListStore.setLoading({ isLoading: true });
  }

  async componentDidMount() {
    const { productModuleDefinitionDraft } = this.injected.productModuleStore;
    const { productModuleId, productModuleDefinitionId } = productModuleDefinitionDraft;
    this.setState({ error: undefined });

    try {
      const productModuleDefinitionAlterationHooks = await this.injected.productModuleDefinitionAlterationHooksListStore.init({
        productModuleId,
        productModuleDefinitionId,
      });

      this.setState({ productModuleDefinitionAlterationHooks });
    } catch (error) {
      this.setState({ error });
    }
  }

  get injected() {
    return this.props as Injected;
  }

  onSave = async (productModuleDefinitionAlterationHookFormData: ProductModuleDefinitionAlterationHookFormData[]) => {
    let { productModuleDefinitionDraft } = this.injected.productModuleStore;

    this.setState({ isEditing: false, savingState: SavingState.Saving, error: undefined });
    this.injected.productModuleDefinitionAlterationHooksListStore.setLoading({ isLoading: true });

    try {
      await this.injected.productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleDefinitionDraft.productModuleId,
        data: {
          alteration_hooks: productModuleDefinitionAlterationHookFormData.map(({ name, key, schema }) => ({
            key: _.snakeCase(key),
            name,
            schema_json: schema,
          })),
        },
      });

      productModuleDefinitionDraft = this.injected.productModuleStore.productModuleDefinitionDraft;
      const { productModuleId, productModuleDefinitionId } = productModuleDefinitionDraft;

      await this.injected.productModuleDefinitionAlterationHooksListStore.init({
        productModuleId,
        productModuleDefinitionId,
      });

      this.setState({ savingState: SavingState.Saved });

      setTimeout(() => this.setState({ savingState: SavingState.Default }), 2000);
    } catch (error) {
      this.setState({ error });
    }
  }

  onEditClick = () => {
    this.setState({ isEditing: true });
  }

  onCancel = () => {
    this.setState({ isEditing: false });
  }

  render() {
    const { isLoading, isEmpty } = this.injected.productModuleDefinitionAlterationHooksListStore;
    const { productModuleDefinitionAlterationHooks } = this.state;
    const { lockedComponent } = this.injected.productModuleStore;

    return <div>
      <CardBody className='product-module-definition-horizontal-card'>
        <Row>
          <Col xs={3}>
            <p className='product-module-definition-key-header'>
              Alteration hooks
            </p>
          </Col>
          <Col xs={9}>
            <p>
              Alteration hooks are used to edit and amend policies.
              They allow for updating flexible unstructured policies through a simple structured interface with minimal required fields.
              Alteration hooks also enable altering a policy without having to capture all the information for a policy again.
              An example usecase is discounts, when you only want to ask the percentage discount or for updating items listed on a policy.</p>
            <Row>
              <Col>
                <CardBody
                  className='white-background'
                  style={{ marginLeft: -17, marginBottom: 0, paddingBottom: 0 }}
                >
                  {this.state.error && <Alert color='danger'>{this.state.error.message}</Alert>}
                  {isEmpty && !this.state.isEditing && <div>No alteration hooks have been created for this definition.</div>}
                  {!isEmpty && !this.state.isEditing &&
                    <AlterationHooksPreview
                      productModuleKey={this.injected.productModuleStore.productModule.key}
                      isLoading={isLoading}
                    />}
                  {this.state.isEditing && !lockedComponent &&
                    <ProductModuleDefinitionAlterationHooksEdit
                      onCancel={this.onCancel}
                      onSave={this.onSave}
                    />}
                </CardBody>
              </Col>
            </Row>
            {!this.state.isEditing && !lockedComponent && <FormGroup className='product-modules-form-group-align'>
              <Button
                outline
                color='primary'
                disabled={isLoading}
                onClick={this.onEditClick}
              >
                {productModuleDefinitionAlterationHooks.length === 0 ? 'Add   +' : 'Edit hooks'}
              </Button>
              {this.state.savingState === SavingState.Saving && (
                <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                  Saving
                </div>
              )}
              {this.state.savingState === SavingState.Saved && (
                <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                  <Icon16PXTick
                    style={{ marginRight: 5, paddingBottom: 3 }}
                  />
                  Saved
                </div>
              )}
            </FormGroup>}
          </Col>
        </Row>
      </CardBody>
      <hr className='hr-with-no-margin-bottom-top' />
    </div>;
  }
}
