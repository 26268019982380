import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { ProductModuleDefinitionSchemaIndexStructure } from '../../product-module-json-to-html/orchestration';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import { ProductModuleJsonHtml } from '../../product-module-json-to-html';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
}
interface State {
  showSchema: boolean;
}
interface Injected extends Props {
  productModuleStore: ProductModuleStore;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionApplicationSchema extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  async componentWillMount() {
    const { productModuleKey, productModuleStore } = this.injected;
    await productModuleStore.init(productModuleKey);
  }

  get injected() {
    return this.props as Injected;
  }

  renderFullScreenModal = () => {
    const { productModuleStore } = this.injected;
    const productModuleDefinitionSchemaId = productModuleStore.productModuleDefinitionDraft[ProductModuleDefinitionSchemaIndexStructure.applicationSchemaId];
    return (
      <ProductModuleJsonHtml
        productModuleDefinitionSchemaId={productModuleDefinitionSchemaId}
        toDbKey={'application_schema_json'}
        breadcrumbDocName={'Application schema form'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showSchema: !this.state.showSchema })
        }
      />
    );
  };

  render() {
    const { productModuleStore } = this.props;

    if (!productModuleStore) {
      return <LoadingInPage />;
    }
    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Application schema
              </p>
            </Col>
            <Col xs={9}>
              <p>Application schema</p>
              <span>
                The application input model defines what data is used to request
                an application. Use this json schema editor to define the input.
                You can read about input models and view examples in the
                documentation.
              </span>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                  onClick={() =>
                    this.setState({ showSchema: !this.state.showSchema })
                  }
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showSchema && this.renderFullScreenModal()}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top ' />
      </div>
    );
  }
}
