import React from 'react';
import { toCamelCaseKeys } from '../../helpers';
import { Input, Table, Row, Col } from 'reactstrap';
import PageHeader from '../../components/page-header';
import ToggleSwitch from '../../components/toggle-switch';
import { PaginationComponent } from '../../components/pagination';
import { History } from 'history';
import { getPolicyholders } from './policyholder/policyholder-api';
import { debounce } from '../../helpers/debounce';

interface Props {
  params: {
    organizationId: string;
  };
  router: History;
}

interface State {
  searchValue: string;
  environment: string;
  policyholders: any;
  loading: boolean;
  page: number;
  pageSize: number;
  total: number;
  error?: any;
}

const PolicyholderSearchBox = (params: {
  searchValue: string;
  handleSearchChanged: (event: any) => void;
}) => (
  <div className='pull-right'>
    <Input
      name='search'
      placeholder='Search'
      type='text'
      value={params.searchValue}
      onChange={params.handleSearchChanged}
    />
  </div>
);

const PolicyTableHeadings = () => (
  <thead>
    <tr>
      <th>First name</th>
      <th>Last name</th>
      <th>Email</th>
      <th>ID / Passport number</th>
    </tr>
  </thead>
);

class Policyholders extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      environment: 'live',
      policyholders: [],
      loading: false,
      page: 0,
      pageSize: 10,
      total: 0,
      error: undefined,
    };
  }

  componentDidMount() {
    this.fetchPolicyholders();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.environment !== this.state.environment) {
      this.setState({ policyholders: [] });
      this.fetchPolicyholders();
    }
  }


  async fetchPolicyholders() {
    this.setState({ loading: true });

    const { page, pageSize, environment, searchValue } = this.state;

    try {
      const policyholders = await getPolicyholders({
        page: page + 1,
        pageSize,
        environment,
        organizationId: this.props.params.organizationId,
        filters: {
          id_number: searchValue,
        },
      });

      this.setState({
        policyholders: policyholders.response.map(toCamelCaseKeys),
        loading: false,
        total: policyholders.rowCount,
      });
    } catch (error) {
      console.dir(error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearchChanged = (event: any) => {
    this.setState({
      searchValue: event.target.value,
      page: 0,
    });
    debounce(
      'filterByPolicyholder',
      async () =>
        await this.fetchPolicyholders(),
      500,
    );
  };

  get policyholdersToShow() {
    const { policyholders } = this.state;

    return policyholders && policyholders.length > 0 ? policyholders : [];
  }

  renderPolicyholderRow = (policyholder: any) => (
    <tr
      className = 'table-row-pointer'
      key={policyholder.policyholderId}
      onClick={() =>
        this.props.router.push(
          `/organizations/${this.props.params.organizationId}/policyholders/${
            policyholder.policyholderId
          }?sandbox=${this.state.environment === 'sandbox'}`,
        )
      }
    >
      <td>{policyholder.firstName}</td>
      <td>{policyholder.lastName}</td>
      <td>{policyholder.email}</td>
      <td>{policyholder.id ? policyholder.id.number : '-'}</td>
    </tr>
  );

  renderSearchInput = () => {
    return (
      <PolicyholderSearchBox
        searchValue={this.state.searchValue}
        handleSearchChanged={this.handleSearchChanged}
      />
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <i>Loading policyholders...</i>
        </div>
      );
    }

    const { total, page, pageSize } = this.state;

    return (
      <div>
        <PageHeader
          title='Policyholders'
          rightComponent={this.renderSearchInput()}
        >
          <Row>
            <Col
              xs={12}
              className='toggle-status-spacer'
              style={{ paddingLeft: 0 }}
            >
              <ToggleSwitch
                id={'environment-toggle'}
                leftText={'LIVE'}
                checked={this.state.environment === 'sandbox'}
                onChange={() => {
                  this.setState(currentState => ({
                    environment:
                      currentState.environment === 'live' ? 'sandbox' : 'live',
                  }));
                }}
                rightText={'TEST'}
              />
              <em className='status'>
                {this.state.loading
                  ? 'Loading...'
                  : this.state.error
                    ? this.state.error
                    : `Viewing ${this.policyholdersToShow.length} ${
                      this.policyholdersToShow.length === 1
                        ? 'policyholder'
                        : 'policyholders'
                    } from the ${this.state.environment} environment.`}
              </em>
            </Col>
          </Row>
        </PageHeader>
        <Table hover>
          <PolicyTableHeadings />
          <tbody>
            {this.policyholdersToShow
              .map(this.renderPolicyholderRow)}
          </tbody>
        </Table>
        <PaginationComponent
          page={page || 0}
          limit={pageSize}
          total={total || 0}
          disabled={false}
          goToPage={page => {
            this.setState(
              {
                page,
              },
              async () => await this.fetchPolicyholders(),
            );
          }}
        />
      </div>
    );
  }
}

export default Policyholders;
