export default (state = [], action) => {
  switch (action.type) {
    case 'SET_API_KEYS':
      return action.apiKeys;
    case 'NEW_API_KEY':
      return [...state, action.apiKey];
    case 'DELETED_API_KEY':
      return state.filter(k => k.id !== action.apiKeyId);
    default:
      return state;
  }
};
