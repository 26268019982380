import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import {
  typesOfCoveredPeople,
} from '../config/covered-people';
import { TypesOfPolicyholdersCondition } from '../config/product-module-definition-settings-policyholder';
import '../../../../../styles/styles.scss';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import LoadingInPage from '../../../../../loading';
import { ComponentType } from '../../product-module-definitions-billing/components/billing';
import ProductModuleSettingsCheckbox from '../checkbox';
import ProductModuleSettingsTextInput from '../text-input';
import _ from 'lodash';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfPolicyholdersCondition;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
}

interface State {}
@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionSettingsCoveredPeople extends React.Component<
Props,
State
> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(typesOfCoveredPeople).map(checkboxDescription => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleSettingsComponents,
          checkboxDescription,
        );
      });
    }
  }

  renderPolicyholderSettings = (
    params: Params,
    componentType: ComponentType,
  ) => {
    if (componentType === ComponentType.Checkbox) {
      return <ProductModuleSettingsCheckbox params={params} />;
    }
    if (
      componentType === ComponentType.Text ||
      componentType === ComponentType.Number
    ) {
      return (
        <ProductModuleSettingsTextInput
          params={params}
          componentType={componentType}
        />
      );
    }
  };

  showCoveredPeople = () => {
    const { productModuleStore } = this.props;
    if (productModuleStore) {
      const { productModuleDefinitionDraft } = productModuleStore;
      const { settings } = productModuleDefinitionDraft;
      return !!_.get(settings, 'coveredPeople.enabled', false);
    }
    return false;
  };

  render() {
    const {
      productModuleComponentLoadingStateStore,
      productModuleStore,
    } = this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Covered people
              </p>
            </Col>
            <Col xs={9}>
              <p>Defining covered people</p>
              <span>
                A covered person is any individual covered under the policy, other than the main policyholder. For example, children, spouse, extended family etc.
              </span>
            </Col>
          </Row>

          {this.renderPolicyholderSettings(
            {
              ...typesOfCoveredPeople.enableCoveredPeople,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.showCoveredPeople() &&
            this.renderPolicyholderSettings(
              {
                ...typesOfCoveredPeople.maximumNumberOfCoveredPeople,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                },
              },
              ComponentType.Number,
            )}

          {this.showCoveredPeople() &&
            this.renderPolicyholderSettings(
              {
                ...typesOfCoveredPeople.minimumNumberOfCoveredPeople,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                },
              },
              ComponentType.Number,
            )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
