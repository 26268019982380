import { Moment, utc as moment } from 'moment';
import { ScheduledFunctionFrequency, ScheduledFunctionFrequencyFactory, Daily, Weekly, Monthly, Yearly } from './scheduled-function-frequency';
import { ProductModuleDefinition } from './product-module-definition';
import { Requestee } from '../../shared/domain/requestee';
import { Construct } from '../../shared/domain/construct';
import { intToOrdinalNumberString } from '../../shared/helpers/numbers';
import { monthToNumber } from '../../shared/domain/month';
import { PolicyStatus } from '../../policies/domain/policy-status';

export class ProductModuleDefinitionScheduledFunction {
  readonly id: string;
  readonly productModuleDefinitionId: string;
  readonly frequency: ScheduledFunctionFrequency;
  readonly functionName: string;
  readonly policyStatuses: PolicyStatus[];
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly productModuleDefinition?: ProductModuleDefinition;

  constructor(init: Construct<ProductModuleDefinitionScheduledFunction>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionScheduledFunction): ProductModuleDefinitionScheduledFunction {
    return new ProductModuleDefinitionScheduledFunction({
      id: init.product_module_definition_scheduled_function_id,
      productModuleDefinitionId: init.product_module_definition_id,
      frequency: ScheduledFunctionFrequencyFactory.fromNetwork(init.frequency),
      functionName: init.function_name,
      policyStatuses: init.policy_statuses,
      createdAt: moment(init.created_at).local(),
      createdBy: Requestee.fromJSON(init.created_by),
    });
  }

  prettyFrequency() {
    const timeOfDay = this.frequency.timeOfDay;

    if (this.frequency instanceof Daily) {
      return `Daily at ${timeOfDay}`;
    } else if (this.frequency instanceof Weekly) {
      return `Every ${moment().day(this.frequency.dayOfWeek).format('dddd')} at ${timeOfDay}`;
    } else if (this.frequency instanceof Monthly) {
      return `On the ${intToOrdinalNumberString(this.frequency.dayOfMonth)} of every month at ${timeOfDay}`;
    } else if (this.frequency instanceof Yearly) {
      return `On the ${intToOrdinalNumberString(this.frequency.dayOfMonth)} of ${moment().month(monthToNumber(this.frequency.monthOfYear)).format('MMMM')} every year at ${timeOfDay}`;
    }

    return '';
  }
}

export interface NetworkProductModuleDefinitionScheduledFunction {
  product_module_definition_scheduled_function_id: string;
  product_module_definition_id: string;
  frequency: object;
  function_name: string;
  policy_statuses: PolicyStatus[];
  created_at: string;
  created_by: object;
}
