import { observable, action } from 'mobx';
import {
  // getAllProductModuleDefinitions,
  getProductModules,
} from '../../product-modules-api';
import { ProductModuleDefinition } from '../../../domain/product-module-definition';
import { ProductModule } from '../../../domain/product-module';
export class ProductModuleListStore {
  @observable isLoaded = false;
  @observable.deep productModules: ProductModule[];
  @observable.deep productModuleDefinitions: ProductModuleDefinition[];
  @observable productModuleId = '';
  @observable pageSize: number = 10;
  @observable page: number = 0;
  @observable totalProductModuleRowsCount: number = 0;
  @observable productModuleName: string = '';

  init = async () => {
    if (!this.isLoaded) {
      try {
        await this.getProductModules();
      } catch (error) {
        return error;
      } finally {
        this.isLoaded = false;
      }
    }
  };

  @action setPage = (page: number) => {
    this.page = page;
  };

  @action setProductModuleName = (productModuleName: string) => {
    this.productModuleName = productModuleName;
  };

  @action getProductModules = async () => {
    try {
      const { response, rowCount } = await getProductModules({
        productModuleName: this.productModuleName,
        page: this.page,
        pageSize: this.pageSize,
      });
      this.productModules = response;
      this.totalProductModuleRowsCount = rowCount;
    } catch (error) {
      return error;
    }
  };

  setProductModuleListStoreIsLoading = () => {
    this.isLoaded = false;
  }
}

export const productModuleListStore = new ProductModuleListStore();
