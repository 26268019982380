export default (state = [], action) => {
  switch (action.type) {
    case 'SET_SHEET_INTEGRATIONS':
      return action.sheets;
    case 'NEW_SHEET_INTEGRATION':
      return [...state, action.sheet];
    case 'DELETED_SHEET_INTEGRATION':
      return state.filter(s => s.id !== action.sheetIntegrationId);
    default:
      return state;
  }
};
