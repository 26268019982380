import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Col,
} from 'reactstrap';
import ReactDOM from 'react-dom';
import CodeMirror from 'react-codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/3024-night.css';
import { CheckboxInput } from '../../../organizations/components/outbound-notifications/forms/inputs/checkbox-input';
import { Icon24PXCross } from '../../../components/icons/icon-24-px-cross';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import { OrganizationListStore } from '../../../organizations/stores/organization-list-store';

interface SelectOptions {
  label: string;
  value: string;
}

export class EditProductModule extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  render() {
    return (
      <Modal isOpen={this.props.show} toggle={this.props.closeEditModal}>
        <ModalHeader toggle={this.props.onClose}>
          Edit product module
        </ModalHeader>
        <ModalBody>
          A draft branch of the live product module will be created. You will be
          able to edit and save changes before submitting to review, and the
          live product module will remain active.
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.props.createDraft} color='primary'>
            Create draft
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export class RevertDraftModal extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  render() {
    return (
      <Modal isOpen={this.props.show} toggle={this.props.closeArchiveModal}>
        <ModalHeader toggle={this.props.onClose}>Revert draft</ModalHeader>
        <ModalBody>
          Are you sure you want to revert the current draft to the Published
          module&#39;s data?
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.props.revertDraft} color='primary'>
            Revert draft
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export class SubmitForReviewModal extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.closeSubmitForReviewModal}
      >
        <ModalHeader toggle={this.props.onClose}>Submit for review</ModalHeader>
        <ModalBody>
          Are you sure you’re ready to submit this version for review?
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.props.submitForReview} color='primary'>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export class RejectReviewModal extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.closeRejectReviewModal}
      >
        <ModalHeader toggle={this.props.onClose}>Decline review</ModalHeader>
        <ModalBody>
          Are you sure you would like to reject this product module?
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.props.rejectReview} color='primary'>
            Decline
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export class ApproveToPublish extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  get errorCancelButtonText() {
    return this.props.error ? 'Ok' : 'Cancel';
  }

  render() {
    return (
      <Modal isOpen={this.props.show} toggle={this.props.closeApproveToPublish}>
        <ModalHeader toggle={this.props.onClose}>
          Approve for publish
        </ModalHeader>
        <ModalBody>
          Are you sure you’re ready to publish this version of the product
          module?
          {this.props.error && (
            <div style={{ color: 'red' }}>
              {<br />}
              {this.props.error}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            {this.errorCancelButtonText}
          </Button>
          {!this.props.error && (
            <Button onClick={this.props.submitApproveToPublish} color='primary'>
              Publish
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export interface ProductModuleData {
  name: string;
  key: string;
  restricted: boolean;
  ownedByOrganizationId?: string;
}
interface NewProductModuleProps {
  isLoading: boolean;
  errorModalText: string;
  newProductModule: (params: {
    name: string;
    key: string;
    restricted: boolean;
  }) => void;
  onClose: () => void;
  show: boolean;
  cloneModuleKey?: string;
}

interface NewProductModuleState {
  errorModalText: string;
  isLoading: boolean;
  restricted: boolean;
  ownedByOrganizationId?: string;
}

interface Injected extends NewProductModuleProps {
  organizationListStore: OrganizationListStore;
}

@inject('organizationListStore')
@observer
export class NewProductModule extends React.Component<
NewProductModuleProps,
NewProductModuleState
> {
  constructor(props: NewProductModuleProps) {
    super(props);
    this.state = {
      errorModalText: '',
      isLoading: false,
      restricted: true,
    };
  }

  componentWillReceiveProps(nextProps: NewProductModuleProps) {
    this.setState({
      errorModalText: nextProps.errorModalText,
      isLoading: nextProps.isLoading,
    });
  }

  get injected() {
    return this.props as Injected;
  }

  createProductModule() {
    const name = (ReactDOM as any).findDOMNode(this.refs.name).value;
    const key = (ReactDOM as any).findDOMNode(this.refs.key).value;
    const productModuleData: ProductModuleData = {
      name,
      key,
      restricted: this.state.restricted,
      ownedByOrganizationId: this.state.ownedByOrganizationId,
    };
    if (productModuleData.name.length === 0) {
      this.setState({ errorModalText: 'Please enter a Name for the module' });
    } else if (productModuleData.key.length === 0) {
      this.setState({ errorModalText: 'Please enter a key for the module' });
    } else if (productModuleData.key.toString().includes(' ')) {
      this.setState({
        errorModalText: 'Please remove all spaces from key or replace with "_"',
      });
    } else if (!productModuleData.ownedByOrganizationId) {
      this.setState({ errorModalText: 'Owned by organization ID is required' });
    } else {
      this.props.newProductModule(productModuleData);
    }
  }

  get organizationDropdownValues() {
    const { organizationListStore } = this.injected;
    const { organizations } = organizationListStore;

    return organizations.map((organization) => ({
      label: organization.productName,
      value: organization.id,
    }));
  }

  render() {
    return (
      <Modal isOpen={this.props.show} toggle={() => this.props.onClose()}>
        <ModalHeader toggle={this.props.onClose}>
          New product module
        </ModalHeader>
        <ModalBody>
          <form onSubmit={this.createProductModule.bind(this)}>
            <FormGroup>
              <Label style={{ fontWeight: 'bold' }}>Name</Label>
              <Input type='text' placeholder='Root funeral' ref='name' />
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: 'bold' }}>Key</Label>
              <Input
                type='text'
                placeholder='root_funeral'
                ref='key'
                onKeyPress={() => this.setState({ errorModalText: '' })}
              />

            </FormGroup>
            {this.props.cloneModuleKey && (
              <FormGroup>
                <Label style={{ fontWeight: 'bold' }}>Cloning module</Label>
                <Input
                  type='text'
                  ref='module-key'
                  value={this.props.cloneModuleKey}
                  disabled={true}
                />
              </FormGroup>
            )}
            <FormGroup row>
              <Label sm={12}>
                <strong>Owned by organization id</strong>
              </Label>
              <Col sm={12}>
                <Select
                  options={this.organizationDropdownValues}
                  defaultValue={undefined}
                  onChange={(value: SelectOptions) =>
                    this.setState({
                      ownedByOrganizationId: value.value,
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup style={{ display: 'flex' }}>
              <div style={{ marginTop: -24, marginBottom: -10 }}>
                <CheckboxInput
                  identifier='attachments'
                  defaultValue={['restricted']}
                  onChange={() =>
                    this.setState({
                      restricted: !this.state.restricted,
                    })
                  }
                  options={[
                    {
                      value: 'restricted',
                      label: 'Private',
                    },
                  ]}
                />
              </div>
            </FormGroup>

          </form>
          <p style={{ color: 'red', paddingTop: 5 }}>
            {this.state.errorModalText}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button onClick={this.createProductModule.bind(this)} color='primary'>
            {this.state.isLoading ? 'Creating...' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export class GeneralModal extends React.Component<any, any> {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  render() {
    return (
      <div
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            //mapping for the enter key pressed
            this.props.submitModal();
          }
        }}
      >
        <Modal isOpen={this.props.show} toggle={this.props.closeModal}>
          <ModalHeader toggle={this.props.onClose}>
            {this.props.modalContent.title}
          </ModalHeader>
          <ModalBody>{this.props.modalContent.body}</ModalBody>
          <ModalFooter>
            {this.props.modalContent.cancel && (
              <Button color='secondary' onClick={this.props.onClose}>
                {this.props.modalContent.cancel}
              </Button>
            )}
            <Button onClick={this.props.submitModal} color='primary'>
              {this.props.modalContent.submit}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export class MergeVarsModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      editorKey: 1,
      mergeVars: this.props.mergeVars,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({ mergeVars: nextProps.mergeVars });
  }

  render() {
    return (
      <div className='template-editor-white'>
        <CodeMirror
          key={this.state.editorKey}
          options={{
            mode: 'javascript',
            lineNumbers: true,
            lineWrapping: true,
          }}
          value={JSON.stringify(this.state.mergeVars, null, 8)}
          onChange={(val) => this.props.handleMergeVarsChange(JSON.parse(val))}
          className='merge-vars-code-window'
        />
      </div>
    );
  }
}

export class SaveAndCloseModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Modal isOpen={this.props.show} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={() => this.props.onclose()} />
          <div style={{ fontSize: 22, textAlign: 'center' }}>
            Save lifecycle rules.
          </div>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <p>
            Would you like to save the changes on the policy lifecycle rules
            before exiting?
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button
            color='primary'
            onClick={() => {
              this.props.submitModal(true);
              this.props.onclose();
            }}
          >
            Save
          </Button>
          <Button color='link' onClick={() => this.props.submitModal(false)}>
            Discard changes
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
