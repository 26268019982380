/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='80px' height='30px' viewBox='0 0 80 30' version='1.1'>
        <defs />
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-57'
            transform='translate(-988.000000, -302.000000)'
          >
            <g id='can-edit' transform='translate(988.000000, 302.289062)'>
              <rect
                id='Rectangle-8-Copy'
                fill='#FFFFFF'
                x='-3.55271368e-13'
                y='0'
                width='80'
                height='29.2105263'
                rx='3'
              />
              <text
                fontFamily='ProximaNova-Semibold, Proxima Nova'
                fontSize='13'
                fontWeight='500'
                fill='#202B40'
              >
                <tspan x='9' y='19'>
                  can edit
                </tspan>
              </text>
              <polygon
                id='Triangle'
                stroke='#202B40'
                fill='#202B40'
                transform='translate(67.000000, 15.653320) scale(1, -1) translate(-67.000000, -15.653320) '
                points='67 14 70 17.3066406 64 17.3066406'
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
