import React, { Component } from 'react';
import { Button, Input, Table, Alert } from 'reactstrap';
import PageHeader from '../../components/page-header';
import moment from 'moment';

const PublicAssetSearchBox = ({ searchValue, handleSearchChanged }) => (
  <div className='pull-right' style={{ display: 'flex', flexDirection: 'row' }}>
    <Input
      name='search'
      placeholder='Search'
      type='text'
      value={searchValue}
      onChange={handleSearchChanged}
    />
    <Button style={{ marginLeft: 11 }} color='primary'>
      Upload
    </Button>
  </div>
);

export default class PublicAssets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };
  }
  renderSearchAndUpload = () => {
    return (
      <div>
        <PublicAssetSearchBox
          searchValue={this.state.searchValue}
          handleSearchChanged={() => {
            alert('TODO!');
          }}
        />
      </div>
    );
  };
  render() {
    const mockAssets = [
      {
        filename: 'mrpricemoney-logo-transparent.png',
        size: '22 KB',
        addedAt: moment()
          .date(Math.random() * 28)
          .from(moment()),
      },
    ];
    return (
      <div>
        <PageHeader
          title='Public assets'
          rightComponent={this.renderSearchAndUpload()}
        />
        <Alert color='danger'>⚠️ This page is still under construction!</Alert>
        <Table>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Size</th>
              <th>Added at</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {mockAssets.map((asset, i) => {
              return (
                <tr key={`AssetRow_${i}`}>
                  <td>{asset.filename}</td>
                  <td>{asset.size}</td>
                  <td>{asset.addedAt}</td>
                  <td>
                    <Button color='link'>Copy public link</Button>
                  </td>
                  <td>
                    <Button color='link' className={'danger'}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
