export const nestedStyleCheckbox = (nested: boolean, index: number) => {
  return nested
    ? {
      borderLeft: '1px solid #dedede',
      paddingLeft: 25,
      marginBottom: 0,
      paddingBottom: 0,
      marginTop: index !== 1 ? -15 : 0,
      paddingTop: index !== 1 ? 15 : 0,
    }
    : {};
};

export const nestedStyleTextInput = (nested: boolean, index: number) => {
  return nested
    ? {
      borderLeft: '1px solid #dedede',
      paddingLeft: 25,
      marginBottom: 0,
      paddingBottom: 0,
      marginTop: index !== 1 ? -30 : 0,
      paddingTop: index !== 1 ? 30 : 0,
    }
    : {};
};
