import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import { ReportViewStore } from '../stores/report-view-store';
import { EmailRecipientType } from '../../outbound-notifications/domain/email-recipient';
import { sentenceCase } from 'sentence-case';
import { buttonText } from '../../../../shared/helpers/button-text-loading';
import Select from 'react-select';
import { ReportListStore, reportListStore } from '../stores/report-list-store';
import { ReportType } from '../domain/report-type';

interface Props {
  close: () => void;
  email: string;
  name?: string;
  type?: EmailRecipientType;
  reportType: ReportType;
  isNewRecipient: boolean;
  isNewReport: boolean;
}

interface SelectOptions {
  label: string;
  value: string;
}

interface Injected extends Props {
  reportViewStore: ReportViewStore;
  reportListStore: ReportListStore;
}

interface State {
  email: string;
  name?: string;
  type?: EmailRecipientType;
  reportType?: ReportType;
  popoverOpen: boolean;
  validate: {
    emailState: string;
  };
}

const reportTypesDropdownList = Object.values(ReportType).map(x => {
  const label = x.replace(/_/gi, ' ');
  return {
    label: sentenceCase(label),
    value: x,
  };
});

const recipientEmailTypeList = Object.values(EmailRecipientType).map(x => {
  const label = x.replace(/_/gi, ' ');
  return {
    label: sentenceCase(label),
    value: x,
  };
});

@inject(
  'reportViewStore',
  'reportListStore',
)
@observer
export class CreateEditReportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: this.props.email,
      name: this.props.name,
      type: this.props.type,
      popoverOpen: false,
      validate: {
        emailState: '',
      },
    };
  }

  get injected() {
    return this.props as Injected;
  }

  closeBtn = (
    <a href='#' onClick={() => this.props.close()}><Icon24PXCross /></a>
  );

  renderRecipientType() {
    return Object.values(EmailRecipientType).map(d => <option key={d} value={d}>
      {sentenceCase(d)}
    </option>);
  }

  render() {

    const { reportViewStore, isNewReport } = this.injected;
    const { type, reportType } = this.props;
    const { isLoading } = reportViewStore;

    const defaultRecipientEmailType = type ? recipientEmailTypeList.find((emailType) => emailType.value === type) : recipientEmailTypeList[0];
    const defaultReportType = reportType ? reportTypesDropdownList.find((emailType) => emailType.value === reportType) : reportTypesDropdownList[0];

    return (
      <Modal
        isOpen={true}
        centered={true}
        className='full-screen-modal'
      >
        <ModalHeader className='exports-modal-header' close={this.closeBtn}>
          Update recipients
        </ModalHeader>
        <ModalBody style={{ overflow: 'auto' }}>
          <br />
          {isNewReport && <Card className='new-report-modal card'>
            <CardHeader>
              <Row className='update-recipient-row-wrapper'>
                <Col className='update-recipient-column-wrapper'>
                  <h5 className='update-recipient-title'>Report details</h5>
                </Col>
                {!this.props.isNewRecipient && <Col xs='auto' style={{ paddingRight: 7 }}>
                  <Button
                    color='danger'
                    disabled={isLoading}
                    onClick={() => this.removeRecipient()}
                    className={'row-edit-button'}>{buttonText({ text: 'Remove recipient', loading: isLoading })}
                  </Button>
                </Col>}
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label sm={6}><strong>Report type<sup>*</sup></strong></Label>
                  <Col sm={6}>
                    <Select
                      defaultValue={defaultReportType}
                      options={reportTypesDropdownList}
                      onChange={(value: SelectOptions) => {
                        this.setState({ reportType: value.value as ReportType});
                      }}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>}
          <div />
          <Card className='new-report-modal card'>
            <CardHeader>
              <Row className='update-recipient-row-wrapper'>
                <Col className='update-recipient-column-wrapper'>
                  <h5 className='update-recipient-title'>Recipient details</h5>
                </Col>
                {!this.props.isNewRecipient && <Col xs='auto' style={{ paddingRight: 7 }}>
                  <Button
                    color='danger'
                    disabled={isLoading}
                    onClick={() => this.removeRecipient()}
                    className={'row-edit-button'}>{buttonText({ text: 'Remove recipient', loading: isLoading })}
                  </Button>
                </Col>}
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label sm={6}><strong>Email<sup>*</sup></strong></Label>
                  <Col sm={6}>
                    <Input
                      type='email'
                      disabled={!this.props.isNewRecipient}
                      className={this.state.validate.emailState}
                      name='email'
                      value={this.state.email}
                      onChange={e => this.validateEmail(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={6}><strong>Name</strong></Label>
                  <Col sm={6}>
                    <Input
                      type='text'
                      name='name'
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={6}><strong>Type</strong></Label>
                  <Col sm={6}>
                    <Select
                      options={recipientEmailTypeList}
                      defaultValue={defaultRecipientEmailType}
                      onChange={(value: SelectOptions) => this.setState({
                        type: value.value as EmailRecipientType,
                      })}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <Button
            color='link'
            disabled={reportViewStore.isLoading || (isNewReport && reportListStore.isLoading)}
            onClick={this.props.close}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            disabled={reportViewStore.isLoading || (isNewReport && reportListStore.isLoading)}
            onClick={this.submit}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  validateEmail(email: string) {
    if (email && email.match(/\S+@\S+.\S+.\S+/)) {
      this.setState({
        email: email,
        validate: {
          ...this.state.validate,
          emailState: '',
        },
      });
    } else {
      this.setState({
        email: email,
        validate: {
          ...this.state.validate,
          emailState: 'is-invalid',
        },
      });
    }
  }

  removeRecipient = async () => {
    const { reportViewStore } = this.injected;
    const recipients = reportViewStore.report && reportViewStore.report.config.recipients || [];
    if (recipients.length > 0) {
      const indexOfRecipient = recipients.findIndex(recipient => recipient.email === this.injected.email);
      recipients.splice(indexOfRecipient, 1);

      await reportViewStore.addOrUpdateRecipients({ recipients });
      this.props.close();
    }
  }

  submit = async () => {
    const { email, type, name, validate } = this.state;
    const organizationId = window.location.pathname.split('/')[2];

    if (validate.emailState === 'is-invalid') return;

    const { reportViewStore, isNewRecipient, reportListStore } = this.injected;
    const recipients = reportViewStore.report && reportViewStore.report.config.recipients || [];
    if (recipients.length > 0) {
      if (isNewRecipient) {
        const indexOfRecipient = recipients.findIndex(recipient => recipient.email === email);
        if (indexOfRecipient === -1) {
          recipients.push({ email, name, type });
          this.props.close();
          return reportViewStore.addOrUpdateRecipients({ recipients });
        }
      }

      const indexOfRecipient = recipients.findIndex(recipient => recipient.email === this.injected.email);

      recipients[indexOfRecipient] = {
        email,
        name,
        type,
      };

      await reportViewStore.addOrUpdateRecipients({ recipients });
      return this.props.close();
    }

    if (this.props.isNewReport) {
      await reportViewStore.createReport({
        organizationId,
        type: ReportType.DailySalesEmail,
        recipients: [{
          email,
          name,
          type,
        }],
      });
      await reportListStore.load({ organizationId });
      return this.props.close();
    }

    await reportViewStore.addOrUpdateRecipients({
      recipients: [{
        email,
        name,
        type,
      }],
    });
    await reportListStore.load({ organizationId });

    return this.props.close();
  };
}
