import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { FeatureListStore } from '../../stores/feature-list-store';

interface Props {
  open: boolean;
  close: () => any;
  featureListStore?: FeatureListStore;
  featureData?: {
    description: string;
    sandboxByDefault: boolean;
    featureKey: string;
    isLoading: boolean;
  };
}

interface State {
  featureKey: string;
  description: string;
  sandboxByDefault: boolean;
  isLoading: boolean;
}
@inject('featureListStore')
@observer
export class AddFeatureFlag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      description: '',
      featureKey: '',
      sandboxByDefault: true,
      isLoading: false,
    };
  }

  updatefeatureKey = async () => {
    const { featureKey, description, sandboxByDefault } = this.state;
    const { featureListStore } = this.props;
    if (featureListStore) {
      this.setState({ isLoading: true });
      await featureListStore.createNewFeature({
        featureKey,
        description,
        sandboxByDefault,
      });
      this.setState({ isLoading: false });
      this.props.close();
    }
  };

  render() {
    const { featureListStore } = this.props;
    if (!featureListStore) {
      return null;
    }

    return (
      <Modal isOpen={this.props.open} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>New feature flag</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label style={{ fontWeight: 'bold' }}>Feature key</Label>
            <Input
              type='text'
              placeholder='feature_key'
              ref='featureKey'
              onChange={e => this.setState({ featureKey: e.target.value.trim() })}
            />
          </FormGroup>
          <FormGroup>
            <Label style={{ fontWeight: 'bold' }}>Description</Label>
            <Input
              type='text'
              placeholder='Description for new feature flag'
              ref='description'
              onChange={e => this.setState({ description: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <p>Sandbox by default</p>
            <Input
              identifier='sandboxByDefault'
              defaultChecked={true}
              type='checkbox'
              onChange={() => {
                this.setState(prevState => ({
                  sandboxByDefault: !prevState.sandboxByDefault,
                }));
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={this.props.close}>
            Cancel
          </Button>
          <Button onClick={() => this.updatefeatureKey()} color='primary'>
            {this.state.isLoading ? 'Creating...' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
