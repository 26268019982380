/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='76px' height='30px' viewBox='0 0 76 21' version='1.1'>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-57'
            transform='translate(-441.000000, -307.000000)'
          >
            <g id='drive' transform='translate(441.000000, 305.289062)'>
              <g
                id='google-drive'
                transform='translate(0.000000, 2.000000)'
                fillRule='nonzero'
              >
                <g id='Group'>
                  <polygon
                    id='Shape'
                    fill='#3777E3'
                    points='3.66450831 19.999888 7.32851385 13.333264 21.9856939 13.333264 18.3213532 19.999888'
                  />
                  <polygon
                    id='Shape'
                    fill='#FFCF63'
                    points='14.6573476 13.333264 21.9856939 13.333264 14.6573476 0 7.32851385 0'
                  />
                  <polygon
                    id='Shape'
                    fill='#11A861'
                    points='0 13.333264 3.66450831 19.999888 10.9928546 6.66664 7.32851385 0'
                  />
                </g>
              </g>
              <text
                id='Drive'
                fontFamily='ProximaNova-Regular, Proxima Nova'
                fontSize='18'
                fontWeight='normal'
                fill='#202B40'
              >
                <tspan x='35' y='18'>
                  Drive
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
