/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='80px' height='30px' viewBox='0 0 80 30' version='1.1'>
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Artboard-Copy-57'
            transform='translate(-809.000000, -302.000000)'
          >
            <g id='Share' transform='translate(809.000000, 302.289062)'>
              <g id='Group-6'>
                <rect
                  id='Rectangle-8-Copy'
                  fill='#3777E3'
                  x='-3.55271368e-13'
                  y='0'
                  width='79.2556735'
                  height='29.2105263'
                  rx='3'
                />
                <text
                  id='Share'
                  fontFamily='ProximaNova-Regular, Proxima Nova'
                  fontSize='13'
                  fontWeight='normal'
                  fill='#FFFFFF'
                >
                  <tspan x='30' y='19'>
                    Share{' '}
                  </tspan>
                </text>
                <path
                  d='M19.8333333,11.9166667 L19.1309524,11.9166667 L19.1309524,10.5119048 C19.1309524,8.57333333 17.557619,7 15.6190476,7 C13.6804762,7 12.1071429,8.57333333 12.1071429,10.5119048 L12.1071429,11.9166667 L11.4047619,11.9166667 C10.628631,11.9166667 10,12.5452976 10,13.3214286 L10,20.3452381 C10,21.1213687 10.628631,21.75 11.4047619,21.75 L19.8333333,21.75 C20.6094643,21.75 21.2380952,21.1213687 21.2380952,20.3452381 L21.2380952,13.3214286 C21.2380952,12.5452976 20.6094643,11.9166667 19.8333333,11.9166667 Z M15.6190476,18.2380952 C14.8429167,18.2380952 14.2142857,17.6094643 14.2142857,16.8333333 C14.2142857,16.0572024 14.8429167,15.4285714 15.6190476,15.4285714 C16.3951786,15.4285714 17.0238095,16.0572024 17.0238095,16.8333333 C17.0238095,17.6094643 16.3951786,18.2380952 15.6190476,18.2380952 Z M17.7964286,11.9166667 L13.4416667,11.9166667 L13.4416667,10.5119048 C13.4416667,9.31083333 14.4179762,8.33452381 15.6190476,8.33452381 C16.820119,8.33452381 17.7964286,9.31083333 17.7964286,10.5119048 L17.7964286,11.9166667 Z'
                  id='Page-1'
                  fill='#FFFFFF'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
