import React from 'react';
import { ReportRun } from '../../domain/report-run';

const ReportRunCreatedAtCellElement: React.SFC<ReportRun> = ({ createdAt }) => (
  <div className='table-cell-single'>
    {createdAt.format('YYYY-MM-DD HH:mm')}
  </div>
);

export const ReportRunCreatedAtColumn = {
  heading: 'Created at',
  element: ReportRunCreatedAtCellElement,
};
