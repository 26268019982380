import { Moment, utc as moment } from 'moment';
import { Construct } from '../../shared/domain/construct';
import { Requestee } from '../../shared/domain/requestee';

export class ProductModuleDefinitionCode {
  readonly productModuleDefinitionCodeId: string;
  readonly code: string;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;

  constructor(init: Construct<ProductModuleDefinitionCode>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleDefinitionCode): ProductModuleDefinitionCode {
    return new ProductModuleDefinitionCode({
      productModuleDefinitionCodeId: init.product_module_definition_code_id,
      code: init.code,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
    });
  }
}

export interface NetworkProductModuleDefinitionCode {
  product_module_definition_code_id: string;
  code: string;
  created_at: string;
  created_by: object;
}
