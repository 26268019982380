import React from 'react';
import { inject, observer } from 'mobx-react';
import { DomainEntityTable } from '../../../../components/domain/tables/domain-entity-table';
import { ReportViewStore } from '../stores/report-view-store';
import { EmailRecipient, EmailRecipientType } from '../../outbound-notifications/domain/email-recipient';
import { RecipientNameColumn, RecipientEmailColumn } from './utils/recipient-list-columns';
import { Row, Col, Button } from 'reactstrap';
import { buttonText } from '../../../../shared/helpers/button-text-loading';
import { CreateEditReportModal } from './report-view-create-edit';
import { ReportType } from '../domain/report-type';
import { sentenceCase } from 'sentence-case';

interface Props {
  router?: History;
}

interface Injected extends Props {
  reportViewStore: ReportViewStore;
}

interface State {
  popoverOpen: boolean;
  name?: string;
  email: string;
  type?: EmailRecipientType;
  isNew?: boolean;
}

@inject(
  'reportViewStore',
)
@observer
export class RecipientListView extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      popoverOpen: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  renderEmpty() {
    const { reportViewStore } = this.injected;

    return (
      <>
        <hr />
        <br />
        <div className=''>
          <div>
            <h3>Nothing to see here</h3>
            <p>This report does not contain any recipients!</p>
          </div>
          <Button
            color='primary'
            style={{ marginLeft: 0 }}
            disabled={reportViewStore.isLoading}
            onClick={() => this.setState({
              popoverOpen: !this.state.popoverOpen,
              name: '',
              type: EmailRecipientType.To,
              email: '',
              isNew: true,
            })}
            className={'row-edit-button'}>{buttonText({ text: 'Add recipient', loading: reportViewStore.isLoading })}
          </Button>
        </div>
      </>
    );
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    const { reportViewStore } = this.injected;
    const { email, name, type, popoverOpen, isNew } = this.state;
    const { isLoading, report } = reportViewStore;

    const recipients = reportViewStore.report && reportViewStore.report.config && reportViewStore.report.config.recipients;
    const renderEmpty = !isLoading && (!recipients || recipients.length === 0);
    const reportType = report && report.config.type || reportTypesDropdownList[0].value;

    return (
      <>
      {popoverOpen &&
        <CreateEditReportModal
          isNewReport={false}
          isNewRecipient={!!isNew}
          name={name}
          type={type}
          email={email}
          reportType={reportType}
          close={() => this.toggle()}
        />}
      {!isLoading && recipients && recipients.length > 0 &&
        <Row className='recipient-list-wrapper'>
          <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>
            <h3 style={{ marginBottom: 0 }}>Report recipients</h3>
          </Col>
          <Col xs='auto'>
            <Button
              color='primary'
              disabled={isLoading}
              onClick={() => this.setState({
                popoverOpen: !this.state.popoverOpen,
                name: '',
                type: EmailRecipientType.To,
                email: '',
                isNew: true,
              })}
              className={'row-edit-button'}>{buttonText({ text: 'Add recipient', loading: isLoading })}
            </Button>
          </Col>
        </Row>}
      <br />
      {renderEmpty && this.renderEmpty()}
      {!isLoading && recipients && recipients.length > 0 && <ReciepientListTable
        rows={recipients}
        getRowProps={({ email, name, type }) => ({
          onClick: () => {
            this.setState({ popoverOpen: !popoverOpen, isNew: false, name, type, email });
          },
        })}
        columns={[
          RecipientNameColumn,
          RecipientEmailColumn,
        ]}
        isLoaded={true}
      />}
      </>
    );
  }
}

const reportTypesDropdownList = Object.values(ReportType).map(x => {
  const label = x.replace(/_/gi, ' ');
  return {
    label: sentenceCase(label),
    value: x,
  };
});

@observer
class ReciepientListTable extends DomainEntityTable<EmailRecipient>{}
