export interface ProductModuleDefinitionSettings {
  canIssuePolicyThroughDashboard: boolean;
  welcomeLetterEnabled: boolean;
  policySchemeType: PolicySchemeType;
  policyholder: {
    entityTypes: PolicyholderEntityType[];
    idTypes: IdentificationType[];
  };
  beneficiaries: {
    enabled: boolean;
    min: number;
    max: number;
  };
  coveredItems: {
    enabled: boolean;
    maxSumAssured: number;
  };
  billing: {
    paymentMethodTypes: PaymentMethodType[];
    currency: Currency;
    premiumType: PolicyBillingFrequency;
    paymentReference: string;
    proRataBillingOnIssue?: boolean;
    proRataBillingDisabled?: boolean;
    proRataMinimum: number;
    retries: {
      retryFailedPayments: number;
      daysBetweenFailedPaymentRetries: number;
    };
    billBeforeWeekend: boolean;
    naedoArrearsPolicies: boolean;
    enableBillingOnSandbox: boolean;
  };
  lifecycle: {
    policyActivationEvent: PolicyActivationEvent;
    canRequote?: boolean;
    canReactivate?: boolean;
    coolingOffPeriod: CoolingOffPeriodConfig;
    gracePeriod: GracePeriodPeriodConfig;
    waitingPeriod: WaitingPeriodConfig;
  };
  claims: {
    claimChecklistItems: string[];
  };
}
export interface WaitingPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: WaitingPeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export enum Currency {
  ZAR = 'ZAR',
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  KRW = 'KRW',
  JPY = 'JPY',
  CNY = 'CNY',
  INR = 'INR',
}

export enum IdentificationType {
  Id = 'id',
  Passport = 'passport',
}

export interface CoolingOffPeriodConfig {
  rules: [
    {
      applyTo: ApplyRulesTo;
      period: {
        type: PeriodOf;
        value: number;
      };
      refundType: CoolingOffPeriodOptions;
    },
  ];
}

export interface GracePeriodPeriodConfig {
  rules: [
    {
      lapseAfter: GracePeriodOptions;
      skippedPayments?: number;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
  notifications?: [
    {
      trigger: GracePeriodNotificationTriggers;
      period: {
        type: PeriodOf;
        value: number;
      };
    },
  ];
}

export enum GracePeriodOptions {
  ConsecutiveSkippedPayments = 'consecutive_skipped_payments',
  SkippedPaymentsOverPolicyTerm = 'skipped_payments_over_policy_term',
  SkippedPaymentsWithinPeriod = 'skipped_payments_within_period',
  PeriodAfterMissedPayment = 'period_after_missed_payment',
}

export enum GracePeriodNotificationTriggers {
  BeforeTheGracePeriodExpires = 'before_the_grace_period_expires',
  OnTheGracePeriodExpiryDate = 'on_the_grace_period_expiry_date',
}

export enum WaitingPeriodNotificationTriggers {
  OnTheWaitingPeriodExpiryDate = 'on_the_waiting_period_expiry_date',
  BeforeTheWaitingPeriodExpires = 'before_the_waiting_period_expires',
}

export enum CoolingOffPeriodOptions {
  AllPremiums = 'all_premiums',
  None = 'none',
}

export enum PeriodOf {
  Days = 'days',
  Months = 'months',
}

export enum ApplyRulesTo {
  Policy = 'the_full_policy',
}
export interface Identification {
  type: IdentificationType;
  number: string;
  country: string;
}

export enum PolicyBillingFrequency {
  Monthly = 'monthly',
  OnceOff = 'once_off',
}

export enum PolicySchemeType {
  Individual = 'individual',
  Group = 'group',
}

export enum PolicyActivationEvent {
  PolicyIssued = 'policy_issued',
  PaymentMethodAssigned = 'payment_method_assigned',
  FirstSuccessfulPayment = 'first_successful_payment',
  None = 'none',
}

export enum PolicyholderEntityType {
  Individual = 'individual',
  Company = 'company',
}

export enum PaymentMethodType {
  DebitOrder = 'debit_order',
  Card = 'card',
  Eft = 'eft',
  External = 'external',
}
export interface DefinitionItems {
  databaseIdKey: string;
  edited: boolean;
  enabled: boolean;
  lastUpdated: string | null;
  name: string;
  path: string;
  published: boolean;
}
