import { observable, computed } from 'mobx';
import { getProductModuleDefinitionAlterationHooks } from '../../product-modules-api';
import { ProductModuleDefinitionAlterationHook } from '../../../domain/product-module-definition-alteration-hooks';

export class ProductModuleDefinitionAlterationHooksListStore {
  @observable productModuleDefinitionAlterationHooks: ProductModuleDefinitionAlterationHook[] = [];
  @observable isLoading = false;
  @observable error?: Error;

  init = async (params: { productModuleId: string; productModuleDefinitionId: string }) => {
    const { productModuleDefinitionId, productModuleId } = params;

    try {
      this.isLoading = true;

      return this.productModuleDefinitionAlterationHooks = await getProductModuleDefinitionAlterationHooks({
        productModuleId,
        productModuleDefinitionId,
      });
    } catch (e) {
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !!(
      this.isLoading === false &&
      this.productModuleDefinitionAlterationHooks &&
      this.productModuleDefinitionAlterationHooks.length === 0
    );
  }

  setLoading = (params: { isLoading: boolean }) => {
    this.isLoading = params.isLoading;
  };
}

export const productModuleDefinitionAlterationHooksListStore = new ProductModuleDefinitionAlterationHooksListStore();
