const initialState = {
  modalType: null,
  modalProps: {},
};
/* eslint-disable-next-line  max-len */
// http://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
// https://github.com/reactjs/react-modal

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case 'HIDE_MODAL':
      return initialState;
    default:
      return state;
  }
};
