import React from 'react';
import { observer, inject } from 'mobx-react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { History } from 'history';
import { DomainEntityCard } from '../../../../components/domain/domain-entity-card/domain-entity-card';
import { Report } from '../domain/reports';
import { ReportViewStore } from '../stores/report-view-store';
import { buttonText } from '../../../../shared/helpers/button-text-loading';
import { ConfigTypeColumn, CreatedAtColumn, EnvironmentColumn } from './utils/report-view-card-rows';
import { ReportRunListView } from './report-run-list-view';
import { RecipientListView } from './reciepient-list-view';
import { ArchiveReportModal } from './modals/archive-report';

interface Props {
  router: History;
}

interface Injected extends Props {
  reportViewStore: ReportViewStore;
}

interface State {
  showArchiveModal: boolean;
}

@inject('reportViewStore')
@observer
export class ReportDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showArchiveModal: false,
    };

    const { reportViewStore } = this.injected;
    reportViewStore.setReportViewStoreIsLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const reportId = window.location.pathname.split('/')[4];
    const organizationId = window.location.pathname.split('/')[2];


    const { reportViewStore } = this.injected;
    await reportViewStore.load({ reportId, organizationId });
  }

  toggleArchive = () => {
    this.setState({ showArchiveModal: !this.state.showArchiveModal });
  }

  render() {
    const { reportViewStore } = this.injected;
    const { showArchiveModal } = this.state;
    const { isLoading, isLoadingArchive } = reportViewStore;
    const organizationId = window.location.pathname.split('/')[2];

    return (
      <>
      {showArchiveModal && <ArchiveReportModal close={() => {
        this.setState({ showArchiveModal: !this.state.showArchiveModal }, () => {
          this.props.router.push(`/organizations/${organizationId}/reports`);
        });
      }} />}
      <br />
      <div className={'card report-domain-entity-card-wrapper'}>
        <Row className={'row-card-header'}>
          <Col style={{ fontSize: '16px', fontWeight: 'bold' }}>Report details</Col>
          <Col xs='auto'>
            <Button
              color='danger'
              disabled={isLoading}
              onClick={() => this.toggleArchive()}
              className={'row-edit-button'}>{buttonText({ text: 'Archive', loading: isLoading || isLoadingArchive })}
            </Button>
          </Col>
        </Row>
        <CardBody>
          {reportViewStore.report && <ReportDetailsCard
            domainEntity={reportViewStore.report as Report}
            rows={[
              ConfigTypeColumn,
              CreatedAtColumn,
              EnvironmentColumn,
            ]}
            isLoading={false}
          />}
        </CardBody>
      </div>
      <Row>
        <Col>
          <RecipientListView />
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col>
          <ReportRunListView />
        </Col>
      </Row>
      <div>
      </div>
      </>
    );
  }
}
@observer
class ReportDetailsCard extends DomainEntityCard<Report>{}
