export enum BlockUIState {
  Empty = 'empty',
  Normal = 'normal',
  Loading = 'loading',
  Editing = 'editing',
  Saving = 'saving',
  Error = 'error',
  Disabled = 'disabled',
  NotAllowed = 'not_allowed',
  Conflict = 'conflict',
};
