import React from 'react';
import { Table, Button } from 'reactstrap';
import {
  formatDate,
  paymentBatchLink,
  policyLink,
  paymentLink,
} from '../../../helpers';
import { Alert } from './payment-alerts-container';

interface TableProps {
  alerts: Alert[];
  resolved: boolean;
  resolveAlert: (paymentAlertId: string) => Promise<void>;
}

interface TableState {
  loading: boolean;
}

export class PaymentAlertsTable extends React.Component<TableProps, TableState> {
  constructor(props: TableProps) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  render() {
    const { alerts } = this.props;

    return (
      <Table hover className='scrollable-table'>
        <thead>
          <tr>
            <td>
              Type
            </td>
            <td>
              Description
            </td>
            <td>
              Policy Id
            </td>
            <td>
              Payment Id
            </td>
            <td>
              Payment Batch Id
            </td>
            {!this.props.resolved ? <td></td> : <td>Resolved at</td>}
          </tr>
        </thead>
        <tbody>{alerts.map(alert => this.renderRow(alert))}</tbody>
      </Table>
    );
  }

  renderRow = (alert: Alert) => {
    return (
      <PaymentAlertRow
        alert={alert}
        key={alert.paymentAlertId}
        resolveAlert={this.props.resolveAlert}/>
    );
  };
}

export const formatType = (type: string) => {
  switch (type) {
    case 'inactive_policy':
      return 'Inactive Policy';
    case 'unverified_payment_method':
      return 'Unverified Payment Method';
    default:
      return type;
  }
};

interface RowProps {
  alert: Alert;
  key: string;
  resolveAlert: (paymentAlertId: string) => Promise<void>;
}

interface RowState {
  loading: boolean;
}

class PaymentAlertRow extends React.Component<RowProps, RowState> {
  constructor(props: RowProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const { alert, key } = this.props;
    const resolved = alert.resolvedAt? true : false;

    return (
      <tr key={key}>
        <td>{formatType(alert.type)}</td>
        <td>{alert.description}</td>
        <td>{alert.policy ? policyLink(alert.policy) : '-'}</td>
        <td>{(alert.paymentId && alert.policyId) ? paymentLink(alert) : '-'}</td>
        <td>{alert.paymentBatchId ? paymentBatchLink(alert.paymentBatchId) : '-'}</td>
        { resolved ? <td>{formatDate(alert.resolvedAt)}</td>
          : <td>
            <Button
              color='link'
              style={{ minWidth: 'auto', display: 'contents' }}
              onClick={() => this.props.resolveAlert(alert.paymentAlertId)}>
            Resolve
            </Button>
          </td> }
      </tr>
    );
  }


}
