import { InputBlock } from './input-block';
import { Construct } from '../../../../../../shared/domain/construct';
import { BlockType } from './block-type';
import { Moment } from 'moment';

export class InputTimeBlock extends InputBlock {
  readonly type: BlockType.InputTime;
  readonly min?: Moment;
  readonly max?: Moment;
  readonly placeholder?: Moment;
  readonly defaultValue?: Moment;

  constructor(init: Construct<InputTimeBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      placeholder: this.placeholder,
      default_value: this.defaultValue,
      min: this.min,
      max: this.max,
    };
  }

  static fromJSON(init: any) {
    return new InputTimeBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      placeholder: init.placeholder,
      defaultValue: init.default_value,
      min: init.min,
      max: init.max,
    });
  }
}
