import { policyholder } from './policyholder';
import { policy } from './policy';
import { claim } from './claim';
import { complaint } from './complaint';
import { payment } from './payment';
import { coveredItems } from './covered-items';
import { policyCharges } from './policy-charges';
import { requesterDetails } from './requester-details';

export const mergeVarStubs = {
  policyholder,
  policy,
  claim,
  complaint,
  payment,
  coveredItems,
  policyCharges,
  requesterDetails,
  claimBlocksSchema: {
    claim: {
      ...claim,
      policyholder,
      policy,
    },
  },
};
