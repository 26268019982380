import React, { Component } from 'react';
import { Alert, Button, Input } from 'reactstrap';
import PageHeader from '../../components/page-header';
import { inject, observer } from 'mobx-react';
import { History } from 'history';
import { OrganizationViewStore } from '../stores/organization-view-store';
import { Icon24PXCross } from '../../components/icons/icon-24-px-cross';
import { debounce } from '../../helpers/debounce';
import { NotificationChannels } from './outbound-notifications/notification-channels/notification-channels';

interface Props {
  router: History;
}

interface Injected extends Props {
  organizationViewStore: OrganizationViewStore;
}

interface State {
  validOutboundEmailDomains: string[];
}

@inject(
  'organizationViewStore',
)
@observer
export default class OutboundNotificationsWithoutData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      validOutboundEmailDomains: [],
    };
  }

  get injected() {
    return this.props as Injected;
  }


  async componentDidMount() {
    const { organizationViewStore } = this.injected;
    const organizationId = (this.props.router as any).params.organizationId;

    await Promise.all([
      organizationViewStore.load({ organizationId }),
    ]);

    this.setState({
      validOutboundEmailDomains: organizationViewStore.organization &&
        organizationViewStore.organization.validOutboundEmailDomains || [],
    });
  }

  validDomains = () => {
    return this.state.validOutboundEmailDomains.map((domain, index) => {
      return (
        <div
          style={{ width: 530 }}
          key={`claim-checklist-${domain}_${index}`}
        >
          <a
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault;
              this.removeListItem(index);
            }}
          >
            <Icon24PXCross />
          </a>
          <Input
            style={{ marginBottom: 10, width: 500, minHeight: 40 }}
            type='text'
            id={`vaild-domains ${index}`}
            defaultValue={domain}
            onChange={e => this.updateListItem(e.target.value, index)}
          />
        </div>
      );
    });
  }

  removeListItem = async (index: number) => {
    const { organizationViewStore } = this.injected;
    const validOutboundEmailDomains = this.state.validOutboundEmailDomains;
    const organizationId = (this.props.router as any).params.organizationId;

    validOutboundEmailDomains.splice(index, 1);
    await organizationViewStore.updateOrganization({
      organizationId,
      data: {
        valid_outbound_email_domains: validOutboundEmailDomains,
      },
    });
    this.setState({ validOutboundEmailDomains });
  }

  updateListItem = async (value: string, index: number) => {
    const { organizationViewStore } = this.injected;
    const organizationId = (this.props.router as any).params.organizationId;

    const validOutboundEmailDomains = this.state.validOutboundEmailDomains;
    validOutboundEmailDomains[index] = value;

    debounce('updating-communications-whitelisted-emails', async () => {
      await organizationViewStore.updateOrganization({
        organizationId,
        data: {
          valid_outbound_email_domains: validOutboundEmailDomains,
        },
      });
      this.setState({ validOutboundEmailDomains });
    }, 1000);
  };

  addListItem = async () => {
    this.setState({
      validOutboundEmailDomains: [...this.state.validOutboundEmailDomains, ''],
    });
  };

  render() {
    const organizationId = (this.props.router as any).params.organizationId;

    return (
      <div>
        <br />
        <PageHeader title='Customer notifications' />
        <NotificationChannels organizationId={organizationId} />
        <h5 style={{ paddingTop: 20 }} className='feather-light'>
          Whitelisted email domains on Mandrill
        </h5>
        <div>
          <Alert color='warning'>
            ⚠️ Adding a domain here is not sufficient for using it to send emails.
            The domain setup must also completed on Mandrill. See the
            <a href='https://root-platform.slite.com/app/docs/RBQSawcI_I/Need-to-know-Custom-Email-Domains-for-Clients'>
              {' '}
              Slite guide{' '}
            </a>
            for details.
          </Alert>
        </div>
        <p>
          Add whitelisted email domains below to allow them to be used in the
          &quot;from&quot; and &quot;reply-to&quot; fields in email
          configs. For example: <code>@dinosure.com</code>.
        </p>
        {this.validDomains()}
        <Button
          color='primary'
          className='product-module-definition-horizontal-card-button'
          style={{ marginTop: 10 }}
          onClick={this.addListItem}
        >
          Add domain
        </Button>
      </div>
    );
  }
}
