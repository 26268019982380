/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='77px'
        height='108px'
        viewBox='0 0 77 108'
        enableBackground='new 0 0 77 108'
        xmlSpace='preserve'
      >
        <path
          fill='#202B40'
          d='M48.058,89.495v5.309H6.275V17.516h41.783v37.146h4.396V17.914c0-2.567-2.101-4.666-4.667-4.666H6.549 c-2.566,0-4.666,2.099-4.666,4.666v85.42c0,2.566,2.099,4.666,4.666,4.666h41.238c2.566,0,4.667-2.1,4.667-4.666V89.495H48.058z M28.101,104.9c-1.933,0-3.499-1.566-3.499-3.499c0-1.935,1.566-3.5,3.499-3.5c1.933,0,3.498,1.565,3.498,3.5 C31.599,103.334,30.034,104.9,28.101,104.9z'
        />
        <path
          fill='#37CBB9'
          d='M69.586,57.717H17.859c-3.246,0-5.887,2.641-5.887,5.887v15.57c-0.002,0.021-0.016,0.038-0.016,0.059 c0,1.951-0.847,3.715-2.157,4.493c-0.209,0.124-0.299,0.381-0.211,0.608l0.022,0.059c0.074,0.193,0.26,0.321,0.467,0.321 c1.236,0,2.354-0.498,3.175-1.299c1.079,1.357,2.741,2.232,4.606,2.232h51.727c3.246,0,5.888-2.642,5.888-5.89V63.604 C75.474,60.357,72.833,57.717,69.586,57.717z'
        />
        <g>
          <path
            fill='#37CBB9'
            d='M59.943,5.604c-0.391-0.391-1.023-0.391-1.414,0l-3.856,3.858c-0.391,0.391-0.391,1.024,0,1.414 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l3.856-3.858C60.333,6.627,60.333,5.994,59.943,5.604z'
          />
          <path
            fill='#37CBB9'
            d='M50.434,1.665c-0.552,0-1,0.448-1,1v5.276c0,0.552,0.448,1,1,1c0.553,0,1-0.448,1-1V2.665 C51.434,2.113,50.987,1.665,50.434,1.665z'
          />
          <path
            fill='#37CBB9'
            d='M62.886,14.115h-5.298c-0.553,0-1,0.448-1,1s0.447,1,1,1h5.298c0.553,0,1-0.448,1-1 S63.439,14.115,62.886,14.115z'
          />
        </g>
        <g>
          <polygon
            fill='#FFFFFF'
            points='22.738,69.753 20.725,71.073 20.851,68.66 19.886,68.66 20.011,71.073 17.977,69.753 17.515,70.592 19.656,71.682 17.515,72.771 17.977,73.591 20.011,72.292 19.886,74.7 20.851,74.7 20.725,72.292 22.738,73.591 23.221,72.771 21.081,71.682 23.221,70.592   '
          />
          <polygon
            fill='#FFFFFF'
            points='31.969,69.753 29.954,71.073 30.082,68.66 29.116,68.66 29.242,71.073 27.207,69.753 26.744,70.592 28.884,71.682 26.744,72.771 27.207,73.591 29.242,72.292 29.116,74.7 30.082,74.7 29.954,72.292 31.969,73.591 32.451,72.771 30.312,71.682 32.451,70.592   '
          />
          <polygon
            fill='#FFFFFF'
            points='41.199,69.753 39.184,71.073 39.312,68.66 38.346,68.66 38.471,71.073 36.436,69.753 35.974,70.592 38.115,71.682 35.974,72.771 36.436,73.591 38.471,72.292 38.346,74.7 39.312,74.7 39.184,72.292 41.199,73.591 41.681,72.771 39.542,71.682 41.681,70.592   '
          />
          <polygon
            fill='#FFFFFF'
            points='50.987,69.753 48.974,71.073 49.1,68.66 48.137,68.66 48.263,71.073 46.227,69.753 45.765,70.592 47.906,71.682 45.765,72.771 46.227,73.591 48.263,72.292 48.137,74.7 49.1,74.7 48.974,72.292 50.987,73.591 51.473,72.771 49.332,71.682 51.473,70.592   '
          />
          <polygon
            fill='#FFFFFF'
            points='60.218,69.753 58.205,71.073 58.333,68.66 57.368,68.66 57.492,71.073 55.458,69.753 54.996,70.592 57.136,71.682 54.996,72.771 55.458,73.591 57.492,72.292 57.368,74.7 58.333,74.7 58.205,72.292 60.218,73.591 60.702,72.771 58.563,71.682 60.702,70.592   '
          />
          <polygon
            fill='#FFFFFF'
            points='69.931,70.592 69.449,69.753 67.434,71.073 67.562,68.66 66.596,68.66 66.722,71.073 64.686,69.753 64.226,70.592 66.365,71.682 64.226,72.771 64.686,73.591 66.722,72.292 66.596,74.7 67.562,74.7 67.434,72.292 69.449,73.591 69.931,72.771 67.792,71.682   '
          />
        </g>
      </svg>
    );
  }
}
