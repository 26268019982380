import { InputBlock } from './input-block';
import { Construct } from '../../../../../../shared/domain/construct';
import { BlockType } from './block-type';

export class InputTextBlock extends InputBlock {
  readonly type: BlockType.InputText;
  readonly placeholder?: string;
  readonly defaultValue?: string;

  constructor(init: Construct<InputTextBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      placeholder: this.placeholder,
      default_value: this.defaultValue,
      min_length: this.minLength,
      max_lenth: this.maxLength,
    };
  }

  static fromJSON(init: any) {
    return new InputTextBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      placeholder: init.placeholder,
      defaultValue: init.default_value,
      minLength: init.min_length,
      maxLength: init.max_lenth,
    });
  }
}
