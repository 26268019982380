import React from 'react';
import { Route, IndexRedirect } from 'react-router';

// Components
import Overview from './components/overview';

export default (
  <Route>
    <IndexRedirect to='overview' />
    <Route path='overview' component={Overview} />
  </Route>
);
