export default (state = [], action) => {
  switch (action.type) {
    case 'SET_CONTACTS':
      return action.contacts;
    case 'NEW_CONTACT':
      return [...state, action.contact];
    case 'UPDATED_CONTACT':
      return state.map(c => {
        if (c.id === action.contact.id) {
          return action.contact;
        }
        return c;
      });
    case 'DELETED_CONTACT':
      return state.filter(c => c.id !== action.contactId);
    default:
      return state;
  }
};
