import React from 'react';
import { CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import LoadingInPage from '../../../../loading';
import { ProductModuleDefinitionDocumentIndexStructure } from '../../product-module-documentation/orchestration';
import ProductModuleHtmlDoc from '../../product-module-html-to-documents';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
}

interface State {
  showDocument: boolean;
}

@inject('productModuleStore')
@observer
export default class ProductModuleDefinitionPolicySchedule extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showDocument: false,
    };
  }

  async componentDidMount() {
    const { productModuleKey, productModuleStore } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
  }

  renderFullScreenModal = () => {
    return (
      <ProductModuleHtmlDoc
        docType={ProductModuleDefinitionDocumentIndexStructure.ScheduleId}
        toDbKey={'schedule_html'}
        breadcrumbDocName={'Policy schedule'}
        productModuleKey={this.props.productModuleKey}
        closeDocument={() =>
          this.setState({ showDocument: !this.state.showDocument })
        }
      />
    );
  };

  render() {
    const { productModuleStore } = this.props;

    if (!productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Policy Schedule
              </p>
            </Col>
            <Col xs={9}>
              <p>Policy Schedule</p>
              <div className='product-module-card-inner-div'>
                <Button
                  outline
                  onClick={() =>
                    this.setState({ showDocument: !this.state.showDocument })
                  }
                  color='primary'
                  className='product-module-definition-horizontal-card-button'
                >
                  Open editor
                </Button>
              </div>
            </Col>
          </Row>
          {this.state.showDocument && this.renderFullScreenModal()}
        </CardBody>
        <hr/>
      </div>
    );
  }
}
