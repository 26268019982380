import React from 'react';
import ReactDOM from 'react-dom';
import '../../../styles/resizable-row-panel.scss';

interface Props {
  children: any;
  updatingResize: (number: any) => void;
  defaultPanelHeight: [number, number];
  bumpPanelHeightMax?: [number, number];
  bumpPanelHeightMin?: [number, number];
  snapPanel: boolean;
  headerComponents: any[];
}

interface State {
  isDragging: boolean;
  panels: number[];
  currentPanel: number;
  initialPos: number;
  delta: number;
}

export default class ResizableRowPanel extends React.Component<Props, State> {
  eventHandler = null;

  constructor(props: any) {
    super(props);

    this.state = {
      isDragging: false,
      panels: [
        this.props.defaultPanelHeight[0] || 300,
        this.props.defaultPanelHeight[1] || 300,
      ],
      currentPanel: 0,
      initialPos: 0,
      delta: 0,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.snapPanel !== undefined &&
      nextProps.snapPanel !== this.props.snapPanel
    ) {
      if (nextProps.snapPanel) {
        this.setState({ panels: this.props.bumpPanelHeightMax || [300, 300] });
      } else {
        this.setState({ panels: this.props.bumpPanelHeightMin || [300, 300] });
      }
      this.props.updatingResize(this.state.initialPos);
    }
  }

  componentDidMount() {
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode) {
      domNode.addEventListener('mousemove', this.resizePanel);
      domNode.addEventListener('mouseup', this.stopResize);
      domNode.addEventListener('mouseleave', this.stopResize);
    }
  }

  startResize = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    this.setState({
      isDragging: true,
      currentPanel: index,
      initialPos: event.clientY,
    });
  };

  stopResize = () => {
    if (this.state.isDragging) {
      this.setState(({ panels, currentPanel, delta }) => ({
        isDragging: false,
        panels: {
          ...panels,
          [currentPanel]: (panels[currentPanel] || 0) - delta,
          [currentPanel - 1]: (panels[currentPanel - 1] || 0) + delta,
        },
        delta: 0,
      }));
      this.props.updatingResize(this.state.initialPos);
    }
  };

  resizePanel = (event: any) => {
    if (this.state.isDragging) {
      const delta = event.clientY - this.state.initialPos;
      this.setState({ delta: delta });
    }
  };

  render() {
    const { children } = this.props;
    const { panels } = this.state;
    const rest = children ? children.slice(1) : null;
    const secondaryPaneHeight = panels ? panels[1] : 0;
    return (
      <div
        className='panel-container-row'
        style={{ width: '100%' }}
        onMouseUp={() => this.stopResize()}
      >
        <div
          className='panel-row'
          style={{
            height: `calc(${window.innerHeight}px - ${secondaryPaneHeight}px)`,
            margin: 0,
            padding: 0,
          }}
        >
          {children && children[0]}
        </div>
        {[].concat(
          ...rest.map((child: any, i: number) => {
            return [
              <div
                onMouseDown={e => this.startResize(e, i + 1)}
                key={'resizer_' + i}
                style={
                  this.state.currentPanel === i + 1
                    ? { top: this.state.delta }
                    : {}
                }
                className='resizer-row'
              ></div>,
              <div
                key={'panel_' + i}
                className='panel-row'
                style={{ height: panels[i + 1] }}
              >
                {this.props.headerComponents[0].header}
                {child}
              </div>,
            ];
          }),
        )}
      </div>
    );
  }
}
