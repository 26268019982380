export enum TypesOfBillingConditions {
  equals = 'equals',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export const billing: any = {
  paymentMethodTypes: {
    label: 'Payment method types',
    description: '',
    indent: 0,
    path: 'billing.paymentMethodTypes',
    condition: TypesOfBillingConditions.equals,
    key: 'paymentMethodTypes',
    multipleSelect: true,
    title: 'Billing rules',
    options: [
      {
        label: 'Debit order',
        value: 'debit_order',
      },
      {
        label: 'Credit card',
        value: 'credit_card',
      },
      {
        label: 'Credit card',
        value: 'card',
      },
      {
        label: 'Eft',
        value: 'eft',
      },
      {
        label: 'External',
        value: 'external',
      },
      {
        label: 'Nedbank debit order',
        value: 'nedbank_debit_order',
      },
    ],
  },
  proRataBillingDisabled: {
    title: 'Billing rules',
    label: 'Pro rata billing disabled',
    description: 'Policies will not be billed a pro rata amount.',
    indent: 0,
    path: 'billing.proRataBillingDisabled',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'proRataBillingDisabled',
    reset: [
      {
        path: 'billing.proRataMinimum',
        default: 1000,
        comparisonValues: [true],
      },
      {
        path: 'billing.proRataBillingOnIssue',
        default: false,
        comparisonValues: [true],
      },
    ],
  },
  proRataBillingOnIssue: {
    label: 'Pro rata billing on issue',
    description: 'Policies will be billed a pro rata amount on issue, instead of on the customer\'s chosen billing day.',
    indent: 1,
    path: 'billing.proRataBillingOnIssue',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'proRataBillingOnIssue',
  },
  proRataMinimum: {
    label: 'Pro rata minimum (cents)',
    description:
      'The minimum amount (in cents) that will be billed on a pro rata payment.',
    indent: 1,
    path: 'billing.proRataMinimum',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'proRataMinimum',
  },
  billBeforeWeekend: {
    label: 'Billing before weekend is enabled',
    description:
      'If a billing day falls on a Sunday or public holiday, the payment will strike on 1 process day prior to the billing date',
    indent: 0,
    path: 'billing.billBeforeWeekend',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'billBeforeWeekend',
  },
  naedoArrearsPolicies: {
    label: 'NAEDO billing is enabled',
    description: 'Policies in arrears will be billed using NAEDO',
    indent: 0,
    path: 'billing.naedoArrearsPolicies',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'naedoArrearsPolicies',
  },
  shouldCreateExternalPayments: {
    label: 'Creating external payments is enabled',
    description: 'External payments will be created by platform',
    indent: 0,
    path: 'billing.shouldCreateExternalPayments',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'shouldCreateExternalPayments',
  },
  currency: {
    label: 'Currency',
    description: '',
    indent: 0,
    path: 'billing.currency',
    condition: TypesOfBillingConditions.equals,
    key: 'currency',
    options: [
      {
        label: 'ZAR',
        value: 'ZAR',
      },
      {
        label: 'USD',
        value: 'USD',
      },
      {
        label: 'GBP',
        value: 'GBP',
      },
      {
        label: 'EUR',
        value: 'EUR',
      },
      {
        label: 'KRW',
        value: 'KRW',
      },
      {
        label: 'JPY',
        value: 'JPY',
      },
      {
        label: 'CNY',
        value: 'CNY',
      },
      {
        label: 'INR',
        value: 'INR',
      },
    ],
  },
  premiumType: {
    label: 'Premium type',
    description: '',
    indent: 0,
    path: 'billing.premiumType',
    condition: TypesOfBillingConditions.equals,
    key: 'premiumType',
    options: [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Once off',
        value: 'once_off',
      },
    ],
  },
  paymentReference: {
    label: 'Payment reference',
    description: 'For Nedbank this needs to match the CPS ShortName',
    indent: 0,
    path: 'billing.paymentReference',
    condition: TypesOfBillingConditions.equals,
    key: 'paymentReference',
  },
  paymentSubmissionLeadTime: {
    label: 'Payment submission lead time',
    description: 'Number of days a payments should be submitted prior to their action date - currently only applied for the "same-day billing strategy".',
    indent: 0,
    path: 'billing.paymentSubmissionLeadTime',
    condition: TypesOfBillingConditions.equals,
    key: 'paymentSubmissionLeadTime',
  },
  retryFailedPayments: {
    label: 'Retry failed payments',
    description:
      'Specify the number of payment attempts before it is set to failed.',
    indent: 0,
    path: 'billing.retries.retryFailedPayments',
    condition: TypesOfBillingConditions.equals,
    key: 'retryFailedPayments',
    reset: [
      {
        path: 'billing.retries.daysBetweenFailedPaymentRetries',
        default: 0,
        comparisonValues: [0, null],
      },
    ],
  },
  daysBetweenFailedPaymentRetries: {
    label: 'Period between retries',
    description: 'Specify the number of days between payment attempts.',
    indent: 1,
    index: 1,
    path: 'billing.retries.daysBetweenFailedPaymentRetries',
    condition: TypesOfBillingConditions.equals,
    key: 'daysBetweenFailedPaymentRetries',
  },
  debitOrderPaymentStrategy: {
    label: 'Debit order payment strategy',
    description: '',
    indent: 0,
    path: 'billing.primaryMethod.strategy',
    condition: TypesOfBillingConditions.equals,
    key: 'debitOrderPaymentStrategy',
    options: [
      {
        label: 'Best effort',
        value: 'best_effort',
      },
      {
        label: 'Same day',
        value: 'same_day',
      },
      {
        label: 'Two day',
        value: 'two_day',
      },
      {
        label: 'DebiCheck',
        value: 'debicheck',
      },
    ],
    reset: [
      {
        path: 'billing.primaryMethod.type',
        default: 'debit_order',
        comparisonValues: [
          'best_effort',
          'same_day',
          'two_day',
          'naedo',
          'debicheck',
        ],
      },
    ],
  },
  enableBillingOnSandbox: {
    label: 'Enable billing runs on Sandbox',
    description: 'Enable billing runs in Sandbox environment',
    indent: 0,
    path: 'billing.enableBillingOnSandbox',
    condition: TypesOfBillingConditions.equals,
    displayCheckValue: true,
    key: 'enableBillingOnSandbox',
  },
  // beneficiarieRequiredAtIssuing: {
  //   label: 'Beneficiaries are required at policy issuing',
  //   description:
  //     'You will not be able to complete issuing a policy without capturing all required beneficiary details',
  //   indent: 1,
  // },
};
