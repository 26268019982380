import React from 'react';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import { Container, CardBody, Button } from 'reactstrap';
import PageHeader from '../../../components/page-header';
import { Router, Route } from 'react-router';
import { FeatureListStore } from '../../stores/feature-list-store';
import { observer, inject } from 'mobx-react';
import { AddFeatureFlag } from './new-feature-modal';
import RenderFeatureTable from './feature-table';

const sideBarSchema = {
  name: 'Features',
  routes: () => {
    return [
      {
        name: 'All features',
        url: '/features',
      },
    ];
  },
};

interface Props {
  router: Router;
  route: Route;
  featureListStore?: FeatureListStore;
}

interface State {
  showNewFeatureFlag: boolean;
  showViewFeature: boolean;
  description: string;
  sandboxByDefault: boolean;
  featureKey: string;
}

@inject('featureListStore')
@observer
export class FeatureList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showNewFeatureFlag: false,
      showViewFeature: false,
      description: '',
      sandboxByDefault: false,
      featureKey: '',
    };
  }

  componentDidMount() {
    const { featureListStore } = this.props;
    if (featureListStore) {
      featureListStore.load();
    }
  }

  renderEditInput = () => {
    const { featureListStore } = this.props;
    if (featureListStore) {
      return (
        <div className='pull-right'>
          <Button
            color='primary'
            onClick={() => this.setState({ showNewFeatureFlag: true })}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            disabled={featureListStore.isLoading}
          >
            New
          </Button>
        </div>
      );
    }
  };

  render() {
    const { showNewFeatureFlag } = this.state;
    const { featureListStore } = this.props;
    if (!featureListStore) {
      return <div>Loading...</div>;
    }
    return (
      <div className='container-with-sidebar'>
        {showNewFeatureFlag && (
          <AddFeatureFlag
            close={() => {
              this.setState({ showNewFeatureFlag: false });
            }}
            open={this.state.showNewFeatureFlag}
          />
        )}
        {/* leaving this here as will impliment next */}
        {/* {showViewFeature && (
          <ViewFeatureFlag
            close={() => {
              this.setState({ showViewFeature: false });
            }}
            featureData={{
              description: this.state.description,
              sandboxByDefault: this.state.sandboxByDefault,
              featureKey: this.state.featureKey,
            }}
            open={this.state.showViewFeature}
          />
        )} */}
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <Container style={{ backgroundColor: '#ffffff' }}>
          <div style={{ marginLeft: '1.5rem' }}>
            <br />
            <PageHeader
              title={'Summary'}
              rightComponent={this.renderEditInput()}
            />
          </div>
          <CardBody>
            <RenderFeatureTable
              rows={featureListStore.features}
              isLoaded={true}
              selected={(feature, index) =>
                this.setState({
                  featureKey: feature.featureKey,
                  description: feature.description,
                  sandboxByDefault: feature.sandboxByDefault,
                  showViewFeature: true,
                })
              }
            />
          </CardBody>
        </Container>
      </div>
    );
  }
}
