import React, { Fragment } from 'react';
import { Table, Badge } from 'reactstrap';
import {
  getRandValue,
  formatDate,
  paymentLink,
  policyPaymentsLink,
} from '../../helpers';
import { ReviewButton } from './payments-buttons/review-button';
import { CancelPaymentButton } from './payments-buttons/cancel-button';

export default class PaymentsTable extends React.Component {
  render() {
    const { payments, checkbox } = this.props;
    const [firstPayment] = this.props.payments;
    const hasPolicies = firstPayment && firstPayment.policy;

    return (
      <Table hover className='scrollable-table'>
        <thead>
          <tr>
            <td>
              Payment Id
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
            <td>
              Description
            </td>
            <td>
              Is retry
            </td>
            <td>
              Provider type
            </td>
            <td>
              Collection Type
            </td>
            <td>
              Payment type
            </td>
            <td>
              Reversed payment ID
            </td>
            <td>
              Payment method type
            </td>
            <td>
              Payment method status
            </td>
            <td>
              Created at
            </td>
            <td>
              Payment date
            </td>
            {hasPolicies && (
              <Fragment>
                <td>
                  Policy
                </td>
                <td>
                  Policy status
                </td>
              </Fragment>
            )}
            <td>Reviewed</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {checkbox
            ? payments
              .filter(payment => payment.status !== 'cancelled')
              .map(o => this.renderRow(o))
            : this.props.payments.map(o => this.renderRow(o))}
        </tbody>
      </Table>
    );
  }

  statusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <Badge color='warning'>{status}</Badge>;
      case 'processing':
        return <Badge color='warning'>{status}</Badge>;
      case 'submitted':
        return <Badge color='warning'>{status}</Badge>;
      case 'failed':
        return <Badge color='danger'>{status}</Badge>;
      case 'successful':
        return <Badge color='success'>{status}</Badge>;
      default:
        return <Badge>{status}</Badge>;
    }
  }

  getPaymentClass = ({ status, paymentMethod, policy }) => {
    if (status === 'cancelled') {
      return 'table-warning';
    }

    if (status === 'pending') {
      if (paymentMethod) {
        if (paymentMethod.type === 'debit_order') {
          if (paymentMethod.banvStatus === 'pending') {
            return 'table-warning';
          }

          if (paymentMethod.banvStatus === 'failed_verification') {
            return 'table-danger';
          }
        }
      }

      if (policy) {
        if (policy.status !== 'active') {
          return 'table-danger';
        }
      }
    }
  };

  renderRow = payment => {
    const classNames = this.getPaymentClass(payment);

    const hasPolicy = payment.policy;

    return (
      <tr key={payment.paymentId} className={classNames}>
        <td>{paymentLink(payment)}</td>
        <td>{this.statusBadge(payment.status)}</td>
        <td>R {getRandValue(payment.amount)}</td>
        <td>{payment.description}</td>
        <td>{payment.retryOf && <Badge>Retry</Badge>}</td>
        <td>{payment.billingProvider ? payment.billingProvider.providerType : '-'}</td>
        <td>{payment.collectionType ? payment.collectionType : '-'}</td>
        <td>{payment.paymentType || '-'}</td>
        <td>{payment.reversalOfPayment ? paymentLink(payment.reversalOfPayment) : '-'}</td>
        <td>{payment.paymentMethod ? payment.paymentMethod.type : '-'}</td>
        <td>
          {payment.paymentMethod ? payment.paymentMethod.banvStatus : '-'}
        </td>
        <td>{formatDate(payment.createdAt)}</td>
        <td>{formatDate(payment.paymentDate)}</td>
        {hasPolicy && (
          <Fragment>
            <td>{payment.policy && policyPaymentsLink(payment.policy)}</td>
            <td>{payment.policy && payment.policy.status}</td>
          </Fragment>
        )}
        <td>
          <ReviewButton
            disabled={payment.status !== 'pending'}
            payment={payment}
            refresh={this.props.refresh}
          />
        </td>
        <td>
          <CancelPaymentButton
            disabled={payment.status !== 'pending'}
            payment={payment}
            refresh={this.props.refresh}
          />
        </td>
      </tr>
    );
  };
}
