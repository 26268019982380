import React, { Component } from 'react';
import { ModalBody, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import '../../../../styles/code-window.scss';
import '../../../../styles/styles.scss';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import { getProductModuleDefinitionTerms } from '../../../product-modules-api';
import { getBase64FromFile } from '../../../../../helpers';
import { channelCompilers } from '../../../../../organizations/components/outbound-notifications/utils/channel-compilers';
import { mergeVarStubs } from '../../../../../organizations/components/outbound-notifications/merge-var-stubs';
import { FullScreenModal } from '../../../../../components/modals/full-screen';
import { FileModal } from '../../../../../components/file-modal';
import { GeneralModal } from '../../modals';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import LoadingInPage from '../../../../loading';
import TemplatePreview from '../../../template-preview';

interface TermsAndConditions {
  modalContent: {
    title: string;
    body: string;
    submit: string;
    cancel: string;
  };
}

interface Props {
  productModuleStore?: ProductModuleStore;
  toDbKey: string;
  productModuleKey: string;
  breadcrumbDocName: string;
  closeDocument: () => any;
}

@inject('productModuleStore')
@observer
class TermsAndConditions extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      editorKey: 0,
      open: true,
      content: '',
      showResetToPublished: true,
      productModule: {
        name: '',
      },
      showSaveModal: false,
      loadingHtml: false,
      channel: 'base64',
      templateBase64: '',
      showArchiveModal: false,
      processingDocument: false,
      testMergeVars: false,
      showFileModal: false,
    };

    this.modalContent = {
      title: 'Schema Saved',
      body: 'Your schema has successfully been saved',
      cancel: 'Cancel',
      submit: 'Submit',
    };
  }

  async componentWillMount() {
    const { productModuleStore, productModuleKey } = this.props;

    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);

      const productModuleDefinition =
        productModuleStore.productModuleDefinitionDraft;

      try {
        this.setState({ loadingHtml: true });
        const termsPdfResponse = await getProductModuleDefinitionTerms(
          productModuleDefinition.productModuleId,
          productModuleDefinition.productModuleDefinitionId,
        );

        const reader = termsPdfResponse.getReader();
        let chunk = await reader.read();
        let fullBase64: any = [];

        while (!chunk.done) {
          if (!chunk.done) {
            fullBase64 = [...fullBase64, ...(chunk.value || [])];
          }
          chunk = await reader.read();
        }

        const templateBase64 = btoa(
          new Uint8Array(fullBase64).reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, ''),
        );

        this.setState({ templateBase64, loadingHtml: false });
      } catch (error) {
        console.debug('error', error);
        this.setState({ loadingHtml: false });
      }
    }

    this.setState({
      editorkey: this.state.editorKey + 1,
    });
  }

  uploadPolicyTerms = async (file: File) => {
    const { productModuleStore } = this.props;

    try {
      const base64 = await getBase64FromFile(file);

      const base64File = base64.split('data:application/pdf;base64,')[1];
      const fileUpload = {
        file_name: file.name,
        description: 'Product module definition terms and conditions',
        file_base64: base64File,
        file_type: file.type,
      };

      const data = {
        terms_file: fileUpload,
      };
      if (productModuleStore) {
        const { productModule } = productModuleStore;

        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModule.productModuleId,
          data,
        });
      }

      this.setState({ templateBase64: base64File });
    } catch (err) {
      console.log('error sending to review', err);
    }
  };

  // revertDraft = async () => {
  //   const productDefinitionResponse = (await ajax({
  //     type: 'GET',
  //     path: `/insurance/admin/product-module-definition/
  //     ${this.state.productModule.product_module_id}/${this.state.productModule.live_id}`
  //   }));

  //   this.setState({
  //     templateHTML: productDefinitionResponse.terms_file_id,
  //     templateHtmlOriginal: productDefinitionResponse.terms_file_id
  //   }, () => {
  //     this.closeArchiveModal();
  //   });
  // }

  closeArchiveModal = () => {
    this.setState({ showArchiveModal: false });
  };

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  submitModal = () => {
    this.closeSaveModal();
  };

  MergeVarsIntoHtml = async () => {
    const { templateHTML } = this.state;
    const response = await channelCompilers.html(
      templateHTML,
      mergeVarStubs.policyholder,
    );
    return response;
  };

  handleMergeVarsChange = (data: any) => {
    this.setState({ mergeVars: data });
  };

  closeModal() {
    this.setState({ showFileModal: false });
  }

  toggle = () => {
    this.setState({ showFileModal: true });
  };

  render() {
    const { productModuleStore, productModuleKey } = this.props;
    if (!productModuleStore || !productModuleStore.isLoaded) {
      return <LoadingInPage />;
    }
    const lockedComponent = productModuleStore.lockedComponent;

    return (
      <FullScreenModal
        isOpen={this.state.open}
        style={{ margin: 0, padding: 0 }}
      >
        <FileModal
          open={this.state.showFileModal}
          onClose={this.closeModal.bind(this)}
          handleSubmit={this.uploadPolicyTerms.bind(this)}
          inputLabel='Select terms and conditions document'
          confirmMessage={file =>
            `Are you sure PDF file '${file.name}' is a terms and conditions file`
          }
          header='Upload Terms PDF'
          description=''
          // submitText='Upload terms and conditions'
          accept='.pdf'
        />
        {/* <RevertDraftModal
          show={this.state.showArchiveModal}
          onClose={this.closeArchiveModal}
          revertDraft={this.revertDraft}
        /> */}
        <GeneralModal
          show={this.state.showSaveModal}
          onclose={this.closeSaveModal}
          submitModal={this.submitModal}
          modalContent={this.modalContent}
        />
        <div className='Rectangle-Copy-9'>
          <div className='Rectangle' style={{ display: 'inline-block' }}>
            <a
              onClick={e => {
                e.preventDefault;
                this.props.closeDocument();
              }}
            >
              <Icon24PXCross />
            </a>
          </div>
          <div style={{ display: 'inline-block', paddingTop: 17 }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  href='/product-modules'
                >
                  Product modules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  href={`/product-modules/${productModuleKey}`}
                >
                  {productModuleStore.productModule.name}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  onClick={e => {
                    e.preventDefault;
                    this.props.closeDocument();
                  }}
                >
                  Product definition
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Terms and conditions</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <ModalBody className='no-padding' style={{ padding: 0 }}>
          {
            <div style={{ position: 'absolute' }}>
              <Button
                style={{ width: 155, height: 40 }}
                color='primary'
                outline
                disabled={lockedComponent}
                className='merge-vars-button'
                onClick={() => this.toggle()}
              >
                Upload pdf
              </Button>
            </div>
          }
          <div className='template-editor'>
            <div
              className='template-editor template-preview'
              style={{ justifyContent: 'center' }}
            >
              {this.state.loadingHtml && <LoadingInPage left={'50%'} />}
              {!this.state.loadingHtml && (
                <TemplatePreview
                  // disableBadge={this.toggleDisabled}
                  title='Upload PDF'
                  content={`data:application/pdf;base64,${this.state.templateBase64}`}
                  channel={this.state.channel}
                  documentName={this.props.productModuleKey}
                />
              )}
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          {
            <div className='full-icon'>
              <Icon18PXCog />
            </div>
          }

          <div className='text'>
            Policy schedule
            {this.isEditing && <small>Edited a few seconds ago</small> // {this.lastEditedDate}
            }
            {this.isPreview && <small> {this.publishedDate}</small>}
          </div>
          {this.isEditing && (
            <div className='right-content'>
              {this.state.productModule.live_id && !this.state.processingDocument && this.state.showResetToPublished
              && <div onClick={() => this.setState({showArchiveModal: true})}
               style={{ color: '#2259f9', cursor: 'pointer' }} className='text'>
                Revert to published version
              </div>}
            </div>
          )}
        </ModalFooter> */}
      </FullScreenModal>
    );
  }
}

export default TermsAndConditions;
