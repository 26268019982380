import React from 'react';
import { FormGroup, CardBody, Col, Row } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { GracePeriodRulesConfig } from './config';
import GracePeriodCompletedChild from './components/grace-period-completed-child';
import { GracePeriodOutput } from '../../store-interfaces';
import { nestedStyleCheckbox } from '../../../../utils';
import { ProductModuleComponentLoadingStateStore } from '../../../../../../stores/product-module-component-loading-state-store';

interface Props {
  gracePeriodRules: GracePeriodOutput;
  gracePeriodNotifications?: GracePeriodOutput | null;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface State {}
@inject('productModuleComponentLoadingStateStore')
@observer
class GracePeriodCompletedCard extends React.Component<Props, State> {
  getValue = (index: number, path: string, dropdown: boolean = false) => {
    let value = _.get(this.props.gracePeriodRules[index], path);
    if (value && dropdown) {
      value = _.startCase(value).toLowerCase();
    }
    return value && value !== undefined ? value : null;
  };

  renderChildren = (storeVariable: GracePeriodOutput | null) => {
    const components = [];

    if (storeVariable) {
      for (let i = 0; i < Object.keys(storeVariable).length; i++) {
        const value = this.getValue(
          i,
          GracePeriodRulesConfig.applyGracePeriodOf.outputPaths.value,
        );
        const lapseAfter = this.getValue(
          i,
          GracePeriodRulesConfig.lapseAfter.outputPaths,
          true,
        );
        const skippedPayments = this.getValue(
          i,
          GracePeriodRulesConfig.skippedPayments.outputPaths,
          true,
        );
        const type = this.getValue(
          i,
          GracePeriodRulesConfig.applyGracePeriodOf.outputPaths.type,
        );

        components.push(
          <div key={`grace_period_${storeVariable}_${i}`}>
            <GracePeriodCompletedChild
              lapseAfter={lapseAfter}
              type={type}
              value={value}
              skippedPayments={skippedPayments}
              index={i}
            />
          </div>,
        );
      }
    }
    return components;
  };

  render() {
    const { gracePeriodRules, gracePeriodNotifications } = this.props;
    const notificationsLength = gracePeriodNotifications
      ? Object.keys(gracePeriodNotifications).length
      : null;
    const rulesLength = Object.keys(gracePeriodRules).length;

    return (
      <Row>
        <Col>
          <CardBody
            className='white-background'
            style={{ marginLeft: -17, marginBottom: 0, paddingBottom: 0 }}
          >
            <FormGroup row>
              <p className='product-module-heading-description-spacing'>
                The conditions under which a policy will be lapsed after
                non-payment of a premium.
              </p>
            </FormGroup>
            {rulesLength && (
              <CardBody style={nestedStyleCheckbox(true, 1)}>
                <h5>Grace period rules</h5>
                <hr className='horizontal-line-without-padding-bottom' />
                {this.renderChildren(gracePeriodRules)}
              </CardBody>
            )}
          </CardBody>
          {notificationsLength && notificationsLength > 0 && (
            <div>
              <CardBody
                className='white-background'
                style={{ paddingBottom: 0, marginBottom: -14 }}
              >
                <h5>Notifications</h5>
                <hr />
              </CardBody>
              <CardBody className='white-background'>
                <FormGroup inline style={{ marginBottom: 0 }}>
                  {this.renderChildren(gracePeriodNotifications || null)}
                </FormGroup>
              </CardBody>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default GracePeriodCompletedCard;
