import React from 'react';
import { Input, CardBody, Row, Col, Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ProductModuleStore } from '../../stores/product-module-store';
import '../../../../styles/styles.scss';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import _ from 'lodash';
import LoadingInPage from '../../../../loading';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../stores/product-module-component-loading-state-store';
import { debounce } from '../../../../../helpers/debounce';
import { Icon16PXTick } from '../../../../../components/icons/icon-16-px-tick';
import uuid from 'uuid';
import { SavingState } from '../../util';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface State {
  showSchema: boolean;
  claimChecklistItems: string[];
}

let timeout: any = null;

@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionClaimChecklist extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSchema: false,
      claimChecklistItems: [],
    };
  }
  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
      const {
        claimChecklistItems,
      } = productModuleStore.productModuleDefinitionDraft.settings.claims;
      this.setState({ claimChecklistItems });
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.productModuleSettingsComponents,
        'ChecklistQuestions',
      );
    }
  }

  removeListItem = async (index: number) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        'ChecklistQuestions',
        SavingState.Saving,
      );
    }

    if (productModuleStore) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const { claimChecklistItems } = settings.claims;
      claimChecklistItems.splice(index, 1);
      _.set(settings, 'claims.claimChecklistItems', claimChecklistItems);
      this.setState({ claimChecklistItems });
      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: { settings_json: settings },
      });
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleSettingsComponents,
        'ChecklistQuestions',
        SavingState.Saved,
      );

      debounce(
        'ChecklistQuestions',
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleSettingsComponents,
            'ChecklistQuestions',
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  addListItem = async () => {
    const { productModuleStore } = this.props;
    if (productModuleStore) {
      this.setState({
        claimChecklistItems: [...this.state.claimChecklistItems, ''],
      });
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const { claimChecklistItems } = settings.claims;
      claimChecklistItems.push('');

      _.set(settings, 'claims.claimChecklistItems', claimChecklistItems);
      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: { settings_json: settings },
      });
    }
  };

  updateListItem = (text: string, index: number) => {
    const claimChecklistItems = this.state.claimChecklistItems;
    claimChecklistItems[index] = text;
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          'ChecklistQuestions',
          SavingState.Saving,
        );
      }
      if (productModuleStore) {
        const { settings } = productModuleStore.productModuleDefinitionDraft;
        const { claimChecklistItems } = settings.claims;
        claimChecklistItems[index] = text;

        _.set(settings, 'claims.claimChecklistItems', claimChecklistItems);
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
      }
      if (productModuleComponentLoadingStateStore) {
        productModuleComponentLoadingStateStore.updateComponentLoadingState(
          StoreIndex.productModuleSettingsComponents,
          'ChecklistQuestions',
          SavingState.Saved,
        );

        debounce(
          'ChecklistQuestions',
          () =>
            productModuleComponentLoadingStateStore.updateComponentLoadingState(
              StoreIndex.productModuleSettingsComponents,
              'ChecklistQuestions',
              SavingState.Default,
            ),
          3000,
        );
      }
    }, 1200);
  };

  checklistQuestionsMap = (lockedComponent: boolean) => {
    if (this.state.claimChecklistItems) {
      return this.state.claimChecklistItems.map((claimChecklistItem, index) => {
        return (
          <div
            style={{ width: 530 }}
            key={`claim-checklist ${uuid().toString()}`}
          >
            <a
              style={{ float: 'right' }}
              onClick={e => {
                e.preventDefault;
                if (!lockedComponent) {
                  this.removeListItem(index);
                }
              }}
            >
              <Icon24PXCross />
            </a>
            <Input
              style={{ marginBottom: 10, width: 500, minHeight: 40 }}
              type='textarea'
              id={`claim-checklist ${index}`}
              defaultValue={claimChecklistItem}
              onChange={e => this.updateListItem(e.target.value, index)}
              disabled={lockedComponent}
            />
          </div>
        );
      });
    }
    return <div></div>;
  };

  render() {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (!productModuleStore || !productModuleComponentLoadingStateStore) {
      return <LoadingInPage />;
    }

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleSettingsComponents[
        'ChecklistQuestions'
      ];

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Claim checklists
              </p>
            </Col>
            <Col xs={9}>
              <p>Claim checklists</p>
              <span>
                The claims checklist is a useful tool for guiding assessors and
                supervisors in processing claims. The checklist is a list of
                questions that the claims assessor can mark a positive or
                negative response to, and add notes before submitting their
                recommended claim outcome to the claims supervisor. Use this
                tool to define the checklist questions. Write your checklist
                questions so that “Yes” is the intended successful response.
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={9}>
              <p
                style={{
                  paddingTop: 10,
                  paddingBottom: 5,
                  verticalAlign: 'middle',
                  paddingRight: 10,
                  display: 'inline-block',
                }}
              >
                Checklist questions
              </p>

              <div className='product-module-definitions-enable-item-checkbox '>
                {saving === SavingState.Saving && (
                  <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                    Saving
                  </div>
                )}
                {saving === SavingState.Saved && (
                  <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                    <Icon16PXTick
                      style={{ marginRight: 5, paddingBottom: 3 }}
                    />
                    Saved
                  </div>
                )}
              </div>
              <div>
                {this.checklistQuestionsMap(productModuleStore.lockedComponent)}
              </div>
              <Button
                color='primary'
                className='product-module-definition-horizontal-card-button'
                style={{ marginTop: 10 }}
                onClick={e => this.addListItem()}
                disabled={productModuleStore.lockedComponent}
              >
                Add question
              </Button>
            </Col>
          </Row>
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
