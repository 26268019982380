/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='222px'
        height='70px'
        viewBox='0 0 222 60'
        enableBackground='new 0 0 222 60'
        xmlSpace='preserve'
      >
        <g>
          <line
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='188.127'
            y1='7.352'
            x2='188.127'
            y2='16.833'
          />
          <line
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='192.867'
            y1='12.093'
            x2='183.385'
            y2='12.093'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='167.139'
            y1='2.929'
            x2='167.139'
            y2='9.232'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='170.289'
            y1='6.081'
            x2='163.986'
            y2='6.081'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='178.299'
            y1='38.495'
            x2='178.299'
            y2='43.051'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='180.578'
            y1='40.773'
            x2='176.021'
            y2='40.773'
          />
          <circle
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            cx='150.974'
            cy='10.671'
            r='1.558'
          />
          <circle
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            cx='178.413'
            cy='24.825'
            r='2.265'
          />
          <g>
            <path
              fill='none'
              d='M59.452,52.653c0,0.976,0.755,1.769,1.697,1.769h99.708c0.935,0,1.69-0.793,1.69-1.769V25.19
                c0-0.976-0.756-1.768-1.69-1.768H61.149c-0.942,0-1.697,0.792-1.697,1.768V52.653z'
            />
            <path
              fill='none'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeMiterlimit='10'
              strokeDasharray='4.0183,4.0183'
              d='M59.452,52.653
                c0,0.976,0.755,1.769,1.697,1.769h99.708c0.935,0,1.69-0.793,1.69-1.769V25.19c0-0.976-0.756-1.768-1.69-1.768H61.149
                c-0.942,0-1.697,0.792-1.697,1.768V52.653z'
            />
          </g>
          <g>
            <line
              fill='none'
              stroke='#B5BDC6'
              strokeWidth='2'
              strokeMiterlimit='10'
              x1='76.791'
              y1='34.571'
              x2='76.791'
              y2='43.272'
            />
            <line
              fill='none'
              stroke='#B5BDC6'
              strokeWidth='2'
              strokeMiterlimit='10'
              x1='81.143'
              y1='38.922'
              x2='72.441'
              y2='38.922'
            />
          </g>
          <text
            transform='matrix(1 0 0 1 92.9609 44.0508)'
            fill='#B5BDC6'
            fontFamily='ProximaNova-Semibold'
            fontSize='14'
          >
            Add Tag
          </text>
        </g>
      </svg>
    );
  }
}
