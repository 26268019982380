import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import { get } from '../helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    const env = runtimeEnv();
    const url = env.REACT_APP_COLLECT_HOST + '/accounts';
    const accounts = await get(url);
    this.setState({ accounts });
  }

  renderAccountRow(account) {
    return (
      <tr
        key={account.accountId}
        onClick={() =>
          this.props.router.push('/collect/accounts/' + account.accountId)
        }
      >
        <td>{account.accountHolderName}</td>
        <td>{account.bankName}</td>
        <td>{account.last4}</td>
        <td>{moment(account.createdAt).format('DD MMM YYYY')}</td>
      </tr>
    );
  }

  render() {
    return (
      <Card>
        <CardHeader>
          Accounts
          {/* <Button color='primary' className='pull-right'>
          <Icon name='plus' /> New Account
        </Button> */}
        </CardHeader>
        <CardBody>
          {this.state.accounts ? (
            <Table hover>
              <thead>
                <tr>
                  <th>Account Holder</th>
                  <th>Bank</th>
                  <th>Last 4</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {this.state.accounts.map(o => this.renderAccountRow(o))}
              </tbody>
            </Table>
          ) : (
            <div>
              <i>Loading accounts...</i>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(Accounts);
