import { policyholder } from './policyholder';
import { policy } from './policy';
import { claim } from './claim';
import { complaint } from './complaint';
import { payment } from './payment';
import { policyCharges } from './policy-charges';

export const mergeVarStubs = {
  policyholder,
  policy,
  claim,
  complaint,
  payment,
  policyCharges,
};
