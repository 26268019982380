import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { FormStore } from '../../formStore';

@observer
export default class Radio extends Component<any, any> {
  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, null);
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, null);
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  componentDidMount() {
    const { keyVal, formStore, validators } = this.props;
    formStore.updateValidTotal(
      keyVal,
      formStore.inputState[keyVal],
      validators,
    );
  }

  onChange = (value: string) => {
    this.props.onRadioInputChange(value);
  };

  selectedValue = (formStore: FormStore) => {
    const { keyVal } = this.props;
    if (formStore.inputState[keyVal]) {
      this.props.onRadioInputChange(formStore.inputState[keyVal]);
      return formStore.inputState[keyVal];
    } else if (this.props.defaultValue) {
      this.props.onRadioInputChange(this.props.defaultValue);
      return this.props.defaultValue;
    } else {
      return false;
    }
  };

  radioButtonsRender = () => {
    const { keyVal } = this.props;
    return (
      <div style={{ marginLeft: -15, paddingTop: 10 }}>
        {this.props.options.map((o: any) => (
          <FormGroup
            style={{ display: 'inline-block' }}
            check
            key={o.value}
            onClick={() => this.onChange(o.value)}
          >
            <Label check style={{ display: 'inline-block' }}>
              <Input
                key={keyVal}
                id={keyVal}
                className='form-control'
                name={keyVal}
                type='radio'
                value={this.selectedValue(this.props.formStore)}
                style={{ display: 'inline-block', marginRight: 10 }}
                defaultChecked={
                  o.value === this.selectedValue(this.props.formStore)
                }
                readOnly={this.props.readonly ? this.props.readonly : false}
                disabled={this.props.disabled ? this.props.disabled : false}
                autoFocus={this.props.autoFocus}
              />
              <div style={{ display: 'inline-block' }}>{o.label}</div>
            </Label>
          </FormGroup>
        ))}
      </div>
    );
  };

  render() {
    const { label } = this.props;
    return (
      <div className='form-group col-sm-6'>
        <FormGroup key={this.props.name} tag='fieldset'>
          {this.props.label !== '' && (
            <Label className={this.props.bold ? 'bold' : ''}>{label}</Label>
          )}
          {this.radioButtonsRender()}
        </FormGroup>
      </div>
    );
  }
}
