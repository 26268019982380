import moment, { Moment } from 'moment';
import { Construct } from '../../shared/domain/construct';

export class ProductModuleCodeRunLogItem {
  readonly productModuleCodeRunId: string;
  readonly createdAt: Moment;
  readonly level: LogLevel;
  readonly content: string;

  constructor(init: Construct<ProductModuleCodeRunLogItem>) {
    Object.assign(this, init);
  }

  static fromNetwork(
    init: NetworkProductModuleCodeRunLogItem,
  ): ProductModuleCodeRunLogItem {
    return new ProductModuleCodeRunLogItem({
      productModuleCodeRunId: init.product_module_code_run_id,
      createdAt: moment(init.created_at),
      level: init.level,
      content: init.content,
    });
  }
}

export interface NetworkProductModuleCodeRunLogItem {
  product_module_code_run_id: string;
  created_at: string;
  level: LogLevel;
  content: string;
}

export enum LogLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Debug = 'debug',
}
