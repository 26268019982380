import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Alert,
} from 'reactstrap';
import Button from '../../components/loading-button';

class ResetLinkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedResetLink: false,
    };
  }

  copyResetLink() {
    this.setState({ copiedResetLink: false });
    const input = document.getElementById('reset-link-text-area');
    input.select();

    input.setSelectionRange(0, this.props.resetLink.length);

    try {
      document.execCommand('copy');
      this.setState({ copiedResetLink: true });
      setTimeout(() => {
        this.setState({ copiedResetLink: false });
      }, 2000);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { copiedResetLink } = this.state;
    const { open, close, email, resetLink } = this.props;

    return (
      <Modal isOpen={open} onClosed={close}>
        <ModalHeader>{`Account reset link - ${email}`}</ModalHeader>
        <ModalBody>
          This link will only be valid for 24 hours
          <Input
            id='reset-link-text-area'
            type='text'
            value={resetLink}
            readOnly
          />
          {copiedResetLink && <Alert color='primary'>Copied!</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Cancel</Button>
          <Button color='primary' onClick={this.copyResetLink.bind(this)}>
            Copy to clipboard
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ResetLinkModal;
