import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

interface FileModalProps {
  open: boolean;
  header: string;
  description: string;
  inputLabel: string;
  accept?: any;
  onClose: () => void;
  confirmMessage: (file: any) => string;
  handleSubmit: (file: any) => void;
}

interface FileModalState {
  show: boolean;
  file: any;
  error: string | null;
  loading: boolean;
}

export class FileModal extends React.Component<FileModalProps, FileModalState> {
  constructor(props: FileModalProps) {
    super(props);
    this.state = {
      show: props.open || false,
      file: null,
      error: null,
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps: FileModalProps) {
    if (this.props.open === false && nextProps.open === true) {
      this.setState({ show: true, file: null, error: null });
    }
  }

  close() {
    this.setState({ show: false });
    this.props.onClose();
  }

  async submit() {
    const file = this.state.file;
    if (!file) {
      return this.setState({ error: 'No file selected' });
    }
    if (!window.confirm(this.props.confirmMessage(file))) {
      return;
    }
    this.setState({ loading: true });
    try {
      await this.props.handleSubmit(file);
      this.setState({ loading: false });
      this.close();
    } catch (e) {
      this.setState({ error: JSON.stringify(e, null, 2), loading: false });
    }
  }

  onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.setState({ file: e.target.files[0], error: null });
    }
  };

  render() {
    return (
      <Modal isOpen={this.state.show} onClosed={this.close.bind(this)}>
        <ModalHeader>{this.props.header}</ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <Label>{this.props.inputLabel}</Label>
              <Input
                type='file'
                onChange={e => this.onFileChange(e)}
                accept={
                  this.props.accept ? this.props.accept.toString() : '.csv'
                }
              />
            </FormGroup>
          </form>
          <p>{this.props.description}</p>
          {this.state.error && (
            <p style={{ color: 'red' }}>Error: {this.state.error}</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            outline
            disabled={this.state.loading}
            onClick={this.close.bind(this)}
          >
            Cancel
          </Button>
          <Button color='primary' onClick={this.submit.bind(this)}>
            {this.state.loading ? 'Loading...' : 'Submit'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
