import React from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import PaymentMethodsTable from '../../../payment-methods/components/payment-methods-table';
import { withData } from '../../../helpers';

class PolicyholderPaymentMethods extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading payment methods...</i>
        </div>
      );
    }

    if (this.props.paymentMethods && this.props.paymentMethods.length === 0) {
      return (
        <Card>
          <CardBody>No payment methods</CardBody>
        </Card>
      );
    }

    return (
      <div>
        <Container style={{ padding: 0 }}>
          <Card>
            <CardBody>
              <PaymentMethodsTable paymentMethods={this.props.paymentMethods} />
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

export default withData({
  path: props =>
    `insurance/admin/payment-methods?policyholder_ids=${
      props.params.policyholderId
    }`,
  prop: 'paymentMethods',
})(PolicyholderPaymentMethods);
