export const FormOptions = {
  allowListRules: false,
  allowListNotifications: false,
  showNotifications: false,
  startingCoolingOffPeriodRules: 1,
  maxCountCoolingOffPeriodRules: 1,
};

export const CoolingOffPeriodRulesConfig = {
  refundThePolicyHolder: {
    selectOptions: [
      {
        label: 'All premuims paid to date',
        value: 'all_premiums',
      },
      {
        label: 'None',
        value: 'none',
      },
    ],
    outputPaths: 'refundType',
  },
  applyTo: {
    selectOptions: [
      {
        label: 'The full policy',
        value: 'the_full_policy',
      },
    ],
    outputPaths: 'applyTo',
  },
  applyCoolingOffPeriodOf: {
    selectOptions: {
      metric: [
        {
          label: 'Days',
          value: 'days',
        },
        {
          label: 'Months',
          value: 'months',
        },
      ],
      period: [
        {
          label: 'The full policy',
          value: 'the_full_policy',
        },
      ],
    },
    outputPaths: {
      type: 'period.type',
      value: 'period.value',
    },
  },
};
