import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TemplateViewStore } from '../../stores/template-view-store';
import { observer, inject } from 'mobx-react';
import { DataExportListStore } from '../../stores/data-export-list-store';

interface Props {
  close: () => void;
}

interface Injected extends Props {
  templateViewStore: TemplateViewStore;
  dataExportListStore: DataExportListStore;
}

interface State {
  activeExports: any;
}

@inject(
  'templateViewStore',
  'dataExportListStore',
)
@observer
export class ArchiveTemplateModal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const { templateViewStore, dataExportListStore } = this.injected;

    this.state = {
      activeExports: templateViewStore.template && templateViewStore.template.getActiveExports(dataExportListStore.scheduledDataExports).length,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    return (
      <Modal isOpen={true} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          Archive template
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          {this.state.activeExports > 0 && (
            <p>
              This template is actively being used in data exports and cannot be archived.
            </p>
          )}
          {this.state.activeExports === 0 && (
            <p>
              This template is not currently being used in any data
              exports and can safely be archived.
            </p>
          )}
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button color='danger' disabled={this.state.activeExports > 0} onClick={this.submit}>
            Archive
          </Button>
          <Button color='link' onClick={this.props.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  submit = async () => {
    const { templateViewStore } = this.injected;
    const result = await templateViewStore.archive({ organizationId: this.organizationId });

    if (result && result.error) {
      return console.error(result.error);
    }
    this.props.close();
    window.location.pathname = `/organizations/${this.organizationId}/exports`;
  }
}
