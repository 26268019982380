export const policy = {
  policy_id: 'bac3db77-56fa-432c-8e87-afc2e666ed70',
  scheme_type: 'individual',
  created_at: '2019-03-18T13:23:21.793Z',
  created_by: {
    type: 'api_key',
    id: '05f00887-5b47-469b-83a7-1f03ffea95a1',
    owner_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  policy_number: '1J1NY5IG6Q',
  policyholder_id: '4e42531a-8304-4073-ad3f-803bce4fde84',
  package_name: 'Funeral Cover: Main Member',
  sum_assured: 3200000,
  base_premium: 2704,
  monthly_premium: 3477,
  billing_amount: 3477,
  billing_frequency: 'monthly',
  billing_day: 1,
  start_date: '2019-03-18T13:23:21.000Z',
  end_date: '2020-03-18T13:23:21.000Z',
  cancelled_at: null,
  reason_cancelled: null,
  app_data: null,
  module: {
    age: 24,
    type: 'root_funeral',
    cover_amount: 3200000,
    spouse_included: false,
    children_included: false,
    extended_family_included: false,
  },
  schedule_versions: [{ version: 1, created_at: '2019-03-18T13:23:28.739Z' }],
  current_version: 1,
  terms_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/terms/terms.pdf',
  policy_schedule_uri:
    'http://127.0.0.1:4000/v1/insurance/policies/bac3db77-56fa-432c-8e87-afc2e666ed70/schedule/schedule_latest.pdf',
  claim_ids: [],
  complaint_ids: [],
  status: 'pending_initial_payment',
  balance: 0,
  currency: 'ZAR',
  policyholder: {
    policyholder_id: '4e42531a-8304-4073-ad3f-803bce4fde84',
    type: 'individual',
    first_name: 'Contact',
    last_name: 'Name',
    company_name: 'Test Inc',
    id: { type: 'id', number: '9410075071086', country: 'ZA' },
    email: 'elrich@root.co.za',
    cellphone: '+27823378000',
    phone_other: null,
    date_of_birth: '19941007',
    gender: 'male',
    created_at: '2018-03-19T21:19:52.000Z',
    app_data: { uid: 2 },
    policy_ids: [
      '68124a45-4a45-43c2-b3f2-16f61f6ce18e',
      'b0f5b527-6a18-435b-81d2-8aabe9b4b5b0',
      'bac3db77-56fa-432c-8e87-afc2e666ed70',
    ],
    created_by: { type: 'user', id: '00000000-0000-0000-0000-000000000001' },
  },
  beneficiaries: [
    {
      beneficiary_id: 'c4c9d61b-dfea-46c4-9090-aa57e48dd970',
      policyholder_id: 'e3ea736e-fb3e-4628-90d2-72eae2772c25',
      percentage: 100,
      relationship: 'other',
    },
  ],
};
