import { observable, action } from 'mobx';
import _ from 'lodash';
import {
  WaitingPeriodRulesOutput,
  WaitingPeriodNotificationsOutput,
  CoolingOffPeriodOutput,
  GracePeriodOutput,
} from './store-interfaces';

export enum Observables {
  coolingOffPeriodRules = 'coolingOffPeriodRules',
  waitingPeriodRules = 'waitingPeriodRules',
  waitingPeriodNotifications = 'waitingPeriodNotifications',
  gracePeriodRules = 'gracePeriodRules',
  renewalRules = 'renewalRules',
  premiumEscalationRules = 'premiumEscalationRules',
  expiryRules = 'expiryRules',
}

export interface LifecycleStore {
  coolingOffPeriodRules: CoolingOffPeriodOutput | {};
  coolingOffPeriodNotifications: any;
  waitingPeriodRules: WaitingPeriodRulesOutput | {};
  waitingPeriodNotifications: WaitingPeriodNotificationsOutput | {};
  gracePeriodRules: GracePeriodOutput | {};
  gracePeriodNotifications: any;
  renewalRules: any;
  renewalNotifications: any;
  premiumEscalationRules: any;
  premiumEscalationNotifications: any;
  expiryRules: any;
  expiryNotifications: any;
}

export enum LifecycleStoreVariables {
  coolingOffPeriod = 'cooling_off_period',
  waitingPeriod = 'waiting_period',
  gracePeriod = 'grace_period',
  renewal = 'renewal',
  premiumEscalation = 'premium_escalation',
  expiry = 'expiry',
  policyActivationEvent = 'policy_activation_event',
}

export interface LifecycleStoreOutput {
  [LifecycleStoreVariables.coolingOffPeriod]: {
    rules: CoolingOffPeriodOutput[] | [];
    notifications: [];
  };
  [LifecycleStoreVariables.waitingPeriod]: {
    rules: WaitingPeriodRulesOutput[] | [];
    notifications: WaitingPeriodNotificationsOutput[] | [];
  };
  [LifecycleStoreVariables.gracePeriod]: {
    rules: GracePeriodOutput[] | [];
    notifications: [];
  };
  [LifecycleStoreVariables.renewal]: {
    rules: [];
    notifications: [];
  };
  [LifecycleStoreVariables.premiumEscalation]: {
    rules: [];
    notifications: [];
  };
  [LifecycleStoreVariables.expiry]: {
    rules: [];
    notifications: [];
  };
  [LifecycleStoreVariables.policyActivationEvent]: {
    rules: [];
    notifications: [];
  };
}

export enum ObservablesTemp {
  waitingPeriodRulesTemp = 'waitingPeriodRulesTemp',
  waitingPeriodNotificationsTemp = 'waitingPeriodNotificationsTemp',
  coolingOffPeriodRulesTemp = 'coolingOffPeriodRulesTemp',
  gracePeriodRulesTemp = 'gracePeriodRulesTemp',
  renewalRulesTemp = 'renewalRulesTemp',
  premiumEscalationRulesTemp = 'premiumEscalationRulesTemp',
  expiryRulesTemp = 'expiryRulesTemp',
}

export interface LifecycleStoreTemp {
  waitingPeriodRulesTemp: WaitingPeriodRulesOutput;
  waitingPeriodNotificationsTemp: WaitingPeriodNotificationsOutput;
  coolingOffPeriodRulesTemp: CoolingOffPeriodOutput;
  gracePeriodRulesTemp: GracePeriodOutput;
  renewalRulesTemp: any;
  premiumEscalationRulesTemp: any;
  expiryRulesTemp: any;
}

export class Store {
  //observables ----------------------------
  @observable.deep lifecycleStore: LifecycleStore = {
    coolingOffPeriodRules: {},
    coolingOffPeriodNotifications: {},
    waitingPeriodRules: {},
    waitingPeriodNotifications: {},
    gracePeriodRules: {},
    gracePeriodNotifications: {},
    renewalRules: {},
    renewalNotifications: {},
    premiumEscalationRules: {},
    premiumEscalationNotifications: {},
    expiryRules: {},
    expiryNotifications: {},
  };

  @observable.deep lifecycleStoreTemp: LifecycleStoreTemp = {
    coolingOffPeriodRulesTemp: {},
    waitingPeriodRulesTemp: {},
    waitingPeriodNotificationsTemp: {},
    gracePeriodRulesTemp: {},
    renewalRulesTemp: {},
    premiumEscalationRulesTemp: {},
    expiryRulesTemp: {},
  };

  @observable loading: boolean = true;
  //actions ----------------------------

  @action updateTempToPrimary = (
    primary: Observables,
    temp: ObservablesTemp,
  ) => {
    const tempString = JSON.stringify(this.lifecycleStoreTemp);
    const tempParsed = JSON.parse(tempString);
    this.lifecycleStore[primary] = tempParsed[temp];
  };

  @action updatePrimaryToTemp = (
    primary: Observables,
    temp: ObservablesTemp,
  ) => {
    const tempString = JSON.stringify(this.lifecycleStore);
    const tempParsed = JSON.parse(tempString);
    this.lifecycleStoreTemp[temp] = tempParsed[primary];
  };

  @action updateLifeCycleStore = (
    lifecycleObservable: ObservablesTemp,
    path: string,
    value: string | null | undefined,
    index: number,
  ) => {
    const newKeyValue = _.set({}, path, value);
    this.lifecycleStoreTemp[lifecycleObservable][index] = _.merge(
      this.lifecycleStoreTemp[lifecycleObservable][index],
      newKeyValue,
    );
  };

  @action removeFromLifeCycleStore = (
    lifecycleObservable: ObservablesTemp,
    index: number,
  ) => {
    delete this.lifecycleStoreTemp[lifecycleObservable][index];

    for (
      var i = 0;
      i < Object.keys(this.lifecycleStoreTemp[lifecycleObservable]).length;
      i++
    ) {
      delete Object.assign(this.lifecycleStoreTemp[lifecycleObservable], {
        [i]: this.lifecycleStoreTemp[lifecycleObservable][i + 1],
      })[i + 1];
    }
  };

  @action removeInput = (
    observable: ObservablesTemp,
    path: string,
    index: number,
  ) => {
    deletePropertyPath(this.lifecycleStoreTemp[observable][index], path);
  };

  @action resetLifecycleStoreTemp = (lifecycleObservable: ObservablesTemp) => {
    this.lifecycleStoreTemp[lifecycleObservable] = {};
  };

  @action resetLifecycleStore = (lifecycleObservable: Observables) => {
    this.lifecycleStore[lifecycleObservable] = {};
  };

  @action loadData = (data: LifecycleStoreOutput) => {
    if (data && Object.keys(data).length > 0) {
      try {
        for (const key of Object.keys(Observables)) {
          this.lifecycleStore[key as Observables] = {
            ...data[key as LifecycleStoreVariables],
          };
        }
      } catch (err) {
        console.log('failed to parse json');
      }
    }
    this.loading = false;
  };
}

const deletePropertyPath = (obj: any, path: any) => {
  if (!obj || !path) {
    return;
  }

  if (typeof path === 'string') {
    path = path.split('.');
  }

  for (var i = 0; i < path.length - 1; i++) {
    obj = obj[path[i]];

    if (typeof obj === 'undefined') {
      return;
    }
  }

  delete obj[path.pop()];
};
