/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='240px'
        height='140px'
        viewBox='0 0 240 140'
        enableBackground='new 0 0 240 140'
        xmlSpace='preserve'
      >
        <g>
          <line
            fill='#CBDDF2'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='186.314'
            y1='14.268'
            x2='182.181'
            y2='18.402'
          />
          <line
            fill='#CBDDF2'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='176.881'
            y1='10.359'
            x2='176.881'
            y2='16.014'
          />
          <line
            fill='#CBDDF2'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='190.225'
            y1='23.701'
            x2='184.548'
            y2='23.701'
          />
        </g>
        <g>
          <path
            fill='none'
            stroke='#5E97FC'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeDasharray='2,5'
            d='
              M154.418,117.645c6.295,0,11.396-5.102,11.396-11.396c0-6.293-5.102-11.395-11.396-11.395H75.805
              c-14.051,0-25.443-11.393-25.443-25.445c0-14.051,11.393-25.443,25.443-25.443h65.979'
          />
          <g>
            <g>
              <path
                fill='#202B40'
                d='M91.247,131.305c-2.75,0-5-2.25-5-5V30.766c0-2.75,2.25-5,5-5h46.191c2.75,0,5,2.25,5,5v95.539
                      c0,2.75-2.25,5-5,5H91.247z'
              />
            </g>
            <rect
              x='90.954'
              y='30.34'
              fill='#FFFFFF'
              width='46.777'
              height='86.824'
            />
            <circle fill='#CAE0FF' cx='114.343' cy='124.234' r='3.75' />
          </g>
        </g>
        <path
          fill='#CAE0FF'
          stroke='#FFFFFF'
          strokeWidth='2.8346'
          strokeMiterlimit='10'
          d='M150.429,13.08
          c-19.843,0-35.929,16.086-35.929,35.928c0,11.973,5.868,22.562,14.874,29.092c-1.178,4.854-4.798,7.768-4.798,7.768
          s-3.375,3.188,0.875,4.938c2.075,0.855,11.685-1.238,17.174-6.727c2.514,0.557,5.123,0.859,7.804,0.859
          c19.844,0,35.93-16.086,35.93-35.93C186.358,29.166,170.272,13.08,150.429,13.08z'
        />
        <g>
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='186.314'
            y1='14.268'
            x2='182.181'
            y2='18.402'
          />
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='176.881'
            y1='10.359'
            x2='176.881'
            y2='16.014'
          />
          <line
            fill='#CAE0FF'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='190.225'
            y1='23.701'
            x2='184.548'
            y2='23.701'
          />
        </g>
        <g>
          <g>
            <circle fill='#202B40' cx='137.566' cy='46.406' r='2.678' />
            <circle fill='#202B40' cx='163.293' cy='46.406' r='2.678' />
          </g>
          <g>
            <circle fill='#CAE0FF' cx='133.663' cy='55.457' r='2.679' />
            <circle fill='#CAE0FF' cx='167.196' cy='55.457' r='2.679' />
          </g>
          <path
            fill='none'
            stroke='#202B40'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            d='M157.477,57.314
              c0,3.891-3.156,7.045-7.047,7.045c-3.888,0-7.044-3.154-7.044-7.045'
          />
        </g>
      </svg>
    );
  }
}
