import moment, { Moment } from 'moment';
import { Construct } from '../../../../shared/domain/construct';

export enum DataExportRunStatus {
  InProgress = 'in_progress',
  Failed = 'failed',
  Complete = 'complete',
}

export const iconForStatus = (status: DataExportRunStatus) => {
  switch (status) {
    case DataExportRunStatus.Complete:
      return '<Images.success />';
    case DataExportRunStatus.Failed:
      return '<Images.error />';
    case DataExportRunStatus.InProgress:
      return '<Images.inProgress />';
  }
};

export interface NetworkDataExportRun {
  data_export_run_id: string;
  scheduled_data_export_id?: string;
  created_at: string;
  completed_at?: string;
  status: DataExportRunStatus;
  file_id?: string;
}

export class DataExportRun {
  readonly dataExportRunId: string;
  readonly scheduledDataExportId?: string;
  readonly createdAt: Moment;
  readonly completedAt?: Moment;
  readonly status: DataExportRunStatus;
  readonly fileId?: string;

  constructor(init: Construct<DataExportRun>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkDataExportRun {
    return {
      data_export_run_id: this.dataExportRunId,
      scheduled_data_export_id: this.scheduledDataExportId,
      created_at: this.createdAt.toISOString(),
      completed_at: this.completedAt && this.completedAt.toISOString(),
      status: this.status,
      file_id: this.fileId,
    };
  }

  static fromNetwork(init: NetworkDataExportRun): DataExportRun {
    return new DataExportRun({
      dataExportRunId: init.data_export_run_id,
      scheduledDataExportId: init.scheduled_data_export_id,
      createdAt: moment(init.created_at),
      completedAt: init.completed_at ? moment(init.completed_at) : undefined,
      status: init.status,
      fileId: init.file_id,
    });
  }

  prettyStatus() {
    switch (this.status) {
      case DataExportRunStatus.Complete:
        return 'Complete';
      case DataExportRunStatus.Failed:
        return 'Failed';
      case DataExportRunStatus.InProgress:
        return 'In progress';
    }
  }
}
