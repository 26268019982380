import React, { Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import {
  formatDate,
  policyPaymentsLink,
  ajax,
  snakeCaseToKebabCase,
} from '../../../helpers';

export default class UpdateRequestsTable extends React.Component {
  render() {
    const { refresh, updateRequests, params } = this.props;

    const policyPage = params ? null : true;

    return (
      <Table>
        <thead>
          <tr>
            <td>
              <b>Request date</b>
            </td>
            <td>
              <b>Request type</b>
            </td>
            <td>
              <b>Policy module</b>
            </td>
            {policyPage && (
              <Fragment>
                <td>
                  <b>Policy</b>
                </td>
                <td>
                  <b>Policy status</b>
                </td>
              </Fragment>
            )}
            <td />
            <td />
            <td />
          </tr>
        </thead>
        <tbody>
          {updateRequests &&
            updateRequests.map(o => (
              <UpdateRequestRow
                key={o.updateRequestId}
                updateRequest={o}
                refresh={refresh}
                params={params}
              />
            ))}
        </tbody>
      </Table>
    );
  }
}

class UpdateRequestRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { updateRequest, refresh, params } = this.props;
    const policyPage = params ? null : true;

    return (
      <tr key={updateRequest.updateRequestId}>
        <td>{formatDate(updateRequest.createdAt)}</td>
        <td>{updateRequest.type}</td>
        <td>{updateRequest.policy && updateRequest.policy.module.type}</td>
        {policyPage && (
          <Fragment>
            <td>{policyPaymentsLink(updateRequest.policy)}</td>
            <td>{updateRequest.policy && updateRequest.policy.status}</td>
          </Fragment>
        )}
        <td>
          <Button
            color='link'
            disabled={this.state.loading}
            onClick={() => this.confirmAction(updateRequest)}
            style={{ margin: '0px' }}
          >
            Confirm {snakeCaseToKebabCase(updateRequest.type)}
          </Button>
        </td>
        <td>
          <Button
            color='link'
            className='danger'
            disabled={this.state.loading}
            onClick={() => this.cancelRequest(updateRequest)}
            style={{ margin: '0px' }}
          >
            Cancel
          </Button>
        </td>
        <td>
          <ReviewButton updateRequest={updateRequest} refresh={refresh} />
        </td>
      </tr>
    );
  }

  async confirmAction(updateRequest) {
    if (
      !window.confirm(
        `Are you sure you want to ${snakeCaseToKebabCase(
          updateRequest.type,
        )} policy ${updateRequest.policy.policyNumber}?`,
      )
    ) {
      return;
    }
    this.setState({ loading: true });
    try {
      await ajax({
        type: 'POST',
        path: `/insurance/admin/update-requests/${
          updateRequest.updateRequestId
        }/action`,
      });
      await this.props.refresh();
    } catch (e) {
      window.alert(`Error:\n${e.message}`);
    }
    this.setState({ loading: false });
  }

  async cancelRequest(updateRequest) {
    if (
      !window.confirm(
        `Are you sure you want to cancel the ${snakeCaseToKebabCase(
          updateRequest.type,
        )} request?`,
      )
    ) {
      return;
    }
    this.setState({ loading: true });
    try {
      await ajax({
        type: 'POST',
        path: `/insurance/admin/update-requests/${
          updateRequest.updateRequestId
        }/cancel`,
      });
      await this.props.refresh();
    } catch (e) {
      window.alert(`Error:\n${e.message}`);
    }
    this.setState({ loading: false });
  }
}

class ReviewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: null,
      color: 'link',
    };

    this.primaryColor = '#007bff';
  }

  get background() {
    return this.props.updateRequest.reviewedAt ? 'green' : this.primaryColor;
  }

  get text() {
    return this.props.updateRequest.reviewedAt ? 'Checked' : 'Review';
  }

  reviewUpdateRequest = async updateRequestId => {
    try {
      const reviewUpdateRequest = await ajax({
        path: `insurance/admin/update-requests/${updateRequestId}/review`,
        type: 'POST',
      });
      return reviewUpdateRequest;
    } catch (error) {
      console.dir(error);
      this.setState({ errorMessage: JSON.parse(error.message), error: true });
    }
  };

  revokeUpdateRequest = async updateRequestId => {
    try {
      const revokeReviewUpdateRequest = await ajax({
        path: `insurance/admin/update-requests/${updateRequestId}/revoke-review`,
        type: 'POST',
      });
      return revokeReviewUpdateRequest;
    } catch (error) {
      console.dir(error);
      this.setState({ errorMessage: JSON.parse(error.message), error: true });
    }
  };

  handleClick = async updateRequest => {
    this.text === 'Review'
      ? await this.reviewUpdateRequest(updateRequest.updateRequestId)
      : await this.revokeUpdateRequest(updateRequest.updateRequestId);
    await this.props.refresh();
  };

  render() {
    const { updateRequest } = this.props;
    const { color } = this.state;

    return (
      <div>
        <Button
          onClick={() => this.handleClick(updateRequest)}
          color={color}
          style={{
            color: this.background,
            minWidth: 'auto',
            display: 'contents',
          }}
        >
          {this.text}
        </Button>
      </div>
    );
  }
}
