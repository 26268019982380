import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class CheckboxBlock extends Block {
  readonly title: string;
  readonly defaultValue: boolean;

  constructor(init: Construct<CheckboxBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      default_value: this.defaultValue,
    };
  }

  static fromJSON(init: any) {
    return new CheckboxBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      defaultValue: init.default_value,
    });
  }
}
