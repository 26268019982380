import * as React from 'react';

export const Icon16PXCheck = (props: { fill?: string; style?: any }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    style={{ ...props.style }}
  >
    <g fill='none' fillRule='evenodd'>
      <circle cx='8' cy='8' r='8' fill={props.fill || '#1fc881'} />
      <path
        fill='#FFF'
        d='M6.943 8.896L4.828 6.78 3.333 8.276l2.862 2.862a1.054 1.054 0 0 0 1.495 0l4.977-4.976-1.495-1.495-4.23 4.229z'
      />
    </g>
  </svg>
);
