import React, { Component } from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import '../styles/select-input/styles.scss';
import '../styles/select/select.scss';
import { Options } from '../interfaces';

export interface FilterOptions {
  value: string;
  label: string;
}

export interface SelectInputKeyValue {
  value: string | undefined;
  key: string | undefined;
}

@observer
export class SelectInput extends Component<any, any> {
  selectedValueChange = (value: Options) => {
    const { keyVal, formStore, validators } = this.props;
    if (value) {
      this.props.onChange(value);
      formStore.updateComponentValid(keyVal, validators);
      formStore.updateValidTotalDirect(
        keyVal,
        !!formStore.componentValid[keyVal].validation.valid,
      );
    } else {
      this.props.formStore.updateInputState(keyVal, null);
      formStore.updateComponentValid(keyVal, validators, true);
      formStore.updateValidTotalDirect(keyVal, false);
    }
  };

  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, null);
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, null);
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  componentDidMount() {
    const { keyVal, formStore, validators } = this.props;
    if (
      this.props.type === 'select' &&
      formStore.inputState[keyVal] !== null &&
      formStore.inputState[keyVal] !== undefined
    ) {
      const indexOfSelected = this.props.options.find(
        (value: FilterOptions) => {
          return value.value === formStore.inputState[keyVal];
        },
      );
      if (indexOfSelected === undefined) {
        this.selectedValueChange({ value: undefined, label: undefined });
      }
    }
    formStore.updateValidTotal(
      keyVal,
      formStore.inputState[keyVal],
      validators,
    );
  }

  customStyles = () => {
    const { formStore, keyVal } = this.props;
    const customStyles = {
      control: (base: any) => ({
        ...base,
        boxShadow: 'none',
        minHeight: '1px',
        maxHeight: '34px',
        borderColor: formStore.componentValid[keyVal].validation.valid
          ? '#ced4da'
          : '#dc3545',
      }),
      container: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '36px',
      }),
      input: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '36px',
        marginTop: '-1px',
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        minHeight: '1px',
        maxHeight: '34px',
        maxWidth: '31px',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        marginTop: '0px',
        height: '33px',
        borderLeft: '1px solid hsl(0,0%,80%)',
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#c3c3c3',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        maxHeight: '33px',
        maxWidth: '33px',
        paddingTop: '0px',
      }),
      singleValue: (provided: any) => ({
        ...provided,
      }),
    };

    return customStyles;
  };

  defaultValue = (value: any, options: Options[]) => {
    if (value && options) {
      const selectedOption = options.find(option => option.value === value);
      if (selectedOption) {
        return selectedOption;
      }
    }
    return null;
  };

  render() {
    const {
      validators,
      keyVal,
      formStore,
      isDisabled,
      isLoading,
      isRtl,
    } = this.props;

    formStore.inputState[keyVal] === null
      ? formStore.updateValidTotal(
        keyVal,
        formStore.inputState[keyVal],
        validators,
      )
      : null;

    return (
      <div className='form-group col-sm-6'>
        <label className='form-label' htmlFor={this.props.keyVal}>
          {this.props.label}
        </label>
        <Select
          inputProps={{ id: keyVal }}
          key={`select-input-${keyVal}`}
          className={
            formStore.componentValid[keyVal].validation.valid
              ? ''
              : 'is-invalid'
          }
          styles={this.customStyles()}
          disabled={isDisabled ? isDisabled : false}
          isLoading={isLoading ? isLoading : false}
          clearable={false}
          rtl={isRtl ? isRtl : false}
          defaultValue={this.defaultValue(
            formStore.inputState[keyVal],
            this.props.options,
          )}
          onChange={(value: Options) => this.selectedValueChange(value)}
          searchable={true}
          isSearchable={true}
          name='color'
          options={
            this.props.type === 'select'
              ? this.props.options
              : (Countries.map(c => {
                return {
                  name: c.name,
                  label: (
                    <span>
                      <span
                        className={
                          'flag-icon flag-icon-' + c.code.toLowerCase()
                        }
                      />
                      &nbsp;&nbsp;{c.name}
                    </span>
                  ),
                  value: c.code,
                };
              }) as any)
          }
          onBlur={() =>
            formStore.updateComponentValid(keyVal, validators, true)
          }
        />
        <div className='select-input-styles'>
          {!formStore.componentValid[keyVal].validation.valid
            ? formStore.componentValid[keyVal].validation.failCondition
            : ''}
        </div>
      </div>
    );
  }
}

export default SelectInput;

const Countries = [
  {
    code: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AR',
    name: 'Argentina',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BO',
    name: 'Bolivia, Plurinational State of',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BR',
    name: 'Brazil',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'TD',
    name: 'Chad',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CG',
    name: 'Congo',
  },
  {
    code: 'CD',
    name: 'Democratic Republic of the Congo',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    name: 'Côte d`Ivoire',
  },
  {
    code: 'HR',
    name: 'Croatia',
  },
  {
    code: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
  },
  {
    code: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finland',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GE',
    name: 'Georgia',
  },
  {
    code: 'DE',
    name: 'Germany',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    name: 'Greece',
  },
  {
    code: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IS',
    name: 'Iceland',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
  },
  {
    code: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Jordan',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'KP',
    name: 'North Korea',
  },
  {
    code: 'KR',
    name: 'South Korea',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    name: 'Lao People`s Democratic Republic',
  },
  {
    code: 'LV',
    name: 'Latvia',
  },
  {
    code: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LY',
    name: 'Libya',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'MO',
    name: 'Macao',
  },
  {
    code: 'MK',
    name: 'Republic of Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MV',
    name: 'Maldives',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'MX',
    name: 'Mexico',
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States of',
  },
  {
    code: 'MD',
    name: 'Republic of Moldova',
  },
  {
    code: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'NO',
    name: 'Norway',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestinian Territory',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Poland',
  },
  {
    code: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'QA',
    name: 'Qatar',
  },
  {
    code: 'RO',
    name: 'Romania',
  },
  {
    code: 'RU',
    name: 'Russian',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
  },
  {
    code: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'ES',
    name: 'Spain',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
  },
  {
    code: 'SE',
    name: 'Sweden',
  },
  {
    code: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'SY',
    name: 'Syria',
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
  },
  {
    code: 'VN',
    name: 'Viet Nam',
  },
  {
    code: 'VI',
    name: 'Virgin Islands',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
];
