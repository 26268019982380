export function createFunctionDependencyProposals(range: any, monaco: any) {
  return [
    {
      label: 'validateQuoteRequest',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: 'Class used for getting reactivation options though root',
      documentation: 'Class used for getting reactivation options though root',
      insertText: `
function validateQuoteRequest(data) {
    return Joi.validate(data, Joi.object().keys({
      \${1:}: \${2:}
    })
  }
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'validateAlterationPackageRequest',
      kind: monaco.languages.CompletionItemKind.Function,
      detail:
        'Class used for validation Joi schema for creating an alteration package',
      documentation:
        'Class used for validation Joi schema for creating an alteration package',
      insertText: `
      function validateAltertionPackage({ alteration_hook_key, data }) {
        switch (alteration_hook_key) {
          case alteration_hook_key === \${1:} {
            return Joi.validate(data, Joi.object().keys({
              \${2:}: \${3:}
            })
          } default:
          throw new InvalidRequestError('Could not validate alteration package'); 
        }
      }
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getAlteration',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: 'Class for returning an alteration package',
      documentation: 'Class for returning an alteration package',
      insertText: `
function getAlterationPackage({ alteration_hook_key, data, policy, policyholder }) {
  return new AlterationPackage({
    input_data: policy.module,
    module: {
      package_name: '\${1:}',
      cover_amount: \${2:},
      base_premium: \${4:policy.base_premium},
      suggested_premium: \${5:policy.suggested_premium || policy.base_premium},
      billing_frequency: 'monthly',
      type: policy.module.type,
    },
    sum_assured:  \${3:policy.sum_assured},
    monthly_premium: \${6:policy.monthly_premium},
    change_description: 'applying alteration hook 1',
  });
}
  `,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'applyAlteration',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: 'Class for returning applying an alteration package to a policy',
      documentation: 'Class for applying an alteration package to a policy',
      insertText: `
function applyAlteration({ alteration_hook_key, policy, policyholder, alteration_package }) {
  if (alteration_hook_key === 'hook_1') {
    return new AlteredPolicy({
        charges: \${1:[]},
        start_date: \${6:moment()},
        package_name: \${2:application.package_name},
        sum_assured: \${3:application.sum_assured},
        base_premium: \${4:application.base_premium},
        monthly_premium: \${5:application.monthly_premium},
        end_date: \${6:moment().add(1, 'year')},
        suggested_premium: \${7:policy.suggested_premium || policy.base_premium},
        module: { 
          \${8:...policy.module, ...alteration_package.module }
        },
    });
  }
}
  `,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getQuote',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: 'Class for returning a quote package',
      documentation: 'Class for returning a quote package',
      insertText: `
function getQuote(data) {
    return new QuotePackage({
        package_name: '\${1:}',
        sum_assured: \${2:},
        base_premium: \${3:},
        suggested_premium: \${4:},
        input_data: data,
        module: {
            \${5:}: \${6:}
        },
        billing_frequency: \${7:}
    });
}
  `,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'validateCoveredItemsAdd',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function validateCoveredItemsAdd(data) {
    return Joi.validate(data, Joi.object().keys({
      \${1:}: \${2:}
    })
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'validateCoveredItemsUpdate',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function validateCoveredItemsUpdate(data) {
    return Joi.validate(data, Joi.object().keys({
      \${1:}: \${2:}
    })
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'validateApplicationRequest',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function validateApplicationRequest(data) {
    return Joi.validate(data, Joi.object().keys({
      \${1:}: \${2:}
    })
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getApplication',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
  function getApplication(data) {
    return new Application({
        package_name: \${1: quotePackage.package_name},
        sum_assured: \${2: quotePackage.sum_assured},
        base_premium: \${3: quotePackage.base_premium},
        monthly_premium: \${4: quotePackage.monthly_premium},
        input_data: data,
        module: {
            \${5:}: \${6:}
        },
        currency: \${7: quotePackage.currency},
    });
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getPolicy',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function getPolicy(application, policyholder) {
    return new Policy({
        policy_number: generatePolicyNumber(),
        package_name: '\${1: application.package_name}',
        sum_assured: \${2: application.sum_assured},
        monthly_premium: \${3: application.monthly_premium},
        base_premium: \${4: application.monthly_premium},
        monthly_premium: \${5:},
        input_data: data,
        start_date: \${6:moment()},
        end_date: \${7:moment().add(1, 'year')},
        module: { 
          ...application.module,
          \${8:}: \${9:}
        },
  });
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'requotePolicy',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function requotePolicy(policy, policyholder, application) {
  return new RequotePolicy({
      charges: \${1:[]},
      package_name: \${2:application.package_name},
      sum_assured: \${3:application.sum_assured},
      base_premium: \${4:application.base_premium},
      monthly_premium: \${5:application.monthly_premium},
      end_date: \${6:moment().add(1, 'year')},
      module: { 
        \${7:...application.module}
      },
  });
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'reactivatePolicy',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
      function reactivatePolicy(policy, policyholder, type) {
        switch (type) {
          case 'recommencement':
            \${1: // recommencement logic}
            break;
          case 'reinstatement':
            \${2: // reinstatement logic}
            break;
          default:
      
            break;
        }
      
        return policy;
      }      
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getReactivationOptions',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function getReactivationOptions(policy) {
  return [
    new ReactivationOption({
      type: \${1:'reinstatement'},
      settlementAmount:  \${2:policy.balance},
      description:  \${3:'For a policy to be reinstated, all arrear premiums must first be paid.'},
      minimumBalanceRequired:  \${4:true},
    }),
    new ReactivationOption({
      type:  \${5:'recommencement'},
      description:  \${6:'For a policy to be recommenced, all arrear premiums will be deducted from the first claim income.'},
      minimumBalanceRequired: \${7:false},
    }),
  ];
}   
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'validateClaimRequest',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function validateClaimRequest(data) {
  return Joi.validate(
    data,
    Joi.object().keys({
      \${1:}: \${2:}
    }),
  );
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'policyMembersUpdated',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function policyMembersUpdated(policy, members) {
  return new ProductModulePolicyMembersUpdatedChanges({
    monthly_premium: \${1: members.reduce((result, member) => result + member.module.premium, 0)},
    module: {
      \${2:}: \${3:}
    }
  });
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getMemberModuleChanges',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const getMemberModuleChanges = async (policy, inputMembersOrMembers) => {
  // Member validation
  inputMembersOrMembers.forEach((member) => {
    const memberAge = ageFromDateOfBirth(member.date_of_birth);

    if (memberAge < \${1: 18}) {
      throw new Error('Member age must be greater than or equal to 18');
    }
    if (memberAge > \${2: 65})) {
      throw new Error('Member age must be less than or equal to 65');
    }
  });

  const now = moment();
  const memberCoverStartDate = getMemberCoverStartDate(now);

  const memberChanges = inputMembersOrMembers.map((inputMember) => {
    const updatedMember = buildMemberFromInputMember({ quoteDataOrPolicyModule: policy.module, inputMember });
    const startedMember = !updatedMember.module.cover_start_date
      ? startMemberCover({
        coverStartDate: memberCoverStartDate,
        member: updatedMember,
      })
      : updatedMember;

    return new MemberModuleChange({
      member_id: \${3: startedMember.member_id},
      module: \${4: startedMember.module},
    });
  });

  return memberChanges;
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'getMemberQuote',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function getMemberQuote(data, policyholder, policy) {
  const validation = validateMemberQuoteRequest(data);

  if (validation.error) {
    throw ValidationError.fromJoi(validation.error);
  }

  return new MemberQuotePackage({
    monthly_premium: \${1:1000},
    module: {
      type: \${2: ''},
      \${3:}: \${4:}
    },
  });
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'beforePolicyReactivated',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const beforePolicyReactivated = ({ policy, reactivationOption }) => {
  const isPolicyLapsedOrCancelled = ['lapsed', 'cancelled'].includes(policy.status);

  if (!isPolicyLapsedOrCancelled) {
    throw new Error(
      'Policy status must be one of ['lapsed', 'cancelled']',
    );
  }

  const wasLapsedOrCancelledWithinSixMonths =
    isPolicyLapsedOrCancelled && moment().diff(moment(policy.status_updated_at), 'months') < \${1: 6};

  if (!wasLapsedOrCancelledWithinSixMonths) {
    throw new Error('Policy older than six months cannot be reactivated. Please issue a new policy instead.');
  }

  if (policy.status === 'lapsed') {
    const premiumPaymentSkippedEvent = {
      type: premiumSkipEventTypes.premium_reset,
      data: {
        created_at: moment().toISOString(),
        reason: 'Policy was reactivated after being lapsed.',
      },
    };
    const updatedEvents = [...policy.module.premium_skips.events, premiumPaymentSkippedEvent];
    const premiumSkipModuleChanges = getPremiumSkipsUsedModuleChanges({
      events: updatedEvents,
      policyStartDate: moment(policy.start_date),
    });

    return [
      {
        name: 'update_policy_module_data',
        data: { ...premiumSkipModuleChanges },
      },
    ];
  }
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPolicyReactivated',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
function afterPolicyReactivated({ policy, reactivationOption }) {
  return reactivationOption.type === 'reinstatement'
    ? [
      {
        name: 'update_policy_module_data',
        data: {
          has_been_reinstated: true,
        },
      },
    ]
    : [
      {
        name: 'update_policy_module_data',
        data: {
          has_been_recommenced: true,
        },
      },
    ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },

    {
      label: 'beforePaymentCouponCreated',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const beforePaymentCouponCreated = async ({ policy }) => {
  const premiumSkipsUsed = await root.policies.getPaymentCoupons({
    includes: {
      policyId: policy.id,
    }
  });

  // Only 5 skips are available on 
  if (premiumSkipsUsed === 5) {
    throw new InvalidRequestError('This policy has no more premium skips available');
  }
    return [];
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentCouponCreated',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const afterPaymentCouponCreated = async ({ policy }) => {
  // Get module changes to increment the premium skip counter for the failed payment.
  const premiumPaymentSkippedEvent = {
    type: premiumSkipEventTypes.premium_payment_skipped,
    data: {
      created_at: moment().toISOString(),
      reason: 'Payment coupon redeemed',
    },
  };
  const updatedEvents = [...policy.module.premium_skips.events, premiumPaymentSkippedEvent];
  const premiumSkipModuleChanges = getPremiumSkipsUsedModuleChanges({
    events: updatedEvents,
    policyStartDate: moment(policy.start_date),
  });

  // Policy should be lapsed if its used premium skips exceed the available premium skips.
  const shouldLapsePolicy = getShouldLapsePolicy({ policy });

  const tranches = policy.module.tranches.map((tranche) => deserializeTranche(tranche));
  const baseSumAssured = getBaseSumAssured({ tranches });
  const sumAssured = await getRevisedSumAssured({
    policyId: policy.policy_id,
    policyStartDate: moment(policy.start_date),
    baseSumAssured,
  });

  const actions = [
    {
      name: 'update_policy',
      data: {
        sumAssured,
        module: {
          ...policy.module,
          ...premiumSkipModuleChanges,
          quick_payout_amount: getQuickPayoutBenefitAmount({ sumAssured }),
        },
      },
    },
    ...(shouldLapsePolicy ? [{ name: 'lapse_policy' }] : []),
  ];

  return actions;
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentCouponCancelled',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const afterPaymentCouponCancelled = async ({ policy }) => {
  // Get module changes to increment the premium skip counter for the failed payment.
  const premiumPaymentSkippedEvent = {
    type: premiumSkipEventTypes.premium_payment_skipped,
    data: {
      created_at: moment().toISOString(),
      reason: 'Payment coupon redeemed',
    },
  };
  const updatedEvents = [...policy.module.premium_skips.events, premiumPaymentSkippedEvent];
  const premiumSkipModuleChanges = getPremiumSkipsUsedModuleChanges({
    events: updatedEvents,
    policyStartDate: moment(policy.start_date),
  });

  // Policy should be lapsed if its used premium skips exceed the available premium skips.
  const shouldLapsePolicy = getShouldLapsePolicy({ policy });

  const tranches = policy.module.tranches.map((tranche) => deserializeTranche(tranche));
  const baseSumAssured = getBaseSumAssured({ tranches });
  const sumAssured = await getRevisedSumAssured({
    policyId: policy.policy_id,
    policyStartDate: moment(policy.start_date),
    baseSumAssured,
  });

  const actions = [
    {
      name: 'update_policy',
      data: {
        sumAssured,
        module: {
          ...policy.module,
          ...premiumSkipModuleChanges,
          quick_payout_amount: getQuickPayoutBenefitAmount({ sumAssured }),
        },
      },
    },
    ...(shouldLapsePolicy ? [{ name: 'lapse_policy' }] : []),
  ];

  return actions;
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentCouponRedeemed',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const afterPaymentCouponRedeemed = async ({ policy }) => {
  // Get module changes to increment the premium skip counter for the failed payment.
  const premiumPaymentSkippedEvent = {
    type: premiumSkipEventTypes.premium_payment_skipped,
    data: {
      created_at: moment().toISOString(),
      reason: 'Payment coupon redeemed',
    },
  };
  const updatedEvents = [...policy.module.premium_skips.events, premiumPaymentSkippedEvent];
  const premiumSkipModuleChanges = getPremiumSkipsUsedModuleChanges({
    events: updatedEvents,
    policyStartDate: moment(policy.start_date),
  });

  // Policy should be lapsed if its used premium skips exceed the available premium skips.
  const shouldLapsePolicy = getShouldLapsePolicy({ policy });

  const tranches = policy.module.tranches.map((tranche) => deserializeTranche(tranche));
  const baseSumAssured = getBaseSumAssured({ tranches });
  const sumAssured = await getRevisedSumAssured({
    policyId: policy.policy_id,
    policyStartDate: moment(policy.start_date),
    baseSumAssured,
  });

  const actions = [
    {
      name: 'update_policy',
      data: {
        sumAssured,
        module: {
          ...policy.module,
          ...premiumSkipModuleChanges,
          quick_payout_amount: getQuickPayoutBenefitAmount({ sumAssured }),
        },
      },
    },
    ...(shouldLapsePolicy ? [{ name: 'lapse_policy' }] : []),
  ];

  return actions;
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentCouponReversed',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const afterPaymentCouponReversed = async ({ policy }) => {
  // Get module changes to increment the premium skip counter for the failed payment.
  const premiumPaymentSkippedEvent = {
    type: premiumSkipEventTypes.premium_payment_skipped,
    data: {
      created_at: moment().toISOString(),
      reason: 'Payment coupon redeemed',
    },
  };
  const updatedEvents = [...policy.module.premium_skips.events, premiumPaymentSkippedEvent];
  const premiumSkipModuleChanges = getPremiumSkipsUsedModuleChanges({
    events: updatedEvents,
    policyStartDate: moment(policy.start_date),
  });

  // Policy should be lapsed if its used premium skips exceed the available premium skips.
  const shouldLapsePolicy = getShouldLapsePolicy({ policy });

  const tranches = policy.module.tranches.map((tranche) => deserializeTranche(tranche));
  const baseSumAssured = getBaseSumAssured({ tranches });
  const sumAssured = await getRevisedSumAssured({
    policyId: policy.policy_id,
    policyStartDate: moment(policy.start_date),
    baseSumAssured,
  });

  const actions = [
    {
      name: 'update_policy',
      data: {
        sumAssured,
        module: {
          ...policy.module,
          ...premiumSkipModuleChanges,
          quick_payout_amount: getQuickPayoutBenefitAmount({ sumAssured }),
        },
      },
    },
    ...(shouldLapsePolicy ? [{ name: 'lapse_policy' }] : []),
  ];

  return actions;
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentSuccess',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPaymentSuccess({ policy, payment }) {
  const payments = await root.policies.getPolicyPayments(policy.policy_id);
  return [
    {
      name: 'update_policy_module_data',
      data: {
        has_on_payment_success_run: true,
        policy_id: policy.policy_id,
        payment_id: payment.payment_id,
        number_of_payments: payments.length,
      },
    },
    {
      name: 'update_policy',
      data: {
        monthlyPremium: 1000,
        basePremium: 1000,
        billingAmount: 1000,
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentFailed',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPaymentFailed({ policy, payment }) {
  const payments = await root.policies.getPolicyPayments(policy.policy_id);
  return [
    {
      name: 'update_policy_module_data',
      data: {
        has_on_payment_success_run: true,
        policy_id: policy.policy_id,
        payment_id: payment.payment_id,
        number_of_payments: payments.length,
      },
    },
    {
      name: 'update_policy',
      data: {
        monthlyPremium: 1000,
        basePremium: 1000,
        billingAmount: 1000,
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPaymentReversed',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPaymentReversed({ policy, payment }) {
  const payments = await root.policies.getPolicyPayments(policy.policy_id);
  return [
    {
      name: 'update_policy_module_data',
      data: {
        has_on_payment_reversed_run: true,
        policy_id: policy.policy_id,
        payment_id: payment.payment_id,
        number_of_payments: payments.length,
      },
    },
    {
      name: 'update_policy',
      data: {
        monthlyPremium: 4000,
        basePremium: 4000,
        billingAmount: 4000,
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPolicyNotTakenUp',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPolicyNotTakenUp({ policy }) {
  return [
    {
      name: 'update_policy_module_data',
      data: {
        policy_has_not_taken_up: true,
      },
    },
    {
      name: 'update_policy',
      data: {
        monthlyPremium: policy.monthly_premium + 15000,
        basePremium: policy.monthly_premium + 15000,
        billingAmount: policy.monthly_premium + 15000,
      },
    },
  ];
}     
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPolicyLapsed',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPolicyLapsed({ policy }) {
  return [
    {
      name: 'update_policy_module_data',
      data: {
        policy_has_lapsed: true,
      },
    },
    {
      name: 'update_policy',
      data: {
        monthlyPremium: policy.monthly_premium + 10000,
        basePremium: policy.monthly_premium + 10000,
        billingAmount: policy.monthly_premium + 10000,
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterPolicyLinkedToClaim',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterPolicyLinkedToClaim({ policy, claim }) {
  return [
    {
      name: 'update_claim_module_data',
      data: {
        has_after_policy_linked_to_claim_hook_run: true,
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'afterClaimApproved',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
async function afterClaimApproved({ policy, payment }) {
  return [
    {
      name: 'update_claim_module_data',
      data: {
        \${1: monthlyPremium}: \${2: policy.monthly_premium * 1.1},
      },
    },
  ];
}
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
    {
      label: 'beforeCoveredItemsUpdate',
      kind: monaco.languages.CompletionItemKind.Function,
      detail: '',
      documentation: '',
      insertText: `
const beforeCoveredItemsUpdate = (coveredItems, policyholder) => {
  return coveredItems.map(setCoveredItemFieldsAndPremiums(policyholderAge(policyholder)));
};
`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range: range,
    },
  ];
}
