import addCategory from './add-category';
import appOtp from './app-otp';
import appOtpWhite from './app-otp-white';
import cardCharacter from './card-character';
import cardMachine from './card-machine';
import cardNumber from './card-number';
import cardNumberMockup from './card-number-mockup';
import categories from './categories';
import chevronDown from './chevron-down';
import chevronDownDisabled from './chevron-down-disabled';
import chevronUp from './chevron-up';
import chevronUpDisabled from './chevron-up-disabled';
import contact from './contact';
import copy from './copy';
import deliveryAddress from './delivery-address';
import docfoxLogo from './docfox-logo';
import driveLogo from './drive-logo';
import editSheet from './edit-sheet';
import email from './email';
import error from './error';
import errorOrange from './error-orange';
import fingerprint from './fingerprint';
import flyingCard from './flying-card';
import house from './house';
import houseAndId from './house-and-id';
import houseIcon from './house-icon';
import id from './id';
import info from './info';
import inProgress from './in-progress';
import latestRunPending from './latest-run-pending';
import lego from './lego';
import lock from './lock';
import logo from './logo';
import logoIcon from './logo-icon';
import metronome from './metronome';
import money from './money';
import moonFlag from './moon-flag';
import newSheet from './new-sheet';
import noiseLoader from './noise-loader';
import otp from './otp';
import outlineFlyingCard from './outline-flying-card';
import outlineTag from './outline-tag';
import password from './password';
import pdfThumbnail from './pdf-thumbnail';
import pieChart from './pie-chart';
import postbox from './postbox';
import profilePicture from './profile-picture';
import questionmark from './questionmark';
import resetPassword from './reset-password';
import rocket from './rocket';
import sheetsIntegration from './sheets-integration';
import shareableLink from './shareable-link';
import shareSheet from './share-sheet';
import smsOtp from './sms-otp';
import smsOtpWhite from './sms-otp-white';
import spinnerLoader from './spinner-loader';
import success from './success';
import paginationArrowLeft from './pagination-arrow-left';
import paginationArrowRight from './pagination-arrow-right';
import tags from './tags';
import upload from './upload';
import uploadIcon from './upload-icon';
import vault from './vault';

export default {
  addCategory,
  appOtp,
  appOtpWhite,
  cardCharacter,
  cardMachine,
  cardNumber,
  cardNumberMockup,
  categories,
  chevronDown,
  chevronDownDisabled,
  chevronUp,
  chevronUpDisabled,
  contact,
  copy,
  deliveryAddress,
  docfoxLogo,
  driveLogo,
  editSheet,
  email,
  error,
  errorOrange,
  fingerprint,
  flyingCard,
  house,
  houseAndId,
  houseIcon,
  id,
  info,
  inProgress,
  latestRunPending,
  lego,
  lock,
  logo,
  logoIcon,
  metronome,
  money,
  moonFlag,
  newSheet,
  noiseLoader,
  otp,
  outlineFlyingCard,
  outlineTag,
  password,
  pdfThumbnail,
  pieChart,
  postbox,
  profilePicture,
  questionmark,
  resetPassword,
  rocket,
  sheetsIntegration,
  shareableLink,
  shareSheet,
  smsOtp,
  smsOtpWhite,
  spinnerLoader,
  success,
  tags,
  upload,
  docfoxLogo,
  paginationArrowLeft,
  paginationArrowRight,
  uploadIcon,
  vault,
};
