import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import { setSingularOrPlural } from '../../../utils';
import '../../../styles/general.scss';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';

interface Props {
  value: string;
  applyTo: string;
  refundType: string;
  type: string;
  index: number;
}

interface State {}

class CoolingOffPeriodCompletedChild extends React.Component<Props, State> {
  render() {
    let { value, applyTo, type, index, refundType } = this.props;

    type = type ? setSingularOrPlural(value, type.toString()) + ' ' : '';
    value = value ? value + ' ' : '';
    applyTo = applyTo ? applyTo : '';
    refundType = refundType ? refundType : '';

    if (refundType && refundType === 'none') {
      refundType = 'Do not refund the policyholder';
    } else {
      refundType = `Refund the policyholder for ${refundType}`;
    }

    return (
      <div>
        <FormGroup row style={{ marginBottom: -14, paddingTop: 14 }}>
          <Col md={4} sm={12}>
            <div
              className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'
              style={{ paddingLeft: 14, paddingRight: 14 }}
            >
              {index === 0 ? <Icon12ArrowRight /> : 'OR'}
            </div>
            <h6 className='completed-card-rule-heading'>Rule {index + 1}</h6>
          </Col>
          <Col md={7} sm={12} className='completed-card-rule-heading'>
            <p>{`Apply a cooling-off period of ${value} ${type} to ${applyTo}. ${refundType}.`}</p>
          </Col>
        </FormGroup>
        <hr className='horizontal-line-without-padding-bottom' />
      </div>
    );
  }
}

export default CoolingOffPeriodCompletedChild;
