import { PolicyholderEntityType } from '../../../../../domain/product-module-definition';
import { IdentificationType } from '../../../interface';

export enum TypesOfPolicyholdersCondition {
  equals = 'equals',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export const typesOfPolicyholders: any = {
  natural: {
    label: 'A policyholder can be a natural person.',
    description:
      'A policyholder will be a natural individual with an identity number.',
    indent: 0,
    path: 'policyholder.entityTypes',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: PolicyholderEntityType.Individual,
    key: 'natural',
  },
  southAfrican: {
    label: 'Policyholder can be a South African resident or citizen.',
    description:
      'A 13-digit South African identity number will be used to identify the policyholder.',
    indent: 1,
    path: 'policyholder.idTypes',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: IdentificationType.Id,
    key: 'southAfrican',
  },
  nonSouthAfrican: {
    label: 'Policyholder can hold a non-South African passport.',
    description: 'A passport number will be used to identify the policyholder.',
    indent: 1,
    path: 'policyholder.idTypes',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: IdentificationType.Passport,
    key: 'nonSouthAfrican',
  },
  policyLegalEntitiy: {
    label: 'A policyholder can be a legal entity.',
    description:
      'A policyholder can be a company and will be identified with a company registration number.',
    indent: 0,
    path: 'policyholder.entityTypes',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: PolicyholderEntityType.Company,
    key: 'policyLegalEntitiy',
  },
};

export const capturedPolicyholderDetails: any = {
  // mobileNumberEmailRequired: {
  //   label: 'Either mobile number or email address is required.',
  //   description:
  //     'Either mobile number or email address details will need to be given before issuing a policy or editing details. It will not be necessary to supply both fields.',
  //   indent: 0,
  //   path: 'policyholder.idTypes',
  //   condition: TypesOfPolicyholdersCondition.equals,
  //   value: IdentificationType.Id
  // },
  // mobileNumberRequired: {
  //   label: 'Mobile number is required.',
  //   description:
  //     'A policyholder’s mobile number will be required to issue them a policy or edit their details.',
  //   indent: 0,
  // },
  // emailAddressRequired: {
  //   label: 'Email address is required.',
  //   description:
  //     'A policyholder’s email address will be required to issue them a policy or edit their details.',
  //   indent: 0,
  // },
  // physicalAddressRequired: {
  //   label: 'Physical address is required.',
  //   description:
  //     'A policyholder’s physical address will be required to issue them a policy or edit their details.',
  //   indent: 0,
  // },
};
