/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='222px'
        height='70px'
        viewBox='0 0 222 60'
        enableBackground='new 0 0 222 60'
        xmlSpace='preserve'
      >
        <g>
          <line
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='203.877'
            y1='7.352'
            x2='203.877'
            y2='16.833'
          />
          <line
            fill='#FFFFFF'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='208.617'
            y1='12.093'
            x2='199.135'
            y2='12.093'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='182.889'
            y1='2.929'
            x2='182.889'
            y2='9.232'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='186.039'
            y1='6.081'
            x2='179.736'
            y2='6.081'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='194.049'
            y1='38.495'
            x2='194.049'
            y2='43.051'
          />
          <line
            fill='#CED5D9'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='196.328'
            y1='40.773'
            x2='191.771'
            y2='40.773'
          />
          <circle
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            cx='166.724'
            cy='10.671'
            r='1.558'
          />
          <circle
            fill='none'
            stroke='#CED5D9'
            strokeWidth='2'
            strokeMiterlimit='10'
            cx='194.163'
            cy='24.825'
            r='2.265'
          />
          <g>
            <path
              fill='none'
              d='M44.177,52.653c0,0.976,0.982,1.769,2.208,1.769h129.714c1.216,0,2.199-0.793,2.199-1.769V25.189
                c0-0.976-0.983-1.768-2.199-1.768H46.385c-1.226,0-2.208,0.792-2.208,1.768V52.653z'
            />
            <path
              fill='none'
              stroke='#CED5D9'
              strokeWidth='2'
              strokeMiterlimit='10'
              strokeDasharray='3.986,3.986'
              d='M44.177,52.653
                c0,0.976,0.982,1.769,2.208,1.769h129.714c1.216,0,2.199-0.793,2.199-1.769V25.189c0-0.976-0.983-1.768-2.199-1.768H46.385
                c-1.226,0-2.208,0.792-2.208,1.768V52.653z'
            />
          </g>
          <g>
            <line
              fill='none'
              stroke='#B5BDC6'
              strokeWidth='2'
              strokeMiterlimit='10'
              x1='61.453'
              y1='34.571'
              x2='61.453'
              y2='43.272'
            />
            <line
              fill='none'
              stroke='#B5BDC6'
              strokeWidth='2'
              strokeMiterlimit='10'
              x1='65.805'
              y1='38.922'
              x2='57.104'
              y2='38.922'
            />
          </g>
          <text
            transform='matrix(1 0 0 1 76.4023 44.0508)'
            fill='#B5BDC6'
            fontFamily='ProximaNova-Semibold'
            fontSize='14'
          >
            Add Category
          </text>
        </g>
      </svg>
    );
  }
}
