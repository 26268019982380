import React from 'react';
import { Alert, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { toCamelCaseKeys } from '../../../helpers';
import ajax from '../../../helpers/ajax';
import PaymentBatchesTable from './payment-batches-table';
import UnassignedPayments from './unassigned-payments';
import { PaginationComponent } from '../../../components/pagination';

export default class PaymentBatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentBatches: null,
      loading: true,
      page: 0,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.fetchPaymentBatches();
  }

  componentWillReceiveProps() {
    this.fetchPaymentBatches();
  }

  get status() {
    return this.props.router.location.pathname
      .split('/')
      .slice(-1)
      .pop();
  }

  async fetchPaymentBatches() {
    this.setState({ loading: true });
    const status = this.status;
    const statusQuery = status === 'pending' ? 'pending,submitting' : status;
    const queryString =`${statusQuery ? `?status=${statusQuery}` : ''}`;
    const includesString = '&include=billing_provider';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payment-batches${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const paymentBatches = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payment-batches${queryString}${includesString}${pagination}`,
    })).map(toCamelCaseKeys);

    this.setState({ paymentBatches, loading: false, total: total.headers.get('X-Total-Count')});
  }

  async createBatches() {
    await ajax({
      type: 'POST',
      path: '/insurance/admin/payment-batches',
    });

    await this.fetchPaymentBatches();
  }

  render() {
    const {
      paymentBatches,
      loading,
      error,
      page,
      pageSize,
      total,
    } = this.state;

    return (
      <div>
        <div className='bottom-padded'>
          <Card>
            <CardHeader>
              Payment batches
              <div className='pull-right'>
                <Button
                  color='primary'
                  onClick={this.createBatches.bind(this)}
                  disabled={loading}
                >
                  Create batches
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              {error && <Alert color='danger'>{error}</Alert>}
              {loading ? (
                <div>Loading...</div>
              ) : paymentBatches && paymentBatches.length > 0 ? (
                <div>
                  <PaymentBatchesTable
                    paymentBatches={paymentBatches}
                    refresh={this.fetchPaymentBatches.bind(this)}
                  />
                  <PaginationComponent
                    page={page || 0}
                    limit={pageSize}
                    total={total || 0}
                    disabled={false}
                    goToPage={page => {
                      this.setState({
                        page,
                      },
                      async () => await this.fetchPaymentBatches());
                    }}
                  />
                </div>
              ) : (
                <div>{`No ${this.status} payment batches`}</div>
              )}

            </CardBody>
          </Card>
        </div>
        {this.status === 'pending' && (
          <UnassignedPayments router={this.props.router} />
        )}
      </div>
    );
  }
}
