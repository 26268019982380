import React from 'react';
import { CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import '../../../../../styles/styles.scss';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import LoadingInPage from '../../../../../loading';
import { TypesOfBillingConditions, billing } from '../config/billing';
import {
  defaultCheckboxValue,
  getTextInputValue,
} from '../orchestration';
import ProductModuleSettingsSelect from './select';
import ProductModuleSettingsTextInput from './text-input';
import ProductModuleSettingsCheckbox from './checkbox';
import _ from 'lodash';
import { TypesOfSetupConditions } from '../../product-module-definition-settings/config/product-module-definition-settings-set-up';
interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfSetupConditions;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
  options?: any;
  multipleSelect?: boolean;
}


export enum ComponentType {
  Checkbox = 'checkbox',
  Select = 'select',
  Text = 'text',
  Number = 'number',
}

export interface BillingParams {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: TypesOfBillingConditions;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
  options?: any;
  multipleSelect?: boolean;
  title?: string;
  reset?: [
    {
      path: string;
      default: string | number | boolean | null;
      comparisonValues: any[];
    },
  ];
}

interface State {}
@inject('productModuleStore')
@inject('productModuleComponentLoadingStateStore')
@observer
export default class ProductModuleDefinitionSettingsBilling extends React.Component<
Props,
State
> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const {
      productModuleKey,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
    }
    if (productModuleComponentLoadingStateStore) {
      Object.keys(billing).map(checkboxDescription => {
        productModuleComponentLoadingStateStore.addComponentToStore(
          StoreIndex.productModuleBillingComponents,
          checkboxDescription,
        );
      });
    }
  }

  defaultCheckboxValue = (params: Params) => {
    const { path, displayCheckValue } = params;

    const { productModuleStore } = this.props;
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      const settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        return !!settingsItem.find(val => val === displayCheckValue);
      }
      return settingsItem === displayCheckValue;
    }
  };

  renderBillingSettings = (
    params: BillingParams,
    componentType: ComponentType,
  ) => {

    if (componentType === ComponentType.Checkbox) {
      return (
        <ProductModuleSettingsCheckbox
          params={params}
        />
      );
    }
    if (componentType === ComponentType.Select) {
      return (
        <ProductModuleSettingsSelect
          params={params}
        />
      );
    }
    if (
      componentType === ComponentType.Text ||
      componentType === ComponentType.Number
    ) {
      return (
        <ProductModuleSettingsTextInput
          params={params}
          componentType={componentType}
        />
      );
    }
  };

  render() {
    const {
      productModuleComponentLoadingStateStore,
      productModuleStore,
    } = this.props;
    if (!productModuleComponentLoadingStateStore || !productModuleStore) {
      return <LoadingInPage />;
    }

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          {this.renderBillingSettings(
            {
              ...billing.paymentMethodTypes,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Select,
          )}
          {this.renderBillingSettings(
            {
              ...billing.currency,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Select,
          )}
          {this.renderBillingSettings(
            {
              ...billing.premiumType,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Select,
          )}
          {this.renderBillingSettings(
            {
              ...billing.paymentReference,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Text,
          )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
        <CardBody className='product-module-definition-horizontal-card'>
          {this.renderBillingSettings(
            {
              ...billing.proRataBillingDisabled,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {!defaultCheckboxValue(
            { ...billing.proRataBillingDisabled },
            productModuleStore,
          ) && this.renderBillingSettings(
            {
              ...billing.proRataBillingOnIssue,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {!defaultCheckboxValue(
            { ...billing.proRataBillingDisabled },
            productModuleStore,
          ) &&
            this.renderBillingSettings(
              {
                ...billing.proRataMinimum,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                  index: 0,
                },
              },
              ComponentType.Text,
            )}
          {this.renderBillingSettings(
            {
              ...billing.retryFailedPayments,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Number,
          )}
          {getTextInputValue(
            { ...billing.retryFailedPayments },
            productModuleStore,
          ) !== 0 &&
            getTextInputValue(
              { ...billing.retryFailedPayments },
              productModuleStore,
            ) !== null &&
            this.renderBillingSettings(
              {
                ...billing.daysBetweenFailedPaymentRetries,
                ...{
                  lockedComponent: productModuleStore.lockedComponent,
                  index: 0,
                },
              },
              ComponentType.Number,
            )}
          {this.defaultCheckboxValue({
            ...billing.paymentMethodTypes,
            displayCheckValue: 'debit_order',
            lockedComponent: productModuleStore.lockedComponent,
          }) &&
          this.renderBillingSettings(
            {
              ...billing.debitOrderPaymentStrategy,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Select,
          )}
          {this.renderBillingSettings(
            {
              ...billing.billBeforeWeekend,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.renderBillingSettings(
            {
              ...billing.naedoArrearsPolicies,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.renderBillingSettings(
            {
              ...billing.shouldCreateExternalPayments,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.renderBillingSettings(
            {
              ...billing.enableBillingOnSandbox,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Checkbox,
          )}
          {this.renderBillingSettings(
            {
              ...billing.paymentSubmissionLeadTime,
              ...{
                lockedComponent: productModuleStore.lockedComponent,
                index: 0,
              },
            },
            ComponentType.Number,
          )}
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
