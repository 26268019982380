import React from 'react';
import { Input, FormGroup, Col, Card, CardHeader, Row, Button, CardBody } from 'reactstrap';
import { Icon12ArrowRight } from '../../../../../../components/icons/icon-12-arrow-right';
import { Icon24PXCross } from '../../../../../../components/icons/icon-24-px-cross';
import { inject, observer } from 'mobx-react';
import { ProductModuleDefinitionAlterationHooksListStore } from '../../../stores/product-module-definition-alteration-hooks-list-store';

export interface ProductModuleDefinitionAlterationHookFormData {
  name: string;
  key: string;
  schema: object;
}

interface Props {
  onSave: (productModuleDefinitionAlterationHooks: ProductModuleDefinitionAlterationHookFormData[]) => void;
  onCancel: () => void;
}

interface State {
  productModuleDefinitionAlterationHooks: ProductModuleDefinitionAlterationHookFormData[];
}

interface Injected extends Props {
  productModuleDefinitionAlterationHooksListStore: ProductModuleDefinitionAlterationHooksListStore;
}
@inject('productModuleStore', 'productModuleDefinitionAlterationHooksListStore')
@observer
export class ProductModuleDefinitionAlterationHooksEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { productModuleDefinitionAlterationHooksListStore } = this.injected;
    const { productModuleDefinitionAlterationHooks } = productModuleDefinitionAlterationHooksListStore;

    this.state = {
      productModuleDefinitionAlterationHooks: productModuleDefinitionAlterationHooks.length > 0 ? productModuleDefinitionAlterationHooks.map(({ name, key, productModuleDefinitionSchema }) => {
        return {
          name,
          key,
          schema: productModuleDefinitionSchema.jsonContent,
        };
      }) : [{
        name: '',
        key: '',
        schema: [],
      }],
    };
  }


  get injected() {
    return this.props as Injected;
  }

  removeItem = (idx: number) => {
    const alterationHooks = [...this.state.productModuleDefinitionAlterationHooks];

    alterationHooks.splice(idx, 1);

    this.setState({ productModuleDefinitionAlterationHooks: alterationHooks });
  }

  onSaveClick = () => {
    this.props.onSave(this.state.productModuleDefinitionAlterationHooks);
  }

  onCancelClick = () => {
    this.props.onCancel();
  }

  onAddClick = () => {
    const newItem: ProductModuleDefinitionAlterationHookFormData = {
      key: '',
      name: '',
      schema: [],
    };

    this.setState({ productModuleDefinitionAlterationHooks: [...this.state.productModuleDefinitionAlterationHooks, newItem] });
  }

  onNameChanged = (idx: number, name: string) => {
    const productModuleDefinitionAlterationHooks = [...this.state.productModuleDefinitionAlterationHooks];
    const item = { ...productModuleDefinitionAlterationHooks[idx], name };

    productModuleDefinitionAlterationHooks[idx] = item;

    this.setState({ productModuleDefinitionAlterationHooks });
  }

  onKeyChanged = (idx: number, key: string) => {
    const productModuleDefinitionAlterationHooks = [...this.state.productModuleDefinitionAlterationHooks];
    const item = { ...productModuleDefinitionAlterationHooks[idx], key };

    productModuleDefinitionAlterationHooks[idx] = item;

    this.setState({ productModuleDefinitionAlterationHooks });
  }

  get valid() {
    return !!this.state.productModuleDefinitionAlterationHooks.find(s => {
      return !!(s.name === '' || s.key === '');
    });
  }

  renderItem = (item: ProductModuleDefinitionAlterationHookFormData, idx: number) => {
    return <Card style={{ marginBottom: 20 }} key={`edit_scheduled_code_${idx}`}>
      <CardHeader
        className='rule-header'
        style={{ backgroundColor: 'white', paddingBottom: 0 }}
      >
        <Row>
          <Col md={4} sm={12}>
            <div
              className='arrow-right-border-fill-lifecycle'
              style={{
                backgroundColor: '#DEDEDE',
                display: 'inline-block',
              }}
            >
              <Icon12ArrowRight />
            </div>
            <h6 className='completed-card-rule-heading'>
              Alteration hook {idx + 1}
            </h6>
          </Col>
          <Col md={8} sm={12} style={{ paddingTop: 0, marginTop: 0 }}>
            <Button
              className='remove-rule-button'
              color='link'
              onClick={() => this.removeItem(idx)}
            >
              Remove
              <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                <Icon24PXCross
                  onClick={() => this.removeItem(idx)}
                  height={'10'}
                  width={'10'}
                  stroke={'#007bff'}
                />
              </div>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <FormGroup row>
          <Col xs={12} sm={6}>
            <label className='label-policy-lifecycle'>Name</label>
          </Col>
          <Col xs={3} sm={6}>
            <Input
              type='text'
              className='alteration-hooks-name-input'
              value={item.name}
              onChange={({ target }) => this.onNameChanged(idx, target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row style={{ marginTop: 20 }}>
          <Col xs={12} sm={6}>
            <label className='label-policy-lifecycle'>Key</label>
          </Col>
          <Col xs={3} sm={6}>
            <Input
              type='text'
              className='alteration-hooks-name-input'
              value={item.key}
              onChange={({ target }) => this.onKeyChanged(idx, target.value)}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </Card>;
  }

  render() {
    return <>
      <Row>
        <Col>
          {this.state.productModuleDefinitionAlterationHooks.length === 0 && <div>No alteration hooks have been created for this definition.</div>}
          {this.state.productModuleDefinitionAlterationHooks.map(this.renderItem)}
        </Col>
      </Row>
      <FormGroup className='product-modules-form-group-align'>
        <Button
          outline
          color='primary'
          onClick={this.onSaveClick}
          disabled={this.valid}
        >
          Save
        </Button>
        <Button
          color='link'
          onClick={this.onAddClick}
        >
          Add +
        </Button>
        <Button
          color='link'
          onClick={this.onCancelClick}
        >
          Cancel
        </Button>
      </FormGroup>
    </>;
  }
}
