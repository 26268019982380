export enum TypesOfPolicyholdersCondition {
  equals = 'equals',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export const documents: any = {
  welcomeLetter: {
    label: 'Welcome letter is enabled',
    description: '',
    indent: 0,
    path: 'welcomeLetterEnabled',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: true,
    key: 'welcome',
  },
};
