export enum Weekday {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export const weekdayToNumber = (wd: Weekday) => {
  switch (wd) {
    case Weekday.Sunday: return 0;
    case Weekday.Monday: return 1;
    case Weekday.Tuesday: return 2;
    case Weekday.Wednesday: return 3;
    case Weekday.Thursday: return 4;
    case Weekday.Friday: return 5;
    case Weekday.Saturday: return 6;
  }
};

export const numberToWeekday = (number: number) => {
  switch (number) {
    case 0: return Weekday.Sunday;
    case 1: return Weekday.Monday;
    case 2: return Weekday.Tuesday;
    case 3: return Weekday.Wednesday;
    case 4: return Weekday.Thursday;
    case 5: return Weekday.Friday;
    case 6: return Weekday.Saturday;
  }
};

export const prettyWeekday = (wd: Weekday) => {
  switch (wd) {
    case Weekday.Sunday: return 'Sunday';
    case Weekday.Monday: return 'Monday';
    case Weekday.Tuesday: return 'Tuesday';
    case Weekday.Wednesday: return 'Wednesday';
    case Weekday.Thursday: return 'Thursday';
    case Weekday.Friday: return 'Friday';
    case Weekday.Saturday: return 'Saturday';
  }
};
