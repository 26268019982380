import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  Alert,
} from 'reactstrap';
import {
  ajax,
} from '../../../../helpers';

interface Policy {
  [key: string]: any;
  module: {
    type: string;
  };
}

interface AddPaymentModalProps {
  onClose: () => void;
  onPaymentAdded: () => void;
  policy?: Policy;
  show: boolean;
}

interface AddPaymentModalState {
  isValid: boolean;
  error: string;
  paymentStatus?: PaymentStatus;
  paymentDate?: string;
  billingDate?: string;
  description?: string;
  amount?: number;
  collectionType?: CollectionType;
  paymentType?: PaymentType;
  premiumType?: PremiumType;
}

enum PaymentStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Processing = 'processing',
  Failed = 'failed',
  Successful = 'successful',
  Cancelled = 'cancelled',
}

enum CollectionType {
  DebitOrderSameDay = 'debit_order_same_day',
  DebitOrderTwoDay = 'debit_order_two_day',
  DebitOrderOneDay = 'debit_order_one_day',
  DebitOrderNaedo = 'debit_order_naedo',
  DebitOrderDebicheck = 'debit_order_debicheck',
  RealTimeClearing = 'real_time_clearing',
  RealTimeLine = 'real_time_line',
  Card = 'card',
  Other = 'other',
}

enum PaymentType {
  Premium = 'premium',
  Reversal = 'reversal',
  ClaimPayout = 'claim_payout',
  PremiumRefund = 'premium_refund',
  Other = 'other'
}

enum PremiumType {
  Recurring = 'recurring',
  Arrears = 'arrears',
  AdHoc = 'ad_hoc',
  ProRata = 'pro_rata',
  CoverPeriod = 'cover_period'
}

export class AddPaymentModal extends React.Component<AddPaymentModalProps, AddPaymentModalState> {
  constructor(props: AddPaymentModalProps) {
    super(props);

    this.state = {
      isValid: false,
      error: '',
    };
  }

  addPayment = async () => {
    const { policy } = this.props;

    if (!policy) {
      return;
    }

    const data = {
      description: this.state.description,
      premium_type: this.state.premiumType,
      payment_type: this.state.paymentType,
      collection_type: this.state.collectionType,
      payment_date: this.state.paymentDate,
      billing_date: this.state.billingDate,
      status: this.state.paymentStatus,
      amount: this.state.amount,
      payment_method_id: this.props.policy && this.props.policy.payment_method_id,
    };

    try {
      await ajax({
        path: `/insurance/admin/apps/${policy.organization_id}/policies/${
          policy.policy_id
        }/payments`,
        type: 'post',
        data,
      });

      this.props.onClose();
      this.props.onPaymentAdded();
    } catch (err) {
      const error = JSON.parse(err.message);
      if (error.message === 'Validation failed') {
        this.setState({ error: error.errors[0].message });
      } else {
        this.setState({ error: error.message });
      }
    }
  }

  checkPaymentType = () => {
    const isPremiumOrReversal = this.state.paymentType &&
      [PaymentType.Premium, PaymentType.Reversal].includes(this.state.paymentType);

    if (
      this.state.paymentType &&
      (isPremiumOrReversal && this.state.premiumType) ||
      (!isPremiumOrReversal && !this.state.premiumType)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate = () => {
    let isValid = false;
    if (
      this.state.paymentStatus &&
      this.state.paymentDate &&
      this.state.amount &&
      this.state.description &&
      this.state.collectionType &&
      this.state.paymentType &&
      [PaymentType.Premium, PaymentType.Reversal].includes(this.state.paymentType) ? this.state.billingDate : true &&
      this.checkPaymentType()
    ) {
      isValid = true;
    }

    if (isValid !== this.state.isValid) {
      this.setState({ isValid });
    }
  }

  handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const paymentType = event.target.value as PaymentType;
    this.setState({ paymentType });

    if (![PaymentType.Premium, PaymentType.Reversal].includes(paymentType)) {
      this.setState({ premiumType: undefined });
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Add payment for this policy
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Policy number: {this.props.policy && this.props.policy.policy_number}</Label>
                <Label>Payment method ID: {this.props.policy && this.props.policy.payment_method_id}</Label>
              </FormGroup>
              <Alert color='warning'>
                <strong>Warning:</strong> this change will reflect on the
                policy&#39;s dashboard & ledger, and the policyholder may be billed.
              </Alert>
              {this.state.error && (
                <Alert color='danger'>{this.state.error}</Alert>
              )}
              <FormGroup>
                <Label>Status</Label>
                <Input
                  onChange={e => this.setState({ paymentStatus: e.target.value as PaymentStatus })}
                  type='select'
                  name='paymentStatus'
                  id='paymentStatus'
                >
                  <option value=''>Select...</option>
                  {Object.entries(PaymentStatus).map(pair =>
                    <option key={pair[1]} value={pair[1]}>{pair[0]}</option>,
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Payment date</Label>
                <Input
                  onChange={e => this.setState({ paymentDate: e.target.value })}
                  type='date'
                  name='paymentDate'
                  id='paymentDate'
                  placeholder='Payment date'
                />
              </FormGroup>
              <FormGroup>
                <Label>Billing date</Label>
                <Input
                  onChange={e => this.setState({ billingDate: e.target.value })}
                  type='date'
                  name='billingDate'
                  id='billingDate'
                  placeholder='Billing date'
                />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  onChange={e => this.setState({ description: e.target.value })}
                  name='paymentDesc'
                  id='paymentDesc'
                  placeholder='Payment description'
                />
              </FormGroup>
              <FormGroup>
                <Label>Amount (in cents with no decimals)</Label>
                <Input
                  onChange={e => this.setState({ amount: parseInt(e.target.value) })}
                  type='number'
                  name='amount'
                  id='amount'
                  placeholder='Payment amount'
                />
              </FormGroup>
              <FormGroup>
                <Label>Collection type</Label>
                <Input
                  onChange={e => this.setState({ collectionType: e.target.value as CollectionType })}
                  type='select'
                  name='collectionType'
                  id='collectionType'
                >
                  <option value=''>Select...</option>
                  {Object.entries(CollectionType).map(pair =>
                    <option key={pair[1]}  value={pair[1]}>{pair[0]}</option>,
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Payment type</Label>
                <Input
                  onChange={this.handlePaymentTypeChange}
                  type='select'
                  name='paymentType'
                  id='paymentType'
                >
                  <option value=''>Select...</option>
                  {Object.entries(PaymentType).map(([key, value]) =>
                    <option key={value} value={value}>{key}</option>,
                  )}
                </Input>
              </FormGroup>
              { this.state.paymentType &&
                [PaymentType.Premium, PaymentType.Reversal].includes(this.state.paymentType) ?
                <FormGroup>
                  <Label>Premium type</Label>
                  <Input
                    onChange={e => this.setState({ premiumType: e.target.value as PremiumType })}
                    type='select'
                    name='premiumType'
                    id='premiumType'
                  >
                    <option value=''>Select...</option>
                    {Object.entries(PremiumType).map(pair =>
                      <option key={pair[1]} value={pair[1]}>{pair[0]}</option>,
                    )}
                  </Input>
                </FormGroup>
                : ''}
            </Form>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={!this.state.isValid}
              onClick={this.addPayment}
              color='primary'
            >
              Add payment
            </Button>

            <Button onClick={this.props.onClose} color='secondary'>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
