import React, { Component } from 'react';
import uuid from 'uuid';

export class BlankSpace extends Component<any, any> {
  render() {
    return (
      <div
        className='form-group col-sm-6'
        key={`blank-space-${uuid().toString()}`}
      />
    );
  }
}

export default BlankSpace;
