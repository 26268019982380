import { Moment } from 'moment';
import { BlockType } from './block-type';

export abstract class InputBlock {
  readonly type: BlockType;
  readonly key: string;
  readonly title: string;
  readonly description?: string;
  readonly placeholder?: string | Moment | number;
  readonly defaultValue?: string | Moment | number;
  readonly min?: number | Moment;
  readonly max?: number | Moment;
  readonly minLength?: number;
  readonly maxLength?: number;

  abstract toJSON(): {
    type: BlockType;
    key: string;
    title: string;
    description?: string;
    placeholder?: string | Moment | number;
    default_value?: string | Moment | number;
    min?: number | Moment;
    max?: number | Moment;
    min_length?: number;
    max_length?: number;
  }
}
