/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <defs>
          <path id='a' d='M0 0h16v16H0z' />
        </defs>
        <g fill='none' fillRule='evenodd'>
          <g>
            <path
              fill='#4078FA'
              d='M2.343 2.343a8 8 0 0 0 0 11.313 7.998 7.998 0 0 0 11.313 0 7.998 7.998 0 0 0 0-11.313 7.998 7.998 0 0 0-11.313 0'
              mask='url(#b)'
            />
          </g>
          <path
            fill='#FFF'
            d='M7.896 10.604c-.667 0-1.295-.26-1.768-.732a2.484 2.484 0 0 1-.732-1.768c0-.668.26-1.295.732-1.768a2.486 2.486 0 0 1 1.768-.732h.98l-.99.99.706.707 2.193-2.194-2.193-2.192-.707.708.981.98h-.97c-.935 0-1.813.365-2.475 1.026a3.477 3.477 0 0 0-1.025 2.475c0 .935.364 1.814 1.025 2.475a3.479 3.479 0 0 0 2.475 1.024c.935 0 1.814-.364 2.476-1.024l-.707-.708a2.488 2.488 0 0 1-1.769.732'
          />
        </g>
      </svg>
    );
  }
}
