import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';
import { AlertColor } from './alert-color';

export class AlertBlock extends Block {
  readonly markdown: string;
  readonly color: AlertColor;

  constructor(init: Construct<AlertBlock>) {
    super();
    Object.assign(this, init);
  }


  toJSON() {
    return {
      type: this.type,
      key: this.key,
      markdown: this.markdown,
      color: this.color,
    };
  }

  static fromJSON(init: any) {
    return new AlertBlock({
      type: init.type,
      key: init.key,
      markdown: init.markdown,
      color: init.color,
    });
  }
}