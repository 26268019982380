import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';
import { DataExportViewStore } from '../../stores/data-export-view-store';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';

interface Props extends RouteComponentProps {
  close: () => void;
  open: boolean;
  router?: History;
}

interface Injected extends Props {
  dataExportViewStore: DataExportViewStore;
}

interface State {}

@inject('dataExportViewStore')
@observer
class ArchiveDataExportModalImpl extends React.Component<Props, State> {

  get injected() {
    return this.props as Injected;
  }

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  render() {
    const { dataExportViewStore } = this.injected;

    return (
      <Modal isOpen={this.props.open} toggle={this.props.close} className='exports-prompt-modal'>
        <ModalHeader className='exports-prompt-modal-header'>
          <Icon24PXCross onClick={this.props.close} />
          <h4 className='exports-prompt-modal-header-text'>
          Archive export
          </h4>
        </ModalHeader>
        <ModalBody className='exports-prompt-modal-body'>
          <p>
            Are you sure you want to archive the{' '}
            <strong>
              {dataExportViewStore.dataExport && dataExportViewStore.dataExport.name}
            </strong>{' '} export? Once archived, an export will not run and cannot be resumed again.
          </p>
        </ModalBody>
        <ModalFooter className='exports-prompt-modal-footer'>
          <Button
            color='danger'
            disabled={dataExportViewStore.isLoading}
            onClick={this.archiveDataExport}
          >
            Archive export
          </Button>
          <br />
          <Button
            color='link'
            disabled={dataExportViewStore.isLoading}
            onClick={this.props.close}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  archiveDataExport = async () => {
    const { dataExportViewStore, router } = this.injected;

    const result = await dataExportViewStore.archive({ organizationId: this.organizationId });

    if (result && result.error) {
      return;
    }
    if (router) {
      router.push(`/organizations/${this.organizationId}/exports/`);
    }

    this.props.close();
  };
}

export const ArchiveDataExportModal = withRouter(ArchiveDataExportModalImpl);
