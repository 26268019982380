import * as React from 'react';
import {
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  ModalHeader,
} from 'reactstrap';

interface RejectPayoutModalProps {
  isOpen: boolean;
  onConfirm: (reason?: string) => void;
  onClose: () => void;
}

interface RejectPayoutModalState {
  reason?: string;
}

export class RejectPayoutModal extends React.Component<RejectPayoutModalProps,RejectPayoutModalState> {
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      reason: e.target.value,
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>Reject payout</ModalHeader>
        <ModalBody>
          <p>Reason</p>
          <Input type='textarea' onChange={this.onChange} />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' outline onClick={this.props.onClose}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => this.props.onConfirm(this.state.reason)}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
