import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

export default class PageHeader extends Component {
  render() {
    const { title, onClick } = this.props;
    return (
      <Container onClick={onClick} className='page-header'>
        <Row>
          <Col xs={6} style={{ paddingLeft: 5 }}>
            <h3>{title}</h3>
          </Col>
          {this.props.rightComponent && (
            <Col xs={6}>{this.props.rightComponent}</Col>
          )}
        </Row>
        {this.props.children && (
          <Row>
            <Col xs={12}>{this.props.children}</Col>
          </Row>
        )}
      </Container>
    );
  }
}
