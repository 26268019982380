import {
  LifecycleStoreOutput,
  LifecycleStoreVariables,
  LifecycleStore,
  Observables,
} from '../store';

import _ from 'lodash';
import { toCamelCase } from '../../../../../../../../helpers';
import { ProductModuleDefinition } from '../../../../../../../domain/product-module-definition';
import { createProductModuleDefinition } from '../../../../../../product-modules-api';
import { ProductModule } from '../../../../../../../domain/product-module';

export const savePolicyLifecycle = async (
  lifecycleStore: any,
  productModuleDefinition: any,
) => {
  const lifecycle: LifecycleStore = {
    coolingOffPeriodRules: {},
    coolingOffPeriodNotifications: {},
    waitingPeriodRules: {},
    waitingPeriodNotifications: {},
    gracePeriodRules: {},
    gracePeriodNotifications: {},
    renewalRules: {},
    renewalNotifications: {},
    premiumEscalationRules: {},
    premiumEscalationNotifications: {},
    expiryRules: {},
    expiryNotifications: {},
  };

  for (const key in lifecycleStore) {
    if (Object.keys(lifecycleStore[key as Observables]).length > 0) {
      lifecycle[key as Observables] = _.values(
        lifecycleStore[key as Observables],
      );
    } else {
      lifecycle[key as Observables] = [];
    }
  }

  const submitData: { [k in Observables]?: any } = {};

  try {
    for (const key in lifecycle) {
      if (_.endsWith(key, 'Rules')) {
        const outputPath = `${toCamelCase(
          key.substring(0, key.length - 5),
        )}.rules`;
        _.set(submitData, outputPath, lifecycle[key as Observables]);
      }
      if (_.endsWith(key, 'Notifications')) {
        const outputPath = `${toCamelCase(
          key.substring(0, key.length - 13),
        )}.notifications`;
        _.set(submitData, outputPath, lifecycle[key as Observables]);
      }
    }
  } catch (err) {
    console.debug('error in saving policy lifecycle to api format', err);
  }

  return saveDraft(false, submitData, productModuleDefinition);
};

export const saveDraft = async (
  ResetToPublished: boolean,
  policyLifecycleData: { [k in Observables]?: any },
  productModuleDefinition: ProductModuleDefinition,
) => {
  try {
    const settings: any = productModuleDefinition.settings;

    const policyActivationEvent =
      settings.lifecycle &&
      settings.lifecycle.policyActivationEvent !== null &&
      settings.lifecycle.policyActivationEvent !== undefined
        ? { policyActivationEvent: settings.lifecycle.policyActivationEvent }
        : null;

    const activateOnIssue =
      settings.lifecycle &&
      settings.lifecycle.activateOnIssue !== null &&
      settings.lifecycle.activateOnIssue !== undefined
        ? { activateOnIssue: settings.lifecycle.activateOnIssue }
        : null;

    const canReactivate =
      settings.lifecycle &&
      settings.lifecycle.canReactivate !== null &&
      settings.lifecycle.canReactivate !== undefined
        ? { canReactivate: settings.lifecycle.canReactivate }
        : null;

    const canRequote =
      settings.lifecycle &&
      settings.lifecycle.canRequote !== null &&
      settings.lifecycle.canRequote !== undefined
        ? { canRequote: settings.lifecycle.canRequote }
        : null;

    const notTakenUpEnabled =
      settings.lifecycle &&
      settings.lifecycle.notTakenUpEnabled !== null &&
      settings.lifecycle.notTakenUpEnabled !== undefined
        ? { notTakenUpEnabled: settings.lifecycle.notTakenUpEnabled }
        : null;

    settings.lifecycle = {
      ...policyLifecycleData,
      ...policyActivationEvent,
      ...activateOnIssue,
      ...canReactivate,
      ...canRequote,
      ...notTakenUpEnabled,
    };

    const data = {
      settings_json: settings,
    };

    return createProductModuleDefinition(
      productModuleDefinition.productModuleId.toString(),
      data,
    );
  } catch (err) {
    console.log('error sending to review', err);
  }
};

export const establishState = async (
  productModule: ProductModule,
  productModuleDefinition?: ProductModuleDefinition,
) => {
  try {
    const lifecycle: any =
      (productModuleDefinition && productModuleDefinition.settings.lifecycle) ||
      {};

    const productModuleSettings: LifecycleStoreOutput = {
      cooling_off_period: {
        rules: [],
        notifications: [],
      },
      waiting_period: {
        rules: [],
        notifications: [],
      },
      grace_period: {
        rules: [],
        notifications: [],
      },
      expiry: {
        rules: [],
        notifications: [],
      },
      renewal: {
        rules: [],
        notifications: [],
      },
      premium_escalation: {
        rules: [],
        notifications: [],
      },
      policy_activation_event: {
        rules: [],
        notifications: [],
      },
    };

    for (const key in lifecycle) {
      if (
        lifecycle[key as LifecycleStoreVariables].rules &&
        lifecycle[key as LifecycleStoreVariables].rules !== undefined
      ) {
        const camelCaseKey = _.camelCase(key);
        const lifecycleKeyValue =
          lifecycle[key as LifecycleStoreVariables].rules;

        lifecycleKeyValue.forEach((value: any, index: number) => {
          _.set(productModuleSettings, `${camelCaseKey}Rules.${index}`, value);
        });
      }
      if (
        lifecycle[key as LifecycleStoreVariables].notifications &&
        lifecycle[key as LifecycleStoreVariables].notifications !== undefined
      ) {
        const camelCaseKey = _.camelCase(key);
        const lifecycleKeyValue =
          lifecycle[key as LifecycleStoreVariables].notifications;

        lifecycleKeyValue.forEach((value: any, index: number) => {
          _.set(
            productModuleSettings,
            `${camelCaseKey}Notifications.${index}`,
            value,
          );
        });
      }
    }
    return {
      productModuleSettings,
      productModule,
      productModuleDefinition,
    };
  } catch (err) {
    console.log('Error loading the product module definition', err);
  }
};
