import React from 'react';
import { Table, CardBody } from 'reactstrap';
import { getAllProductModuleDefinitions, getProductModuleDefinitionMarkdown } from '../../product-modules-api';
import moment from 'moment';
import { ProductModule } from '../../../domain/product-module';
import { ProductModuleDefinition } from '../../../domain/product-module-definition';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore } from '../../product-definition/stores/product-module-store';
import { PaginationComponent } from '../../../../components/pagination';
import marked from 'marked';
interface Props {
  productModuleKey: string;
  productModuleStore?: ProductModuleStore;
  redirect: (key: string) => any;
}

interface State {
  productModuleDefinitions: ProductModuleDefinition[];
  productModule?: ProductModule;
  page: number;
  limit: number;
}
@inject('productModuleStore')
@observer
export default class ProductModuleVersion extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      productModuleDefinitions: [],
      page: 0,
      limit: 10,
    };
  }

  async componentWillMount() {
    const { productModuleKey, productModuleStore } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey, true);
      const productModule = productModuleStore.productModule;
      if (productModule) {
        const productModuleDefinitions = await getAllProductModuleDefinitions({
          productModuleId: productModuleStore.productModule.productModuleId,
        });
        this.setState({ productModuleDefinitions, productModule });
      }
    }
  }

  onProductModuleDefinitionSelect = (productModuleDefinition: ProductModuleDefinition) => {
    const { productModuleStore, redirect } = this.props;
    if (productModuleStore) {
      productModuleStore.setProductModulePrimary(productModuleDefinition);
      redirect(productModuleStore.productModule.key);
    }
  };

  openChangelog = async (productModuleDefinition: ProductModuleDefinition) => {
    // Hacky open in new page to keep it light

    const markdown = await getProductModuleDefinitionMarkdown(productModuleDefinition.productModuleId,
      productModuleDefinition.productModuleDefinitionId,
      productModuleDefinition.changelogMarkdownId!);

    const newWindow = window.open();
    const version = productModuleDefinition.versionMajor + '.' + productModuleDefinition.versionMinor;
    newWindow!.document.write(`<html><body><p>Version ${version}</p><hr/>${marked(markdown.markdown)}</body></html>`);
    newWindow!.document.title = 'Product module changelog';
  };

  renderTableRow = (
    productModule: ProductModule,
    productModuleDefinition: ProductModuleDefinition,
    key: number,
  ) => {
    const editedOrPublished = productModuleDefinition.versionMinor === 0 ? 'Published' : 'Edited';

    let status = 'Draft';
    if (productModuleDefinition.productModuleDefinitionId === productModule.liveId) {
      status = 'Active';
    } else if (productModuleDefinition.productModuleDefinitionId !== productModule.draftId) {
      status = 'Archived';
    }

    return (
      <tr key={key}>
        <td>
          <div>
            <div className='inline-div-with-padding-left-right'>
              {productModuleDefinition.versionMajor}.
              {productModuleDefinition.versionMinor}
            </div>
            {
              productModuleDefinition.productModuleDefinitionId === productModule.liveId
              && <div
                className='arrow-right-border-fill completed-card-background-color-green inline-div-with-padding-left-right'
                style={{ fontSize: 13 }}
              >Published</div>
            }
            {
              productModuleDefinition.productModuleDefinitionId === productModule.draftId
              && <div
                className='arrow-right-border-fill completed-card-background-color-grey inline-div-with-padding-left-right'
                style={{ fontSize: 13 }}
              >Latest draft</div>
            }
          </div>
        </td>
        <td>{status}</td>
        <td style={{ fontStyle: 'italic' }}>
          {editedOrPublished}{' '}
          {moment.duration(moment().utc().diff(productModuleDefinition.createdAt)).humanize()}{' '}ago
        </td>
        <td>
          <a href='#' onClick={() => this.onProductModuleDefinitionSelect(productModuleDefinition)}>View</a>
          {' | '}
          {
            productModuleDefinition.changelogMarkdownId
              ? <a href='#' onClick={() => this.openChangelog(productModuleDefinition)}>CHANGELOG.md</a>
              : 'no changelog'
          }
        </td>
      </tr>
    );
  };

  renderTableRows = (
    productModuleDefinitions: ProductModuleDefinition[],
    productModule: ProductModule,
    page: number,
  ) => {
    const productModuleDefinitionsPaged = productModuleDefinitions.slice(
      page * this.state.limit,
      page * this.state.limit + this.state.limit,
    ).map((pmd: ProductModuleDefinition, index: number) => this.renderTableRow(productModule, pmd, index));
    return [...productModuleDefinitionsPaged];
  };

  render() {
    const { productModule, page, limit, productModuleDefinitions } = this.state;

    if (!productModule) {
      return <div />;
    }
    return (
      <div>
        <CardBody style={{ marginBottom: 0 }}>
          <Table>
            <thead>
              <tr>
                <th>Version</th>
                <th>Status</th>
                <th>Last edit</th>
                <th>Changelog</th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableRows(productModuleDefinitions, productModule, page)}
            </tbody>
          </Table>
        </CardBody>
        <div style={{ marginTop: -20, marginLeft: 20 }}>
          <PaginationComponent
            page={page}
            limit={limit}
            total={productModuleDefinitions.length || 0}
            disabled={false}
            goToPage={page => {
              this.setState({ page });
            }}
          />
        </div>
      </div>
    );
  }
}
