
//https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-completion-provider-example

export function createJsonSchemaDependencyProposals(range: any, monaco: any) {
  return [
    {
      label: 'date-picker',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a date picker input',
      insertText: JSON.stringify(
        {
          type: 'date-picker',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          outputPath:
            '${3:<optional - dot notation where to set value e.g. spouse.date_of_birth>}',
          validators: [
            {
              validation: {
                type: '${4:required}',
              },
            },
          ],
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'currency',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a currency input',
      insertText: JSON.stringify(
        {
          type: 'currency',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          props: {
            prefix: '\${3:\'R\'}',
          },
          validators: [
            {
              validation: {
                type: '${4:required}',
              },
            },
            {
              validation: {
                min: '\${5:',
                type: '\${6: greaterThanCurrency}',
              },
            },
            {
              validation: {
                max: '\${7:}',
                type: '\${8:lessThanCurrency}',
              },
            },
          ],
          outputPath:
            '${9:<optional - dot notation where to set value e.g. beneficiary.select_type>}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'select',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a select input',
      insertText: JSON.stringify(
        {
          type: 'select',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          options: [
            {
              label: '${3:}',
              value: '${4:}',
            },
          ],
          outputPath:
            '${5:<optional - dot notation where to set value e.g. beneficiary.select_type>}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'section-header',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a section header',
      insertText: JSON.stringify(
        {
          type: 'section-header',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          props: {
            fontWeight: '${3:<either: bold, normal>}',
            headingTag: '${4:<either h1, h2, h3, h4, h5>}',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'text',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a text input',
      insertText: JSON.stringify(
        {
          type: 'text',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          outputPath:
            '${3:<optional - dot notation where to set value e.g. beneficiary.text_value>}',
          validators: [
            {
              validation: {
                type: '${4:required}',
              },
            },
          ],
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'radio',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a radio input',
      insertText: JSON.stringify(
        {
          type: 'radio',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          options: [
            {
              label: '${3:}',
              value: '${4:}',
            },
          ],
          outputPath:
            '${5:<optional - dot notation where to set value e.g. deceased.id_type>}',
          defaultValue: '${6:}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'country',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a country picker',
      insertText: JSON.stringify(
        {
          type: 'country',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          outputPath:
            '${3:<optional - dot notation where to set value e.g. deceased.country>}',
          validators: [
            {
              validation: {
                type: '${4:required}',
              },
            },
          ],
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'number',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a number picker',
      insertText: JSON.stringify(
        {
          type: 'number',
          key: '${1:<mandatory - enter_unique_key>}',
          label: '${2:<mandatory - enter_label>}',
          outputPath:
            '${3:<optional - dot notation where to set value e.g. beneficiary.number_value>}',
          validators: [
            {
              validation: {
                type: '${4:required}',
              },
            },
            {
              validation: {
                min: '${5:}',
                type: '${6:}',
              },
            },
            {
              validation: {
                max: '${7:}',
                type: '${8:}',
              },
            },
          ],
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'blank-space',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a blank space',
      insertText: JSON.stringify(
        {
          type: 'blank-space',
          key: '${1:<mandatory - enter_unique_key>}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'horizontal-line',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a horizontal line',
      insertText: JSON.stringify(
        {
          type: 'horizontal-line',
          key: '${1:<mandatory - enter_unique_key>}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'checkbox',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a checkbox picker',
      insertText: JSON.stringify(
        {
          type: 'horizontal-line',
          key: '${1:<mandatory - enter_unique_key>}',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'list',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a list component',
      insertText: JSON.stringify(
        {
          type: 'list',
          label: '${1:<mandatory - enter_label>}',
          props: {
            addButtonLabel: 'Add child',
          },
          maxNumber: 20,
          components: [
            {
              type: 'section-header',
              label: '${2:<mandatory - enter_label>}',
              props: {
                headingTag: 'h5',
                indexNumber: true,
              },
            },
            {
              type: 'number',
              key: '${3:<mandatory - enter_unique_key>}',
              label: '${4:<mandatory - enter_label>}',
              outputPath: 'children',
              validators: [
                {
                  validation: {
                    type: 'required',
                  },
                },
                {
                  validation: {
                    min: 0,
                    type: 'greaterThanNumber',
                  },
                },
                {
                  validation: {
                    max: 21,
                    type: 'lessThanNumber',
                  },
                },
              ],
            },
          ],
          outputPathList: 'children',
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'paragraph',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a paragraph',
      insertText: JSON.stringify(
        {
          type: 'paragraph',
          label: '${1:<mandatory - enter_label>}',
          key: '${2:<mandatory - enter_unique_key>}',
          props: {
            colWidth: '${3:<Optional number parameter will default to 12>}',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'display conditions',
      kind: monaco.languages.CompletionItemKind.Property,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a display conditions',
      insertText: JSON.stringify(
        {
          path: '${1:<path to value to base off>}',
          value: '${2:<either string or boolean>}',
          condition: '===',
          or: {
            path: '${3:<path to value to base off>}',
            value: '${4:<either string or boolean>}',
            condition: '===',
          },
          and: {
            path: '${5:<path to value to base off>}',
            value: '${6:<either string or boolean>}',
            condition: '===',
          },
        },
        null,
        4,
      ),
      range: range,
    },
    {
      label: 'validators conditions',
      kind: monaco.languages.CompletionItemKind.Property,
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      documentation: 'Snippet for creating a validators conditions',
      insertText: JSON.stringify(
        {
          validators: [
            {
              validation: {
                type:
                  '${1:<one of the following - [lessThanNumber, lessThanLength, greaterThanNumber, greaterThanLength ,za_id, email, lessThanNumber, greaterThanCurrency, lessThanCurrency, lessThanLength, required, imei]>}',
                min: '${2:}',
                max: '${3:}',
              },
            },
          ],
        },
        null,
        4,
      ),
      range: range,
    },
  ];
}
