import React from 'react';
import { Table, Badge, Button } from 'reactstrap';
import {
  formatDate,
  getRandValue,
  orgLink,
  paymentBatchLink,
  paymentBatchPaymentsLink,
  ajax,
} from '../../../helpers';
import { utc as moment } from 'moment';

export default class PaymentBatchesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { paymentBatches } = this.props;

    return (
      <Table hover className='scrollable-table'>
        <thead>
          <tr>
            <td>
              Batch Id
            </td>
            <td>
              Org Id
            </td>
            <td>
              Payment method
            </td>
            <td>
              Payment type
            </td>
            <td>
              Provider type
            </td>
            <td>
              Status
            </td>
            <td>
              Submitted at
            </td>
            <td>
              Process date
            </td>
            <td>
              Action date
            </td>
            <td>
              Payments
            </td>
            <td>
              Total value
            </td>
            <td>
              External
            </td>
            <td>
              Download
            </td>
            <td>
              Action
            </td>
          </tr>
        </thead>
        <tbody>{paymentBatches.map(o => this.renderRow(o))}</tbody>
      </Table>
    );
  }

  renderRow = batch => {
    return (
      <PaymentBatchRow
        batch={batch}
        key={batch.paymentBatchId}
        refresh={this.props.refresh}
      />
    );
  };
}

export const statusBadge = (status) => {
  switch (status) {
    case 'pending':
      return <Badge color='warning'>Pending</Badge>;
    case 'submitting':
      return <Badge color='info'>Submitting</Badge>;
    case 'submitted':
      return <Badge color='success'>Submitted</Badge>;
    default:
      return <Badge>{status}</Badge>;
  }
};

class PaymentBatchRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  getFileName(batch) {
    return (
      `${moment(batch.actionDate).format('YYYY_MM_DD')}_` +
      `${batch.paymentType.toUpperCase()}_${batch.paymentBatchId}.csv`
    );
  }

  downloadCsv = async batch => {
    this.setState({ loading: true });
    const response = await ajax({
      type: 'GET',
      path: `/insurance/admin/payment-batches/${batch.paymentBatchId}/csv`,
      raw: true,
    });

    if (response.status !== 200) {
      const body = await response.json();

      console.error(
        'Error downloading export: ',
        body && JSON.stringify(body.error),
      );

      window.alert(
        `Error downloading CSV: ${body && JSON.stringify(body.error, null, 2)}`,
      );
    } else {
      const blob = await response.blob();
      const uri = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const filename = this.getFileName(batch);

      element.setAttribute('href', uri);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    this.setState({ loading: false });
  };

  submitPaymentBatch = async batch => {
    if (!window.confirm('Are you sure you want to submit this batch?')) {
      return;
    }
    this.setState({ loading: true });
    try {
      if (!batch.numPayments) {
        throw new Error('Batch has no payments to submit');
      }
      if (batch.numReviewed !== batch.numPayments) {
        if (
          !window.confirm(
            'Batch has unreviewed payments. Are you sure you want to submit?',
          )
        ) {
          return;
        }
      }
      await ajax({
        path: `/insurance/admin/payment-batches/${batch.paymentBatchId}/submit`,
        type: 'POST',
      });
      this.props.refresh();
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      window.alert(error.message);
    }
  };

  render() {
    const batch = this.props.batch;
    return (
      <tr key={batch.paymentBatchId}>
        <td>{paymentBatchLink(batch.paymentBatchId)}</td>
        <td>{orgLink(batch.organizationId)}</td>
        <td>{batch.paymentMethodType}</td>
        <td>{batch.paymentType}</td>
        <td>{batch.billingProvider.providerType}</td>
        <td>{statusBadge(batch.status)}</td>
        <td>{batch.submittedAt ? formatDate(batch.submittedAt) : '-'}</td>
        <td>{formatDate(batch.processDate)}</td>
        <td>{formatDate(batch.actionDate)}</td>
        <td>{paymentBatchPaymentsLink(batch)}</td>
        <td>{`R ${getRandValue(batch.totalValue)}`}</td>
        <td>{batch.externalReference || '-'}</td>
        <td>
          <Button
            disabled={this.state.loading}
            color='link'
            style={{ minWidth: 'auto', display: 'contents' }}
            onClick={() => this.downloadCsv(batch)}
          >
            CSV
          </Button>
        </td>
        <td>
          {batch.status === 'pending' ? (
            <Button
              disabled={this.state.loading}
              color='link'
              style={{ minWidth: 'auto', display: 'contents' }}
              onClick={() => this.submitPaymentBatch(batch)}
            >
              Submit
            </Button>
          ) : (
            '-'
          )}
        </td>
      </tr>
    );
  }
}
