import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { BlockState } from '../domain/states/block-state';
import { InputDateBlock as Block } from '../domain/input-date-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { InputDateBlockState } from '../domain/states/input-date-block-state';
import { FormGroup, Label } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: InputDateBlockState;
}

interface State {
  UIState: BlockUIState;
  inputDateBlockState?: BlockState;
}

export class InputDateBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      inputDateBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { disabled, block, required } = this.props;
    const { inputDateBlockState } = this.state;
    if (!inputDateBlockState) {
      return null;
    }

    const { value } = inputDateBlockState as InputDateBlockState;

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <Label>
          <b>{block.title}</b>
        </Label>
        <br />
        {block.description && (
          <span className='form-label'>{block.description}</span>
        )}
        <ReactDatePicker
          disabled={disabled}
          required={required}
          id={block.key}
          className='form-control'
          key={block.key}
          isClearable={!this.props.required}
          selected={value ? value.toDate() : null}
          dateFormat='YYYY-MM-DD'
          minDate={block.min ? block.min.toDate() : null}
          maxDate={block.max ? block.max.toDate() : null}
          onChange={() => {}}
        />
      </FormGroup>
    );
  }
}
