import React from 'react';
import { Alert, Card, CardBody, CardHeader } from 'reactstrap';
import { toCamelCaseKeys } from '../../../helpers';
import ajax from '../../../helpers/ajax';
import PaymentsTable from '../payments-table';
import { PaginationComponent } from '../../../components/pagination';

export default class UnassignedPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: null,
      loading: true,
      page: 0,
      pageSize: 10,
      total: 0,
    };
  }

  get status() {
    return this.props.router.location.pathname
      .split('/')
      .slice(-1)
      .pop();
  }

  componentDidMount() {
    this.fetchPayments();
  }

  componentWillReceiveProps() {
    this.fetchPayments();
  }

  async fetchPayments() {
    this.setState({ loading: true });

    const queryString = `payment_batch_id=null&status=${this.status}&include=payment_method,billing_provider`;

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payments?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const payments = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payments?${queryString}${pagination}`,
    })).map(toCamelCaseKeys);

    this.setState({ payments, loading: false, total: total.headers.get('X-Total-Count') });
  }

  render() {
    const { page, pageSize, total } = this.state;
    return (
      <div className='bottom-padded'>
        <Card>
          <CardHeader>
            Unassigned {this.status} payments (
            {this.state.payments ? this.state.payments.length : 0})
          </CardHeader>
          <CardBody>
            {this.state.error && (
              <Alert color='danger'>{this.state.error}</Alert>
            )}
            {this.state.loading ? (
              <div>Loading...</div>
            ) : this.state.payments && this.state.payments.length > 0 ? (
              <div>
                <PaymentsTable
                  payments={this.state.payments}
                  refresh={this.fetchPayments.bind(this)}
                />
                <PaginationComponent
                  page={page || 0}
                  limit={pageSize}
                  total={total || 0}
                  disabled={false}
                  goToPage={page => {
                    this.setState({
                      page,
                    }, async () => await this.fetchPayments());
                  }}
                />
              </div>
            ) : (
              <div>{`No unassigned ${this.status} payments`}</div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
