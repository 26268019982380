import * as React from 'react';

export const Icon16PXCopy = (props: { fill?: string; stroke?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='16'
    viewBox='0 0 14 16'
  >
    <g fill='none' fillRule='evenodd'>
      <path
        stroke={props.stroke || '#4078FA'}
        strokeWidth='2'
        d='M5 14V6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1z'
      />
      <path
        fill={props.fill || '#FFF'}
        d='M1 10V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1'
      />
      <path
        stroke={props.stroke || '#4078FA'}
        strokeWidth='2'
        d='M1 10V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z'
      />
    </g>
  </svg>
);
