
import React from 'react';

export const buttonText = (params: { loading: boolean; text: string  }) => {
  const { loading, text } = params;
  if(loading) {
    return <span>
      <span className='loading-dots' style={{ position: 'relative', left: 0 }}>
        <span className='dot dot-one'></span>
        <span className='dot dot-two'></span>
        <span className='dot dot-three'></span>
      </span>
    </span>;
  }
  return <span>{text}</span>;
};
