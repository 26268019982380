import React from 'react';
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Alert,
  ModalFooter,
} from 'reactstrap';
import { withData, toSentenceCase, renderValue, ajax } from '../../../helpers';
import CardEntry from '../../../components/card-entry';

const failures = ['failed'];

class SetPaymentStatusModal extends React.Component {
  state = {
    status: '',
    reason: '',
    isValid: false,
    error: '',
  };

  setPaymentStatus = async () => {
    const { paymentId } = this.props;

    if (!this.state.isValid) return;

    try {
      await ajax({
        path: '/insurance/admin/payments',
        type: 'PATCH',
        data: [
          {
            payment_id: paymentId,
            status: this.state.status,
            reason_failed: this.state.reason || undefined,
          },
        ],
      });

      this.props.onClose();
      this.props.onPaymentStatusUpdated();
    } catch (e) {
      console.log(e);

      try {
        const error = JSON.parse(e.message);
        this.setState({ error: error.message });
      } catch (_) {
        this.setState({ error: e.message });
      }
    }
  };

  setStatus = ({ target }) => {
    if (target.value) {
      this.setState({
        status: target.value,
        isValid: failures.includes(target.value) ? !!this.state.reason : true,
        error: '',
      });
    } else {
      this.setState({ status: null, isValid: false });
    }
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.props.show} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Set the status for this payment
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='paymentStatusSelect'>Select status:</Label>
              <Input
                onChange={e => this.setStatus(e)}
                type='select'
                name='select'
                id='paymentStatusSelect'
              >
                <option value=''>Select...</option>
                <option value='submitted'>Submitted</option>
                <option value='successful'>Successful</option>
                <option value='cancelled'>Cancelled</option>
                <option value='failed'>Failed</option>
              </Input>
              {failures.includes(this.state.status) ? (
                <Input
                  placeholder={`Reason ${this.state.status}`}
                  onChange={e =>
                    this.setState({
                      reason: e.target.value,
                      isValid: !!e.target.value,
                    })
                  }
                  value={this.state.reason}
                />
              ) : (
                ''
              )}
            </FormGroup>
            <Alert color='warning'>
              <strong>Warning:</strong> this change will reflect in the
              organization&#39;s dashboard
            </Alert>
            {this.state.error && (
              <Alert color='danger'>{this.state.error}</Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.isValid}
              onClick={this.setPaymentStatus}
              color='primary'
            >
              Set
            </Button>
            <Button color='secondary' onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class PolicyPayment extends React.Component {
  state = {
    showSetPaymentStatusModal: false,
  };

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading payment...</i>
        </div>
      );
    }

    const { payment } = this.props;

    return (
      <Container style={{ padding: 0 }}>
        <Card>
          <CardHeader>
            <Row>
              <Col sm={4}>Payment details</Col>

              <Col sm={{ size: 'auto' }}>
                <Button
                  onClick={() =>
                    this.setState({ showSetPaymentStatusModal: true })
                  }
                >
                  Update status
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {Object.keys(payment).map((key, index) => (
              <CardEntry key={key} index={index}>
                <div style={{ padding: '2px' }}>
                  <b>{toSentenceCase(key)}</b>
                </div>
                <CardText className='overview-value'>
                  {renderValue(key, payment[key])}
                </CardText>
              </CardEntry>
            ))}
          </CardBody>
        </Card>

        <SetPaymentStatusModal
          onClose={() => this.setState({ showSetPaymentStatusModal: false })}
          show={this.state.showSetPaymentStatusModal}
          paymentId={this.props.payment.paymentId}
          onPaymentStatusUpdated={this.props.fetchPayment}
        />
      </Container>
    );
  }
}

export default withData({
  path: props => `/insurance/admin/payments/${
    props.params.paymentId
  }?include=payment_method,billing_provider`,
  prop: 'payment',
})(PolicyPayment);
