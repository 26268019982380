import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class HeadingBlock extends Block {
  readonly title: string;

  constructor(init: Construct<HeadingBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
    };
  }

  static fromJSON(init: any) {
    return new HeadingBlock({
      type: init.type,
      key: init.key,
      title: init.title,
    });
  }
}