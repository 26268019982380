import { TypesOfPolicyholdersCondition } from './product-module-definition-settings-policyholder';

export const typesOfBeneficiaries: any = {
  enableBeneficiaries: {
    label: 'Enable beneficiaries on a policy.',
    description:
      'Claim benefits will be distributed to one or more nominated recipients who are not the policyholder.',
    indent: 0,
    path: 'beneficiaries.enabled',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: true,
    key: 'enableBeneficiaries',
    reset: [
      {
        path: 'beneficiaries.min',
        default: 0,
        comparisonValues: [0, null, false],
      },
      {
        path: 'beneficiaries.max',
        default: 0,
        comparisonValues: [0, null, false],
      },
      {
        path: 'beneficiaries.policyholderIsBeneficiary',
        default: false,
        comparisonValues: [0, null, false],
      },
    ],
  },
  enablePolicyholderIsBeneficiary: {
    label: 'Enable policyholder as beneficiary.',
    description:
      'Policyholder is added to beneficiaries on a policy.',
    indent: 1,
    index: 0,
    path: 'beneficiaries.policyholderIsBeneficiary',
    condition: TypesOfPolicyholdersCondition.equals,
    displayCheckValue: true,
    key: 'enablePolicyholderIsBeneficiary',
  },
  maximumNumberOfBeneficiaries: {
    label: 'Maximum number of beneficiaries',
    description: 'The benefit of a claim or payout will be distributed between 1 - N beneficiaries.',
    indent: 1,
    index: 0,
    path: 'beneficiaries.max',
    condition: TypesOfPolicyholdersCondition.equals,
    key: 'maximumNumberOfBeneficiaries',
  },
  minimumNumberOfBeneficiaries: {
    label: 'Minimum number of beneficiaries',
    description: 'The benefit of a claim or payout will be distributed between 1 - N beneficiaries.',
    indent: 1,
    index: 0,
    path: 'beneficiaries.min',
    condition: TypesOfPolicyholdersCondition.equals,
    key: 'minimumNumberOfBeneficiaries',
  },

  // beneficiarieRequiredAtIssuing: {
  //   label: 'Beneficiaries are required at policy issuing',
  //   description:
  //     'You will not be able to complete issuing a policy without capturing all required beneficiary details',
  //   indent: 1,
  // },
};

export const capturedBeneficiaryDetails: any = {
  // mobileRequired: {
  //   label: 'Mobile number is required.',
  //   description:
  //     'A beneficiary’s mobile number will be required to complete their details.',
  //   indent: 1,
  // },
  // emailAddressRequired: {
  //   label: 'Email address is required.',
  //   description:
  //     'A beneficiary’s email address will be required to complete their details.',
  //   indent: 1,
  // },
  // idNumberRequired: {
  //   label: 'Identity number is required.',
  //   description:
  //     'A beneficiary’s identity number will be required to complete their details.',
  //   indent: 1,
  // },
  // beneficiaryCanBeSAResident: {
  //   label: 'Beneficiary can be a South African resident or citizen.',
  //   description:
  //     'A 13-digit South African identity number will be used to identify the beneficiary.',
  //   indent: 2,
  // },
  // beneficiaryCanHoldNonSAPassport: {
  //   label: 'Beneficiary can hold a non-South African passport.',
  //   description:
  //     'A passport number will be used to identify the beneficiary.',
  //   indent: 2,
  // },
  // collectBankDetails: {
  //   label: 'Collect bank details.',
  //   description:
  //     'Request the bank details of beneficiaries if claim payouts can include monetary payments. ',
  //   indent: 1,
  // },
  // relationshipToPolicyholderIsRequired: {
  //   label: 'Relationship to policyholder is required.',
  //   description:
  //     'The beneficiay’s relationship to policyholder must be specified.',
  //   indent: 1,
  // },
};
