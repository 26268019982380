export const policyholder = {
  policyholder_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  type: 'individual',
  first_name: 'Erlich',
  last_name: 'Bachman',
  company_name: 'Random Corp',
  registration_number: '123CantCatchMe321',
  id: {
    type: 'id',
    number: '9401014800081',
    country: 'ZA',
  },
  email: 'elrich@root.co.za',
  cellphone: '022 232 2323',
  phone_other: '022 232 3232',
  date_of_birth: '19940101',
  gender: 'non-binary',
  created_at: '"2019-03-01T06:54:33.731Z',
  app_data: null,
  created_by: {
    type: 'user',
    id: 'c7bc530c-7291-4574-96ed-1018c9cd45c8',
    ownerId: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
  address: {
    line_1: 'Milky Way',
    line_2: 'Universe',
    code: 0,
  },
};
