import { observable, computed } from 'mobx';
import { Pagination } from '../../shared/domain/pagination';
import { ProductModuleCodeRunLogItem } from '../domain/product-module-code-run-log-item';
import { getProductModuleCodeRunLogItems } from '../product-module-api';

export class ProductModuleCodeRunLogStore {
  @observable productModuleCodeRunLogItems: ProductModuleCodeRunLogItem[] = [];
  @observable isLoading = true;
  @observable total = 0;
  @observable pagination: Pagination = { page: 0, pageSize: 10 };

  load = async (params: {
    productModuleId: string;
    productModuleCodeRunId: string;
    pagination?: Pagination;
  }) => {
    const { productModuleId, productModuleCodeRunId, pagination } = params;
    this.isLoading = true;
    this.productModuleCodeRunLogItems = [];

    const {
      productModuleCodeRunLogItems,
      total,
    } = await getProductModuleCodeRunLogItems({
      productModuleId,
      productModuleCodeRunId,
      page: pagination ? pagination.page : this.pagination.page + 1,
      pageSize: pagination ? pagination.pageSize : this.pagination.pageSize,
    });

    if (pagination) {
      this.pagination = pagination;
    }

    this.productModuleCodeRunLogItems = productModuleCodeRunLogItems;
    this.total = total;
  };

  @computed get isEmpty() {
    return !this.isLoading && this.productModuleCodeRunLogItems.length === 0;
  }

  setProductModuleCodeRunLogStoreLoading = (params: { isLoading: boolean }) => {
    this.isLoading = params.isLoading;
  };
}

export const productModuleCodeRunLogStore = new ProductModuleCodeRunLogStore();
