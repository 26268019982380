import React from 'react';
import { getRandValue } from './index';
import moment from 'moment';
import Highlight from 'react-highlight';
import { Link } from 'react-router';
import pluralize from 'pluralize';
import { toKebabCase } from './cases';

const MONEY_KEYS = [
  'sumassured',
  'billingamount',
  'basepremium',
  'monthlypremium',
  'amount',
  'balance',
  'totalvalue',
];

const JSON_KEYS = [
  'appdata',
  'createdby',
  'finalizedby',
  'module',
  'scheduleversions',
  'beneficiaries',
  'paymentmethod',
  'billingprovider',
  'id',
  'bankdetails',
  'address',
  'submittedby',
  'rawresponse',
  'charges',
];

const LINK_KEYS = [
  'organizationid',
  'policyid',
  'paymentid',
  'paymentmethodid',
  'policyholderid',
  'paymentbatchid',
];

const MULTILINK_KEYS = ['policyids'];

export const renderValue = (propKey, value) => {
  const key = propKey.toLowerCase().replace('_', '');

  if (key.slice(-2) === 'At' || key.includes('date')) {
    const m = moment(value);
    if (!m.isValid()) {
      return '-';
    }
    return moment(value).format('YYYY-MM-DD HH:mm');
  }

  if (JSON_KEYS.includes(key)) {
    return (
      <Highlight className='json'>{JSON.stringify(value, null, 2)}</Highlight>
    );
  }

  if (MONEY_KEYS.includes(key)) {
    return 'R' + getRandValue(value);
  }

  if (LINK_KEYS.includes(key)) {
    const prefix = toKebabCase(pluralize(propKey.slice(0, -2)));

    return <Link to={'/' + prefix + '/' + value}>{value}</Link>;
  }

  if (MULTILINK_KEYS.includes(key)) {
    const prefix = toKebabCase(pluralize(propKey.slice(0, -3)));

    return (
      <React.Fragment>
        {value.map((id, i) => (
          <React.Fragment key={i}>
            <Link key={id} to={'/' + prefix + '/' + id}>
              {id}
            </Link>
            {i < value.length - 1 && ', '}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  if (key.toLowerCase() === 'billingday') {
    return value || 1;
  }

  return value || value === 0 ? value.toString() : '';
};
