/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='122px'
        height='87.486px'
        viewBox='0 0 122 87.486'
        enableBackground='new 0 0 122 87.486'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <rect
              x='30.531'
              y='41.852'
              fill='#FFFFFF'
              stroke='#212B40'
              strokeWidth='2'
              strokeMiterlimit='10'
              width='60.938'
              height='43.634'
            />
            <rect
              x='27.71'
              y='15.708'
              fill='#CCDEF3'
              width='66.58'
              height='27.648'
            />
            <g>
              <path
                fill='#CCDEF3'
                d='M47.047,6.053c0,0.55-0.45,1-1,1H33.228c-0.55,0-1-0.45-1-1V1c0-0.55,0.45-1,1-1h12.819
            c0.55,0,1,0.45,1,1V6.053z'
              />
            </g>
            <rect
              x='34.277'
              y='7.636'
              fill='#9FC3E7'
              stroke='#12161F'
              strokeMiterlimit='10'
              width='10.721'
              height='7.322'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='27.71'
              y1='21.333'
              x2='94.29'
              y2='21.333'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='27.71'
              y1='26.753'
              x2='94.29'
              y2='26.753'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='27.71'
              y1='32.172'
              x2='94.29'
              y2='32.172'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='41.025'
              y1='15.914'
              x2='41.025'
              y2='21.333'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='54.342'
              y1='15.914'
              x2='54.342'
              y2='21.333'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='67.658'
              y1='15.914'
              x2='67.658'
              y2='21.333'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='80.975'
              y1='15.914'
              x2='80.975'
              y2='21.333'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='34.367'
              y1='21.333'
              x2='34.367'
              y2='26.753'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='47.684'
              y1='21.333'
              x2='47.684'
              y2='26.753'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='74.316'
              y1='21.333'
              x2='74.316'
              y2='26.753'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='87.632'
              y1='21.333'
              x2='87.632'
              y2='26.753'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='41.025'
              y1='27.164'
              x2='41.025'
              y2='32.583'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='80.975'
              y1='27.164'
              x2='80.975'
              y2='32.583'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='34.367'
              y1='32.583'
              x2='34.367'
              y2='38.003'
            />
            <line
              fill='none'
              stroke='#9FC3E7'
              strokeMiterlimit='10'
              x1='87.632'
              y1='32.583'
              x2='87.632'
              y2='38.003'
            />
            <g>
              <path
                fill='#CCDEF3'
                d='M95.748,44.378c0,0.549-0.45,1-1,1H27.252c-0.55,0-1-0.451-1-1v-5.053c0-0.55,0.45-1,1-1h67.496
            c0.55,0,1,0.45,1,1V44.378z'
              />
              <path
                fill='none'
                stroke='#212B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                d='M95.748,44.378c0,0.549-0.45,1-1,1H27.252
            c-0.55,0-1-0.451-1-1v-5.053c0-0.55,0.45-1,1-1h67.496c0.55,0,1,0.45,1,1V44.378z'
              />
            </g>
            <g>
              <polygon
                fill='#FFFFFF'
                stroke='#212B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                points='81.03,40.723 60.928,21.539 40.97,40.723 40.97,85.486 81.03,85.486      '
              />
              <g>
                <g>
                  <path
                    fill='#CCDEF3'
                    d='M61.652,26.296c-0.396-0.382-1.043-0.381-1.438,0.002L36.286,49.511
                c-0.395,0.383-1.03,0.371-1.411-0.025l-2.681-2.788c-0.382-0.396-0.37-1.034,0.024-1.417l27.98-27.109
                c0.396-0.383,1.043-0.384,1.439-0.002l28.141,27.107c0.396,0.382,0.409,1.02,0.029,1.418l-2.669,2.799
                c-0.38,0.397-1.015,0.41-1.41,0.029L61.652,26.296z'
                  />
                  <path
                    fill='none'
                    stroke='#212B40'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                    d='M61.652,26.296
                c-0.396-0.382-1.043-0.381-1.438,0.002L36.286,49.511c-0.395,0.383-1.03,0.371-1.411-0.025l-2.681-2.788
                c-0.382-0.396-0.37-1.034,0.024-1.417l27.98-27.109c0.396-0.383,1.043-0.384,1.439-0.002l28.141,27.107
                c0.396,0.382,0.409,1.02,0.029,1.418l-2.669,2.799c-0.38,0.397-1.015,0.41-1.41,0.029L61.652,26.296z'
                  />
                </g>
              </g>
              <g>
                <path
                  fill='#CCDEF3'
                  stroke='#212B40'
                  strokeWidth='2'
                  strokeMiterlimit='10'
                  d='M70.834,49.302c0-1.104-0.896-2-2-2H53.166
              c-1.104,0-2,0.896-2,2v21.822c0,0,0.896,0,2,0h15.668c1.104,0,2,0,2,0V49.302z'
                />
              </g>
              <line
                fill='none'
                stroke='#212B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                x1='60.834'
                y1='47.302'
                x2='60.834'
                y2='71.125'
              />
              <line
                fill='none'
                stroke='#212B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                x1='51.166'
                y1='55.244'
                x2='70.834'
                y2='55.244'
              />
              <line
                fill='none'
                stroke='#212B40'
                strokeWidth='2'
                strokeMiterlimit='10'
                x1='51.166'
                y1='63.184'
                x2='70.834'
                y2='63.184'
              />
            </g>
          </g>
          <line
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='1.2'
            strokeMiterlimit='10'
            x1='20.501'
            y1='74.875'
            x2='20.501'
            y2='67.298'
          />
          <line
            fill='none'
            stroke='#FFFFFF'
            strokeWidth='1.2'
            strokeMiterlimit='10'
            x1='101.499'
            y1='74.875'
            x2='101.499'
            y2='67.298'
          />
          <g>
            <path
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='1.2'
              strokeMiterlimit='10'
              d='M14.75,83.548v-6.674c0-1.104,0.896-2,2-2
          h7.502c1.104,0,2,0.896,2,2v6.674'
            />
            <circle
              fill='#CCDEF3'
              stroke='#CCDEF3'
              strokeWidth='1.2'
              strokeMiterlimit='10'
              cx='20.501'
              cy='62.423'
              r='4.875'
            />
            <path
              fill='#FFFFFF'
              stroke='#FFFFFF'
              strokeWidth='1.2'
              strokeMiterlimit='10'
              d='M95.748,83.548v-6.674c0-1.104,0.896-2,2-2
          h7.502c1.104,0,2,0.896,2,2v6.674'
            />
            <circle
              fill='#CCDEF3'
              stroke='#CCDEF3'
              strokeWidth='1.2'
              strokeMiterlimit='10'
              cx='101.499'
              cy='62.423'
              r='4.875'
            />
          </g>
          <line
            fill='none'
            stroke='#12161F'
            strokeWidth='4'
            strokeLinecap='round'
            strokeMiterlimit='10'
            x1='2'
            y1='85.486'
            x2='120'
            y2='85.486'
          />
        </g>
      </svg>
    );
  }
}
