import React from 'react';
import { Media } from 'reactstrap';

export default class LoadingInPage extends React.Component {
  render() {
    const left = this.props.left ? this.props.left : '50%';
    const height = this.props.height ? this.props.height : 100;
    return (
      <div style={{ position: 'absolute', zIndex: 12, top: '50%', left }}>
        <Media
          style={{ height }}
          src='/images/lego-alfred-full.png'
          alt='Alfred Logo'
        />
        <p style={{ fontSize: 20, textAlign: 'center' }}>Loading ...</p>
      </div>
    );
  }
}
