import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { ProductModuleStore } from '../../../../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import { TypesOfPolicyholdersCondition } from '../../../../config/product-module-definition-settings-policyholder';
import {
  ProductModuleComponentLoadingStateStore, StoreIndex,
} from '../../../../../../stores/product-module-component-loading-state-store';
import ProductModuleSettingsCheckbox from '../../../../checkbox';

interface Props {
  productModuleKey: string;
}

interface Injected extends Props {
  productModuleComponentLoadingStateStore: ProductModuleComponentLoadingStateStore;
  productModuleStore: ProductModuleStore;
}

const notTakenUpEnabled = {
  label: 'Enable not taken up checks for policies.',
  description: '',
  indent: 0,
  path: 'lifecycle.notTakenUpEnabled',
  condition: TypesOfPolicyholdersCondition.equals,
  displayCheckValue: true,
  key: 'notTakenUpEnabled',
};

interface State {}
@inject('productModuleStore', 'productModuleComponentLoadingStateStore')
@observer
export class ProductModuleDefinitionSettingsNTUEnabled extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { productModuleKey, productModuleStore, productModuleComponentLoadingStateStore } = this.injected;

    await productModuleStore.init(productModuleKey);
    productModuleComponentLoadingStateStore.addComponentToStore(
      StoreIndex.productModuleSettingsComponents,
      notTakenUpEnabled.key,
    );
  }

  render() {
    const { productModuleStore } = this.injected;

    return (
      <div>
        <CardBody className='product-module-definition-horizontal-card'>
          <Row>
            <Col xs={3}>
              <p className='product-module-definition-key-header'>
                Not taken up
              </p>
            </Col>
            <Col xs={9}>
              <span>
                The not taken up rules for policies govern what happens when a policy&apos;s first payment fails or gets reversed.
              </span>
            </Col>
          </Row>

          <ProductModuleSettingsCheckbox params={{
            ...notTakenUpEnabled,
            ...{
              lockedComponent: productModuleStore.lockedComponent,
              index: 0,
            },
          }} />
        </CardBody>
        <hr className='hr-with-no-margin-bottom-top' />
      </div>
    );
  }
}
