import { Construct } from '../../shared/domain/construct';
import { Moment, utc as moment } from 'moment';
import { Requestee } from '../../shared/domain/requestee';

export class OrganizationProductModule {
  readonly productModuleId: string;
  readonly organizationId: string;
  readonly sandbox: boolean;
  readonly live: boolean;
  readonly createdAt: Moment;
  readonly restricted: boolean;
  readonly key: string;
  readonly name: string;
  readonly createdBy: Requestee;
  readonly liveId?: string;

  constructor(init: Construct<OrganizationProductModule>) {
    Object.assign(this, init);
  }

  toNetwork() {
    return {
      product_module_id: this.productModuleId,
      organization_id: this.organizationId,
      sandbox: this.sandbox ? this.sandbox : undefined,
      live: this.live ? this.live : undefined,
      created_at: this.createdAt.toISOString(),
      name: this.name && this.name,
      key: this.key && this.key,
      restricted: this.restricted,
      created_by: this.createdBy,
    };
  }

  static fromNetwork(init: NetworkOrganizationProductModule): OrganizationProductModule {
    return new OrganizationProductModule({
      productModuleId: init.product_module_id,
      organizationId: init.organization_id,
      sandbox: init.sandbox,
      live: init.live,
      createdAt: moment(init.created_at).utc(),
      restricted: init.restricted,
      key: init.key,
      name: init.name,
      createdBy: Requestee.fromJSON(init.created_by),
      liveId: init.live_id,
    });
  }
}

export interface NetworkOrganizationProductModule {
  product_module_id: string;
  organization_id: string;
  sandbox: boolean;
  live: boolean;
  created_at: string;
  restricted: boolean;
  key: string;
  name: string;
  created_by: object;
  live_id?: string;
  product_module_definition_id: string;
}
