import React from 'react';
import { Route, IndexRoute } from 'react-router';

// Components
import { FeatureList } from './views/feature-list';

export default (
  <Route name='features'>
    <IndexRoute component={FeatureList} />
  </Route>
);
