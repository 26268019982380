import React from 'react';
import {
  Col,
  Button,
  FormGroup,
  Row,
  CardHeader,
  CardBody,
  Card,
} from 'reactstrap';
import '../../../../../../../../../styles/styles.scss';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { ObservablesTemp, Store } from '../../../store';
import '../../../styles/general.scss';
import {
  WaitingPeriodNotificationsConfig,
  waitingPeriodNotificationsSelectOptions,
} from '../config';
import SelectInputLifecycle from '../../form-components/select-input';
import InputLifecycle from '../../form-components/text-input';
import { WaitingPeriodNotificationsOutput } from '../../../store-interfaces';
import { spliceSelectOptions } from '../../../utils';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';
import { Icon12plus } from '../../../../../../../../../../components/icons/icon-12-plus';
import { Icon24PXCross } from '../../../../../../../../../../components/icons/icon-24-px-cross';

interface Props {
  index: number;
  reduceCount: () => void;
  allowList: boolean;
  removeFromLifeCycleStore: (lifecycleObservable: ObservablesTemp) => void;
  waitingPeriodNotificationsTemp: WaitingPeriodNotificationsOutput;
  store: Store;
}

interface State {}

@observer
class WaitingPeriodNotification extends React.Component<Props, State> {
  getValue = (path: string) => {
    const { index, waitingPeriodNotificationsTemp } = this.props;
    const value = _.get(waitingPeriodNotificationsTemp[index], path);
    return value && value !== undefined ? value : '';
  };

  displayPeriod = (path: string) => {
    const value = this.getValue(path);
    return (
      value &&
      value !== waitingPeriodNotificationsSelectOptions.selectOption1.value
    );
  };

  render() {
    const { notifyThePolicyholder } = WaitingPeriodNotificationsConfig;
    const { waitingPeriodNotificationsTemp: temp } = ObservablesTemp;
    const { index, removeFromLifeCycleStore, reduceCount } = this.props;

    return (
      <Card style={{ marginBottom: 20 }}>
        <CardHeader
          className='rule-header'
          style={{ backgroundColor: 'white', paddingBottom: 0 }}
        >
          <Row>
            <Col md={4} sm={12}>
              <div
                className='arrow-right-border-fill-lifecycle'
                style={{
                  backgroundColor: '#DEDEDE',
                  display: 'inline-block',
                }}
              >
                {index === 0 ? <Icon12ArrowRight /> : <Icon12plus />}
              </div>
              <h6 className='completed-card-rule-heading'>Notification</h6>
            </Col>
            <Col md={8} sm={12} className='remove-button-wrapper'>
              {this.props.allowList && (
                <Button
                  className='remove-notification-button'
                  color='link'
                  onClick={() => {
                    removeFromLifeCycleStore(temp);
                    reduceCount();
                  }}
                >
                  Remove
                  {
                    <div style={{ display: 'inline-block', paddingLeft: 4 }}>
                      <Icon24PXCross
                        height={'10'}
                        width={'10'}
                        stroke={'#007bff'}
                      />
                    </div>
                  }
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <FormGroup row className='row-form-spacing'>
              <Col xs={12} sm={6}>
                <label className='label-policy-lifecycle'>
                  Notify the policyholder
                </label>
              </Col>
              <Col xs={3} sm={6} style={{ paddingLeft: 16, paddingBottom: 10 }}>
                <SelectInputLifecycle
                  observable={ObservablesTemp.waitingPeriodNotificationsTemp}
                  store={this.props.store}
                  optionsSpliced={spliceSelectOptions(
                    this.props.waitingPeriodNotificationsTemp,
                    notifyThePolicyholder.outputPaths.trigger,
                    [
                      waitingPeriodNotificationsSelectOptions.selectOption1
                        .value,
                      waitingPeriodNotificationsSelectOptions.selectOption2
                        .value,
                    ],
                    notifyThePolicyholder.selectWaitingPeriodNotifications
                      .trigger,
                  )}
                  options={
                    notifyThePolicyholder.selectWaitingPeriodNotifications
                      .trigger
                  }
                  index={this.props.index}
                  path={notifyThePolicyholder.outputPaths.trigger}
                />
              </Col>
            </FormGroup>
            <FormGroup row style={{ marginBottom: 15 }}>
              <Col xs={12} sm={6} />
              {this.displayPeriod(
                notifyThePolicyholder.outputPaths.trigger,
              ) && (
                <Col xs={3} sm={2}>
                  <InputLifecycle
                    observable={ObservablesTemp.waitingPeriodNotificationsTemp}
                    store={this.props.store}
                    index={this.props.index}
                    path={notifyThePolicyholder.outputPaths.value}
                    placeHolder={'30'}
                  />
                </Col>
              )}
              {this.displayPeriod(
                notifyThePolicyholder.outputPaths.trigger,
              ) && (
                <Col xs={3} sm={4} style={{ paddingLeft: 0 }}>
                  <SelectInputLifecycle
                    observable={ObservablesTemp.waitingPeriodNotificationsTemp}
                    store={this.props.store}
                    options={
                      notifyThePolicyholder.selectWaitingPeriodNotifications
                        .type
                    }
                    index={this.props.index}
                    path={notifyThePolicyholder.outputPaths.type}
                    placeHolder={
                      notifyThePolicyholder.selectWaitingPeriodNotifications
                        .type[0].label
                    }
                  />
                </Col>
              )}
            </FormGroup>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

export default WaitingPeriodNotification;
