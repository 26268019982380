import React from 'react';
import ProductModuleDefinitionSettingsPolicyHolder from './components/product-module-definition-settings-policyholder';
import ProductModuleDefinitionSettingsSetUp from './components/product-module-definition-settings-set-up';
import ProductModuleDefinitionSettingsBeneficiaries from './components/beneficiaries';
import PolicyLifecycleComponent from './components/policy-lifecycle';
import ProductModuleDefinitionSettingsCoveredPeople from './components/covered-people';
import { ProductModuleDefinitionSettingsNTUEnabled } from './components/policy-lifecycle/components/not-taken-up/not-taken-up';

interface Props {
  productModuleKey: string;
}

export enum ComparisionType {
  NotIn = 'notIn',
}

export interface SettingsParams {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: string;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
  options?: any;
  multipleSelect?: boolean;
  title?: string;
  reset?: [
    {
      path: string;
      default: string | number | boolean | null;
      comparisonValues: any[];
      comparisionType: ComparisionType;
    },
  ];
}

export default class ProductModuleDefinitionSettings extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <ProductModuleDefinitionSettingsSetUp
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionSettingsPolicyHolder
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionSettingsBeneficiaries
          productModuleKey={this.props.productModuleKey}
        />
        <ProductModuleDefinitionSettingsCoveredPeople
          productModuleKey={this.props.productModuleKey}
        />
        <PolicyLifecycleComponent
          productModuleKey={this.props.productModuleKey}
          stage={'draft'}
        />
        <ProductModuleDefinitionSettingsNTUEnabled
          productModuleKey={this.props.productModuleKey}
        />
      </div>
    );
  }
}
