import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class Pager extends Component {
  renderPager = (page, name) => {
    const { onClick, currentPage } = this.props;
    return (
      <PaginationItem
        key={`PagerLink_${page}_${name}`}
        disabled={page === currentPage && isNaN(name || page)}
        active={page === currentPage}
      >
        <PaginationLink onClick={() => onClick(page)}>
          {name || page}
        </PaginationLink>
      </PaginationItem>
    );
  };
  renderFirst = () => {
    return this.renderPager(1, 'First');
  };
  renderLast = () => {
    const { maxPages } = this.props;
    return this.renderPager(maxPages, 'Last');
  };
  renderRewind = () => {
    const { currentPage } = this.props;
    return this.renderPager(
      currentPage - 1 > 0 ? currentPage - 1 : currentPage,
      '«',
    );
  };
  renderFastForward = () => {
    const { currentPage, maxPages } = this.props;
    return this.renderPager(
      currentPage + 1 < maxPages ? currentPage + 1 : maxPages,
      '»',
    );
  };
  render() {
    const { currentPage, maxPages, maxPagers } = this.props;
    /* Use the maxPagers as a window to determine the number of paging links to display */
    const halfPagers = Math.ceil(maxPagers / 2);
    /* Use the uppermost value of pages to base window
     of to prioritize future pages - it is more likely you have been at 1 before */
    const indexEnd =
      currentPage + maxPagers < maxPages
        ? currentPage + maxPagers
        : currentPage + halfPagers <= maxPages
          ? currentPage + halfPagers
          : maxPages;
    const indexStart = indexEnd - maxPagers > 0 ? indexEnd - maxPagers : 1;
    const aPagers = [];
    for (let i = indexStart; i <= indexEnd; i++) {
      aPagers[i] = i;
    }
    return (
      <div style={{display: 'inline-block'}}>
        <Pagination size='md'>
          {this.renderFirst()}
          {this.renderRewind()}
          {aPagers.map((page, i) => {
            return this.renderPager(page);
          })}
          {this.renderFastForward()}
          {this.renderLast()}
        </Pagination>
      </div>
    );
  }
}
