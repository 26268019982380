export enum TypesOfSetupConditions {
  equals = 'equals',
  greaterThan = 'greaterThan',
  lessThan = 'greaterThan',
}

export const settingsToExtract: any = {
  policySchemeType: {
    label: 'Premium type',
    description: '',
    indent: 0,
    path: 'policySchemeType',
    condition: TypesOfSetupConditions.equals,
    key: 'policySchemeType',
    options: [
      {
        label: 'Individual',
        value: 'individual',
      },
      {
        label: 'Group',
        value: 'group',
      },
    ],
  },
  policyActivationEvent: {
    label: 'Policy activation event',
    description: 'Determines when the policy is activated',
    indent: 0,
    path: 'lifecycle.policyActivationEvent',
    condition: TypesOfSetupConditions.equals,
    key: 'policyActivationEvent',
    options: [
      {
        label: 'Active on issue',
        value: 'policy_issued',
      },
      {
        label: 'Active on payment method assigned',
        value: 'payment_method_assigned',
      },
      {
        label: 'Active on first successful payment',
        value: 'first_successful_payment',
      },
      {
        label: 'None (handled externally)',
        value: 'none',
      },
    ],
  },
  policyStructure: {
    label: 'Policy structure',
    description: '',
    indent: 0,
    path: 'policyholder.entityTypes',
    condition: TypesOfSetupConditions.equals,
    key: 'policyStructure',
    multipleSelect: true,
    options: [
      {
        label: 'Individual',
        value: 'individual',
      },
      {
        label: 'Group',
        value: 'group',
      },
    ],
  },
  canRequote: {
    label: 'Requote policy is enabled',
    description: 'Polices can be requoted',
    indent: 0,
    path: 'lifecycle.canRequote',
    condition: TypesOfSetupConditions.equals,
    displayCheckValue: true,
    key: 'canRequote',
  },
  canReactivate: {
    label: 'Reactivate policy is enabled',
    description: 'Policies can be reactivated',
    indent: 0,
    path: 'lifecycle.canReactivate',
    condition: TypesOfSetupConditions.equals,
    displayCheckValue: true,
    key: 'canReactivate',
  },
  policyAnniversary: {
    label: 'Policy anniversary notification enabled',
    description: 'Content to be sent on policy anniversary',
    indent: 0,
    path: 'policyAnniversary.enabled',
    condition: TypesOfSetupConditions.equals,
    displayCheckValue: true,
    key: 'policyAnniversaryEnabled',
    reset: [
      {
        path: 'policyAnniversary.daysBeforeAnniversary',
        default: 0,
        comparisonValues: [0, null, false],
      },
    ],
  },
  policyAnniversaryDaysBeforeAnniversary: {
    label: 'Days before anniversary',
    description: '',
    indent: 1,
    path: 'policyAnniversary.daysBeforeAnniversary',
    condition: TypesOfSetupConditions.equals,
    key: 'policyAnniversaryDaysBeforeAnniversary',
  },
  coveredItemsEnabled: {
    label: 'Covered items are enabled',
    description: 'Policies can have covered items',
    indent: 0,
    path: 'coveredItems.enabled',
    condition: TypesOfSetupConditions.equals,
    displayCheckValue: true,
    key: 'coveredItemsEnabled',
    reset: [
      {
        path: 'coveredItems.maxSumAssured',
        default: 0,
        comparisonValues: [0, null, false],
      },
    ],
  },
  coveredItemsMaxSumAssured: {
    label: 'Covered items max sum assured',
    description: '',
    indent: 1,
    path: 'coveredItems.maxSumAssured',
    condition: TypesOfSetupConditions.equals,
    key: 'coveredItemsMaxSumAssured',
  },
  canIssuePolicyThroughDashboard: {
    label: 'Enable issuing through the dashboard',
    description: 'Policies can be issued through the dashoard',
    indent: 0,
    path: 'canIssuePolicyThroughDashboard',
    condition: TypesOfSetupConditions.equals,
    displayCheckValue: true,
    key: 'canIssuePolicyThroughDashboard',
  },
};
