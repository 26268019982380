import { observable, computed } from 'mobx';
import { getProductModuleDefinitionScheduledFunctions } from '../../product-modules-api';
import { ProductModuleDefinitionScheduledFunction } from '../../../domain/product-module-definition-scheduled-function';

export class ProductModuleDefinitionScheduledFunctionStore {
  @observable isLoading = false;
  @observable error?: Error;
  @observable productModuleDefinitionScheduledFunctions?: ProductModuleDefinitionScheduledFunction[];

  init = async (params: { productModuleId: string; productModuleDefinitionId: string }) => {
    const { productModuleDefinitionId, productModuleId } = params;

    try {
      this.isLoading = true;

      this.productModuleDefinitionScheduledFunctions = await getProductModuleDefinitionScheduledFunctions({
        productModuleId,
        productModuleDefinitionId,
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !!(
      this.isLoading === false &&
      this.productModuleDefinitionScheduledFunctions &&
      this.productModuleDefinitionScheduledFunctions.length === 0
    );
  }

  setLoading = () => {
    this.isLoading = true;
  };
}

export const productModuleDefinitionScheduledFunctionStore = new ProductModuleDefinitionScheduledFunctionStore();
