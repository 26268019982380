import * as React from 'react';

export const Icon16PXChevron = props => {
  const rotate =
    props && props.rotate ? 'rotate(180deg)' : 'rotate(0deg)';
  return (
    <svg
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      style={{ transform: rotate, transition: '0.4s' }}
    >
      <path
        fill={props.fill || '#7A7A7A'}
        fillRule='evenodd'
        d='M11.273 5.372a1 1 0 0 1 1.454 1.373l-4 4.236a1 1 0 0 1-1.454 0l-4-4.236a1 1 0 1 1 1.454-1.373L8 8.838l3.273-3.466z'
      />
    </svg>
  );
};
