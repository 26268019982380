import React, { Component } from 'react';
import {
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ModalFooter,
  Alert,
  ModalHeader,
} from 'reactstrap';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import { FullScreenModal } from '../../../../components/modals/full-screen';
import '../../../styles/code-window.scss';
import '../../../styles/styles.scss';
import { Icon24PXCross } from '../../../../components/icons/icon-24-px-cross';
import { GeneralModal } from '../modals';
import { mergeVarStubs } from '../../../../organizations/components/outbound-notifications/merge-var-stubs';
import DynamicForm from './dynamic-form';
import { FormStore } from './formStore';
import {
  establishState,
} from './orchestration';
import CodeWindow from '../../../utils/code-editor/code-editor';
import { FormStoreModelList } from './dynamic-form/interfaces';
import { observer, inject } from 'mobx-react';
import { ProductModuleStore, ProductModuleDefinitionErrors } from '../stores/product-module-store';
import LoadingInPage from '../../../loading';
import { ClaimBlocks } from './claim-blocks-schema/views/claim-blocks';
import { BlockStore } from './claim-blocks-schema/stores/block-store';
import { debounce } from '../../../../helpers/debounce';
import { ProductModuleDefinitionAlterationHooksListStore } from '../stores/product-module-definition-alteration-hooks-list-store';

interface ProductModuleJsonHtmlImpl {
  modalContent: any;
  formStore: FormStore;
  blockStore: BlockStore;
  syncValidity?: (valid: boolean) => void;
}

interface State {
  editorKey: number;
  showResetToPublished: boolean;
  saving: boolean;
  loadingFormStoreModel: boolean;
  errorMessages: ProductModuleDefinitionErrors[];
  showSaveModal: boolean;
  templateJsonOriginal: string;
  templateBase64: string;
  showArchiveModal: boolean;
  contentChanged: boolean;
  mergeVars: object;
  error?: any;
}

interface Props {
  productModuleDefinitionSchemaId: string;
  toDbKey: string;
  productModuleKey: string;
  breadcrumbDocName: string;
  closeDocument: () => any;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  productModuleDefinitionAlterationHooksListStore: ProductModuleDefinitionAlterationHooksListStore;
}

@inject('productModuleStore', 'productModuleDefinitionAlterationHooksListStore')
@observer
class ProductModuleJsonHtmlImpl extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editorKey: 0,
      showResetToPublished: true,
      saving: false,
      loadingFormStoreModel: true,
      errorMessages: [],
      showSaveModal: false,
      templateJsonOriginal: JSON.stringify([{}]),
      templateBase64: '',
      showArchiveModal: false,
      mergeVars: mergeVarStubs.policyholder,
      contentChanged: false,
    };
    const data: FormStoreModelList[] = [];
    this.formStore = new FormStore();
    this.formStore.updateModel(data);
    this.blockStore = new BlockStore();
    this.modalContent = {
      title: 'Schema Saved',
      body: 'Your schema has successfully been saved',
      submit: 'Ok',
    };
  }

  get injected() {
    return this.props as Injected;
  }

  onSubmit = (model: any) => {
    alert(JSON.stringify(model));
  };

  handleCodeChange = async (code: any) => {
    if (code.replace(/\s/g, '') !== this.state.templateJsonOriginal) {
      if (!this.state.contentChanged) {
        this.setState({ contentChanged: true });
      }
    } else {
      if (this.state.contentChanged) {
        this.setState({ contentChanged: false });
      }
    }

    this.props.toDbKey === 'claim_blocks_schema_json' ?
      this.updateBlocks(code) :
      this.updateDynamicSchemas(code);
  }

  updateDynamicSchemas = (code: string) => {
    try {
      const parsedData = JSON.parse(code);
      this.formStore.updateModel(parsedData);
      this.setState({ errorMessages: [] });
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  updateBlocks = (code: string) => {
    try {
      const parsedData = JSON.parse(code);
      debounce('updating-claim-blocks', async () => {
        await this.blockStore.init(parsedData);
        const errorMessages = [{
          message: this.blockStore.error,
          path: this.blockStore.error,
        }];
        this.setState({ errorMessages: this.blockStore.error ? errorMessages : [] });
      });
    } catch (error) {
      this.setState({
        errorMessages: [{
          message: error.message,
          path: error.message,
        }],
      });
    }
  }

  async componentDidMount() {
    const { productModuleStore, productModuleDefinitionSchemaId, productModuleKey, toDbKey } = this.injected;
    this.setState({ loadingFormStoreModel: true });

    await productModuleStore.init(productModuleKey);

    const productModuleDefinition =
      productModuleStore.productModuleDefinitionDraft;

    const state = await establishState(productModuleDefinition, productModuleDefinitionSchemaId);

    if (state && toDbKey === 'claim_blocks_schema_json') {
      await this.blockStore.init(state.templateJson);
    }
    else if (state) {
      this.formStore.updateModel(state.templateJson as any);
    }

    const editorKey = this.state.editorKey + 1;
    this.setState({ editorKey, loadingFormStoreModel: false });
  }

  closeArchiveModal = () => {
    this.setState({ showArchiveModal: false });
  };

  closeSaveModal = () => {
    this.setState({ showSaveModal: false });
  };

  submitModal = () => {
    this.closeSaveModal();
  };

  saveDraftMethod = async () => {
    const { productModuleStore, toDbKey } = this.injected;
    this.setState({ saving: true });
    const productModule = productModuleStore.productModule;

    const data = {
      [toDbKey]:
        toDbKey === 'claim_blocks_schema_json'
          ? this.blockStore.unparsedBlocksSchema
          : this.formStore.model,
    };

    await productModuleStore.createProductModuleDefinition({
      productModuleId: productModule.productModuleId,
      data,
    });

    if (productModuleStore.errors.length > 0) {
      return this.setState({
        errorMessages: productModuleStore.errors,
        saving: false,
      });
    } else {
      this.setState({ showSaveModal: true });
    }

    this.setState({
      saving: false,
    });
  };

  saveAlterationHooks = async () => {
    const { productModuleDefinitionAlterationHooksListStore, productModuleDefinitionSchemaId, productModuleStore, toDbKey } = this.injected;
    const { productModuleDefinitionAlterationHooks } = productModuleDefinitionAlterationHooksListStore;

    this.setState({ error: undefined });

    try {
      const productModuleDefinitionAlterationHookFormData = productModuleDefinitionAlterationHooks.map(({ name, key, productModuleDefinitionSchema }) => {
        if (productModuleDefinitionSchema.productModuleDefinitionSchemaId === productModuleDefinitionSchemaId) {
          return {
            schema_json: this.formStore.model,
            key,
            name,
          };
        }
        return {
          schema_json: productModuleDefinitionSchema.jsonContent,
          key,
          name,
        };
      });

      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: {
          [toDbKey]: productModuleDefinitionAlterationHookFormData,
        },
      });

      await productModuleDefinitionAlterationHooksListStore.init({
        productModuleId: productModuleStore.productModule.productModuleId,
        productModuleDefinitionId: productModuleStore.productModuleDefinitionDraft.productModuleDefinitionId,
      });

      if (productModuleStore.errors.length > 0) {
        return this.setState({
          errorMessages: productModuleStore.errors,
          saving: false,
        });
      } else {
        this.setState({ showSaveModal: true });
      }
    } catch (error) {
      this.setState({ error });
    }

    this.setState({
      saving: false,
    });
  }

  renderPreview = () => {
    if (this.props.toDbKey === 'claim_blocks_schema_json') {
      return <ClaimBlocks blockStore={this.blockStore} />;
    }

    return <DynamicForm
      className='form row'
      formStore={this.formStore}
      onSubmit={model => this.onSubmit(model)}
    />;
  }

  get defaultValue() {
    if (this.props.toDbKey === 'claim_blocks_schema_json') {
      return JSON.stringify(this.blockStore.blocksSchema);
    }

    return JSON.stringify(this.formStore.model);
  }

  render() {
    const {
      productModuleStore,
      breadcrumbDocName,
      productModuleKey,
    } = this.injected;
    if (
      !productModuleStore.isLoaded ||
      this.state.loadingFormStoreModel
    ) {
      return <LoadingInPage />;
    }

    const lockedComponent = productModuleStore.lockedComponent;

    return (
      <FullScreenModal isOpen={true}>
        <GeneralModal
          show={this.state.showSaveModal}
          onclose={this.closeSaveModal}
          submitModal={this.submitModal}
          modalContent={this.modalContent}
        />
        <ModalHeader style={{ paddingBottom: 19 }}>
          <div
            className='Rectangle'
            style={{ display: 'inline-block', paddingTop: 7 }}
          >
            <a
              onClick={e => {
                e.preventDefault;
                this.props.closeDocument();
              }}
            >
              <Icon24PXCross />
            </a>
          </div>
          <div style={{ display: 'inline-block', paddingTop: 17 }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href='/product-modules'>
                  Product modules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  href={`/product-modules/${productModuleKey}`}
                >
                  {productModuleStore.productModule.name}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a
                  className='breadcrumb-a-tag-link'
                  onClick={e => {
                    e.preventDefault;
                    this.props.closeDocument();
                  }}
                >
                  Product definition
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>{breadcrumbDocName}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </ModalHeader>
        <ModalBody className='zero-padding'>
          <div className='template-editor' onKeyDown={(event) => {
            if ((window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) && event.keyCode === 83) {
              event.preventDefault();
              this.setState({ showSaveModal: true }, () => {
                const { toDbKey } = this.injected;
                toDbKey !== 'alteration_hooks' ? this.saveDraftMethod() : this.saveAlterationHooks();
              });
            }
          }}>
            <div className='template-editor-error-message'>
              {this.state.errorMessages && (
                this.state.errorMessages.map(({ message, path }, index) =>
                  <Alert key={`product-module-defintion-error-message-${index}`} color='danger'>{`Path: ${path}, Message: ${message}`}</Alert>,
                )
              )}
            </div>
            {this.state.error && <Alert color='danger'>{(this.state.error as any).message}</Alert>}
            {!this.state.loadingFormStoreModel && (
              <CodeWindow
                key='Code Window'
                defaultValue={this.defaultValue}
                language='json'
                toDbKey={this.props.toDbKey}
                handleCodeChange={(code: any) => this.handleCodeChange(code)}
              />
            )}
            <div
              className='template-editor template-preview'
              style={{ justifyContent: 'center', backgroundColor: 'white' }}
            >
              <div style={{ flexDirection: 'column', flex: 1 }}>
                <div className='dynamic-form-parent'>
                  <div className='dynamic-form-parent'>{this.renderPreview()}</div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='right-content'>
            <Button
              disabled={lockedComponent || this.state.errorMessages.length > 0}
              color='secondary'
              onClick={async () => {
                const { toDbKey } = this.injected;
                toDbKey !== 'alteration_hooks' ? this.saveDraftMethod() : this.saveAlterationHooks();
              }}
            >
              {this.state.saving ? 'Saving...' : 'Save draft'}
            </Button>
          </div>
        </ModalFooter>
      </FullScreenModal>
    );
  }
}

export const ProductModuleJsonHtml = ProductModuleJsonHtmlImpl;
