import React from 'react';
import { CardBody, Button, FormGroup, Form, Row, Col } from 'reactstrap';
import '../../../../../../../../styles/styles.scss';
import { observer, inject } from 'mobx-react';
import '../../styles/general.scss';
import { Store, ObservablesTemp, Observables } from '../../store';
import CoolingOffPeriodRule from './components/cooling-off-period-rule';
import { flattenObject, displayBasedOnCount } from '../../utils';
import { FormOptions } from './config';
import { savePolicyLifecycle } from '../../orchestration';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../../../../stores/product-module-component-loading-state-store';
import { ProductModuleStore } from '../../../../../../stores/product-module-store';
import { nestedStyleCheckbox } from '../../../../utils';
import { debounce } from '../../../../../../../../../helpers/debounce';
import { SavingState } from '../../../../../../util';

enum enumOfState {
  countRules = 'countRules',
  countNotifications = 'countNotifications',
}

interface Props {
  store: Store;
  productModuleStore?: ProductModuleStore;
  closeWindow: () => void;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

interface State {
  countRules: number;
  countNotifications: number;
}

const coolingOffPeriodKey = 'coolingOffPeriod';

@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
class CoolingOffPeriod extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      countRules: FormOptions.startingCoolingOffPeriodRules,
      countNotifications: 0,
    };
  }

  componentWillMount() {
    const { store, productModuleComponentLoadingStateStore } = this.props;
    store.updatePrimaryToTemp(
      Observables.coolingOffPeriodRules,
      ObservablesTemp.coolingOffPeriodRulesTemp,
    );
    this.setState({
      countRules:
        Object.keys(store.lifecycleStoreTemp.coolingOffPeriodRulesTemp)
          .length || FormOptions.startingCoolingOffPeriodRules,
    });
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.addComponentToStore(
        StoreIndex.policyLifecycleComponents,
        coolingOffPeriodKey,
      );
    }
  }

  saveAndClose = async () => {
    const {
      store,
      closeWindow,
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;
    store.updateTempToPrimary(
      Observables.coolingOffPeriodRules,
      ObservablesTemp.coolingOffPeriodRulesTemp,
    );
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        coolingOffPeriodKey,
        SavingState.Saving,
      );
    }
    if (productModuleStore) {
      await savePolicyLifecycle(
        store.lifecycleStore,
        productModuleStore.productModuleDefinitionDraft,
      );
      productModuleStore.init(productModuleStore.loadedProductModuleKey, true);
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        coolingOffPeriodKey,
        SavingState.Saved,
      );

      debounce(
        coolingOffPeriodKey,
        () => {
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.policyLifecycleComponents,
            coolingOffPeriodKey,
            SavingState.Default,
          );
        },
        3000,
      );
    }
    closeWindow();
  };

  cancelAndClose = () => {
    const { store, closeWindow } = this.props;
    store.updatePrimaryToTemp(
      Observables.coolingOffPeriodRules,
      ObservablesTemp.coolingOffPeriodRulesTemp,
    );
    closeWindow();
  };

  showSaveButton = (store: Store) => {
    let valid = true;
    const { lifecycleStoreTemp } = store;
    const { coolingOffPeriodRulesTemp } = lifecycleStoreTemp;
    const coolingOffPeriodRulesTempFlat = flattenObject(
      coolingOffPeriodRulesTemp,
    );

    for (const key in coolingOffPeriodRulesTempFlat) {
      if (!coolingOffPeriodRulesTempFlat[key]) {
        return (valid = false);
      }
    }
    return valid;
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      countRules:
        Object.keys(
          nextProps.store.lifecycleStoreTemp.coolingOffPeriodRulesTemp,
        ).length || FormOptions.startingCoolingOffPeriodRules,
    });
  }

  addRule = (countState: enumOfState) => {
    let countTemp: number = this.state[countState];
    countTemp++;
    this.setState({ [countState]: countTemp } as Pick<State, keyof State>);
  };

  reduceCount = (countState: enumOfState) => {
    let count = this.state[countState];
    count--;
    this.setState({ [countState]: count } as Pick<State, keyof State>);
  };

  renderChildrenRules = (count: number) => {
    const components = [];
    const { store } = this.props;

    for (let i = 0; i < count; i++) {
      components.push(
        <CoolingOffPeriodRule
          index={i}
          allowList={FormOptions.allowListRules}
          reduceCount={() => this.reduceCount(enumOfState.countRules)}
          key={`${CoolingOffPeriodRule}_${i}`}
          store={this.props.store}
          removeFromLifeCycleStore={(
            lifecycleObservable: ObservablesTemp,
            index: number,
          ) => store.removeFromLifeCycleStore(lifecycleObservable, index)}
        />,
      );
    }
    return components;
  };

  renderChildrenNotifications = (count: number) => {
    // const components = [];
    // const { store } = this.props;
    // for (let i = 0; i < count; i++) {
    //   components.push(
    //     <CoolingOffPeriodNotification
    //       index={i + 1}
    //       allowList={FormOptions.allowListNotifications}
    //       reduceCount={() => this.reduceCount(enumOfState.countNotifications)}
    //       key={`${CoolingOffPeriodNotification}_${i}`}
    //       store={this.props.store}
    //       coolingOffPeriodNotificationsTemp={null}
    //       updateLifeCycleStore={(
    //         lifecycleObservable: ObservablesTemp,
    //         path: string,
    //         value: string,
    //         index: number,
    //       ) => store.updateLifeCycleStore(lifecycleObservable, path, value, index)}
    //       removeFromLifeCycleStore={(lifecycleObservable: ObservablesTemp, index: number) =>
    //         store.removeFromLifeCycleStore(lifecycleObservable, index)
    //       }
    //     />,
    //   );
    // }
    // return components;
  };

  render() {
    const allowListRules = FormOptions.allowListRules;
    const allowListNotifications = FormOptions.allowListNotifications;
    const showNotifications = FormOptions.showNotifications;
    const { countRules, countNotifications } = this.state;

    return (
      <div className='product-module-definition-lifecycle-rules-position'>
        <p className='product-module-heading-description-spacing'>
          An initial period in which a policyholder can cancel a policy and
          receive a refund.
        </p>

        <Row>
          <Col xs={12}>
            <CardBody style={nestedStyleCheckbox(true, 1)}>
              <h5
                style={{
                  fontWeight: 'bold',
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                Cooling off period rules
              </h5>
              <Form>
                {this.renderChildrenRules(countRules)}
                {
                  <div>
                    <FormGroup row style={{ marginLeft: -7 }}>
                      <Button
                        onClick={() => this.saveAndClose()}
                        color='primary'
                        className='product-module-definition-lifecycle-component-editing-buttons'
                        disabled={!this.showSaveButton(this.props.store)}
                      >
                        Done
                      </Button>
                      {allowListRules &&
                        displayBasedOnCount(
                          this.state.countRules,
                          FormOptions.maxCountCoolingOffPeriodRules,
                        ) && (
                        <Button
                          className='product-module-definition-lifecycle-component-editing-buttons'
                          color='link'
                          onClick={() => this.addRule(enumOfState.countRules)}
                        >
                          + Add rule
                        </Button>
                      )}
                      <Button
                        className='product-module-definition-lifecycle-component-editing-buttons'
                        onClick={() => this.cancelAndClose()}
                        color='link'
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                  </div>
                }
              </Form>
              {showNotifications && <hr />}
              {showNotifications && (
                <Form>
                  <FormGroup>
                    <h5>Notifications</h5>
                  </FormGroup>
                  {this.renderChildrenNotifications(countNotifications)}
                  {allowListNotifications && (
                    <div style={{ marginTop: 0 }}>
                      <FormGroup row>
                        <Button
                          color='link'
                          className='add-notification product-module-definition-lifecycle-component-editing-buttons'
                          onClick={() =>
                            this.addRule(enumOfState.countNotifications)
                          }
                        >
                          + Add rule
                        </Button>
                        <Button
                          onClick={() => this.saveAndClose()}
                          color='primary'
                          className='product-module-definition-lifecycle-component-editing-buttons'
                          disabled={!this.showSaveButton(this.props.store)}
                        >
                          Done
                        </Button>
                        <Button
                          onClick={() => this.cancelAndClose()}
                          color='link'
                          className='product-module-definition-lifecycle-component-editing-buttons'
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </div>
                  )}
                </Form>
              )}
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CoolingOffPeriod;
