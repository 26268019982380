import React from 'react';
import { observer } from 'mobx-react';
import { History } from './data-export-history';
import { Details } from './data-export-details';
import { History as RouterHistory } from 'history';

interface Props {
  params: any;
  router: RouterHistory;
}

@observer
export class DataExportView extends React.Component<Props> {
  render() {
    return <div>
      <Details
        organizationId={this.props.params.organizationId}
        router={this.props.router}
        isDefault={false}
      />
      <History
        organizationId={this.props.params.organizationId} />
    </div>;
  }
}
