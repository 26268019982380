import React from 'react';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
} from 'reactstrap';
import '../../styles/styles.scss';
import PageHeader from '../../../components/page-header';
import '../../../stylesheets/_sidebar.scss';
import classnames from 'classnames';
import ProductModuleDefinitionSettings from './views/product-module-definition-settings';
import ProductModuleDefinitionPricing from './views/product-module-definition-pricing';
import ProductModuleDefinitionDocuments from './views/product-module-definition-documents';
import ProductModuleDefinitionClaims from './views/product-module-definition-claims';
import _ from 'lodash';
import { ProductModuleStore } from './stores/product-module-store';
import { inject, observer } from 'mobx-react';
import LoadingInPage from '../../loading';
import { RouteComponentProps } from 'react-router';
import ProductModuleDefinitionBilling from './views/product-module-definitions-billing';

export enum ActiveTab {
  Settings = 'settings',
  Pricing = 'pricing',
  Documents = 'documents',
  Claims = 'claims',
  // Notifications = 'notifications',
  Billing = 'billing',
}

interface Props {
  productModuleStore: ProductModuleStore;
  router: RouteComponentProps;
  params: {
    productModuleKey: string;
  };
  route: RouteComponentProps;
}

interface State {
  activeTab: ActiveTab;
  modulesCount: number;
}

const tabsConfig: any = {
  Settings: {
    active: ActiveTab.Settings,
  },
  Pricing: {
    active: ActiveTab.Pricing,
  },
  Documents: {
    active: ActiveTab.Documents,
  },
  Claims: {
    active: ActiveTab.Claims,
  },
  // Notifications: {
  //   active: ActiveTab.Notifications,
  // },
  Billing: {
    active: ActiveTab.Billing,
  },
};

@inject('productModuleStore')
@observer
export default class ProductDefinitionParent extends React.Component<
Props,
State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      modulesCount: 0,
      activeTab: ActiveTab.Settings,
    };
  }

  tabNav = (state: any) => {
    return Object.keys(tabsConfig).map((tab: any) => {
      const active = state.activeTab === tabsConfig[tab].active;
      const color = active ? 'black' : '#7a7a7a';
      return (
        <NavItem key={_.snakeCase(tab)}>
          <NavLink
            style={{ color }}
            className={classnames({
              active,
            })}
            onClick={() => {
              this.toggle(tabsConfig[tab].active);
            }}
          >
            {tab}
          </NavLink>
        </NavItem>
      );
    });
  };

  async componentWillMount() {
    const { params, productModuleStore } = this.props;

    if (productModuleStore) {
      await productModuleStore.init(params.productModuleKey);
    }
  }

  productModuleKey = () => {
    if (this.props.params && this.props.params.productModuleKey) {
      return this.props.params.productModuleKey;
    }
    return null;
  };

  toggle = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { params, router, route, productModuleStore } = this.props;
    if (
      !productModuleStore ||
      !productModuleStore.productModule ||
      !productModuleStore.productModuleDefinitionDraft ||
      !params.productModuleKey
    ) {
      return <LoadingInPage />;
    }
    const {
      productModuleDefinitionDraft,
      productModule,
      lockedComponent,
    } = productModuleStore;

    const liveModule = !!(
      productModuleStore.productModule &&
      productModuleStore.productModule.liveId ===
        productModuleDefinitionDraft.productModuleDefinitionId
    );

    return (
      <div>
        <SidebarSecondary
          router={router}
          route={route}
          subRoutes={this.sidebarSchemaRender().routes()}
          title={productModule.name}
        />
        <div className='container-with-sidebar'>
          <Container className='container-positioning'>
            <Breadcrumb>
              <BreadcrumbItem>
                <a className='breadcrumb-a-tag-link' href='/product-modules'>
                  Product modules
                </a>
              </BreadcrumbItem>
              {this.productModuleKey() && (
                <BreadcrumbItem>
                  <a
                    className='breadcrumb-a-tag-link'
                    href={`/product-modules/${params.productModuleKey}`}
                  >
                    {productModuleStore.productModule.name}
                  </a>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem active>Product definition</BreadcrumbItem>
            </Breadcrumb>
            <div style={{ marginLeft: '1.5rem', paddingTop: '1.5rem' }}>
              <PageHeader title={'Product definition'} />
            </div>
            <CardBody>
              {!lockedComponent && (
                <div className='arrow-right-border-fill completed-card-background-color-grey inline-div-with-padding-left-right'>
                  Latest draft
                </div>
              )}
              {liveModule && (
                <div className='arrow-right-border-fill completed-card-background-color-green inline-div-with-padding-left-right'>
                  Live module
                </div>
              )}
              <div className='inline-div-with-padding-left-right'>
                <h5>
                  Version {productModuleDefinitionDraft.versionMajor}.
                  {productModuleDefinitionDraft.versionMinor}
                </h5>
              </div>
            </CardBody>
            <CardBody>
              <Nav tabs>{this.tabNav(this.state)}</Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId={ActiveTab.Settings}>
                  <ProductModuleDefinitionSettings
                    productModuleKey={this.props.params.productModuleKey}
                  />
                </TabPane>
                <TabPane tabId={ActiveTab.Pricing}>
                  <ProductModuleDefinitionPricing
                    productModuleKey={this.props.params.productModuleKey}
                  />
                </TabPane>
                <TabPane tabId={ActiveTab.Documents}>
                  <ProductModuleDefinitionDocuments
                    productModuleKey={this.props.params.productModuleKey}
                  />
                </TabPane>
                <TabPane tabId={ActiveTab.Claims}>
                  <ProductModuleDefinitionClaims
                    productModuleKey={this.props.params.productModuleKey}
                  />
                </TabPane>
                <TabPane tabId={ActiveTab.Billing}>
                  <ProductModuleDefinitionBilling
                    productModuleKey={this.props.params.productModuleKey}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Container>
        </div>
      </div>
    );
  }
  sidebarSchemaRender = () => {
    const { productModuleKey } = this.props.params;
    return {
      routes: () => {
        return [
          {
            name: 'Overview',
            url: `/product-modules/${productModuleKey}`,
          },
          {
            name: 'Deployment',
            url: `/product-modules/${productModuleKey}/deployment`,
          },
          {
            name: 'Version history',
            url: `/product-modules/${productModuleKey}/version-history`,
          },
          {
            name: 'Run logs',
            url: `/product-modules/${productModuleKey}/runs`,
          },
        ];
      },
    };
  };
}
