import React from 'react';
import ProductModuleDefinitionSettingsBilling from './components/billing';

interface Props {
  productModuleKey: string;
}

export default class ProductModuleDefinitionBilling extends React.Component<
Props,
any
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <ProductModuleDefinitionSettingsBilling
          productModuleKey={this.props.productModuleKey}
        />
      </div>
    );
  }
}
