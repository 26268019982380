import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Icon24PXCross } from '../../../../../components/icons/icon-24-px-cross';

interface Props {
  isOpen: boolean;
  close: () => void;
  dismiss: () => void;
  dataValid: boolean;
  submit: () => Promise<void>;
}

export const ConfirmExitTemplateModal: React.SFC<Props> = props => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.close} className='exports-prompt-modal'>
      <ModalHeader className='exports-prompt-modal-header'>
        <Icon24PXCross onClick={props.dismiss} />
        <h4 className='exports-prompt-modal-header-text'>Exit template editing.</h4>
      </ModalHeader>
      <ModalBody className='exports-prompt-modal-body'>
        <p>
            Would you like to save changes to this custom export template?
        </p>
      </ModalBody>
      <ModalFooter className='exports-prompt-modal-footer'>
        <Button
          color='primary'
          disabled={!props.dataValid}
          onClick={() => {
            if (props.dataValid) {props.submit(); }
            props.close();
          }}>
            Save
        </Button>
        <Button color='link' onClick={props.close}>
            Discard changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
