import * as React from 'react';
interface Icon16PXCrossProps {
  fill?: string;
  style?: any;
  className?: string;
}

export const Icon16PXCross: React.SFC<Icon16PXCrossProps> = (
  props: Icon16PXCrossProps,
) => (
  <svg
    className={props.className}
    style={{ ...props.style }}
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
  >
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h16v16H0z' />
      <path fill='#FD3238' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0' />
      <path d='M0 0h16v16H0z' />
      <path
        fill='#FFF'
        d='M9.414 8l2.122 2.121a1 1 0 0 1-1.415 1.415L8 9.414l-2.121 2.122a1 1 0 0 1-1.415-1.415L6.586 8 4.464 5.879A1 1 0 1 1 5.88 4.464L8 6.586l2.121-2.122a1 1 0 1 1 1.415 1.415L9.414 8z'
      />
    </g>
  </svg>
);
