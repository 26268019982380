import { observable } from 'mobx';
import { Organization } from '../domain/organization';
import { OrganizationApi } from '../organization-api';

export class OrganizationViewStore {
  @observable organization?: Organization;
  @observable isLoading: boolean = true;

  load = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    this.isLoading = true;
    try {
      this.organization = await OrganizationApi.getOrganizationById({
        organizationId,
      });

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  }

  updateOrganization = async (params: { organizationId: string; data: object }) => {
    const { data, organizationId } = params;
    try {
      this.organization = await OrganizationApi.updateOrganization({
        organizationId,
        data,
      });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  };

  setOrganizationIsLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const organizationViewStore = new OrganizationViewStore();
