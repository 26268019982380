import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Card, CardBody, Popover, PopoverBody } from 'reactstrap';
import { PauseDataExportModal } from './modals/pause-data-export-modal';
import { ResumeDataExportModal } from './modals/resume-data-export-modal';
import { TriggerDataExportModal } from './modals/trigger-data-export-modal';
import { DefaultExportTemplates, Template } from '../domain/templates/template';
import { NewExportModal } from './modals/new-export-modal';
import { TemplateListStore } from '../stores/template-list-store';
import { TemplateViewStore } from '../stores/template-view-store';
import { DataExportViewStore } from '../stores/data-export-view-store';
import { History } from 'history';
import Images from '../../../../components/svg-images';
import { SFTPAuthenticationMethod } from '../domain/data-export-adapter';
import { SFTPAdapter, HTTPSAdapter, isSFTPDestinationData, ScheduledDataExportAdapterType, isHTTPSDestinationData, isS3DestinationData, S3Adapter } from '../domain/scheduled-data-export-adapter';
import { ScheduledDataExport, ScheduledDataExportStatus } from '../domain/scheduled-data-export';
import { isMonthlyFrequency, isWeeklyFrequency } from '../domain/scheduled-data-export-frequency';
import { ExportType } from '../domain/export-type';
import uuid from 'uuid';
import { ArchiveDataExportModal } from './modals/archive-data-export-modal';

interface Props {
  organizationId: string;
  isDefault: boolean;
  router: History;
}

interface Injected extends Props {
  templateListStore: TemplateListStore;
  templateViewStore: TemplateViewStore;
  dataExportViewStore: DataExportViewStore;
}

interface State {
  showPauseModal: boolean;
  showResumeModal: boolean;
  showArchiveModal: boolean;
  showEditModal: boolean;
  showTriggerModal: boolean;
  popoverOpen: boolean;
}

@inject(
  'templateViewStore',
  'templateListStore',
  'dataExportViewStore',
)
@observer
export class Details extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPauseModal: false,
      showResumeModal: false,
      showArchiveModal: false,
      showEditModal: false,
      showTriggerModal: false,
      popoverOpen: false,
    };

    this.injected.dataExportViewStore.setDataExportViewStoreLoadingTrue();
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { dataExportViewStore, organizationId, templateViewStore } = this.injected;
    await dataExportViewStore.load({
      scheduledDataExportId: window.location.pathname.split('/')[4],
      organizationId,
    });

    const { dataExport } = dataExportViewStore;
    if (this.isCustomExport && dataExport && dataExport.templateId) {
      templateViewStore.load({
        organizationId,
        templateId: dataExport.templateId,
      });
    }
    else {
      templateViewStore.clear();
    }
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }


  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  renderCard(params: { dataExport?: ScheduledDataExport; template?: Template | DefaultExportTemplates }) {
    const { dataExport, template } = params;
    const { dataExportViewStore, templateViewStore } = this.injected;
    const isLoading = dataExportViewStore.isLoading || (this.isCustomExport && templateViewStore.isLoading);

    const uniqueId = dataExport && dataExport.scheduledDataExportId || uuid().toString();

    if (isLoading) {
      return <div>loading ...</div>;
    }

    return (
      <div>
        <Card className='template-detail-card'>
          <CardBody style={{ verticalAlign: 'middle', display: 'flex' }}>
            <h5>{dataExport && dataExport.name}</h5>
            <Button
              color='primary'
              disabled={isLoading}
              style={{ width: '10%' }}
              onClick={() =>
                this.setState({ showEditModal: true, popoverOpen: false })
              }
            >
              Edit
            </Button>
            <Button
              outline
              color='secondary'
              className='ellipsisButton'
              id={`template-popover-${uniqueId}`}
              type='button'
              onClick={this.toggle}
              disabled={this.props.isDefault || isLoading}
            >
              &#183;&#183;&#183;
            </Button>
            <Popover
              className='popoverBox'
              placement='right'
              disabled={isLoading}
              isOpen={this.state.popoverOpen}
              target={`template-popover-${uniqueId}`}>
              <PopoverBody>
                <Button
                  className='popoverListItem'
                  outline
                  color='secondary'
                  disabled={isLoading}
                  onClick={() =>
                    this.setState({
                      showTriggerModal: true,
                    }, () => this.toggle())
                  }>
                  Run now
                </Button>

                {dataExport && dataExport.status === ScheduledDataExportStatus.Active && (
                  <Button
                    className='popoverListItem'
                    outline
                    color='secondary'
                    disabled={isLoading}
                    onClick={() =>
                      this.setState({
                        showPauseModal: true,
                      }, () => this.toggle())
                    }>
                    Pause job
                  </Button>
                )}

                {dataExport && dataExport.status === ScheduledDataExportStatus.Paused && (
                  <Button
                    className='popoverListItem'
                    outline
                    color='secondary'
                    disabled={isLoading}
                    onClick={() =>
                      this.setState({
                        showResumeModal: true,
                      }, () => this.toggle())
                    }>
                    Resume job
                  </Button>
                )}

                <hr />
                <Button
                  className='popoverListItem'
                  outline
                  color='danger'
                  disabled={isLoading}
                  onClick={() =>
                    this.setState({
                      showArchiveModal: true,
                    }, () => this.toggle)
                  }>
                  Archive
                </Button>
              </PopoverBody>
            </Popover>
          </CardBody>
        </Card>
        <Card
          className='template-detail-card'
          style={{ borderTop: 'none', borderRadius: '0 0 4px 4px' }}
        >
          <CardBody>
            <table className='data-export-details-table'>
              <tbody>
                {template && template.templateId && <tr>
                  <td>
                    <strong>Data template</strong>
                  </td>
                  <td>
                    {this.displayDataTemplateRowItem()}
                  </td>
                </tr>}
                <tr>
                  <td>
                    <strong>Data source</strong>
                  </td>
                  <td>
                    {template && !isLoading && template.dataSource}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Environment</strong>
                  </td>
                  <td>
                    {dataExport && dataExport.prettyEnvironment() || '-'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Included data range</strong>
                  </td>
                  <td>
                    {dataExport && dataExport.dataRange || ''}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Export intervals</strong>
                  </td>
                  <td>
                    {dataExport && dataExport.prettyFrequencyType() || '-'}
                  </td>
                </tr>
                {dataExport && isMonthlyFrequency(dataExport.frequency) &&
                  <tr>
                    <td>
                      <strong>Day of month</strong>
                    </td>
                    <td>
                      {dataExport.frequency.dayOfMonth}
                    </td>
                  </tr>
                }
                {dataExport && isWeeklyFrequency(dataExport.frequency) &&
                  <tr>
                    <td>
                      <strong>Day of month</strong>
                    </td>
                    <td>
                      {dataExport.frequency.dayOfWeek}
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    <strong>Time of day</strong>
                  </td>
                  <td>
                    {dataExport && dataExport.frequency.timeOfDay || '-'}
                  </td>
                </tr>
                {dataExport && <tr>
                  <td>
                    <strong>Permissions</strong>
                  </td>
                  <td>
                    <span style={{ padding: '5px' }}>{dataExport.restricted ? <Images.lock/> : <Images.success/>}</span>
                    {dataExport.restricted ? 'Root-admin only editing' : 'Open to editing'}
                  </td>
                </tr>}
              </tbody>
            </table>
            {dataExport && <h5 style={{ margin: '2rem 0 0.5rem 0' }}>{dataExport.adapter.type === ScheduledDataExportAdapterType.SFTP ? 'SFTP' :
              dataExport.adapter.type === ScheduledDataExportAdapterType.HTTPS ? 'HTTPS' :
                dataExport.adapter.type === ScheduledDataExportAdapterType.S3 ? 'S3' : ''} configuration</h5>}
            <table className='data-export-details-table'>
              {dataExport && isSFTPDestinationData(dataExport.adapter) && renderSFTPAdapter(dataExport.adapter)}
              {dataExport && isHTTPSDestinationData(dataExport.adapter) && renderHTTPSAdapter(dataExport.adapter)}
              {dataExport && isS3DestinationData(dataExport.adapter) && renderS3Adapter(dataExport.adapter)}
            </table>
          </CardBody>
        </Card>
      </div>
    );
  }

  displayDataTemplateRowItem = () => {
    const { templateViewStore } = this.injected;

    const { template, isLoading } = templateViewStore;
    if (template && template.templateId) {
      return (
        <Button
          style={{ marginLeft: '0px' }}
          color='link'
          onClick={() => {
            this.props.router.push(
              `/organizations/${this.organizationId}/exports/templates/${template.templateId}`,
            );
          }}
        >
          {template && !isLoading && template.templateName}
        </Button>
      );
    }
    return <>
      {template && !isLoading && template.templateName}
    </>;
  }

  get isCustomExport() {
    const { dataExportViewStore } = this.injected;
    return dataExportViewStore.dataExport && dataExportViewStore.dataExport.exportType === ExportType.CustomExport;
  }

  render() {
    const { dataExportViewStore, templateViewStore } = this.injected;
    const { dataExport } = dataExportViewStore;

    let template: Template | undefined | DefaultExportTemplates;
    if (this.isCustomExport) {
      template = templateViewStore.template;
    } else {
      template = DefaultExportTemplates.find(d => d.exportType === (dataExport && dataExport.exportType));
    }
    return (
      <div className='section no-section-divider'>
        <h2 className='export-details-page-header'>Export details</h2>

        {this.renderCard({ dataExport, template })}

        {dataExport && dataExport.status !== ScheduledDataExportStatus.Archived &&
          this.state.showEditModal &&
          <NewExportModal
            editing={true}
            exportData={{...dataExport, ...template}}
            close={() =>
              this.setState({ showEditModal: false })
            }
          />
        }

        {dataExport && dataExport.status === ScheduledDataExportStatus.Active && (
          <PauseDataExportModal
            open={this.state.showPauseModal}
            close={() => this.setState({ showPauseModal: false })}
          />
        )}

        {dataExport && dataExport.status === ScheduledDataExportStatus.Paused && (
          <ResumeDataExportModal
            open={this.state.showResumeModal}
            close={() => this.setState({ showResumeModal: false })}
          />
        )}

        {dataExport && dataExport.status !== ScheduledDataExportStatus.Archived && (
          <ArchiveDataExportModal
            open={this.state.showArchiveModal}
            close={() => this.setState({ showArchiveModal: false })}
          />
        )}

        {dataExport && dataExport.status !== ScheduledDataExportStatus.Archived && (
          <TriggerDataExportModal
            open={this.state.showTriggerModal}
            close={() => this.setState({ showTriggerModal: false })}
          />
        )}
      </div>
    );
  }
}


const renderSFTPAdapter = (adapter: SFTPAdapter) => {
  return <tbody>
    <tr>
      <td>
        <strong>Host</strong>
      </td>
      <td>
        {adapter.host}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Port</strong>
      </td>
      <td>
        {adapter.port}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Directory</strong>
      </td>
      <td>
        {adapter.path}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Username</strong>
      </td>
      <td>
        {adapter.username}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Authentication method</strong>
      </td>
      <td>
        {adapter.authenticationMethod === SFTPAuthenticationMethod.PrivateKey
          ? 'SSH private key along with optional passphrase is being used as authentication'
          : 'Password'}
      </td>
    </tr>

  </tbody>;
};

const renderS3Adapter = (adapter: S3Adapter) => {
  return <tbody>
    <tr>
      <td>
        <strong>Region</strong>
      </td>
      <td>
        {adapter.region}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Bucket name</strong>
      </td>
      <td>
        {adapter.bucketName}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Bucket path</strong>
      </td>
      <td>
        {adapter.bucketPath}
      </td>
    </tr>
  </tbody>;
};

const renderHTTPSAdapter = (adapter: HTTPSAdapter) => {
  return <tbody>
    <tr>
      <td>
        <strong>URL</strong>
      </td>
      <td>
        {adapter.url}
      </td>
    </tr>
    {adapter.headers && Object.keys(adapter.headers).length > 0 && <tr>
      <td>
        <strong>Headers</strong>
      </td>
      <td>
        <table className='data-export-details-table'>
          <tbody>
            {Object.keys(adapter.headers).map((key, i) => <tr key={i}>
              <td>
                {key}
              </td>
              <td>
                {(adapter.headers as any)[key]}
              </td>
            </tr>)}
          </tbody>
        </table>
      </td>
    </tr>}
  </tbody>;
};
