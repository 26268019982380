/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (<svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Icon / Decorative / Image / Document / Copy / 16px</title>
        <desc>Created with Sketch.</desc>
        <g id="Organizations---Custom-exports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Organizations---[Org]---Data-template---Helpers" transform="translate(-1240.000000, -181.000000)">
                <g id="Icon-/-Decorative-/-Image-/-Document-/-Copy-/-16px" transform="translate(1240.000000, 181.000000)">
                    <rect id="Rectangle-Copy-6" x="0" y="0" width="16" height="16"></rect>
                    <path d="M2,2 L2,14 L2.22044605e-16,14 L-4.4408921e-16,2 L0,0 L10,0 L10,2 L2,2 Z M5,5 L5,14 L11,14 L11,5 L5,5 Z M3,3 L13,3 L13,16 L3,16 L3,3 Z M6,9 L10,9 L10,10 L6,10 L6,9 Z M6,7 L10,7 L10,8 L6,8 L6,7 Z M6,11 L10,11 L10,12 L6,12 L6,11 Z" id="Combined-Shape" fill="#7A7A7A" fillRule="evenodd"></path>
                </g>
            </g>
        </g>
    </svg>);
  }
}