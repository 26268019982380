import React, { Component } from 'react';
import uuid from 'uuid';

export class HorizontalLine extends Component<any, any> {
  render() {
    return (
      <div
        className='form-group col-sm-12'
        key={`blank-space-${uuid().toString()}`}
      >
        <hr />
      </div>
    );
  }
}

export default HorizontalLine;
