import { Construct } from '../../../../../../../shared/domain/construct';
import { BlockState } from './block-state';

export class MarkdownBlockState extends BlockState {

  constructor(init: Construct<MarkdownBlockState>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
    };
  }

  static fromJSON(init: any) {
    return new MarkdownBlockState({
      type: init.type,
    });
  }
}
