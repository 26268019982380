import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';

export default class PdfViewer extends Component {
  state = {
    file: this.props.base64,
    numPages: null,
    pagesRendered: null,
  };

  onDocumentLoadSuccess = ({ numPages }) =>
    this.setState({
      numPages,
      pagesRendered: 0,
    });

  onRenderSuccess = () =>
    this.setState(prevState => ({
      pagesRendered: prevState.pagesRendered + 1,
    }));

  componentWillReceiveProps(nextProps) {
    this.setState({ file: nextProps.base64 });
  }

  render() {
    const { file, numPages, pagesRendered } = this.state;
    const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

    return (
      <div>
        <div
          style={{
            justifyContent: 'center',
            flex: 1,
            display: 'flex',
            overflow: 'scroll',
          }}
        >
          <div>
            <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
              {Array.from(new Array(pagesRenderedPlusOne), (el, index) => {
                const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
                const isLastPage = numPages === index + 1;
                const needsCallbackToRenderNextPage =
                  isCurrentlyRendering && !isLastPage;

                return (
                  <Page
                    key={`page_${index + 1}`}
                    onRenderSuccess={
                      needsCallbackToRenderNextPage
                        ? this.onRenderSuccess
                        : null
                    }
                    pageNumber={index + 1}
                  />
                );
              })}
            </Document>
          </div>
        </div>
      </div>
    );
  }
}
