import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  Form,
  FormGroup,
  Col,
  Label,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {
  DefaultExportTemplates,
  Template,
} from '../../../domain/templates/template';
import {
  DailyFrequencyForm,
  WeeklyFrequencyForm,
  MonthlyFrequencyForm,
} from './frequency-form';
import Images from '../../../../../../components/svg-images';
import { TemplateListStore } from '../../../stores/template-list-store';
import { FrequencyData, DetailsData } from '../new-export-modal';
import { ProductModule } from '../../../../../../product-modules/domain/product-module';
import { DataExportDataRange } from '../../../domain/data-export-data-range';
import { ScheduledDataExportFrequencyType } from '../../../domain/scheduled-data-export-frequency';
import { ExportType } from '../../../domain/export-type';
import { Environment } from '../../../../../../shared/domain/environment';
import uuid from 'uuid';
import { TemplateViewStore } from '../../../stores/template-view-store';

const environment = Object.freeze({
  Sandbox: 'sandbox',
  Production: 'production',
});

const dataRangeOptions = Object.values(DataExportDataRange).map((x) => {
  const label = x.replace(/_/gi, ' ');
  return {
    label: label[0].toLocaleUpperCase() + label.substr(1),
    value: x,
  };
});

const environmentOptions = Object.values(environment).map((e) => ({
  label: e[0].toLocaleUpperCase() + e.substr(1),
  value: e,
}));

const frequencyOptions = Object.values(ScheduledDataExportFrequencyType).map(
  (e) => ({
    label: e[0].toLocaleUpperCase() + e.substr(1),
    value: e,
  }),
);

interface Props {
  fromTemplate?: boolean;
  detailsData?: {
    name: string;
    exportType: ExportType;
    templateId: string;
    environment: Environment;
    productModuleId: string;
    dataRange: string;
    frequency: {
      type: ScheduledDataExportFrequencyType;
    };
  };
  autoFocus: boolean;
  initialized: (valid: boolean, detailsData: DetailsData) => void;
  frequencyChanged: (valid: boolean, frequencyData: FrequencyData) => void;
  touched: boolean;
  changed: (valid: boolean, detailsData: any) => void;
}

interface Injected extends Props {
  templateListStore: TemplateListStore;
  templateViewStore: TemplateViewStore;
}

@inject('templateListStore', 'templateViewStore')
@observer
export class DetailsForm extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    const { detailsData, fromTemplate } = this.props;
    const templateId = fromTemplate
      ? window.location.pathname.split('/')[5]
      : (detailsData && detailsData.templateId) || '';
    this.state = {
      name: detailsData ? detailsData.name : '',
      exportType: detailsData ? detailsData.exportType : '',
      templateId,
      product: detailsData ? detailsData.productModuleId : '',
      environment: detailsData ? detailsData.environment : Environment.Sandbox,
      dataRange: detailsData ? detailsData.dataRange : 'today',
      frequencyType: detailsData
        ? detailsData.frequency.type
        : ScheduledDataExportFrequencyType.Daily,
      productModules: [],
      validate: {
        exportNameState: 'has-danger',
        exportTypeState: 'has-success',
        templateState: 'has-success',
        environmentState: 'has-success',
        includedDataRangeState: 'has-success',
        exportIntervalsState: 'has-success',
      },
      restricted: true,
    };

    props.changed(this.validateForm(), this.state);
  }

  get injected() {
    return this.props as Injected;
  }

  componentDidMount = async () => {
    const { templateListStore } = this.injected;

    await templateListStore.load({
      organizationId: this.organizationId,
      ignorePagination: true,
    });

    this.setState({
      exportType: this.props.fromTemplate
        ? ExportType.CustomExport
        : DefaultExportTemplates[0].exportType,
    });
  };

  get organizationId() {
    return window.location.pathname.split('/')[2];
  }

  validateForm() {
    if (
      this.state.validate.exportNameState === 'has-success' &&
      this.state.validate.exportTypeState === 'has-success' &&
      this.state.validate.environmentState === 'has-success' &&
      this.state.validate.includedDataRangeState === 'has-success' &&
      this.state.validate.exportIntervalsState === 'has-success' &&
      this.state.validate.templateState === 'has-success'
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateExportName(exportName: string) {
    if (exportName) {
      this.setState(
        {
          ...this.state,
          name: exportName,
          validate: {
            ...this.state.validate,
            exportNameState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          exportNameState: 'has-danger',
        },
      });
    }
  }

  async validateTemplate(templateId: string) {
    if (
      templateId !== ExportType.PolicyExport &&
      templateId !== ExportType.PolicyholderExport &&
      templateId !== ExportType.ClaimExport &&
      templateId !== ExportType.ComplaintExport
    ) {
      this.setState(
        {
          exportType: ExportType.CustomExport,
          templateId: templateId,
          validate: {
            ...this.state.validate,
            templateState: 'has-success',
          },
        },
        () => this.props.changed(this.validateForm(), this.state),
      );
    } else {
      this.setState(
        {
          exportType: templateId,
          templateId: templateId,
          validate: {
            ...this.state.validate,
            templateState: 'has-success',
          },
        },
        () => this.props.changed(this.validateForm(), this.state),
      );
    }
  }

  validateEnvironment(environment: string) {
    if (environment) {
      this.setState(
        {
          ...this.state,
          environment: environment,
          validate: {
            ...this.state.validate,
            environmentState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        validate: {
          ...this.state.validate,
          environmentState: 'has-danger',
        },
      });
    }
  }

  validateIncludedDataRange(includedDataRange: string) {
    if (includedDataRange) {
      this.setState(
        {
          ...this.state,
          dataRange: includedDataRange,
          validate: {
            ...this.state.validate,
            includedDataRangeState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        ...this.state.validate,
        includedDataRangeState: 'has-danger',
      });
    }
  }

  validateExportIntervals(exportIntervals: string) {
    if (exportIntervals) {
      this.setState(
        {
          ...this.state,
          frequencyType: exportIntervals,
          validate: {
            ...this.state.validate,
            exportIntervalsState: 'has-success',
          },
        },
        () => {
          this.props.changed(this.validateForm(), this.state);
        },
      );
    } else {
      this.setState({
        ...this.state.validate,
        exportIntervalsState: 'has-danger',
      });
    }
  }

  newExportTableRow(label: JSX.Element, value: JSX.Element) {
    return (
      <FormGroup row>
        <Label sm={6}>{label}</Label>
        <Col sm={6}>{value}</Col>
      </FormGroup>
    );
  }

  render() {
    const { templateListStore } = this.injected;
    const { templates } = templateListStore;

    return (
      <div>
        <Card className='new-export-modal'>
          <CardHeader>
            <h5>Export details</h5>
          </CardHeader>
          <CardBody>
            <Form style={{ width: '100%' }}>
              {this.newExportTableRow(
                <strong>
                  Export name<sup>*</sup>
                </strong>,
                <Input
                  type='text'
                  name='name'
                  value={this.state.name}
                  onChange={(e) => this.validateExportName(e.target.value)}
                />,
              )}
              {this.newExportTableRow(
                <strong>
                  Export template<sup>*</sup>
                </strong>,
                <Input
                  type='select'
                  name='templateId'
                  disabled={!!this.props.detailsData || this.props.fromTemplate}
                  value={
                    this.state.exportType === ExportType.CustomExport
                      ? this.state.templateId
                      : this.state.exportType
                  }
                  onChange={(e) => {
                    this.validateTemplate(e.target.value);
                  }}
                >
                  {this.renderTemplateOptions(templates)}
                  {this.renderTemplateOptionsDefault(DefaultExportTemplates)}
                </Input>,
              )}
              {this.newExportTableRow(
                <strong>
                  Permissions<sup>*</sup>
                  <Images.lock />
                </strong>,
                <div style={{ verticalAlign: 'top', display: 'flex' }}>
                  <div>
                    <Input
                      type='checkbox'
                      checked={this.state.restricted}
                      disabled={!!this.props.detailsData}
                      onChange={() =>
                        this.setState(
                          { ...this.state, restricted: !this.state.restricted },
                          () => {
                            this.props.changed(this.validateForm(), this.state);
                          },
                        )
                      }
                    />
                  </div>
                  <div>
                    <span>Limit export editing to Root-admin users only.</span>
                  </div>
                </div>,
              )}
            </Form>
          </CardBody>
        </Card>
        <Card className='new-export-modal'>
          <CardHeader>
            <h5>Filter exported data</h5>
          </CardHeader>
          <CardBody>
            <Form style={{ width: '100%' }}>
              {this.newExportTableRow(
                <strong>
                  Environment<sup>*</sup>
                </strong>,
                <Input
                  type='select'
                  name='environment'
                  disabled={!!this.props.detailsData}
                  value={this.state.environment}
                  onChange={(e) => this.validateEnvironment(e.target.value)}
                >
                  {this.renderOptions(environmentOptions)}
                </Input>,
              )}
              {this.newExportTableRow(
                <strong>
                  Included data range<sup>*</sup>
                </strong>,
                <Input
                  type='select'
                  name='dataRange'
                  value={this.state.dataRange}
                  onChange={(e) =>
                    this.validateIncludedDataRange(e.target.value)
                  }
                >
                  {this.renderOptions(dataRangeOptions)}
                </Input>,
              )}
            </Form>
          </CardBody>
        </Card>
        <Card className='new-export-modal'>
          <CardHeader>
            <h5>Scheduled intervals</h5>
          </CardHeader>
          <CardBody>
            <Form style={{ width: '100%' }}>
              {this.newExportTableRow(
                <strong>
                  Export intervals<sup>*</sup>
                </strong>,
                <Input
                  type='select'
                  name='frequencyType'
                  value={this.state.frequencyType}
                  onChange={(e) => this.validateExportIntervals(e.target.value)}
                >
                  {this.renderOptions(frequencyOptions)}
                </Input>,
              )}
            </Form>

            {this.state.frequencyType ===
              ScheduledDataExportFrequencyType.Daily && (
              <DailyFrequencyForm
                autoFocus
                frequencyData={
                  this.props.detailsData
                    ? this.props.detailsData.frequency
                    : null
                }
                changed={this.props.frequencyChanged}
                initialized={this.props.frequencyChanged}
                touched={this.props.touched}
              />
            )}

            {this.state.frequencyType ===
              ScheduledDataExportFrequencyType.Weekly && (
              <WeeklyFrequencyForm
                autoFocus
                frequencyData={
                  this.props.detailsData
                    ? this.props.detailsData.frequency
                    : null
                }
                changed={this.props.frequencyChanged}
                initialized={this.props.frequencyChanged}
                touched={this.props.touched}
              />
            )}

            {this.state.frequencyType ===
              ScheduledDataExportFrequencyType.Monthly && (
              <MonthlyFrequencyForm
                autoFocus
                frequencyData={
                  this.props.detailsData
                    ? this.props.detailsData.frequency
                    : null
                }
                changed={this.props.frequencyChanged}
                initialized={this.props.frequencyChanged}
                touched={this.props.touched}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }

  renderProductModulesOptions(productModules: ProductModule[]) {
    return productModules.map((productModule: ProductModule) => {
      return (
        <option key={productModule.key} value={productModule.key}>
          {productModule.name}
        </option>
      );
    });
  }

  renderOptions(data: { value: string }[]) {
    return data.map((content: { value: string; label: string }) => {
      return (
        <option key={content.value} value={content.value}>
          {content.label}
        </option>
      );
    });
  }

  renderTemplateOptions(templates: Template[]) {
    return templates.map((content: Template) => {
      return (
        <option
          key={content.templateId || uuid().toString()}
          value={content.templateId}
        >
          {content.templateName}
        </option>
      );
    });
  }

  renderTemplateOptionsDefault(templates: DefaultExportTemplates[]) {
    return templates.map((content: DefaultExportTemplates) => {
      return (
        <option
          key={content.templateId || uuid().toString()}
          value={content.exportType}
        >
          {content.templateName}
        </option>
      );
    });
  }
}
