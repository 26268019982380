/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='110px'
        height='110px'
        viewBox='0 0 110 110'
        enableBackground='new 0 0 110 110'
        xmlSpace='preserve'
      >
        <g>
          <g>
            <g>
              <path
                fill='#FFFFFF'
                d='M33.884,106.322c-2.558,0-4.651-2.094-4.651-4.651V16.522c0-2.56,2.093-4.651,4.651-4.651H74.99
            c2.559,0,4.651,2.091,4.651,4.651v85.148c0,2.558-2.093,4.651-4.651,4.651H33.884z'
              />
              <rect
                x='33.61'
                y='16.125'
                fill='#202B40'
                width='41.653'
                height='77.041'
              />
              <circle fill='#202B40' cx='55.367' cy='99.744' r='3.489' />
            </g>
          </g>
          <g>
            <line
              fill='#CAE0FF'
              stroke='#CAE0FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='86.404'
              y1='4.955'
              x2='82.561'
              y2='8.802'
            />
            <line
              fill='#CAE0FF'
              stroke='#CAE0FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='77.63'
              y1='1.322'
              x2='77.63'
              y2='6.581'
            />
            <line
              fill='#CAE0FF'
              stroke='#CAE0FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeMiterlimit='10'
              x1='90.042'
              y1='13.73'
              x2='84.761'
              y2='13.73'
            />
          </g>
          <rect
            x='71.507'
            y='53.652'
            fill='#202B40'
            width='12.951'
            height='33.725'
          />
          <path
            fill='#CAE0FF'
            stroke='#CAE0FF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='M102.091,78.169
        c0,2.967-2.403,5.372-5.37,5.372H45.16c-2.966,0-5.371-2.405-5.371-5.372V62.066c0-2.967,2.405-5.371,5.371-5.371h51.562
        c2.967,0,5.37,2.404,5.37,5.371V78.169z'
          />
          <g>
            <g>
              <path
                fill='#FFFFFF'
                d='M47.179,73.127l0.125-2.401l-2.029,1.295l-0.46-0.815l2.133-1.086l-2.133-1.088l0.46-0.837l2.029,1.318
            l-0.125-2.406h0.962l-0.126,2.406l2.008-1.318l0.481,0.837l-2.134,1.088l2.134,1.086l-0.481,0.815l-2.008-1.295l0.126,2.401
            H47.179z'
              />
              <path
                fill='#FFFFFF'
                d='M56.38,73.127l0.125-2.401l-2.03,1.295l-0.46-0.815l2.135-1.086l-2.135-1.088l0.46-0.837l2.03,1.318
            l-0.125-2.406h0.962l-0.127,2.406l2.009-1.318l0.48,0.837l-2.132,1.088l2.132,1.086l-0.48,0.815l-2.009-1.295l0.127,2.401H56.38z
            '
              />
              <path
                fill='#FFFFFF'
                d='M65.58,73.127l0.125-2.401l-2.027,1.295l-0.46-0.815l2.132-1.086l-2.132-1.088l0.46-0.837l2.027,1.318
            l-0.125-2.406h0.964l-0.127,2.406l2.009-1.318l0.479,0.837l-2.132,1.088l2.132,1.086l-0.479,0.815l-2.009-1.295l0.127,2.401
            H65.58z'
              />
            </g>
            <g>
              <path
                fill='#FFFFFF'
                d='M75.339,73.127l0.126-2.401l-2.027,1.295l-0.461-0.815l2.133-1.086l-2.133-1.088l0.461-0.837l2.027,1.318
            l-0.126-2.406h0.963l-0.126,2.406l2.007-1.318l0.481,0.837l-2.132,1.088l2.132,1.086l-0.481,0.815l-2.007-1.295l0.126,2.401
            H75.339z'
              />
              <path
                fill='#FFFFFF'
                d='M84.541,73.127l0.126-2.401l-2.029,1.295l-0.461-0.815l2.134-1.086l-2.134-1.088l0.461-0.837l2.029,1.318
            l-0.126-2.406h0.963l-0.128,2.406l2.009-1.318l0.481,0.837l-2.133,1.088l2.133,1.086l-0.481,0.815l-2.009-1.295l0.128,2.401
            H84.541z'
              />
              <path
                fill='#FFFFFF'
                d='M93.74,73.127l0.128-2.401l-2.029,1.295l-0.461-0.815l2.134-1.086l-2.134-1.088l0.461-0.837l2.029,1.318
            l-0.128-2.406h0.965l-0.128,2.406l2.009-1.318l0.481,0.837l-2.133,1.088l2.133,1.086l-0.481,0.815l-2.009-1.295l0.128,2.401
            H93.74z'
              />
            </g>
          </g>
          <path
            fill='#CAE0FF'
            stroke='#CAE0FF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            d='M41.436,78.583
        c0,2.225-1.805,4.028-4.034,4.028l-0.022-0.058c1.41-0.837,2.393-2.721,2.393-4.908L41.436,78.583z'
          />
        </g>
      </svg>
    );
  }
}
