import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';
import { ProductModuleStore } from '../product-definition/stores/product-module-store';
import { ProductModuleCodeRunStore } from '../../stores/product-module-code-run-store';
import { ProductModuleCodeRunLogStore } from '../../stores/product-module-code-run-log-store';
import { ProductModuleCodeRun, ProductModuleCodeRunStatus } from '../../domain/product-module-code-run';
import { DomainEntityCard } from '../../../components/domain/domain-entity-card/domain-entity-card';
import { Breadcrumb, Container, BreadcrumbItem } from 'reactstrap';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';
import PageHeader from '../../../components/page-header';
import Images from '../../../components/svg-images';
import LoadingInPage from '../../loading';
import { ApiError } from '../../../errors/error';
import { ErrorDisplay } from '../../../errors/error-display';

class RunDetailsCard extends DomainEntityCard<ProductModuleCodeRun> {}

const CreatedAtElement: React.FC<ProductModuleCodeRun> = ({ createdAt }) => {
  return (
    <div className='table-cell-single'>
      {createdAt.format('YYYY-MM-DD HH:mm:ss')}
    </div>
  );
};

interface Props extends RouteComponentProps {
  route: any;
  params: {
    productModuleKey: string;
    productModuleCodeRunId: string;
  };
  router?: History;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  productModuleCodeRunStore: ProductModuleCodeRunStore;
  productModuleCodeRunLogStore: ProductModuleCodeRunLogStore;
}

interface State {
  error?: ApiError;
}

@inject(
  'productModuleStore',
  'productModuleCodeRunStore',
  'productModuleCodeRunLogStore',
)
@observer
class ProductModuleCodeRunViewImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  get injected() {
    return this.props as Injected;
  }

  async componentDidMount() {
    const { params } = this.props;
    const {
      productModuleStore,
      productModuleCodeRunStore,
      productModuleCodeRunLogStore,
    } = this.injected;
    try {
      await productModuleStore.init(params.productModuleKey);

      await Promise.all([
        productModuleCodeRunStore.load({
          productModuleId: productModuleStore.productModule.productModuleId,
          productModuleCodeRunId: params.productModuleCodeRunId,
        }),
        productModuleCodeRunLogStore.load({
          productModuleId: productModuleStore.productModule.productModuleId,
          productModuleCodeRunId: params.productModuleCodeRunId,
        }),
      ]);
    } catch (error) {
      this.setState({ error: new ApiError(error)});
    } finally {
      productModuleCodeRunStore.setProductModuleCodeRunViewStoreLoading({ isLoading: false });
      productModuleCodeRunLogStore.setProductModuleCodeRunLogStoreLoading({ isLoading: false });
    }

  }

  CreatedAtColumn = {
    heading: 'Created at',
    element: CreatedAtElement,
  };

  FunctionNameElement: React.FC<ProductModuleCodeRun> = ({
    functionName,
  }) => {
    return <span className='scheduled-code-function-name'>{functionName}</span>;
  };

  FunctionNameColumn = {
    heading: 'Function name',
    element: this.FunctionNameElement,
  };

  StatusElement: React.FC<ProductModuleCodeRun> = (productModuleCodeRun) => (
    <div className='table-cell-single'>{this.renderStatus({ productModuleCodeRun })}</div>
  );

  StatusColumn = {
    heading: 'Status',
    element: this.StatusElement,
  };

  PolicyIdElement: React.FC<ProductModuleCodeRun> = ({ policyId }) => (
    <div className='table-cell-single'>{policyId}</div>
  );

  PolicyIDColumn = {
    heading: 'Policy ID',
    element: this.PolicyIdElement,
  };

  renderStatus = (params: { productModuleCodeRun: ProductModuleCodeRun}) => {
    const { productModuleCodeRun } = params;
    return (
      <div>
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.Complete && (
          <Images.success />
        )}
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.Failed && <Images.error />}
        {productModuleCodeRun.status === ProductModuleCodeRunStatus.InProgress && (
          <Images.inProgress />
        )}
        <strong className='run-log-status-text'> {productModuleCodeRun.prettyStatus()}</strong>
      </div>
    );
  };

  renderTable() {
    const { productModuleCodeRunStore } = this.injected;
    const isLoading = productModuleCodeRunStore.isLoading;

    if (!productModuleCodeRunStore.productModuleCodeRun) {
      return;
    }

    return (
      <RunDetailsCard
        isLoading={isLoading}
        rows={[this.PolicyIDColumn, this.CreatedAtColumn, this.FunctionNameColumn, this.StatusColumn]}
        domainEntity={productModuleCodeRunStore.productModuleCodeRun}
      />
    );
  }

  renderEmptyLogs() {
    return (
      <div className='list-empty-state'>
        <div>
          <h3>Nothing to see here</h3>
          <p>There are no logs to report from this run.</p>
        </div>
        <img src='/images/empty-genericfolder-lrg.png' role='presentation' />
      </div>
    );
  }

  renderLogs() {
    const { productModuleCodeRunLogStore } = this.injected;
    const isLoading = productModuleCodeRunLogStore.isLoading;

    if (isLoading) {
      return;
    }

    return (
      <div className='data-export-run-logs'>
        <table className='no-hover'>
          <tbody>
            {productModuleCodeRunLogStore.productModuleCodeRunLogItems.map(
              (l, i) => (
                <tr key={`${l.productModuleCodeRunId}-${i}`}>
                  <td style={{ width: '250px' }}>
                    {l.createdAt.toISOString()}
                  </td>
                  <td style={{ width: '100px', color: l.level === 'error'? 'red' : 'black' }}>[{l.level}]</td>
                  <td>{l.content}</td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { productModuleStore, productModuleCodeRunLogStore } = this.injected;

    const { productModuleCodeRunLogItems } = productModuleCodeRunLogStore;

    if (!productModuleStore.productModule || !productModuleStore.productModuleDefinitionDraft) {
      return <LoadingInPage />;
    }

    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          route={this.props.route}
          subRoutes={this.sidebarSchemaRender().routes()}
          title={productModuleStore.productModule.name}
          router={this.props.router}
        />
        <Container className='container-positioning'>
          <Breadcrumb>
            <BreadcrumbItem>
              <a className='breadcrumb-a-tag-link' href='/product-modules'>
                Product modules
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a
                className='breadcrumb-a-tag-link'
                href={`/product-modules/${this.props.params.productModuleKey}`}
              >
                {productModuleStore.productModule.name}
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a className='breadcrumb-a-tag-link'
                href={`/product-modules/${this.props.params.productModuleKey}/runs`}
              >
                Run logs
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Product module code run</BreadcrumbItem>
          </Breadcrumb>
          <div style={{ marginLeft: '1.5rem', paddingTop: '1.5rem' }}>
            <PageHeader title={'Run logs'} />
          </div>
          {this.state.error && <ErrorDisplay error={this.state.error}/>}
          <div style={{ marginLeft: 30 }}>
            {this.renderTable()}
            {productModuleCodeRunLogItems.length > 0 ? this.renderLogs() : this.renderEmptyLogs()}
          </div>
        </Container>
      </div>
    );
  }

  Routes = [
    {
      name: 'Overview',
      url: `/product-modules/${this.props.params.productModuleKey}`,
    },
    {
      name: 'Deployment',
      url: `/product-modules/${this.props.params.productModuleKey}/deployment`,
    },
    {
      name: 'Version history',
      url: `/product-modules/${this.props.params.productModuleKey}/version-history`,
    },
    {
      name: 'Run logs',
      url: `/product-modules/${this.props.params.productModuleKey}/runs`,
    },
  ];

  sidebarSchemaRender = () => {
    return {
      routes: () => {
        return this.Routes;
      },
    };
  };
}

export const ProductModuleCodeRunView = withRouter(
  ProductModuleCodeRunViewImpl,
);
