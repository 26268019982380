import { observable, computed } from 'mobx';
import { ReportApi, ReportFilters } from './report-api';
import { Pagination, defaultPagination } from '../../../../shared/domain/pagination';
import { ReportRun } from '../domain/report-run';

export class ReportRunListStore {
  @observable reportRuns: ReportRun[] = [];
  @observable isLoading = true;
  @observable filters: ReportFilters = {};
  @observable pagination: Pagination = defaultPagination;
  @observable total = 0;


  load = async (params: { organizationId: string; reportId: string }) => {
    const { organizationId, reportId } = params;
    this.isLoading = true;
    try {
      const { reportRuns, total } = await ReportApi.getReportRuns({
        organizationId,
        pagination: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        },
        includes: [],
        filters: this.filters,
        reportId,
      });

      this.reportRuns = reportRuns;
      this.total = total;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      return error;
    }
  };

  filter = (params: { filters: ReportFilters; organizationId: string; reportId: string }) => {
    const { filters, organizationId, reportId } = params;
    this.pagination.page = 1;
    this.filters = filters;
    this.load({
      organizationId,
      reportId,
    });
  };

  goToPage = (params: { pageNumber: number; organizationId: string; reportId: string } ) => {
    const { organizationId, pageNumber, reportId } = params;
    this.pagination.page = pageNumber + 1;
    this.load({
      organizationId,
      reportId,
    });
  }

  @computed get isEmpty() {
    return !this.isLoading && this.reportRuns.length === 0;
  }

  setReportRunListStoreIsLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const reportRunListStore = new ReportRunListStore();
