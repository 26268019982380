import React from 'react';
import { FormGroup, Col } from 'reactstrap';
import { FormOptions } from '../config';
import { setSingularOrPlural } from '../../../utils';
import _ from 'lodash';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';

interface Props {
  value: string;
  lapseAfter: string;
  type: string;
  index: number;
  skippedPayments: string;
}

interface State {}

class GracePeriodCompletedChild extends React.Component<Props, State> {
  text = () => {
    let { lapseAfter, value, type, skippedPayments } = this.props;
    lapseAfter = lapseAfter ? lapseAfter : '';
    value = value ? value : '';
    type = type ? type : '';
    skippedPayments = skippedPayments ? skippedPayments : '';

    let text = 'Lapse a policy after';

    switch (skippedPayments) {
      case null:
      case undefined:
        text = `${text} a period of`;
        break;
      default:
        text = text + ' ' + skippedPayments.toString();
    }

    switch (lapseAfter) {
      case 'period after missed payment':
        text = `${text} ${value} ${_.trim(
          setSingularOrPlural(value, type),
        )} after a payment is missed.`;
        break;
      default:
        if (text && type) {
          text = `${text} ${lapseAfter.toString()} of ${value} ${_.trim(
            setSingularOrPlural(value, type),
          )}.`;
        } else {
          text = `${text} ${_.trim(lapseAfter.toString())}.`;
        }
    }

    return text;
  };

  render() {
    return (
      <div>
        <FormGroup row style={{ marginBottom: -14, paddingTop: 14 }}>
          <Col md={4} sm={12}>
            <div className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'>
              {this.props.index === 0 ? <Icon12ArrowRight /> : 'OR'}
            </div>
            <h6 className='completed-card-rule-heading'>
              Rule {FormOptions.allowListRules ? this.props.index + 1 : ''}
            </h6>
          </Col>
          <Col md={8} sm={12} className='completed-card-rule-heading'>
            <p>{this.text()}</p>
          </Col>
        </FormGroup>
        <hr className='horizontal-line-without-padding-bottom' />
      </div>
    );
  }
}

export default GracePeriodCompletedChild;
