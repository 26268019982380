import React from 'react';
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import ajax from '../../helpers/ajax';

class Communications extends React.Component {
  state = {
    toAddressesString: '',
    ccAddressesString: '',
    bccAddressesString: '',
    subject: '',
    templateString: '',
    allowSubmit: false,
    showAlert: false,
    alertText: '',
    alertType: '',
  };

  onDismiss = () => {
    this.setState({ showAlert: false });
  };

  canSubmit = () => {
    this.setState(prevState => {
      return {
        allowSubmit:
          prevState.toAddressesString.trim() !== '' &&
          prevState.subject.trim() !== '' &&
          prevState.templateString.trim() !== '',
      };
    });
  };

  toAddressesChangedHandler = event => {
    this.setState({
      toAddressesString: event.target.value,
    });

    this.canSubmit();
  };

  ccAddressesChangedHandler = event => {
    this.setState({
      ccAddressesString: event.target.value,
    });

    this.canSubmit();
  };

  bccAddressesChangedHandler = event => {
    this.setState({
      bccAddressesString: event.target.value,
    });

    this.canSubmit();
  };

  subjectChangedHandler = event => {
    this.setState({
      subject: event.target.value,
    });

    this.canSubmit();
  };

  templateChangedHandler = event => {
    this.setState({
      templateString: event.target.value,
    });

    this.canSubmit();
  };

  processAddresses = (emails, type) =>
    emails
      .split(';')
      .map(address => {
        const item = { email: address.trim() };

        if (['cc', 'bcc'].includes(type)) {
          item.type = type;
        }

        return item;
      })
      .filter(item => item !== undefined && item.email !== '');

  sendCommsHandler = async event => {
    event.preventDefault();

    const addressArray = [
      ...this.processAddresses(this.state.toAddressesString),
      ...this.processAddresses(this.state.ccAddressesString, 'cc'),
      ...this.processAddresses(this.state.bccAddressesString, 'bcc'),
    ];

    const customMailData = {
      subject: this.state.subject,
      to: addressArray,
      mjml: this.state.templateString,
    };

    if (this.state.allowSubmit) {
      try {
        const result = await ajax({
          type: 'POST',
          path: '/insurance/internal/custom-email',
          data: customMailData,
        });

        if (result.type === 'failed_to_send_notification') {
          this.setState({
            showAlert: true,
            alertText: 'Sending of email failed!',
            alertType: 'danger',
          });
        } else {
          this.setState({
            showAlert: true,
            alertText: 'Send successful!',
            alertType: 'success',
          });
        }
      } catch (error) {
        this.setState({
          showAlert: true,
          alertText: 'Sending of email failed! Message: ' + error.message,
          alertType: 'danger',
        });
      }
    } else {
      this.setState({
        showAlert: true,
        alertText: 'Please fill in all required fields before submitting!',
        alertType: 'danger',
      });
    }
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Mailer</CardHeader>
          <CardBody>
            <Alert
              color={this.state.alertType}
              isOpen={this.state.showAlert}
              toggle={this.onDismiss}
            >
              {this.state.alertText}
            </Alert>
            <Form onSubmit={this.sendCommsHandler}>
              <FormGroup>
                <Label for='txtToAddress'>To</Label>
                <Input
                  type='text'
                  name='toAddress'
                  id='txtToAddress'
                  placeholder='Enter To addresses separated by semi-colon'
                  value={this.state.toAddressesString}
                  invalid={this.state.toAddressesString === ''}
                  onChange={event => this.toAddressesChangedHandler(event)}
                />
                <FormFeedback>This is required!</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for='txtCCAddress'>CC</Label>
                <Input
                  type='text'
                  name='CCAddress'
                  id='txtCCAddress'
                  placeholder='Enter CC addresses separated by semi-colon'
                  value={this.state.ccAddressesString}
                  onChange={event => this.ccAddressesChangedHandler(event)}
                />
              </FormGroup>
              <FormGroup>
                <Label for='txtBCCAddress'>BCC</Label>
                <Input
                  type='text'
                  name='BCCAddress'
                  id='txtBCCAddress'
                  placeholder='Enter BCC addresses separated by semi-colon'
                  value={this.state.bccAddressesString}
                  onChange={event => this.bccAddressesChangedHandler(event)}
                />
              </FormGroup>
              <FormGroup>
                <Label for='txtSubject'>Subject</Label>
                <Input
                  type='text'
                  name='Subject'
                  id='txtSubject'
                  placeholder='Subject line'
                  value={this.state.subject}
                  invalid={this.state.subject === ''}
                  onChange={event => this.subjectChangedHandler(event)}
                />
                <FormFeedback>This is required!</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for='txtTemplate'>Completed Template</Label>
                <Input
                  type='textarea'
                  name='template'
                  id='txtTemplate'
                  placeholder='Paste MJML code here'
                  style={{ height: 200 }}
                  value={this.state.templateString}
                  invalid={this.state.templateString === ''}
                  onChange={event => this.templateChangedHandler(event)}
                />
                <FormFeedback>This is required!</FormFeedback>
              </FormGroup>
              <Button id='btnSubmit' color='primary' className='pull-right'>
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Communications;
