import React, { Component } from 'react';
import { Card, Table, Row, Col, Input, Button } from 'reactstrap';
import PageHeader from '../../components/page-header';
import { withData } from '../../helpers';
import { ConfigToggle } from '../../components/config-toggle';
import { Icon24PXCross } from '../../components/icons/icon-24-px-cross';
import { inject, observer } from 'mobx-react';
import { OrganizationListStore } from '../stores/organization-list-store';

interface Props {
  fetch: (params: { path: string; type: string }) => void;
  params: {
    organizationId: string;
  };
  organizationListStore?: OrganizationListStore;
  loading: boolean;
  organization: {
    reviewed: string;
    productIcon: string;
    productName: string;
    clientId: string;
    productWebsite: string;
    productDescription: string;
    validUserDomains: string[];
    ficaEnabled: boolean;
    banvDisabled: boolean;
    qaEnabled: boolean;
  };
}

interface State {
  validUserDomains: string[];
  ficaEnabled: boolean;
  qaEnabled: boolean;
  banvDisabled: boolean;
  errorIndices: number[];
}
let timeout: any = null;

@inject('organizationListStore')
@observer
class Summary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      validUserDomains: [],
      ficaEnabled: props.organization && props.organization.ficaEnabled,
      qaEnabled: props.organization && props.organization.qaEnabled,
      banvDisabled: props.organization && props.organization.banvDisabled,
      errorIndices: [],
    };
  }

  componentDidMount() {
    const { organization } = this.props;
    this.setState({
      ficaEnabled: organization && organization.ficaEnabled,
      validUserDomains:
        organization && organization.validUserDomains
          ? organization.validUserDomains
          : [],
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.organization &&
      nextProps.organization.validUserDomains !==
        (this.props.organization && this.props.organization.validUserDomains)
    ) {
      this.setState({
        validUserDomains: nextProps.organization.validUserDomains,
        ficaEnabled: nextProps.organization.ficaEnabled,
        qaEnabled: nextProps.organization.qaEnabled,
        banvDisabled: nextProps.organization.banvDisabled,
      });
    }
  }

  publish = () =>
    this.props.fetch({
      path: `/admin/organizations/${this.props.params.organizationId}/enable`,
      type: 'POST',
    });

  sandbox = () =>
    this.props.fetch({
      path: `/admin/organizations/${this.props.params.organizationId}/disable`,
      type: 'POST',
    });

  handleLiveToggle = (isChecked: boolean) => {
    if (isChecked) {
      if (!window.confirm('Are you sure you want to set this org live')) {
        return false;
      }
      this.publish();
      window.location.reload();
    } else {
      if (
        !window.confirm('Are you sure you want to set this org to sandbox only')
      ) {
        return false;
      }
      this.sandbox();
      window.location.reload();
    }
  };

  handleFicaToggle = async (isChecked: boolean) => {
    if (isChecked) {
      if (
        !window.confirm('Are you sure you want to display the FICA checklist?')
      ) {
        return;
      }
      this.setState({ ficaEnabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          fica_enabled: isChecked,
        });
      }
    } else {
      if (
        !window.confirm(
          'Are you sure you want to turn off the FICA checklist display?',
        )
      ) {
        return;
      }
      this.setState({ ficaEnabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          fica_enabled: isChecked,
        });
      }
    }
  };

  handleBanvToggle = async (isChecked: boolean) => {
    if (isChecked) {
      if (!window.confirm('Are you sure you want to disable BANV?')) {
        return;
      }
      this.setState({ banvDisabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          banv_disabled: isChecked,
        });
      }
    } else {
      if (!window.confirm('Are you sure you want to enable BANV?')) {
        return;
      }
      this.setState({ banvDisabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          banv_disabled: isChecked,
        });
      }
    }
  };

  handleQAToggle = async (isChecked: boolean) => {
    if (isChecked) {
      if (!window.confirm('Are you sure you want to enable QA checklist?')) {
        return;
      }
      this.setState({ qaEnabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          qa_enabled: isChecked,
        });
      }
    } else {
      if (!window.confirm('Are you sure you want to disbale QA checklist?')) {
        return;
      }
      this.setState({ qaEnabled: isChecked });
      const { organizationListStore, organization } = this.props;
      if (organizationListStore) {
        await organizationListStore.updateOrganization(organization.clientId, {
          qa_enabled: isChecked,
        });
      }
    }
  };

  get isLive() {
    return !!this.props.organization.reviewed;
  }

  get isFicaEnabled() {
    return this.props.organization.ficaEnabled;
  }

  get isBanvDisabled() {
    return this.props.organization.banvDisabled;
  }

  get isQAEnabled() {
    return this.props.organization.qaEnabled;
  }

  removeListItem = async (index: number) => {
    const { organizationListStore, organization } = this.props;
    if (organizationListStore) {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const { validUserDomains, errorIndices } = this.state;
        validUserDomains.splice(index, 1);

        await organizationListStore.updateOrganization(organization.clientId, {
          valid_user_domains: validUserDomains,
        });
        this.setState({
          validUserDomains,
          errorIndices: errorIndices.filter(
            (errorIndex) => errorIndex !== index,
          ),
        });
      }, 800);
    }
  };

  updateListItem = async (value: string, index: number) => {
    const { organizationListStore, organization } = this.props;
    if (organizationListStore) {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const validUserDomains = this.state.validUserDomains;
        validUserDomains[index] = value;
        value.length > 0 &&
          (await organizationListStore.updateOrganization(
            organization.clientId,
            {
              valid_user_domains: validUserDomains,
            },
          ));
        this.setState({ validUserDomains });
      }, 1000);
    }
  };

  addListItem = async () => {
    this.setState({
      validUserDomains: [...this.state.validUserDomains, ''],
    });
  };

  vaildDomainsMap = (validDomains: string[]) => {
    if (validDomains) {
      return validDomains.map((domain, index) => {
        return (
          <div
            style={{ width: 530 }}
            key={`claim-checklist-${domain}_${index}`}
          >
            <a
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault;
                this.removeListItem(index);
              }}
            >
              <Icon24PXCross />
            </a>
            <div
              style={{
                display: 'flex',
                borderCollapse: 'separate',
              }}
            >
              <span
                style={{
                  borderRadius: '4px 0px 0px 4px',
                  borderRight: 0,
                  marginBottom: 10,
                  border: '1px solid #ccc',
                  color: '#555',
                  backgroundColor: '#eee',
                  width: 30,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                @
              </span>
              <Input
                style={{
                  marginBottom: 10,
                  width: 470,
                  minHeight: 40,
                  borderRadius: '0px 4px 4px 0px',
                  border: this.state.errorIndices.includes(index)
                    ? '3px solid red'
                    : '1px solid #ccc',
                }}
                type='text'
                id={`vaild-domains ${index}`}
                defaultValue={domain.replace('@', '')}
                onChange={(e) => {
                  const regex =
                    /^((:?[^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                  const errorIndices = this.state.errorIndices;
                  if (e.target.value && !regex.test(e.target.value)) {
                    errorIndices.push(index);
                    this.setState({
                      errorIndices,
                    });
                  } else {
                    this.setState({
                      errorIndices: errorIndices.filter(
                        (errorIndex) => errorIndex !== index,
                      ),
                    });
                    this.updateListItem(e.target.value, index);
                  }
                }}
              />
            </div>
          </div>
        );
      });
    }
    return <div></div>;
  };

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading...</i>
        </div>
      );
    }

    const { organization } = this.props;
    const organizationData = [
      {
        field: 'Organization name',
        value: organization.productName,
      },
      {
        field: 'Organization ID',
        value: organization.clientId,
      },
      {
        field: 'Website',
        value: organization.productWebsite,
      },
      {
        field: 'Description',
        value: organization.productDescription,
      },
    ];

    return (
      <div>
        <br />
        <PageHeader title='Summary' />
        <Row>
          <Col xs={3}>
            {this.props.organization.productIcon ? (
              <Card className='mb-4'>
                <img
                  src={this.props.organization.productIcon}
                  alt='Organization picture'
                  style={{
                    padding: 5,
                    maxWidth: '100%',
                    maxHeight: '100px',
                    margin: 'auto',
                  }}
                />
              </Card>
            ) : (
              <Card className='mb-4' style={{ backgroundColor: '#0f1b4a' }}>
                <img
                  src={'/images/sidebar/organizations.png'}
                  alt='Organization picture'
                  height='100'
                  width='100'
                  style={{ padding: 20, margin: 'auto' }}
                />
              </Card>
            )}
          </Col>
          <Col xs={9}>
            <h5 className='feather-light'>Organization details</h5>
            <Table>
              <tbody>
                {organizationData.map((orgValue, i) => {
                  return (
                    <tr key={`SummaryRow_${i}`}>
                      <th style={{ whiteSpace: 'nowrap' }}>{orgValue.field}</th>
                      <td>{orgValue.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <h5 className='feather-light padded'>Launch mode settings</h5>
            <p>
              An organization needs to undergo a market conduct review before
              being approved to go live.
            </p>
            <ConfigToggle
              title='Approved for live operational use'
              toggleSwitchProps={{
                checked: this.isLive,
                onColor: '#1fc881',
                onChange: this.handleLiveToggle,
              }}
            />
            <div style={{ marginTop: 20 }}>
              <ConfigToggle
                title='Display FICA checklist'
                toggleSwitchProps={{
                  checked: this.state.ficaEnabled,
                  onColor: '#1fc881',
                  onChange: this.handleFicaToggle,
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <ConfigToggle
                title='Disable BANV'
                toggleSwitchProps={{
                  checked: this.state.banvDisabled,
                  onColor: '#1fc881',
                  onChange: this.handleBanvToggle,
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <ConfigToggle
                title='Display QA checklist'
                toggleSwitchProps={{
                  checked: this.state.qaEnabled,
                  onColor: '#1fc881',
                  onChange: this.handleQAToggle,
                }}
              />
            </div>
            <h5 style={{ paddingTop: 20 }} className='feather-light'>
              Whitelisted user email domains
            </h5>
            <p>
              Admins in an organization have the ability to invite people to
              become Root users from the Team Members section on the Dashboard.
              Only people with email addresses that match the list of
              whitelisted domains below can be invited to become users of Root.
              Any existing Root users can still be added to the organization
              regardless of this list.
            </p>
            {this.vaildDomainsMap(this.state.validUserDomains)}
            <Button
              color='primary'
              className='product-module-definition-horizontal-card-button'
              style={{ marginTop: 10 }}
              onClick={(e) => this.addListItem()}
            >
              Add domain
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withData({
  prop: 'organization',
  path: (props: any) => `/client-apps/${props.params.organizationId}`,
})(Summary as any);
