import { observable, computed, action } from 'mobx';
import { debounce } from '../../../../helpers/debounce';
import { Pagination } from '../../../../shared/domain/pagination';
import { DataExportApi } from '../data-export-api';
import { ScheduledDataExport } from '../domain/scheduled-data-export';
import { DetailsData } from '../views/modals/new-export-modal';

export class DataExportListStore {
  @observable scheduledDataExports: ScheduledDataExport[] = [];
  @observable isLoading = true;
  @observable error = '';
  @observable pagination: Pagination = { page: 0, pageSize: 10 };
  @observable total: number = 0;
  @observable organizationId: string = '';

  load = async (params: { organizationId: string; pagination?: Pagination }) => {
    const { organizationId, pagination } = params;
    this.organizationId = organizationId;
    this.isLoading = true;
    try {
      const { scheduledDataExports, total } = await DataExportApi.get({
        organizationId,
        page: pagination ? pagination.page : this.pagination.page + 1,
        pageSize: pagination ? pagination.pageSize : this.pagination.pageSize,
      });
      this.scheduledDataExports = scheduledDataExports;
      this.total = total;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get isEmpty() {
    return !this.isLoading && this.scheduledDataExports.length === 0;
  }

  @action create = async (params: {
    organizationId: string;
    payload: DetailsData;
  }) => {
    const { organizationId, payload } = params;
    this.isLoading = true;
    try {
      const dataExport = await DataExportApi.add({ organizationId, payload });
      this.scheduledDataExports = [...this.scheduledDataExports, dataExport];
      return dataExport;
    } catch (error) {
      this.error = error;
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  setDataExportListStoreLoadingTrue = () => {
    this.isLoading = true;
  }

  goToPage = (pageNumber: number) => {
    this.pagination.page = pageNumber;
    debounce('go-to-page', () => this.load({ organizationId: this.organizationId }));
  }
}

export const dataExportListStore = new DataExportListStore();
