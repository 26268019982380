import * as React from 'react';
import { ValueType } from 'react-select/lib/types';
import ReactSelect from 'react-select';

export interface FilterOptions {
  value: string;
  label: string;
}

export const FilterBox = (params: {
  label: string;
  value?: string;
  options: FilterOptions[];
  onChange: (value: ValueType<FilterOptions>) => void;
}) => {
  const selectedElement = params.options.find(o => o.value === params.value);
  return (
    <div className='filter-box'>
      <span>{params.label}</span>
      <div style={{ width: '250px' }}>
        <ReactSelect
          value={selectedElement}
          options={params.options}
          onChange={params.onChange}
          defaultInputValue='All'
        />
      </div>
    </div>
  );
};
