/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <g fill='none' fillRule='evenodd'>
          <path fill='#C3C3C3' d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0' />
          <rect width='2' height='2' x='4' y='7' fill='#FFF' rx='1' />
          <rect width='2' height='2' x='7' y='7' fill='#FFF' rx='1' />
          <rect width='2' height='2' x='10' y='7' fill='#FFF' rx='1' />
        </g>
      </svg>
    );
  }
}
