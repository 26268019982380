import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { ObservablesTemp, Store } from '../../store';
import '../../styles/general.scss';
import { debounce } from '../../../../../../../../../helpers/debounce';

interface Props {
  store: Store;
  observable: ObservablesTemp;
  path: string;
  index: number;
  defaultValue?: string | null;
  placeHolder?: string;
}

interface State {
  invalid: boolean;
  value: string;
}

@observer
export default class InputLifecycle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invalid: false,
      value: '',
    };
  }

  componentDidMount() {
    const { path, observable, store } = this.props;
    const storeValue = this.getValue();

    if (storeValue) {
      this.setState({ value: storeValue });
    } else {
      store.updateLifeCycleStore(observable, path, '', this.props.index);
      this.setState({ value: '' });
    }
  }

  componentWillUnmount() {
    const { store, observable, path, index } = this.props;
    store.removeInput(observable, path, index);
  }

  getValue = () => {
    const { store, observable, index, path } = this.props;
    const value = _.get(store.lifecycleStoreTemp[observable][index], path);
    return value && value !== undefined ? value : '';
  };

  validSet = (value: string | null) => {
    const invalid = value && value.length > 0 ? false : true;
    this.setState({ invalid });
  };

  updateInput = (value: string, path: string, submitCheck: boolean = false) => {
    const { observable } = this.props;
    if (submitCheck) {
      this.validSet(value);
    }
    this.setState({ value });
    this.updateStore(observable, path, value, this.props.index);
  };

  updateStore = (
    observable: ObservablesTemp,
    path: string,
    value: string | null,
    index: number,
  ) => {
    debounce(
      'updating-select-input',
      () =>
        this.props.store.updateLifeCycleStore(observable, path, value, index),
      100,
    );
  };

  render() {
    const { path } = this.props;

    return (
      <Input
        type='number'
        min={0}
        className='input-placeholder'
        placeholder={this.props.placeHolder}
        value={this.state.value}
        invalid={this.state.invalid}
        onBlur={() => this.validSet(this.state.value)}
        onChange={({ target }) => this.updateInput(target.value, path, true)}
      />
    );
  }
}
