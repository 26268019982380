import * as React from 'react';
import { BlockUIState } from '../domain/states/block-ui-state';
import { BlockState } from '../domain/states/block-state';
import { MarkdownBlock as Block } from '../domain/markdown-block';
import { blockStateFactory } from '../domain/states/block-state-factory';
import { MarkdownBlockState } from '../domain/states/markdown-block-state';
import { FormGroup } from 'reactstrap';

interface Props {
  required?: boolean;
  disabled?: boolean;
  block: Block;
  blockState: MarkdownBlockState;
}

interface State {
  UIState: BlockUIState;
  markdownBlockState?: BlockState;
}

export class MarkdownBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      UIState: BlockUIState.Normal,
      markdownBlockState: blockStateFactory.createBlock({
        ...this.props.blockState,
      }),
    };
  }

  render() {
    const { block  } = this.props;
    const { markdownBlockState } = this.state;
    if (!markdownBlockState) {
      return null;
    }

    return (
      <FormGroup className='blocks-ui' style={{ width: '50%' }}>
        <p>{block.markdown}</p>
      </FormGroup>
    );
  }
}
