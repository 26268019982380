import { observable, action } from 'mobx';
import { TemplateApi } from '../template-api';
import { Template } from '../domain/templates/template';

export class TemplateViewStore {
  @observable isLoading = true;
  @observable template?: Template;

  load = async (params: { organizationId: string; templateId: string }) => {
    const { organizationId, templateId } = params;
    this.isLoading = true;

    try {
      this.template = await TemplateApi.single({ organizationId, templateId });
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  };

  clear = () => {
    this.template = undefined;
    this.isLoading = false;
  }

  @action archive = async (params: { organizationId: string }) => {
    const { organizationId } = params;
    if (this.template) {
      this.isLoading = true;
      try {
        await TemplateApi.archive({ organizationId, templateId: this.template.templateId.toString() });
        this.template = undefined;
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action update = async (params: { organizationId: string; payload: Template }) => {
    const { organizationId, payload } = params;
    if (this.template) {
      this.isLoading = true;
      try {
        this.template = await TemplateApi.update({
          organizationId,
          templateId: this.template.templateId.toString(),
          payload,
        });
      } catch (error) {
        return error;
      } finally {
        this.isLoading = false;
      }
    }
  };

  setTemplateViewStoreLoadingTrue = () => {
    this.isLoading = true;
  }
}

export const templateViewStore = new TemplateViewStore();
