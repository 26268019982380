import React from 'react';
import { connect } from 'react-redux';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import { toSentenceCase } from '../../helpers';
import { get, renderValue } from '../helpers';
import runtimeEnv from '@mars/heroku-js-runtime-env';

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() {
    const env = runtimeEnv();
    const requestUrl =
      env.REACT_APP_COLLECT_HOST + '/requests/' + this.props.params.requestId;
    const request = await get(requestUrl);
    this.setState({ request });
  }

  render() {
    return (
      <Card>
        <CardHeader>Request</CardHeader>
        <CardBody>
          {this.state.request ? (
            <Table size='sm'>
              <tbody>
                {Object.keys(this.state.request).map(k => (
                  <tr key={k}>
                    <td>
                      <b>{toSentenceCase(k)}</b>
                    </td>
                    <td>{renderValue(k, this.state.request[k])}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <i>Loading request...</i>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(Request);
