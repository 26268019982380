/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg width='50px' height='46px' viewBox='0 0 30.5 28'>
        <path
          d='M13.737,19.912v-5.943h1.75c0.394,0.592,1.058,0.952,1.777,0.952c1.179,0,2.136-0.958,2.136-2.137
          c0-1.178-0.957-2.137-2.136-2.137c-0.719,0-1.383,0.359-1.777,0.952h-3.774c-0.191,0-0.347,0.155-0.347,0.347v8.806
          c0,0.09,0.037,0.18,0.102,0.244l6.968,6.688c0.066,0.067,0.153,0.104,0.244,0.104h2.375c0.139,0,0.267-0.084,0.32-0.215
          c0.053-0.129,0.022-0.277-0.076-0.379L13.737,19.912z'
        />
        <path
          d='M30.301,27.402L30.299,0.346c0-0.19-0.157-0.346-0.348-0.346H0.348C0.156,0,0,0.156,0,0.346v27.113
          c0,0.19,0.156,0.346,0.348,0.346h0.441c0.036,0,0.07-0.009,0.104-0.017h1.131c0.191,0,0.348-0.157,0.348-0.35V2.37h25.553v23.34
          l-6.274-5.686h2.595c0.192,0,0.348-0.152,0.348-0.344V5.917c0-0.191-0.156-0.345-0.348-0.345H6.008
          c-0.191,0-0.346,0.154-0.346,0.345v21.521c0,0.192,0.154,0.35,0.346,0.35h1.679c0.191,0,0.347-0.157,0.347-0.35V7.943h14.187
          v9.713h-4.338c-0.191,0-0.345,0.155-0.345,0.347v1.251c0,0.093,0.039,0.186,0.108,0.252l8.666,8.188
          c0.064,0.062,0.149,0.095,0.239,0.095h3.406c0.098,0,0.192-0.043,0.259-0.116C30.28,27.599,30.313,27.499,30.301,27.402z'
        />
      </svg>
    );
  }
}
