import { Weekday } from '../../../../shared/domain/weekday';

type DataExportFrequencyConstruct<T> = Pick<T, Exclude<{
  [K in keyof T]: T[K] extends Function ? never : K
}[keyof T], 'type'>>;

export enum ScheduledDataExportFrequencyType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface ScheduledDataExportFrequency {
  readonly type: ScheduledDataExportFrequencyType;
  readonly timeOfDay: string;
  toNetwork(): { type: string; [key: string]: any };
}

export interface NetworkScheduledDataExportFrequency {
  type: ScheduledDataExportFrequencyType;
}

abstract class BaseScheduledDataExportFrequency<T> implements ScheduledDataExportFrequency {
  abstract readonly type: ScheduledDataExportFrequencyType;
  abstract readonly timeOfDay: string;

  constructor(init: DataExportFrequencyConstruct<T>) {
    Object.assign(this, init);
  }

  abstract toNetwork(): { type: string; [key: string]: any };
}

export class Daily extends BaseScheduledDataExportFrequency<Daily> {
  readonly type: ScheduledDataExportFrequencyType = ScheduledDataExportFrequencyType.Daily;
  readonly timeOfDay: string;

  toNetwork() {
    return {
      type: this.type,
      time_of_day: this.timeOfDay,
    };
  }

  static fromNetwork(init: any): Daily {
    return new Daily({
      timeOfDay: init.time_of_day,
    });
  }
}

export class Weekly extends BaseScheduledDataExportFrequency<Weekly> {
  readonly type: ScheduledDataExportFrequencyType = ScheduledDataExportFrequencyType.Weekly;
  readonly dayOfWeek: Weekday;
  readonly timeOfDay: string;

  toNetwork() {
    return {
      type: this.type,
      day_of_week: this.dayOfWeek,
      time_of_day: this.timeOfDay,
    };
  }

  static fromNetwork(init: any): Weekly {
    return new Weekly({
      dayOfWeek: init.day_of_week,
      timeOfDay: init.time_of_day,
    });
  }
}

export class Monthly extends BaseScheduledDataExportFrequency<Monthly> {
  readonly type: ScheduledDataExportFrequencyType = ScheduledDataExportFrequencyType.Monthly;
  readonly dayOfMonth: number;
  readonly timeOfDay: string;

  toNetwork() {
    return {
      type: this.type,
      day_of_month: this.dayOfMonth,
      time_of_day: this.timeOfDay,
    };
  }

  static fromNetwork(init: any): Monthly {
    return new Monthly({
      dayOfMonth: init.day_of_month,
      timeOfDay: init.time_of_day,
    });
  }
}

export class ScheduledDataExportFrequencyFactory {
  static fromNetwork(init: NetworkScheduledDataExportFrequency): ScheduledDataExportFrequency {
    switch (init.type) {
      case ScheduledDataExportFrequencyType.Daily: return Daily.fromNetwork(init);
      case ScheduledDataExportFrequencyType.Weekly: return Weekly.fromNetwork(init);
      case ScheduledDataExportFrequencyType.Monthly: return Monthly.fromNetwork(init);
    }
  }
}

export const isDailyFrequency = (value: ScheduledDataExportFrequency): value is Daily => value && value.type === ScheduledDataExportFrequencyType.Daily;
export const isWeeklyFrequency = (value: ScheduledDataExportFrequency): value is Weekly => value && value.type === ScheduledDataExportFrequencyType.Weekly;
export const isMonthlyFrequency = (value: ScheduledDataExportFrequency): value is Monthly => value && value.type === ScheduledDataExportFrequencyType.Monthly;
