import { Requestee } from '../../../../../../shared/domain/requestee';
import { utc as moment, Moment } from 'moment';
import { UUID } from '../../../../../../shared/domain/uuid';
import { Construct } from '../../../../../../shared/domain/construct';
import { NetworkAnnuityType } from './network-annuity-type';

export class AnnuityType {
  readonly id: UUID;
  readonly type: AnnuityTypes;
  readonly productModuleId: UUID;
  readonly key: string;
  readonly name: string;
  readonly requiresReview: boolean;
  readonly reviewQuestionnaire?: { [key: string]: any }[];
  readonly createdAt: Moment;
  readonly createdBy: Requestee;

  constructor(init: Construct<AnnuityType>) {
    Object.assign(this, init);
  }

  toNetwork(): NetworkAnnuityType {
    return {
      annuity_type_id: this.id.toString(),
      type: this.type,
      product_module_id: this.productModuleId.toString(),
      key: this.key,
      name: this.name,
      requires_review: this.requiresReview,
      review_questionnaire: this.reviewQuestionnaire,
      created_at: this.createdAt.toISOString(),
      created_by: this.createdBy.toJSON(),
    };
  }

  static fromNetwork(init: NetworkAnnuityType): AnnuityType {
    return new AnnuityType({
      id: UUID.fromString(init.annuity_type_id),
      type: init.type,
      productModuleId: UUID.fromString(init.product_module_id),
      key: init.key,
      name: init.name,
      requiresReview: init.requires_review,
      reviewQuestionnaire: init.review_questionnaire,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromJSON(init.created_by),
    });
  }
}

export interface AnnuityTypeFrequency {
  type: AnnuityTypeFrequencyType;
  value: number;
}

export enum AnnuityTypeFrequencyType {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum AnnuityTypes {
  Payout = 'payout',
}
