import { observable } from 'mobx';
import { ajax, toCamelCaseKeys } from '../../helpers';

export class OrganizationListStore {
  @observable organizations: any[];
  @observable isLoading: boolean = false;
  constructor() {
    this.getAllOrganizations();
    (window as any).organizationListStore = this;
  }

  getAllOrganizations = async () => {
    this.isLoading = true;
    const response = await ajax({
      path: '/admin/client-apps',
      type: 'GET',
    });

    this.organizations = response.map(toCamelCaseKeys);
    this.isLoading = false;
  };

  updateOrganization = async (clientId: string, data: object) => {
    await ajax({
      path: `/admin/organizations/${clientId}`,
      type: 'PATCH',
      data,
    });
  };

  setOrganizationListStoreIsLoading = () => {
    this.isLoading = true;
  }
}

export const organizationListStore = new OrganizationListStore();
