import React from 'react';
import { Button } from 'reactstrap';
import {
  ajax,
} from '../../../helpers';
import { Payment } from '../../../shared/domain/payment';

interface Props {
  disabled: boolean;
  payment: Payment;
  refresh: () => void;
}

interface State {
  paymentReviewed: boolean;
}

export class ReviewButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paymentReviewed: this.props.payment.reviewedAt ? true : false,
    };
  }

  get text() {
    return this.props.payment.reviewedAt ? 'Checked' : 'Review';
  }

  reviewPayment = async (paymentId: string) => {
    try {
      const payment = await ajax({
        path: `insurance/admin/payments/${paymentId}/review`,
        type: 'PATCH',
      });
      this.setState({ paymentReviewed: true });
      return payment;
    } catch (error) {
      alert(`An error occured trying to review this payment ${error.message}`);
    }
  };

  revokeReviewPayment = async (paymentId: string) => {
    try {
      const payment = await ajax({
        path: `insurance/admin/payments/${paymentId}/revoke-review`,
        type: 'PATCH',
      });
      this.setState({ paymentReviewed: false });

      return payment;
    } catch (error) {
      alert(`An error occured trying to revoke review of this payment ${error.message}`);
    }
  };

  handleClick = async (payment: Payment) => {
    !this.state.paymentReviewed
      ? await this.reviewPayment(payment.paymentId)
      : await this.revokeReviewPayment(payment.paymentId);
    await this.props.refresh();
  };

  render() {
    const { payment } = this.props;

    return (
      <div>
        <Button
          disabled={this.props.disabled}
          color='link'
          style={{
            color: this.props.disabled ? 'grey' : (this.props.payment.reviewedAt ? 'green' : '#007bff'),
            minWidth: 'auto',
            display: 'contents',
          }}
          onClick={() => this.handleClick(payment)}
        >
          {this.text}
        </Button>
      </div>
    );
  }
}
