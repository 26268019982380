import React from 'react';
import { DomainEntityTable } from '../../../../../../components/domain/tables/domain-entity-table';
import { ProductModuleJsonHtml } from '../../../product-module-json-to-html';
import { ProductModuleDefinitionAlterationHook } from '../../../../../domain/product-module-definition-alteration-hooks';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { inject, observer } from 'mobx-react';
import { ProductModuleDefinitionAlterationHooksListStore } from '../../../stores/product-module-definition-alteration-hooks-list-store';

class Table extends DomainEntityTable<ProductModuleDefinitionAlterationHook> { }

interface Props {
  isLoading: boolean;
  productModuleKey: string;
}

interface State {
  showSchema: boolean;
  productModuleDefinitionSchemaId?: string;
}

interface Injected extends Props {
  productModuleStore: ProductModuleStore;
  productModuleDefinitionAlterationHooksListStore: ProductModuleDefinitionAlterationHooksListStore;
}
@inject('productModuleStore', 'productModuleDefinitionAlterationHooksListStore')
@observer
export class AlterationHooksPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSchema: false,
    };
  }

  get injected() {
    return this.props as Injected;
  }

  ActionsElement: React.SFC<ProductModuleDefinitionAlterationHook> = ({ productModuleDefinitionSchema }) => {
    const productModuleDefinitionSchemaId = productModuleDefinitionSchema.productModuleDefinitionSchemaId;

    return <a
      style={{ color: '#4078fa', cursor: 'pointer' }}
      onClick={() => this.setState({ productModuleDefinitionSchemaId, showSchema: true })}>
        Edit schema
    </a>;
  }

  ActionsRow = {
    heading: 'Actions',
    element: this.ActionsElement,
  };

  renderFullScreenModal = () => {
    if (this.state.productModuleDefinitionSchemaId) {
      return (
        <ProductModuleJsonHtml
          productModuleDefinitionSchemaId={this.state.productModuleDefinitionSchemaId}
          toDbKey={'alteration_hooks'}
          breadcrumbDocName={'Alteration hook'}
          productModuleKey={this.props.productModuleKey}
          closeDocument={() =>
            this.setState({ showSchema: !this.state.showSchema })
          }
        />
      );
    }
    return <></>;
  };

  render() {
    const { productModuleDefinitionAlterationHooksListStore } = this.injected;
    const { productModuleDefinitionAlterationHooks } = productModuleDefinitionAlterationHooksListStore;

    return <>
      {this.state.showSchema && this.renderFullScreenModal()}
      <Table
        isLoaded={!this.props.isLoading}
        hover={false}
        columns={[
          AlterationHookNameRow,
          KeyRow,
          this.ActionsRow,
        ]}
        rows={productModuleDefinitionAlterationHooks}
      />
    </>;
  }
}

const AlterationHookElement: React.SFC<ProductModuleDefinitionAlterationHook> = ({ name }) => (
  <span>{name}</span>
);

const AlterationHookNameRow = {
  heading: 'Name',
  element: AlterationHookElement,
};

const KeyElement: React.SFC<ProductModuleDefinitionAlterationHook> = ({ key }) => (
  <span>{key}</span>
);

const KeyRow = {
  heading: 'key',
  element: KeyElement,
};