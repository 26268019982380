export const claim = {
  claim_id: 'd51d8d9e-602a-481d-a5e8-7f458bfee9a9',
  claim_number: '1IW98YNKT1',
  created_at: '2019-01-28T12:06:52.310Z',
  claim_status: 'open',
  claimant: {
    first_name: 'Elrich',
    last_name: 'Groenewald',
    email: 'elrich@root.co.za',
    relationship: 'grandparent',
  },
  approval_status: 'pending',
  policy_id: '98027f30-b47a-487b-a275-7dba64539090',
  policyholder_id: '6d28b30e-cdae-4bca-b220-d1021d08bf11',
  incident_type: 'accidental_death',
  incident_cause: 'stupidity',
  incident_date: '2019-01-28T12:06:51.000Z',
  app_data: null,
  module_data: {
    doctor: {
      name: 'Mark',
    },
    date_of_death: '2019-01-28T12:06:51.000Z',
    incident_date: '2019-01-28T12:06:51.000Z',
    incident_type: 'accidental_death',
    incident_cause: 'negligence',
    police_station: 'Stellenbosch Central',
    member_deceased: 'main_member',
    police_case_number: 'RJLKJDO00023',
    dha_reference_number: 'Wait 8 hours',
  },
  rejection_reason: null,
  payouts: [],
  checklist_items: [
    {
      checklist_item_id: 'a4ba5e49-c48d-444f-894c-8fc05f34e1bb',
      text: 'Are all premiums up to date?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '873cea4f-fa41-4d0d-a2d4-542f401a825c',
      text:
        'Is the incident date or date of death after the inception of cover?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '1a9dcc2d-c895-487e-9c0e-d5d8ce3ba644',
      text:
        'Does the date of death fall outside of the waiting period? If no, was the insured covered under a similar policy prior to the commencement date?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '1bc991f1-7f37-4eb3-bb6f-ef2397e41773',
      text:
        'Is the deceased`s identity and relationship to the main memmber confirmed as true and is the individual specifically covered under the policy?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '83d001e3-2bea-4e72-9822-166cc9d24b6e',
      text:
        'Has the claim been logged within the permitted time period after the claim event?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: 'bf909fa7-8f51-4e40-be04-6a2b584dbe16',
      text:
        'No exclusions, as per the policy terms and conditions, apply on this claim.',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: 'b3ddb0e1-90cc-4650-9ff4-3eb39410ff7d',
      text:
        'Has the insured adhered to the terms and conditions, to the best of your knowledge?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '998524e3-a84f-4b7c-870b-500b157b6e52',
      text:
        'Is the beneficiary correct in terms of the policy terms and conditions?',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '5d68636b-eaa7-4fe1-bdbc-32daa7487dea',
      text: 'Are the beneficiaries` banking details loaded correctly',
      checked: null,
      notes: [],
      events: [],
    },
    {
      checklist_item_id: '89985862-cabe-4bc5-9283-015b3060e756',
      text: 'Have any other claims been logged for the policy?',
      checked: null,
      notes: [],
      events: [],
    },
  ],
  created_by: {
    type: 'api_key',
    id: '92f7bd2c-db58-42d7-b312-0a11514531ff',
    owner_id: 'd8062d32-5031-4fbf-acf3-df89422bd33a',
  },
};
