import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import _ from 'lodash';
import {
  savedBadgeRender,
  savingBadgeRender,
  updateSavingState,
  getTextInputValue,
} from './orchestration';
import {
  ProductModuleComponentLoadingStateStore,
} from '../../stores/product-module-component-loading-state-store';
import { inject, observer } from 'mobx-react';
import { nestedStyleTextInput } from './utils';
import { debounce } from '../../../../../helpers/debounce';
import { ProductModuleStore } from '../../stores/product-module-store';
import { ComponentType } from '../product-module-definitions-billing/components/billing';
import { SavingState } from '../../util';

enum ComparisionType {
  NotIn = 'notIn',
}

interface Params {
  label: string;
  description: string;
  index: number;
  path: string;
  condition: string;
  displayCheckValue: string | number | boolean;
  lockedComponent: boolean;
  key: string;
  indent: number;
  options?: any;
  multipleSelect?: boolean;
  title?: string;
  reset?: [
    {
      path: string;
      default: string | number | boolean | null;
      comparisonValues: any[];
      comparisionType: ComparisionType;
    },
  ];
}

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  params: Params;
  componentType: ComponentType;
}

interface ProductModuleSettingsTextInput {
  [k: string]: any;
}

@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
class ProductModuleSettingsTextInput extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this[this.props.params.key] = null;
  }

  updateTextInput = async (params: Params, value: string | number | null) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    const { key, path, reset } = params;

    updateSavingState(
      key,
      SavingState.Saving,
      productModuleComponentLoadingStateStore,
    );

    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      if (reset) {
        reset.forEach(val => {
          const resetConditionMet = val.comparisonValues.indexOf(value);
          if (resetConditionMet !== -1) {
            _.set(settings, val.path, val.default);
          }
        });
      }
      _.set(settings, path, value);
      clearTimeout(this[params.key]);
      this[params.key] = setTimeout(async () => {
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
        if (productModuleComponentLoadingStateStore) {
          updateSavingState(
            key,
            SavingState.Saved,
            productModuleComponentLoadingStateStore,
          );
          debounce(
            key,
            () =>
              updateSavingState(
                key,
                SavingState.Default,
                productModuleComponentLoadingStateStore,
              ),
            3000,
          );
        }
      }, 1000);
    }
  };

  render() {
    const {
      label,
      lockedComponent,
      description,
      indent,
      key,
      title,
      index,
    } = this.props.params;

    const componentType = this.props.componentType;

    const productModuleStore = this.props.productModuleStore;
    const productModuleComponentLoadingStateStore = this.props
      .productModuleComponentLoadingStateStore;

    const nested = indent ? true : false;

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleSettingsComponents[key];

    return (
      <Row className='product-module-definition-settings-text-input-wrapper'>
        <Col xs={3}>{title}</Col>
        <Col xs={9}>
          <div style={nestedStyleTextInput(nested, index)}>
            <div>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <div className='settings-description-label-div'>
                  <p style={{ fontWeight: 'bold' }}>{label}</p>
                </div>
                {savedBadgeRender(saving)}
                {savingBadgeRender(saving)}
                {description && (
                  <span
                    style={{
                      display: 'flex',
                      marginTop: -7,
                      paddingBottom: 10,
                    }}
                  >
                    {description}
                  </span>
                )}
                <Input
                  onChange={e => {
                    if (componentType === ComponentType.Number) {
                      const targetValue = parseInt(e.target.value);
                      if (isNaN(targetValue)) {
                        this.updateTextInput({ ...this.props.params }, null);
                      } else {
                        this.updateTextInput(
                          { ...this.props.params },
                          targetValue,
                        );
                      }
                    } else {
                      this.updateTextInput(
                        { ...this.props.params },
                        e.target.value,
                      );
                    }
                  }}
                  style={{ width: 300 }}
                  disabled={lockedComponent}
                  type={componentType}
                  defaultValue={getTextInputValue(
                    this.props.params,
                    productModuleStore,
                  )}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ProductModuleSettingsTextInput;
