import * as React from 'react';
import { Input } from 'reactstrap';
import { observer } from 'mobx-react';
import '../styles/text-input/styles.scss';
import _ from 'lodash';

@observer
export class NumberInput extends React.Component<any, any> {
  componentWillUnmount() {
    const { parentKeyLookup, formStore } = this.props;

    if (parentKeyLookup) {
      if (!formStore.modelChildrenHoldState[parentKeyLookup]) {
        this.props.formStore.updateInputState(this.props.keyVal, null);
        const { keyVal, formStore } = this.props;
        formStore.updateValidTotalDirect(keyVal, true);
      }
    } else {
      this.props.formStore.updateInputState(this.props.keyVal, null);
      const { keyVal, formStore } = this.props;
      formStore.updateValidTotalDirect(keyVal, true);
    }
  }

  componentDidMount() {
    const { keyVal, formStore, validators } = this.props;
    formStore.updateValidTotal(
      keyVal,
      formStore.inputState[keyVal],
      validators,
    );
  }

  displayValue = () => {
    const { keyVal, formStore, componentProps } = this.props;
    if (formStore.inputState[keyVal] !== undefined && componentProps.prefix) {
      if (_.startsWith(formStore.inputState[keyVal], componentProps.prefix)) {
        const value = formStore.inputState[keyVal].slice(
          componentProps.prefix.length,
        );
        this.props.formStore.updateInputState(this.props.keyVal, value);
      }
    }
    return formStore.inputState[keyVal] !== undefined ? formStore.inputState[keyVal] : '';
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { keyVal, formStore, validators } = this.props;
    const targetValue = parseInt(e.target.value);

    if (isNaN(targetValue)) {
      this.props.onChange('');
    } else {
      this.props.onChange(targetValue);
    }

    formStore.updateComponentValid(keyVal, validators);
    formStore.updateValidTotalDirect(
      keyVal,
      !!formStore.componentValid[keyVal].validation.valid,
    );
  };

  render() {
    const { keyVal, componentProps, validators, formStore } = this.props;

    formStore.inputState[keyVal] === ''
      ? formStore.updateValidTotal(
        keyVal,
        formStore.inputState[keyVal],
        validators,
      )
      : null;

    return (
      <div className='form-group col-sm-6'>
        <label className='form-label' htmlFor={keyVal}>
          {this.props.label}
        </label>
        <div className='input-group'>
          {componentProps && componentProps.prefix && (
            <div className='input-group-prepend'>
              <span className='input-group-text'>{componentProps.prefix}</span>
            </div>
          )}
          <Input
            {...componentProps}
            className={
              formStore.componentValid[keyVal].validation.valid
                ? ''
                : 'is-invalid'
            }
            type='number'
            key={`${keyVal}`}
            id={`${keyVal}`}
            value={this.displayValue()}
            onBlur={() => {
              formStore.updateComponentValid(keyVal, validators, true);
            }}
            onChange={e => this.onChange(e)}
          />
        </div>
        <div className='text-input-validation'>
          {!formStore.componentValid[keyVal].validation.valid
            ? formStore.componentValid[keyVal].validation.failCondition
            : ''}
        </div>
      </div>
    );
  }
}
