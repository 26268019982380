import React from 'react';
import { toCamelCaseKeys } from '../../helpers';
import { utc as moment, Moment } from 'moment';
import { Input, Table, Row, Col } from 'reactstrap';
import PageHeader from '../../components/page-header';
import ToggleSwitch from '../../components/toggle-switch';
import { PaginationComponent } from '../../components/pagination';
import { History } from 'history';
import { debounce } from '../../helpers/debounce';
import { getPolicies } from './policy/policy-api';

interface Props {
  params: {
    organizationId: string;
  };
  router: History;
  loading: boolean;
}

interface State {
  searchValue: string;
  environment: string;
  policies: any;
  loading: boolean;
  page: number;
  pageSize: number;
  total: number;
  error?: any;
}

const formatPolicyDate = (date: Moment) => {
  return moment(date).format('DD MMM YYYY');
};

const PolicySearchBox = (params: {
  searchValue: string;
  handleSearchChanged: (event: any) => void;
}) => (
  <div className='pull-right'>
    <Input
      name='search'
      placeholder='Search'
      type='text'
      value={params.searchValue}
      onChange={params.handleSearchChanged}
    />
  </div>
);

const PolicyTableHeadings = () => (
  <thead>
    <tr>
      <th>Package name</th>
      <th>Policy number</th>
      <th>Status</th>
      <th>Start date</th>
    </tr>
  </thead>
);

class Policies extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      environment: 'live',
      policies: [],
      loading: false,
      page: 0,
      pageSize: 10,
      total: 0,
      error: undefined,
    };
  }

  componentDidMount() {
    this.fetchPolicies();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.environment !== this.state.environment) {
      this.setState({ policies: [] });
      this.fetchPolicies();
    }
  }

  async fetchPolicies() {
    this.setState({ loading: true });

    const { page, pageSize, environment, searchValue } = this.state;

    try {
      const policies = await getPolicies({
        page: page + 1,
        pageSize,
        environment,
        organizationId: this.props.params.organizationId,
        filters: {
          search: searchValue,
        },
      });

      this.setState({
        policies: policies.response.map(toCamelCaseKeys),
        loading: false,
        total: policies.rowCount,
      });
    } catch (error) {
      console.dir(error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearchChanged = async (event: any) => {
    this.setState({
      searchValue: event.target.value,
    });
    debounce(
      'filterByPolicyNumber',
      async () => await this.fetchPolicies(),
      500,
    );
  };

  get policiesToShow() {
    const { policies } = this.state;

    return policies && policies.length > 0 ? policies : [];
  }

  renderPolicyRow = (policy: any) => (
    <tr
      className='table-row-pointer'
      key={policy.policyId}
      onClick={() =>
        this.props.router.push(
          `/organizations/${this.props.params.organizationId}/policies/${
            policy.policyId
          }?sandbox=${this.state.environment === 'sandbox'}`,
        )
      }
    >
      <td>{policy.packageName}</td>
      <td>{policy.policyNumber}</td>
      <td>{policy.status}</td>
      <td>{formatPolicyDate(policy.startDate)}</td>
    </tr>
  );

  renderSearchInput = () => {
    return (
      <PolicySearchBox
        searchValue={this.state.searchValue}
        handleSearchChanged={this.handleSearchChanged}
      />
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading policies...</i>
        </div>
      );
    }

    const { total, page, pageSize } = this.state;

    return (
      <div>
        <PageHeader title='Policies' rightComponent={this.renderSearchInput()}>
          <Row>
            <Col
              xs={12}
              className='toggle-status-spacer'
              style={{ paddingLeft: 0 }}
            >
              <ToggleSwitch
                id={'environment-toggle'}
                leftText={'LIVE'}
                checked={this.state.environment === 'sandbox'}
                onChange={() => {
                  this.setState((currentState) => ({
                    environment:
                      currentState.environment === 'live' ? 'sandbox' : 'live',
                  }));
                }}
                rightText={'TEST'}
              />
              <em className={'status'}>
                {this.state.loading
                  ? 'Loading...'
                  : this.state.error
                    ? this.state.error
                    : `Viewing ${this.policiesToShow.length} ${
                      this.policiesToShow.length === 1 ? 'policy' : 'policies'
                    } from the ${this.state.environment} environment.`}
              </em>
            </Col>
          </Row>
        </PageHeader>
        <Table hover>
          <PolicyTableHeadings />
          <tbody>{this.policiesToShow.map(this.renderPolicyRow)}</tbody>
        </Table>
        <PaginationComponent
          page={page || 0}
          limit={pageSize}
          total={total || 0}
          disabled={false}
          goToPage={(page) => {
            this.setState(
              {
                page,
              },
              async () => await this.fetchPolicies(),
            );
          }}
        />
      </div>
    );
  }
}

export default Policies;
