import moment, { Moment } from 'moment';
import { Construct } from '../../shared/domain/construct';

export enum ProductModuleCodeRunStatus {
  InProgress = 'in_progress',
  Failed = 'failed',
  Complete = 'complete',
}

export interface NetworkProductModuleCodeRun {
  product_module_code_run_id: string;
  product_module_definition_id: string;
  created_at: string;
  completed_at?: string;
  status: ProductModuleCodeRunStatus;
  function_name: string;
  policy_id: string;
}

export class ProductModuleCodeRun {
  readonly productModuleCodeRunId: string;
  readonly productModuleDefinitionId: string;
  readonly createdAt: Moment;
  readonly completedAt?: Moment;
  readonly status: ProductModuleCodeRunStatus;
  readonly functionName: string;
  readonly policyId: string;

  constructor(init: Construct<ProductModuleCodeRun>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkProductModuleCodeRun): ProductModuleCodeRun {
    return new ProductModuleCodeRun({
      productModuleCodeRunId: init.product_module_code_run_id,
      productModuleDefinitionId: init.product_module_definition_id,
      createdAt: moment(init.created_at),
      completedAt: init.completed_at ? moment(init.completed_at) : undefined,
      status: init.status,
      functionName: init.function_name,
      policyId: init.policy_id,
    });
  }

  prettyStatus() {
    switch (this.status) {
      case ProductModuleCodeRunStatus.Complete:
        return 'Complete';
      case ProductModuleCodeRunStatus.Failed:
        return 'Failed';
      case ProductModuleCodeRunStatus.InProgress:
        return 'In progress';
    }
  }
}
