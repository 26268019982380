import React from 'react';
import { FormGroup, Col } from 'reactstrap';
import { setSingularOrPlural } from '../../../utils';
import { Icon12ArrowRight } from '../../../../../../../../../../components/icons/icon-12-arrow-right';
import '../../../styles/general.scss';

interface Props {
  value: string;
  trigger: string;
  type: string;
  index: number;
}

interface State {}

class WaitingPeriodNotificationsCompletedChild extends React.Component<
Props,
State
> {
  render() {
    let { value, trigger, type } = this.props;

    type = type ? setSingularOrPlural(value, type) + ' ' : '';
    value = value ? value + ' ' : '';
    trigger = trigger ? trigger + '' : '';

    return (
      <div style={{ paddingBottom: 6 }}>
        <FormGroup row style={{ marginBottom: -13 }}>
          <Col md={4} sm={12}>
            <div className='arrow-right-border-fill-lifecycle completed-card-background-color-grey'>
              {this.props.index === 0 ? <Icon12ArrowRight /> : 'OR'}
            </div>
            <h6 className='completed-card-rule-heading'>
              Rule {this.props.index + 1}
            </h6>
          </Col>
          <Col md={8} sm={12} className='completed-card-rule-heading'>
            <p>{`Notify the policyholder ${value}${type}${trigger}.`}</p>
          </Col>
        </FormGroup>
        <hr className='horizontal-line-without-padding-bottom' />
      </div>
    );
  }
}

export default WaitingPeriodNotificationsCompletedChild;
