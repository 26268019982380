import React, { Component } from 'react';
export class SectionHeader extends Component<any, any> {
  render() {
    const { componentProps, title, parentIndex } = this.props;

    const headingTag =
      componentProps && componentProps.headingTag
        ? componentProps.headingTag
        : 'h3';
    const fontWeight = componentProps.fontWeight
      ? componentProps.fontWeight
      : 'normal';

    const indexNumber =
      componentProps &&
      componentProps.indexNumber &&
      (parentIndex !== null && parentIndex !== undefined)
        ? ` ${parentIndex+1}`
        : '';

    switch (headingTag) {
      case 'h1':
        return (
          <div
            style={{
              width: '100%',
              paddingLeft: 15,
              marginTop: 3,
            }}
          >
            <h1
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h1>
          </div>
        );
      case 'h2':
        return (
          <div
            style={{
              width: '100%',
              paddingLeft: 15,
              marginTop: 3,
            }}
          >
            <h2
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h2>
          </div>
        );
      case 'h3':
        return (
          <div
            style={{
              width: '100%',
              paddingLeft: 15,
              marginTop: 3,
            }}
          >
            <h3
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h3>
          </div>
        );

      case 'h4':
        return (
          <div
            style={{
              width: '100%',
              paddingLeft: 15,
              marginTop: 3,
            }}
          >
            <h4
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h4>
          </div>
        );

      case 'h5':
        return (
          <div style={{ width: '100%', paddingLeft: 15, marginTop: 3 }}>
            <h5
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h5>
          </div>
        );
      default:
        return (
          <div
            style={{
              width: '100%',
              paddingLeft: 15,
              marginTop: 3,
            }}
          >
            <h3
              style={{ fontWeight: fontWeight }}
            >{`${title}${indexNumber}`}</h3>
          </div>
        );
    }
  }
}

export default SectionHeader;
