import React from 'react';
import _ from 'lodash';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { StoreIndex, ProductModuleComponentLoadingStateStore } from '../../../stores/product-module-component-loading-state-store';
import { Icon16PXTick } from '../../../../../../components/icons/icon-16-px-tick';
import { SettingsParams } from '..';
import { SavingState } from '../../../util';

export const selectOptionDefault = (
  params: SettingsParams,
  productModuleStore?: ProductModuleStore,
) => {
  const { path } = params;

  if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
    const { settings } = productModuleStore.productModuleDefinitionDraft;
    const settingsItem = _.get(settings, path, null);
    if (Array.isArray(settingsItem)) {
      if (params.options) {
        const defaultOptions = settingsItem.map(setting => {
          return params.options.find((option: any) => option.value === setting);
        });
        if (defaultOptions) {
          return defaultOptions;
        }
      }
    } else {
      if (params.options) {
        const defaultOption = params.options.find(
          (option: any) => option.value === settingsItem,
        );

        if (defaultOption) {
          return defaultOption;
        }
      }
    }
  }
};

export const defaultCheckboxValue = (params: SettingsParams, productModuleStore: ProductModuleStore | undefined) => {
  const { path, displayCheckValue } = params;

  if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
    const { settings } = productModuleStore.productModuleDefinitionDraft;
    const settingsItem = _.get(settings, path, null);
    if (Array.isArray(settingsItem)) {
      return !!settingsItem.find(val => val === displayCheckValue);
    }
    return settingsItem === displayCheckValue;
  }
};

export const getTextInputValue = (params: SettingsParams, productModuleStore: ProductModuleStore | undefined) => {
  const { path } = params;
  if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
    const { settings } = productModuleStore.productModuleDefinitionDraft;
    const settingsItem = _.get(settings, path, null);
    return settingsItem;
  }
};

export const updateSavingState = (key: string, savingState: SavingState, productModuleComponentLoadingStateStore: ProductModuleComponentLoadingStateStore | undefined) => {
  if (productModuleComponentLoadingStateStore) {
    productModuleComponentLoadingStateStore.updateComponentLoadingState(
      StoreIndex.productModuleSettingsComponents,
      key,
      savingState,
    );
  }
};

export const savedBadgeRender = (saving: SavingState) => {
  if (saving === SavingState.Saved) {
    return (
      <div className='product-module-definitions-enable-item-checkbox '>
        <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
          <Icon16PXTick style={{ marginRight: 5, paddingBottom: 3 }} />
          Saved
        </div>
      </div>
    );
  }
};

export const savingBadgeRender = (saving: SavingState) => {
  if (saving === SavingState.Saving) {
    return (
      <div className='product-module-definitions-enable-item-checkbox '>
        <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
          Saving
        </div>
      </div>
    );
  }
};

export const customStyles = {
  container: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
    minWidth: '300px',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '33px',
  }),
  input: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '35px',
    marginTop: '-1px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    minHeight: '1px',
    maxHeight: '33px',
    maxWidth: '31px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    height: '31px',
    borderLeft: '1px solid hsl(0,0%,80%)',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#c3c3c3',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    maxHeight: '31px',
    maxWidth: '31px',
    paddingTop: '0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
  }),
};
