// Case conversion helpers
export const toCamelCase = snakeCase =>
  snakeCase.replace(/(_\w)/g, m => m[1].toUpperCase());

export const toCamelCaseKeys = object =>
  Object.keys(object).reduce((a, k) => {
    let value = object[k];
    if (isPlainObject(value)) {
      value = toCamelCaseKeys(value);
    }
    a[toCamelCase(k)] = value;
    return a;
  }, {});

export const toCamelCaseKeysTopLevelOnly = object =>
  Object.keys(object).reduce((a, k) => {
    const value = object[k];
    a[toCamelCase(k)] = value;
    return a;
  }, {});

export const toSnakeCase = camelCase =>
  camelCase.replace(/[A-Z]/g, s => `_${s.toLowerCase()}`);

export const toSnakeCaseKeys = object =>
  Object.keys(object).reduce((a, k) => {
    let value = object[k];
    if (isPlainObject(value)) {
      value = toSnakeCase(value);
    }
    a[toSnakeCase(k)] = value;
    return a;
  }, {});

export const toSentenceCase = camelCase => {
  const s = camelCase
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1');
  return (s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()).replace(
    '_',
    ' ',
  );
};
export const toTitleCase = camelCase => {
  const s = camelCase
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/([A-Z][a-z])/g, ' $1');
  return s.slice(0, 1).toUpperCase() + s.slice(1);
};
export const toKebabCase = camelCase =>
  camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
export const snakeCaseToKebabCase = snakeCase =>
  snakeCase.replace(/_/g, '-').toLowerCase();

/**
 * Copied from lodash
 */
const isObjectLike = value => {
  return typeof value === 'object' && value !== null;
};

/**
 * Copied from lodash
 */
const isPlainObject = value => {
  if (!isObjectLike(value) || value.toString() !== '[object Object]') {
    return false;
  }
  if (Object.getPrototypeOf(value) === null) {
    return true;
  }
  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(value) === proto;
};
