import React from 'react';
import { Col, Collapse, CardBody, Row, Input, Button } from 'reactstrap';
import '../../../../../../styles/styles.scss';
import moment from 'moment';
import CoolingOffPeriod from './components/cooling-off-period/cooling-off-period';
import { Store, Observables } from './store';
import CoolingOffPeriodCompletedCard from './components/cooling-off-period/cooling-off-period-completed-card';
import WaitingPeriod from './components/waiting-period/waiting-period';
import WaitingPeriodCompletedCard from './components/waiting-period/waiting-period-completed-card';
import GracePeriod from './components/grace-period/grace-period';
import GracePeriodCompletedCard from './components/grace-period/grace-period-completed-card';
import { establishState, savePolicyLifecycle } from './orchestration';
import { observer, inject } from 'mobx-react';
import { ModalContent } from './store-interfaces';
import { ProductModuleDefinition } from '../../../../../../domain/product-module-definition';
import { ProductModuleStateId } from '../../../../product-module-json-to-html/orchestration/constants';
import { ProductModuleStore } from '../../../../stores/product-module-store';
import {
  ProductModuleComponentLoadingStateStore,
  StoreIndex,
} from '../../../../stores/product-module-component-loading-state-store';
import NoProducts from '../../../../no-products';
import { nestedStyleCheckbox } from '../../utils';
import ProductModuleDefinitionSettings from '../..';
import { Icon16PXTick } from '../../../../../../../components/icons/icon-16-px-tick';
import { GeneralModal } from '../../../../modals';
import { debounce } from '../../../../../../../helpers/debounce';
import { SavingState } from '../../../../util';
interface PolicyLifecycleComponent {
  store: Store;
  modalContent: ModalContent;
}
interface State {
  modulesCount: number;
  showSaveAndClose: boolean;
  saved: boolean;
  saving: boolean;
  activeKeyName: string;
  activeDatabaseReferences: Observables[];
  showEditRules: boolean;
  productModuleDefinition?: ProductModuleDefinition;
  productModule: {
    name: string;
  };
  modalContentClearLifecycle: {
    title: string;
    body: string;
    submit: string;
    cancel: string;
  };
  stage: ProductModuleStateId | null;
  userName: string;
  definitionItems: DefinitionItemsOutput[];
  collapse: {
    [k: string]: boolean;
  };
  showSaveModal: boolean;
  productModuleSettings: ProductModuleDefinitionSettings | {};
  editedAt: string;
  showClearLifecycleModal: boolean;
  activeDatabaseKey: string;
}

interface DefinitionItemsOutput {
  name: string;
  mode?: string;
  stage?: string;
  published?: boolean;
  path: string;
  edited: boolean;
  enabled: boolean;
  lastUpdated: string | null;
  databaseIdKey: string;
  reference: Observables[];
  label?: string;
  description?: string;
  keyName: string;
  indent?: number;
  component?: () => any;
  completedCard?: () => any;
}

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleKey: string;
  stage: string;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
}

@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
class PolicyLifecycleComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.store = new Store();

    this.state = {
      showSaveAndClose: false,
      showEditRules: true,
      modalContentClearLifecycle: {
        title: 'Warning!',
        body: 'Are you sure you would like to clear this data?',
        submit: 'Yes',
        cancel: 'Cancel',
      },
      modulesCount: 0,
      saved: true,
      saving: false,
      showSaveModal: false,
      collapse: {},
      productModuleDefinition: undefined,
      productModule: {
        name: '',
      },
      editedAt: '',
      showClearLifecycleModal: false,
      activeDatabaseKey: '',
      activeKeyName: '',
      activeDatabaseReferences: [],
      productModuleSettings: {},
      stage: null,
      userName: '',
      definitionItems: definitionItems(
        (key: string) => this.closeWindow(key),
        this.store,
      ),
    };
  }

  async componentWillMount() {
    const { productModuleStore, productModuleKey, stage } = this.props;
    if (productModuleStore) {
      await productModuleStore.init(productModuleKey);
      const productModule = productModuleStore.productModule;

      let productModuleDefinition: ProductModuleDefinition | undefined =
        productModuleStore.productModuleDefinitionDraft;
      if (stage === 'live') {
        productModuleDefinition =
          productModuleStore.productModuleDefinitionLive;
      }
      const state = await establishState(
        productModule,
        productModuleDefinition,
      );
      if (state) {
        this.setState({
          productModuleSettings: state.productModuleSettings,
          productModule: state.productModule,
          productModuleDefinition: state.productModuleDefinition,
        });
        this.store.loadData(state.productModuleSettings);
      }
    }
  }

  lastTouched = (editedAt: string) => {
    if (editedAt) {
      return (
        <p style={{ position: 'absolute', left: 16, fontSize: 12 }}>
          <i>
            Edited{' '}
            {moment
              .duration(
                moment()
                  .utc()
                  .diff(editedAt),
              )
              .humanize()}{' '}
            ago
          </i>
        </p>
      );
    }
    return '';
  };

  showClearLifecycleModal = (
    activeDatabaseKey: string,
    activeDatabaseReferences: Observables[],
    activeKeyName: string,
  ) => {
    this.setState({
      showClearLifecycleModal: true,
      activeDatabaseKey,
      activeDatabaseReferences,
      activeKeyName,
    });
  };

  submitClearLifecyle = async (reference: Observables[]) => {
    const { productModuleComponentLoadingStateStore } = this.props;
    reference.forEach(value => {
      this.store.resetLifecycleStore(value);
    });

    this.setState({
      modalContentClearLifecycle: {
        ...this.state.modalContentClearLifecycle,
        submit: 'Clearing...',
      },
    });

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        this.state.activeKeyName,
        SavingState.Saving,
      );
    }
    await savePolicyLifecycle(
      this.store.lifecycleStore,
      this.state.productModuleDefinition,
    );

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.policyLifecycleComponents,
        this.state.activeKeyName,
        SavingState.Saved,
      );

      this.setState({
        showClearLifecycleModal: false,
        modalContentClearLifecycle: {
          ...this.state.modalContentClearLifecycle,
          submit: 'Ok',
        },
      });

      debounce(
        this.state.activeKeyName,
        () => {
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.policyLifecycleComponents,
            this.state.activeKeyName,
            SavingState.Default,
          );
          this.setState({
            activeDatabaseKey: '',
            activeKeyName: '',
            activeDatabaseReferences: [],
          });
        },
        2000,
      );
    }
  };

  closeClearLifecycleModal = () => {
    this.setState({
      showClearLifecycleModal: false,
      activeDatabaseKey: '',
      activeKeyName: '',
      activeDatabaseReferences: [],
    });
  };

  render() {
    const {
      productModuleDefinition,
      definitionItems,
      modulesCount,
    } = this.state;
    return (
      !this.store.loading &&
      this.props.productModuleComponentLoadingStateStore && (
        <div>
          <GeneralModal
            show={this.state.showClearLifecycleModal}
            onClose={this.closeClearLifecycleModal}
            submitModal={() =>
              this.submitClearLifecyle(this.state.activeDatabaseReferences)
            }
            modalContent={this.state.modalContentClearLifecycle}
          />
          {modulesCount !== 0 && <NoProducts />}
          {definitionItems.length > 0 &&
            productModuleDefinition &&
            productModuleDefinition.productModuleId &&
            this.definitionItems(
              definitionItems,
              this.props.productModuleComponentLoadingStateStore,
            )}
        </div>
      )
    );
  }

  closeWindow = (key: string) => {
    this.editRules(key);
  };

  editRules = (databaseIdKey: string) => {
    const collapse = this.state.collapse;

    collapse[databaseIdKey] = this.state.collapse[`${databaseIdKey}`]
      ? !this.state.collapse[`${databaseIdKey}`]
      : true;

    for (const key in collapse) {
      if (key !== databaseIdKey) {
        collapse[key] = false;
      }
    }
    this.setState({ collapse, saved: false });
    this.showEditRules(collapse);
  };

  showEditRules = (collapase: { [k: string]: boolean }) => {
    const showEditRules = Object.keys(collapase).find(
      (key: string) => collapase[key] === true,
    );
    this.setState({ showEditRules: showEditRules ? false : true });
  };

  definitionItems = (
    definitionItems: DefinitionItemsOutput[],
    productModuleComponentLoadingStateStore: ProductModuleComponentLoadingStateStore,
  ) => {
    return definitionItems.map((item: DefinitionItemsOutput, index: number) => {
      let collapsed = true;
      let storeCheck = false;

      if (this.store && item.reference) {
        item.reference.forEach((reference: Observables) => {
          if (
            this.store.lifecycleStore[reference] &&
            Object.keys(this.store.lifecycleStore[reference]).length > 0
          ) {
            storeCheck = true;
            return;
          }
        });
      }
      collapsed = !!this.state.collapse[`${item.databaseIdKey}`];

      const savingComponentStatus =
        productModuleComponentLoadingStateStore.policyLifecycleComponents &&
        productModuleComponentLoadingStateStore.policyLifecycleComponents[
          item.keyName
        ];

      const { productModuleStore } = this.props;
      let lockedComponent = false;
      if (productModuleStore) {
        lockedComponent = productModuleStore.lockedComponent;
      }

      return (
        <div key={index}>
          <CardBody className='product-module-definition-horizontal-card'>
            <Row>
              <Col xs={3}>
                <p className='product-module-definition-key-header product-module-definiton-lifecycle-heading'>
                  {item.name}
                </p>
              </Col>
              <Col xs={9}>
                <div>
                  <div>
                    <div
                      style={{
                        verticalAlign: 'middle',
                        display: 'inline-block',
                      }}
                    >
                      {item.enabled && (
                        <Input
                          type='checkbox'
                          disabled={
                            lockedComponent || !!this.state.activeKeyName
                          }
                          checked={storeCheck || collapsed}
                          onClick={() => {
                            if (!storeCheck && !collapsed) {
                              this.editRules(item.databaseIdKey);
                            } else {
                              this.showClearLifecycleModal(
                                item.databaseIdKey,
                                item.reference,
                                item.keyName,
                              );
                            }
                          }}
                        />
                      )}
                      {!item.enabled && (
                        <a className='preview-button-disabled'>Preview</a>
                      )}
                    </div>
                    <p
                      className='product-module-definiton-lifecycle-heading'
                      style={{
                        display: 'inline-block',
                        paddingRight: 10,
                        verticalAlign: 'middle',
                        marginTop: -2,
                      }}
                    >
                      {item.label}
                    </p>
                    {savingComponentStatus === SavingState.Saving && (
                      <div className='arrow-right-border-fill data-saving-badge inline-div-with-padding-left-right'>
                        Saving
                      </div>
                    )}
                    {savingComponentStatus === SavingState.Saved && (
                      <div className='arrow-right-border-fill data-saved-badge inline-div-with-padding-left-right'>
                        <Icon16PXTick
                          style={{ marginRight: 5, paddingBottom: 3 }}
                        />
                        Saved
                      </div>
                    )}
                    <div className='timestamp-parent'>
                      {item.published ? '' : ''}
                    </div>
                  </div>
                  {!collapsed &&
                    storeCheck &&
                    item.completedCard &&
                    item.completedCard()}
                  <Collapse isOpen={collapsed}>
                    {item.component &&
                      collapsed &&
                      item.completedCard &&
                      item.component()}
                  </Collapse>
                  {item.enabled && storeCheck && !collapsed && (
                    <CardBody style={{ ...nestedStyleCheckbox(true, 0) }}>
                      <Button
                        outline
                        disabled={lockedComponent}
                        color='primary'
                        style={{ marginLeft: 0, marginBottom: 0 }}
                        onClick={() => this.editRules(item.databaseIdKey)}
                      >
                        Edit
                      </Button>
                    </CardBody>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
          <hr className='hr-with-no-margin-bottom-top' />
        </div>
      );
    });
  };
}

export default PolicyLifecycleComponent;

const definitionItems = (
  closeWindow: (key: string) => void,
  store: Store,
): DefinitionItemsOutput[] => {
  return [
    {
      name: 'Cooling-off period',
      published: false,
      path: 'cooling-off-period',
      edited: false,
      enabled: true,
      lastUpdated: null,
      label: 'A cooling off period is applied to policies',
      description:
        'For an initial period the policyholder can cancel the policy and receive a refund.',
      indent: 0,
      databaseIdKey: 'cooling_off_period_id',
      keyName: 'coolingOffPeriod',
      reference: [Observables.coolingOffPeriodRules],
      // eslint-disable-next-line react/display-name
      component: () => (
        <CoolingOffPeriod
          store={store}
          closeWindow={() => closeWindow('cooling_off_period_id')}
        />
      ),
      // eslint-disable-next-line react/display-name
      completedCard: () => (
        <CoolingOffPeriodCompletedCard
          coolingOffPeriodRules={store.lifecycleStore.coolingOffPeriodRules}
        />
      ),
    },
    {
      name: 'Waiting period',
      published: false,
      path: 'waiting-period',
      edited: false,
      enabled: true,
      lastUpdated: null,
      label: 'Apply a waiting period to policies',
      description:
        'For an initial period after a policy is issued, certain claims will be invalid.',
      indent: 0,
      databaseIdKey: 'waiting_period_id',
      keyName: 'waitingPeriod',
      reference: [
        Observables.waitingPeriodRules,
        Observables.waitingPeriodNotifications,
      ],
      // eslint-disable-next-line react/display-name
      component: () => (
        <WaitingPeriod
          store={store}
          closeWindow={() => closeWindow('waiting_period_id')}
        />
      ),
      // eslint-disable-next-line react/display-name
      completedCard: () => (
        <WaitingPeriodCompletedCard
          waitingPeriodRules={store.lifecycleStore.waitingPeriodRules}
          waitingPeriodNotifications={
            store.lifecycleStore.waitingPeriodNotifications
          }
        />
      ),
    },
    {
      name: 'Grace period',
      published: false,
      path: 'grace-period',
      edited: false,
      enabled: true,
      lastUpdated: null,
      label: 'Apply a grace period to policies',
      description:
        'Under certain conditions a policy will be lapsed after non-payment of a premium. The following rules define the period of leniency after a premium is missed.',
      indent: 0,
      reference: [Observables.gracePeriodRules],
      keyName: 'gracePeriod',
      databaseIdKey: 'grace_period_id',
      // eslint-disable-next-line react/display-name
      component: () => (
        <GracePeriod
          store={store}
          closeWindow={() => closeWindow('grace_period_id')}
        />
      ),
      // eslint-disable-next-line react/display-name
      completedCard: () => (
        <GracePeriodCompletedCard
          gracePeriodRules={store.lifecycleStore.gracePeriodRules}
        />
      ),
    },
    // {
    //   name: 'Renewal',
    //   published: false,
    //   path: 'renewal',
    //   edited: false,
    //   enabled: true,
    //   lastUpdated: null,
    //   reference: [Observables.renewalRules],
    //   databaseIdKey: 'renewal_id',
    // },
    // {
    //   name: 'Premium escalation',
    //   published: false,
    //   path: 'premium-escalation',
    //   edited: false,
    //   enabled: true,
    //   lastUpdated: null,
    //   reference: [Observables.premiumEscalationRules],
    //   databaseIdKey: 'premium_escalation_id',
    // },
    // {
    //   name: 'Expiry rules',
    //   published: false,
    //   path: 'expiry-rules',
    //   edited: false,
    //   enabled: true,
    //   lastUpdated: null,
    //   reference: [Observables.expiryRules],
    //   databaseIdKey: 'expiry_rules_id',
    // },
  ];
};
