import React from 'react';
import { Button } from 'reactstrap';

export const TeamMemberRow = ({user, editUserDetails, onEditClick, onRemove}) => (
  <tr key={user.userId} className = 'table-row-pointer'>
    <td onClick={() => editUserDetails(user.userId)}>
      {user.firstName} {user.lastName}
    </td>
    <td onClick={() => editUserDetails(user.userId)}>{user.email}</td>
    <td onClick={() => editUserDetails(user.userId)}>
      {user.isOwner ? 'Owner' : user.organizationRoleName}
    </td>
    <td>
      <Button color='link' onClick={onEditClick} disabled={user.isOwner}>
            Edit role
      </Button>
    </td>
    <td>
      <Button
        color='link'
        className='danger'
        disabled={user.isOwner}
        onClick={() => onRemove(user)}
      >
            Remove
      </Button>
    </td>
  </tr>
);
