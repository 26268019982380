/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        width='240px'
        height='140px'
        viewBox='0 0 240 140'
        enableBackground='new 0 0 240 140'
        xmlSpace='preserve'
      >
        <circle fill='#CAE0FF' cx='120' cy='70.001' r='40' />
        <g>
          <path
            fill='#202B40'
            d='M127.808,96.79c-0.106,0-0.241-0.025-0.348-0.054c-4.259-1.179-7.046-2.758-9.965-5.626
        c-3.752-3.723-5.813-8.679-5.813-13.983c0-4.34,3.697-7.875,8.251-7.875c4.555,0,8.252,3.535,8.252,7.875
        c0,2.867,2.491,5.197,5.572,5.197c3.08,0,5.573-2.33,5.573-5.197c0-10.1-8.707-18.298-19.424-18.298
        c-7.608,0-14.573,4.233-17.708,10.796c-1.044,2.17-1.58,4.716-1.58,7.502c0,2.09,0.186,5.385,1.795,9.671
        c0.266,0.695-0.082,1.473-0.778,1.715c-0.697,0.267-1.473-0.106-1.715-0.777c-1.312-3.51-1.955-6.992-1.955-10.608
        c0-3.215,0.615-6.134,1.82-8.681c3.563-7.475,11.467-12.324,20.121-12.324c12.191,0,22.102,9.404,22.102,20.978
        c0,4.34-3.697,7.875-8.251,7.875c-4.555,0-8.251-3.535-8.251-7.875c0-2.866-2.492-5.198-5.573-5.198
        c-3.082,0-5.573,2.332-5.573,5.198c0,4.582,1.77,8.867,5.011,12.083c2.544,2.519,4.983,3.911,8.759,4.955
        c0.724,0.188,1.125,0.938,0.938,1.636C128.935,96.389,128.372,96.79,127.808,96.79z M133.194,91.271
        c-3.188,0-6.001-0.803-8.306-2.385c-3.992-2.705-6.376-7.1-6.376-11.76c0-0.75,0.589-1.339,1.339-1.339s1.34,0.589,1.34,1.339
        c0,3.777,1.928,7.34,5.197,9.538c1.903,1.286,4.127,1.901,6.806,1.901c0.643,0,1.715-0.081,2.786-0.268
        c0.724-0.135,1.42,0.348,1.554,1.098c0.135,0.723-0.35,1.42-1.099,1.554C134.909,91.245,133.569,91.271,133.194,91.271z
         M113.985,96.229c-0.348,0-0.698-0.134-0.938-0.401c-2.332-2.332-3.589-3.833-5.385-7.073c-1.85-3.296-2.812-7.314-2.812-11.627
        c0-7.957,6.803-14.44,15.162-14.44c8.359,0,15.164,6.483,15.164,14.44c0,0.75-0.589,1.34-1.34,1.34c-0.75,0-1.34-0.59-1.34-1.34
        c0-6.483-5.6-11.761-12.484-11.761s-12.483,5.277-12.483,11.761c0,3.858,0.856,7.421,2.491,10.313
        c1.713,3.082,2.894,4.394,4.957,6.483c0.508,0.535,0.508,1.367,0,1.903C114.683,96.095,114.333,96.229,113.985,96.229z
         M97.241,63.893c-0.268,0-0.535-0.08-0.777-0.242c-0.616-0.428-0.75-1.258-0.32-1.874c2.651-3.751,6.027-6.698,10.045-8.76
        c8.412-4.34,19.182-4.368,27.621-0.027c4.019,2.062,7.394,4.982,10.046,8.707c0.43,0.589,0.296,1.446-0.321,1.876
        c-0.615,0.426-1.447,0.294-1.875-0.323c-2.411-3.375-5.466-6.027-9.083-7.876c-7.688-3.938-17.521-3.938-25.183,0.027
        c-3.643,1.876-6.697,4.555-9.108,7.931C98.072,63.704,97.669,63.893,97.241,63.893z M135.579,49.827
        c-0.214,0-0.43-0.053-0.616-0.16c-5.146-2.653-9.592-3.777-14.922-3.777c-5.306,0-10.342,1.26-14.924,3.777
        c-0.643,0.349-1.446,0.107-1.82-0.536c-0.35-0.642-0.108-1.474,0.534-1.822c4.983-2.705,10.448-4.099,16.209-4.099
        c5.706,0,10.689,1.261,16.154,4.073c0.671,0.348,0.912,1.152,0.563,1.795C136.516,49.56,136.061,49.827,135.579,49.827z'
          />
        </g>
      </svg>
    );
  }
}
