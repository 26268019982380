import React from 'react';
import { Alert, Card, CardBody, CardHeader } from 'reactstrap';
import { toCamelCaseKeys } from '../../../helpers';
import ajax from '../../../helpers/ajax';
import PaymentsTable from '../payments-table';
import { PaginationComponent } from '../../../components/pagination';

export default class PaymentBatchPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: null,
      loading: true,
      page: 0,
      pageSize: 30,
    };
  }

  get paymentBatchId() {
    return this.props.router.params.paymentBatchId;
  }

  componentDidMount() {
    this.fetchPayments();
  }

  componentWillReceiveProps() {
    this.fetchPayments();
  }

  async fetchPayments() {
    this.setState({ loading: true });
    const queryString = 'include=payment_method,policy,billing_provider';

    const total = (await ajax({
      type: 'HEAD',
      path: `/insurance/admin/payment-batches/${
        this.paymentBatchId
      }/payments?${queryString}`,
      raw: true,
    }));

    const pagination = `&page=${this.state.page + 1}&page_size=${this.state.pageSize}`;

    const payments = (await ajax({
      type: 'GET',
      path: `/insurance/admin/payment-batches/${
        this.paymentBatchId
      }/payments?${queryString}${pagination}`,
    })).map(toCamelCaseKeys);

    this.setState({ payments, loading: false, total: total.headers.get('X-Total-Count')});
  }

  render() {
    const {payments, loading, error, page, pageSize, total} = this.state;

    return (
      <div className='bottom-padded'>
        <Card>
          {loading ? (
            <CardHeader>Payment batch payments</CardHeader>
          ) : <CardHeader>{`Payment batch payments - ${payments.length} (${payments.filter(p => p.reviewedAt !== null).length})`}</CardHeader>}
          <CardBody>
            {error && (
              <Alert color='danger'>{error}</Alert>
            )}
            {loading ? (
              <div>Loading...</div>
            ) : payments &&payments.length > 0 ? (
              <div>
                <PaymentsTable
                  payments={payments}
                  refresh={this.fetchPayments.bind(this)}
                />
                <PaginationComponent
                  page={page || 0}
                  limit={pageSize}
                  total={total || 0}
                  disabled={false}
                  goToPage={page => {
                    this.setState({
                      page,
                    }, async () => await this.fetchPayments());
                  }}
                />
              </div>
            ) : (
              <div>{'No payments'}</div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}
