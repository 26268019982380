import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem, Row } from 'reactstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { History } from 'history';

const BreadcrumbContext = React.createContext({});
export const BreadcrumbProvider = BreadcrumbContext.Provider;
export const BreadcrumbConsumer = BreadcrumbContext.Consumer;

interface BreadcrumbMenuProps {
  router: History;
  replaceValues: object;
}

interface BreadcrumbMenuState {}

export default class BreadcrumbMenu extends Component<BreadcrumbMenuProps, BreadcrumbMenuState> {

  render() {
    const { router } = this.props;
    const aItems = getItems({ path: router.location.pathname });
    return (
      <Row>
        <div>
          <Breadcrumb tag='nav' listTag='div'>
            {aItems.map((item, i) => {
              return (
                <IndexLinkContainer key={`BreadcrumbItem_${i}`} to={item.url}>
                  <BreadcrumbItem tag='a'>
                    <BreadcrumbConsumer>
                      {breadcrumbContext =>
                        getItemName({ part: item.name, context: breadcrumbContext })
                      }
                    </BreadcrumbConsumer>
                  </BreadcrumbItem>
                </IndexLinkContainer>
              );
            })}
          </Breadcrumb>
        </div>
      </Row>
    );
  }
}


const buildPath = (params: { pathParts: string[]; partCount: number }) => {
  const { partCount, pathParts } = params;
  return '/' + pathParts.slice(0, partCount + 1).join('/');
};

const getItems = (params: { path: string }) => {
  const { path } = params;
  const aPathParts = path.split('/').filter(p => p.length > 0);
  return aPathParts.map((part, i) => {
    return { name: part || part, url: buildPath({ pathParts: aPathParts, partCount: i })};
  });
};

const getItemName = (params: { part: string; context: object }) => {
  const { part, context } = params;

  return (context as any)[part] || part;
};
