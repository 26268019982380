export const getRandValue = amount =>
  `${(amount / 100.0)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;

export const getCentValue = randValue => {
  if (typeof randValue === 'string') {
    const cent = (parseFloat(randValue).toFixed(2) * 100).toFixed(0);
    return parseInt(cent, 10);
  }
  return parseInt((randValue * 100).toFixed(0), 10);
};

export const formatReadableAmount = stringValue => {
  const totalCents = getCentValue(stringValue);
  if (isNaN(totalCents)) {
    return '';
  }
  const onlyCents = ((totalCents % 100) + 100).toString().slice(-2);
  const integer = Math.floor(totalCents / 100);
  const reversed = integer
    .toString()
    .split('')
    .reverse()
    .join('');
  const comma = reversed
    .replace(/(\d{3})/g, '$1 ')
    .trim()
    .replace(/\s/g, ',');
  const normalComma = comma
    .split('')
    .reverse()
    .join('');
  if (onlyCents === '00') {
    return `${normalComma} Rand.`;
  }
  return `${normalComma} Rand, ${onlyCents} cents.`;
};

export const isInvalidAmountEntry = stringValue => {
  if (stringValue.split('.').length - 1 > 1) {
    return 'Numbers can only have one period.';
  }
  if (isNaN(Number(stringValue))) {
    return 'Only numbers and a period allowed.';
  }
  if (stringValue.split('.').length > 1) {
    const decimals = stringValue.split('.')[1].length;
    if (decimals > 2) {
      return 'Cents are only two digits.';
    }
  }
  return false;
};
