import React from 'react';
import { ProductModuleDefinitionScheduledFunction } from '../../../../../domain/product-module-definition-scheduled-function';
import { DomainEntityTable } from '../../../../../../components/domain/tables/domain-entity-table';
import { sentenceCase } from 'sentence-case';

class Table extends DomainEntityTable<ProductModuleDefinitionScheduledFunction> {}

const FunctionNameElement: React.SFC<ProductModuleDefinitionScheduledFunction> = (scheduledFunction) => (
  <span className='scheduled-code-function-name'>{scheduledFunction.functionName}</span>
);

const FunctionNameRow = {
  heading: 'Function name',
  element: FunctionNameElement,
};

const FrequencyElement: React.SFC<ProductModuleDefinitionScheduledFunction> = (scheduledFunction) => (
  <span>{scheduledFunction.prettyFrequency()}</span>
);

const FrequencyRow = {
  heading: 'Frequency',
  element: FrequencyElement,
};

const PolicyStatusElement: React.SFC<ProductModuleDefinitionScheduledFunction> = (scheduledFunction) =>
  <div>{
    scheduledFunction.policyStatuses
      .map((s, i) => <span key={i} className='scheduled-code-function-name'>{sentenceCase(s)}</span>)
      .reduce((acc, x) => acc === null ? x : <>{acc}, {x}</>, null)
  }</div>;

const PolicyStatusRow = {
  heading: 'Policy statuses',
  element: PolicyStatusElement,
};

interface Props {
  scheduledFunctions: ProductModuleDefinitionScheduledFunction[];
  isLoading: boolean;
}

export class ScheduledFunctionPreview extends React.Component<Props> {
  render() {
    return <Table
      isLoaded={!this.props.isLoading}
      hover={false}
      columns={[
        FunctionNameRow,
        PolicyStatusRow,
        FrequencyRow,
      ]}
      rows={this.props.scheduledFunctions}
    />;
  }
}
