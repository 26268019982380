import { observable, action } from 'mobx';

export interface ConsoleData {
  timeStamp: string;
  type: string;
  description: string;
}

export class ProductModuleCodeConsole {
  @observable.deep consoleData: ConsoleData[] = [];
  @observable responseData: any = '';
  @observable isLoadingResponse: boolean = false;
  @observable isLoadingRequest: boolean = false;

  @action updateConsoleData = (dataItem: ConsoleData) => {
    this.consoleData.push(dataItem);
  };

  updateIsLoadingResponse = (value: boolean) => {
    this.isLoadingResponse = value;
  };

  updateIsLoadingRequest = (value: boolean) => {
    this.isLoadingRequest = value;
  };

  @action updateResponseData = (dataItem: string) => {
    this.responseData = dataItem;
  };
}

export const productModuleCodeConsole = new ProductModuleCodeConsole();
