import { Block } from './block';
import { Construct } from '../../../../../../shared/domain/construct';

export class DropdownBlock extends Block {
  readonly title: string;
  readonly defaultValue?: string;
  readonly description?: string;
  readonly options: {
    key: string;
    value: string;
  }[];

  constructor(init: Construct<DropdownBlock>) {
    super();
    Object.assign(this, init);
  }

  toJSON() {
    return {
      type: this.type,
      key: this.key,
      title: this.title,
      description: this.description,
      default_value: this.defaultValue,
      options: this.options,
    };
  }

  static fromJSON(init: any) {
    return new DropdownBlock({
      type: init.type,
      key: init.key,
      title: init.title,
      description: init.description,
      defaultValue: init.default_value,
      options: init.options,
    });
  }
}
