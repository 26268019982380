import moment, { Moment } from 'moment';
import { Environment } from '../../../../shared/domain/environment';
import { Requestee } from '../../../../shared/domain/requestee';
import { ExportType } from './export-type';
import { weekdayToNumber } from '../../../../shared/domain/weekday';
import { intToOrdinalNumberString } from '../../../../shared/helpers/numbers';
import { Construct } from '../../../../shared/domain/construct';
import { NetworkScheduledDataExportFrequency, ScheduledDataExportFrequency, ScheduledDataExportFrequencyFactory, ScheduledDataExportFrequencyType, Weekly, Monthly, Daily } from './scheduled-data-export-frequency';
import { DataExportDataRange } from './data-export-data-range';
import { DataExportAdapterFactory, ScheduledDataExportAdapter, NetworkScheduledDataExportAdapter } from './scheduled-data-export-adapter';
import { NetworkDataExportRun, DataExportRun } from './data-export-run';

export interface NetworkScheduledDataExport {
  scheduled_data_export_id: string;
  environment: Environment;
  created_at: string;
  created_by: object;
  status: ScheduledDataExportStatus;
  name: string;
  frequency: NetworkScheduledDataExportFrequency;
  export_type: ExportType;
  latest_run?: NetworkDataExportRun;
  data_range: DataExportDataRange;
  adapter: NetworkScheduledDataExportAdapter;
  template_id?: string;
  restricted: boolean;
}

export enum ScheduledDataExportType {
  Scheduled = 'scheduled',
  OnceOff = 'once_off',
}

export enum ScheduledDataExportStatus {
  Active = 'active',
  Paused = 'paused',
  Archived = 'archived',
}

export class ScheduledDataExport {
  readonly scheduledDataExportId: string;
  readonly environment: Environment;
  readonly createdAt: Moment;
  readonly createdBy: Requestee;
  readonly status: ScheduledDataExportStatus;
  readonly name: string;
  readonly frequency: ScheduledDataExportFrequency;
  readonly exportType: ExportType;
  readonly latestRun?: DataExportRun;
  readonly dataRange: DataExportDataRange;
  readonly adapter: ScheduledDataExportAdapter;
  readonly templateId?: string;
  readonly restricted: boolean;

  constructor(init: Construct<ScheduledDataExport>) {
    Object.assign(this, init);
  }

  static fromNetwork(init: NetworkScheduledDataExport): ScheduledDataExport {
    return new ScheduledDataExport({
      scheduledDataExportId: init.scheduled_data_export_id,
      environment: init.environment,
      createdAt: moment(init.created_at),
      createdBy: Requestee.fromNetwork(init.created_by),
      status: init.status,
      name: init.name,
      exportType: init.export_type,
      frequency: ScheduledDataExportFrequencyFactory.fromNetwork(init.frequency),
      latestRun: init.latest_run && DataExportRun.fromNetwork(init.latest_run),
      dataRange: init.data_range,
      adapter: DataExportAdapterFactory.fromNetwork(init.adapter),
      templateId: init.template_id,
      restricted: init.restricted,
    });
  }

  prettyEnvironment() {
    if (this.environment === Environment.Production) {
      return 'Production';
    }

    return 'Sandbox';
  }

  prettyExportType() {
    switch (this.exportType) {
      case ExportType.PolicyExport: return 'Policy export';
      case ExportType.PolicyholderExport: return 'Policyholder export';
      case ExportType.ClaimExport: return 'Claim export';
      case ExportType.ComplaintExport: return 'Complaint export';
      case ExportType.CustomExport: return 'Custom export';
    }

    return '';
  }

  prettyFrequencyType() {
    switch (this.frequency.type) {
      case ScheduledDataExportFrequencyType.Daily: return 'Daily';
      case ScheduledDataExportFrequencyType.Weekly: return 'Weekly';
      case ScheduledDataExportFrequencyType.Monthly: return 'Monthly';
    }

    return '';
  }

  prettyFrequency() {
    if (this.frequency instanceof Daily) {
      return `Daily at ${this.frequency.timeOfDay} UTC`;
    } else if (this.frequency instanceof Weekly) {
      return `Every ${moment.utc().day(this.frequency.dayOfWeek).format('dddd')} at ${this.frequency.timeOfDay} UTC`;
    } else if (this.frequency instanceof Monthly) {
      return `On the ${intToOrdinalNumberString(this.frequency.dayOfMonth)} of every month at ${this.frequency.timeOfDay} UTC`;
    }

    return '';
  }

  prettyDataRange() {
    switch (this.dataRange) {
      case DataExportDataRange.Today: return 'Today';
      case DataExportDataRange.WeekToDate: return 'Week to date';
      case DataExportDataRange.MonthToDate: return 'Month to date';
      case DataExportDataRange.SinceLastRun: return 'Since last run';
      case DataExportDataRange.Full: return 'Full';
    }
  }

  prettyNextRun() {
    const now = moment.utc();
    let date = moment.utc();

    if (this.frequency instanceof Daily) {
      date = moment.utc(this.frequency.timeOfDay, 'HH:mm');

      if (now.isAfter(date)) {
        date = date.add(1, 'day');
      }
    } else if (this.frequency instanceof Weekly) {
      date = moment.utc(this.frequency.timeOfDay, 'HH:mm').day(weekdayToNumber(this.frequency.dayOfWeek));

      if (now.isAfter(date)) {
        date = date.add(1, 'week');
      }
    } else if (this.frequency instanceof Monthly) {
      date = moment.utc(this.frequency.timeOfDay, 'HH:mm').date(this.frequency.dayOfMonth);
    }

    if (now.isSame(date, 'day') && now.isBefore(date)) {
      return `Today at ${date.format('HH:mm')} UTC`;
    } else if (date.diff(now, 'days') <= 1) {
      return `Tomorrow at ${date.format('HH:mm')} UTC`;
    } else {
      return `${date.format('DD MMM YYYY')} at ${date.format('HH:mm')} UTC`;
    }
  }
}
