import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { nestedStyleCheckbox } from '../../product-module-definition-settings/utils';
import _ from 'lodash';
import {
  savedBadgeRender,
  savingBadgeRender,
  updateSavingState,
  getTextInputValue,
} from '../orchestration';
import {
  StoreIndex,
  ProductModuleComponentLoadingStateStore,
} from '../../../stores/product-module-component-loading-state-store';
import { debounce } from '../../../../../../helpers/debounce';
import { TypesOfBillingConditions } from '../config/billing';
import { ProductModuleStore } from '../../../stores/product-module-store';
import { BillingParams, ComponentType } from './billing';
import { inject, observer } from 'mobx-react';
import { SavingState } from '../../../util';

interface Props {
  productModuleStore?: ProductModuleStore;
  productModuleComponentLoadingStateStore?: ProductModuleComponentLoadingStateStore;
  params: BillingParams;
  componentType: ComponentType;
}

let timeout: any = null;

@inject('productModuleComponentLoadingStateStore')
@inject('productModuleStore')
@observer
export default class ProductModuleSettingsTextInput extends React.Component<
Props,
any
> {
  constructor(props: Props) {
    super(props);
  }

  onCheckBoxChange = async (
    path: string,
    condition: TypesOfBillingConditions,
    value: string | number | boolean | null,
    key: string,
  ) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleBillingComponents,
        key,
        SavingState.Saving,
      );
    }
    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      let settingsItem = _.get(settings, path, null);
      if (Array.isArray(settingsItem)) {
        const settingsItemValue = settingsItem.indexOf(value);
        settingsItemValue !== -1
          ? settingsItem.splice(settingsItemValue, 1)
          : settingsItem.push(value);
        _.set(settings, path, settingsItem);
      } else {
        settingsItem = !settingsItem;
        _.set(settings, path, settingsItem);
      }
      await productModuleStore.createProductModuleDefinition({
        productModuleId: productModuleStore.productModule.productModuleId,
        data: { settings_json: settings },
      });
    }
    if (productModuleComponentLoadingStateStore) {
      productModuleComponentLoadingStateStore.updateComponentLoadingState(
        StoreIndex.productModuleBillingComponents,
        key,
        SavingState.Saved,
      );

      debounce(
        key,
        () =>
          productModuleComponentLoadingStateStore.updateComponentLoadingState(
            StoreIndex.productModuleBillingComponents,
            key,
            SavingState.Default,
          ),
        3000,
      );
    }
  };

  updateTextInput = async (
    params: BillingParams,
    value: string | number | null,
  ) => {
    const {
      productModuleStore,
      productModuleComponentLoadingStateStore,
    } = this.props;

    const { key, path, reset } = params;

    updateSavingState(
      key,
      SavingState.Saving,
      productModuleComponentLoadingStateStore,
    );

    if (productModuleStore && productModuleStore.productModuleDefinitionDraft) {
      const { settings } = productModuleStore.productModuleDefinitionDraft;
      if (reset) {
        reset.forEach(val => {
          const resetConditionMet = val.comparisonValues.indexOf(value);
          if (resetConditionMet !== -1) {
            _.set(settings, val.path, val.default);
          }
        });
      }
      _.set(settings, path, value);
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        await productModuleStore.createProductModuleDefinition({
          productModuleId: productModuleStore.productModule.productModuleId,
          data: { settings_json: settings },
        });
        if (productModuleComponentLoadingStateStore) {
          updateSavingState(
            key,
            SavingState.Saved,
            productModuleComponentLoadingStateStore,
          );
          debounce(
            key,
            () =>
              updateSavingState(
                key,
                SavingState.Default,
                productModuleComponentLoadingStateStore,
              ),
            3000,
          );
        }
      }, 1000);
    }
  };

  render() {
    const {
      label,
      lockedComponent,
      description,
      indent,
      key,
      title,
    } = this.props.params;

    const componentType = this.props.componentType;

    const productModuleStore = this.props.productModuleStore;
    const productModuleComponentLoadingStateStore = this.props
      .productModuleComponentLoadingStateStore;

    const nested = indent ? true : false;

    const saving =
      productModuleComponentLoadingStateStore &&
      productModuleComponentLoadingStateStore.productModuleBillingComponents[key];

    return (
      <Row className='product-module-definition-settings-text-input-wrapper'>
        <Col xs={3}>{title}</Col>
        <Col xs={9}>
          <div style={nestedStyleCheckbox(nested, 1)}>
            <div>
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <div className='settings-description-label-div'>
                  <p style={{ fontWeight: 'bold' }}>{label}</p>
                </div>
                {savedBadgeRender(saving)}
                {savingBadgeRender(saving)}
                {description && (
                  <span
                    style={{
                      display: 'flex',
                      marginTop: -7,
                      paddingBottom: 10,
                    }}
                  >
                    {description}
                  </span>
                )}
                <Input
                  onChange={e => {
                    if (componentType === ComponentType.Number) {
                      const targetValue = parseInt(e.target.value);
                      if (isNaN(targetValue)) {
                        this.updateTextInput({ ...this.props.params }, null);
                      } else {
                        this.updateTextInput(
                          { ...this.props.params },
                          targetValue,
                        );
                      }
                    } else {
                      this.updateTextInput(
                        { ...this.props.params },
                        e.target.value,
                      );
                    }
                  }}
                  style={{ width: 300 }}
                  disabled={lockedComponent}
                  type={componentType}
                  defaultValue={getTextInputValue(
                    this.props.params,
                    productModuleStore,
                  )}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
