export default (state = [], action) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return action.categories;
    case 'NEW_CATEGORY':
      return [...state, action.category];
    case 'UPDATED_CATEGORY':
      return state.map(c => {
        if (c.id === action.category.id) {
          return action.category;
        }
        return c;
      });
    case 'DELETED_CATEGORY':
      return state.filter(c => c.id !== action.categoryId);
    default:
      return state;
  }
};
