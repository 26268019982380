import * as React from 'react';
import { Table } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

type PrimitiveColumn<T extends { [k: string]: any }> = (keyof T extends Function ? never : keyof T);

interface CustomColumn<T> {
  heading: string;
  element: React.SFC<T>;
}

const isPrimitiveColumn = (value: any): value is PrimitiveColumn<any> => {
  if (!value.heading && !value.element) {
    return true;
  }
  return false;
};

export type ColumnToRender<T> = PrimitiveColumn<T> | CustomColumn<T>;

export interface RowProps {
  onClick: () => void;
}

export interface DomainEntityTableProps<T> {
  rows: T[];
  columns: ColumnToRender<T>[];
  isLoaded?: boolean;
  getRowProps?: (param: T, index?: number) => RowProps;
  hover?: boolean;
  emptyRowLength?: number;
  lean?: boolean;
}

export class DomainEntityTable<T> extends React.Component<DomainEntityTableProps<T>>{

  constructor(props: DomainEntityTableProps<T>){
    super(props);
  }
  renderHeadings = () => {
    const { columns, lean, hover } = this.props;
    return <tr style={{ cursor: hover ? 'pointer' : 'default' }}>
      {columns.map((column, index) =>
        <th className={lean ? '' : 'fader'} key={index}>
          {
            isPrimitiveColumn(column) ?
              _.startCase(column as string) :
              column.heading
          }
        </th>)}
    </tr>;
  };

  renderRow = (row: T, index: number) => {
    const { getRowProps, columns, hover } = this.props;
    return <tr className={hover === false ? 'no-hover-row' : ''} key={`row_${index}`} {...(getRowProps ? getRowProps(row, index) : {})}>
      {columns.map((key, cellIndex) => this.renderCell(key, row, cellIndex))}
    </tr>;
  };

  renderCell = (columnToRender: ColumnToRender<T>, row: T, index: number) => {
    const { lean } = this.props;
    let propertyToRender;

    if (isPrimitiveColumn(columnToRender)) {
      const key = columnToRender;
      propertyToRender = _.get(row, key);

      if (moment.isMoment(propertyToRender)) {
        propertyToRender = propertyToRender.format('YYYY-MM-DD');
      }

      return <td className={lean ? 'fader-lean' : 'fader'} key={`cell_${index}`}>
        {propertyToRender && propertyToRender.toString()}
      </td>;
    } else {
      return <td className={lean ? 'fader-lean' : 'fader'} key={`cell_${index}`}>
        {columnToRender.element(row)}
      </td>;
    }
  };

  getRandomWidth = (max: number = 90, min: number = 60) => {
    const ran = Math.random();
    return Math.round((max - min) * ran) + min;
  }

  renderEmptyRows = () => {
    const { columns, emptyRowLength } = this.props;
    const length = emptyRowLength ? emptyRowLength : 3;

    return Array.from({ length }, (value, index) => (
      <tr className='empty-row' key={index}>
        {
          columns.map((c, index) => (
            <td key={`cell_${index}`}>
              <div className='empty-state'>
                <div style={{ width: `${this.getRandomWidth()}%` }} />
              </div>
            </td>
          ))
        }
      </tr>
    ));
  }

  render() {
    const { lean } = this.props;
    return (
      <Table className='domain-entity-table'>
        <thead style={{ marginBottom: '10px' }}>
          {
            this.renderHeadings()
          }
        </thead>

        <tbody className={`${lean ? 'fader-lean' : 'fader'} ${this.props.hover === false ? '' : 'hover-highlight-rows'}`} >
          {
            this.props.isLoaded ?
              this.props.rows.map(this.renderRow) :
              this.renderEmptyRows()
          }
        </tbody>
      </Table>
    );
  };
}
