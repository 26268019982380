/* eslint-disable */
import React from 'react';

export default class Image extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
      >
        <g fill='none' fillRule='evenodd'>
          <path fill='#4078FA' d='M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16z' />
          <path
            fill='#FFF'
            d='M10.117 11.569l-.135.549c-.402.158-.723.28-.963.362-.24.084-.519.125-.836.125-.488 0-.867-.118-1.137-.356a1.153 1.153 0 0 1-.406-.906c0-.142.01-.288.03-.436.021-.148.054-.316.098-.503l.5-1.781c.045-.17.083-.332.113-.485.03-.152.046-.292.046-.418 0-.227-.047-.386-.14-.475-.094-.09-.273-.134-.537-.134-.13 0-.263.02-.4.06-.136.041-.252.08-.35.116l.134-.548c.33-.135.645-.25.945-.345.301-.096.586-.143.854-.143.484 0 .857.117 1.12.35.262.234.393.538.393.912 0 .077-.01.213-.028.409a2.767 2.767 0 0 1-.1.536l-.5 1.775c-.041.142-.078.305-.11.488a2.59 2.59 0 0 0-.049.415c0 .235.053.396.159.481.105.086.288.129.548.129.122 0 .26-.022.415-.064.155-.043.267-.08.336-.113zm.128-7.447c0 .31-.117.572-.351.79a1.196 1.196 0 0 1-.845.326c-.329 0-.612-.109-.847-.326a1.035 1.035 0 0 1-.354-.79c0-.309.118-.573.354-.793A1.2 1.2 0 0 1 9.049 3c.33 0 .611.11.845.33.234.219.35.483.35.792z'
          />
        </g>
      </svg>
    );
  }
}
