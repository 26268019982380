import React from 'react';
import { Table } from 'reactstrap';
import { formatDate } from '../../helpers';
import { browserHistory } from 'react-router';

export default class PaymentMethodsTable extends React.Component {
  navigateToPaymentMethod = paymentMethod => {
    const orgId =
      paymentMethod.organizationId ||
      browserHistory.getCurrentLocation().pathname.split('/')[2];
    browserHistory.push(
      `/organizations/${orgId}/policyholders/${
        paymentMethod.policyholderId
      }/payment-methods/${
        paymentMethod.paymentMethodId
      }?sandbox=${browserHistory.getCurrentLocation().query.sandbox ===
        'true'}`,
    );
  };

  render() {
    return (
      <Table hover>
        <thead>
          <tr>
            <td>
              <b>Payment Method Id</b>
            </td>
            <td>
              <b>BANV Status</b>
            </td>
            <td>
              <b>Type</b>
            </td>
            <td>
              <b>Created at</b>
            </td>
          </tr>
        </thead>
        <tbody>{this.props.paymentMethods.map(o => this.renderRow(o))}</tbody>
      </Table>
    );
  }

  renderRow(paymentMethod) {
    return (
      <tr
        key={paymentMethod.paymentMethodId}
        onClick={() => this.navigateToPaymentMethod(paymentMethod)}
      >
        <td>{paymentMethod.paymentMethodId}</td>
        <td>
          {paymentMethod.bankDetails
            ? paymentMethod.bankDetails.banvStatus
            : '-'}
        </td>
        <td>{paymentMethod.type}</td>
        <td>{formatDate(paymentMethod.createdAt)}</td>
      </tr>
    );
  }
}
/**
 * TODO:
 * - link to policy, policyholder
 * - policyholder page, payment method page
 */
