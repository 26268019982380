import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { OrganizationListStore } from '../../../../organizations/stores/organization-list-store';
import { UUID } from '../../../../shared/domain/uuid';

export const OrganizationDisplay = inject('organizationListStore')(
  observer(
    (props: {
      organizationListStore?: OrganizationListStore;
      organizationId: UUID;
    }) => {
      if (!props.organizationListStore || !props.organizationListStore.organizations) {
        return <></>;
      }

      const organization = props.organizationListStore.organizations.find(
        o => o.id === props.organizationId.toString(),
      );

      return (
        <>
          {organization ? (
            <a
              target='_'
              href={`/organizations/${props.organizationId}/summary`}
            >
              {organization.productName}
            </a>
          ) : (
            'Org not found'
          )}
        </>
      );
    },
  ),
);
