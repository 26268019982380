import { PolicyBillingFrequency } from '../../domain/product-module-definition';

const billingFrequencyTypeString = Object.values(PolicyBillingFrequency)
  .map((t) => `'${t}'`)
  .join(' | ');

export const productModuleQuotePackage = `
declare class QuotePackage {
  package_name: string;
  sum_assured?: number;
  base_premium: number;
  suggested_premium: number;
  module: object;
  billing_frequency: ${billingFrequencyTypeString};
  input_data?: { [key: string]: any };

  constructor(init: QuotePackage) {
    Object.assign(this, init);
  };
}
`;
