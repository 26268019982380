import React from 'react';
import { Link, browserHistory } from 'react-router';

export const isSandbox = () =>
  browserHistory.getCurrentLocation().query.sandbox === 'true';

export const policyLink = policy => {
  const url = `/policies/${policy.id}?sandbox=${isSandbox()}`;

  return <Link to={url}>{policy.policyNumber}</Link>;
};

export const policyPaymentsLink = policy => {
  const url = `/policies/${policy.id}/payments?sandbox=${isSandbox()}`;

  return <Link to={url}>{policy.policyNumber}</Link>;
};

export const paymentLink = payment => {
  const url = `/policies/${payment.policyId}/payments/${
    payment.paymentId
  }?sandbox=${isSandbox()}`;

  return <Link to={url}>{payment.paymentId.substring(0, 8)}</Link>;
};

export const orgLink = orgId => {
  const url = `/organizations/${orgId}/summary`;

  return <Link to={url}>{orgId.substring(0, 8)}</Link>;
};

export const paymentBatchLink = paymentBatchId => {
  const url = `/payment-batches/${paymentBatchId}`;

  return <Link to={url}>{paymentBatchId.substring(0, 8)}</Link>;
};

export const paymentBatchPaymentsLink = paymentBatch => {
  const url = `/payment-batches/${paymentBatch.paymentBatchId}/payments`;

  return (
    <Link to={url}>{`${paymentBatch.numPayments} (${
      paymentBatch.numReviewed
    })`}</Link>
  );
};
