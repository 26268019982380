import React from 'react';

const LinkedListItem = props => {
  const classes = props.router && props.router.isActive(props.path) ? 'active' : '';
  return (
    <li
      id={props.id}
      className={classes}
      onClick={() => props.router.push(props.to)}
    >
      <a>{props.children}</a>
    </li>
  );
};

class SidebarMenu extends React.Component {
  render() {
    return (
      <div className='sidebar-secondary'>
        <div className='sidebar-header'>
          <h5>{this.props.title}</h5>
        </div>
        <ul className='list-unstyled components'>
          {this.props.subRoutes.map((subRoute, i) => {
            if (subRoute.title) {
              return <p key={subRoute.name + '-p'}>{subRoute.name}</p>;
            }
            return (
              <LinkedListItem
                key={subRoute.name + i + '-li'}
                path={subRoute.url}
                to={subRoute.url}
                router={this.props.router}
              >
                {subRoute.name}
              </LinkedListItem>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default class SidebarSecondary extends React.Component {
  render() {
    return (
      <SidebarMenu
        subRoutes={this.props.subRoutes}
        router={this.props.router}
        route={this.props.route}
        title={this.props.title}
      />
    );
  }
}
