import React from 'react';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Button,
} from 'reactstrap';
import { withData, toSentenceCase, renderValue } from '../../../helpers';
import CardEntry from '../../../components/card-entry';
import { SetBANVStatusModal } from './set-banv-status-modal';
import SidebarSecondary from '../../../components/sidebar/SidebarSecondary';

class PaymentMethod extends React.Component {
  state = {
    showSetBANVStatusModal: false,
  };

  openSetBANVStatusModal = () =>
    this.setState({ showSetBANVStatusModal: true });
  closeSetBANVStatusModal = () =>
    this.setState({ showSetBANVStatusModal: false });

  render() {
    if (this.props.loading) {
      return (
        <div>
          <i>Loading payment method...</i>
        </div>
      );
    }

    const { paymentMethod } = this.props;

    return (
      <div className='container-with-sidebar'>
        <SidebarSecondary
          router={this.props.router}
          route={this.props.route}
          subRoutes={sideBarSchema.routes()}
          title={sideBarSchema.name}
        />
        <Container>
          <Card>
            <CardHeader>
              Payment method details{' '}
              <Button color='secondary' onClick={this.openSetBANVStatusModal}>
                Set banv status...
              </Button>
            </CardHeader>
            <CardBody>
              {Object.keys(paymentMethod).map((key, index) => (
                <CardEntry key={key} index={index}>
                  <div style={{ padding: '2px' }}>
                    <b>{toSentenceCase(key)}</b>
                  </div>
                  <CardText className='overview-value'>
                    {renderValue(key, paymentMethod[key])}
                  </CardText>
                </CardEntry>
              ))}
            </CardBody>
          </Card>
          <SetBANVStatusModal
            onClose={this.closeSetBANVStatusModal}
            show={this.state.showSetBANVStatusModal}
            organizationId={paymentMethod.organizationId}
            paymentMethodId={paymentMethod.paymentMethodId}
            onBANVStatusUpdated={this.props.fetchPaymentMethod}
          />
        </Container>
      </div>
    );
  }
}

export default withData({
  path: props =>
    `/insurance/admin/payment-methods/${props.params.paymentMethodId}`,
  prop: 'paymentMethod',
})(PaymentMethod);

const sideBarSchema = {
  name: 'Payment method',
  routes: () => {
    return [];
  },
};
