export const joiValidation = `var Joi: AnySchema;

interface AnySchema extends Object {
    schemaType?: Types | string;

    /**
     * Validates a value using the schema and options.
     */
    validate<T>(value: T): ValidationResult<T>;
    validate<T>(value: T, options: ValidationOptions): ValidationResult<T>;
    validate<T, R>(value: T, callback: (err: ValidationError, value: T) => R): R;
    validate<T, R>(value: T, options: ValidationOptions, callback: (err: ValidationError, value: T) => R): R;

    /**
     * Whitelists a value
     */
    allow(...values: any[]): this;
    allow(values: any[]): this;

    /**
     * Adds the provided values into the allowed whitelist and marks them as the only valid values allowed.
     */
    valid(...values: any[]): this;
    valid(values: any[]): this;
    only(...values: any[]): this;
    only(values: any[]): this;
    equal(...values: any[]): this;
    equal(values: any[]): this;

    /**
     * Blacklists a value
     */
    invalid(...values: any[]): this;
    invalid(values: any[]): this;
    disallow(...values: any[]): this;
    disallow(values: any[]): this;
    not(...values: any[]): this;
    not(values: any[]): this;

    /**
     * Marks a key as required which will not allow undefined as value. All keys are optional by default.
     */
    required(): this;
    exist(): this;

    /**
     * Marks a key as optional which will allow undefined as values. Used to annotate the schema for readability as all keys are optional by default.
     */
    optional(): this;

    /**
     * Marks a key as forbidden which will not allow any value except undefined. Used to explicitly forbid keys.
     */
    forbidden(): this;

    /**
     * Marks a key to be removed from a resulting object or array after validation. Used to sanitize output.
     */
    strip(): this;

    /**
     * Annotates the key
     */
    description(desc: string): this;

    /**
     * Annotates the key
     */
    notes(notes: string): this;
    notes(notes: string[]): this;

    /**
     * Annotates the key
     */
    tags(notes: string): this;
    tags(notes: string[]): this;

    /**
     * Attaches metadata to the key.
     */
    meta(meta: object): this;

    /**
     * Annotates the key with an example value, must be valid.
     */
    example(value: any): this;

    /**
     * Annotates the key with an unit name.
     */
    unit(name: string): this;

    /**
     * Overrides the global validate() options for the current key and any sub-key.
     */
    options(options: ValidationOptions): this;

    /**
     * Sets the options.convert options to false which prevent type casting for the current key and any child keys.
     */
    strict(isStrict?: boolean): this;

    /**
     * Sets a default value if the original value is undefined.
     * @param value - the value.
     *   value supports references.
     *   value may also be a function which returns the default value.
     *   If value is specified as a function that accepts a single parameter, that parameter will be a context
     *    object that can be used to derive the resulting value. This clones the object however, which incurs some
     *    overhead so if you don't need access to the context define your method so that it does not accept any
     *    parameters.
     *   Without any value, default has no effect, except for object that will then create nested defaults
     *    (applying inner defaults of that object).
     *
     * Note that if value is an object, any changes to the object after default() is called will change the
     *  reference and any future assignment.
     *
     * Additionally, when specifying a method you must either have a description property on your method or the
     *  second parameter is required.
     */
    default(value: any, description?: string): this;
    default(): this;

    /**
     * Returns a new type that is the result of adding the rules of one type to another.
     */
    concat(schema: this): this;

    /**
     * Converts the type into an alternatives type where the conditions are merged into the type definition where:
     */
    when(ref: string, options: WhenOptions): AlternativesSchema;
    when(ref: Reference, options: WhenOptions): AlternativesSchema;
    when(ref: Schema, options: WhenSchemaOptions): AlternativesSchema;

    /**
     * Overrides the key name in error messages.
     */
    label(name: string): this;

    /**
     * Outputs the original untouched value instead of the casted value.
     */
    raw(isRaw?: boolean): this;

    /**
     * Considers anything that matches the schema to be empty (undefined).
     * @param schema - any object or joi schema to match. An undefined schema unsets that rule.
     */
    empty(schema?: SchemaLike): this;


    error(err: Error | ValidationErrorFunction): this;

    /**
     * Returns a plain object representing the schema's rules and properties
     */
    describe(): Description;

    number: () => NumberSchema;
    array: () => ArraySchema;
    binary: () => BinarySchema;
    boolean: () => BooleanSchema;
    date: () => DateSchema;
    object: () => ObjectSchema;
    string: () => StringSchema;

    dateOfBirth: () => AnySchema;
}

interface Description {
    type?: Types | string;
    label?: string;
    description?: string;
    flags?: object;
    notes?: string[];
    tags?: string[];
    meta?: any[];
    example?: any[];
    valids?: any[];
    invalids?: any[];
    unit?: string;
    options?: ValidationOptions;
    [key: string]: any;
}

interface Context {
    [key: string]: any;
    key?: string;
    label?: string;
}

interface State {
    key?: string;
    path?: string;
    parent?: any;
    reference?: any;
}

interface BooleanSchema extends AnySchema {
    /**
     * Allows for additional values to be considered valid booleans by converting them to true during validation.
     * Accepts a value or an array of values. String comparisons are by default case insensitive,
     * see boolean.insensitive() to change this behavior.
     * @param values - strings, numbers or arrays of them
     */
    truthy(...values: Array<string | number | string[] | number[]>): this;

    /**
     * Allows for additional values to be considered valid booleans by converting them to false during validation.
     * Accepts a value or an array of values. String comparisons are by default case insensitive,
     * see boolean.insensitive() to change this behavior.
     * @param values - strings, numbers or arrays of them
     */
    falsy(...values: Array<string | number | string[] | number[]>): this;

    /**
     * Allows the values provided to truthy and falsy as well as the "true" and "false" default conversion
     * (when not in strict() mode) to be matched in a case insensitive manner.
     * @param enabled
     */
    insensitive(enabled?: boolean): this;
}

interface NumberSchema extends AnySchema {
    /**
     * Specifies the minimum value.
     * It can also be a reference to another field.
     */
    min(limit: number): this;
    min(limit: Reference): this;

    /**
     * Specifies the maximum value.
     * It can also be a reference to another field.
     */
    max(limit: number): this;
    max(limit: Reference): this;

    /**
     * Specifies that the value must be greater than limit.
     * It can also be a reference to another field.
     */
    greater(limit: number): this;
    greater(limit: Reference): this;

    /**
     * Specifies that the value must be less than limit.
     * It can also be a reference to another field.
     */
    less(limit: number): this;
    less(limit: Reference): this;

    /**
     * Requires the number to be an integer (no floating point).
     */
    integer(): this;

    /**
     * Specifies the maximum number of decimal places where:
     * @param limit - the maximum number of decimal places allowed.
     */
    precision(limit: number): this;

    /**
     * Specifies that the value must be a multiple of base.
     */
    multiple(base: number): this;

    /**
     * Requires the number to be positive.
     */
    positive(): this;

    /**
     * Requires the number to be negative.
     */
    negative(): this;

    /**
     * Requires the number to be a TCP port, so between 0 and 65535.
     */
    port(): this;
}

interface StringSchema extends AnySchema {
    /**
     * Allows the value to match any whitelist of blacklist item in a case insensitive comparison.
     */
    insensitive(): this;

    /**
     * Specifies the minimum number string characters.
     * @param limit - the minimum number of string characters required. It can also be a reference to another field.
     * @param encoding - if specified, the string length is calculated in bytes using the provided encoding.
     */
    min(limit: number, encoding?: string): this;
    min(limit: Reference, encoding?: string): this;

    /**
     * Specifies the maximum number of string characters.
     * @param limit - the maximum number of string characters allowed. It can also be a reference to another field.
     * @param encoding - if specified, the string length is calculated in bytes using the provided encoding.
     */
    max(limit: number, encoding?: string): this;
    max(limit: Reference, encoding?: string): this;

    /**
     * Specifies whether the string.max() limit should be used as a truncation.
     * @param enabled - optional parameter defaulting to true which allows you to reset the behavior of truncate by providing a falsy value.
     */
    truncate(enabled?: boolean): this;

    /**
     * Requires the string value to be in a unicode normalized form. If the validation convert option is on (enabled by default), the string will be normalized.
     * @param form - The unicode normalization form to use. Valid values: NFC [default], NFD, NFKC, NFKD
     */
    normalize(form?: 'NFC' | 'NFD' | 'NFKC' | 'NFKD'): this;

    /**
     * Requires the string value to be a valid base64 string; does not check the decoded value.
     * @param options - optional settings: The unicode normalization options to use. Valid values: NFC [default], NFD, NFKC, NFKD
     */
    base64(options?: Base64Options): this;

    /**
     * Requires the number to be a credit card number (Using Lunh Algorithm).
     */
    creditCard(): this;

    /**
     * Specifies the exact string length required
     * @param limit - the required string length. It can also be a reference to another field.
     * @param encoding - if specified, the string length is calculated in bytes using the provided encoding.
     */
    length(limit: number, encoding?: string): this;
    length(limit: Reference, encoding?: string): this;

    /**
     * Defines a regular expression rule.
     * @param pattern - a regular expression object the string value must match against.
     * @param options - optional, can be:
     *   Name for patterns (useful with multiple patterns). Defaults to 'required'.
     *   An optional configuration object with the following supported properties:
     *     name - optional pattern name.
     *     invert - optional boolean flag. Defaults to false behavior. If specified as true, the provided pattern will be disallowed instead of required.
     */
    regex(pattern: RegExp, options?: string | StringRegexOptions): this;

    /**
     * Replace characters matching the given pattern with the specified replacement string where:
     * @param pattern - a regular expression object to match against, or a string of which all occurrences will be replaced.
     * @param replacement - the string that will replace the pattern.
     */
    replace(pattern: RegExp, replacement: string): this;
    replace(pattern: string, replacement: string): this;

    /**
     * Requires the string value to only contain a-z, A-Z, and 0-9.
     */
    alphanum(): this;

    /**
     * Requires the string value to only contain a-z, A-Z, 0-9, and underscore _.
     */
    token(): this;

    /**
     * Requires the string value to be a valid email address.
     */
    email(options?: EmailOptions): this;

    /**
     * Requires the string value to be a valid ip address.
     */
    ip(options?: IpOptions): this;

    /**
     * Requires the string value to be a valid RFC 3986 URI.
     */
    uri(options?: UriOptions): this;

    /**
     * Requires the string value to be a valid data URI string.
     */
    dataUri(options?: DataUriOptions): this;

    /**
     * Requires the string value to be a valid GUID.
     */
    guid(options?: GuidOptions): this;

    uuid(options?: GuidOptions): this;

    /**
     * Requires the string value to be a valid hexadecimal string.
     */
    hex(options?: HexOptions): this;

    /**
     * Requires the string value to be a valid hostname as per RFC1123.
     */
    hostname(): this;

    /**
     * Requires the string value to be in valid ISO 8601 date format.
     */
    isoDate(): this;

    /**
     * Requires the string value to be all lowercase. If the validation convert option is on (enabled by default), the string will be forced to lowercase.
     */
    lowercase(): this;

    /**
     * Requires the string value to be all uppercase. If the validation convert option is on (enabled by default), the string will be forced to uppercase.
     */
    uppercase(): this;

    /**
     * Requires the string value to contain no whitespace before or after. If the validation convert option is on (enabled by default), the string will be trimmed.
     */
    trim(): this;
}

interface SymbolSchema extends AnySchema {
    // TODO: support number and symbol index
    map(iterable: Iterable<[string | number | boolean | symbol, symbol]> | { [key: string]: symbol }): this;
}

interface ArraySchema extends AnySchema {
    /**
     * Allow this array to be sparse.
     * enabled can be used with a falsy value to go back to the default behavior.
     */
    sparse(enabled?: any): this;

    /**
     * Allow single values to be checked against rules as if it were provided as an array.
     * enabled can be used with a falsy value to go back to the default behavior.
     */
    single(enabled?: any): this;

    /**
     * List the types allowed for the array values.
     * type can be an array of values, or multiple values can be passed as individual arguments.
     * If a given type is .required() then there must be a matching item in the array.
     * If a type is .forbidden() then it cannot appear in the array.
     * Required items can be added multiple times to signify that multiple items must be found.
     * Errors will contain the number of items that didn't match.
     * Any unmatched item having a label will be mentioned explicitly.
     *
     * @param type - a joi schema object to validate each array item against.
     */
    items(...types: SchemaLike[]): this;
    items(types: SchemaLike[]): this;

    /**
     * Lists the types in sequence order for the array values where:
     * @param type - a joi schema object to validate against each array item in sequence order. type can be an array of values, or multiple values can be passed as individual arguments.
     * If a given type is .required() then there must be a matching item with the same index position in the array.
     * Errors will contain the number of items that didn't match.
     * Any unmatched item having a label will be mentioned explicitly.
     */
    ordered(...types: SchemaLike[]): this;
    ordered(types: SchemaLike[]): this;

    /**
     * Specifies the minimum number of items in the array.
     */
    min(limit: number): this;

    /**
     * Specifies the maximum number of items in the array.
     */
    max(limit: number): this;

    /**
     * Specifies the exact number of items in the array.
     */
    length(limit: number): this;
    length(limit: Reference): this;

    /**
     * Requires the array values to be unique.
     * Be aware that a deep equality is performed on elements of the array having a type of object,
     * a performance penalty is to be expected for this kind of operation.
     */
    unique(comparator?: string): this;
    unique<T = any>(comparator?: (a: T, b: T) => boolean): this;
}

interface ObjectSchema extends AnySchema {
    /**
     * Sets or extends the allowed object keys.
     */
    keys(schema?: SchemaMap): this;

    /**
     * Appends the allowed object keys. If schema is null, undefined, or {}, no changes will be applied.
     */
    append(schema?: SchemaMap): this;

    /**
     * Specifies the minimum number of keys in the object.
     */
    min(limit: number): this;

    /**
     * Specifies the maximum number of keys in the object.
     */
    max(limit: number): this;

    /**
     * Specifies the exact number of keys in the object.
     */
    length(limit: number): this;

    /**
     * Specify validation rules for unknown keys matching a pattern.
     *
     * @param pattern - a pattern that can be either a regular expression or a joi schema that will be tested against the unknown key names
     * @param schema - the schema object matching keys must validate against
     */
    pattern(pattern: RegExp | SchemaLike, schema: SchemaLike): this;

    /**
     * Defines an all-or-nothing relationship between keys where if one of the peers is present, all of them are required as well.
     * @param peers - the key names of which if one present, all are required. peers can be a single string value,
     * an array of string values, or each peer provided as an argument.
     */
    and(...peers: string[]): this;
    and(peers: string[]): this;

    /**
     * Defines a relationship between keys where not all peers can be present at the same time.
     * @param peers - the key names of which if one present, the others may not all be present.
     * peers can be a single string value, an array of string values, or each peer provided as an argument.
     */
    nand(...peers: string[]): this;
    nand(peers: string[]): this;

    /**
     * Defines a relationship between keys where one of the peers is required (and more than one is allowed).
     */
    or(...peers: string[]): this;
    or(peers: string[]): this;

    /**
     * Defines an exclusive relationship between a set of keys. one of them is required but not at the same time where:
     */
    xor(...peers: string[]): this;
    xor(peers: string[]): this;

    /**
     * Requires the presence of other keys whenever the specified key is present.
     */
    with(key: string, peers: string): this;
    with(key: string, peers: string[]): this;

    /**
     * Forbids the presence of other keys whenever the specified is present.
     */
    without(key: string, peers: string): this;
    without(key: string, peers: string[]): this;

    /**
     * Renames a key to another name (deletes the renamed key).
     */
    rename(from: string, to: string, options?: RenameOptions): this;

    /**
     * Verifies an assertion where.
     */
    assert(ref: string, schema: SchemaLike, message?: string): this;
    assert(ref: Reference, schema: SchemaLike, message?: string): this;

    /**
     * Overrides the handling of unknown keys for the scope of the current object only (does not apply to children).
     */
    unknown(allow?: boolean): this;

    /**
     * Requires the object to be an instance of a given constructor.
     *
     * @param constructor - the constructor function that the object must be an instance of.
     * @param name - an alternate name to use in validation errors. This is useful when the constructor function does not have a name.
     */
    type(constructor: Function, name?: string): this;

    /**
     * Sets the specified children to required.
     *
     * @param children - can be a single string value, an array of string values, or each child provided as an argument.
     *
     *   var schema = Joi.object().keys({ a: { b: Joi.number() }, c: { d: Joi.string() } });
     *   var requiredSchema = schema.requiredKeys('', 'a.b', 'c', 'c.d');
     *
     * Note that in this example '' means the current object, a is not required but b is, as well as c and d.
     */
    requiredKeys(children: string[]): this;
    requiredKeys(...children: string[]): this;

    /**
     * Sets the specified children to optional.
     *
     * @param children - can be a single string value, an array of string values, or each child provided as an argument.
     *
     * The behavior is exactly the same as requiredKeys.
     */
    optionalKeys(children: string[]): this;
    optionalKeys(...children: string[]): this;

    /**
     * Sets the specified children to forbidden.
     *
     * @param children - can be a single string value, an array of string values, or each child provided as an argument.
     *
     *   const schema = Joi.object().keys({ a: { b: Joi.number().required() }, c: { d: Joi.string().required() } });
     *   const optionalSchema = schema.forbiddenKeys('a.b', 'c.d');
     *
     * The behavior is exactly the same as requiredKeys.
     */
    forbiddenKeys(children: string[]): this;
    forbiddenKeys(...children: string[]): this;
}

interface BinarySchema extends AnySchema {
    /**
     * Sets the string encoding format if a string input is converted to a buffer.
     */
    encoding(encoding: string): this;

    /**
     * Specifies the minimum length of the buffer.
     */
    min(limit: number): this;

    /**
     * Specifies the maximum length of the buffer.
     */
    max(limit: number): this;

    /**
     * Specifies the exact length of the buffer:
     */
    length(limit: number): this;
}

interface DateSchema extends AnySchema {
    /**
     * Specifies the oldest date allowed.
     * Notes: 'now' can be passed in lieu of date so as to always compare relatively to the current date,
     * allowing to explicitly ensure a date is either in the past or in the future.
     * It can also be a reference to another field.
     */
    min(date: Date): this;
    min(date: number): this;
    min(date: string): this;
    min(date: Reference): this;

    /**
     * Specifies the latest date allowed.
     * Notes: 'now' can be passed in lieu of date so as to always compare relatively to the current date,
     * allowing to explicitly ensure a date is either in the past or in the future.
     * It can also be a reference to another field.
     */
    max(date: Date): this;
    max(date: number): this;
    max(date: string): this;
    max(date: Reference): this;

    /**
     * Specifies the allowed date format:
     * @param format - string or array of strings that follow the moment.js format.
     */
    format(format: string): this;
    format(format: string[]): this;

    /**
     * Requires the string value to be in valid ISO 8601 date format.
     */
    iso(): this;

    /**
     * Requires the value to be a timestamp interval from Unix Time.
     * @param type - the type of timestamp (allowed values are unix or javascript [default])
     */
    timestamp(type?: 'javascript' | 'unix'): this;
}

interface FunctionSchema extends AnySchema {
    /**
     * Specifies the arity of the function where:
     * @param n - the arity expected.
     */
    arity(n: number): this;

    /**
     * Specifies the minimal arity of the function where:
     * @param n - the minimal arity expected.
     */
    minArity(n: number): this;

    /**
     * Specifies the minimal arity of the function where:
     * @param n - the minimal arity expected.
     */
    maxArity(n: number): this;

    /**
     * Requires the function to be a Joi reference.
     */
    ref(): this;
}

interface AlternativesSchema extends AnySchema {
    try(types: SchemaLike[]): this;
    try(...types: SchemaLike[]): this;
    when(ref: string, options: WhenOptions): this;
    when(ref: Reference, options: WhenOptions): this;
    when(ref: Schema, options: WhenSchemaOptions): this;
}

interface LazySchema extends AnySchema {
}`;
